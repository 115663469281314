import React, { FC, ReactNode, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";

export interface SwipeableSupportingPaneHeaderProps {
  label: string;
  hideLabel?: boolean;
  disableResize: boolean;
  toolbarContent?: ReactNode;
  onChange: (open: boolean) => void;
  onToolbarContainerMount: (element: Element) => void;
}

export const SwipeableSupportingPaneHeader: FC<
  SwipeableSupportingPaneHeaderProps
> = (props) => {
  const hideLabel = props.hideLabel ?? false;
  const toolbarContainerRef = React.useRef<Element | null>(null);

  useEffect(() => {
    if (toolbarContainerRef.current != null) {
      props.onToolbarContainerMount(toolbarContainerRef.current);
    }
  }, [toolbarContainerRef, props]);

  const handleClick = () => {
    if (!props.disableResize) {
      props.onChange?.(false);
    }
  };

  return (
    <Stack
      className={"SwipeableSupportingPaneHeader"}
      direction={"column"}
      onClick={handleClick}
    >
      {!hideLabel && (
        <Typography color={"secondary.main"} align={"center"}>
          {props.label}
        </Typography>
      )}
      <Stack direction={"row"} sx={{ minHeight: "56px" }}>
        <Stack
          direction={"row"}
          className={"toolbarContent"}
          sx={{ "> first-child()": { ml: 1 } }}
        >
          {props.toolbarContent != null && props.toolbarContent}
        </Stack>
        <Box
          className={"toolbarContainer"}
          ref={toolbarContainerRef}
          sx={{
            ml: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
            "> first-child()": { ml: 1 },
          }}
        ></Box>
      </Stack>
    </Stack>
  );
};
