import { useContext } from "react";
import { AppInfoContext } from "./AppInfoProvider";
import { AppInfo } from "./AppInfo";

export const useAppInfo = (): AppInfo => {
  const appInfo = useContext(AppInfoContext);
  if (appInfo == null) {
    throw new Error("useAppInfo must be used within a AppInfoProvider");
  }

  return appInfo;
};
