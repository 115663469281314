import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { DateTime } from "luxon";
import {
  BurnId,
  BurnNonSensitiveDto,
  BurnQualityCount,
  BurnQualityCountAggregateByTimeDto,
  BurnQualityCountAggregateQuery,
  BurnQualityCountDto,
  BurnTemperatureSeriesNonSensitiveDto,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { BurnQualityCountQuery } from "./assessment/BurnQualityCountQuery";
import { QueryResult } from "shared/ts/utils/query";
import { AbnormalBurnsQueryDto } from "./AbnormalBurnsQuery";

export class BurnDao {
  public readonly customerEnvironment: CustomerEnvironmentName;
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.customerEnvironment = args.customerEnvironment;
    this.fetchHelper = new FetchHelper({ basePath: "/api/burn/" })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  getByFlue = (
    flue: FlueId,
    fromDate: DateTime,
    toDate: DateTime
  ): Promise<Array<BurnNonSensitiveDto>> => {
    return this.fetchHelper
      .get(
        `get-by-flue/${flue}?fromDate=${fromDate
          .toUTC()
          .toISO()}&toDate=${toDate.toUTC().toISO()}`
      )
      .fetchAsJson();
  };

  queryAbnormalBurns = (
    query: AbnormalBurnsQueryDto
  ): Promise<QueryResult<BurnNonSensitiveDto>> => {
    return this.fetchHelper.post("query-abnormal-burns", query).fetchAsJson();
  };

  getTemperatureSeriesByBurn = (
    burnIds: Array<BurnId>
  ): Promise<Array<BurnTemperatureSeriesNonSensitiveDto>> => {
    return this.fetchHelper
      .post("get-temperature-series-by-burn", burnIds)
      .fetchAsJson();
  };

  queryBurnQualityCountAggregate = async (
    query: BurnQualityCountAggregateQuery
  ): Promise<Array<BurnQualityCountAggregateByTimeDto>> => {
    return await this.fetchHelper
      .post("query-burn-quality-count-aggregate", query)
      .fetchAsJson();
  };

  querySingleBurnQualityCount = async (
    query: BurnQualityCountQuery
  ): Promise<BurnQualityCount> => {
    const result = await this.fetchHelper
      .post("query-single-burn-quality-count", query)
      .fetchAsJson<BurnQualityCountDto>();
    return new BurnQualityCount(result);
  };

  getBurnQualityCountSinceSweep = async (
    flueId: FlueId
  ): Promise<BurnQualityCount> => {
    const result = await this.fetchHelper
      .get("get-burn-quality-count-since-sweep/" + flueId)
      .fetchAsJson<BurnQualityCountDto>();
    return new BurnQualityCount(result);
  };
}
