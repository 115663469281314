import React, { FC } from "react";

export interface ConditionalWrapProps {
  condition: boolean;
  wrap: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
}

export const ConditionalWrap: FC<ConditionalWrapProps> = ({
  condition,
  wrap,
  children,
}) => (condition ? wrap(children) : children);
