import { FC, ReactNode } from "react";
import { Card, Typography, useTheme } from "@mui/material";
import styled from "styled-components";

export const StyledSettingsGroup = styled(Card)`
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  > :nth-child(2) {
    flex-grow: 1;
  }
`;

export interface SettingsGroupProps {
  name: string;
  children: ReactNode;
}

export const SettingsGroup: FC<SettingsGroupProps> = (props) => {
  const theme = useTheme();
  return (
    <StyledSettingsGroup
      elevation={1}
      sx={{
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      }}
    >
      <Typography
        color={"text.secondary"}
        sx={{
          paddingBottom: theme.spacing(1),
        }}
      >
        {props.name}
      </Typography>
      <Card elevation={1}>{props.children}</Card>
    </StyledSettingsGroup>
  );
};
