import React, { FC, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { BurnAssessmentColor } from "./BurnAssessmentColor";
import { BurnQualityCount } from "./BurnQualityCount";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { PieChart } from "@mui/x-charts";
import { PieValueType } from "@mui/x-charts/models/seriesType/pie";

export interface BurnQualityForPeriodPieChartProps {
  data: BurnQualityCount | undefined;
  isLoading?: boolean;
  width: number;
  height: number;
  sx?: SxProps;
}

export const BurnQualityPieChart: FC<BurnQualityForPeriodPieChartProps> = (
  props
) => {
  const { data, isLoading, width, height } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");

  const series: Array<PieValueType> | undefined = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    return [
      {
        id: "excellent",
        value: data.excellent,
        label: t("BurnAssessment.Good"),
        color: BurnAssessmentColor.Good,
      },
      {
        id: "good",
        value: data.good,
        label: t("BurnAssessment.Normal"),
        color: BurnAssessmentColor.Normal,
      },
      {
        id: "bad",
        value: data.bad,
        label: t("BurnAssessment.Bad"),
        color: BurnAssessmentColor.Bad,
      },
      {
        id: "unknown",
        value: data.unknown,
        label: t("BurnAssessment.Unknown"),
        color: BurnAssessmentColor.Unknown,
      },
    ] as PieValueType[];
  }, [data, t]);

  return (
    <>
      {series !== undefined && (data?.total ?? 0) === 0 && (
        <Box
          sx={{
            textAlign: "center",
            width: `${width}px`,
            height: `${height}px`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>{t("No data for selected period")}</Typography>
        </Box>
      )}
      {series !== undefined && (data?.total ?? 0) > 0 && (
        <PieChart
          slotProps={{
            legend: {
              padding: -10,
            },
          }}
          series={[
            {
              arcLabel: (item) => (item.value ? `${item.value}` : ""),
              data: series,
            },
          ]}
          width={width}
          height={height}
        />
      )}
    </>
  );
};
