import React, { FC, ReactNode } from "react";
import {
  FlueId,
  FlueMetrics,
  FlueMonthMetricsByYearLoader,
  PredictedFlueMonthMetricsByYearLoader,
  Sweeping,
  SweepingLoader,
} from "@airmont/firefly/shared/ts/domain";
import { DateTime } from "luxon";
import { UseQueryResult } from "@tanstack/react-query";

export interface SootIndexByMonthLoaderResult {
  loading: boolean;
  flueMetricsByMonth: Record<string, FlueMetrics>;
  predictedFlueMetricsByMonth: Record<string, FlueMetrics>;
  sweepings: UseQueryResult<Sweeping[], Error>;
}

export interface SootIndexByMonthLoaderProps {
  flueId: FlueId;
  lastSweep: DateTime<true>;
  year: number;
  children: (props: SootIndexByMonthLoaderResult) => ReactNode;
}

export const SootIndexByMonthLoader: FC<SootIndexByMonthLoaderProps> = (
  props
) => {
  const { flueId, year, lastSweep } = props;

  return (
    <SweepingLoader flueId={flueId}>
      {(sweepingsResult) => {
        return (
          <FlueMonthMetricsByYearLoader
            flueId={flueId}
            createMissing={{
              enabled: true,
              since: lastSweep,
            }}
          >
            {(flueMetricsByMonth, monthMetricsLoading) => {
              return (
                <PredictedFlueMonthMetricsByYearLoader
                  flueId={flueId}
                  year={year}
                >
                  {(predictedFlueMetricsByMonth, predictedMetricsLoading) => {
                    return props.children({
                      loading: monthMetricsLoading || predictedMetricsLoading,
                      flueMetricsByMonth: flueMetricsByMonth,
                      predictedFlueMetricsByMonth: predictedFlueMetricsByMonth,
                      sweepings: sweepingsResult,
                    });
                  }}
                </PredictedFlueMonthMetricsByYearLoader>
              );
            }}
          </FlueMonthMetricsByYearLoader>
        );
      }}
    </SweepingLoader>
  );
};
