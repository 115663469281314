import React, { FC, ReactNode } from "react";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { NavigationLessAppPage } from "./NavigationLessAppPage";
import { useTranslation } from "react-i18next";
import { useLanguage } from "shared-ts-language";

export interface UnauthenticatedPageProps {
  appIcon?: ReactNode;
  booting?: string;
  appName: ReactNode;
  loginPath?: string;
}

export const UnauthenticatedPage: FC<UnauthenticatedPageProps> = (props) => {
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();
  const { t } = useTranslation("shared-ts-ui-app-boot");
  const handleLoginClick = () => {
    if (props.loginPath != null) {
      navigate(props.loginPath);
    }
  };

  return (
    <NavigationLessAppPage
      appName={props.appName}
      appIcon={props.appIcon}
      language={language}
      onLanguageChange={setLanguage}
    >
      <Stack useFlexGap gap={2}>
        <Button
          variant={"contained"}
          onClick={handleLoginClick}
          sx={{
            marginTop: "30px",
            alignSelf: "center",
          }}
        >
          {t("Log in")}
        </Button>
      </Stack>
    </NavigationLessAppPage>
  );
};
