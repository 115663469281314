import React, { cloneElement, FC, ReactElement } from "react";
import { TabPanelProps } from "./TabPanel";
import { Box, Tabs, TabsProps } from "@mui/material";
import { TabPanels } from "./TabPanels";
import { TabContext } from "@mui/lab";
import { SxProps } from "@mui/system";
import { useSxMerge } from "shared-ts-mui";

export interface TabsAndPanelsProps {
  value: string;
  onChange: (newValue: string) => void;
  tabs: Array<ReactElement<TabsProps> | undefined>;
  tabPanels: Array<ReactElement<TabPanelProps> | undefined>;
  variant?: "standard" | "scrollable" | "fullWidth";
  renderHiddenTabPanels?: boolean;
  sx?: SxProps;
}

export const TabsAndPanels: FC<TabsAndPanelsProps> = (props) => {
  const sx = useSxMerge(props.sx, {
    display: "flex",
    flexDirection: "column",
    ".TabPanels": {
      flexGrow: 1,
      minHeight: 0,
      minWidth: 0,
    },
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    props.onChange(newValue);
  };

  return (
    <Box className={"TabsAndPanels"} sx={sx}>
      <TabContext value={props.value}>
        <Box
          className={"TabsWrapper"}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            value={props.value}
            variant={props.variant}
            onChange={handleTabChange}
          >
            {props.tabs.map((tab) => {
              if (tab == null) {
                return null;
              }
              return cloneElement(tab, {
                key: tab.props.value,
              });
            })}
          </Tabs>
        </Box>
        <TabPanels
          value={props.value}
          tabPanels={props.tabPanels}
          renderHidden={props.renderHiddenTabPanels}
        />
      </TabContext>
    </Box>
  );
};
