import React, { FC, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { BurnAssessmentColor } from "./BurnAssessmentColor";
import { BurnQualityCount } from "./BurnQualityCount";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { BarChart } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import { YAxisDefaultConfig } from "shared-ts-mui";

export interface BurnQualityBarChartProps {
  data: BurnQualityCount | undefined;
  isLoading?: boolean;
  width: number;
  height: number;
  sx?: SxProps;
}

export const BurnQualityBarChart: FC<BurnQualityBarChartProps> = (props) => {
  const { data, isLoading, width, height } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");

  const series: MakeOptional<BarSeriesType, "type">[] = [
    {
      data:
        data != null
          ? [data.excellent, data.good, data.bad, data.unknown]
          : undefined,
    },
  ];

  const yAxis: YAxisDefaultConfig[] = useMemo(() => {
    const yAxisData = [
      t("BurnAssessment.Good"),
      t("BurnAssessment.Normal"),
      t("BurnAssessment.Bad"),
    ];

    const colors = [
      BurnAssessmentColor.Good,
      BurnAssessmentColor.Normal,
      BurnAssessmentColor.Bad,
    ];
    if ((data?.unknown ?? 0) > 0) {
      colors.push(BurnAssessmentColor.Unknown);
      yAxisData.push(t("BurnAssessment.Unknown"));
    }
    return [
      {
        scaleType: "band",
        data: yAxisData,
        colorMap: {
          type: "ordinal",
          colors: colors,
        },
        categoryGapRatio: 0.4,
      } as YAxisDefaultConfig,
    ];
  }, [data?.unknown, t]);
  return (
    <>
      {(data?.total ?? 0) === 0 && (
        <Box
          sx={{
            textAlign: "center",
            width: `${width}px`,
            height: `${height}px`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>{t("No data for selected period")}</Typography>
        </Box>
      )}
      {(data?.total ?? 0) > 0 && (
        <BarChart
          layout={"horizontal"}
          margin={{ left: 70, top: 10, bottom: 40, right: 10 }}
          xAxis={[{ max: data?.total ?? 0 }]}
          yAxis={yAxis}
          series={series}
          width={width}
          height={height}
        />
      )}
    </>
  );
};
