import { StringUtils } from "@airmont/shared/ts/utils/core";
import { ChimneySensorStatusQueryInput } from "./ChimneySensorStatusQueryInput";
import {
  BatteryLevelEnum,
  ChimneySensorCableStatusEnum,
  ChimneySensorStatusEnum,
  MunicipalityId,
  PostalCodeId,
  SensorId,
} from "@airmont/firefly/shared/ts/domain";
import { QueryDto, SortItemDto } from "shared/ts/utils/query";

export class ChimneySensorStatusQuery {
  readonly municipality?: MunicipalityId;
  readonly postalCode?: PostalCodeId;
  readonly id?: SensorId;
  readonly search?: string;
  readonly status?: ChimneySensorStatusEnum;
  readonly cableStatus?: ChimneySensorCableStatusEnum;
  readonly batteryLevel?: BatteryLevelEnum;
  readonly sort?: Array<SortItemDto>;

  constructor(args: ChimneySensorStatusQueryInput) {
    this.municipality = StringUtils.notEmptyOrUndef(args.municipality);
    this.postalCode = StringUtils.notEmptyOrUndef(args.zipCode);
    this.id = StringUtils.notEmptyOrUndef(args.id);
    this.search = StringUtils.notEmptyOrUndef(args.search);
    this.status = args.status ?? undefined;
    this.cableStatus = args.cableStatus ?? undefined;
    this.batteryLevel = args.batteryLevel ?? undefined;
    this.sort = args.sort ?? undefined;
  }

  toDto(): ChimneySensorStatusQueryDto {
    return {
      municipality: this.municipality,
      postalCode: this.postalCode,
      search: this.search,
      id: this.id,
      status: this.status,
      cableStatus: this.cableStatus,
      batteryLevel: this.batteryLevel,
      sort: this.sort,
    };
  }
}

export interface ChimneySensorStatusQueryDto extends QueryDto {
  municipality?: MunicipalityId;
  postalCode?: PostalCodeId;
  id?: string;
  search?: string;
  status?: ChimneySensorStatusEnum;
  cableStatus?: ChimneySensorCableStatusEnum;
  batteryLevel?: BatteryLevelEnum;
  retired?: boolean;
  sort?: Array<SortItemDto>;
}
