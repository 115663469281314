import { release_2023_2_1 } from "./release_2023_2_1";
import { release_2023_2_0 } from "./release_2023_2_0";
import { release_2024_1_0 } from "./release_2024_1_0";
import { release_2024_2_0 } from "./release_2024_2_0";
import { Release } from "shared-ts-release";

export const releases: Release[] = [
  release_2024_2_0,
  release_2024_1_0,
  release_2023_2_1,
  release_2023_2_0,
];
