import {useContext} from "react";
import { OidcAuthenticationService } from "./OidcAuthenticationService";
import { OidcContext } from "./OidcProvider";

export const useOidcAuthenticationService = (): OidcAuthenticationService => {
  const context = useContext(OidcContext);
  if(context == null) {
    throw new Error("useOidcAuthenticationService must be used within OidcProvider");
  }
  return context;
}
