import { Visit } from "./Visit";
import { VisitListItem } from "./VisitListItem";
import { SweepTask } from "./SweepTask";
import { Box } from "@mui/material";
import { RenderEventParams } from "@airmont/shared-ts-calendar";
import { Event } from "@airmont/shared-ts-event";
import { VisitCard } from "./VisitCard";
import { ReactNode } from "react";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export const renderEvent = (params: RenderEventParams): ReactNode => {
  const { type, event } = params;

  console.log(`renderEvent: type: ${type} `, event);

  const handleClick = (event: Event) => {
    if (params.clickAction != null) {
      console.log(
        `renderEvent: type: ${type} : handleClick with clickAction: ${params.clickAction}`
      );
      params.onClick?.(event, params.clickAction);
    }
  };
  if (event instanceof Visit) {
    if (params.type === "ListItem") {
      return (
        <VisitListItem key={event.id} event={event} onClick={handleClick} />
      );
    } else if (params.type === "Card") {
      return <VisitCard key={event.id} event={event} />;
    } else {
      throw new IllegalArgumentError("Illegal type: " + type);
    }
  } else if (event instanceof SweepTask) {
    return <Box>Sweep</Box>;
  } else {
    return null;
  }
};
