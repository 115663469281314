import { FC, ReactNode, useMemo } from "react";
import { FlueMetricsDto } from "../FlueMetrics";
import { useFlueMetricsDao } from "../dao/useFlueMetricsDao";
import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { FlueMetricsQuery, FlueMetricsQueryImpl } from "../dao/FlueMetricsQuery";
import { FlueId } from "../../FlueId";
import { FlueMetricsTimeUnitEnum } from "../FlueMetricsTimeUnit";
import { DateTime } from "luxon";

export interface FlueMetricsLoaderProps {
  flueId: FlueId;
  type: Array<FlueMetricsTimeUnitEnum>;
  time?: DateTime;
  children: (
    result: Record<FlueMetricsTimeUnitEnum, UseQueryResult<FlueMetricsDto>>
  ) => ReactNode;
}

export const FlueMetricsLoader: FC<FlueMetricsLoaderProps> = (props) => {
  const flueMetricsDao = useFlueMetricsDao();

  const queries: Array<FlueMetricsQuery> = useMemo(() => {
    const time = props.time;
    return props.type.map((type) => {
      let queryTime: DateTime | undefined = undefined;
      if (type === FlueMetricsTimeUnitEnum.Year) {
        queryTime = time?.startOf("year");
      } else if (type === FlueMetricsTimeUnitEnum.Month) {
        queryTime = time?.startOf("month");
      }
      return new FlueMetricsQueryImpl({
        flueId: props.flueId,
        timeUnit: type,
        time: queryTime,
      });
    });
  }, [props.flueId, props.type, props.time]);

  const queriesResult = useQueries({
    queries: queries.map((query, index) => {
      return {
        queryKey: ["/api/flue-metrics/query", query],
        queryFn: () => flueMetricsDao.query(query),
        select: (data: Array<FlueMetricsDto>) => data[0],
        staleTime: 10 * 60 * 1000,
        gcTime: 15 * 60 * 1000,
      };
    }),
  });

  const result = useMemo(() => {
    const result: Record<string, UseQueryResult<FlueMetricsDto>> = {};
    queriesResult.forEach((queryResult, index) => {
      const key = props.type[index];
      result[key] = queryResult;
    });

    return result;
  }, [props.type, queriesResult]);

  return props.children(result);
};
