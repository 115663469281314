import { DateTime } from "luxon";
import { TimeSeries, TimeSeriesDto, TimeSeriesImpl } from "./TimeSeries";

export class NumberTimeSeriesImpl extends TimeSeriesImpl<number> {
  static fromNumberTimeSeriesDto(
    dto: TimeSeriesDto<number>
  ): TimeSeries<number> {
    return new NumberTimeSeriesImpl({
      info: dto.info,
      points: dto.points.map((point) => {
        return {
          time: DateTime.fromISO(point.time) as DateTime<true>,
          value: point.value,
        };
      }),
    });
  }
}
