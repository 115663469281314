import { RequestHandler, rest } from "msw";
import { ChannelDto } from "./Channel";
import { ContactDto } from "../contact/Contact";

export class ChannelController {
  private readonly channels: Array<ChannelDto>;
  private readonly contacts: Array<ContactDto>;
  private readonly listHandler: RequestHandler;
  private readonly getNonChannelContacts: RequestHandler;
  private readonly addContact: RequestHandler;
  private readonly removeContact: RequestHandler;
  public readonly handlers: RequestHandler[];

  constructor(mocks: {
    channels: Array<ChannelDto>;
    contacts: Array<ContactDto>;
  }) {
    this.channels = mocks.channels;
    this.contacts = mocks.contacts;

    this.listHandler = rest.get("api/channel/list", async (req, res, ctx) => {
      return res(ctx.json(this.channels));
    });

    this.addContact = rest.get(
      "api/channel/add-contact",
      async (req, res, ctx) => {
        return res(ctx.json(this.channels));
      }
    );

    this.removeContact = rest.get(
      "api/channel/remove-contact",
      async (req, res, ctx) => {
        return res(ctx.json(this.channels));
      }
    );

    this.getNonChannelContacts = rest.get(
      "/api/channel/:id/nonchannelcontacts",
      async (req, res, ctx) => {
        const { id } = req.params;
        const channel = this.channels.find((it) => it.id === id);
        if (channel == null) {
          return res(ctx.status(404));
        }

        return res(
          ctx.json(
            this.contacts.filter((c) =>
              channel.contacts.some((it) => it.id === c.id)
            )
          )
        );
      }
    );
    this.handlers = [
      this.listHandler,
      this.addContact,
      this.removeContact,
      this.getNonChannelContacts,
    ];
  }

  cascadeContactDeleted(id: string) {
    this.channels.forEach((channel) => {
      const indexToDelete = channel.contacts.findIndex((it) => it.id === id);
      if (indexToDelete > -1) {
        channel.contacts.splice(indexToDelete, 1);
      }
    });
  }
}
