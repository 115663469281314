import React, { FC, useMemo } from "react";
import {
  LeftRightSideValueHighlight,
  MuiChartContainer,
  RightSideValueHighlight,
  useSxMerge,
} from "shared-ts-mui";
import {
  axisClasses,
  BarPlot,
  ChartsAxisHighlight,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { AllSeriesType } from "@mui/x-charts/models/seriesType";
import { SxProps } from "@mui/system";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import {
  AxisConfig,
  ChartsYAxisProps,
  ScaleName,
} from "@mui/x-charts/models/axis";
import { FlueMetrics } from "../metrics/FlueMetrics";
import { useResolveBurnHourCountSeries } from "./useResolveBurnHourCountSeries";
import { useResolveSootIndexSeries } from "./useResolveSootIndexSeries";
import { useResolveSootIndexCumulativeSeries } from "./useResolveSootIndexCumulativeSeries";
import { LineSeriesType } from "@mui/x-charts/models/seriesType/line";
import { useResolveSootIndexCumulativePredictedSeries } from "./useResolveSootIndexCumulativePredictedSeries";
import { useResolveSootIndexPredictedSeries } from "./useResolveSootIndexPredictedSeries";
import { useResolveBurnHourCountEstimatedSeries } from "./useResolveBurnHourCountEstimatedSeries";
import { ChartsTooltipAxisContent } from "./ChartsTooltipAxisContent";
import { blue, orange, red } from "@mui/material/colors";
import { TimeWheel, useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { LinearProgress, Stack, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Sweeping } from "../../sweeping/SweepingDto";
import { UseQueryResult } from "@tanstack/react-query";
import { MathUtils } from "@airmont/shared/ts/utils/core";

const months: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export interface SootIndexByMonthViewProps {
  loading?: boolean;
  selectedYear: number;
  selectedMonth: number;
  flueMetricsByMonth: Record<string, FlueMetrics>;
  predictedFlueMetricsByMonth: Record<string, FlueMetrics>;
  sweepings: UseQueryResult<Sweeping[], Error>;
  sootIndexThreshold: number;
  hideBurnHours?: boolean;
  sx?: SxProps;
}

export const SootIndexByMonthView: FC<SootIndexByMonthViewProps> = (props) => {
  const {
    selectedYear,
    selectedMonth,
    flueMetricsByMonth,
    predictedFlueMetricsByMonth,
    sweepings,
    sootIndexThreshold,
  } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");
  const loading = props.loading ?? false;
  const theme = useTheme();
  const svgRef = React.useRef<SVGSVGElement>(null);
  const lastYear = selectedYear - 1;
  const displayBurnHours = props.hideBurnHours ?? true;
  const now = useDateTimeNow();

  const datesAxisData = useMemo(() => {
    const startOfYear = DateTime.local(selectedYear) as DateTime<true>;
    const nextYear = startOfYear.plus({ year: 1 });
    return new TimeWheel({
      start: startOfYear,
      duration: { day: 1 },
    }).runWhile(
      (date) => date < nextYear,
      (date) => date.toJSDate()
    );
  }, [selectedYear]);

  const monthsAxisData = useMemo(() => {
    return months.map((month) => {
      return DateTime.local(selectedYear, month).toFormat("MMM yyyy");
    });
  }, [selectedYear]);

  const burnHoursCountSeries = useResolveBurnHourCountSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth
  );
  const burnHourCountEstimatedSeries = useResolveBurnHourCountEstimatedSeries(
    lastYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth
  );

  const sootIndexSeries = useResolveSootIndexSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth
  );

  const sootIndexCumulativeSeries = useResolveSootIndexCumulativeSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth,
    sweepings.data
  );

  const sootIndexEstimatedSeries = useResolveSootIndexPredictedSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    predictedFlueMetricsByMonth
  );

  const sootIndexCumulativePredictedSeries =
    useResolveSootIndexCumulativePredictedSeries({
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      nowYear: now.year,
      nowMonth: now.month,
      flueMetricsByMonth: flueMetricsByMonth,
      predictedFlueMetricsByMonth: predictedFlueMetricsByMonth,
      sootIndexCumulativeSeries: sootIndexCumulativeSeries,
      sweepings: sweepings.data,
    });
  const maxSootIndex = useMemo(() => {
    return sootIndexCumulativePredictedSeries.reduce((a, b) => {
      return Math.max(a ?? 0, b ?? 0);
    }, 0);
  }, [sootIndexCumulativePredictedSeries]);

  const maxSootIndexAxisValue = useMemo(() => {
    const maxA = MathUtils.max(sootIndexCumulativePredictedSeries);
    const maxB = MathUtils.max(sootIndexCumulativeSeries);
    return Math.max(maxA, maxB, props.sootIndexThreshold + 1000);
  }, [
    sootIndexCumulativePredictedSeries,
    sootIndexCumulativeSeries,
    props.sootIndexThreshold,
  ]);

  const burnHoursThisYearSeriesDef: AllSeriesType = {
    id: "burnHours-thisYear",
    type: "bar",
    label: t("Burn Hours") + ", " + selectedYear,
    yAxisId: "burnHours",
    data: burnHoursCountSeries,
    color: blue["500"],
  };
  const burnHoursLastYearSeriesDef: AllSeriesType = {
    id: "burnHours-lastYear",
    type: "bar",
    label: t("Burn Hours, Estimated"),
    yAxisId: "burnHours",
    data: burnHourCountEstimatedSeries,
    color: blue["200"],
  };
  const sootIndexSeriesDef: AllSeriesType = {
    id: "sootIndex",
    type: "bar",
    label: `${t("Soot Index:abbreviation")}, ${selectedYear}`,
    yAxisId: "sootIndex",
    data: sootIndexSeries,
    color: orange["700"],
  };

  const sootIndexEstimatedSeriesDef: AllSeriesType = {
    id: "sootIndex-estimated",
    type: "bar",
    label: t("SI, estimated"),
    yAxisId: "sootIndex",
    data: sootIndexEstimatedSeries,
    color: orange["300"],
  };
  const sootIndexCumulativeSeriesDef: AllSeriesType = {
    id: "sootIndex-cumulative",
    type: "line",
    label: t("SI, cumulative"),
    yAxisId: "sootIndex",
    data: sootIndexCumulativeSeries,
    color: red["500"],
  };
  const sootIndexCumulativePredictedSeriesDef: LineSeriesType = {
    id: "sootIndex-cumulative-estimated",
    type: "line",
    label: t("SI, estimated, cumulative"),
    yAxisId: "sootIndex",
    data: sootIndexCumulativePredictedSeries,
    color: red["200"],
  };

  const series: Array<AllSeriesType> = [];
  series.push(sootIndexCumulativeSeriesDef);
  if (displayBurnHours) {
    series.push(burnHoursThisYearSeriesDef);
    if (now.year === selectedYear) {
      series.push(burnHoursLastYearSeriesDef);
    }
  }
  series.push(sootIndexSeriesDef);
  if (now.year === selectedYear) {
    series.push(sootIndexEstimatedSeriesDef);
    series.push(sootIndexCumulativePredictedSeriesDef);
  }

  const yAxis: MakeOptional<
    AxisConfig<ScaleName, any, ChartsYAxisProps>,
    "id"
  >[] = [];
  if (displayBurnHours) {
    yAxis.push({
      id: "burnHours",
      scaleType: "linear",
      min: 0,
      max: 800,
    });
  }

  yAxis.push({
    id: "sootIndex",
    scaleType: "linear",
    min: 0,
    max: maxSootIndexAxisValue,
  });

  const sx = useSxMerge(props.sx, {
    position: "relative",
  });

  const chartContainerSx = {
    flex: 1,
    minHeight: sx != null && "minHeight" in sx ? sx.minHeight : undefined,
    minWidth: 0,
  } as SxProps;

  return (
    <Stack direction={"column"} sx={sx}>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
      <MuiChartContainer sx={chartContainerSx}>
        <ResponsiveChartContainer
          ref={svgRef}
          margin={{
            top: 20,
            left: 80,
            right: 90,
            bottom: 100,
          }}
          series={series}
          xAxis={[
            {
              id: "months",
              data: monthsAxisData,
              scaleType: "band",
            },
            {
              id: "dates",
              data: datesAxisData,
              scaleType: "band",
            },
          ]}
          yAxis={yAxis}
          sx={
            {
              ".MuiMarkElement-root": {
                scale: "1.2",
              },
              ".MuiLineElement-root": {
                strokeWidth: 5,
                filter: "drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.9))",
              },
              ".MuiLineElement-series-sootIndex-cumulative-estimated": {
                strokeDasharray: "5 5",
              },
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                // Move the y-axis label with CSS
                transform: "translateX(-20px)",
              },
              [`.${axisClasses.right} .${axisClasses.label}`]: {
                // Move the y-axis label with CSS
                transform: "translateX(30px)",
              },
            } as SxProps
          }
        >
          <ChartsLegend
            position={{
              vertical: "bottom",
              horizontal: "middle",
            }}
          />
          {now.year === selectedYear && (
            <ChartsReferenceLine
              axisId={"dates"}
              x={now.toJSDate()}
              label={"Now"}
              labelStyle={{
                fontSize: "smaller",
              }}
              lineStyle={{
                strokeDasharray: "10 5",
                stroke: theme.palette.grey["500"],
              }}
            />
          )}
          {props.sweepings.data?.map((sweeping) => {
            return (
              <ChartsReferenceLine
                axisId={"dates"}
                x={sweeping.time.toJSDate()}
                label={t("Sweep")}
                labelStyle={{
                  fontSize: "smaller",
                }}
                lineStyle={{
                  strokeDasharray: "10 5",
                  stroke: theme.palette.grey["500"],
                }}
              />
            );
          })}
          <ChartsReferenceLine
            y={props.sootIndexThreshold}
            label={t("Need for Sweep at SI {{index}}", {
              index: props.sootIndexThreshold,
            })}
            labelAlign={"start"}
            labelStyle={{
              fontSize: "smaller",
            }}
            lineStyle={{
              strokeDasharray: "10 5",
              stroke: theme.palette.grey["500"],
            }}
            axisId={"sootIndex"}
          />
          <ChartsXAxis label={t("Month")} position="bottom" axisId="months" />
          {displayBurnHours && (
            <ChartsYAxis
              label={"Burn Hours"}
              position="left"
              axisId="burnHours"
            />
          )}
          <ChartsYAxis
            label={t("Soot Index")}
            position="right"
            axisId="sootIndex"
          />
          <BarPlot />
          <LinePlot />
          <MarkPlot />
          <ChartsAxisHighlight x="band" />
          {/*<ChartsXAxis position="top" axisId="dates" />*/}
          {displayBurnHours && (
            <LeftRightSideValueHighlight
              svgRef={svgRef}
              leftAxisScaleId={"burnHours"}
              rightAxisScaleId={"sootIndex"}
            />
          )}
          {!displayBurnHours && (
            <RightSideValueHighlight
              svgRef={svgRef}
              rightAxisScaleId={"sootIndex"}
            />
          )}
          <ChartsTooltip
            trigger={"axis"}
            slotProps={{
              popper: {
                popperOptions: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, 10],
                      },
                    },
                  ],
                },
              },
            }}
            slots={{
              axisContent: ChartsTooltipAxisContent,
            }}
          />
        </ResponsiveChartContainer>
      </MuiChartContainer>
    </Stack>
  );
};
