import { ApiError } from "./ApiError";

export class Uri {
  value: string;

  constructor(value: string) {
    this.value = value;
  }
}

/* Helpers */
export const HttpStatusText: Record<string, string> = {
  "200": "OK",
  "201": "Created",
  "202": "Accepted",
  "203": "Non-Authoritative Information",
  "204": "No Content",
  "205": "Reset Content",
  "206": "Partial Content",
  "300": "Multiple Choices",
  "301": "Moved Permanently",
  "302": "Found",
  "303": "See Other",
  "304": "Not Modified",
  "305": "Use Proxy",
  "306": "Unused",
  "307": "Temporary Redirect",
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Proxy Authentication Required",
  "408": "Request Timeout",
  "409": "Conflict",
  "410": "Gone",
  "411": "Length Required",
  "412": "Precondition Required",
  "413": "Request Entry Too Large",
  "414": "Request-URI Too Long",
  "415": "Unsupported Media Type",
  "416": "Requested Range Not Satisfiable",
  "417": "Expectation Failed",
  "418": "I'm a teapot",
  "429": "Too Many Requests",
  "500": "Internal Server Error",
  "501": "Not Implemented",
  "502": "Bad Gateway",
  "503": "Service Unavailable",
  "504": "Gateway Timeout",
  "505": "HTTP Version Not Supported",
};

export class Api {
  baseurl: string;
  type: string;
  accessToken: string | undefined;
  environment: string | undefined;

  constructor(
    type: string,
    accessToken: string | undefined = undefined,
    environment: string | undefined = undefined
  ) {
    this.type = type;
    this.accessToken = accessToken;
    this.environment = environment;
    this.baseurl = "/api/"; //Configuration.urlApi;
  }

  // Get data from the api for the given type.
  // Passing in an Id will fetch one item by Id.
  // Passing in no Id will fetch all items.
  //
  async getPage(page: number, take: number) {
    let url = this.baseurl + this.type;
    url += "?page=" + page;
    url += "&take=" + take;

    try {
      const headers = await this.createHeaders();

      const response = await fetch(url, {
        headers: headers,
      });
      return this.handleResponse(response);
    } catch (e) {
      console.error("An error occured during a 'getPage'.");
      console.error(e);
      throw e;
    }
  }

  //
  // Get data from the api for the given type.
  // Passing in an Id will fetch one item by Id.
  // Passing in no Id will fetch all items.
  //
  async get(id: any = "", endpoint?: string, forceReturnValue = false) {
    let url = this.baseurl + this.type;

    if (endpoint) url += "/" + endpoint;

    if (id) url += "/" + id;

    try {
      const headers = await this.createHeaders();
      const response = await fetch(url, {
        headers: headers,
      });
      return this.handleResponse(response, forceReturnValue);
    } catch (e) {
      const msg = "An error occured during a 'get'.";
      console.error(msg);
      console.error(e);
      throw e;
    }
  }

  //
  // Handle the web response
  // Checks the content-type and does the necessary actions
  // to check the response content and status.
  //
  handleResponse(response: Response, forceReturnValue = false) {
    // get content type from header
    const contentType = response.headers.get("content-type");

    // any status above 400 is an error
    if (response.status >= 400)
      return response.text().then((body: any) => {
        throw new ApiError(
          response.status,
          response.url,
          response.statusText && response.statusText.length > 0
            ? response.statusText
            : body
            ? body
            : "An " + response.status + " error occured on the server."
        );
      });

    // if the response is a location change, return new url
    if (!forceReturnValue) {
      const location: any = response.headers.get("Location");
      if (location) {
        return new Uri("edit/" + location.split("/").pop());
      }
    }

    // if content type is json, parse and return
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then((data: any) => {
        if (response.status == 201 && data.location) {
          return new Uri("edit/" + data.location.split("/").pop());
        }
        return data;
      });
      // body is not json, check for text content
    } else {
      if (!response.bodyUsed) {
        return response.text().then((text: string) => {
          return { message: text };
        });
      }
      //last resort, it's not an error, just return true.
      return true;
    }
  }

  createHeaders(options?: { encType?: string | undefined | null }): Headers {
    const headers = new Headers();

    if (this.accessToken) {
      headers.append("Authorization", `Bearer ${this.accessToken}`);
    }

    if (options?.encType !== null) {
      headers.append("Content-Type", options?.encType ?? "application/json");
    }

    if (this.environment) {
      headers.append("Firefly-Environment", this.environment);
    }

    return headers;
  }

  post(
    data: any,
    endpoint?: string,
    encType?: string | undefined | null,
    forceReturnValue = false
  ): Promise<any> {
    let url = this.baseurl + this.type;

    if (endpoint) url = url + "/" + endpoint;
    const headers = this.createHeaders({ encType: encType });

    const body =
      encType === undefined || encType === "application/json"
        ? JSON.stringify(data)
        : data;
    return fetch(url, {
      method: "post",
      headers: headers,
      body: body,
    }).then((response) => {
      return this.handleResponse(response, forceReturnValue);
    });
  }

  async getWithBody(data: any, endpoint?: string) {
    let url = this.baseurl + this.type;

    if (endpoint) url = url + "/" + endpoint;

    try {
      const headers = await this.createHeaders();
      const response = await fetch(url, {
        method: "get",
        headers: headers,
        body: JSON.stringify(data),
      });
      return this.handleResponse(response);
    } catch (e) {
      console.error("An error occured during a 'post'.");
      console.error(e);
      return null;
    }
  }

  put(
    data: any,
    id?: any,
    encType?: string | undefined | null,
    forceReturnValue = false
  ): Promise<any> {
    const identifier = id ?? data.id;
    if (!identifier)
      throw new Error("Failed to put data: No identifier provided");

    const url = this.baseurl + this.type + "/" + identifier;

    const headers = this.createHeaders({ encType });
    return fetch(url, {
      method: "put",
      headers: headers,
      body: JSON.stringify(data),
    }).then((response: any) => {
      return this.handleResponse(response, forceReturnValue);
    });
  }

  fetch(
    endpoint: string,
    method = "get",
    body: any = null,
    encType = "application/json"
  ): Promise<any> {
    let url = this.baseurl + this.type;

    if (endpoint) url = url + "/" + endpoint;
    const headers = this.createHeaders();
    const options: any = {
      method: method,
      headers: headers,
    };

    if (body) {
      options.body =
        encType === undefined || encType === "application/json"
          ? JSON.stringify(body)
          : body;
    }

    return fetch(url, options).then((response: any) => {
      return this.handleResponse(response);
    });
  }

  delete(
    id: string,
    endpoint: string | undefined = undefined
  ): Promise<boolean> {
    let url = this.baseurl + this.type;

    if (endpoint) url = url + "/" + endpoint;

    url = url + "/" + id;
    const headers = this.createHeaders();
    return fetch(url, {
      method: "delete",
      headers: headers,
    }).then((response: any) => {
      if (!response.ok) throw response.statusText;
      return true;
    });
  }

  newGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : r & (0x3 | 0x8);
        return v.toString(16);
      }
    );
  }

  emptyGuid(): string {
    return "00000000-0000-0000-0000-000000000000";
  }
}

export interface IPaginationResult<T> {
  page: number;
  recordsPerPage: number;
  totalRecords: number;
  totalPages: number;
  data: T[];
}

export class EntityService<TEntity> extends Api {
  //constructor(type: string) {
  //    super(type);
  //}

  async GetPage(
    page: number,
    take: number
  ): Promise<IPaginationResult<TEntity>> {
    const result = this.getPage(page, take).then(
      (data: any) => data as IPaginationResult<TEntity>
    );
    return result;
  }

  async Get(id: string): Promise<TEntity> {
    const result = this.get(id).then((data: any) => data as TEntity);
    return result;
  }

  async List(): Promise<TEntity[]> {
    const entities = this.get().then((data: any) =>
      data.map((x: TEntity) => x as TEntity)
    );
    return entities;
  }

  async Post(entity: TEntity): Promise<TEntity> {
    const response = this.post(entity).then((data: any) =>
      data.map((x: TEntity) => x as TEntity)
    );
    return response;
  }
}
