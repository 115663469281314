import React, { useCallback, useEffect } from "react";
import { Property, PropertyProps } from "./Property";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { PropertyValue } from "./PropertyValue";

export interface FormikPropertyProps<Values>
  extends Omit<PropertyProps, "name" | "value" | "onFormikFieldValueChange"> {
  name: string;
  formik: {
    values: Values;
    setFieldTouched: (
      field: string,
      touched?: boolean,
      shouldValidate?: boolean
    ) => Promise<void | FormikErrors<Values>>;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => Promise<void | FormikErrors<Values>>;
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
  };
  value?: PropertyValue;
}

export const FormikProperty = <Values,>(
  props: FormikPropertyProps<Values>
): JSX.Element => {
  if (props.name === undefined)
    throw new IllegalArgumentError("name must be defined");
  const formik = props.formik;
  const setFieldValue = props.formik.setFieldValue;
  const setFieldTouched = props.formik.setFieldTouched;
  const name = props.name;
  const onChange = props.onChange;
  const isTouched = (props.formik.touched as any)[name];
  const errorMessage = (props.formik.errors as any)[name];
  let value = props.value;
  if (value === undefined) {
    const valuesIndexed = formik.values as Record<string, PropertyValue>;
    value = valuesIndexed[name] as PropertyValue | undefined;
  }

  useEffect(() => {
    if (props.value === undefined) {
      const valuesIndexed = formik.values as Record<string, PropertyValue>;
      const value = valuesIndexed[name] as PropertyValue | undefined;
      if (value === undefined) {
        throw new IllegalArgumentError(
          `Property[${name}] has no value in formik.values: \n` +
            JSON.stringify(formik.values)
        );
      }
    }
  }, [name, formik.values, props.value]);

  const handleChange = useCallback(
    (value: PropertyValue) => {
      setFieldValue(name, value);
      onChange?.(value, name);
    },
    [setFieldValue, name, onChange]
  );

  const handleBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <Property
      label={props.label}
      name={name}
      variant={props.variant}
      value={value}
      removable={props.removable}
      editComponent={props.editComponent}
      editComponentProps={props.editComponentProps}
      type={props.type}
      multiline={props.multiline}
      unit={props.unit}
      valueOptions={props.valueOptions}
      disableFuture={props.disableFuture}
      durationFormatterOptions={props.durationFormatterOptions}
      onChange={handleChange}
      onValueChange={props.onValueChange}
      onReactSetState={props.onReactSetState}
      onBlur={handleBlur}
      size={props.size}
      fullWidth={props.fullWidth}
      readOnly={props.readOnly}
      mode={props.mode}
      info={props.info}
      autoFocus={props.autoFocus}
      helperText={isTouched ? (errorMessage as string) : undefined}
      error={isTouched && errorMessage != null}
      inputProps={props.inputProps}
      inputRef={props.inputRef}
      sx={props.sx}
    />
  );
};
