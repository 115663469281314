import { AppPage } from "@airmont/shared/ts/ui/app-page";
import React, { useState } from "react";
import { SweepCountLoader } from "@airmont/firefly/chimney-insights/ts/domain";
import { NeedForSweepPanel } from "./NeedForSweepPanel";
import { Box, Stack, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FluesNeedForSweepTable } from "./FluesNeedForSweepTable";
import { useDense } from "@airmont/shared/ts/ui/responsive";
import { Info } from "luxon";

export const NeedForSweepPage = () => {
  const dense = useDense();
  const months = Info.months();
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: tSharedDomain } = useTranslation("firefly-shared-ts-domain");
  const handleYearChange = (year: number | null) => {
    setSelectedYear(year);
    if (year == null) {
      setSelectedMonth(null);
    }
  };
  const handleMonthChange = (month: number | null) => {
    setSelectedMonth(month);
  };
  return (
    <SweepCountLoader selectedYear={selectedYear} selectedMonth={selectedMonth}>
      {({
        numberOfYears,
        overdueSweepCountResult,
        sweepCountAfterPeriodResult,
        upcomingSweepCountsByYearResult,
        repeatingSweepCountsByYearResult,
        upcomingSweepCountsByMonthResult,
        repeatingSweepCountsByMonthResult,
        fluesNeedsSweepResult,
      }) => {
        const isLoading =
          overdueSweepCountResult.isLoading ||
          upcomingSweepCountsByYearResult.isLoading;

        return (
          <AppPage
            $key={"NeedForSweep"}
            name={"Need for Sweep"}
            hideName={true}
            mainPaneProps={{}}
            mainPane={
              <>
                {isLoading && (
                  <Box sx={{ alignSelf: "center", mt: "auto", mb: "auto" }}>
                    {`${t("Inspecting Flues")}...`}
                  </Box>
                )}
                {!isLoading && (
                  <NeedForSweepPanel
                    numberOfYears={numberOfYears}
                    selectedYear={selectedYear}
                    overdueSweepCount={overdueSweepCountResult}
                    sweepCounterAfterPeriod={sweepCountAfterPeriodResult}
                    upcomingSweepCountsByYear={upcomingSweepCountsByYearResult}
                    repeatingSweepCountsByYear={
                      repeatingSweepCountsByYearResult
                    }
                    upcomingSweepCountsByMonth={
                      upcomingSweepCountsByMonthResult
                    }
                    repeatingSweepCountsByMonth={
                      repeatingSweepCountsByMonthResult
                    }
                    fluesNeedsForSweep={fluesNeedsSweepResult}
                    onYearChange={handleYearChange}
                    onMonthChange={handleMonthChange}
                  />
                )}
              </>
            }
            supportingPaneProps={{
              label: t("Flues with Sweeping Needs"),
            }}
            supportingPane={
              <Stack
                direction={"column"}
                useFlexGap
                gap={1}
                sx={{ flexGrow: 1, minWidth: 0, minHeight: 0 }}
              >
                <Toolbar variant={dense ? "dense" : "regular"} disableGutters>
                  {selectedYear == null && (
                    <Typography>{t("No year selected")}</Typography>
                  )}
                  {selectedYear != null && selectedMonth == null && (
                    <Typography>
                      {tSharedDomain("Year")} {selectedYear}
                    </Typography>
                  )}
                  {selectedYear != null && selectedMonth != null && (
                    <Typography>
                      {`${months[selectedMonth - 1]}`.capitalizeFirstLetter()}{" "}
                      {selectedYear}
                    </Typography>
                  )}
                </Toolbar>
                {selectedYear != null && fluesNeedsSweepResult != null && (
                  <FluesNeedForSweepTable
                    rows={fluesNeedsSweepResult}
                    sx={{ flex: 1, minHeight: 0, minWidth: 0 }}
                  />
                )}
              </Stack>
            }
          />
        );
      }}
    </SweepCountLoader>
  );
};
