import {FC} from "react";
import {AppPage} from "@airmont/shared/ts/ui/app-page";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

export const SupervisionPage: FC = (props) => {
  const { t: tDomain } = useTranslation("firefly-shared-ts-domain");


  return (
    <AppPage
      $key={"SupervisionPage"}
      name={tDomain("Supervision")}
      mainPane={
        <Stack direction={"column"} sx={{ flexGrow: 1, minHeight: 0 }}>
          TODO
        </Stack>
      }
    />
  );
};
