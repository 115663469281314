export class RandomUtils {
  /**
   * Get random integer between min and max.
   * @param min inclusive
   * @param max exclusive
   */
  static getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }
}
