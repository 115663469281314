import React, { FC, ReactElement, ReactNode } from "react";
import { ValueCardProps, ValueType } from "./ValueCard";
import Grid from "@mui/material/Unstable_Grid2";
import {
  alpha,
  Box,
  getOverlayAlpha,
  styled,
  Typography,
  useTheme,
} from "@mui/material";

type StyledValueCardsContainerProps = {
  $spacing: (value: number) => string;
  $backgroundColor: string;
  $overlayBackgroundColor: string;
};
export const StyledValueCardsContainer = styled(
  Grid
)<StyledValueCardsContainerProps>`
  padding: ${(props: StyledValueCardsContainerProps) => props.$spacing(1)};
  background-color: ${(props: StyledValueCardsContainerProps) =>
    props.$backgroundColor};
  background-image: linear-gradient(
    ${(props: StyledValueCardsContainerProps) => props.$overlayBackgroundColor},
    ${(props: StyledValueCardsContainerProps) => props.$overlayBackgroundColor}
  );

  &.disableHeaderGutters {
    header {
      margin-top: -${(props: StyledValueCardsContainerProps) => props.$spacing(1)};
      margin-bottom: -${(props: StyledValueCardsContainerProps) => props.$spacing(0.5)};
      padding-left: ${(props: StyledValueCardsContainerProps) =>
        props.$spacing(2)};
      padding-right: ${(props: StyledValueCardsContainerProps) =>
        props.$spacing(2)};
    }
  }
`;

export interface ValueCardsContainerProps {
  label?: ReactNode;
  labelProps?: {
    disableGutters?: boolean;
  };
  valueCards: Array<ReactElement<ValueCardProps<ValueType, ValueType>>>;
  elevation?: number;
  direction?: "row" | "column";
  scrollContent?: boolean;
  minWidth?: number | string;
}

export const ValueCardsContainer: FC<ValueCardsContainerProps> = (props) => {
  const theme = useTheme();
  const elevation = props.elevation ?? 1;
  const overlayBackgroundColor = alpha(
    "#fff",
    getOverlayAlpha(elevation) as unknown as number
  );
  const direction = props.direction ?? "row";
  const scrollContent = props.scrollContent ?? false;

  return (
    <StyledValueCardsContainer
      className={`ValueCardsContainer ${
        props.labelProps?.disableGutters ? "disableHeaderGutters" : ""
      }`}
      $spacing={theme.spacing}
      $backgroundColor={theme.palette.background.paper}
      $overlayBackgroundColor={overlayBackgroundColor}
      container
      direction={"column"}
      sx={{
        minWidth: props.minWidth,
      }}
    >
      {props.label != null && (
        <Grid>
          <Box component={"header"} sx={{ minHeight: 30 }}>
            <Typography color={"text.secondary"} variant={"overline"}>
              {props.label}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid
        xs
        container
        direction={"column"}
        sx={scrollContent ? { position: "relative" } : undefined}
      >
        <Box
          sx={
            scrollContent
              ? {
                  flex: 1,
                  position: "absolute",
                  overflowY: "auto",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "stretch",
                }
              : {
                  flex: 1,
                }
          }
        >
          <Grid
            xs
            container
            justifyContent={"stretch"}
            gap={1}
            direction={direction}
            flexWrap={"nowrap"}
          >
            {props.valueCards.map((vc, index) => {
              const clonedVc = React.cloneElement(vc, {
                layout:
                  vc.props.layout != null
                    ? vc.props.layout
                    : direction === "column"
                    ? "row"
                    : "standard",
                elevation:
                  vc.props.elevation != null
                    ? vc.props.elevation
                    : elevation + 1,
              });

              return (
                <Grid xs={direction === "row"} key={index}>
                  {clonedVc}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </StyledValueCardsContainer>
  );
};
