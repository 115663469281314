export interface BurnQualityCountDto {
  excellent: number;
  good: number;
  bad: number;
  unknown: number;
  total: number;
}

export class BurnQualityCount {
  readonly excellent: number;
  readonly good: number;
  readonly bad: number;
  readonly unknown: number;

  readonly total: number;

  public constructor(dto: BurnQualityCountDto) {
    this.excellent = dto.excellent;
    this.good = dto.good;
    this.bad = dto.bad;
    this.unknown = dto.unknown;
    this.total = dto.total;
  }
}
