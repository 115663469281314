import { useLanguageInternal } from "./useLanguageInternal";

export interface UseLanguageReturnType {
  language: string;
  setLanguage: (value: string) => void;
}

export const useLanguage = (): UseLanguageReturnType => {
  const { language, setLanguage } = useLanguageInternal();
  return { language: language, setLanguage: setLanguage };
};
