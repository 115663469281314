import React, { FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import {
  AuthenticationResultStatus,
  OidcLogoutActions,
  OidcQueryParameterNames,
  useOidcAuthenticationService,
} from "@airmont/firefly/shared/ts/authentication-oidc";
import { useUser } from "shared-ts-utils-authentication";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";

interface ILogoutState {
  message?: string;
  isReady?: boolean;
  authenticated?: boolean;
}

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export interface LogoutProps {
  action: string;
}

export const Logout: FC<LogoutProps> = (props) => {
  const appInfo = useAppInfo();
  const user = useUser();
  const authService = useOidcAuthenticationService();
  const [state, setState] = useState<ILogoutState>({
    message: undefined,
    isReady: false,
    authenticated: false,
  });

  const logout = async (returnUrl: string) => {
    const state = { returnUrl };
    const isActiveSession = user.isSessionActive();
    if (isActiveSession) {
      const result: any = await authService.signOut(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setState({ message: result.message });
          break;
        default:
          throw new Error("Invalid authentication result status.");
      }
    } else {
      setState({ message: "You successfully logged out!" });
    }
  };

  const processLogoutCallback = async () => {
    const url = window.location.href;
    const result: any = await authService.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error("Should not redirect.");
      case AuthenticationResultStatus.Success:
        await navigateToReturnUrl(getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        setState({ message: result.message });
        break;
      default:
        throw new Error("Invalid authentication result status.");
    }
  };

  const populateAuthenticationState = async () => {
    setState({ isReady: true, authenticated: user.isSessionActive() });
  };

  const getReturnUrl = (state: any | null = null) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(OidcQueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}${authService.applicationPaths().LoggedOut}`
    );
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    return window.location.replace(returnUrl);
  };

  useEffect(() => {
    const action = props.action;
    switch (action) {
      case OidcLogoutActions.Logout:
        if (window.history.state.local) {
          logout(getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          setState({
            isReady: true,
            message: "The logout was not initiated from within the page.",
          });
        }
        break;
      case OidcLogoutActions.LogoutCallback:
        processLogoutCallback();
        break;
      case OidcLogoutActions.LoggedOut:
        setState({ isReady: true, message: "You successfully logged out!" });
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }

    populateAuthenticationState();
  }, []);

  const { isReady, message } = state;
  console.log("Logout: action: " + props.action);
  console.log("Logout: isReady: " + isReady);
  console.log("Logout: message: " + message);

  return (
    <NavigationLessAppPage appName={appInfo.name} appIcon={<AirmontLogoImg />}>
      <>
        <Typography>Logging out </Typography>
        <DotsProgress />
        {message != null && <Typography>{message}</Typography>}
      </>
    </NavigationLessAppPage>
  );
};
