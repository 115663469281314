import * as React from "react";
import { FC } from "react";
import { MenuItem, Select } from "@mui/material";
import { useFireflySharedTsDomainTranslation } from "../i18n";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { ChimneySensorCableStatusEnum } from "./ChimneySensorCableStatus";

export interface ChimneySensorCableStatusSelectProps {
  noneOption?: boolean;
  displayEmpty?: boolean;
  selected: ChimneySensorCableStatusEnum | null | undefined;
  onChange: (value: ChimneySensorCableStatusEnum | null) => void;
}

export const ChimneySensorCableStatusSelect: FC<
  ChimneySensorCableStatusSelectProps
> = (props) => {
  const { t } = useFireflySharedTsDomainTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    return props.onChange(
      ChimneySensorCableStatusEnum[
        event.target.value as keyof typeof ChimneySensorCableStatusEnum
      ]
    );
  };

  return (
    <Select
      value={props.selected ?? ""}
      displayEmpty={props.displayEmpty}
      onChange={handleChange}
    >
      {props.noneOption && (
        <MenuItem value="">
          <em>{t("Not set")}</em>
        </MenuItem>
      )}
      <MenuItem value={ChimneySensorCableStatusEnum.Ok}>
        {t(`ChimneySensorCableStatus.${ChimneySensorCableStatusEnum.Ok}`)}
      </MenuItem>
      <MenuItem value={ChimneySensorCableStatusEnum.Shorted}>
        {t(`ChimneySensorCableStatus.${ChimneySensorCableStatusEnum.Shorted}`)}
      </MenuItem>
      <MenuItem value={ChimneySensorCableStatusEnum.Broken}>
        {t(`ChimneySensorCableStatus.${ChimneySensorCableStatusEnum.Broken}`)}
      </MenuItem>
    </Select>
  );
};
