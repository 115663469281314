export interface FetchErrorOptions {
  response?: Response | null;
  request?: RequestInfo | null;
  cause?: Error | null;
}

export class FetchError extends Error {
  cause?: Error | null;
  response: Response | null;
  request: RequestInfo | null;

  constructor(options: FetchErrorOptions) {
    super(FetchError.resolveMessage(options));
    this.cause = options.cause || null;
    this.name = "FetchError";
    this.response = options.response || null;
    this.request = options.request || null;
  }

  static resolveMessage(options: FetchErrorOptions): string {
    let requestAsString = "";

    if (options.request != null) {
      if (typeof options.request == "string") {
        requestAsString = options.request;
      } else if (options.request.url != null) {
        requestAsString = options.request.url;
      }
    } else if (options.response != null) {
      requestAsString = options.response.url;
    }

    const cause = options.cause != null ? `: ${options.cause.message}` : "";
    const httpStatus = `${options.response?.statusText}`;
    return `${httpStatus} : ${requestAsString} ${cause}`;
  }
}
