import { SettingConfig } from "./SettingConfig";
import {
  IllegalArgumentError,
  IllegalStateError,
} from "@airmont/shared/ts/utils/core";

export const IntSetting: SettingConfig<number> = {
  type: "int",
  serialize: (value: number): string => {
    if (isNaN(value)) {
      throw new IllegalArgumentError(
        "Cannot serialize a number not being a number: " + value
      );
    }
    return value.toString();
  },

  deserialize: (value: string): number => {
    const number = parseInt(value);
    if (isNaN(number)) {
      throw new IllegalStateError("Deserialized int is not a number: " + value);
    }
    return number;
  },
};
