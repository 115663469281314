import { Box, useTheme } from "@mui/material";
import { FC } from "react";

export interface ChartsTooltipMarkProps {
  color?: string;
}

export const ChartsTooltipMark: FC<ChartsTooltipMarkProps> = (props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: theme.spacing(1),
        height: theme.spacing(1),
        borderRadius: "50%",
        backgroundColor: props.color,
        borderColor: theme.palette.background.paper,
        border: `solid ${theme.palette.background.paper} ${theme.spacing(
          0.25
        )}`,
        boxSizing: "content-box",
      }}
    ></Box>
  );
};
