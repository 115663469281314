import React, { FC, ReactNode } from "react";
import { Divider, Stack, Typography } from "@mui/material";

interface FilterGroupProps {
  label: ReactNode;
  children?: ReactNode;
}

export const FilterGroup: FC<FilterGroupProps> = (props) => {
  const { label, children } = props;
  return (
    <Stack direction={"column"}>
      <Typography variant={"caption"}>{label}</Typography>
      <Divider />
      <Stack direction={"row"} gap={2} useFlexGap sx={{ pt: 2 }} flexWrap={"wrap"}>
        {children}
      </Stack>
    </Stack>
  );
};
