import { FC } from "react";
import {List, Stack} from "@mui/material";
import { SxProps } from "@mui/system";
import { BurnListItem } from "./BurnListItem";
import { BurnId } from "./Burn";
import { BurnNonSensitiveUi } from "./BurnNonSensitiveUi";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface BurnListProps {
  burns: Array<BurnNonSensitiveUi>;
  multiSelect: boolean;
  hideDuration?: boolean;
  colors?: Array<string>;
  sx?: SxProps;
  layout?: SizeClass;
  hovered?: BurnId;
  selected?: Array<boolean>;
  onHovered?: (burn: BurnId | undefined) => void;
  onSelected?: (burns: Array<boolean>) => void;
  direction?: "row"| "column";
}
export const BurnList: FC<BurnListProps> = (props) => {
  const renderListItems = () => {
    return props.burns.map((burn, burnIndex) => {
      const selected = props.selected?.[burnIndex];
      const hovered = props.hovered === burn.id;
      const handleHover = (hovered: boolean) => {
        if (hovered) {
          props.onHovered?.(burn.id);
        } else {
          props.onHovered?.(undefined);
        }
      };

      const handleSelected = (selected: boolean) => {
        if (props.onSelected != null) {
          const newSelected = props.burns?.map((it, index) =>
            index === burnIndex
              ? selected
              : props.multiSelect
                ? props.selected?.[index] ?? false
                : false
          );
          props.onSelected?.(newSelected);
        }
      };
      return (
        <BurnListItem
          key={burn.id}
          burn={burn}
          hideDuration={props.hideDuration}
          selected={selected}
          hovered={hovered}
          layout={props.layout}
          onHover={handleHover}
          onSelected={handleSelected}
          direction={props.direction}
        />
      );
    })
  }
  return (
    props.direction === "row"
        ? <Stack direction={"row"} style={{overflow: 'scroll'}} > {renderListItems()}</Stack>
        :  <List disablePadding sx={props.sx}>{renderListItems()}</List>
  );
};
