import {
  AuditInfoDto,
  AuditPaneWithPopper,
  ChimneyWasteTypeStringsEnum,
  FlueId,
  FlueMetricsTimeUnitEnum,
  FlueMetricValuesPanel,
  SingleFlueMetricsLoader,
  SweepingDto,
  useSweepingDao,
  WasteAmountUnit,
} from "@airmont/firefly/shared/ts/domain";
import React, { FC, useEffect, useRef, useState } from "react";
import { Box, Card, CardActions, Stack, Typography } from "@mui/material";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { Formik } from "formik";
import { FormikErrors, FormikHelpers } from "formik/dist/types";
import {
  _throw,
  IllegalStateError,
  NullError,
} from "@airmont/shared/ts/utils/core";
import { Button } from "shared-ts-mui";
import { PersistedState } from "./PersistedState";
import { SweepingFormValues } from "./SweepingFormValues";
import { ConfirmationDialog } from "@airmont/shared/ts/ui/dialog";
import {
  SizeClass,
  useComponentSizeFromDense,
} from "@airmont/shared/ts/ui/responsive";
import { useTranslation } from "react-i18next";
import { DuenessProperty } from "./DuenessProperty";
import { ChimneyWasteTypeProperty } from "./ChimneyWasteTypeProperty";
import { DuenessStringsEnum } from "@airmont/shared/ts/types";
import { Property, PropertyMode } from "shared-ts-property";
import { FlameByPctIcon } from "./FlameByPctIcon";
import { CircleQuarterlyByPctIcon } from "./CircleQuarterlyByPctIcon";

export interface SweepingCardProps {
  id?: string;
  flueId: FlueId;
  formValues: SweepingFormValues;
  persistedState: PersistedState;
  layout: SizeClass;
  audit?: AuditInfoDto;
  mode?: PropertyMode;
  onPersisted?: (value: SweepingDto) => void;
  onDelete?: () => void;
  onCancel?: () => void;
}

export const SweepingCard: FC<SweepingCardProps> = (props) => {
  const { t } = useTranslation("firefly-chimney-insights-ts-domain");
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const sweepingDao = useSweepingDao();
  const size = useComponentSizeFromDense();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [mode, setMode] = useState<PropertyMode>(
    props.persistedState === "new" ? "edit" : "read"
  );
  const initialValues = props.formValues;
  const timePropertyInputRef = useRef<HTMLInputElement>(null);

  const validateForm = (values: SweepingFormValues) => {
    const errors: FormikErrors<SweepingFormValues> = {};
    if (values.time == null) {
      errors.time = "Tid er påkrevd";
    }
    if (values.wasteAmountValue != null) {
      if (values.wasteAmountValue < 0) {
        errors.wasteAmountValue = "Sotmengde må være større en 0";
      }
    }
    return errors;
  };

  const handleSubmit = async (
    values: SweepingFormValues,
    formikHelpers: FormikHelpers<SweepingFormValues>
  ) => {
    if (props.persistedState === "new") {
      const created = await sweepingDao.create({
        flueId: props.flueId,
        time: values.time ?? _throw(new NullError("time is required")),
        wasteAmount:
          values.wasteAmountValue != null
            ? {
                value: values.wasteAmountValue,
                unit: WasteAmountUnit,
                type:
                  values.wasteType ??
                  _throw(new NullError("waste type is required")),
                flammablePct: values.flammablePct,
                coveragePct: values.coveragePct,
              }
            : undefined,
        dueness: values.dueness,
      });

      props.onPersisted?.(created);
    } else {
      const updated = await sweepingDao.update({
        id: props.id ?? _throw(new IllegalStateError("id should not be null")),
        time:
          values.time ??
          _throw(new IllegalStateError("time should not be null")),
        wasteAmount:
          values.wasteAmountValue != null
            ? {
                value: values.wasteAmountValue,
                unit: WasteAmountUnit,
                type:
                  values.wasteType ??
                  _throw(new NullError("waste type is required")),
                flammablePct: values.flammablePct,
                coveragePct: values.coveragePct,
              }
            : undefined,
        dueness: values.dueness,
      });
      setMode("read");
      props.onPersisted?.(updated);
    }
  };

  useEffect(() => {
    if (mode === "edit" && timePropertyInputRef.current) {
      timePropertyInputRef.current.focus();
    }
  }, [mode]);

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirmed = () => {
    setDeleteDialogOpen(false);
    props.onDelete?.();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ dirty, values, errors, resetForm, submitForm, setFieldValue }) => {
        const handleCancel = () => {
          resetForm();
          setMode("read");
          props.onCancel?.();
        };

        return (
          <Card sx={{ p: 1 }}>
            <Stack
              direction={props.layout === SizeClass.Compact ? "column" : "row"}
            >
              <PropertiesCard
                header={{
                  title: t("Sweep"),
                }}
                direction={"row"}
                elevation={0}
                sx={{
                  backgroundColor: "inherit",
                  flex: 1,
                  minWidth: 0,
                  minHeight: 0,
                }}
              >
                <Property
                  name={"time"}
                  label={t("Date")}
                  value={values.time}
                  disableFuture={true}
                  type={"date"}
                  mode={mode}
                  helperText={errors.time}
                  inputRef={timePropertyInputRef}
                  onChange={(value) => setFieldValue("time", value)}
                />
                <Property
                  name={"wasteAmountValue"}
                  label={t("Amount of waste")}
                  value={values.wasteAmountValue}
                  type={"number"}
                  unit={WasteAmountUnit}
                  mode={mode}
                  helperText={errors.wasteAmountValue}
                  onChange={(value) => setFieldValue("wasteAmountValue", value)}
                />
                <ChimneyWasteTypeProperty
                  propertyMode={mode}
                  value={values.wasteType}
                  onChange={(n, v) => setFieldValue("wasteType", v)}
                />
                {values.wasteType !== ChimneyWasteTypeStringsEnum.Ash && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Property
                      name={"flammablePct"}
                      label={"% avfall som er brennbart"}
                      info={
                        "Fordeling ikke-brennbart, brennbart, hvor mye av avfallet er brennbart?"
                      }
                      mode={mode}
                      value={values.flammablePct ?? 0}
                      editComponent={"Slider"}
                      editComponentProps={{
                        Slider: {
                          min: 0,
                          max: 100,
                          step: 25,
                        },
                      }}
                      onFormikFieldValueChange={setFieldValue}
                    />
                    <FlameByPctIcon size={30} pct={values.flammablePct ?? 0} />
                  </Stack>
                )}
                <DuenessProperty
                  label={tFireflySharedDomain("Need for Sweep")}
                  info={"Hvor viktig var det å feie nå?"}
                  propertyMode={mode}
                  value={values.dueness}
                  onChange={(n, v) => setFieldValue("dueness", v)}
                />
                {values.dueness === DuenessStringsEnum.NotDue && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Property
                      name={"coveragePct"}
                      label={"% grad av nedsotning"}
                      info={"Hvor langt på vei er det til feiing?"}
                      mode={mode}
                      editComponent={"Slider"}
                      editComponentProps={{
                        Slider: {
                          min: 0,
                          max: 100,
                          step: 25,
                        },
                      }}
                      value={values.coveragePct ?? 0}
                      onFormikFieldValueChange={setFieldValue}
                    />
                    <CircleQuarterlyByPctIcon
                      size={30}
                      pct={values.coveragePct ?? 0}
                      mainColor={"#444"}
                      secondaryColor={"#ddd"}
                    />
                  </Stack>
                )}
              </PropertiesCard>

              {mode === "read" && (
                <SingleFlueMetricsLoader
                  flueId={props.flueId}
                  time={values.time}
                  type={FlueMetricsTimeUnitEnum.AtSweep}
                >
                  {(flueMetrics) => {
                    if (flueMetrics == null) {
                      return (
                        <Box sx={{ p: 2, flex: 1, minHeight: 0, minWidth: 0 }}>
                          <Typography color={"text.disabled"}>
                            {t("No calculations found for this sweep")}
                          </Typography>
                        </Box>
                      );
                    }
                    return (
                      <FlueMetricValuesPanel
                        metrics={flueMetrics.metrics}
                        header={{
                          title: t("Calculations done at sweep"),
                        }}
                        elevation={0}
                        sx={{ flex: 1 }}
                      />
                    );
                  }}
                </SingleFlueMetricsLoader>
              )}
            </Stack>
            <CardActions disableSpacing={true} sx={{ gap: 1 }}>
              {props.onPersisted != null && mode === "read" && (
                <Button variant={"outlined"} onClick={() => setMode("edit")}>
                  Endre
                </Button>
              )}
              {mode === "edit" && (
                <Button
                  variant={"outlined"}
                  disabled={!(dirty || props.persistedState === "new")}
                  type={"submit"}
                  onClick={() => submitForm()}
                >
                  Lagre
                </Button>
              )}
              {mode === "edit" && (
                <Button variant={"text"} onClick={handleCancel}>
                  Angre
                </Button>
              )}
              {props.onDelete != null && mode === "read" && (
                <Button variant={"text"} onClick={handleDelete}>
                  Slett
                </Button>
              )}
              {props.audit != null && (
                <AuditPaneWithPopper
                  audit={props.audit}
                  disablePortal={true}
                  anchorButtonSx={{ marginLeft: "auto" }}
                />
              )}
              <ConfirmationDialog
                open={deleteDialogOpen}
                title={"Slett"}
                message={"Er du sikker på at du vil slette feiingen?"}
                onConfirm={handleDeleteConfirmed}
                onClose={handleDeleteDialogClose}
              />
            </CardActions>
          </Card>
        );
      }}
    </Formik>
  );
};
