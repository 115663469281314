import {
  ActionListItem,
  isAction,
  isActionGroup,
  isDivider,
  isItem,
  isRouteItem,
  isSpacer,
  ItemVariant,
  resolveActionGroupPreferences,
  RouteListItem,
} from "@airmont/shared/ts/ui/action";
import { Box, Divider, List, Paper, useTheme } from "@mui/material";
import { GroupList } from "../shared/group/list/GroupList";
import { GroupMenu } from "../shared/group/menu/GroupMenu";
import React from "react";
import { NavigationComponentProps } from "../NavigationComponentProps";
import { isComponent } from "../shared/Component";
import { useSxMerge } from "shared-ts-mui";
import { Layout } from "../Layout";

const layout: Layout = "medium";
const itemVariant: ItemVariant = "square";
export const NavigationRail = (props: NavigationComponentProps) => {
  const theme = useTheme();
  const variant = props.variant ?? "elevation";
  const paperVariant = variant === "flat" ? "outlined" : variant;

  const sx = useSxMerge(props.sx, {
    minWidth: "80px",
    maxWidth: "80px",
    overflowX: "clip",
    overflowY: "auto",
    ...(variant === "flat" && {
      backgroundColor: "unset",
      transition: "unset",
      border: "unset",
    }),
    ...(variant === "elevation" && {
      ".Item": {
        "&.variant-square": {
          borderRadius: 0,
        },
      },
    }),
  });

  return (
    <Paper
      sx={sx}
      variant={paperVariant}
      style={props.style}
      className={
        `NavigationRail ${variant}` +
        (props.className !== null ? ` ${props.className}` : "")
      }
      classes={props.classes}
    >
      <List
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          ".Spacer": {
            marginTop: "-12px",
            flex: 1,
          },
        }}
      >
        {props.items.map((item, index) => {
          if (item === undefined) {
            return undefined;
          } else if (React.isValidElement(item)) {
            return React.cloneElement(item, { key: index });
          } else if (isComponent(item)) {
            return React.cloneElement(item.render("rail"), { key: index });
          } else if (isItem(item) && item.hide === true) {
            return undefined;
          } else if (isAction(item)) {
            return (
              <ActionListItem
                key={index}
                item={item}
                variant={itemVariant}
                layout={layout}
              />
            );
          } else if (isRouteItem(item)) {
            return (
              <RouteListItem
                key={index}
                item={item}
                variant={itemVariant}
                layout={layout}
                onSelected={props.onSelected}
              />
            );
          } else if (isActionGroup(item)) {
            const preferences = resolveActionGroupPreferences(
              item.preferences,
              layout
            );
            const displayInline =
              preferences?.displayInline != null
                ? preferences.displayInline
                : item.items.length < 4;

            if (displayInline) {
              return (
                <GroupList
                  key={index}
                  item={item}
                  variant={itemVariant}
                  layout={layout}
                  onSelected={props.onSelected}
                  hideLabels={true}
                />
              );
            } else {
              return (
                <GroupMenu
                  key={index}
                  item={item}
                  variant={itemVariant}
                  layout={layout}
                  layoutDirection={"column"}
                  onSelected={props.onSelected}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                />
              );
            }
          } else if (isSpacer(item)) {
            return <Box key={index} className={"Spacer"} />;
          } else if (isDivider(item)) {
            return <Divider key={index} />;
          } else {
            throw new Error("Unsupported type: \n" + item.type);
          }
        })}
      </List>
    </Paper>
  );
};
