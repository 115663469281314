import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { FlueMetrics, FlueMetricsDto } from "../FlueMetrics";
import { FlueMetricsQuery } from "./FlueMetricsQuery";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { FlueId } from "../../FlueId";
import { DateTime } from "luxon";

export class FlueMetricsDao {
  private fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({ basePath: "/api/flue-metrics/" })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  query(query: FlueMetricsQuery): Promise<Array<FlueMetricsDto>> {
    return this.fetchHelper.post("query", query.toDto()).fetchAsJson();
  }
  queryMonthMetricsByYear(
    flueId: FlueId,
    year?: Array<number>
  ): Promise<Array<FlueMetricsDto>> {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    return this.fetchHelper
      .post(`query-month-metrics-by-year`, {
        flueId: flueId,
        timeZone: timeZone,
        year: year,
      })
      .fetchAsJson();
  }

  predictMonthMetricsByYear(flueId: FlueId): Promise<Array<FlueMetricsDto>> {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return this.fetchHelper
      .get(
        `predict-month-metrics-by-year?flueId=${flueId}&timeZone=${timeZone}`
      )
      .fetchAsJson();
  }
}
