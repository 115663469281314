import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import {
  FlueStatusDto,
  FlueStatusGeoFeature,
  flueStatusToGeoFeature,
  groupFeaturesOnSameLocation,
} from "@airmont/firefly/chimney-insights/ts/domain";

import {
  Map,
  MapController,
  MapLoadingProgress,
  useLatLngBounds,
} from "shared-ts-ui-react-google-maps";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { FlueInfoWindow } from "./FlueInfoWindow";
import { FlueStatusClusterPin } from "./FlueStatusClusterPin";
import { FlueStatusPin } from "./FlueStatusPin";
import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import { FlueStatusMapLegend } from "./FlueStatusMapLegend";

export interface FlueStatusMapProps {
  pageUrl: string;
  municipality?: string;
  selected?: string;
  data: Array<FlueStatusDto>;
  width?: number;
  height?: number;
}

export const FlueStatusMap: FC<FlueStatusMapProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [features, setFeatures] = useState<Array<FlueStatusGeoFeature>>([]);

  useEffect(() => {
    const features = groupFeaturesOnSameLocation(
      props.data.map((it) => flueStatusToGeoFeature(it))
    );
    setFeatures(features);
  }, [props.data]);

  const latLngBounds = useLatLngBounds(features);
  const defaultBounds = useMemo(
    () => notUndef(latLngBounds, (it) => it.toJSON()),
    [latLngBounds]
  );
  const defaultCenter = useMemo(
    () => notUndef(latLngBounds, (it) => it.getCenter().toJSON()),
    [latLngBounds]
  );
  const numberOfFluesWithSensors = useMemo(() => {
    let count = 0;
    props.data.forEach((flue) => {
      if (flue.sensorFields?.serialNumber != null) {
        count++;
      }
    });
    return count;
  }, [props.data]);

  const handleView = (flue: FlueStatusDto) => {
    navigate(`${props.pageUrl}/view/${flue.id}`);
  };

  return defaultBounds !== undefined ? (
    <Map
      palette={theme.palette.mode}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
      defaultBounds={defaultBounds}
      backgroundColor={theme.palette.background.default}
      defaultCenter={defaultCenter}
      isFractionalZoomEnabled={false}
    >
      <MapController
        features={features}
        renderFeaturePin={(props) => <FlueStatusPin {...props} />}
        renderInfoWindow={(props) => (
          <FlueInfoWindow {...props} onView={handleView} />
        )}
        renderClusterPin={(props) => (
          <FlueStatusClusterPin
            {...props}
            numberOfFluesWithSensors={numberOfFluesWithSensors}
          />
        )}
      ></MapController>
      <MapControl position={ControlPosition.TOP_LEFT}>
        <FlueStatusMapLegend />
      </MapControl>
    </Map>
  ) : (
    <MapLoadingProgress />
  );
};
