import { FC, ReactNode, useMemo } from "react";
import { FlueMetrics, FlueMetricsDto } from "../FlueMetrics";
import { useFlueMetricsDao } from "../dao/useFlueMetricsDao";
import { useQuery } from "@tanstack/react-query";
import { FlueId } from "../../FlueId";

export interface FlueMonthMetricsByYearPredictedLoaderProps {
  flueId: FlueId;
  year: number;
  children: (
    result: Record<string, FlueMetrics>,
    loading: boolean
  ) => ReactNode;
}

export const PredictedFlueMonthMetricsByYearLoader: FC<
  FlueMonthMetricsByYearPredictedLoaderProps
> = (props) => {
  const flueMetricsDao = useFlueMetricsDao();
  const { data, isLoading } = useQuery({
    queryKey: [
      "flueMetricsDao.predictMonthMetricsByYear",
      props.flueId,
      props.year,
    ],
    queryFn: () => {
      return flueMetricsDao.predictMonthMetricsByYear(props.flueId);
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const result = useMemo(() => {
    const metrics = data?.map((dto: FlueMetricsDto) => new FlueMetrics(dto));
    const result: Record<string, FlueMetrics> = {};
    metrics?.forEach((flueMetric: FlueMetrics) => {
      const key = `${flueMetric.time?.year}-${flueMetric.time?.month}`;
      result[key] = flueMetric;
    });

    return result;
  }, [data]);

  return props.children(result, isLoading);
};
