import React from "react";
import {WindowSizeContext} from "./WindowSizeProvider";
import {WindowSize} from "./WindowSize";

export const useWindowSize = (): WindowSize => {
  const context = React.useContext(WindowSizeContext);
  if (context === undefined) {
    throw new Error("useWindowSize must be used within a WindowSizeProvider");
  }
  return context;
};
