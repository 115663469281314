export interface ValueOption {
  id: string | number;
  label: string;
}

export function isValueOption(obj: unknown): obj is ValueOption {
  return (
    typeof obj === "object" &&
    obj != null &&
    "id" in obj &&
    "label" in obj &&
    typeof obj.label === "string" &&
    (typeof obj.id === "string" || typeof obj.id === "number")
  );
}
