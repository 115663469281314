/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { useCallback } from "react";

export const useYAxisLabelsFormatter = (): ((
  value: number,
  opts?: any
) => string | string[]) => {
  return useCallback((value: number, opts?: any): string | string[] => {
    if (value == null) {
      return "";
    }
    return `${value} °C`;
  }, []);
};
