import { FC, ReactNode, useMemo } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import {
  MunicipalityMetricsQuery,
  MunicipalityMetricsQueryImpl,
} from "./MunicipalityMetricsQuery";
import { MunicipalityMetricsTimeUnitEnum } from "./MunicipalityMetricsTimeUnit";
import { MunicipalityMetricsDto } from "./MunicipalityMetrics";
import { useMunicipalityMetricsDao } from "./useMunicipalityMetricsDao";

import { MunicipalityId } from "./MunicipalityId";

export interface SingleMunicipalityMetricsLoaderProps {
  municipality: MunicipalityId;
  type: MunicipalityMetricsTimeUnitEnum;
  time?: DateTime;
  children: (result: UseQueryResult<MunicipalityMetricsDto>) => ReactNode;
}

export const SingleMunicipalityMetricsLoader: FC<
  SingleMunicipalityMetricsLoaderProps
> = (props) => {
  const dao = useMunicipalityMetricsDao();

  const query: MunicipalityMetricsQuery = useMemo(() => {
    return new MunicipalityMetricsQueryImpl({
      municipality: props.municipality,
      type: props.type,
      time: props.time,
    });
  }, [props.municipality, props.type, props.time]);
  const queryResult = useQuery({
    queryKey: ["api/municipality-metrics/query", query],
    queryFn: () => dao.query(query),
    select: (data: Array<MunicipalityMetricsDto>) => data[0],
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  return props.children(queryResult);
};
