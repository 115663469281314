import { Tooltip, TooltipProps } from "@mui/material";
import { FC, ReactNode } from "react";

export interface TooltipOrNot extends TooltipProps {
  title: ReactNode | undefined;
}

export const TooltipOrNot: FC<TooltipOrNot> = (props) => {
  if (props.title != null) {
    return <Tooltip title={props.title}>{props.children}</Tooltip>;
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return props.children;
  }
};
