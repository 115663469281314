export const RsrqThresholds = {
  max: 0,
  excellent: {
    min: -10,
  },
  good: {
    min: -15,
  },
  weak: {
    min: -20,
  },
  veryLow: {
    min: -25,
  },
  min: -25,
};
export const RsrqLevelDictionary = (value: number) => ({
  excellent: value >= -10,
  good: value >= -15 && value < -10,
  weak: value >= -20 && value < -15,
  veryLow: value < -20,
});
