import { NavigationItemObject } from "./shared/NavigationItemObject";
import {
  isActionGroup,
  isRouteItem,
  RouteItem,
} from "@airmont/shared/ts/ui/action";

export class SelectedRouteItemResolver {
  private found: RouteItem | undefined = undefined;
  private foundPathCount: number | undefined = undefined;
  private foundCharCount: number | undefined = undefined;

  resolve = (
    items: Array<NavigationItemObject>,
    path: string
  ): RouteItem | undefined => {
    for (const item of items) {
      if (item == null) {
        continue;
      }
      const relativePath = path.startsWith("/") ? path.substring(1) : path;
      if (
        isRouteItem(item) &&
        (path.startsWith(item.route) || relativePath.startsWith(item.route))
      ) {
        const newFoundCharCount = item.route.length;
        const newFoundPathCount =
          item.route.match(new RegExp("/", "g"))?.length ?? 0;

        if (this.foundPathCount === undefined) {
          this.found = item;
          this.foundPathCount = newFoundPathCount;
          this.foundCharCount = item.route.length;
        } else {
          if (
            newFoundPathCount >= this.foundPathCount ||
            (this.found?.route === "/" &&
              newFoundCharCount > (this.foundCharCount ?? 0))
          ) {
            this.found = item;
            this.foundPathCount = newFoundPathCount;
            this.foundCharCount = newFoundCharCount;
          }
        }
      } else if (isActionGroup(item)) {
        this.resolve(item.items, path);
      }
    }
    return this.found;
  };
}
