export class StringUtils {
  static isEmpty(str: string | null | undefined): str is string {
    if (str == null) {
      return true;
    }
    return str.length === 0;
  }

  static isNotEmpty(str: string | null | undefined): str is string {
    if (str == null) {
      return false;
    }
    return str.length > 0;
  }

  static notEmptyOrNull(str: string | null | undefined): string | null {
    if (str == null) {
      return null;
    }
    return str.length > 0 ? str : null;
  }

  static notEmptyOrUndef(str: string | null | undefined): string | undefined {
    if (str == null) {
      return undefined;
    }
    return str.length > 0 ? str : undefined;
  }

  static isBlank(str: string | null | undefined): boolean {
    if (str == null) {
      return true;
    }
    return str.length === 0 || /^\s*$/.test(str);
  }

  static isNotBlank(str: string | null | undefined): boolean {
    if (str == null) {
      return false;
    }
    return str.length > 0 || !/^\s*$/.test(str);
  }

  static capitalizeFirstLetter(str: string): string {
    if (str.length === 0) {
      return str;
    } else if (str.length === 1) {
      return str.toUpperCase();
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  static emptyToNull(str: string | null | undefined): string | null {
    if (str == null) {
      return null;
    }
    if (str.length === 0) {
      return null;
    }
    return str;
  }
}
