import React, { FC, useCallback } from "react";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { Button } from "./Button";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
} from "@mui/material";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ThumbDownAltRoundedIcon from "@mui/icons-material/ThumbDownAltRounded";
import { useTranslation } from "react-i18next";

export interface ConfirmationButtonProps {
  actionText: string;
  actionIcon?: React.ReactNode;
  disabled?: boolean;
  layout?: SizeClass;
  onConfirmed: () => void;
  sx?: SxProps;
}

export const ConfirmationButton: FC<ConfirmationButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { actionText, actionIcon, onConfirmed } = props;
  const layout = props.layout ?? SizeClass.Medium;
  const disabled = props.disabled ?? false;
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleInitiated = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleConfirmed = useCallback(() => {
    setAnchorEl(null);
    onConfirmed();
  }, [onConfirmed]);

  const handleCancel = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <Button
        startIcon={actionIcon}
        variant={"text"}
        title={actionText}
        disabled={disabled}
        onClick={handleInitiated}
      >
        {layout !== SizeClass.Compact && actionText}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem title={t("Yes")} onClick={handleConfirmed}>
          <ListItemIcon>
            <ThumbUpAltRoundedIcon />
          </ListItemIcon>
          <ListItemText>{t("Yes")}</ListItemText>
        </MenuItem>
        <MenuItem title={t("No")} onClick={handleCancel}>
          <ListItemIcon>
            <ThumbDownAltRoundedIcon />
          </ListItemIcon>
          <ListItemText>{t("No")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
