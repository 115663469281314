import { Release } from "shared-ts-release";

export const release_2024_1_0: Release = {
  version: "2024.1.0",
  date: "2024-04-26",
  entries: [
    {
      type: "Feature",
      heading: "Forventet utvikling av sot over tid",
      description:
        "På oversiktsbildet for et røykløp kan du nå se utvkling av sotindex over tid og forventet sotindex ved utgangen av året.",
    },
    {
      type: "Feature",
      heading: "Forventet feiedato",
      description:
        "I pipestatus-tabellen, samt på oversiktsbildet for en pipe, er det nå mulig å se forventet feiedato utregnet på tidligere mengde og kvalitet av fyringer.",
    },
    {
      type: "Feature",
      heading: "Vurdering av fyringskvalitet",
      description:
        "I oversiktsbildet for en pipe er det nå mulig å se et sammendrag av fyringer for perioden og fordeling i kvalitet.",
    },
    {
      type: "Improvement",
      heading: "A.I. oppstartskvalitet",
      description: "Funksjonene for å beregne oppstartskvalitet er forbedret.",
    },
    {
      type: "Improvement",
      heading: "A.I. fyringsberegning",
      description: "Funksjonene for å detektere fyringer er forbedret.",
    },
  ],
};
