import {
  BooleanSetting,
  useUserSetting,
} from "@airmont/shared/ts/utils/user-settings";

export const useAnonymizeIssueMonitoringData = () => {
  return useUserSetting<boolean>(
    "anonymizeIssueMonitoringData",
    BooleanSetting
  );
};
