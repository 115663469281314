/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { useCallback } from "react";
import {MathUtils, notUndef} from "@airmont/shared/ts/utils/core";

export const useYAxisLabelsFormatter = (): ((
  value: number,
  opts?: any
) => string | string[]) => {
  return useCallback((value: number, opts?: any): string | string[] => {
    if (value == null) {
      return "";
    }
    return `${notUndef(value, it => MathUtils.round(it))} °C`;
  }, []);
};
