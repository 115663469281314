import React, { FC, ReactNode } from "react";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { PropertyValue } from "./PropertyValue";
import { ValueOptions } from "./ValueOptions";
import { isValueOption } from "./ValueOption";
import { resolvePropertyValueTypeFromValue } from "./resolvePropertyValueTypeFromValue";
import { PropertyValueType } from "./PropertyValueType";
import { ValueUtils } from "./ValueUtils";
import { _throw, IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { useMergeSx } from "./useMergeSx";
import { useOptionLabelResolver } from "./OptionLabelResolver";
import { useTranslation } from "react-i18next";
import { PropertyEditBaseProps } from "./PropertyEditBaseProps";
import {RemoveButton} from "./RemoveButton";
import {usePropertyEditBase} from "./usePropertyEditBase";


export interface PropertyEditAutocompleteProps extends PropertyEditBaseProps {
  valueOptions: ValueOptions;
  unit?: string;
  type?: PropertyValueType;
  autoFocus?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;

}

export const PropertyEditAutocomplete: FC<PropertyEditAutocompleteProps> = (
  props
) => {
  const { t } = useTranslation();
  const { removable, handleRemove } = usePropertyEditBase(props);
  const inputValue = !props.valueOptions.multiple
    ? resolveDisplayValue(props.value)
    : undefined;
  const freeSolo = !props.valueOptions.restrictToOptions;
  const disableClearable =
    props.valueOptions.restrictToOptions === true ?? false;

  const sx = useMergeSx(
    {
      width: props.fullWidth ? "100%" : "25ch",
    },
    props.sx,
    [props.sx, props.fullWidth]
  );

  const isOptionEqualToValue = (option: any, value: any): boolean => {
    if (isValueOption(option)) {
      return option.id === value;
    } else {
      return option === value;
    }
  };

  /*if (!freeSolo) {
    const optionForValue = props.valueOptions.options.find((it) => {
      if (isValueOption(it)) {
        return it.id === props.value;
      } else {
        return it === props.value;
      }
    });

    if (optionForValue == null) {
      throw new Error(
        `Given value [${
          props.value
        }] does not exists in given options: ${JSON.stringify(
          props.valueOptions.options
        )}`
      );
    }
  }*/

  const handleAutocompleteChange = (
    event: React.SyntheticEvent,
    value: any
  ) => {

    if (isValueOption(value)) {
      props.onChange(
        value.id,
        props.name ?? _throw(new Error("name cannot be null"))
      );
    } else {
      props.onChange(
        value,
        props.name ?? _throw(new Error("name cannot be null"))
      );
    }
  };

  /*const handleAutocompleteInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    console.log(
      `PropertyEditAutocomplete[${props.name}]: handleAutocompleteInputChange (reason: ${reason}): `,
      value
    );

    if (reason === "reset" && value === undefined) {
      return;
    }

    if (value === "") {
      props.onChange(
        undefined,
        props.name ?? _throw(new Error("name cannot be null"))
      );
      return;
    }
    const optionForValue = props.valueOptions.options.find((it) => {
      if (isValueOption(it)) {
        return it.label === value;
      } else {
        return it === props.value;
      }
    });

    if (isValueOption(optionForValue)) {
      props.onChange(
        optionForValue.id,
        props.name ?? _throw(new Error("name cannot be null"))
      );
    } else {
      props.onChange(
        value,
        props.name ?? _throw(new Error("name cannot be null"))
      );
    }
  };*/

  const optionLabelResolver = useOptionLabelResolver(props.valueOptions);

  return (
    <Autocomplete
      disablePortal
      disableClearable={disableClearable}
      freeSolo={freeSolo}
      options={props.valueOptions.options}
      multiple={false}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={optionLabelResolver.getOptionLabel}
      value={inputValue}
      onChange={handleAutocompleteChange}
      onBlur={props.onBlur}
      size={props.size}
      fullWidth={props.fullWidth}
      sx={sx}
      renderInput={(params) => {
        if (props.valueOptions.multiple === false) {
          params.inputProps.value = ValueUtils.resolveDisplayValue({
            value: props.value,
            type: props.type ?? resolvePropertyValueTypeFromValue(props.value),
            t: t,
            valueOptions: props.valueOptions,
          });
        }

        return (
          <TextField
            {...params}
            label={props.label}
            error={props.error}
            helperText={props.helperText}
            autoFocus={props.autoFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment:
                params.InputProps.endAdornment || props.unit || props.info || removable ? (
                  <InputAdornment position="end">
                    {props.unit ? props.unit : undefined}
                    {props.info && (
                      <Tooltip title={props.info}>
                        <IconButton size={"small"}>
                          <HelpOutlineRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {params.InputProps.endAdornment}
                    {removable && <RemoveButton onRemove={handleRemove} />}
                  </InputAdornment>
                ) : undefined,
            }}
            inputRef={props.inputRef}
          />
        );
      }}
    />
  );
};

const resolveDisplayValue = (value: PropertyValue): string => {
  if (value == null) {
    return "";
  }
  if (typeof value === "number") {
    return value.toString();
  } else if (typeof value === "boolean") {
    return value.toString();
  } else if (typeof value === "string") {
    return value;
  } else {
    throw new IllegalArgumentError(
      "value type not supported: " + JSON.stringify(value)
    );
  }
};
