import { Property, PropertyProps, ValueOption } from "shared-ts-property";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useI18Next } from "@airmont/shared/ts/utils/i18n";

export interface LanguagePropertyProps
  extends Omit<PropertyProps<string>, "label" | "type" | "valueOptions"> {
  label?: string;
}

export const LanguageProperty: FC<LanguagePropertyProps> = (props) => {
  const { t } = useTranslation("shared-ts-language");
  const name = props.name ?? "language";
  const label = props.label ?? t("Language");
  const { languages } = useI18Next();
  const languagesValueOptions: Array<ValueOption> = useMemo(
    () =>
      languages.map((it) => {
        const language: ValueOption = {
          id: it,
          label: t(it as any),
        };
        return language;
      }),
    [languages, t]
  );

  return (
    <Property
      {...props}
      name={name}
      label={label}
      type={"string"}
      valueOptions={{
        multiple: false,
        restrictToOptions: true,
        options: languagesValueOptions,
      }}
    />
  );
};
