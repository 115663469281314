import { SettingConfig } from "./SettingConfig";
import {
  IllegalArgumentError,
  IllegalStateError,
} from "@airmont/shared/ts/utils/core";

export const FloatSetting: SettingConfig<number> = {
  type: "float",
  serialize: (value: number): string => {
    if (isNaN(value)) {
      throw new IllegalArgumentError(
        "Cannot serialize a number not being a number: " + value
      );
    }
    return value.toString();
  },

  deserialize: (value: string): number => {
    const number = parseFloat(value);
    if (isNaN(number)) {
      throw new IllegalStateError(
        "Deserialized float is not a number: " + value
      );
    }
    return number;
  },
};
