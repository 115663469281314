import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useMunicipalityContext } from "./MunicipalityContextProvider";

export const MunicipalitySelect = () => {
  const { municipalities, municipality, setMunicipality } =
    useMunicipalityContext();

  const handleMunicipalityChange = (event: SelectChangeEvent) => {
    setMunicipality(
      event.target.value === "-1" ? undefined : event.target.value
    );
  };

  return (
    <FormControl size={"small"}>
      <InputLabel>Kommune</InputLabel>
      <Select
        label={"Kommune"}
        value={municipality ?? "-1"}
        onChange={handleMunicipalityChange}
      >
        <MenuItem key={"-1"} value={"-1"}>
          ALLE
        </MenuItem>
        {municipalities.map((it, i) => (
          <MenuItem key={it.id} value={it.id}>
            {it.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
