import { ValueOption } from "shared-ts-property";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useI18Next } from "@airmont/shared/ts/utils/i18n";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

export interface LanguageIconButtonMenuProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

export const LanguageIconButtonMenu: FC<LanguageIconButtonMenuProps> = (
  props
) => {
  const { t } = useTranslation("shared-ts-language");

  const title = props.label;
  const { languages } = useI18Next();
  const languagesValueOptions: Array<ValueOption> = useMemo(
    () =>
      languages.map((it) => {
        const language: ValueOption = {
          id: it,
          label: t(it as any),
        };
        return language;
      }),
    [languages, t]
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleIconButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelected = (selectedLanguage: ValueOption) => {
    props.onChange(selectedLanguage.id as string);
  };

  return (
    <>
      <Tooltip title={title}>
        <IconButton onClick={handleIconButtonClick}>
          <LanguageRoundedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {languagesValueOptions.map((it) => {
          const handleClick = () => {
            handleLanguageSelected(it);
          };
          return (
            <MenuItem
              key={it.id}
              onClick={handleClick}
              selected={it.id === props.value}
            >
              <ListItemText>{it.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
