import { FC, ReactElement, ReactNode, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  useTheme,
} from "@mui/material";
import { DateTime, Interval } from "luxon";
import { CalendarUtils } from "./CalendarUtils";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { CalendarWeek } from "./week/CalendarWeek";
import { useSxMerge } from "shared-ts-mui";
import { Event, EventImpl } from "@airmont/shared-ts-event";
import { ClickAction, RenderEventParams } from "./RenderEventParams";
import { CalendarSettingsProvider } from "./CalendarSettingsProvider";
import { CalendarDay } from "./day/CalendarDay";
import { CalendarWeekBody } from "./week/CalendarWeekBody";
import { CalendarHeader } from "./CalendarHeader";

export type CalendarView = "month" | "week" | "day" | "agenda";
export interface CalendarProps {
  view: CalendarView;
  dateTimeNow: DateTime<true>;
  selectedDate: DateTime<true>;
  events: Array<EventImpl>;
  onDateClick?: (date: DateTime<true>) => void;
  onNewEvent?: (value: Interval<true> | DateTime<true> | null) => ReactElement;
  renderEvent: (params: RenderEventParams) => ReactNode;
  sx?: SxProps;
}
export const Calendar: FC<CalendarProps> = (props) => {
  const { view, dateTimeNow, selectedDate, events } = props;
  const dates = useMemo(() => {
    if (view === "month") {
      return CalendarUtils.generateMonth(selectedDate);
    } else if (view === "week") {
      return CalendarUtils.generateWeek(selectedDate);
    } else if (view === "day") {
      return [selectedDate];
    } else {
      return [];
    }
  }, [view, selectedDate]);
  const datesByWeek = useMemo(
    () => (view === "month" ? ArrayUtils.chunk(dates, 7) : undefined),
    [dates, view]
  );
  const [displayEvent, setDisplayEvent] = useState<Event | null>(null);
  const [displayNewEvent, setDisplayNewEvent] = useState<
    Interval<true> | DateTime<true> | null | undefined
  >(undefined);

  const handleNewEventClick = (
    dateTime: Interval<true> | DateTime<true> | null
  ) => {
    if (props.onNewEvent != null) {
      setDisplayNewEvent(dateTime);
    }
  };

  const handleEventClick = (event: Event, action: ClickAction) => {
    console.log(`CalendarMonth handleEventClick: ${action}: `, event);
    if (action === "view:Card") {
      setDisplayEvent(event);
    }
  };

  const handleRenderEvent = (params: RenderEventParams): ReactNode => {
    return props.renderEvent({ ...params, onClick: handleEventClick });
  };
  const theme = useTheme();
  const sx = useSxMerge(props.sx, {
    alignItems: "stretch",
    border: "1px solid " + theme.palette.divider,
    "> .CalendarWeekBody:not(:last-child)": {
      borderBottom: "1px solid " + theme.palette.divider,
    },
  });

  return (
    <CalendarSettingsProvider>
      {(settings) => (
        <Stack className={"Calendar"} direction={"column"} sx={sx}>
          {view === "month" && datesByWeek != null && (
            <>
              <CalendarHeader
                variant={"events"}
                dateTimeNow={dateTimeNow}
                selectedDate={selectedDate}
                dates={dates}
              />
              {datesByWeek.map((weekDates, index) => {
                return (
                  <CalendarWeekBody
                    key={index}
                    variant={"events"}
                    dateTimeNow={dateTimeNow}
                    selectedDate={selectedDate}
                    dates={weekDates}
                    events={events}
                    sx={{ flexGrow: 1, minHeight: 0, width: "100%" }}
                    hideDayHeader={index > 0}
                    renderEvent={handleRenderEvent}
                    onNewEvent={handleNewEventClick}
                  />
                );
              })}
            </>
          )}
          {view === "week" && (
            <CalendarWeek
              variant={"timeslots"}
              dateTimeNow={dateTimeNow}
              selectedDate={selectedDate}
              dates={dates}
              events={events}
              sx={{ flexGrow: 1, width: "100%" }}
              hideDayHeader={false}
              renderEvent={handleRenderEvent}
              onNewEvent={handleNewEventClick}
            />
          )}
          {view === "day" && (
            <CalendarDay
              date={selectedDate}
              dateTimeNow={dateTimeNow}
              events={events}
              renderEvent={handleRenderEvent}
              onNewEvent={handleNewEventClick}
            />
          )}
          <Dialog
            open={displayNewEvent !== undefined}
            onClose={() => setDisplayNewEvent(undefined)}
          >
            <DialogTitle>New Event</DialogTitle>
            <DialogContent>
              {props.onNewEvent != null &&
                displayNewEvent !== undefined &&
                props.onNewEvent(displayNewEvent)}
            </DialogContent>
          </Dialog>
          <Dialog
            open={displayEvent != null}
            onClose={() => setDisplayEvent(null)}
          >
            <DialogTitle>Event</DialogTitle>
            {displayEvent != null &&
              handleRenderEvent({
                event: displayEvent,
                type: "Card",
              })}
          </Dialog>
        </Stack>
      )}
    </CalendarSettingsProvider>
  );
};
