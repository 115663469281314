import React, { FC, useState } from "react";
import { TabPanel, TabPanelProps } from "@airmont/shared/ts/ui/tabs";
import {
  Box,
  Container,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { CssGridTemplate } from "@airmont/shared/ts/ui/css-grid-template";
import { StringUtils } from "@airmont/shared/ts/utils/core";
import {
  EstimatedSweepDateProperty,
  FireplaceCatalyticProperty,
  FlueMetricsLoader,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { Timeframe, useTimeframe } from "@airmont/shared/ts/ui/timeframe";
import {
  SizeClass,
  useComponentSizeFromDense,
  useDense,
  useWindowSize,
} from "@airmont/shared/ts/ui/responsive";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { DateTime, Info } from "luxon";
import {
  BurnQualityCountByPeriodLoader,
  ChimneyHatProperty,
  FlueHeightProperty,
  FlueMetricsTable,
  FlueStatusDto,
  FlueTypeProperty,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FlueFormValues } from "../FlueFormValues";
import { SootIndexByMonth } from "./SootIndexByMonth";
import { MenuStartButton, ScrollableToolbar, useSxMerge } from "shared-ts-mui";
import { PropertyMode } from "shared-ts-property";
import { DisplayMode } from "shared-ts-app-dialog";
import { BurnQualityChartCard } from "./BurnQualityChartCard";

export interface OverviewTabPanelProps extends TabPanelProps {
  flueStatus: FlueStatusDto;
  mode: DisplayMode;
}

export const OverviewTabPanel: FC<OverviewTabPanelProps> = (props) => {
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const propertyMode: PropertyMode = props.mode === "view" ? "read" : "edit";
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const { windowWidth } = useWindowSize();
  const { values: formValues, setFieldValue: setFormValue } =
    useFormikContext<FlueFormValues>();

  const timeframeProps = useTimeframe({
    disallowFuture: true,
    maxEnd: DateTime.now().startOf("day"),
    allowedUnits: ["month"],
    persistUsingUserSettings: true,
    storageId: "Flue.OverviewTabPanel.",
  });
  const [displayBurnHours, setDisplayBurnHours] = useState<boolean>(false);

  const sx = useSxMerge(props.sx, {
    display: "flex",
    flexDirection: "column",
    pt: 1,
    pb: 1,
  });

  return (
    <TabPanel tabValue={props.tabValue} sx={sx} scrollableY={true}>
      <Container
        maxWidth={"lg"}
        sx={{
          flex: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CssGridTemplate
          className={"Overview"}
          areas={{
            compact: "'k' 's' 'c'",
            medium: "'k c' 's c'",
            expanded: "'k c' 's c'",
            extraLarge: "'k c' 's c'",
          }}
          columns={{
            compact: "auto",
            medium: "auto",
            expanded: "auto",
            extraLarge: "auto",
          }}
          rows={{
            compact: "auto",
            medium: "auto",
            expanded: "auto 1fr",
            extraLarge: "auto 1fr",
          }}
          sx={{
            flex: 1,
            minHeight: 0,
            width: "fit-content",
            ml: "auto",
            mr: "auto",
            gap: 1,
          }}
        >
          <Paper sx={{ gridArea: "k", minHeight: 0 }}>
            <ScrollableToolbar
              ToolbarProps={{
                variant: dense ? "dense" : "regular",
                sx: {
                  paddingRight: 0,
                },
              }}
            >
              <Typography variant={"subtitle1"}>
                {tFireflySharedDomain("Key Figures")}
              </Typography>
              <Box sx={{ maxWidth: "300px", marginLeft: "auto" }}>
                <Timeframe {...timeframeProps} layout={"row"} />
              </Box>
            </ScrollableToolbar>
            <Stack direction={"column"} sx={{ flex: 1, minHeight: 0 }}>
              <FlueMetricsLoader
                flueId={props.flueStatus.id}
                type={[
                  FlueMetricsTimeUnitEnum.Ever,
                  FlueMetricsTimeUnitEnum.SinceSweep,
                  FlueMetricsTimeUnitEnum.Year,
                  FlueMetricsTimeUnitEnum.Month,
                ]}
                time={timeframeProps.timeframe.start}
              >
                {(resultByName) => {
                  return (
                    <FlueMetricsTable
                      metrics={[
                        {
                          label: tFireflySharedDomain("Since Start"),
                          values:
                            resultByName[FlueMetricsTimeUnitEnum.Ever]?.data
                              ?.metrics,
                        },
                        {
                          label: tFireflySharedDomain("Since Last Sweep"),
                          values:
                            resultByName[FlueMetricsTimeUnitEnum.SinceSweep]
                              ?.data?.metrics,
                        },
                        {
                          label: `${timeframeProps.timeframe.start.year}`,
                          values:
                            resultByName[FlueMetricsTimeUnitEnum.Year]?.data
                              ?.metrics,
                        },
                        {
                          label: `${StringUtils.capitalizeFirstLetter(
                            Info.months()[
                              timeframeProps.timeframe.start.month - 1
                            ]
                          )} ${timeframeProps.timeframe.start.year}`,
                          values:
                            resultByName[FlueMetricsTimeUnitEnum.Month]?.data
                              ?.metrics,
                        },
                      ]}
                      layout={windowWidth}
                      sx={{
                        p: windowWidth === SizeClass.Compact ? 0 : 1,
                        minHeight: "fit-content",
                      }}
                    />
                  );
                }}
              </FlueMetricsLoader>
            </Stack>
          </Paper>
          <Paper
            sx={{
              gridArea: "s",
              display: "flex",
              flexDirection: "column",
              minHeight: "fit-content",
              mb: 1,
            }}
          >
            <Toolbar variant={dense ? "dense" : "regular"}>
              <Typography
                variant={"subtitle1"}
                sx={{ flexGrow: 1, minWidth: "fit-content" }}
              >
                {tFireflySharedDomain(
                  "The development of the soot index (SI) for"
                )}{" "}
                {timeframeProps.timeframe.start.year}
              </Typography>
              <MenuStartButton
                ButtonProps={{
                  size: componentSize,
                  startIcon: <MoreVertRoundedIcon />,
                }}
                menu={(open, anchorEl, onClose, setDisabled) => (
                  <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
                    <MenuItem
                      selected={displayBurnHours}
                      onClick={() => setDisplayBurnHours((prev) => !prev)}
                    >
                      <ListItemIcon>
                        {displayBurnHours ? (
                          <CheckBoxOutlinedIcon />
                        ) : (
                          <CheckBoxOutlineBlankRoundedIcon />
                        )}
                      </ListItemIcon>
                      {tFireflySharedDomain("Show Burn Hours")}
                    </MenuItem>
                  </Menu>
                )}
              />
            </Toolbar>
            <SootIndexByMonth
              flueId={props.flueStatus.id}
              lastSweep={
                props.flueStatus.flue.lastSweepDate ??
                props.flueStatus.audit.created
              }
              selectedYear={timeframeProps.timeframe.start.year}
              selectedMonth={timeframeProps.timeframe.start.month}
              hideBurnHours={displayBurnHours}
              sootIndexThreshold={4000}
              sx={{
                flex: 1,
                minHeight: "350px",
                minWidth: 0,
              }}
            />
          </Paper>
          <Stack direction={"column"} useFlexGap gap={1} sx={{ gridArea: "c" }}>
            <PropertiesCard
              header={{
                title: tFireflySharedDomain("Combustion Plant"),
              }}
              childPropertyProps={{
                fullWidth: true,
              }}
            >
              <ChimneyHatProperty
                mode={propertyMode}
                value={formValues.chimneyHat}
                onFormikFieldValueChange={setFormValue}
              />
              <FlueTypeProperty
                mode={propertyMode}
                value={formValues.flueType}
                onFormikFieldValueChange={setFormValue}
              />
              <FlueHeightProperty
                mode={propertyMode}
                value={formValues.flueHeight}
                onFormikFieldValueChange={setFormValue}
              />
              <FireplaceCatalyticProperty
                mode={propertyMode}
                value={formValues.fireplaceCatalytic}
                onFormikFieldValueChange={setFormValue}
              />
              <EstimatedSweepDateProperty
                value={props.flueStatus.flue.estimatedSweepDate}
              />
            </PropertiesCard>
            <BurnQualityCountByPeriodLoader
              flueId={props.flueStatus.id}
              start={timeframeProps.timeframe.start}
              end={timeframeProps.timeframe.end}
            >
              {(loadResult) => {
                return (
                  <BurnQualityChartCard
                    result={loadResult}
                    sx={{ minHeight: "fit-content" }}
                  />
                );
              }}
            </BurnQualityCountByPeriodLoader>
          </Stack>
        </CssGridTemplate>
      </Container>
    </TabPanel>
  );
};
