import { SizeClass } from "./SizeClass";

export class SizeClassComparer {
  private static sizeByValue: Record<string, number> = {
    Compact: 1,
    Medium: 2,
    Expanded: 3,
    ExtraLarge: 4,
  };
  public readonly sizeClass: SizeClass;

  constructor(sizeClass: SizeClass) {
    if (sizeClass == null) {
      throw new Error("SizeClassComparer expects a SizeClass");
    }
    this.sizeClass = sizeClass;
  }

  eq(other: SizeClass): boolean {
    return this.sizeClass === other;
  }

  gt(other: SizeClass): boolean {
    const leftValue = SizeClassComparer.sizeByValue[this.sizeClass];
    const rightValue = SizeClassComparer.sizeByValue[other];
    return leftValue > rightValue;
  }

  gte(other: SizeClass): boolean {
    const leftValue = SizeClassComparer.sizeByValue[this.sizeClass];
    const rightValue = SizeClassComparer.sizeByValue[other];
    return leftValue >= rightValue;
  }

  lt(other: SizeClass): boolean {
    const leftValue = SizeClassComparer.sizeByValue[this.sizeClass];
    const rightValue = SizeClassComparer.sizeByValue[other];
    return leftValue < rightValue;
  }

  lte(other: SizeClass): boolean {
    const leftValue = SizeClassComparer.sizeByValue[this.sizeClass];
    const rightValue = SizeClassComparer.sizeByValue[other];
    return leftValue <= rightValue;
  }
}
