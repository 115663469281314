import {PropertyEditBaseProps} from "./PropertyEditBaseProps";


export interface UsePropertyEditBaseResult {
  removable: boolean;
  handleRemove: () => void;
}
export const usePropertyEditBase = (props: PropertyEditBaseProps) : UsePropertyEditBaseResult => {
  const removable = props.removable ?? false;
  const handleRemove = () => {
    props.onChange(undefined, props.name);
  };

  return {
    removable: removable,
    handleRemove: handleRemove,
  }
}
