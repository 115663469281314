import {
  DataGridPremium,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { FC, useMemo, useState } from "react";
import { useQueryAndDisplayRowsProvider } from "shared/ts/ui/x-data-grid";
import { BurnId, BurnNonSensitiveDto } from "@airmont/firefly/shared/ts/domain";
import { DataGridFooterWithToolbar, useMuiLocalization } from "shared-ts-mui";
import { LinearProgress } from "@mui/material";
import {
  AbnormalBurnsQueryDto,
  useAbnormalBurnsQueryClient,
} from "@airmont/firefly/chimney-insights/ts/domain";
import {
  _throw,
  IllegalStateError,
  MathUtils,
  notNullOrUndefined,
  notUndef,
} from "@airmont/shared/ts/utils/core";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Duration, Info } from "luxon";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface AbnormalBurnsTableProps {
  query: AbnormalBurnsQueryDto;
  forceRefresh: number;
  rows?: Array<BurnNonSensitiveDto>;
  onRowsChange: (rows: Array<BurnNonSensitiveDto>) => void;
  onSelectRows?: (rowIds: Array<BurnId>) => void;
  onViewRequest?: (rows: Array<BurnNonSensitiveDto>) => void;
  sx?: SxProps;
}

export const AbnormalBurnsTable: FC<AbnormalBurnsTableProps> = (props) => {
  const { muiLocalization } = useMuiLocalization();
  const columns = useTableColumns();
  const queryClient = useAbnormalBurnsQueryClient();
  const [scrollEndThreshold] = useState<number>(80);
  const {
    apiRef,
    rows,
    totalRows,
    loading,
    selectionModel: [selectionModel],
    sortModel: [sortModel],
    columnVisibilityModel,
    onRowSelectionModelChange,
    onRowsScrollEnd: handleRowsScrollEnd,
    onSortModelChange: handleSortModelChange,
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
  } = useQueryAndDisplayRowsProvider<BurnNonSensitiveDto>({
    tableId: "AbnormalBurnsTable",
    query: props.query,
    queryClient: queryClient,
    rows: props.rows,
    forceRefresh: props.forceRefresh,
    defaultColumnVisibilityModel: {},
  });

  const handleRowSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    onRowSelectionModelChange(selectionModel);
    const selectedRowIds = selectionModel as Array<BurnId>;
    props.onSelectRows?.(selectedRowIds);
    if (rows != null && rows.length > 0) {
      const selectedRows = notUndef(rows, (rows) =>
        selectedRowIds.map((selectedRowId) => {
          return (
            rows?.find((row) => row.id === selectedRowId) ??
            _throw(
              new IllegalStateError(
                "Unable to find row with id: " + selectedRowId
              )
            )
          );
        })
      );
      if (selectedRows != null) {
        props.onViewRequest?.(selectedRows);
      }
    }
  };

  return (
    <DataGridPremium
      localeText={
        muiLocalization.components.MuiDataGrid.defaultProps.localeText
      }
      apiRef={apiRef}
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      columnGroupingModel={[
        {
          groupId: "Fyring",
          children: [
            { field: "id" },
            { field: "year" },
            { field: "month" },
            { field: "burn.durationInMillis" },
            { field: "analytics.startQuality" },
            { field: "completed" },
            { field: "selfSimilarity" },
            { field: "temperatureMax" },
          ],
        },
        {
          groupId: "Anslått røykløpstype",
          children: [
            { field: "burn.analytics.predictedFlueType" },
            { field: "burn.analytics.predictedFlueTypeConfidence" },
          ],
        },
        {
          groupId: "Temperatursvigninger",
          children: [
            { field: "metrics.deltaTemperature" },
            { field: "metrics.deltaTemperatureMinute" },
          ],
        },
      ]}
      loading={loading}
      rowCount={totalRows}
      rows={rows}
      sortModel={sortModel}
      scrollEndThreshold={scrollEndThreshold}
      onRowsScrollEnd={handleRowsScrollEnd}
      onSortModelChange={handleSortModelChange}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      slots={{
        loadingOverlay: LinearProgress as never,
        footer: DataGridFooterWithToolbar,
      }}
      slotProps={{
        footer: {
          rowCount: rows.length,
          totalRowCount: totalRows,
          loading: loading,
        },
      }}
      sx={props.sx}
    />
  );
};

const useTableColumns = (): Array<GridColDef<BurnNonSensitiveDto>> => {
  const { t: tDomain } = useTranslation("firefly-shared-ts-domain");
  return useMemo(() => {
    const columns: Array<GridColDef<BurnNonSensitiveDto>> = [
      {
        field: "id",
        headerName: "Id",
        width: 80,
        pinnable: false,
      },
      {
        field: "year",
        headerName: "År",
        width: 80,
        pinnable: false,
      },
      {
        field: "month",
        headerName: "Måned",
        width: 80,
        pinnable: false,
        valueGetter: (value, row) => {
          return Info.months()[row.month - 1].capitalizeFirstLetter();
        },
      },
      {
        field: "burn.durationInMillis",
        headerName: tDomain("Duration"),
        pinnable: false,
        align: "right",
        valueGetter: (value, row) => {
          return row.metrics.burnHourCount;
        },
        renderCell: (params) => {
          return (
            <DurationDisplay
              duration={Duration.fromMillis(params.row.durationInMillis)}
              maxUnits={1}
              shiftTo={["hours"]}
              layout={SizeClass.Compact}
              sx={{ fontSize: "inherit", lineHeight: "inherit" }}
            />
          );
        },
      },
      {
        field: "completed",
        headerName: tDomain("Completed"),
        pinnable: false,
        align: "center",
        valueGetter: (_, row) => {
          return row.completed ? tDomain("Yes") : tDomain("No");
        },
      },
      {
        field: "selfSimilarity",
        headerName: tDomain("Self Similarity"),
        pinnable: false,
        align: "right",
        valueGetter: (_, row) => {
          return notUndef(row.analytics.selfSimilarity, (value) =>
            MathUtils.round((value as number) * 100)
          );
        },
        valueFormatter: (value) =>
          notNullOrUndefined(value, (value) => value + " %"),
      },
      {
        field: "temperatureMax",
        headerName: tDomain("Max Temperature"),
        pinnable: false,
        align: "right",
        valueGetter: (value, row) => {
          return row.metrics.temperatureMax;
        },
        valueFormatter: (value) => value + " C°",
      },
      {
        field: "analytics.startQuality",
        headerName: tDomain("Ignition"),
        width: 100,
        pinnable: false,
        valueGetter: (value, row) => {
          return tDomain(`BurnAssessment.${row.analytics.startQuality}`);
        },
      },
      {
        field: "burn.analytics.predictedFlueType",
        headerName: `${tDomain("Predicted")} ${tDomain(
          "Flue Type"
        ).toLocaleLowerCase()}`,
        width: 150,
        pinnable: false,
        valueGetter: (value, row) => {
          return tDomain(`FlueType.${row.analytics.predictedFlueType}`);
        },
      },
      {
        field: "burn.analytics.predictedFlueTypeConfidence",
        headerName: `Treffsikkerhet`,
        pinnable: false,
        valueGetter: (value, row) =>
          MathUtils.round(row.analytics.predictedFlueTypeConfidence * 100),
        valueFormatter: (value) => `${value} %`,
      },
      {
        field: "metrics.deltaTemperature",
        headerName: "Delta temperatur",
        pinnable: false,
        valueGetter: (value, row) => row.metrics.deltaTemperature,
      },
      {
        field: "metrics.deltaTemperatureMinute",
        headerName: "Delta temperatur per min",
        pinnable: false,
        valueGetter: (value, row) =>
          MathUtils.round(row.metrics.deltaTemperatureMinute, 1),
      },
    ];
    return columns;
  }, [tDomain]);
};
