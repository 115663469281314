import { Property, PropertyProps } from "shared-ts-property";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { Stack, Typography } from "@mui/material";

export interface CConsentToMonitorIssuesPropertyProps
  extends Omit<
    PropertyProps<boolean>,
    "name" | "value" | "onChange" | "label" | "type"
  > {
  name: string;
  value: boolean | null | undefined;
}

export const ConsentToMonitorIssuesProperty: FC<
  CConsentToMonitorIssuesPropertyProps
> = (props) => {
  const { t } = useTranslation("shared-ts-sentry");
  return (
    <Property
      {...props}
      label={t("I consent to the monitoring of ...")}
      type={"boolean"}
      info={
        <Stack useFlexGap gap={2}>
          <Typography>{t("I consent to the monitoring of ...")}</Typography>
        </Stack>
      }
      mode={props.mode ?? "edit"}
    />
  );
};
