import { Expr } from "../../Expr";

export interface EndsWithXpr {
  $type: "endsWith";
  instance: Expr;
  arg: Expr;
}

export const endsWithExpr = (instance: Expr, arg: Expr): EndsWithXpr => ({
  $type: "endsWith",
  instance: instance,
  arg: arg,
});
