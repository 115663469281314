import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export const useConsentToMonitorIssuesWithNullAsDefault = () => {
  return useUserSettingWithDefault<boolean | null>(
    "consentToMonitorIssues",
    BooleanSetting,
    null
  );
};
