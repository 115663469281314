import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export const useConsentToCollectUsageDataWithNullAsDefault = () => {
  return useUserSettingWithDefault<boolean | null>(
    "consentToCollectUsageData",
    BooleanSetting,
    null
  );
};
