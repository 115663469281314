import { useMemo } from "react";
import {
  FlueStatusQuery,
  FlueStatusQueryDto,
  FlueStatusQueryInput,
} from "./FlueStatusQuery";

export const useFlueStatusQuery = (
  input: FlueStatusQueryInput
): FlueStatusQueryDto => {
  return useMemo(
    () => new FlueStatusQuery(input).toDto(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(input)
  );
};
