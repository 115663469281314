import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import i18n, { FallbackLng } from "i18next";
import { initReactI18next } from "react-i18next";
import { I18NextResourceLoader } from "./I18NextResourceLoader";

let initCount = 0;

export type I18NextContextType = {
  languages: Array<string>;
};

const I18NextContext = React.createContext({} as I18NextContextType);

export interface I18NextProviderProps {
  language?: string;
  languages: Array<string>;
  fallbackLng?: false | FallbackLng;
  debug?: boolean;
  resources: Array<{ namespace: string; loader: Promise<any> }>;
  children?: ReactNode;
}

export const I18NextProvider: FC<I18NextProviderProps> = (props) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const doEffect = async () => {
      if (initCount === 0) {
        await i18n.use(initReactI18next).init(
          {
            lng: props.language,
            fallbackLng: props.fallbackLng,
            debug: true,
            interpolation: {
              escapeValue: false,
            },
            resources: {},
          },
          () => {
            // init finished
          }
        );
        await new I18NextResourceLoader(i18n, props.languages).load(
          props.resources
        );

        setInitialized(true);
      } else if (initCount > 2) {
        console.warn("I18NextProvider mounted more than twice");
      }

      initCount++;
    };
    doEffect();

    return () => {
      console.log("I18NextProvider umounted...");
    };
  }, [props.language, props.fallbackLng, props.languages, props.resources]);

  const contextValue: I18NextContextType = useMemo(() => {
    return { languages: props.languages };
  }, [props.languages]);

  if (!initialized || !i18n.isInitialized) {
    return null;
  }

  return (
    <I18NextContext.Provider value={contextValue}>
      {props.children}
    </I18NextContext.Provider>
  );
};

export const useI18Next = (): I18NextContextType => {
  const context = useContext(I18NextContext);
  if (context == null) {
    throw new Error("useI18Next must be used within I18NextProvider");
  }
  return context;
};
