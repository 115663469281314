import { FC, ReactNode, useMemo } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime, DateTimeUnit } from "luxon";
import {
  BurnQualityCountAggregateByTime,
  BurnQualityCountAggregateByTimeDto,
} from "./BurnQualityCountAggregateByTime";
import { BurnQualityCountAggregateQuery } from "./BurnQualityCountAggregateQuery";
import { FlueId } from "../../chimney/flue/FlueId";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { TimeWheel } from "@airmont/shared/ts/utils/luxon";

export interface BurnQualityCountAggregateByTimeLoaderProps {
  start?: DateTime;
  end?: DateTime;
  aggregateBy: DateTimeUnit;
  flueId?: FlueId;
  children: (
    result: UseQueryResult<Array<BurnQualityCountAggregateByTime>>
  ) => ReactNode;

  queryBurnQualityCountAggregate(
    query: BurnQualityCountAggregateQuery
  ): Promise<Array<BurnQualityCountAggregateByTimeDto>>;
}

export const BurnQualityCountAggregateByTimeLoader: FC<
  BurnQualityCountAggregateByTimeLoaderProps
> = (props) => {
  const { flueId, aggregateBy } = props;
  const start = props.start?.startOf(aggregateBy);
  const end = props.end?.endOf(aggregateBy).plus({ millisecond: 1 });
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const query: BurnQualityCountAggregateQuery = useMemo(() => {
    const queryStartTime = start?.toUTC().toISO();
    const queryEndTime = end?.toUTC().toISO();
    return {
      timeZone: timeZone,
      flueId: flueId,
      start: queryStartTime,
      end: queryEndTime,
      aggregateOnTimeUnit: aggregateBy,
    } as BurnQualityCountAggregateQuery;
  }, [start, end, flueId, aggregateBy, timeZone]);

  const queryResult = useQuery({
    queryKey: [
      "BurnQualityCountAggregateByTimeLoader.getBurnQualityCountByPeriod",
      query,
    ],
    queryFn: () => props.queryBurnQualityCountAggregate(query),
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const result = useMemo(() => {
    const returnedData: Array<BurnQualityCountAggregateByTime> = [];
    const loadedData = queryResult.data?.map((it) => {
      return new BurnQualityCountAggregateByTime(it);
    });
    if (loadedData != null) {
      const timeWheelStart = start ?? loadedData[0].time;
      const timeWheelEnd = end ?? loadedData[loadedData.length - 1].time;
      new TimeWheel({
        start: timeWheelStart,
        timeUnit: aggregateBy,
      }).runUntilTime(timeWheelEnd, (dateTime) => {
        const dateTimeAsUtc = dateTime.toUTC();
        const index = loadedData?.findIndex((it) =>
          it.time.equals(dateTimeAsUtc)
        );
        if (index === -1) {
          returnedData.push(
            new BurnQualityCountAggregateByTime({
              time: dateTimeAsUtc.toISO() as DateTimeISO,
              qualityCount: {
                unknown: 0,
                bad: 0,
                good: 0,
                excellent: 0,
                total: 0,
              },
            })
          );
        } else {
          returnedData.push(loadedData[index]);
        }
      });
    }
    return {
      ...queryResult,
      data: returnedData,
    } as UseQueryResult<Array<BurnQualityCountAggregateByTime>>;
  }, [aggregateBy, end, queryResult, start]);
  return props.children(result);
};
