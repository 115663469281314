import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Button, LinearProgress, Toolbar } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useMuiLocalization } from "shared-ts-mui";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { useQueryClient } from "@tanstack/react-query";
import { SettingsGroup } from "../shared/SettingsGroup";
import { SettingsInfo } from "../shared/SettingsInfo";
import {
  GridInputRowSelectionModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid/models/gridRowSelectionModel";
import { getGridRowActions } from "../shared/GridRowActions";
import { GridRowPersistedState } from "../shared/GridRowPersistedState";
import AddIcon from "@mui/icons-material/Add";
import {
  _throw,
  IllegalStateError,
  NotFoundError,
} from "@airmont/shared/ts/utils/core";
import {
  ContactDto,
  ContactId,
  useChannelDao,
  useContactDao,
} from "@airmont/firefly/shared/ts/domain";
import { useNotify } from "@airmont/shared/ts/ui/notification";
import { useUser } from "shared-ts-utils-authentication";
import { useTranslation } from "react-i18next";

interface ChimneyFireContactsRow extends GridRowPersistedState {
  id: number;
  contact?: ContactId;
}

export const ChimneyFireSettingsPane: FC = () => {
  const { muiLocalization } = useMuiLocalization();
  const { t } = useTranslation("firefly-chimney-insights-ts-settings");
  const { t: tSharedDomain } = useTranslation("firefly-shared-ts-domain");
  const notify = useNotify();
  const user = useUser();
  const hasAdminRole = user.hasRole("Admin");
  const gridApiRef = useGridApiRef();
  const contactDao = useContactDao();
  const channelDao = useChannelDao();
  const queryClient = useQueryClient();
  const [chimneyFireChannelId, setChimneyFireChannelId] = useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [allContacts, setAllContacts] = useState<Array<ContactDto>>([]);
  const [rows, setRows] = useState<Array<ChimneyFireContactsRow>>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const unAddedContacts = useMemo(() => {
    return allContacts.filter(
      (it) => !rows.some((row) => row.contact === it.id && row.isNew !== true)
    );
  }, [allContacts, rows]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [selectionModel, setSelectionModel] =
    useState<GridInputRowSelectionModel>([]);
  const contactValueOptions = useMemo(
    () => allContacts.map((it) => ({ value: it.id, label: it.name })),
    [allContacts]
  );

  const columns: Array<GridColDef<ChimneyFireContactsRow>> = [
    {
      field: "contact",
      flex: 1,
      headerName: t("Contact"),
      type: "singleSelect",
      editable: true,
      valueOptions: contactValueOptions,
    },
    {
      field: "mobile",
      headerName: t("Mobile"),
      editable: false,
      valueGetter: (value, row) =>
        allContacts.find((it) => it.id === row.contact)?.mobile,
    },
  ];
  if (hasAdminRole) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: t("Actions"),
      cellClassName: "actions",
      getActions: (params) => {
        const disableEdit =
          (rowModesModel[params.id]?.mode ?? GridRowModes.View) ===
          GridRowModes.View;

        return getGridRowActions<ChimneyFireContactsRow>({
          params: params,
          rows: rows,
          rowModesModel: rowModesModel,
          setRows: setRows,
          setRowModesModel: setRowModesModel,
          disableEdit: disableEdit,
          onSave: async (id) => {
            if (chimneyFireChannelId == null) {
              throw new IllegalStateError("chimneyFireChannelId is null");
            }
            const addedRow =
              rows.find((it) => it.id === id) ??
              _throw(new IllegalStateError(`Row with id ${id} does not exist`));
            return Promise.resolve(addedRow) as Promise<never>;
          },
          onDelete: async (id) => {
            if (chimneyFireChannelId == null) {
              throw new IllegalStateError("chimneyFireChannelId is null");
            }

            const row = rows.find((it) => it.id === id);
            if (row != null && row.contact != null) {
              await channelDao.removeContact({
                channelId: chimneyFireChannelId,
                contactId: row.contact,
              });
            }
          },
          t: tSharedDomain,
        });
      },
    });
  }

  useEffect(
    function loadRows() {
      const fetch = async () => {
        try {
          setLoading(true);
          const chimneyFireChannel = await queryClient.fetchQuery({
            queryKey: ["channel/list/fire", channelDao.queryKey()],
            queryFn: () => channelDao.getByName("fire"),
          });
          if (chimneyFireChannel == null) {
            throw new NotFoundError("chimneyFireChannel");
          }
          setChimneyFireChannelId(chimneyFireChannel.id);
          setRows(
            chimneyFireChannel?.contacts.map((it, index) => ({
              id: index,
              contact: it.id,
            }))
          );
          setLoading(false);
        } catch (e) {
          notify.error({ error: e });
          setLoading(false);
        }
      };
      fetch();
    },
    [notify, queryClient, channelDao]
  );

  useEffect(
    function loadAllContacts() {
      const fetch = async () => {
        setLoading(true);
        try {
          const contacts = await queryClient.fetchQuery({
            queryKey: ["contact/list", contactDao.queryKey()],
            queryFn: () => contactDao.getList(),
          });
          setLoading(false);
          setAllContacts(contacts);
        } catch (e) {
          setLoading(false);
          notify.error({ error: e });
        }
      };
      fetch();
    },
    [notify, queryClient, contactDao]
  );

  const handleColumnVisibilityChange = (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails
  ) => {
    setColumnVisibilityModel(model);
  };

  const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => {
    setSelectionModel(selectionModel);
  };

  const handleSortModelChange = (
    sortModel: GridSortModel,
    details: GridCallbackDetails
  ) => {
    setSortModel(sortModel);
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = async (
    changedRow: ChimneyFireContactsRow,
    originalRow: ChimneyFireContactsRow
  ) => {
    const updatedRow = { ...changedRow, isNew: false };

    if (chimneyFireChannelId == null) {
      throw new IllegalStateError("chimneyFireChannelId is null");
    }
    await channelDao.addContact({
      channelId: chimneyFireChannelId,
      contactId: changedRow.contact as string,
    });

    setRows(rows.map((row) => (row.id === changedRow.id ? updatedRow : row)));
    setRowModesModel?.({
      ...rowModesModel,
      [updatedRow.id]: { mode: GridRowModes.View },
    });
    return updatedRow;
  };

  const handleProcessRowUpdateError = (error: Error) => {
    // Nothing
  };

  const handleNewClick = () => {
    if (unAddedContacts.length > 0) {
      const id = rows.length;
      setRows((oldRows) => [
        ...oldRows,
        {
          isNew: true,
          id: id,
          contact: "",
        } as ChimneyFireContactsRow,
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "contact" },
      }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SettingsInfo>{t("In case of a temperature alarm ...")}</SettingsInfo>
      <SettingsGroup name={t("Recipients of notice")}>
        <Toolbar disableGutters>
          {hasAdminRole && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              disabled={unAddedContacts.length === 0}
              onClick={handleNewClick}
            >
              {t("Add")}
            </Button>
          )}
        </Toolbar>
        <Box
          sx={{
            height: "500px",
            width: "100%",
          }}
        >
          <DataGridPremium
            apiRef={gridApiRef}
            localeText={
              muiLocalization.components.MuiDataGrid.defaultProps.localeText
            }
            slots={{
              loadingOverlay: LinearProgress as never,
            }}
            hideFooterPagination
            disableColumnFilter
            disableRowGrouping
            disableAggregation
            loading={loading}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            rows={rows}
            rowCount={rows?.length ?? 0}
            editMode={"row"}
            rowModesModel={rowModesModel}
            rowSelectionModel={selectionModel}
            sortingMode="client"
            sortModel={sortModel}
            isCellEditable={(params) => params.row.isNew}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={handleSelectionModelChange}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </SettingsGroup>
    </Box>
  );
};
