import { alpha, useTheme } from "@mui/material";
import { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { Palette3, PaletteColor3 } from "./theme3";

export type ColorName = "primary" | "secondary" | "tertiary";

export const getContainer = (theme: Theme, name: ColorName): string => {
  const palette = theme.palette as Palette3;
  const colorPalette = palette[name] as PaletteColor3;
  if (colorPalette.container !== undefined) {
    return colorPalette.container;
  }
  const mainColor = colorPalette.main;
  return alpha(mainColor, 0.6);
};
export const useContainerColor = (color: ColorName): string => {
  const theme = useTheme();
  //console.log(theme);
  return useMemo(() => {
    return getContainer(theme, color);
  }, [color, theme]);
};

export const getOnContainer = (theme: Theme, name: ColorName): string => {
  const palette = theme.palette as Palette3;
  const colorPalette = palette[name] as PaletteColor3;
  if (colorPalette.onContainer !== undefined) {
    return colorPalette.onContainer;
  }
  //const containerColor = getContainer(theme, name);
  return theme.palette.text.primary;
};

export const useOnContainerColor = (color: ColorName): string => {
  const theme = useTheme();
  //console.log(theme);
  return useMemo(() => {
    return getOnContainer(theme, color);
  }, [color, theme]);
};
