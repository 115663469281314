import { DateTime, Duration, Settings } from "luxon";
import { PropertyValueType } from "./PropertyValueType";
import { PropertyValue } from "./PropertyValue";
import { ValueOptions } from "./ValueOptions";
import { ValueOptionsUtils } from "./ValueOptionsUtils";
import { isLink } from "./Link";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { TFunction } from "i18next";
import {
  DurationFormatter,
  DurationFormatterOptions,
} from "@airmont/shared/ts/ui/time";

export class ValueUtils {
  static resolveDisplayValue(args: {
    value: PropertyValue;
    type: PropertyValueType;
    t: TFunction;
    valueOptions?: ValueOptions;
    durationFormatterOptions?: DurationFormatterOptions;
  }): string {
    const { value, type, t, valueOptions, durationFormatterOptions } = args;

    if (valueOptions != null) {
      return ValueOptionsUtils.resolveDisplayValue(value, valueOptions);
    } else {
      if (value === undefined || value === null) {
        return "";
      } else if (typeof value === "boolean") {
        return value ? t("Yes") : t("No");
      } else if (type === "dateTime" && value instanceof DateTime) {
        return value.toLocaleString(DateTime.DATETIME_SHORT);
      } else if (type === "dateTime" && typeof value === "string") {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
      } else if (type === "date" && value instanceof DateTime) {
        return value.toLocaleString(DateTime.DATE_SHORT);
      } else if (type === "date" && typeof value === "string") {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
      } else if (type === "duration" && typeof value === "string") {
        const parts = new DurationFormatter({
          locales: Settings.defaultLocale,
          options: durationFormatterOptions,
        }).formatToParts(Duration.fromISO(value));
        return parts.reduce((a, part) => `${a} ${part.value}`, "");
      } else if (type === "duration" && value instanceof Duration) {
        const parts = new DurationFormatter({
          locales: Settings.defaultLocale,
          options: durationFormatterOptions,
        }).formatToParts(value);
        return parts.reduce((a, part) => `${a} ${part.value}`, "");
      } else if (typeof value === "string") {
        return value;
      } else if (typeof value === "number") {
        return value.toString();
      } else if (value instanceof File) {
        return value.name;
      } else if (isLink(value)) {
        return value.label ?? "";
      } else {
        throw new IllegalArgumentError(
          "value not supported: " + JSON.stringify(value)
        );
      }
    }
  }
}
