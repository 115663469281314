import { DialogTitle, Stack, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { ChimneySensorDialogContent } from "./ChimneySensorDialogContent";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { notNull, notUndef } from "@airmont/shared/ts/utils/core";
import { Button } from "shared-ts-mui";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import { useTranslation } from "react-i18next";
import { AppDialog, DisplayMode } from "shared-ts-app-dialog";

export interface ChimneySensorDialogProps {
  sensor?: ChimneySensorStatusDto;
  open: boolean;
  onGoNext?: () => void;
  onGoPrevious?: () => void;
  onUpdated: (updated: ChimneySensorStatusDto) => void;
  onDeleted: (deleted: ChimneySensorStatusDto) => void;
  onClose: () => void;
}

export const ChimneySensorDialog = (props: ChimneySensorDialogProps) => {
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const windowWidthComparer = useWindowWidthComparer();
  const [actionsContainer, setActionsContainer] = useState<Element | null>(
    null
  );
  const [displayMode, setDisplayMode] = useState<DisplayMode>("view");
  const fullScreen = windowWidthComparer.lte(SizeClass.Compact);

  const handleNavigatePrevious = () => {
    props.onGoPrevious?.();
  };

  const handleNavigateNext = () => {
    props.onGoNext?.();
  };

  const handleModeChange = (mode: DisplayMode) => {
    setDisplayMode(mode);
  };

  const handleClose = () => {
    setDisplayMode("view");
    props.onClose();
  };

  const actionsContainerRef = useCallback((node: Element | null) => {
    if (node != null) {
      setActionsContainer(node);
    }
  }, []);

  return (
    <AppDialog
      open={props.open}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={"md"}
      onDisplayModeChange={handleModeChange}
      onClose={props.onClose}
      slots={{
        DialogTitle: (
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              pt: 1,
              pb: 1,
              pl: 1,
              pr: 1,
            }}
          >
            <Stack direction={"row"} sx={{ justifySelf: "left" }}>
              <Button
                title={t("Close")}
                startIcon={<CloseRoundedIcon />}
                color={"inherit"}
                onClick={handleClose}
              />
              <Stack direction={"row"}>
                <Button
                  title={t("Previous")}
                  startIcon={<NavigateBeforeRoundedIcon />}
                  color={"inherit"}
                  onClick={handleNavigatePrevious}
                  disabled={props.onGoPrevious === undefined}
                />
                <Button
                  title={t("Next")}
                  startIcon={<NavigateNextRoundedIcon />}
                  color={"inherit"}
                  onClick={handleNavigateNext}
                  disabled={props.onGoNext === undefined}
                />
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              useFlexGap
              gap={1}
              sx={{ alignItems: "center" }}
            >
              {displayMode === "edit" && <EditRoundedIcon />}
              {displayMode === "view" && <VisibilityRoundedIcon />}
              {props.sensor && (
                <Stack direction={"row"} useFlexGap gap={1}>
                  <Typography>Sensor: &nbsp;{props.sensor.id}</Typography>
                </Stack>
              )}
            </Stack>
            <Stack
              sx={{ marginLeft: "auto" }}
              ref={actionsContainerRef}
              direction={"row"}
              useFlexGap
              gap={1}
            />
          </DialogTitle>
        ),
        DialogContent: notUndef(props.sensor, (sensor) =>
          notNull(actionsContainer, (actionsContainer) => (
            <ChimneySensorDialogContent
              mode={displayMode}
              sensor={sensor}
              actionsContainer={actionsContainer}
              onDisplayModeChange={handleModeChange}
              onUpdated={props.onUpdated}
              onDeleted={props.onDeleted}
            />
          ))
        ),
      }}
    />
  );
};
