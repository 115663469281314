/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { useResizeDetector } from "react-resize-detector";
import { resolveSizeClassFromPixels, SizeClass } from "./SizeClass";
import { OnRefChangeType } from "react-resize-detector/build/types/types";

export const useElementSizeClass = <T extends HTMLElement = any>(): {
  ref: OnRefChangeType<T>;
  width: SizeClass;
  height: SizeClass;
} => {
  const { width, height, ref } = useResizeDetector<T>({
    handleHeight: true,
    handleWidth: true,
  });

  const widthAsSizeClass =
    width != null ? resolveSizeClassFromPixels(width) : SizeClass.Compact;
  const heightAsSizeClass =
    height != null ? resolveSizeClassFromPixels(height) : SizeClass.Compact;
  return { ref: ref, width: widthAsSizeClass, height: heightAsSizeClass };
};
