import React, { FC } from "react";
import { SxProps } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Release, ReleaseEntry, ReleaseNoteEntry } from "shared-ts-release";
import { releases } from "./Releases";

export interface WhatsNewPaneProps {
  sx?: SxProps;
}

export const WhatsNewPane: FC<WhatsNewPaneProps> = (props) => {
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: t_shared_ts_release } = useTranslation("shared-ts-release");
  return (
    <Box sx={props.sx}>
      {releases.map((release: Release, ix: number) => {
        const date =
          release.date === "in-dev"
            ? release.date
            : new Date(release.date).toLocaleDateString();

        return (
          <Accordion key={release.version} defaultExpanded={ix === 0}>
            <AccordionSummary
              key={release.version + "-header"}
              id={release.version + "-header"}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={release.version + "-content"}
            >
              <Stack direction={"row"} gap={4}>
                <Typography>{`${t_shared_ts_release("Release")}: ${
                  release.version
                }`}</Typography>
                <Typography>{`${t_shared_ts_release(
                  "Date"
                )}: ${date}`}</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {release.entries.map((entry: ReleaseEntry, iix) => {
                return (
                  <Box key={`${ix}:${iix}`}>
                    <ReleaseNoteEntry
                      type={entry.type}
                      heading={entry.heading}
                      description={entry.description}
                    />
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};
