import { SensorSerialNumber } from "./SensorSerialNumber";
import { FC, ReactNode } from "react";
import { useGetLatestChimneySensorSettingsBySensorId } from "./useGetLatestChimneySensorSettingsBySensorId";
import { ChimneySensorSettingsHistoryDto } from "./ChimneySensorSettingsHistory";
import { UseQueryResult } from "@tanstack/react-query";

export interface LatestChimneySensorSettingsLoaderProps {
  id: SensorSerialNumber;
  children: (
    queryResult: UseQueryResult<ChimneySensorSettingsHistoryDto>
  ) => ReactNode;
}

export const LatestChimneySensorSettingsLoader: FC<
  LatestChimneySensorSettingsLoaderProps
> = (props) => {
  const queryResult = useGetLatestChimneySensorSettingsBySensorId(props.id);

  return props.children(queryResult);
};
