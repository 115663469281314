import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  MobileStepper,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import { useApi } from "@airmont/firefly/shared/ts/api";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { ImageGallery } from "./ImageGallery";
import { ImageUploader } from "shared-ts-image-upload";

export interface DevicePicturesFormProps {
  id: string;
  sx?: SxProps;
}

export const DevicePicturesForm = (props: DevicePicturesFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: fireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const [images, setImages] = useState<Array<string>>([]);
  const [imageRefreshTrigger, setImageRefreshTrigger] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const service = useApi({ controllerName: "images", environmentAware: true });

  useEffect(() => {
    service.get(props.id).then((imageUrls: Array<string>) => {
      setImages(imageUrls);
      setSelectedIndex(imageUrls.length > 0 ? 0 : -1);
    });
  }, [service, props.id, imageRefreshTrigger]);

  const handleDelete = () => {
    const imageUrl = images[selectedIndex]!; //finn url
    const name = imageUrl.substr(imageUrl.lastIndexOf("/") + 1); //splitter url på siste /
    const nameEncoded = encodeURI(name); //encoder for \ fjerne ulovlige tegn.

    if (
      window.confirm(
        fireflyDomain("Are you sure you want to delete the image {{name}}", {
          name: name,
        })
      )
    ) {
      service.delete(nameEncoded).then((success) => {
        setImageRefreshTrigger((prevState) => prevState + 1);
      });
    }
  };

  const handlePreviousClick = () => {
    setSelectedIndex((prevState) => Math.max(prevState - 1, 0));
  };

  const handleNextClick = () => {
    setSelectedIndex((prevState) => Math.min(prevState + 1, images.length - 1));
  };

  const handleImageUpload = () => {
    setImageRefreshTrigger((prevState) => prevState + 1);
  };

  return (
    <Stack direction={"column"} sx={props.sx}>
      {images.length > 0 && (
        <>
          <Toolbar disableGutters>
            <MobileStepper
              variant="text"
              position={"static"}
              steps={images.length}
              activeStep={selectedIndex}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNextClick}
                  disabled={selectedIndex === images.length - 1}
                >
                  {fireflyDomain("Next")}
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeftIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handlePreviousClick}
                  disabled={selectedIndex === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRightIcon />
                  ) : (
                    <KeyboardArrowLeftIcon />
                  )}
                  {fireflyDomain("Previous")}
                </Button>
              }
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginRight: theme.spacing(1) }}
            />
            <Button
              startIcon={<DeleteRoundedIcon />}
              title={fireflyDomain("Delete Picture")}
              onClick={handleDelete}
            >
              {t("Delete")}
            </Button>
            <Button
              startIcon={<FullscreenRoundedIcon />}
              sx={{
                "> .MuiButton-startIcon": { marginRight: 0 },
              }}
              title={fireflyDomain("View image in full screen")}
              /*onClick={handleFullscreen}*/
            ></Button>
          </Toolbar>
          <ImageGallery
            images={[images[selectedIndex]]}
            sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
          />
          {/*<Gallery>
            {images.map((image) => {
              return (
                <Item original={image}>
                  {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={image} />
                  )}
                </Item>
              );
            })}
          </Gallery>*/}
        </>
      )}
      <ImageUploader refValue={props.id} onUpload={handleImageUpload} />
    </Stack>
  );
};
