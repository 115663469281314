import {
  CadastralNumbersDto,
  FlueFieldsDto,
  StreetAddress,
  StreetAddressDto,
} from "@airmont/firefly/shared/ts/domain";

export class FlueNameResolver {
  readonly name: string;

  constructor(args: {
    streetAddress?: StreetAddressDto;
    cadastralNumbers?: CadastralNumbersDto;
    flue?: FlueFieldsDto;
  }) {
    let name = "";
    if (args.streetAddress != null) {
      name += new StreetAddress(args.streetAddress);
    }
    if (
      args.flue != null &&
      args.flue.name != null &&
      args.flue.name.length > 0
    ) {
      name += " / " + args.flue.name;
    }
    this.name = name;
  }

  public toString(): string {
    return this.name;
  }
}
