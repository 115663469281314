import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { ColorSchemeGenerator } from "shared-ts-mui";
import { merge } from "lodash";
import { apexChartsLocales } from "./apexChartsLocales";

export const useDefaultApexOptions = (args: {
  theme: Theme;
  seriesCount: number;
  apexOptions?: ApexOptions;
}): ApexOptions => {
  const { theme, seriesCount, apexOptions } = args;

  const colors = useMemo(
    () => ColorSchemeGenerator.getComplementaries(seriesCount),
    [seriesCount]
  );

  return useMemo(() => {
    return merge(
      {
        theme: {
          mode: theme.palette.mode,
        },
        chart: {
          background: "transparent",
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
              pan: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
            },
          },
          locales: apexChartsLocales,
          defaultLocale: "no",
        },
        grid: {
          borderColor: theme.palette.divider,
          position: "back",
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            bottom: 0,
          },
        },
        xaxis: {
          title: {
            style: {
              color: theme.palette.text.secondary,
            },
          },
          labels: {
            style: {
              colors: theme.palette.text.secondary,
            },
          },
        },
        yaxis: undefined,
        colors: colors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        annotations: {},
        markers: {
          size: [0, 0],
        },
      },
      apexOptions
    );
  }, [theme, apexOptions, colors]);
};
