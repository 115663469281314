import { AddressDto, AddressIdResolver } from "./Address";
import { names, uniqueNamesGenerator } from "unique-names-generator";
import { RandomUtils } from "@airmont/shared/ts/utils/core";
import { StreetAddressDto } from "./StreetAddress";
import { PostalAddressDto } from "./PostalAddress";

let cadastralUnitCount = 1;

export interface AddressSpecification {
  streetAddress?: StreetAddressDto;
  postalAddress?: PostalAddressDto;
}
export const createAddressMocks = (args: {
  count: number;
  specifications: Array<AddressSpecification>;
}): Array<AddressDto> => {
  const addresses: Array<AddressDto> = [];
  for (let i = 0; i < args.count; i++) {
    const specification = args.specifications[i];
    const municipalityId = "3001";
    const municipalityName = "Halden";

    const address: Omit<AddressDto, "id"> = {
      municipality: { id: municipalityId, name: municipalityName },
      postalAddress: specification?.postalAddress ?? {
        code: "1794",
        place: "Sponvika",
      },
      streetAddress: specification?.streetAddress ?? {
        street:
          uniqueNamesGenerator({
            dictionaries: [names],
          }) + " road",
        houseNumber: 1,
      },
      cadastralAddress: {
        municipalityId: municipalityId,
        cadastralUnit: `${cadastralUnitCount++}`,
        propertyUnit: `${RandomUtils.getRandomInt(1, 50)}`,
      },
    };

    addresses.push({
      ...address,
      id: AddressIdResolver.resolveAddressId({
        municipalityId: address.municipality.id,
        postalCode: address.postalAddress.code,
        streetAddress: address.streetAddress,
        cadastralAddress: address.cadastralAddress,
      }),
    });
  }

  return addresses;
};
