import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Menu } from "@mui/material";
import { MenuStartButton } from "shared-ts-mui";
import React, { FC } from "react";
import { Action, ActionMenuItem } from "@airmont/shared/ts/ui/action";

export interface AddFilterProps {
  filterActions: Array<Action>;
}

export const AddFilter: FC<AddFilterProps> = (props) => {
  const { filterActions } = props;

  return (
    <MenuStartButton
      label={"Legg til"}
      ButtonProps={{
        color: "secondary",
        startIcon: <AddRoundedIcon />,
      }}
      menu={(open, anchorEl, onClose, setDisabled) => {
        return (
          <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
            {filterActions.map((action) => (
              <ActionMenuItem
                key={action.name}
                item={action}
                layout={"large"}
              />
            ))}
          </Menu>
        );
      }}
    />
  );
};
