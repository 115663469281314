import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { AddressDto } from "./Address";

export class AddressController {
  public readonly handlers: RequestHandler[];

  private addresses: Array<AddressDto>;

  constructor(args: { addresses: Array<AddressDto> }) {
    this.addresses = args.addresses;
    this.handlers = [
      rest.post("/api/address/query", this.query),
      ///**/rest.get("/api/address/get-by-id/:id", this.getById),
    ];
  }

  query = async (
    request: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<AddressDto>>> => {
    return res(ctx.json(this.addresses));
  };

  /*getById = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<AddressDto>> => {
    const { id } = req.params;
    const result = this.addresses.find((it) => it.id === id);
    if (result == null) {
      return res(ctx.status(404));
    }
    return res(ctx.json(result));
  };*/
}
