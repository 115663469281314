import React, { FC, useMemo, useState } from "react";
import {
  Box,
  Container,
  LinearProgress,
  Stack,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  TabPanel,
  TabPanelProps,
  TabsAndPanels,
} from "@airmont/shared/ts/ui/tabs";
import {
  FlueId,
  FlueMetricsLoader,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { SxProps } from "@mui/system";
import {
  SizeClass,
  useDense,
  useWindowSize,
} from "@airmont/shared/ts/ui/responsive";
import {
  Timeframe,
  TimeframeObjectProvider,
  useTimeframe,
} from "@airmont/shared/ts/ui/timeframe";
import { Duration, Info } from "luxon";
import { AggregateType } from "@airmont/firefly/shared/ts/timeseries";
import { merge } from "lodash";
import { StringUtils } from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";
import {
  BurnsLoader,
  BurnsPanel,
  BurnTemperatureSeriesLoader,
  FlueMetricsTable,
} from "@airmont/firefly/chimney-insights/ts/domain";
import {
  TimeSeriesChart,
  TimeSeriesChartOptions,
  TimeSeriesOption,
} from "@airmont/firefly/shared/ts/timeseries-apex-chart";
import {
  TimeSeriesLoader,
  TimeSeriesQueryImpl,
} from "@airmont/firefly/shared/ts/timeseries-dao";

type BurnsTabValue = "burn-patterns" | "burn-hours";

export interface BurnsTabPanelProps extends TabPanelProps {
  flueId: FlueId;
  chimneyFireThreshold?: number;
}

export const BurnsTabPanel: FC<BurnsTabPanelProps> = (props) => {
  const dense = useDense();
  const { windowWidth } = useWindowSize();
  const { t } = useTranslation("firefly-chimney-insights-ts-domain");
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const [fetching] = useState(false);
  const timeframeProps = useTimeframe({
    disallowFuture: true,
    allowedUnits: ["month"],
    persistUsingUserSettings: true,
    storageId: "Flue.BurnsTabPanel.",
  });
  const [burnsTab, setBurnsTab] = useState<BurnsTabValue>("burn-patterns");

  const sx: SxProps = useMemo(
    () =>
      merge({}, props.sx, {
        display: "flex",
        flexDirection: "column",
      }),
    [props.sx]
  );
  const handleBurnsTabChange = (value: string) => {
    setBurnsTab(value as BurnsTabValue);
  };

  return (
    <TabPanel tabValue={props.tabValue} sx={sx} scrollableY>
      <TimeframeObjectProvider
        timeframe={timeframeProps.timeframe}
        timeframeUnit={timeframeProps.timeframeUnit}
      >
        <Container
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            pl: windowWidth === SizeClass.Compact ? 0 : 1,
            pr: windowWidth === SizeClass.Compact ? 0 : 1,
          }}
        >
          {fetching && (
            <LinearProgress
              sx={{
                height: "2px",
              }}
            />
          )}
          <Stack
            direction={"column"}
            spacing={1}
            useFlexGap
            sx={{
              flexGrow: 1,
              minHeight: 0,
              minWidth: 0,
              alignItems: "stretch",
              position: "relative",
            }}
          >
            <Toolbar
              variant={dense ? "dense" : "regular"}
              style={{ paddingRight: 0 }}
            >
              <Typography variant={"subtitle1"}>
                {tFireflySharedDomain("Key Figures")}
              </Typography>
              <Box sx={{ maxWidth: "300px", marginLeft: "auto" }}>
                <Timeframe {...timeframeProps} layout={"row"} />
              </Box>
            </Toolbar>
            <FlueMetricsLoader
              flueId={props.flueId}
              type={[
                FlueMetricsTimeUnitEnum.Ever,
                FlueMetricsTimeUnitEnum.SinceSweep,
                FlueMetricsTimeUnitEnum.Month,
              ]}
              time={timeframeProps.timeframe.start}
            >
              {(resultByName) => {
                return (
                  <>
                    <FlueMetricsTable
                      metrics={[
                        {
                          label: `${StringUtils.capitalizeFirstLetter(
                            Info.months()[
                              timeframeProps.timeframe.start.month - 1
                            ]
                          )} ${timeframeProps.timeframe.start.year}`,
                          values:
                            resultByName[FlueMetricsTimeUnitEnum.Month]?.data
                              ?.metrics,
                        },
                      ]}
                      layout={windowWidth}
                      paper
                      sx={{
                        p: windowWidth === SizeClass.Compact ? 0 : 1,
                        minHeight: "87px",
                      }}
                    />
                    <Box
                      sx={{
                        flexGrow: 1,
                        minHeight: 0,
                        minWidth: 0,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TabsAndPanels
                        value={burnsTab}
                        onChange={handleBurnsTabChange}
                        sx={{ flexGrow: 1, minHeight: 0, minWidth: 0, pb: 1 }}
                        tabs={[
                          <Tab
                            label={tFireflySharedDomain("Burns")}
                            value={"burn-patterns"}
                          />,
                          <Tab
                            label={tFireflySharedDomain("Burn Hours")}
                            value={"burn-hours"}
                          />,
                        ]}
                        tabPanels={[
                          <TabPanel
                            tabValue="burn-patterns"
                            sx={{
                              display: "flex",
                              flexGrow: 1,
                              paddingBottom: 0,
                            }}
                          >
                            <BurnsLoader
                              flueId={props.flueId}
                              fromDate={timeframeProps.timeframe.start}
                              toDate={timeframeProps.timeframe.end}
                            >
                              {({ burns, burnIds, isBurnsLoading }) => {
                                return (
                                  <BurnTemperatureSeriesLoader
                                    burnIds={burnIds}
                                  >
                                    {({
                                      temperatureSeries,
                                      isTemperatureSeriesLoading,
                                    }) => {
                                      return (
                                        <BurnsPanel
                                          loading={
                                            isBurnsLoading ||
                                            isTemperatureSeriesLoading
                                          }
                                          burns={burns}
                                          burnTemperatureSeries={
                                            temperatureSeries
                                          }
                                          maxTemperatures={{
                                            ever: resultByName[
                                              FlueMetricsTimeUnitEnum.Ever
                                            ]?.data?.metrics.temperatureMax,
                                            sinceSweep:
                                              resultByName[
                                                FlueMetricsTimeUnitEnum
                                                  .SinceSweep
                                              ]?.data?.metrics.temperatureMax,
                                          }}
                                          chimneyFireThreshold={
                                            props.chimneyFireThreshold
                                          }
                                          sx={{
                                            flex: 1,
                                            minWidth: 0,
                                            minHeight: 0,
                                          }}
                                          layout={windowWidth}
                                        />
                                      );
                                    }}
                                  </BurnTemperatureSeriesLoader>
                                );
                              }}
                            </BurnsLoader>
                          </TabPanel>,
                          <TabPanel
                            tabValue="burn-hours"
                            sx={{
                              display: "flex",
                              flexGrow: 1,
                              paddingBottom: 0,
                            }}
                          >
                            <TimeSeriesLoader
                              query={
                                new TimeSeriesQueryImpl({
                                  sid: props.flueId,
                                  name: "burn:Aggregate",
                                  start: timeframeProps.timeframe.start,
                                  end: timeframeProps.timeframe.end,
                                  sourceResolution: Duration.fromISO("P1D"),
                                  resolution: Duration.fromISO("P1W"),
                                  aggregate: AggregateType.Sum,
                                  includeQuality: false,
                                  timeZone: "Europe/Oslo",
                                  clientTimeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                      .timeZone,
                                })
                              }
                            >
                              {({ timeSeries, isLoading }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexGrow: 1,
                                    position: "relative",
                                  }}
                                >
                                  {isLoading && (
                                    <LinearProgress
                                      sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                  )}
                                  {timeSeries?.length === 0 && (
                                    <Typography
                                      align={"center"}
                                      sx={{
                                        flex: 1,
                                        alignSelf: "center",
                                      }}
                                    >
                                      {t("No burn hours")}
                                    </Typography>
                                  )}
                                  {timeSeries !== undefined &&
                                    timeSeries.length > 0 && (
                                      <TimeSeriesChart
                                        timeSeries={timeSeries}
                                        timeSeriesOptions={
                                          [
                                            {
                                              displayName:
                                                tFireflySharedDomain(
                                                  "Burn Duration"
                                                ),
                                              type: "bar",
                                              transformValue: (value) =>
                                                (value / 60).toFixed(1),
                                              transformTime: (dateTime) =>
                                                dateTime.weekNumber,
                                            },
                                          ] as Array<TimeSeriesOption<number>>
                                        }
                                        options={
                                          {
                                            noData: {
                                              text: t("No burn hours"),
                                            },
                                            xAxis: {
                                              type: "category",
                                              title: {
                                                text: "Uke",
                                              },
                                              crosshairs: {
                                                show: false,
                                              },
                                            },
                                            yAxis: {
                                              title: {
                                                text: tFireflySharedDomain(
                                                  "Hours"
                                                ),
                                              },
                                              unit: "t",
                                              max: 168,
                                              min: 0,
                                            },
                                            tooltip: {
                                              x: {
                                                formatter: (value) => {
                                                  return `Uke: ${value}`;
                                                },
                                              },
                                            },
                                          } as TimeSeriesChartOptions<number>
                                        }
                                        sx={{ flexGrow: 1 }}
                                      />
                                    )}
                                </Box>
                              )}
                            </TimeSeriesLoader>
                          </TabPanel>,
                        ]}
                      />
                    </Box>
                  </>
                );
              }}
            </FlueMetricsLoader>
          </Stack>
        </Container>
      </TimeframeObjectProvider>
    </TabPanel>
  );
};
