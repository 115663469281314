import { Expr } from "../../Expr";

export interface ContainsXpr {
  $type: "contains";
  instance: Expr;
  arg: Expr;
}

export const containsExpr = (instance: Expr, arg: Expr): ContainsXpr => ({
  $type: "contains",
  instance: instance,
  arg: arg,
});
