import { FlueMetrics } from "../metrics/FlueMetrics";
import { ArrayUtils, MathUtils } from "@airmont/shared/ts/utils/core";

export const useResolveBurnHourCountEstimatedSeries = (
  year: number,
  thisMonth: number | null,
  metricsByMonth: Record<string, FlueMetrics>
): Array<number | null> => {
  return ArrayUtils.createAndFill(12, (index) => {
    const month = index + 1;
    if (thisMonth == null || month <= thisMonth) {
      return null;
    }
    const flueMetrics = metricsByMonth[`${year}-${month}`];
    if (flueMetrics == null) {
      return null;
    }
    return MathUtils.round(flueMetrics.metrics.burnHourCount);
  });
};
