import { useCustomerEnvironmentContext } from "./useCustomerEnvironmentContext";
import { useMemo } from "react";
import { CustomerEnvironment } from "./CustomerEnvironment";

export const useCustomerEnvironment = (): CustomerEnvironment | undefined => {
  const context = useCustomerEnvironmentContext();
  if (context == null) {
    throw new Error(
      "useCustomerEnvironment must be used within a EnvironmentProvider"
    );
  }
  return useMemo(
    () => context.environmentList.find((it) => it.id === context.environmentId),
    [context.environmentId, context.environmentList]
  );
};
