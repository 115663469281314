import { FC, ReactNode, useMemo } from "react";
import { FlueMetricsDto } from "../FlueMetrics";
import { useFlueMetricsDao } from "../dao/useFlueMetricsDao";
import { useQueries, UseQueryResult } from "@tanstack/react-query";
import {
  FlueMetricsQuery,
  FlueMetricsQueryImpl,
} from "../dao/FlueMetricsQuery";
import { FlueId } from "../../FlueId";
import { FlueMetricsTimeUnitEnum } from "../FlueMetricsTimeUnit";
import { DateTime } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { DateTimeISO } from "@airmont/shared/ts/types";

export interface FlueMetricsByTimeLoaderProps {
  flueId: FlueId;
  type: FlueMetricsTimeUnitEnum;
  time: Array<DateTime>;
  children: (
    result: Record<DateTimeISO, UseQueryResult<FlueMetricsDto>>
  ) => ReactNode;
}

export const FlueMetricsByTimeLoader: FC<FlueMetricsByTimeLoaderProps> = (
  props
) => {
  const flueMetricsDao = useFlueMetricsDao();

  const queries: Array<FlueMetricsQuery> = useMemo(() => {
    const type = props.type;
    return props.time.map((time) => {
      return new FlueMetricsQueryImpl({
        flueId: props.flueId,
        timeUnit: type,
        time: time,
      });
    });
  }, [props.flueId, props.type, props.time]);

  const queriesResult = useQueries({
    queries: queries.map((query, index) => {
      return {
        queryKey: ["FlueMetricsDao.query", query],
        queryFn: () => flueMetricsDao.query(query),
        select: (data: Array<FlueMetricsDto>) => data[0],
        staleTime: 10 * 60 * 1000,
        gcTime: 15 * 60 * 1000,
      };
    }),
  });

  const result = useMemo(() => {
    const result: Record<string, UseQueryResult<FlueMetricsDto>> = {};
    queriesResult.forEach((queryResult, index) => {
      const key =
        props.time[index].toISO() ??
        _throw(new IllegalStateError("time.toISO() failed"));
      result[key] = queryResult;
    });

    return result;
  }, [props.time, queriesResult]);

  return props.children(result);
};
