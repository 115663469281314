import { FC, ReactNode, useMemo } from "react";
import { useEnvironmentMetricsDao } from "./useEnvironmentMetricsDao";
import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { EnvironmentMetricsTimeUnitEnum } from "./EnvironmentMetricsTimeUnit";
import { EnvironmentMetricsDto } from "./EnvironmentMetrics";
import {
  EnvironmentMetricsQuery,
  EnvironmentMetricsQueryImpl,
} from "./EnvironmentMetricsQuery";

export interface EnvironmentMetricsLoaderProps {
  environment: CustomerEnvironmentName;
  timeUnits: Array<EnvironmentMetricsTimeUnitEnum>;
  time?: DateTime;
  children: (
    result: Record<
      EnvironmentMetricsTimeUnitEnum,
      UseQueryResult<EnvironmentMetricsDto>
    >
  ) => ReactNode;
}

export const EnvironmentMetricsLoader: FC<EnvironmentMetricsLoaderProps> = (
  props
) => {
  const environmentMetricsDao = useEnvironmentMetricsDao();

  const queries: Array<EnvironmentMetricsQuery> = useMemo(() => {
    return props.timeUnits.map((type) => {
      let time: DateTime | undefined = undefined;
      if (type === EnvironmentMetricsTimeUnitEnum.Year) {
        time = props.time?.startOf("year");
      } else if (type === EnvironmentMetricsTimeUnitEnum.Month) {
        time = props.time?.startOf("month");
      }
      return new EnvironmentMetricsQueryImpl({
        environment: props.environment,
        timeUnit: type,
        time: time,
      });
    });
  }, [props.environment, props.timeUnits, props.time]);
  const queriesResult = useQueries({
    queries: props.timeUnits.map((type, index) => {
      const query = queries[index];
      return {
        queryKey: ["/api/environment-metrics/query", query],
        queryFn: () => environmentMetricsDao.query(query),
        select: (data: Array<EnvironmentMetricsDto>) => data[0],
        staleTime: 10 * 60 * 1000,
        gcTime: 15 * 60 * 1000,
      };
    }),
  });

  const result: Record<string, UseQueryResult<EnvironmentMetricsDto>> = {};
  queriesResult.forEach((queryResult, index) => {
    const key = props.timeUnits[index];
    result[key] = queryResult;
  });

  return props.children(result);
};
