import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { Notification } from "./Notification";

export interface NotificationDisplayProps {
  notification: Notification;
}

export const NotificationDisplay: FC<NotificationDisplayProps> = (props) => {

  const heading = resolveHeading(props.notification);

  if (props.notification.error != null) {

    const notificationText = resolveNotificationText(props.notification);
    const heading = notificationText != null ? notificationText : props.notification.error.message;
    const subHeading = notificationText != null ? props.notification.error.message : null;

    return (
      <Box className={"NotificationDisplay"}>
        {heading && heading}
        {subHeading && subHeading}
      </Box>
    );
  } else {
    return (
      <Box className={"NotificationDisplay"}>
        {heading}
      </Box>
    );
  }
};

const resolveNotificationText = (notification: Notification): ReactNode => {
  if (notification.text != null) {
    if (typeof notification.text === "string") {
      return <Typography>{notification.text}</Typography>;
    } else {
      return notification.text;
    }
  }

  return null;
};





const resolveHeading = (notification: Notification): ReactNode => {
  if (notification.text != null) {
    if (typeof notification.text === "string") {
      return <Typography>{notification.text}</Typography>;
    } else {
      return notification.text;
    }
  } else if (notification.error != null) {
    return notification.error.message;
  } else {
    return <Typography>An unexpected error occured</Typography>;
  }
};
