import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useResizeDetector } from "react-resize-detector";

const StyledApexChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  > div {
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    overflow-y: clip;
  }
`;

export interface ApexChartContainerProps {
  chart: (width: number, height: number) => ReactNode;
  sx?: SxProps<Theme>;
}

export const ApexChartContainer: FC<ApexChartContainerProps> = (props) => {
  const { width, height, ref } = useResizeDetector();
  return (
    <StyledApexChartContainer
      className={"ApexChartContainer"}
      sx={props.sx}
      ref={ref}
    >
      {width != null && height != null && props.chart(width, height - 15)}
    </StyledApexChartContainer>
  );
};
