import React, { ReactNode, useMemo } from "react";
import { EnvironmentContext, IEnvironmentContext } from "./EnvironmentContext";
import * as Sentry from "@sentry/react";
import {
  CustomerEnvironment,
  CustomerEnvironmentId,
} from "./CustomerEnvironment";
import {
  ReadOnlyUserSettings,
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { UserSettingName } from "./UserSettingName";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface EnvironmentProviderProps {
  initialUserSettings?: ReadOnlyUserSettings;
  environments: Array<CustomerEnvironment>;
  children?:
    | ReactNode
    | ((customerEnvironment: CustomerEnvironment) => ReactNode);
}

export const EnvironmentProvider = (props: EnvironmentProviderProps) => {
  if (props.environments.length === 0) {
    throw new Error("EnvironmentProvider require environments");
  }

  const initialEnvironment = resolveInitialEnvironment(
    props.environments,
    props.initialUserSettings
  );
  const [environmentId, setEnvironmentId] =
    useUserSettingWithDefault<CustomerEnvironmentId>(
      UserSettingName,
      StringSetting,
      initialEnvironment
    );

  const environment = useMemo(
    () =>
      props.environments.find((it) => it.id === environmentId) ??
      _throw(
        new IllegalStateError("Environment not available: " + environmentId)
      ),
    [environmentId, props.environments]
  );
  const _setEnvironment = (value: CustomerEnvironmentId) => {
    const environmentExists = props.environments.some((it) => it.id === value);
    let environment: CustomerEnvironmentId = value;
    if (!environmentExists) {
      environment = props.environments[0].id;
    }

    setEnvironmentId(environment);

    Sentry.setTags({
      customerEnvironment: environment,
    });
  };

  const value: IEnvironmentContext = useMemo(() => {
    return {
      environmentId: environmentId,
      environment: environment,
      environmentList: props.environments,
      setEnvironment: _setEnvironment,
    };
  }, [environmentId, environment, props.environments, _setEnvironment]);
  return (
    <EnvironmentContext.Provider value={value}>
      {typeof props.children === "function"
        ? props.children(environment)
        : props.children}
    </EnvironmentContext.Provider>
  );
};

const resolveInitialEnvironment = (
  environments: Array<CustomerEnvironment>,
  initialUserSettings: ReadOnlyUserSettings | undefined
): CustomerEnvironmentId => {
  const initialEnvironment = initialUserSettings?.readString(UserSettingName);
  if (initialEnvironment != null) {
    return initialEnvironment;
  }
  return environments[0].id;
};
