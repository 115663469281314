import { Property, PropertyMode } from "shared-ts-property";
import React, { FC, ReactNode } from "react";
import { DuenessStringsEnum } from "@airmont/shared/ts/types";
import { useTranslation } from "react-i18next";
export interface DuenessPropertyProps {
  propertyMode: PropertyMode;
  label: string;
  info?: ReactNode;
  value: DuenessStringsEnum | null | undefined;
  onChange: (
    name: string,
    value: DuenessStringsEnum | null | undefined
  ) => void;
}
export const DuenessProperty: FC<DuenessPropertyProps> = (props) => {
  const { propertyMode, value } = props;
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  return (
    <Property
      name="Dueness"
      label={props.label}
      info={props.info}
      mode={propertyMode}
      type={"string"}
      value={value}
      onChange={(value, name) =>
        props.onChange(name, value as typeof props.value)
      }
      valueOptions={{
        multiple: false,
        restrictToOptions: true,
        options: [
          {
            label: tFireflySharedDomain(`Dueness.${DuenessStringsEnum.NotDue}`),
            id: DuenessStringsEnum.NotDue,
          },
          {
            label: tFireflySharedDomain(`Dueness.${DuenessStringsEnum.Due}`),
            id: DuenessStringsEnum.Due,
          },
          {
            label: tFireflySharedDomain(
              `Dueness.${DuenessStringsEnum.Overdue}`
            ),
            id: DuenessStringsEnum.Overdue,
          },
        ],
      }}
    />
  );
};
