import { DateTime } from "luxon";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { EnvironmentMetricsTimeUnitEnum } from "./EnvironmentMetricsTimeUnit";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface EnvironmentMetricsQuery {
  environment: CustomerEnvironmentName;
  timeUnit?: EnvironmentMetricsTimeUnitEnum;
  time?: DateTime;
  toDto: () => EnvironmentMetricsQueryDto;
}

export interface EnvironmentMetricsQueryDto {
  environment: CustomerEnvironmentName;
  timeUnit?: EnvironmentMetricsTimeUnitEnum;
  time?: DateTimeISO;
}

export class EnvironmentMetricsQueryImpl implements EnvironmentMetricsQuery {
  readonly environment: CustomerEnvironmentName;
  readonly timeUnit: EnvironmentMetricsTimeUnitEnum;
  readonly time?: DateTime;
  constructor(args: {
    environment: CustomerEnvironmentName;
    timeUnit: EnvironmentMetricsTimeUnitEnum;
    time?: DateTimeISO | DateTime;
  }) {
    this.environment = args.environment;
    this.timeUnit = args.timeUnit;
    this.time =
      args.time !== undefined
        ? typeof args.time === "string"
          ? DateTime.fromISO(args.time)
          : args.time
        : undefined;
  }

  toDto(): EnvironmentMetricsQueryDto {
    return {
      environment: this.environment,
      timeUnit: this.timeUnit,
      time:
        this.time !== undefined
          ? this.time.toUTC().toISO() ?? _throw(new IllegalStateError("time"))
          : undefined,
    };
  }
}
