import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { ChimneySensorSettingsDao } from "./ChimneySensorSettingsDao";
import { ChimneySensorSerialNr } from "./ChimneySensorUpdate";
import { ChimneySensorSettingsHistoryDto } from "./ChimneySensorSettingsHistory";
import { useChimneySensorSettingsDao } from "./useChimneySensorSettingsDao";
import { useMemo } from "react";
import { EntityResponse } from "../shared/entity/EntityResponse";

export class ChimneySensorSettingsQueryClient {
  private queryClient: QueryClient;
  private dao: ChimneySensorSettingsDao;

  constructor(args: {
    queryClient: QueryClient;
    dao: ChimneySensorSettingsDao;
  }) {
    this.queryClient = args.queryClient;
    this.dao = args.dao;
  }

  getLatestBySensorId = (
    id: ChimneySensorSerialNr
  ): Promise<EntityResponse<ChimneySensorSettingsHistoryDto>> => {
    return this.queryClient.fetchQuery({
      queryKey: ["ChimneySensorSettingsDao.getLatestBySensorId", id],
      queryFn: () => this.dao.getLatestBySensorId(id),
      staleTime: 10 * 60 * 1000,
      gcTime: 15 * 60 * 1000,
    });
  };
}

export const useChimneySensorSettingsQueryClient =
  (): ChimneySensorSettingsQueryClient => {
    const queryClient = useQueryClient();
    const dao = useChimneySensorSettingsDao();
    return useMemo(
      () => new ChimneySensorSettingsQueryClient({ queryClient, dao }),
      [dao, queryClient]
    );
  };
