import React from "react";
import { WindowSizeContext } from "./WindowSizeProvider";
import { SizeClass } from './SizeClass';

export const useWindowWidth = (): SizeClass => {
  const context = React.useContext(WindowSizeContext);
  if (context === undefined) {
    throw new Error("useWindowSize must be used within a WindowSizeProvider");
  }
  return context.windowWidth;
};
