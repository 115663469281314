export enum ChimneyWasteTypeEnum {
  Ash,
  Soot,
  Creosote
}
export enum ChimneyWasteTypeStringsEnum {
  Ash = "Ash",
  Soot = "Soot",
  Creosote = "Creosote",
}
