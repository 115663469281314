import * as React from "react";
import { FC } from "react";
import { MenuItem, Select } from "@mui/material";
import { ChimneySensorStatusEnum } from "./ChimneySensorStatus";
import { useFireflySharedTsDomainTranslation } from "../i18n";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

export interface ChimneySensorStatusSelectProps {
  noneOption?: boolean;
  displayEmpty?: boolean;
  selected: ChimneySensorStatusEnum | null | undefined;
  onChange: (value: ChimneySensorStatusEnum | null) => void;
}

export const ChimneySensorStatusSelect: FC<ChimneySensorStatusSelectProps> = (
  props
) => {
  const { t } = useFireflySharedTsDomainTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    return props.onChange(
      ChimneySensorStatusEnum[
        event.target.value as keyof typeof ChimneySensorStatusEnum
      ]
    );
  };

  return (
    <Select
      value={props.selected ?? ""}
      displayEmpty={props.displayEmpty}
      onChange={handleChange}
    >
      {props.noneOption && (
        <MenuItem value="">
          <em>{t("Not set")}</em>
        </MenuItem>
      )}
      <MenuItem value={ChimneySensorStatusEnum.Active}>
        {t(`ChimneySensorStatus.${ChimneySensorStatusEnum.Active}`)}
      </MenuItem>
      <MenuItem value={ChimneySensorStatusEnum.NotCommunicating}>
        {t(`ChimneySensorStatus.${ChimneySensorStatusEnum.NotCommunicating}`)}
      </MenuItem>
      <MenuItem value={ChimneySensorStatusEnum.Retired}>
        {t(`ChimneySensorStatus.${ChimneySensorStatusEnum.Retired}`)}
      </MenuItem>
      <MenuItem value={ChimneySensorStatusEnum.Dead}>
        {t(`ChimneySensorStatus.${ChimneySensorStatusEnum.Dead}`)}
      </MenuItem>
      <MenuItem value={ChimneySensorStatusEnum.Starting}>
        {t(`ChimneySensorStatus.${ChimneySensorStatusEnum.Starting}`)}
      </MenuItem>
      <MenuItem value={ChimneySensorStatusEnum.FailedToStart}>
        {t(`ChimneySensorStatus.${ChimneySensorStatusEnum.FailedToStart}`)}
      </MenuItem>
    </Select>
  );
};
