import { ClusterPinRenderProps } from "shared-ts-ui-react-google-maps";
import React, { ReactNode, useMemo } from "react";
import { PieChart } from "@mui/x-charts";
import { useTheme } from "@mui/material";
import {
  FlueStatusDto,
  FlueStatusGeoFeature,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { DateTime, Interval } from "luxon";
import { PieCenterLabel } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { MathUtils } from "@airmont/shared/ts/utils/core";

export interface FlueStatusClusterPinProps
  extends ClusterPinRenderProps<FlueStatusGeoFeature> {
  numberOfFluesWithSensors: number;
}

export const FlueStatusClusterPin = (
  props: FlueStatusClusterPinProps
): ReactNode => {
  const { features, numberOfFluesWithSensors } = props;
  const theme = useTheme();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const now = useDateTimeNow();
  const flues = useMemo<FlueStatusDto[]>(() => {
    const array: Array<FlueStatusDto> = [];
    features.forEach((feature: FlueStatusGeoFeature) => {
      array.push(...feature.properties.flues);
    });
    return array;
  }, [features]);

  const countNeedsSweepNext12Months = flues.filter((flue) => {
    if (flue.flue.estimatedSweepDate == null) {
      return false;
    }
    const estimatedSweepDate = DateTime.fromISO(flue.flue.estimatedSweepDate);
    return estimatedSweepDate > now
      ? Interval.fromDateTimes(now, estimatedSweepDate)
          .toDuration()
          .shiftTo("months")
          .get("month") <= 12
      : true;
  }).length;

  const countNeedsInOneToTwoYears = flues.filter((flue) => {
    if (flue.flue.estimatedSweepDate == null) {
      return false;
    }
    const estimatedSweepDate = DateTime.fromISO(flue.flue.estimatedSweepDate);
    if (now > estimatedSweepDate) {
      return false;
    }
    const interval = Interval.fromDateTimes(now, estimatedSweepDate);
    const duration = interval.toDuration();
    const durationInMonths = duration.shiftTo("months").get("month");
    return durationInMonths > 12 && durationInMonths <= 24;
  }).length;

  const countNoNeedForSweep =
    flues.length - countNeedsInOneToTwoYears - countNeedsSweepNext12Months;

  const totalAffected = flues.length;

  const size = Math.max(
    MathUtils.round(
      (totalAffected / numberOfFluesWithSensors) * (maxSize - minSize) + minSize
    ),
    minSize
  );
  const innerRadius = size * 0.3;
  const fontSize = Math.max(
    16,
    MathUtils.round((totalAffected / numberOfFluesWithSensors) * 30)
  );
  return (
    <PieChart
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      skipAnimation
      slotProps={{ legend: { hidden: true } }}
      series={[
        {
          innerRadius: innerRadius,
          data: [
            {
              id: 0,
              value: countNeedsSweepNext12Months,
              label: t("Needs sweep within {{months}} months", { months: 12 }),
              color: theme.palette.error.main,
            },
            {
              id: 1,
              value: countNeedsInOneToTwoYears,
              label: t("Needs sweep within {{years}} years", { years: "1-2" }),
              color: theme.palette.warning.main,
            },
            {
              id: 2,
              value: countNoNeedForSweep,
              label: t("No need for sweep until after {{date}}", {
                date: t("{{years}} years", { years: 2 }),
              }),
              color: theme.palette.success.main,
            },
          ],
        },
      ]}
      width={size}
      height={size}
    >
      <PieCenterLabel fontSize={fontSize}>{flues.length}</PieCenterLabel>
    </PieChart>
  );
};

const minSize = 35;
const maxSize = 100;
