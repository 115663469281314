import { DateTime, Interval } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export class IntervalUtils {
  static fromDateTimes(
    start: DateTime<true>,
    end: DateTime<true>
  ): Interval<true> {
    return (Interval.fromDateTimes(start, end) ??
      _throw(
        new IllegalStateError(`Invalid Interval: [${start}, ${end}]`)
      )) as Interval<true>;
  }
}
