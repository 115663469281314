import { FC, ReactNode, useMemo } from "react";
import { Dialog as MuiDialog, useTheme } from "@mui/material";
import { DisplayMode } from "./DisplayMode";
import { Breakpoint, SxProps } from "@mui/system";
import { useWindowSize } from "usehooks-ts";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";

export interface AppDialogProps {
  open: boolean;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: Breakpoint | false;
  mode?: DisplayMode;
  onDisplayModeChange: (mode: DisplayMode) => void;
  slots: {
    DialogTitle: ReactNode;
    DialogContent: ReactNode | undefined;
  };
  onClose: () => void;
}

export const AppDialog: FC<AppDialogProps> = (props) => {
  const theme = useTheme();
  const windowWidthComparer = useWindowWidthComparer();
  const fullScreen =
    props.fullScreen ?? windowWidthComparer.lte(SizeClass.Compact);
  const { height: heightInPixels } = useWindowSize();
  const height = useMemo(() => {
    if (fullScreen) {
      return "100%";
    }
    const pixels = Math.min(heightInPixels * 0.95, heightInPixels - 52 * 2);
    return `${pixels}px`;
  }, [heightInPixels, fullScreen]);
  const sx: SxProps = {
    ">.MuiDialog-container": {
      ">.MuiDialog-paper": {
        height: height,
        width: fullScreen ? "unset" : undefined,
        margin: fullScreen ? 0 : undefined,
        maxHeight: fullScreen ? "unset" : undefined,
        ">.MuiDialogTitle-root": {
          maxHeight: "64px",
        },
        ">.MuiDialogContent-root": {
          backgroundColor: theme.palette.background.default,
          display: "flex",
          minWidth: 0,
          minHeight: 0,
          "> *": {
            flex: 1,
            minWidth: 0,
            minHeight: 0,
          },
        },
      },
    },
  };

  return (
    <MuiDialog
      className={"AppDialog"}
      open={props.open}
      fullWidth={props.fullWidth}
      fullScreen={props.fullScreen}
      maxWidth={props.maxWidth}
      onClose={props.onClose}
      sx={sx}
    >
      {props.slots.DialogTitle}
      {props.slots.DialogContent}
    </MuiDialog>
  );
};
