import { FC } from "react";
import {
  Box,
  SxProps,
  Toolbar as MuiToolbar,
  ToolbarOwnProps,
  ToolbarProps as MuiToolbarProps,
} from "@mui/material";
import { useSxMerge } from "../sx/useSxMerge";
import { merge } from "lodash";

export interface ScrollableToolbarProps extends MuiToolbarProps {
  ToolbarProps?: ToolbarOwnProps;
  sx?: SxProps;
}

export const ScrollableToolbar: FC<ScrollableToolbarProps> = (props) => {
  const sx = useSxMerge({ scrollbarWidth: "none" }, props.sx, {
    position: "relative",
    overflowX: "auto",
  });
  const toolbarProps: ToolbarOwnProps = merge(
    { disableGutters: true } as ToolbarOwnProps,
    props.ToolbarProps
  );

  return (
    <Box sx={sx}>
      <MuiToolbar {...toolbarProps}>{props.children}</MuiToolbar>
    </Box>
  );
};
