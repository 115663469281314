import React, { useMemo } from "react";
import { useTheme } from "@mui/material";
import {
  FeatureMarker,
  Map,
  useLatLngBounds,
} from "shared-ts-ui-react-google-maps";
import {
  FlueStatusDto,
  flueStatusToGeoFeature,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { notUndef } from "@airmont/shared/ts/utils/core";

export interface ViewLocationProps {
  flueStatus: FlueStatusDto;
  minHeight?: string;
  minWidth?: string;
}

export const ViewLocation = (props: ViewLocationProps) => {
  const theme = useTheme();
  const mapId =
    theme.palette.mode === "light" ? "b9eccba04faf5ab6" : "aa56e046793435c6";

  const feature = flueStatusToGeoFeature(props.flueStatus);
  const latLngBounds = useLatLngBounds(feature);
  const defaultCenter = useMemo(
    () => notUndef(latLngBounds, (it) => it.getCenter().toJSON()),
    [latLngBounds]
  );
  return (
    <Map
      palette={theme.palette.mode}
      mapId={mapId}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
      defaultCenter={defaultCenter}
      defaultZoom={18}
      backgroundColor={theme.palette.background.default}
    >
      <FeatureMarker feature={feature} />
    </Map>
  );
};
