import { SettingConfig } from "./SettingConfig";
import { SettingKey } from "./SettingKeyDto";
import { AppKey } from "shared-ts-app-info";
import { User } from "shared-ts-utils-authentication";
import { SettingsContextDictionary } from "./UserSettingsContext";
import { StringSetting } from "./StringSetting";

export class ReadOnlyUserSettings {
  readonly app: AppKey;
  readonly user: User | null;
  private readonly settings: SettingsContextDictionary;

  constructor(params: {
    app: AppKey;
    user: User | null;
    settings: SettingsContextDictionary;
  }) {
    this.app = params.app;
    this.user = params.user;
    this.settings = params.settings;
  }

  readString(name: string): string | undefined {
    return this.read(name, StringSetting);
  }

  read<Type>(
    name: string,
    config: SettingConfig<NonNullable<Type>>
  ): Type | undefined {
    const key = SettingKey.from(name, this.app, this.user?.id);
    const asString = this.settings[key.asString];
    if (asString == null) {
      return undefined as Type;
    }
    return config.deserialize(asString);
  }
}
