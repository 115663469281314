import { useContext } from "react";
import { AccessTokenContext } from "./AccessTokenContextProvider";
import { AccessToken } from "./AccessToken";

export const useAccessToken = (): AccessToken => {
  const context = useContext(AccessTokenContext);
  if (context == null) {
    throw new Error(
      "useAccessToken must be used inside a AccessTokenContextProvider"
    );
  }
  return context.token;
};
