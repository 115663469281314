import { SettingConfig } from "./SettingConfig";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { Interval } from "luxon";

export const IntervalSetting: SettingConfig<Interval<true>> = {
  type: "interval",
  serialize: (value: Interval<true>): string => {
    return (
      value.toISO() ??
      _throw(new IllegalStateError("Invalid Interval: " + value))
    );
  },

  deserialize: (value: string): Interval<true> => {
    return (Interval.fromISO(value) ??
      _throw(
        new IllegalStateError("Invalid Interval: " + value)
      )) as Interval<true>;
  },
};
