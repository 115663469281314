import { FC, useState } from "react";
import { PanelResizeHandle } from "react-resizable-panels";
import styled from "styled-components";
import { Box, useTheme } from "@mui/material";

const hPath =
  "M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z";
const vPath =
  "M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z";

export const StyledResizeHandle = styled(PanelResizeHandle)<{
  $width: number | string;
  $backgroundColor: string;
}>`
  flex: 0 0 ${(props) => props.$width};
  position: relative;
  outline: none;

  > .resize-handle-inner {
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    transition: background-color 0.2s linear;

    background-color: ${({ $backgroundColor }) => $backgroundColor};

    svg {
      width: 1em;
      height: 1em;
      position: absolute;
      left: calc(50% - 0.5rem);
      top: calc(50% - 0.5rem);
    }
  }
`;

export interface ResizeHandleProps {
  width?: number | string;
  direction: "horizontal" | "vertical";
}

export const ResizeHandle: FC<ResizeHandleProps> = (props) => {
  const theme = useTheme();
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = () => {
    setHovering(true);
  };
  const handleMouseLeave = () => {
    setHovering(false);
  };

  const backgroundColorDefault = theme.palette.background.default;
  const backgroundColorWhenHovering = theme.palette.divider;
  const backgroundColor = hovering
    ? backgroundColorWhenHovering
    : backgroundColorDefault;

  const handleColor = hovering ? "transparent" : theme.palette.divider;

  return (
    <StyledResizeHandle
      $backgroundColor={backgroundColor}
      $width={props.width ?? "24px"}
    >
      <Box
        className={"resize-handle-inner"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <svg viewBox="0 0 24 24">
          <path
            fill={handleColor}
            d={props.direction === "horizontal" ? hPath : vPath}
          />
        </svg>
      </Box>
    </StyledResizeHandle>
  );
};
