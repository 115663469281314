import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { ChimneySensorSerialNr } from "./ChimneySensor";
import { ChimneySensorSettingsHistoryDto } from "./ChimneySensorSettingsHistory";
import { EntityResponse } from "../shared/entity/EntityResponse";
import { AccessToken } from "shared-ts-utils-authentication";

export class ChimneySensorSettingsDao {
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: AccessToken;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/chimney-sensor/settings/",
    })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  async getListBySensorId(
    id: ChimneySensorSerialNr
  ): Promise<Array<ChimneySensorSettingsHistoryDto>> {
    return this.fetchHelper.get(`get-list-by-sensor-id/${id}`).fetchAsJson();
  }

  async getLatestBySensorId(
    id: ChimneySensorSerialNr
  ): Promise<EntityResponse<ChimneySensorSettingsHistoryDto>> {
    return this.fetchHelper.get(`get-latest-by-sensor-id/${id}`).fetchAsJson();
  }
}
