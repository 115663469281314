import React, { FC, ReactElement, useMemo } from "react";
import {
  FlueStatusDto,
  useBurnDao,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { Container, Stack } from "@mui/material";
import { TabPanel, TabPanelProps } from "@airmont/shared/ts/ui/tabs";
import {
  ChipToggleButton,
  ChipToggleButtonGroup,
  ScrollableToolbar,
  useSxMerge,
} from "shared-ts-mui";
import { Timeframe, useTimeframe } from "@airmont/shared/ts/ui/timeframe";
import { DateTime } from "luxon";
import {
  useComponentSizeFromDense,
  useDense,
} from "@airmont/shared/ts/ui/responsive";
import { useTranslation } from "react-i18next";
import { Property, ValueOption } from "shared-ts-property";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import {
  BurnQualityCountAggregateByTimeLoader,
  BurnQualityCountByTimeChart,
  BurnQualityCountDataPoints,
} from "@airmont/firefly/shared/ts/domain";
import {
  ArraySetting,
  FloatSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

type DiagramFeaturesType =
  | ["IgnitionByQuality", "GoodInPercent"]
  | ["IgnitionByQuality"]
  | ["GoodInPercent"];

export interface SupervisionTabPanelProps extends TabPanelProps {
  flueStatus: FlueStatusDto;
}

export const SupervisionTabPanel: FC<SupervisionTabPanelProps> = (props) => {
  const { flueStatus, tabValue } = props;
  const burnDao = useBurnDao();
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const { t: t_firefly_shared_domain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const timeframeProps = useTimeframe({
    disallowFuture: true,
    maxEnd: DateTime.now().startOf("day"),
    allowedUnits: ["year"],
    persistUsingUserSettings: true,
    storageId: "Flue.SupervisionTabPanel.",
  });
  const start = DateTime.fromISO(flueStatus.audit.created);
  const [diagramFeatures, setDiagramFeatures] =
    useUserSettingWithDefault<DiagramFeaturesType>(
      "Flue.SupervisionTabPanel.diagramFeatures",
      ArraySetting,
      ["GoodInPercent"]
    );

  const goodInPercentChecked = diagramFeatures.some(
    (it) => it === "GoodInPercent"
  );

  const ignitionByQualityChecked = diagramFeatures.some(
    (it) => it === "IgnitionByQuality"
  );

  const diagramFeaturesValues: Array<
    ValueOption & { checked: boolean; disabled: boolean; icon: ReactElement }
  > = useMemo(() => {
    return [
      {
        id: "GoodInPercent",
        label: t_firefly_shared_domain("Good ignitions in %"),
        checked: goodInPercentChecked,
        disabled: goodInPercentChecked && !ignitionByQualityChecked,
        icon: <TimelineRoundedIcon />,
      },
      {
        id: "IgnitionByQuality",
        label: t_firefly_shared_domain("Ignitions by quality"),
        checked: ignitionByQualityChecked,
        disabled: ignitionByQualityChecked && !goodInPercentChecked,
        icon: <BarChartRoundedIcon />,
      },
    ];
  }, [goodInPercentChecked, ignitionByQualityChecked, t_firefly_shared_domain]);

  const [thresholdFactor, setThresholdFactor] =
    useUserSettingWithDefault<number>(
      "Flue.SupervisionTabPanel.thresholdFactor",
      FloatSetting,
      BurnQualityCountDataPoints.DefaultBalanceGoodInPercentOptions
        .thresholdFactor
    );
  const showRawGoodInPercent =
    localStorage.getItem("Flue.SupervisionTabPanel.showRawGoodInPercent") ===
    "true";
  const showThresholdProperty =
    localStorage.getItem("Flue.SupervisionTabPanel.showThresholdProperty") ===
    "true";

  const sx = useSxMerge(props.sx, {
    display: "flex",
    flexDirection: "column",
    pt: 1,
    pb: 1,
  });

  const handleDiagramFeaturesToggleGroupChange = (newValue: Array<string>) => {
    const newDiagramFeatures = newValue as DiagramFeaturesType;
    setDiagramFeatures(newDiagramFeatures);
  };

  return (
    <TabPanel tabValue={tabValue} sx={sx}>
      <Container
        maxWidth={"lg"}
        sx={{
          flexGrow: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack direction={"column"} sx={{ flexGrow: 1, minHeight: 0 }}>
          <ScrollableToolbar
            ToolbarProps={{
              variant: dense ? "dense" : "regular",
              sx: { gap: 1 },
            }}
          >
            <ChipToggleButtonGroup
              value={diagramFeatures}
              onChange={handleDiagramFeaturesToggleGroupChange}
              noneZero
            >
              {diagramFeaturesValues.map((diagramFeature) => {
                return (
                  <ChipToggleButton
                    color={"primary"}
                    value={diagramFeature.id}
                    key={diagramFeature.id}
                    label={diagramFeature.label}
                    icon={diagramFeature.icon}
                    size={componentSize}
                  />
                );
              })}
            </ChipToggleButtonGroup>
            {goodInPercentChecked && showThresholdProperty && (
              <Property<number>
                name={"threshold"}
                label={"Threshold"}
                value={thresholdFactor}
                mode={"edit"}
                editComponent={"Slider"}
                editComponentProps={{
                  Slider: {
                    min: 0,
                    max: 1,
                    step: 0.1,
                  },
                }}
                size={componentSize}
                onReactSetState={setThresholdFactor}
              />
            )}
            <Timeframe {...timeframeProps} layout={"row"} sx={{ ml: "auto" }} />
          </ScrollableToolbar>
          <BurnQualityCountAggregateByTimeLoader
            flueId={flueStatus.id}
            start={start}
            end={timeframeProps.timeframe.end}
            aggregateBy={"month"}
            queryBurnQualityCountAggregate={
              burnDao.queryBurnQualityCountAggregate
            }
          >
            {({ data: aggregates, isFetching }) => {
              return (
                <BurnQualityCountByTimeChart
                  showBurnQualityCount={ignitionByQualityChecked}
                  showGoodInPercent={goodInPercentChecked}
                  showRawGoodInPercent={showRawGoodInPercent}
                  interval={timeframeProps.timeframe}
                  timeUnit={"month"}
                  aggregates={aggregates}
                  thresholdFactor={thresholdFactor}
                  loading={isFetching}
                  sx={{ flexGrow: 1, minHeight: 0 }}
                />
              );
            }}
          </BurnQualityCountAggregateByTimeLoader>
        </Stack>
      </Container>
    </TabPanel>
  );
};
