import { SettingConfig } from "./SettingConfig";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export const BooleanSetting: SettingConfig<boolean> = {
  type: "boolean",
  serialize: (value: boolean): string => {
    return value ? "true" : "false";
  },

  deserialize: (value: string): boolean => {
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    } else {
      throw new IllegalStateError(
        "Deserialized value of a boolean is illegal: " + value
      );
    }
  },
};
