import { FC, ReactNode, useMemo } from "react";
import { PostHogProvider } from "posthog-js/react";
import { PostHogConfig } from "posthog-js";
import { PosthogEnabledContext } from "./PosthogEnabled";
import { useResolveServerEnvironmentFromWindowLocation } from "shared-ts-server-environment";
import { useConsentToCollectUsageData } from "./useConsentToCollectUsageData";
import { useAnonymizeUsageData } from "./useAnonymizeUsageData";

export interface PostHogInitializerProps {
  apiKey: string;
  options?: Partial<PostHogConfig> | undefined;
  children: ReactNode;
}

export const PostHogInitializer: FC<PostHogInitializerProps> = (props) => {
  const serverEnvironment = useResolveServerEnvironmentFromWindowLocation();

  const [consentToCollectUsageData] = useConsentToCollectUsageData();
  const [anonymizeUsageData] = useAnonymizeUsageData();

  const enabled =
    (serverEnvironment !== "local" ||
      localStorage.getItem("PostHog.enabled") === "true") &&
    consentToCollectUsageData;
  const options: Partial<PostHogConfig> = useMemo(() => {
    //
    const options: Partial<PostHogConfig> = {
      ...props.options,
      /* capture_pageview must be set to false for SPAs */
      capture_pageview: false,
      /* Anonymous events by default .. */
      person_profiles: "identified_only",
      ip: !anonymizeUsageData,
    };
    return options;
  }, [props.options, anonymizeUsageData]);

  if (!enabled) {
    return (
      <PosthogEnabledContext.Provider value={false}>
        {props.children}
      </PosthogEnabledContext.Provider>
    );
  }
  return (
    <PostHogProvider apiKey={props.apiKey} options={options}>
      <PosthogEnabledContext.Provider value={true}>
        {props.children}
      </PosthogEnabledContext.Provider>
    </PostHogProvider>
  );
};
