import { User } from "./User";

export interface MockUserOptions {
  id: string;
  name: string;
  email: string;
  claims: Array<string>;
  role: string;
  accessToken: string;
}

export class MockUser implements User {
  private options: MockUserOptions;

  constructor(options: MockUserOptions) {
    this.options = options;
  }

  get id(): string {
    return this.options.id;
  }
  get name(): string {
    return this.options.name;
  }
  get email(): string {
    return this.options.email;
  }

  get accessToken(): string {
    return this.options.accessToken;
  }

  hasClaim(claim: string): boolean {
    return this.options.claims.includes(claim);
  }

  hasRole(role: string): boolean {
    return this.options.role === role;
  }

  getRole(): string {
    return this.options.role;
  }
  isSessionActive(): boolean {
    return true; // TODO
  }
}
