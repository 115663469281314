import React, { FC, useState } from "react";
import { Button } from "shared-ts-mui";
import { Popover, SxProps } from "@mui/material";
import { AuditInfoDto } from "./AuditInfoDto";
import { AuditPane } from "./AuditPane";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import { useTranslation } from "react-i18next";

export interface AuditPaneWithPopperProps {
  audit: AuditInfoDto;
  disablePortal?: boolean;
  anchorButtonSx?: SxProps;
}

export const AuditPaneWithPopper: FC<AuditPaneWithPopperProps> = (props) => {
  const { t } = useTranslation("firefly-shared-ts-domain");
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        startIcon={<CalendarMonthRoundedIcon />}
        onClick={handleClick}
        sx={props.anchorButtonSx}
      >
        {t("Audit")}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <AuditPane audit={props.audit} />
      </Popover>
    </>
  );
};
