import { FC, ReactNode, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import { UnsupportedError } from "@airmont/shared/ts/utils/core";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { useTranslation } from "react-i18next";

export type ReleaseNoteEntryType =
  | "Feature"
  | "Usability"
  | "Bugfix"
  | "Improvement";

export interface ReleaseNoteEntryProps {
  type: ReleaseNoteEntryType;
  heading: ReactNode;
  description?: ReactNode;
}

export const ReleaseNoteEntry: FC<ReleaseNoteEntryProps> = (props) => {
  const { t } = useTranslation("shared-ts-release");
  const resolveTypeIcon = useCallback(
    (type: ReleaseNoteEntryType): OverridableComponent<SvgIconTypeMap> => {
      if (type === "Feature") {
        return CelebrationIcon;
      } else if (type === "Usability" || type === "Improvement") {
        return StarRoundedIcon;
      } else if (type === "Bugfix") {
        return BugReportRoundedIcon;
      } else {
        throw new UnsupportedError("type: " + type);
      }
    },
    []
  );

  const resolveTypeText = useCallback(
    (type: ReleaseNoteEntryType): string => {
      if (type === "Feature") {
        return t("ReleaseNoteEntryType.Functionality");
      } else if (type === "Usability") {
        return t("ReleaseNoteEntryType.Usability");
      } else if (type === "Improvement") {
        return t("ReleaseNoteEntryType.Improvement");
      } else if (type === "Bugfix") {
        return t("ReleaseNoteEntryType.Bugfix");
      } else {
        throw new UnsupportedError("type: " + type);
      }
    },
    [t]
  );

  const TypeIcon = resolveTypeIcon(props.type);
  const typeText = resolveTypeText(props.type);

  return (
    <Box className={"ReleaseNoteEntry"} sx={{ marginBottom: 2 }}>
      <Stack direction={"row"} gap={1} useFlexGap sx={{ marginBottom: 1 }}>
        <Stack direction={"row"} gap={1} useFlexGap sx={{ minWidth: "50px" }}>
          <TypeIcon />
        </Stack>
        <Stack direction={"column"}>
          <Typography
            component={"span"}
            variant={"subtitle2"}
            color={"text.disabled"}
          >
            {typeText}
          </Typography>
          <Typography>{props.heading}</Typography>
          {props.description && (
            <Typography color={"text.secondary"}>
              {props.description}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
