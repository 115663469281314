import * as React from 'react';
import Box from '@mui/material/Box';
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import {FC} from "react";
import {useTheme} from "@mui/material";
export interface CircleQuarterlyByPctIconProps {
  size: number;
  mainColor: string;
  secondaryColor: string;
  pct: 0 | 25 | 50 | 75 | 100 | number;
}

export const CircleQuarterlyByPctIcon: FC<CircleQuarterlyByPctIconProps> = (props) => {
  const getMainColorByPct = () => {
    if(props.pct >= 75)
      return props.mainColor;
    return props.secondaryColor;
  }

  const getOverlayColorByPct = () => {
    if(props.pct < 75)
      return props.mainColor;
    return props.secondaryColor;
  }

  const getOverLayIconByPct = () => {
    const baseBoxStyle = {
      width: props.size,
      height: props.size,
      margin: 0,
      overflow: "hidden",
      position: "relative",
      top: -props.size,
      left: 0
    };

    const baseIconStyle = {
      color: getOverlayColorByPct(),
      height: props.size,
      width: props.size };

    if(props.pct === 75)
      return <Box
        sx={{
          ...baseBoxStyle,
          width: props.size/2,
          height: props.size/2,
        }}>
        <CircleRoundedIcon
          sx={baseIconStyle} />
      </Box>

    if(props.pct === 50)
      return <Box
        sx={{
          ...baseBoxStyle,
          width: props.size/2,
          marginLeft: "50%",
        }}>
        <CircleRoundedIcon sx={{
          ...baseIconStyle,
          position: "relative",
          left: -props.size/2,
        }} />
      </Box>

    if(props.pct === 25)
      return <Box
        sx={{
          ...baseBoxStyle,
          width: props.size/2,
          height: props.size/2,
          marginLeft: "50%",
        }}>
        <CircleRoundedIcon sx={{
          ...baseIconStyle,
          position: "relative",
          left: -props.size/2,
        }} />
      </Box>
  }

  return (
    <Box sx={{ margin: 0, padding: 0, width: props.size, height: props.size, lineHeight: 0 }}>
      <CircleRoundedIcon style={{color: getMainColorByPct(), height: props.size, width: props.size }}/>
      {props.pct > 0 && getOverLayIconByPct()}
    </Box>
  );
}
