import { ChartsAxisContentProps, LineSeriesType } from "@mui/x-charts";
import { FC } from "react";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { With } from "@airmont/shared/ts/ui/react";
import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import { ChartsTooltipMark } from "shared-ts-mui";

const axisValueFormatterContext: AxisValueFormatterContext = {
  location: "tooltip",
};
export const ChartsTooltipAxisContent: FC<ChartsAxisContentProps> = (props) => {
  const theme = useTheme();
  const axisValue = props.axisValue;

  return (
    <Stack
      className={"ChartsTooltipAxisContent"}
      direction={"column"}
      sx={{
        ml: 2,
        mr: 2,
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        borderRadius: 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {axisValue != null && (
        <Typography p={1}>
          {axisValue instanceof Date
            ? props.axis.valueFormatter != null
              ? props.axis.valueFormatter(axisValue, axisValueFormatterContext)
              : DateTime.fromJSDate(axisValue).toLocaleString(
                  DateTime.DATETIME_SHORT
                )
            : axisValue}
        </Typography>
      )}
      <Divider />
      <Stack p={1} direction={"row"} sx={{ alignItems: "center" }}>
        <With>
          {() => {
            const dataIndex = props.dataIndex;
            if (dataIndex == null) {
              return undefined;
            }
            const series = props.series as Array<LineSeriesType>;
            return series.map((itSeries) => {
              const value = itSeries.data?.[dataIndex];
              const valueFormatted =
                value != null
                  ? itSeries.valueFormatter?.(value, {
                      dataIndex: dataIndex,
                    })
                  : "";

              return (
                <Stack
                  key={itSeries.id}
                  direction={"row"}
                  useFlexGap
                  gap={1}
                  sx={{ alignItems: "center" }}
                >
                  <ChartsTooltipMark color={itSeries.color} />
                  <Typography color={"text.secondary"}>
                    {itSeries.label !== undefined &&
                    typeof itSeries.label === "string"
                      ? itSeries.label
                      : itSeries.label?.("tooltip")}
                  </Typography>
                  <Typography sx={{ marginLeft: "auto" }}>
                    {valueFormatted}
                  </Typography>
                </Stack>
              );
            });
          }}
        </With>
      </Stack>
    </Stack>
  );
};
