import React, { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import { useMunicipalityContext } from "@airmont/firefly/chimney-insights/ts/domain";
import { CardLayoutType } from "@airmont/firefly/shared/ts/ui";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Timeframe, useTimeframe } from "@airmont/shared/ts/ui/timeframe";
import {
  BooleanSetting,
  IntSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import {
  EnvironmentMetricsTimeUnitEnum,
  MunicipalityMetricsTimeUnitEnum,
  SingleEnvironmentMetricsLoader,
  SingleMunicipalityMetricsLoader,
} from "@airmont/firefly/shared/ts/domain";
import { useCustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { SizeClass, useWindowSize } from "@airmont/shared/ts/ui/responsive";
import { MainPane } from "./MainPane";
import { With } from "@airmont/shared/ts/ui/react";
import { useTranslation } from "react-i18next";

const autoRollDelaySettingKey = "autoRollDelay";
const autoRollSettingKey = "autoRoll";
export const OverviewPage: FC = () => {
  const theme = useTheme();
  const { windowWidth } = useWindowSize();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const environmentName =
    useCustomerEnvironmentName() ??
    _throw(new IllegalStateError("CustomerEnvironment not set"));
  const { municipality } = useMunicipalityContext();
  const timeframeProps = useTimeframe({
    disallowFuture: true,
  });

  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [autoRoll, setAutoRoll] = useUserSettingWithDefault(
    `OverviewPage.${autoRollSettingKey}`,
    BooleanSetting,
    true
  );
  const [autoRollDelay, setAutoRollDelay] = useUserSettingWithDefault(
    `OverviewPage.${autoRollDelaySettingKey}`,
    IntSetting,
    10
  );

  const handleAutoRollChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAutoRoll(checked);
  };

  const handleAutoRollDelayChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    if (event.target.valueAsNumber > 0) {
      setAutoRollDelay(event.target.valueAsNumber);
    }
  };

  const handleSettingsChange = (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => {
    setSettingsExpanded(expanded);
  };

  return (
    <AppPage
      name={"OverviewPage"}
      hideName={true}
      mainPane={
        <>
          {municipality === undefined && (
            <With>
              {() => {
                const environmentMetricTimeUnit =
                  timeframeProps.timeframeUnit === "year"
                    ? EnvironmentMetricsTimeUnitEnum.Year
                    : EnvironmentMetricsTimeUnitEnum.Month;

                return (
                  <SingleEnvironmentMetricsLoader
                    environment={environmentName}
                    type={environmentMetricTimeUnit}
                    time={timeframeProps.timeframe.start}
                  >
                    {(result) => {
                      const loadingMetrics =
                        result.isLoading || result.isFetching;
                      const metricsValues =
                        result.data === undefined && !loadingMetrics
                          ? {
                              flueCount: 0,
                              burnCount: 0,
                              burnHourCount: 0,
                              chimneyFireCount: 0,
                              sootIndex: 0,
                            }
                          : result.data?.metrics;

                      return (
                        <MainPane
                          timeframeProps={timeframeProps}
                          autoRollDelay={autoRollDelay}
                          autoRoll={autoRoll}
                          loadingMetrics={loadingMetrics}
                          metricValues={metricsValues}
                          timeSeriesOwner={environmentName}
                          sx={{ flexGrow: 1, minWidth: 0, minHeight: 0 }}
                        />
                      );
                    }}
                  </SingleEnvironmentMetricsLoader>
                );
              }}
            </With>
          )}
          {municipality !== undefined && (
            <With>
              {() => {
                const municipalityMetricTimeUnit =
                  timeframeProps.timeframeUnit === "year"
                    ? MunicipalityMetricsTimeUnitEnum.Year
                    : MunicipalityMetricsTimeUnitEnum.Month;
                return (
                  <SingleMunicipalityMetricsLoader
                    municipality={municipality}
                    type={municipalityMetricTimeUnit}
                    time={timeframeProps.timeframe.start}
                  >
                    {(result) => {
                      const loadingMetrics =
                        result.isLoading || result.isFetching;
                      const metricsValues =
                        result.data === undefined && !loadingMetrics
                          ? {
                              flueCount: 0,
                              burnCount: 0,
                              burnHourCount: 0,
                              chimneyFireCount: 0,
                              sootIndex: 0,
                            }
                          : result.data?.metrics;

                      return (
                        <MainPane
                          timeframeProps={timeframeProps}
                          autoRollDelay={autoRollDelay}
                          autoRoll={autoRoll}
                          timeSeriesOwner={municipality}
                          loadingMetrics={loadingMetrics}
                          metricValues={metricsValues}
                          sx={{ flexGrow: 1, minWidth: 0, minHeight: 0 }}
                        />
                      );
                    }}
                  </SingleMunicipalityMetricsLoader>
                );
              }}
            </With>
          )}
        </>
      }
      supportingPaneProps={{
        label: t("Settings"),
        resizePanelProps: {
          horizontal: {
            style: {
              maxWidth: 391,
            },
          },
          vertical: {
            style: {
              maxHeight: 350,
            },
          },
        },
      }}
      supportingPane={({ layout }) => (
        <Box
          sx={{
            padding: theme.spacing(1),
            flexGrow: 1,
            display: "flex",
            flexDirection:
              layout === "PanelGroupHorizontal" ||
              layout === "SwipeableBottomPane"
                ? "column"
                : "row",
            justifyContent:
              layout === "PanelGroupHorizontal" ||
              layout === "SwipeableBottomPane"
                ? "flex-start"
                : "flex-start",
            alignItems:
              layout === "PanelGroupHorizontal" ||
              layout === "SwipeableBottomPane"
                ? "center"
                : "flex-start",
          }}
        >
          <Card
            elevation={3}
            sx={{
              overflow: "visible",
              paddingTop: 2,
              paddingLeft: 1,
              paddingRight: 1,
              paddingBottom: 1,
            }}
          >
            <Timeframe
              {...timeframeProps}
              layout={windowWidth === SizeClass.Compact ? "row" : "standard"}
            />
          </Card>
          <Box sx={{ margin: 3 }} />
          <Accordion
            expanded={settingsExpanded}
            onChange={handleSettingsChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t("Behaviour")}
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  paddingTop: theme.spacing(1),
                  display: "flex",
                  flexDirection: "column",
                  rowGap: theme.spacing(1),
                }}
              >
                <FormControlLabel
                  label={t("Automatic rolling of charts")}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={autoRoll}
                      onChange={handleAutoRollChange}
                    />
                  }
                />
                <TextField
                  label={t("Time Delay")}
                  helperText={t(
                    "Time Delay.in seconds before the next chart is displayed"
                  )}
                  type={"number"}
                  value={autoRollDelay}
                  onChange={handleAutoRollDelayChange}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    />
  );
};

export const resolveCardLayoutTypeFromSizeClass = (
  sizeClass: SizeClass
): CardLayoutType => {
  /**
   * layout === "compact"
   ? "compact"
   : layout === "medium"
   ? "compact"
   : "standard"
   */

  if (sizeClass === SizeClass.Compact) {
    return "tiny";
  } else if (sizeClass === SizeClass.Medium) {
    return "compact";
  } else {
    return "standard";
  }
};
