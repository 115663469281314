import { AllSeriesType } from "@mui/x-charts/models/seriesType";
import { MathUtils, notUndef } from "@airmont/shared/ts/utils/core";
import { merge } from "lodash";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { BarSeriesType, LineSeriesType } from "@mui/x-charts";
import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";
import { MuiTimeSeriesOptions } from "./TimeSeriesMuiChart";

export const resolveSeries = (
  timeSeries: Array<TimeSeries<number | null>>,
  timeSeriesOptions?: Array<MuiTimeSeriesOptions>
): Array<AllSeriesType> => {
  return timeSeries.map((series, seriesIndex) => {
    const seriesOptions = notUndef(timeSeriesOptions, (timeSeriesOptions) =>
      timeSeriesOptions.length >= seriesIndex - 1
        ? timeSeriesOptions[seriesIndex]
        : timeSeriesOptions[0]
    );
    const defaultOptions: AllSeriesType = merge(
      {
        type: "line",
        showMark: false,
        data: series.points.map((point) => point.value),
        valueFormatter: (value: number | null) => {
          let formatted = "";
          if (value != null) {
            formatted += MathUtils.round(value);
          }
          if (series.info.unit != null) {
            formatted += " " + series.info.unit;
          }
          return formatted;
        },
      },
      seriesOptions
    );
    const options:
      | MakeOptional<LineSeriesType, "type">
      | MakeOptional<BarSeriesType, "type">
      | undefined = timeSeriesOptions?.[seriesIndex];

    return merge(defaultOptions, options);
  });
};
