import { SensorFeature } from "./SensorFeature";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";

export class SensorFeatureUtils {
  static sensorsToFeatures(
    sensors: Array<ChimneySensorStatusDto>
  ): Array<SensorFeature> {
    return sensors
      .filter((it) => it.geoLocation != null)
      .map((sensor) => {
        return {
          type: "Feature",
          id: sensor.id,
          geometry: {
            type: "Point",
            coordinates: [sensor.geoLocation?.lng, sensor.geoLocation?.lat],
          },
          properties: {
            sensors: [sensor],
          },
        } as SensorFeature;
      });
  }

  static groupSensorsOnSameLocation(
    features: Array<SensorFeature>
  ): Array<SensorFeature> {
    const featuresByLocation: Record<string, SensorFeature> = {};
    const featuresGroupedOnLocation: Array<SensorFeature> = [];
    features.forEach((feature) => {
      const existing =
        featuresByLocation[JSON.stringify(feature.geometry.coordinates)];
      if (existing != null) {
        existing.properties.sensors.push(...feature.properties.sensors);
        existing.id = existing.id + "|" + feature.id;
      } else {
        featuresByLocation[JSON.stringify(feature.geometry.coordinates)] =
          feature;
        featuresGroupedOnLocation.push(feature);
      }
    });

    return featuresGroupedOnLocation;
  }
}
