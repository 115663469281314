import * as React from 'react';
import Box from '@mui/material/Box';
import LocalFireDepartmentRoundedIcon from "@mui/icons-material/LocalFireDepartmentRounded";
import {FC} from "react";
import {useTheme} from "@mui/material";
export interface FlameByPctIconProps {
  size: number;
  pct: 0 | 25 | 50 | 75 | 100 | number;
}

export const FlameByPctIcon: FC<FlameByPctIconProps> = (props) => {
  const theme = useTheme();

  const getMainColorByPct = () => {
    if(props.pct >= 75)
      return theme.palette.error.main;
    return "#ccc";
  }

  const getOverlayColorByPct = () => {
    if(props.pct < 75)
      return theme.palette.error.main;
    return "#ccc";
  }

  const getOverLayIconByPct = () => {
    const baseBoxStyle = {
      width: props.size,
      height: props.size,
      margin: 0,
      overflow: "hidden",
      position: "relative",
      top: -props.size,
      left: 0
    };

    const baseIconStyle = {
      color: getOverlayColorByPct(),
      height: props.size,
      width: props.size };

    if(props.pct === 75)
      return <Box
        sx={{
          ...baseBoxStyle,
          width: props.size/2,
          height: props.size/2,
        }}>
        <LocalFireDepartmentRoundedIcon
          sx={baseIconStyle} />
      </Box>

    if(props.pct === 50)
      return <Box
        sx={{
          ...baseBoxStyle,
          width: props.size/2,
          marginLeft: "50%",
        }}>
        <LocalFireDepartmentRoundedIcon sx={{
          ...baseIconStyle,
          position: "relative",
          left: -props.size/2,
        }} />
      </Box>

    if(props.pct === 25)
      return <Box
        sx={{
          ...baseBoxStyle,
          width: props.size/2,
          height: props.size/2,
          marginLeft: "50%",
        }}>
        <LocalFireDepartmentRoundedIcon sx={{
          ...baseIconStyle,
          position: "relative",
          left: -props.size/2,
        }} />
      </Box>
  }

  return (
    <Box sx={{ margin: 0, padding: 0, width: props.size, height: props.size, lineHeight: 0 }}>
      <LocalFireDepartmentRoundedIcon style={{color: getMainColorByPct(), height: props.size, width: props.size }}/>
      {props.pct > 0 && getOverLayIconByPct()}
    </Box>
  );
}
