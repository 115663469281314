import { DateTime, Duration, Interval } from "luxon";
import { FC, ReactNode, useMemo } from "react";
import { Stack, SxProps, Typography } from "@mui/material";
import { useSxMerge } from "shared-ts-mui";
import { EventImpl } from "@airmont/shared-ts-event";
import { RenderEventParams } from "../RenderEventParams";
import { DayEvents } from "./DayEvents";
import { DayTimeslots } from "./DayTimeslots";
import { useCalendarSettings } from "../CalendarSettingsProvider";

export interface DayProps {
  date: DateTime<true>;
  dateTimeNow: DateTime<true>;
  isToday?: boolean;
  variant: "events" | "timeslots";
  timeslotDuration?: Duration;
  events: Array<EventImpl>;
  sx?: SxProps;
  children?: ReactNode;
  renderEvent: (params: RenderEventParams) => ReactNode;
  onNewEvent?: (interval: Interval<true> | DateTime<true> | null) => void;
}

export const Day: FC<DayProps> = (props) => {
  const { date, dateTimeNow, variant, renderEvent } = props;
  const isToday = props.isToday ?? false;
  const settings = useCalendarSettings();
  const dateStart = useMemo(() => date.startOf("day"), [date]);
  const dateEnd = useMemo(() => dateStart.plus({ day: 1 }), [dateStart]);

  const events = useMemo(() => {
    return props.events.filter(
      (event) => event.start >= dateStart && event.start < dateEnd
    );
  }, [dateStart, dateEnd, props.events]);

  const childEventsCount = useMemo(() => {
    return events.reduce((a, b) => {
      return a + b.countChildEvents();
    }, 0);
  }, [events]);

  const sx = useSxMerge(props.sx, {
    minHeight: 0,
    alignItems: "stretch",
  });

  return (
    <Stack className={"Day"} direction={"column"} sx={sx}>
      {variant === "events" && (
        <Stack
          sx={{
            flex: 1,
            minHeight: 0,
            alignItems: "stretch",
          }}
        >
          <Typography
            align={"right"}
            sx={{
              pr: 1,
              flexShrink: 0,
              borderBottom: isToday
                ? "1px solid " + settings.nowColor
                : "1px solid transparent",
            }}
          >
            {date.day}
          </Typography>
          <DayEvents
            date={date}
            events={events}
            renderEvent={renderEvent}
            onNewEvent={props.onNewEvent}
          />
        </Stack>
      )}
      {variant === "timeslots" && props.timeslotDuration != null && (
        <DayTimeslots
          date={date}
          dateTimeNow={dateTimeNow}
          timeslotDuration={props.timeslotDuration}
          isToday={isToday}
          events={events}
          renderEvent={renderEvent}
          onNewEvent={props.onNewEvent}
        />
      )}
    </Stack>
  );
};
