import { createContext, FC, ReactNode, useMemo } from "react";
import { AppInfo } from "./AppInfo";
import { useQuery } from "@tanstack/react-query";

export const AppInfoContext = createContext<AppInfo>({} as AppInfo);

export interface AppInfoProviderProps {
  appInfo: AppInfo;
  getBuildNumber?: () => Promise<string>;
  children: ReactNode | ((appInfo: AppInfo) => ReactNode);
}

export const AppInfoProvider: FC<AppInfoProviderProps> = (props) => {
  const buildNumberFromServer = useQuery({
    queryKey: ["AppInfoProvider.getBuildNumber"],
    queryFn: () => props.getBuildNumber?.() ?? null,
    enabled: true,
    staleTime: 10 * 60 * 60 * 60 * 1000,
    gcTime: 15 * 60 * 60 * 60 * 1000,
  });

  const appInfo: AppInfo = useMemo(() => {
    let buildVersion = process.env["NX_PUBLIC_BUILD_NUMBER"];

    if (buildNumberFromServer.data != null) {
      buildVersion = buildNumberFromServer.data;
    }
    return { ...props.appInfo, buildVersion: buildVersion };
  }, [props.appInfo, buildNumberFromServer.data]);

  return (
    <AppInfoContext.Provider value={appInfo}>
      {typeof props.children === "function"
        ? props.children(appInfo)
        : props.children}
    </AppInfoContext.Provider>
  );
};
