import {
  ActionGroup,
  isSelectableAction,
  ItemVariant,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import { renderMenuItem } from "./renderMenuItem";
import { Menu as MuiMenu, SxProps } from "@mui/material";
import React, { FC, useMemo } from "react";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";
import { PopoverOrigin } from "@mui/material/Popover/Popover";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { useSxMerge } from "shared-ts-mui";

export interface MenuProps {
  anchorRef?: React.RefObject<HTMLDivElement>;
  anchor?: HTMLElement | null;
  open: boolean;
  variant: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: ActionGroup;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps;
  onClose: () => void;
  onItemSelected?: (item: SelectableItem) => void;
}

export const Menu: FC<MenuProps> = (props) => {
  const { open, variant, layout, layoutDirection, item } = props;
  let anchor: HTMLElement | null = null;
  if (props.anchor !== undefined) {
    anchor = props.anchor;
  } else if (props.anchorRef !== undefined) {
    anchor = props.anchorRef.current;
  } else {
    throw new IllegalArgumentError(
      "Either an anchor or an anchorRef must be given"
    );
  }
  const handleClose = () => {
    props.onClose();
  };

  const sx = useSxMerge(props.sx, {
    "&.variant-square": {
      ">.MuiMenu-paper": {
        borderRadius: "9px",
        ">.MuiList-root": {
          ">.GroupMenu": {
            borderRadius: 0,
          },
        },
      },
    },
    "&.variant-rounded": {
      ">.MuiMenu-paper": {
        borderRadius: "9px",
      },
    },
  });

  const className = useMemo(() => {
    let classNameBuilder =
      "GroupMenu-menu variant-" + variant + " layout-" + layout;

    if (layoutDirection != null) {
      classNameBuilder += " direction-" + layoutDirection;
    }
    return classNameBuilder;
  }, [layout, variant, layoutDirection]);

  return (
    <MuiMenu
      anchorEl={anchor}
      className={className}
      sx={sx}
      open={open}
      onClose={handleClose}
      anchorPosition={{
        top: 0,
        left: 4,
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={
        props.anchorOrigin ?? {
          vertical: "bottom",
          horizontal: "center",
        }
      }
      transformOrigin={
        props.transformOrigin ?? {
          vertical: "top",
          horizontal: "left",
        }
      }
    >
      {props.item?.items
        .filter((it) => it != null)
        .map((childItem, index) => {
          const childItemLayoutDirection = isSelectableAction(childItem)
            ? "row"
            : layoutDirection;
          return renderMenuItem({
            variant: variant,
            layout: layout,
            item: childItem,
            index: index,
            onSelected: props.onItemSelected,
            layoutDirection: childItemLayoutDirection,
            childComponentPreferences: {
              groupMenu: {
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              },
            },
          });
        })}
    </MuiMenu>
  );
};
