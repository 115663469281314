import { Checkbox, FormControlLabel } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

export interface ConsentToCollectSentryDataCheckboxProps {
  value: boolean | null | undefined;
  onChange: (value: boolean) => void;
}

export const ConsentToCollectSentryDataCheckbox: FC<
  ConsentToCollectSentryDataCheckboxProps
> = (props) => {
  const { t } = useTranslation("shared-ts-sentry");
  const handleConsentCheckboxChange = (
    event: ChangeEvent,
    checked: boolean
  ) => {
    props.onChange(checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.value ?? undefined}
          onChange={handleConsentCheckboxChange}
        />
      }
      label={t("I consent to the monitoring of ...")}
    />
  );
};
