import { BatteryLevelEnum } from "./BatteryLevelEnum";
import { BatteryLevelThresholds } from "./BatteryLevelThresholds";

export class BatteryLevelUtils {
  /*
       0 - 2700 - Critical
    2700 - 2750 - Poor
    2750 - 2900 - Good
    3000 -  MAX - Excellent
   */
  static getStatus(voltage: number): BatteryLevelEnum {
    if (voltage === 0) {
      return BatteryLevelEnum.Unknown;
    } else if (voltage >= BatteryLevelThresholds.excellent) {
      return BatteryLevelEnum.Excellent;
    } else if (voltage >= BatteryLevelThresholds.good) {
      return BatteryLevelEnum.Good;
    } else if (voltage >= BatteryLevelThresholds.poor) {
      return BatteryLevelEnum.Poor;
    } else {
      return BatteryLevelEnum.Critical;
    }
  }
}
