import { DateTime } from "luxon";

export class MonthWheel {
  private readonly startYear: number;
  private readonly startMonth: number;

  constructor(args: { startYear: number; startMonth: number }) {
    this.startYear = args.startYear;
    this.startMonth = args.startMonth;
  }
  run<T>(count: number, fn: (month: number, year: number) => T): Array<T> {
    let currYear = this.startYear;
    let currMonth = this.startMonth;
    const result: Array<T> = [];
    for (let i = 0; i < count; i++) {
      result.push(fn(currMonth, currYear));
      currMonth += 1;
      if (currMonth > 12) {
        currMonth = 1;
        currYear += 1;
      }
    }

    return result;
  }

  runUntilTime<T>(
    untilTime: DateTime<true>,
    fn: (month: number, year: number) => T
  ): Array<T> {
    let currYear = this.startYear;
    let currMonth = this.startMonth;
    const result: Array<T> = [];
    for (
      let time = DateTime.local(currYear, currMonth);
      time < untilTime;
      time = time.plus({ month: 1 })
    ) {
      result.push(fn(currMonth, currYear));
      currMonth += 1;
      if (currMonth > 12) {
        currMonth = 1;
        currYear += 1;
      }
    }

    return result;
  }
}
