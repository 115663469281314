import { BurnAssessment } from "./BurnAssessment";
import { green, grey, red } from "@mui/material/colors";

const good = green[500];
const normal = green[200];
const bad = red[500];
const unknown = grey[500];

export const BurnAssessmentColor: Record<BurnAssessment, string> = {
  Good: good,
  Normal: normal,
  Bad: bad,
  Unknown: unknown,
};
