import { Expr } from "../../Expr";

export interface IsNotEmptyXpr {
  $type: "isNotEmpty";
  instance: Expr;
}

export const isNotEmptyExpr = (instance: Expr): IsNotEmptyXpr => ({
  $type: "isNotEmpty",
  instance: instance,
});
