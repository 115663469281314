import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, LinearProgress, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import {
  NumberTimeSeriesImpl,
  TimeSeries,
} from "@airmont/firefly/shared/ts/timeseries";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { merge } from "lodash";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { DateTimeISO } from "@airmont/shared/ts/types";
import {
  TimeSeriesChart,
  TimeSeriesChartOptions,
  TimeSeriesOption,
} from "@airmont/firefly/shared/ts/timeseries-apex-chart";
import { useTimeSeriesDao } from "@airmont/firefly/shared/ts/timeseries-dao";

export interface ChimneyFireChartProps {
  id: string;
  date: DateTime;
  threshold?: number;
  sx?: SxProps<Theme>;
}

export const ChimneyFireChart: FC<ChimneyFireChartProps> = (props) => {
  const theme = useTheme();
  const [timeSeries, setTimeSeries] = useState<
    Array<TimeSeries<number>> | undefined
  >(undefined);
  const [lastTime, setLastTime] = useState<DateTime | undefined>(undefined);
  const [chartMaxTemp, setChartMaxTemp] = useState<number>(600);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const timeSeriesDao = useTimeSeriesDao();

  const getMaxTempForSeries = (
    series: TimeSeries<number> | undefined
  ): number => {
    if (!series) return -1;
    let maxTemp = 0;
    series.points.forEach((point) => {
      if (point.value > maxTemp) {
        maxTemp = point.value;
      }
    });

    return Math.ceil(maxTemp / 100) * 100;
  };

  useEffect(
    function initialLoad() {
      let timerId = -1;
      const doInitialLoadAsync = async () => {
        setLoading(true);
        const initialTimeSeriesDto = await timeSeriesDao.getChimneyFire(
          props.id
        );
        if (initialTimeSeriesDto == null) {
          setLoading(false);
          return;
        }

        const initialTimeSeries =
          NumberTimeSeriesImpl.fromNumberTimeSeriesDto(initialTimeSeriesDto);

        setTimeSeries([initialTimeSeries]);

        const lastPoint = initialTimeSeries.lastPoint;
        if (lastPoint != null) {
          setLastTime(lastPoint.time);
        }

        const maxTemp = getMaxTempForSeries(initialTimeSeries);
        setChartMaxTemp(maxTemp);

        setLoading(false);

        timerId = setInterval(() => {
          setRefreshCount((prevRefreshCount) => prevRefreshCount + 1);
        }, 15000) as unknown as number;
      };
      doInitialLoadAsync();
      return () => {
        clearInterval(timerId);
      };
    },
    [timeSeriesDao, props.id]
  );

  useEffect(() => {
    const doRefresh = async () => {
      if (lastTime == null) {
        return;
      }
      setLoading(true);
      const newTimeSeriesDto = await timeSeriesDao.getChimneyFire(props.id, {
        refresh: true,
        previousTime: lastTime,
      });

      if (newTimeSeriesDto == null || newTimeSeriesDto.points.length === 0) {
        setLoading(false);
        return;
      }
      const newTimeSeries =
        NumberTimeSeriesImpl.fromNumberTimeSeriesDto(newTimeSeriesDto);

      const lastPoint = newTimeSeries.lastPoint;
      if (lastPoint != null) {
        setLastTime(lastPoint.time);
      }

      const maxTemp = getMaxTempForSeries(newTimeSeries);

      if (maxTemp > chartMaxTemp) {
        setChartMaxTemp(maxTemp);
      }

      setTimeSeries((previous) => {
        if (previous == null) {
          throw new IllegalStateError("should not happen");
        }
        return [
          new NumberTimeSeriesImpl({
            info: previous[0].info,
            points: [...previous[0].points, ...newTimeSeries.points],
          }),
        ];
      });
      setLoading(false);
    };
    if (refreshCount > 0) {
      doRefresh();
    }
  }, [refreshCount, lastTime, props.id, timeSeriesDao]);

  const yAxisAnnotations: Array<YAxisAnnotations> = useMemo(() => {
    if (props.threshold == null) {
      return [];
    }

    const yAxisAnnotations: Array<YAxisAnnotations> = [];
    yAxisAnnotations.push({
      y: props.threshold,
      borderColor: theme.palette.warning.main,
      label: {
        position: "left",
        textAnchor: "start",
        borderColor: theme.palette.warning.main,
        style: {
          color: theme.palette.getContrastText(theme.palette.warning.dark),
          background: theme.palette.warning.dark,
          fontSize: `14px`,
        },
        text: `Terskelverdi: ${props.threshold} °C`,
      },
    });
    return yAxisAnnotations;
  }, [theme, props.threshold]);

  const getXAxisAnnotationAnchor = (
    time: number,
    series: TimeSeries<number>[] | undefined
  ): string => {
    const min = timeSeries?.[0].points[0].time.toUTC().toMillis();
    const max = timeSeries?.[0].points[timeSeries?.[0].points.length - 1].time
      .toUTC()
      .toMillis();
    let anchor = "end";
    if (time - (min ?? 0) < (max ?? 0) - time) {
      anchor = "start";
    }
    return anchor;
  };

  const xAxisAnnotations: Array<XAxisAnnotations> = useMemo(() => {
    const xAxisAnnotations: Array<XAxisAnnotations> = [];
    const time = props.date.toUTC().toMillis();
    const anchor = getXAxisAnnotationAnchor(time, timeSeries);
    xAxisAnnotations.push({
      x: time,
      borderColor: theme.palette.warning.main,
      label: {
        position: "bottom",
        textAnchor: anchor,
        orientation: "horizontal",
        offsetY: -20,
        borderColor: theme.palette.warning.main,
        style: {
          color: theme.palette.getContrastText(theme.palette.warning.dark),
          background: theme.palette.warning.dark,
          fontSize: `14px`,
        },
        text: `Temperaturalarm: ${props.date.toLocaleString(
          DateTime.DATETIME_SHORT
        )}`,
      },
    });
    return xAxisAnnotations;
  }, [props.date, getXAxisAnnotationAnchor, timeSeries, theme.palette]);

  const sx = useMemo(() => {
    return merge({}, props.sx, {
      flex: 1,
      minHeight: 0,
      position: "relative",
      display: "flex",
      flexDirection: "column",
    });
  }, [props.sx]);

  return (
    <Box className={"ChimneyFireChart"} sx={sx}>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}

      {timeSeries && (
        <TimeSeriesChart
          timeSeries={timeSeries}
          timeSeriesOptions={
            [
              {
                displayName: "Temperaturalarm",
                type: "line",
                color: theme.palette.error.main,
              },
            ] as Array<TimeSeriesOption<number>>
          }
          options={
            {
              noData: {
                text: "Ingen temperatur-serie funnet",
              },
              xAxis: {
                type: "datetime",
                title: {
                  text: "Tid",
                },
                crosshairs: {
                  show: true,
                },
              },
              yAxis: {
                title: {
                  text: "Temperatur",
                },
                unit: "°C",
                max: chartMaxTemp,
              },
              tooltip: {
                x: {
                  formatter: (value) => {
                    const dateTime = DateTime.fromMillis(Number(value));
                    return `Tid : ${dateTime.toLocaleString(
                      DateTime.DATETIME_SHORT_WITH_SECONDS
                    )}`;
                  },
                },
              },
              annotations: {
                yaxis: yAxisAnnotations,
                xaxis: xAxisAnnotations,
              },
            } as TimeSeriesChartOptions<DateTimeISO>
          }
          sx={{ flex: 1, minHeight: 0 }}
        />
      )}
    </Box>
  );
};
