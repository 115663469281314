import { FC, ReactNode, useMemo } from "react";
import {
  axisClasses,
  BarPlot,
  BarSeriesType,
  ChartsAxisHighlight,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  LineSeriesType,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { SxProps } from "@mui/material";
import { resolveXAxisConfig } from "./resolveXAxisConfig";
import { resolveYAxisConfig } from "./resolveYAxisConfig";
import {
  useSxMerge,
  XAxisDefaultConfig,
  YAxisDefaultConfig,
} from "shared-ts-mui";
import { AllSeriesType } from "@mui/x-charts/models/seriesType";
import { ChartsTooltipAxisContent } from "./ChartsTooltipAxisContent";
import { resolveSeries } from "./resolveSeries";
import { resolveTimeAxisScaleType } from "./resolveTimeAxisScaleType";
import { resolveTimeAxisHighlightType } from "./resolveChartAxisHighlightType";
import { CardinalDirections } from "@mui/x-charts/models/layout";
import { BarPlotProps } from "@mui/x-charts/BarChart/BarPlot";
import { LinePlotProps } from "@mui/x-charts/LineChart/LinePlot";

export type MuiTimeSeriesOptions = Omit<
  MakeOptional<LineSeriesType, "type"> | MakeOptional<BarSeriesType, "type">,
  "data"
>;

export interface TimeSeriesMuiChartProps {
  timeSeries: Array<TimeSeries<number | null>>;
  timeSeriesOptions?: Array<MuiTimeSeriesOptions>;
  yAxisConfig?: Array<YAxisDefaultConfig>;
  yAxisLabelOffset?: { x?: number };
  xAxisLabelOffset?: { y?: number };
  xAxisConfig?: XAxisDefaultConfig;
  slotProps?: { BarPlotProps?: BarPlotProps; LinePlotProps?: LinePlotProps };
  width?: number;
  height?: number;
  margin?: Partial<CardinalDirections<number>>;
  sx?: SxProps;
  children?: ReactNode;
}

export const TimeSeriesMuiChart: FC<TimeSeriesMuiChartProps> = (props) => {
  const {
    timeSeries,
    timeSeriesOptions,
    xAxisConfig,
    yAxisConfig,
    width,
    height,
  } = props;

  const timeAxisScaleType = useMemo(
    () => resolveTimeAxisScaleType(timeSeriesOptions),
    [timeSeriesOptions]
  );
  const timeAxisHighlightType = useMemo(
    () => resolveTimeAxisHighlightType(timeAxisScaleType),
    [timeAxisScaleType]
  );

  const xAxis: Array<XAxisDefaultConfig> = useMemo(() => {
    return resolveXAxisConfig(timeSeries, xAxisConfig, timeAxisScaleType);
  }, [timeSeries, xAxisConfig, timeAxisScaleType]);

  const yAxis: Array<YAxisDefaultConfig> = useMemo(() => {
    return resolveYAxisConfig(timeSeries, yAxisConfig);
  }, [timeSeries, yAxisConfig]);

  const series: Array<AllSeriesType> = useMemo(() => {
    return resolveSeries(timeSeries, timeSeriesOptions);
  }, [timeSeriesOptions, timeSeries]);

  const sx = useSxMerge(props.sx, {
    ...(props.yAxisLabelOffset?.x != null && {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: `translateX(${props.yAxisLabelOffset.x}px)`,
      },
    }),
    ...(props.xAxisLabelOffset?.y != null && {
      [`.${axisClasses.bottom} .${axisClasses.label}`]: {
        transform: `translateY(${props.xAxisLabelOffset?.y}px)`,
      },
    }),
  });

  return (
    <ResponsiveChartContainer
      margin={props.margin}
      xAxis={xAxis}
      yAxis={yAxis}
      series={series}
      width={width}
      height={height}
      sx={sx}
    >
      <BarPlot {...props.slotProps?.BarPlotProps} />
      <LinePlot {...props.slotProps?.LinePlotProps} />
      <ChartsXAxis />
      <ChartsYAxis />
      <ChartsTooltip
        slots={{
          axisContent: ChartsTooltipAxisContent,
        }}
      />
      <ChartsAxisHighlight x={timeAxisHighlightType} />
      {props.children}
    </ResponsiveChartContainer>
  );
};
