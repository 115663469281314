import { isSelectableItem, SelectableItem } from "./SelectableItem";
import { ReactNode } from "react";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { isItem } from "./Item";

export interface RouteItem extends SelectableItem {
  type: "RouteItem";
  route: string;
  name: string;
  nameBreakable?: string;
  icon?: ReactNode;
}

export function isRouteItem(obj: object): obj is RouteItem {
  const check =
    isSelectableItem(obj) && "type" in obj && obj.type === "RouteItem";

  if (
    !check &&
    isItem(obj) &&
    obj.type === "RouteItem" &&
    !("selectable" in obj)
  ) {
    throw new IllegalStateError(
      "A RouteItem must have selectable property of type boolean"
    );
  }

  if (check) {
    if (!("route" in obj && typeof obj.route === "string")) {
      throw new IllegalStateError("A RouteItem requires route property");
    }
    if (!("name" in obj && typeof obj.name === "string")) {
      throw new IllegalStateError("A RouteItem requires name property");
    }
  }
  return check;
}
