import { Feature, Point } from "geojson";
import { LatLng } from "./LatLng";

export class LatLngBounds {
  static fromFeatures(
    features: Array<Feature<Point>>
  ): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();
    features.forEach((feature) => {
      bounds.extend(LatLng.fromFeature(feature));
    });
    return bounds;
  }
}
