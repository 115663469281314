import { DateTimeISO, DuenessStringsEnum } from "@airmont/shared/ts/types";
import { FlueId } from "../flue/FlueId";
import { AuditInfoDto } from "../../audit/AuditInfoDto";
import { WasteDto } from "./WasteDto";
import { DateTime } from "luxon";

export type SweepingId = string;

export interface SweepingDto {
  id: SweepingId;
  flueId: FlueId;
  time: DateTimeISO;
  wasteAmount?: WasteDto;
  dueness?: DuenessStringsEnum;
  audit: AuditInfoDto;
}

export interface Sweeping {
  id: SweepingId;
  flueId: FlueId;
  time: DateTime<true>;
  wasteAmount?: WasteDto;
  dueness?: DuenessStringsEnum;
  audit: AuditInfoDto;
}

export class SweepingImpl implements Sweeping {
  readonly id: string;
  readonly flueId: FlueId;
  readonly time: DateTime<true>;
  readonly wasteAmount?: WasteDto;
  readonly dueness?: DuenessStringsEnum;
  readonly audit: AuditInfoDto;

  constructor(args: SweepingDto) {
    this.id = args.id;
    this.flueId = args.flueId;
    this.time = DateTime.fromISO(args.time) as DateTime<true>;
    this.wasteAmount = args.wasteAmount;
    this.dueness = args.dueness;
    this.audit = args.audit;
  }
}
