import { Breakpoint, Breakpoints } from "@mui/material";

export class BreakpointUtils {
  static resolveBreakpoint(
    width: number,
    breakpoints: Breakpoints
  ): Breakpoint {
    let result: Breakpoint = "xs";
    const breakpointsReversed = Object.entries(breakpoints.values).reverse();
    for (let i = 0; i < breakpointsReversed.length; i++) {
      const curr = breakpointsReversed[i];
      if (width >= curr[1]) {
        result = curr[0] as Breakpoint;
        break;
      }
    }

    return result;
  }
}
