import { FC, ReactNode } from "react";
import { RenderEventParams } from "../RenderEventParams";
import { Event } from "@airmont/shared-ts-event";
import { List, ListItem, ListItemButton } from "@mui/material";
import { DateTime, Interval } from "luxon";
import { useCalendarSettings } from "../CalendarSettingsProvider";

export interface DayEventsProps {
  date: DateTime<true>;
  events: Array<Event>;
  renderEvent: (params: RenderEventParams) => ReactNode;
  onNewEvent?: (date: Interval<true> | DateTime<true> | null) => void;
}
export const DayEvents: FC<DayEventsProps> = (props) => {
  const { date, events } = props;
  const calendarSettings = useCalendarSettings();
  const isWeekend = date.isWeekend;

  const handleNewEventClick = () => {
    props.onNewEvent?.(date);
  };

  return (
    <List
      className={"DayEvents"}
      sx={{
        flex: 1,
        backgroundColor: isWeekend
          ? calendarSettings.weekendBackgroundColor
          : undefined,
        ".MuiListItem-root": {
          pt: 0,
          pb: 0,
          ".MuiListItemButton-root": {
            p: 0,
            alignItems: "flex-start",
            ".MuiListItemIcon-root": {
              pl: 0.5,
              pt: 1,
              svg: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }}
    >
      {events.length > 0 &&
        props.renderEvent &&
        events.map((event, index) => {
          return props.renderEvent({
            event,
            type: "ListItem",
            clickAction: "view:Card",
          });
        })}
      <ListItem disableGutters sx={{ height: "100%" }}>
        <ListItemButton
          onClick={handleNewEventClick}
          sx={{ height: "100%" }}
        ></ListItemButton>
      </ListItem>
    </List>
  );
};
