import { FC } from "react";
import { DayHeader } from "../day/DayHeader";
import { SxProps } from "@mui/material";
import { DateTime } from "luxon";

export interface CalendarWeekDaysProps {
  dates?: Array<DateTime<true>>;
  dateTimeNow?: DateTime<true>;
  dayHeaderProps: {
    sx?: SxProps;
  };
}
export const CalendarWeekDays: FC<CalendarWeekDaysProps> = (props) => {
  const { dateTimeNow } = props;
  if (props.dates != null) {
    return props.dates.map((date) => {
      const isToday =
        date.year === dateTimeNow?.year &&
        date.month === dateTimeNow?.month &&
        date.day === dateTimeNow?.day;
      return (
        <DayHeader
          key={date.toMillis()}
          date={date}
          isToday={isToday}
          sx={props.dayHeaderProps.sx}
        />
      );
    });
  }
  return (
    <>
      <DayHeader key={1} weekday={1} sx={props.dayHeaderProps.sx} />
      <DayHeader key={2} weekday={2} sx={props.dayHeaderProps.sx} />
      <DayHeader key={3} weekday={3} sx={props.dayHeaderProps.sx} />
      <DayHeader key={4} weekday={4} sx={props.dayHeaderProps.sx} />
      <DayHeader key={5} weekday={5} sx={props.dayHeaderProps.sx} />
      <DayHeader key={6} weekday={6} sx={props.dayHeaderProps.sx} />
      <DayHeader key={7} weekday={7} sx={props.dayHeaderProps.sx} />
    </>
  );
};
