import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  LinearProgress,
  Stack,
  Toolbar,
} from "@mui/material";
import { TabPanel, TabPanelProps } from "@airmont/shared/ts/ui/tabs";
import {
  ChimneyWasteTypeStringsEnum,
  FlueId,
  SweepingDto,
  useSweepingDao,
} from "@airmont/firefly/shared/ts/domain";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import {
  useComponentSizeFromDense,
  useWindowSize,
} from "@airmont/shared/ts/ui/responsive";
import {
  PersistedState,
  SweepingCard,
  SweepingFormValues,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { DuenessStringsEnum } from "@airmont/shared/ts/types";
import { useTranslation } from "react-i18next";

export interface SweepingWithPersistedState extends SweepingDto {
  persistedState?: PersistedState;
}

export interface SweepingTabPanelProps extends TabPanelProps {
  flueId: FlueId;
}

export const SweepingTabPanel: FC<SweepingTabPanelProps> = (props) => {
  const { windowWidth } = useWindowSize();
  const componentSize = useComponentSizeFromDense();
  const sweepingDao = useSweepingDao();
  const { t: fireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const [fetching, setFetching] = useState(false);
  const [newSweep, setNewSweep] = useState<SweepingFormValues | undefined>(
    undefined
  );
  const [sweepings, setSweepings] = useState<Array<SweepingWithPersistedState>>(
    []
  );
  const sweepingsAsFormValues: Array<SweepingFormValues> = useMemo(() => {
    return sweepings.map((sweeping) => {
      return {
        time: sweeping.time,
        wasteAmountValue: sweeping.wasteAmount?.value,
        wasteType: sweeping.wasteAmount?.type,
        flammablePct: sweeping.wasteAmount?.flammablePct,
        coveragePct: sweeping.wasteAmount?.coveragePct,
        dueness: sweeping.dueness,
      };
    });
  }, [sweepings]);

  useEffect(
    function loadSweepings() {
      const doFetch = async () => {
        setFetching(true);
        const sweepings = await sweepingDao.getListByFlue(props.flueId);
        //sweepings.map(it => it.wasteType)
        setSweepings(sweepings);
        setFetching(false);
      };
      doFetch();
    },
    [props.flueId, sweepingDao]
  );

  const handleNewSweepingClick = () => {
    setNewSweep({
      time: undefined,
      wasteAmountValue: undefined,
      wasteType: ChimneyWasteTypeStringsEnum.Ash,
      flammablePct: 0,
      coveragePct: 0,
      dueness: DuenessStringsEnum.Due,
    });
  };

  const handleNewSweepingPersisted = (newSweep: SweepingDto) => {
    setNewSweep(undefined);
    setSweepings((prevState) => [newSweep, ...prevState]);
  };

  const handleNewSweepingCancel = () => {
    setNewSweep(undefined);
  };

  return (
    <TabPanel tabValue={props.tabValue} sx={props.sx} scrollableY>
      <Container maxWidth={"sm"}>
        <Toolbar disableGutters>
          <Button
            variant={newSweep == null ? "outlined" : "text"}
            size={componentSize}
            onClick={handleNewSweepingClick}
            disabled={newSweep != null}
          >
            {fireflyDomain("New")}
          </Button>
        </Toolbar>
        {fetching && (
          <LinearProgress
            sx={{
              height: "2px",
            }}
          />
        )}
        {(sweepingsAsFormValues.length > 0 || newSweep != null) && (
          <Stack
            direction={"column"}
            spacing={2}
            useFlexGap
            sx={{
              alignItems: "stretch",
              position: "relative",
              ">.MuiCard-root": { mb: 1 },
            }}
          >
            {newSweep != null && (
              <SweepingCard
                key={-1}
                flueId={props.flueId}
                formValues={newSweep}
                persistedState={"new"}
                layout={windowWidth}
                onPersisted={handleNewSweepingPersisted}
                onCancel={handleNewSweepingCancel}
              />
            )}
            {sweepingsAsFormValues.map((formValues, index) => {
              const sweeping = sweepings[index];
              const handleSweepingPersisted = (value: SweepingDto) => {
                setSweepings((prevState) => {
                  return ArrayUtils.copyAndUpdateElement(
                    sweepings,
                    (it, itIndex) => itIndex === index,
                    value
                  );
                });
              };

              const handleSweepingDelete = async () => {
                await sweepingDao.delete(sweeping.id);
                setSweepings((prevState) =>
                  prevState.copyAndRemoveByPredicate(
                    (it) => it.id === sweeping.id
                  )
                );
              };

              return (
                <SweepingCard
                  key={index}
                  id={sweeping.id}
                  flueId={props.flueId}
                  layout={windowWidth}
                  formValues={formValues}
                  audit={sweeping.audit}
                  persistedState={sweeping.persistedState}
                  onPersisted={
                    index === 0 ? handleSweepingPersisted : undefined
                  }
                  onDelete={index === 0 ? handleSweepingDelete : undefined}
                />
              );
            })}
          </Stack>
        )}
      </Container>
    </TabPanel>
  );
};
