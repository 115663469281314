import React, { FC, useMemo } from "react";
import { Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { merge } from "lodash";
import {
  BurnAssessmentInfo,
  BurnNonSensitiveUi,
  WeatherInfo,
} from "@airmont/firefly/shared/ts/domain";
import { useTranslation } from "react-i18next";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import { InfoPill } from "@airmont/shared/ts/ui/info-pill";

export interface BurnInfoSingleProps {
  burn: BurnNonSensitiveUi;
  layout: SizeClass;
  sx?: SxProps;
}

export const BurnInfoSingle: FC<BurnInfoSingleProps> = (props) => {
  const { burn, layout } = props;
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      position: "relative",
      overflowX: "auto",
      minHeight: "40px",
      alignItems: "center",
    } as SxProps);
  }, [props.sx]);

  return (
    <Stack direction={"row"} useFlexGap gap={1} sx={sx}>
      <InfoPill
        variant={"normal"}
        layout={layout}
        label={`${tFireflySharedDomain("Burn")} #${burn.orderOfOccurence}`}
        info={
          <Stack
            direction={"row"}
            useFlexGap
            gap={1}
            sx={{ alignItems: "center", flexWrap: "nowrap" }}
          >
            <Tooltip title={tFireflySharedDomain("Burn Duration")}>
              <TimelapseRoundedIcon
                fontSize={"small"}
                sx={{ color: "text.secondary" }}
              />
            </Tooltip>
            <Typography>≈</Typography>
            <DurationDisplay duration={burn.duration} maxUnits={1} />
          </Stack>
        }
      />
      <BurnAssessmentInfo
        value={burn.analytics.startQuality}
        variant={"normal"}
        layout={layout}
        sx={{ border: "unset" }}
      />
      <WeatherInfo
        weather={burn.weather}
        variant={"normal"}
        layout={layout}
        sx={{ border: "unset" }}
      />
    </Stack>
  );
};
