import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { FlueFormValues } from "./FlueFormValues";

export const resolveFlueAndSensorFormValuesFromDevice = (
  flue: FlueStatusDto
): FlueFormValues => {
  return {
    comment: flue.comment,
    tags: flue.tags?.split(" ").filter((e) => e.trim().length > 0) ?? [],

    lat: flue.geoLocation?.lat ?? 0,
    lng: flue.geoLocation?.lng ?? 0,

    municipality: flue.municipality.id,
    municipalityName: flue.municipality.name,

    postalCode: flue.postalAddress.code,
    postalPlace: flue.postalAddress.place,

    street: flue.streetAddress.street,
    houseNumber: flue.streetAddress.houseNumber,
    houseLetter: flue.streetAddress.houseLetter,
    houseSection: flue.streetAddress.houseSection,

    cadastralUnit: flue.cadastralNumbers.cadastralUnit,
    propertyUnit: flue.cadastralNumbers.propertyUnit,
    leaseNumber: flue.cadastralNumbers.leaseNumber,

    chimneyHat: flue.chimney.hat,

    flueType: flue.flue.type,
    flueName: flue.flue.name,
    flueHeight: flue.flue.height,

    fireplaceCatalytic: flue.fireplace.catalytic,

    sensorPlacement: flue.flue.sensorPlacement,
  };
};
