import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { BurnDto } from "./Burn";
import { BurnTemperatureSeriesDto } from "./BurnTemperatureSeries";

export class BurnController {
  private readonly burns: Array<BurnDto>;
  public readonly handlers: RequestHandler[];

  constructor(args: { burns: Array<BurnDto> }) {
    this.burns = args.burns;

    this.handlers = [
      rest.get("api/burn/get-by-flue/:flueId", this.getByFlue),
      rest.post(
        "api/burn/get-temperature-series-by-burn",
        this.getTemperatureSeriesByBurn
      ),
    ];
  }

  private getByFlue = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<BurnDto>>> => {
    const { flueId } = req.params;
    const result = this.burns.filter((it) => it.flueId === flueId);
    return res(ctx.json(result));
  };

  private getTemperatureSeriesByBurn = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<BurnTemperatureSeriesDto>>> => {
    const { flueId } = req.params;
    const result = this.burns.filter((it) => it.flueId === flueId);
    return res(ctx.json(result));
  };
}
