import { FlueMetrics } from "../metrics/FlueMetrics";
import { FlueMetricValues } from "../metrics/FlueMetricValues";
import { DateTime } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { MonthWheel } from "@airmont/shared/ts/utils/luxon";

export const summarizeFlueMetricValues = (
  untilTime: DateTime<true>,
  flueMetricsByMonth: Record<string, FlueMetrics>
): FlueMetricValues => {
  const result: FlueMetricValues = {
    burnCount: 0,
    burnHourCount: 0,
    temperatureMax: 0,
    chimneyFireCount: 0,
    sootIndex: 0,
  };

  const firstMonth: DateTime<true> | null = Object.entries(
    flueMetricsByMonth
  ).reduce((a: DateTime<boolean> | null, [key, flueMetrics]) => {
    const time = flueMetrics.time;
    if (time === undefined) {
      _throw(new IllegalStateError("time unexpectedly not set"));
    }
    if (a == null) {
      return time;
    } else if (a > time) {
      return time;
    } else {
      return a;
    }
  }, null);

  if (firstMonth != null) {
    new MonthWheel({
      startYear: firstMonth.year,
      startMonth: firstMonth.month,
    }).runUntilTime(untilTime, (month, year) => {
      const currFlueMetrics = flueMetricsByMonth[`${year}-${month}`];
      if (currFlueMetrics != null) {
        result.burnCount += currFlueMetrics.metrics.burnCount;
        result.burnHourCount += currFlueMetrics.metrics.burnHourCount;
        result.temperatureMax += currFlueMetrics.metrics.temperatureMax;
        result.chimneyFireCount += currFlueMetrics.metrics.chimneyFireCount;
        result.sootIndex += currFlueMetrics.metrics.sootIndex;
      }
    });
  }

  return result;
};
