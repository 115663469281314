import {IconButton, Tooltip} from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
export interface RemoveButtonProps {
  onRemove: () => void;
}
export const RemoveButton : FC<RemoveButtonProps> = (props) => {
  const { t } = useTranslation();
  const handleClick = () => {
    props.onRemove();
  }

  return <Tooltip title={t("Remove")}>
    <IconButton size={"small"} onClick={handleClick}>
      <CloseRoundedIcon fontSize={"small"} />
    </IconButton>
  </Tooltip>
}
