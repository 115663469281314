import React, { FC, useMemo } from "react";
import {
  FlueNeedsSweepDto,
  SweepCountByMonthBarChart,
  SweepCountByPeriod,
  SweepCountByYearBarChart,
} from "@airmont/firefly/chimney-insights/ts/domain";
import {
  alpha,
  Box,
  Card,
  CardContent,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { red, teal } from "@mui/material/colors";
import { DateTime } from "luxon";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import CelebrationIcon from "@mui/icons-material/CelebrationRounded";
import { useTranslation } from "react-i18next";
import { useDense } from "@airmont/shared/ts/ui/responsive";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button } from "shared-ts-mui";
import { UseQueryResult } from "@tanstack/react-query";

const overdueColor = alpha(red[500], 0.7);
const upcomingColor = alpha(teal[500], 0.8);
const repeatingColor_light = alpha(teal[100], 1);
const repeatingColor_dark = alpha(teal[100], 0.3);

export interface NeedForSweepPanelProps {
  numberOfYears: number;
  selectedYear: number | null;
  overdueSweepCount: UseQueryResult<number>;
  sweepCounterAfterPeriod: UseQueryResult<number>;
  upcomingSweepCountsByYear: UseQueryResult<Array<SweepCountByPeriod>>;
  repeatingSweepCountsByYear: UseQueryResult<Array<SweepCountByPeriod>>;
  upcomingSweepCountsByMonth:
    | UseQueryResult<Array<SweepCountByPeriod>>
    | undefined;
  repeatingSweepCountsByMonth:
    | UseQueryResult<Array<SweepCountByPeriod>>
    | undefined;
  fluesNeedsForSweep: UseQueryResult<Array<FlueNeedsSweepDto>> | undefined;
  onYearChange: (year: number | null) => void;
  onMonthChange: (month: number | null) => void;
}

export const NeedForSweepPanel: FC<NeedForSweepPanelProps> = (props) => {
  const {
    numberOfYears,
    selectedYear,
    overdueSweepCount,
    sweepCounterAfterPeriod,
    upcomingSweepCountsByYear,
    repeatingSweepCountsByYear,
    upcomingSweepCountsByMonth,
    repeatingSweepCountsByMonth,
  } = props;
  const theme = useTheme();
  const dense = useDense();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const colors = useMemo(() => {
    return {
      overdue: overdueColor,
      repeating:
        theme.palette.mode === "light"
          ? repeatingColor_light
          : repeatingColor_dark,
      upcoming: upcomingColor,
    };
  }, [theme.palette.mode]);

  const now = useDateTimeNow();
  const selectedYearTotal = useMemo(() => {
    if (selectedYear === now.year) {
      return (
        (overdueSweepCount.data ?? 0) +
        (upcomingSweepCountsByYear.data?.reduce(
          (a, it) => a + (it.time.year === selectedYear ? it.count : 0),
          0
        ) ?? 0) +
        (repeatingSweepCountsByYear.data?.reduce(
          (a, it) => a + (it.time.year === selectedYear ? it.count : 0),
          0
        ) ?? 0)
      );
    } else {
      return (
        (upcomingSweepCountsByYear.data?.reduce(
          (a, it) => a + (it.time.year === selectedYear ? it.count : 0),
          0
        ) ?? 0) +
        (repeatingSweepCountsByYear.data?.reduce(
          (a, it) => a + (it.time.year === selectedYear ? it.count : 0),
          0
        ) ?? 0)
      );
    }
  }, [
    now.year,
    repeatingSweepCountsByYear,
    selectedYear,
    overdueSweepCount,
    upcomingSweepCountsByYear,
  ]);

  const handleYearTimeClick = (time: DateTime<true>) => {
    props.onYearChange(time.year);
  };

  const handleClose = () => {
    props.onYearChange(null);
  };

  const handleMonthClick = (month: number) => {
    props.onMonthChange(month);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: 0,
        minWidth: 0,
        position: "relative",
        display: "grid",
        gridAutoRows: "1fr",
      }}
    >
      <SweepCountByYearBarChart
        overdue={overdueSweepCount.data}
        numberOfYears={numberOfYears}
        colors={colors}
        upcomingSweepCounts={upcomingSweepCountsByYear}
        repeatingSweepCounts={repeatingSweepCountsByYear}
        selectedYear={selectedYear}
        sx={{ minHeight: 0, minWidth: 0 }}
        onTimeClick={handleYearTimeClick}
      />

      {selectedYear != null &&
        upcomingSweepCountsByMonth != null &&
        repeatingSweepCountsByMonth != null && (
          <Paper
            elevation={3}
            sx={{
              m: 1,
              p: 1,
              minHeight: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Toolbar
              variant={dense ? "dense" : "regular"}
              disableGutters
              sx={{ gap: 1 }}
            >
              <Button
                color={"inherit"}
                startIcon={<CloseRoundedIcon />}
                onClick={handleClose}
              />
              <Typography align={"center"}>
                {t(
                  "Year {{year}} has a total of {{flues}} flues that needs sweep",
                  { year: selectedYear, flues: selectedYearTotal }
                )}
              </Typography>
            </Toolbar>
            <SweepCountByMonthBarChart
              upcomingSweepCounts={upcomingSweepCountsByMonth}
              repeatingSweepCounts={repeatingSweepCountsByMonth}
              colors={colors}
              onMonthClick={handleMonthClick}
              sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
            />
          </Paper>
        )}

      <Card
        elevation={3}
        sx={{
          position: "absolute",
          top: theme.spacing(3),
          right: theme.spacing(3),
          maxWidth: "200px",
        }}
      >
        <CardContent>
          <CelebrationIcon />
          <Typography noWrap={false} color={theme.palette.text.secondary}>
            {t("X flues, has no need for sweep...", {
              flues: sweepCounterAfterPeriod.data,
              before: notUndef(
                upcomingSweepCountsByYear.data,
                (it) => it[it.length - 1].time.year + 1
              ),
            })}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
