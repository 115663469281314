import {
  Collapse,
  Divider,
  ListItemButton,
  Stack,
  useTheme,
} from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import React, { useMemo, useState } from "react";
import {
  ActionGroup,
  isActionGroup,
  isSelectableItem,
  Item,
  ItemVariant,
  resolveActionGroupPreferences,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import { PopoverOrigin } from "@mui/material/Popover/Popover";
import { GroupNode } from "./GroupNode";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";
import { renderListItem } from "../list/renderListItem";
import { Menu } from "./Menu";

const resolveSelectedItem = (items: Item[]): SelectableItem | undefined => {
  let selectedItem: SelectableItem | undefined;
  items.forEach((item) => {
    if (isSelectableItem(item) && item.selected) {
      selectedItem = item;
    } else if (selectedItem === undefined && isActionGroup(item)) {
      selectedItem = resolveSelectedItem(item.items);
    }
  });

  return selectedItem;
};

export interface GroupMenuProps extends CommonProps {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: ActionGroup;
  hideLabel?: boolean;
  hideSelected?: boolean;
  onSelected?: (item: SelectableItem) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const GroupMenu = (props: GroupMenuProps) => {
  const { layout, item } = props;
  const variant = props.variant ?? "rounded";
  const theme = useTheme();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const preferences = resolveActionGroupPreferences(item.preferences, layout);
  const hideSelected = props.hideSelected ?? preferences?.hideSelected ?? false;
  const layoutDirection = props.layoutDirection ?? preferences?.layoutDirection;
  const selectedItem = useMemo(
    () => resolveSelectedItem(item.items),
    [item.items]
  );

  const handleGroupNodeClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleItemSelected = (item: SelectableItem) => {
    props.onSelected?.(item);
    setOpen(false);
  };

  return (
    <Stack
      ref={anchorRef}
      className={`Item GroupMenu layout-${layout} variant-${variant}`}
      direction={"column"}
      sx={{
        "&.layout-large": {
          borderRadius: hideSelected
            ? "50px"
            : selectedItem != null
            ? 3
            : undefined,
          backgroundColor: theme.palette.action.hover,
        },
        "&.layout-medium": {
          borderRadius: 3,
          backgroundColor: theme.palette.action.hover,
          "&.direction-column": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
        "&.variant-rounded": {
          ">.Item": {
            borderTopLeftRadius: "9px",
            borderTopRightRadius: "9px",
            borderBottomRightRadius: hideSelected ? "9px" : 0,
            borderBottomLeftRadius: hideSelected ? "9px" : 0,
          },
        },
      }}
    >
      <GroupNode
        variant={variant}
        item={props.item}
        open={open}
        layout={layout}
        hideLabel={props.hideLabel}
        hasSiblings={selectedItem != null && !hideSelected}
        onClick={handleGroupNodeClick}
      />
      <Menu
        open={open}
        variant={variant}
        layout={layout}
        layoutDirection={layoutDirection}
        item={item}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        anchorRef={anchorRef}
        onClose={handleClose}
        onItemSelected={handleItemSelected}
      />
      {!hideSelected && (
        <Collapse
          className={"GroupSelectedItemContainer layout-" + layout}
          in={selectedItem != null}
          timeout={{ enter: 500, exit: 500 }}
          sx={{
            "&.layout-medium": {
              ".Item": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
            },
            ".Item": {
              borderBottomLeftRadius: "9px",
              borderBottomRightRadius: "9px",
              "&.selected": {
                backgroundColor: "unset",
                ".MuiButtonBase-root": {
                  backgroundColor: "unset",
                },
                "&:hover": {
                  backgroundColor: "unset",
                  cursor: "auto",
                },
                ".CheckIcon": {
                  display: "none",
                },
              },
            },
          }}
        >
          {selectedItem != null && (
            <>
              <Divider sx={{ ml: 1, mr: 1 }} />
              {renderListItem({
                variant: "square",
                layout: layout,
                item: selectedItem,
                hideLabels: false,
              })}
            </>
          )}
          {selectedItem == null && (
            <>
              <Divider />
              <ListItemButton
                className={"Item NavigationItem layout-" + layout}
                sx={{ minHeight: "66px" }}
              ></ListItemButton>
            </>
          )}
        </Collapse>
      )}
    </Stack>
  );
};
