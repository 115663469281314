import { FC, ReactNode, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { merge } from "lodash";
import { CssGridTemplateAreas } from "./GridTemplateAreas";
import { CssGridTemplateColumns } from "./GridTemplateColumns";
import { CssGridTemplateRows } from "./GridTemplateRows";
import { ResponsiveValue } from "./ResponsiveValue";
import { BreakpointUtils } from "shared-ts-mui";
import { useResizeDetector } from "react-resize-detector";
import {
  resolveWindowSizeFromBreakpoint,
  SizeClass,
} from "@airmont/shared/ts/ui/responsive";

function resolveNearestResponsiveValue<T>(
  responsiveValue: ResponsiveValue<T>,
  width: SizeClass
): T {
  // shit helvete faen
  if (width === SizeClass.Compact) {
    if ("compact" in responsiveValue) {
      return responsiveValue["compact"] as unknown as T;
    } else {
      throw new Error(
        "Cannot resolve ResponsiveValue for " +
          width +
          " in : " +
          JSON.stringify(responsiveValue)
      );
    }
  } else if (width === SizeClass.Medium) {
    if ("medium" in responsiveValue) {
      return responsiveValue["medium"] as unknown as T;
    } else if ("compact" in responsiveValue) {
      return responsiveValue["compact"] as unknown as T;
    } else {
      throw new Error(
        "Cannot resolve ResponsiveValue for " +
          width +
          " in : " +
          JSON.stringify(responsiveValue)
      );
    }
  } else if (width === SizeClass.Expanded) {
    if ("expanded" in responsiveValue) {
      return responsiveValue["expanded"] as unknown as T;
    } else if ("medium" in responsiveValue) {
      return responsiveValue["medium"] as unknown as T;
    } else if ("compact" in responsiveValue) {
      return responsiveValue["compact"] as unknown as T;
    } else {
      throw new Error(
        "Cannot resolve ResponsiveValue for " +
          width +
          " in : " +
          JSON.stringify(responsiveValue)
      );
    }
  } else if (width === SizeClass.ExtraLarge) {
    if ("extraLarge" in responsiveValue) {
      return responsiveValue["extraLarge"] as unknown as T;
    } else if ("expanded" in responsiveValue) {
      return responsiveValue["expanded"] as unknown as T;
    } else if ("medium" in responsiveValue) {
      return responsiveValue["medium"] as unknown as T;
    } else if ("compact" in responsiveValue) {
      return responsiveValue["compact"] as unknown as T;
    } else {
      throw new Error(
        "Cannot resolve ResponsiveValue for " +
          width +
          " in : " +
          JSON.stringify(responsiveValue)
      );
    }
  } else {
    throw new Error(
      "Cannot resolve ResponsiveValue for " +
        width +
        " in : " +
        JSON.stringify(responsiveValue)
    );
  }
}

function resolveValueOrResponsiveValue<T>(
  valueOrResponsiveValue: T | ResponsiveValue<T>,
  width: SizeClass
): T {
  if (valueOrResponsiveValue == null) {
    throw new Error("Given valueOrResponsiveValue cannot be null");
  } else if (typeof valueOrResponsiveValue === "object") {
    return resolveNearestResponsiveValue(valueOrResponsiveValue, width);
  } else {
    return valueOrResponsiveValue;
  }
}

export interface CssGridTemplateProps {
  className?: string;
  areas: CssGridTemplateAreas | ResponsiveValue<CssGridTemplateColumns>;
  columns: CssGridTemplateColumns | ResponsiveValue<CssGridTemplateColumns>;
  rows: CssGridTemplateRows | ResponsiveValue<CssGridTemplateRows>;
  sx?: SxProps;
  children: ReactNode | ((args: { width: SizeClass }) => ReactNode);
}

export const CssGridTemplate: FC<CssGridTemplateProps> = (props) => {
  const theme = useTheme();
  const { width: widthInPixels, ref } = useResizeDetector();
  const widthAsBreakpoint =
    widthInPixels != null
      ? BreakpointUtils.resolveBreakpoint(widthInPixels, theme.breakpoints)
      : "xs";
  const width = resolveWindowSizeFromBreakpoint(widthAsBreakpoint);
  //console.log(`CssGridTemplate[${props.className}] width: ` + width);
  const sx = useMemo(() => {
    return merge(props.sx ?? {}, {
      display: "grid",
      gridTemplateAreas: resolveValueOrResponsiveValue(props.areas, width),
      gridTemplateColumns: resolveValueOrResponsiveValue(props.columns, width),
      gridTemplateRows: resolveValueOrResponsiveValue(props.rows, width),
    } as SxProps);
  }, [props.areas, props.columns, props.rows, props.sx, width]);

  return (
    <Box
      className={
        props.className != null
          ? props.className + " CssGridTemplate"
          : "CssGridTemplate"
      }
      ref={ref}
      sx={sx}
    >
      {typeof props.children === "function"
        ? props.children({ width })
        : props.children}
    </Box>
  );
};
