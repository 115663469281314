import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { MetricResponseDto } from "./MetricResponseDto";
import { RandomUtils } from "@airmont/shared/ts/utils/core";

export class MetricsController {
  public readonly handlers: RequestHandler[];

  constructor() {
    this.handlers = [
      rest.post("/api/metrics/device/:metric", this.countMetric),
    ];
  }

  countMetric = async (
    request: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<MetricResponseDto>> => {
    const response: MetricResponseDto = {
      value: RandomUtils.getRandomInt(10, 100),
      history: [],
    };

    return res(ctx.json(response));
  };
}
