import { FC, ReactNode, useMemo } from "react";
import { useEnvironmentMetricsDao } from "./useEnvironmentMetricsDao";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { EnvironmentMetricsTimeUnitEnum } from "./EnvironmentMetricsTimeUnit";
import { EnvironmentMetricsDto } from "./EnvironmentMetrics";
import {
  EnvironmentMetricsQuery,
  EnvironmentMetricsQueryImpl,
} from "./EnvironmentMetricsQuery";

export interface SingleEnvironmentMetricsLoaderProps {
  environment: CustomerEnvironmentName;
  type: EnvironmentMetricsTimeUnitEnum;
  time?: DateTime;
  children: (result: UseQueryResult<EnvironmentMetricsDto>) => ReactNode;
}

export const SingleEnvironmentMetricsLoader: FC<
  SingleEnvironmentMetricsLoaderProps
> = (props) => {
  const environmentMetricsDao = useEnvironmentMetricsDao();

  const query: EnvironmentMetricsQuery = useMemo(() => {
    return new EnvironmentMetricsQueryImpl({
      environment: props.environment,
      timeUnit: props.type,
      time: props.time,
    });
  }, [props.environment, props.type, props.time]);
  const queryResult = useQuery({
    queryKey: ["api/environment-metrics/query", query],
    queryFn: () => environmentMetricsDao.query(query),
    select: (data: Array<EnvironmentMetricsDto>) => data[0],
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  return props.children(queryResult);
};
