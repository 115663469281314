import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, Popover } from "@mui/material";
import { LocalizationProvider, YearCalendar } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import {
  SizeClass,
  useComponentSizeFromDense,
  useWindowWidth,
} from "@airmont/shared/ts/ui/responsive";
import { TimeframeObject } from "./TimeframeObject";

export interface YearSelectProps {
  timeframe: TimeframeObject;
  disableFuture?: boolean;
  minYear?: number;
  onChange: (newYear: number) => void;
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | React.RefObject<HTMLButtonElement>
    | null
    | undefined;
}
export const YearSelect: FC<YearSelectProps> = (props) => {
  const componentSize = useComponentSizeFromDense();
  const width = useWindowWidth();
  const { timeframe, disableFuture, minYear } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const now = useDateTimeNow();
  const minDate = useMemo(
    () => notUndef(minYear, (it) => DateTime.local(it, 1, 1, 0, 0, 0)),
    [minYear]
  );
  const maxDate = useMemo(() => {
    if (disableFuture === true) {
      const newEnd = timeframe.end.set({ year: now.year });
      if (newEnd.month > now.month) {
        return timeframe.end.set({ year: now.year - 1 });
      }
      return now;
    } else {
      return undefined;
    }
  }, [disableFuture, now, timeframe.end]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleYearChange = (dateTime: DateTime) => {
    setAnchorEl(null);
    props.onChange(dateTime.year);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button ref={props.ref} size={componentSize} onClick={handleClick}>
        {timeframe.start.year}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <YearCalendar
            value={timeframe.start}
            yearsPerRow={width === SizeClass.Compact ? 3 : 4}
            disableFuture={disableFuture}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleYearChange}
          />
        </LocalizationProvider>
      </Popover>
    </>
  );
};
