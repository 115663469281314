import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, Popover } from "@mui/material";
import { LocalizationProvider, MonthCalendar } from "@mui/x-date-pickers-pro";
import { DateTime, Info } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { TimeframeObject } from "./TimeframeObject";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";

export interface MonthSelectProps {
  timeframe: TimeframeObject;
  disableFuture?: boolean;
  onChange: (newMonth: number) => void;
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | React.RefObject<HTMLButtonElement>
    | null
    | undefined;
}
export const MonthSelect: FC<MonthSelectProps> = (props) => {
  const { timeframe, disableFuture } = props;
  const componentSize = useComponentSizeFromDense();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const now = useDateTimeNow();
  const minDate = useMemo(
    () =>
      notUndef(timeframe.start, (it) => DateTime.local(it.year, 1, 1, 0, 0, 0)),
    [timeframe.start]
  );
  const maxDate = useMemo(() => {
    if (disableFuture === true) {
      if (timeframe.end.year < now.year) {
        return undefined;
      }
      return timeframe.end.set({ month: now.month }).startOf("month");
    } else {
      return undefined;
    }
  }, [disableFuture, now, timeframe.end]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMonthChange = (dateTime: DateTime) => {
    setAnchorEl(null);
    props.onChange(dateTime.month);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        ref={props.ref}
        size={componentSize}
        onClick={handleClick}
        className={"monthPeriodName"}
      >
        {Info.months()[timeframe.start.month - 1]}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <MonthCalendar
            value={timeframe.start}
            disableFuture={disableFuture}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleMonthChange}
            sx={{
              ".MuiPickersMonth-root": {
                ".Mui-disabled": {
                  display: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Popover>
    </>
  );
};
