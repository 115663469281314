import { FC, ReactNode, useMemo } from "react";
import { FlueMetrics } from "../FlueMetrics";
import { useFlueMetricsDao } from "../dao/useFlueMetricsDao";
import { useQuery } from "@tanstack/react-query";
import { FlueId } from "../../FlueId";
import { FlueMetricsQueryImpl } from "../dao/FlueMetricsQuery";
import { FlueMetricsTimeUnitEnum } from "../FlueMetricsTimeUnit";

export interface FlueYearMetricsLoaderProps {
  flueId: FlueId;
  children: (result: Record<string, FlueMetrics>) => ReactNode;
}

export const FlueYearMetricsLoader: FC<FlueYearMetricsLoaderProps> = (
  props
) => {
  const flueMetricsDao = useFlueMetricsDao();

  const flueMetricsQuery = new FlueMetricsQueryImpl({
    flueId: props.flueId,
    timeUnit: FlueMetricsTimeUnitEnum.Year,
  });
  const { data } = useQuery({
    queryKey: ["FlueMetricsDao.query", flueMetricsQuery],
    queryFn: () => flueMetricsDao.query(flueMetricsQuery),
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const result = useMemo(() => {
    const metrics = data?.map((dto) => new FlueMetrics(dto));
    const result: Record<string, FlueMetrics> = {};
    metrics?.forEach((flueMetric) => {
      const key = `${flueMetric.time?.year}`;
      result[key] = flueMetric;
    });

    return result;
  }, [data]);

  return props.children(result);
};
