import { useMemo } from "react";
import { useAccessToken } from "shared-ts-utils-authentication";
import { useCustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { EnvironmentMetricsDao } from "./EnvironmentMetricsDao";

export const useEnvironmentMetricsDao = (): EnvironmentMetricsDao => {
  const accessToken = useAccessToken();
  const customerEnvironment =
    useCustomerEnvironmentName() ??
    _throw(new NullError("customerEnvironment"));

  return useMemo(
    () =>
      new EnvironmentMetricsDao({
        accessToken: accessToken,
        customerEnvironment: customerEnvironment,
      }),
    [accessToken, customerEnvironment]
  );
};
