import { SensorId } from "./SensorId";
import { useEffect, useState } from "react";
import { useChimneySensorSettingsQueryClient } from "./ChimneySensorSettingsQueryClient";
import { ChimneySensorSettingsHistoryDto } from "./ChimneySensorSettingsHistory";
import { EntityResponse } from "../shared/entity/EntityResponse";

export const useGetLatestChimneySensorSettingsBySensorIdOrUndefined = (
  id: SensorId | undefined
): EntityResponse<ChimneySensorSettingsHistoryDto> | undefined => {
  const [settings, setSettings] = useState<
    EntityResponse<ChimneySensorSettingsHistoryDto> | undefined
  >(undefined);

  const chimneySensorSettingsQueryClient =
    useChimneySensorSettingsQueryClient();

  useEffect(() => {
    const fetch = async () => {
      if (id != null) {
        const latestChimneySensorSettings =
          await chimneySensorSettingsQueryClient.getLatestBySensorId(id);
        setSettings(latestChimneySensorSettings);
      } else {
        setSettings(undefined);
      }
    };
    fetch();
  }, [id, chimneySensorSettingsQueryClient]);

  return settings;
};
