import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import React, { forwardRef, RefObject } from "react";
import { styled } from "@mui/styles";
import { ListItemIcon, ListItemText } from "@mui/material";

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  justifyContent: "space-between",
  paddingRight: 0,
});

type IconMenuItemProps = {
  MenuItemProps?: MenuItemProps;
  className?: string;
  disabled?: boolean;
  label?: string;
  leftIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  ref?: RefObject<HTMLLIElement>;
  rightIcon?: React.ReactNode;
  sx?: SxProps;
};

export const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  function IconMenuItem(
    { MenuItemProps, className, label, leftIcon, rightIcon, ...props },
    ref
  ) {
    return (
      <StyledMenuItem
        {...MenuItemProps}
        ref={ref}
        className={className}
        {...props}
      >
        <ListItemIcon>{leftIcon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
        <ListItemIcon>{rightIcon}</ListItemIcon>
      </StyledMenuItem>
    );
  }
);
