import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NoSettingsChosenPane: FC = () => {
  const { t } = useTranslation("firefly-chimney-insights-ts-settings");
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography align={"center"}>{t("No setting chosen")}</Typography>
      </Box>
    </Box>
  );
};
