import { Release } from "shared-ts-release";

export const release_2023_2_1: Release = {
  version: "2023.2.1",
  date: "2024-02-05",
  entries: [
    {
      type: "Feature",
      heading: "Fargekoding av radio-signal-kvalitet i Sensor-Status-tabellen",
      description:
        "Ved hjelp av farger blir det lettere å tolke kvaliteten på radio-signalene",
    },
    {
      type: "Feature",
      heading: "Visning av sensorens radio-signal-kvalitet over tid",
    },
    {
      type: "Feature",
      heading: "Lagring av brukerinnstillinger for kolonner i tabeller",
      description:
        "Nå lagres synlighet og sortering av kolonner i tabeller. Dette gjør at du slipper å sette opp tabellene på nytt hver gang du besøker siden.",
    },
    {
      type: "Usability",
      heading:
        "Forbedret støtte for mobil når man ser på fyringer for et røykløp",
      description:
        "Vi har gjort grep for at applikasjonen skal være mer brukbar på mobil.",
    },
    {
      type: "Bugfix",
      heading: "Visning av graf for temperaturalarm fungerer igjen",
    },
    {
      type: "Bugfix",
      heading:
        "Innlogging bringer deg tilbake til siden du var på før du ble logget ut",
    },
  ],
};
