import { QueryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";
import { ChimneySensorSettingsHistoryDto } from "./ChimneySensorSettingsHistory";
import { SensorId } from "./SensorId";
import { useChimneySensorSettingsDao } from "./useChimneySensorSettingsDao";

export const useGetLatestChimneySensorSettingsBySensorId = (
  id: SensorId,
  queryOptions?: QueryOptions
): UseQueryResult<ChimneySensorSettingsHistoryDto> => {
  const chimneySensorSettingsDao = useChimneySensorSettingsDao();
  return useQuery({
    queryKey: ["ChimneySensorSettingsDao.getLatestBySensorId", id],
    queryFn: () => {
      try {
        return chimneySensorSettingsDao.getLatestBySensorId(id);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    ...(queryOptions ?? {
      staleTime: 10 * 60 * 1000,
      gcTime: 15 * 60 * 1000,
    }),
  });
};
