import { QueryClient as TanStackQueryClient } from "@tanstack/react-query";
import { BurnDao } from "./BurnDao";
import { PageDto, QueryClient, QueryResult } from "shared/ts/utils/query";
import { BurnNonSensitiveDto } from "@airmont/firefly/shared/ts/domain";
import { AbnormalBurnsQueryDto } from "./AbnormalBurnsQuery";

export class AbnormalBurnsQueryClient
  implements QueryClient<BurnNonSensitiveDto>
{
  private queryClient: TanStackQueryClient;
  private dao: BurnDao;

  constructor(args: { queryClient: TanStackQueryClient; dao: BurnDao }) {
    this.queryClient = args.queryClient;
    this.dao = args.dao;
  }

  query = (
    query: AbnormalBurnsQueryDto,
    page: PageDto
  ): Promise<QueryResult<BurnNonSensitiveDto>> => {
    const combinedQuery: AbnormalBurnsQueryDto = {
      ...query,
      page: page,
    };
    return this.queryClient.fetchQuery({
      queryKey: [
        "BurnDao.queryAbnormalBurns",
        this.dao.customerEnvironment,
        combinedQuery,
      ],
      queryFn: () => this.dao.queryAbnormalBurns(combinedQuery),
      staleTime: 10 * 60 * 1000,
      gcTime: 15 * 60 * 1000,
    });
  };
}
