import { ReactNode, useMemo } from "react";
import { TimeSeriesQuery } from "./TimeSeriesQuery";
import { useQuery } from "@tanstack/react-query";
import { useTimeSeriesDao } from "./useTimeSeriesDao";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import {
  NumberTimeSeriesImpl,
  TimeSeries,
  TimeSeriesDto,
  TimeSeriesImpl,
} from "@airmont/firefly/shared/ts/timeseries";

export interface TimeSeriesLoaderProps<PV> {
  query: Array<TimeSeriesQuery> | TimeSeriesQuery;
  timeSeriesFactory?: (dto: TimeSeriesDto<PV>) => TimeSeries<PV>;
  children: (args: {
    timeSeries: Array<TimeSeries<PV>> | undefined;
    isLoading: boolean;
  }) => ReactNode;
}

export function TimeSeriesLoader<PV>(
  props: TimeSeriesLoaderProps<PV>
): ReactNode {
  const { query, timeSeriesFactory, children } = props;
  const timeSeriesDao = useTimeSeriesDao();
  const { data: timeSeriesDtoList, isLoading } = useQuery({
    queryKey: ["/api/timeSeries/query", query],
    queryFn: () => timeSeriesDao.query<PV>(query),
  });

  const timeSeriesList = useMemo(() => {
    return timeSeriesDtoList?.map((it) => {
      if (timeSeriesFactory != null) {
        return timeSeriesFactory(it);
      } else {
        if (it.points.length === 0) {
          return TimeSeriesImpl.fromDto<PV>(it as TimeSeriesDto<PV>);
        } else {
          const firstPoint = it.points[0];
          if (typeof firstPoint.value === "number") {
            return NumberTimeSeriesImpl.fromNumberTimeSeriesDto(
              it as TimeSeriesDto<number>
            ) as unknown as TimeSeries<PV>;
          } else {
            throw new IllegalArgumentError(
              "Unable to resolve TimeSeries Point value type from point: \n" +
                JSON.stringify(firstPoint)
            );
          }
        }
      }
    });
  }, [timeSeriesDtoList, timeSeriesFactory]);

  return children({ timeSeries: timeSeriesList, isLoading: isLoading });
}
