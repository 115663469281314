import { useSensorDao } from "./useSensorDao";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { SensorQueryClient } from "./SensorQueryClient";

export const useSensorQueryClient = (): SensorQueryClient => {
  const queryClient = useQueryClient();
  const sensorDao = useSensorDao();
  return useMemo(
    () =>
      new SensorQueryClient({ queryClient: queryClient, sensorDao: sensorDao }),
    [queryClient, sensorDao]
  );
};
