import { FC } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconOwnProps, SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import NorthRoundedIcon from "@mui/icons-material/NorthRounded";
import NorthWestRoundedIcon from "@mui/icons-material/NorthWestRounded";
import NorthEastRoundedIcon from "@mui/icons-material/NorthEastRounded";
import SouthEastRoundedIcon from "@mui/icons-material/SouthEastRounded";
import SouthWestRoundedIcon from "@mui/icons-material/SouthWestRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import {
  resolveWindDirectionFromDegrees,
  WindDirection,
} from "./WindDirection";

export interface WindDirectionIconProps extends SvgIconOwnProps {
  value: number | WindDirection;
}

export const WindDirectionArrowIcon: FC<WindDirectionIconProps> = (props) => {
  const { value, ...svgIconProps } = props;
  const windDirection =
    typeof value === "number" ? resolveWindDirectionFromDegrees(value) : value;
  const WindDirectionIcon = resolveWindDirectionIcon(windDirection);
  return <WindDirectionIcon {...svgIconProps} />;
};

export const resolveWindDirectionIcon = (
  windDirection: WindDirection
): OverridableComponent<SvgIconTypeMap> => {
  if (windDirection === WindDirection.North) {
    return SouthRoundedIcon;
  } else if (windDirection === WindDirection.NorthEast) {
    return SouthWestRoundedIcon;
  } else if (windDirection === WindDirection.East) {
    return WestRoundedIcon;
  } else if (windDirection === WindDirection.SouthEast) {
    return NorthWestRoundedIcon;
  } else if (windDirection === WindDirection.South) {
    return NorthRoundedIcon;
  } else if (windDirection === WindDirection.SouthWest) {
    return NorthEastRoundedIcon;
  } else if (windDirection === WindDirection.West) {
    return EastRoundedIcon;
  } else {
    return SouthEastRoundedIcon;
  }
};
