import { TimeSeriesChartOptions } from "./TimeSeriesChartOptions";
import { Theme } from "@mui/material/styles";
import { useMemo } from "react";

const resolveXAxis = (
  options: TimeSeriesChartOptions | undefined,
  theme: Theme
): ApexXAxis => {
  const type = options?.xAxis?.type ?? "datetime";
  return {
    type: type,
    min: options?.xAxis?.min,
    max: options?.xAxis?.max,
    range: options?.xAxis?.range,
    tickAmount: options?.xAxis?.tickAmount,
    title: {
      text: options?.xAxis?.title?.text,
      offsetY: 90,
      style: {
        color: theme.palette.text.secondary,
      },
    },
    labels: {
      datetimeUTC: type === "datetime" ? false : undefined,
      style: {
        colors: theme.palette.text.secondary,
      },
    },
    crosshairs: {
      show: options?.xAxis?.crosshairs?.show,
    },
    tooltip: {
      enabled: true,
    },
  };
};

export const useResolveXAxis = (
  options: TimeSeriesChartOptions | undefined,
  theme: Theme
): ApexXAxis => {
  return useMemo(() => {
    return resolveXAxis(options, theme);
  }, [options, theme]);
};
