import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, Tab, Toolbar, Typography } from "@mui/material";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import {
  BurnId,
  BurnNonSensitiveChart2,
  BurnNonSensitiveDto,
  DerivativeOptions,
  FlueMetricsLoader,
  FlueMetricsTimeUnitEnum,
  StreetAddress,
} from "@airmont/firefly/shared/ts/domain";
import { MathUtils, notUndef } from "@airmont/shared/ts/utils/core";
import {
  AbnormalBurnsQueryDto,
  BurnTemperatureSeriesLoader,
  FlueWithAbnormalBurnsDto,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { AbnormalBurnsTable } from "./AbnormalBurnsTable";
import { useQueryRows } from "shared/ts/ui/x-data-grid";
import { useResizeDetector } from "react-resize-detector";
import { TabPanel, TabsAndPanels } from "@airmont/shared/ts/ui/tabs";
import { AbnormalBurnsFilterFormValues } from "./AbnormalBurnsFilterFormValues";
import { FilterGroup } from "./FilterGroup";
import { Formik } from "formik";
import { useFlueTypeOptions } from "./useFlueTypeOptions";
import {
  useComponentSizeFromDense,
  useDense,
} from "@airmont/shared/ts/ui/responsive";
import { FormikProperty, Property } from "shared-ts-property";
import { merge } from "lodash";
import { Action, selectableAction } from "@airmont/shared/ts/ui/action";
import { AddFilter } from "./AddFilter";
import { useTranslation } from "react-i18next";

const derivativeOptions: DerivativeOptions = {
  includeNegatives: true,
  absoluteNegatives: false,
};

export interface AbnormalBurnsSupportingPaneProps {
  viewedFlue: FlueWithAbnormalBurnsDto | undefined;
  filterValues: AbnormalBurnsFilterFormValues;
  defaultFilterValues: AbnormalBurnsFilterFormValues;
}

export const AbnormalBurnsSupportingPane: FC<
  AbnormalBurnsSupportingPaneProps
> = (props) => {
  const { viewedFlue } = props;
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const { t } = useTranslation("firefly-shared-ts-domain");
  const [filterValues, setFilterValues] =
    useState<AbnormalBurnsFilterFormValues>(props.filterValues);

  const queryInput = useMemo(() => {
    return {
      flueId: viewedFlue?.id,
      completed: notUndef(filterValues.unfinished, (value) => !value),
      minDurationInMinutes: notUndef(
        filterValues.minDurationInHours,
        (value) => value * 60
      ),
      maxTemperature: filterValues.maxTemperature,
      selfSimilarity: notUndef(filterValues.selfSimilarity, (it) => ({
        min: it.min / 100,
        max: it.max / 100,
      })),
      minMaxDeltaTemperature: filterValues.minMaxDeltaTemperature,
      maxMaxDeltaTemperature: filterValues.maxMaxDeltaTemperature,
      requireDeviantPresumedFlueType:
        filterValues.requireDeviantPresumedFlueType,
      presumedFlueTypeConfidence: notUndef(
        filterValues.presumedFlueTypeConfidence,
        (it) => ({ min: it.min / 100, max: it.max / 100 })
      ),
      presumedFlueTypes:
        filterValues.presumedFlueTypes !== undefined &&
        filterValues.presumedFlueTypes.length > 0
          ? filterValues.presumedFlueTypes
          : undefined,
    } as AbnormalBurnsQueryDto;
  }, [
    viewedFlue?.id,
    filterValues.unfinished,
    filterValues.minDurationInHours,
    filterValues.maxTemperature,
    filterValues.selfSimilarity,
    filterValues.minMaxDeltaTemperature,
    filterValues.maxMaxDeltaTemperature,
    filterValues.requireDeviantPresumedFlueType,
    filterValues.presumedFlueTypeConfidence,
    filterValues.presumedFlueTypes,
  ]);

  const [viewedBurns, setViewedBurns] = useState<Array<BurnNonSensitiveDto>>(
    []
  );
  const selectedBurnIds = useMemo(
    () => viewedBurns.map((it) => it.id),
    [viewedBurns]
  );

  const { rows, query, handleRowsChange } = useQueryRows<
    BurnId,
    BurnNonSensitiveDto,
    AbnormalBurnsQueryDto
  >(queryInput);

  const { height, ref } = useResizeDetector();
  const burnsTableHeight = height != null ? MathUtils.round(height / 2) : 0;
  const burnsGraphHeight = height != null ? height - burnsTableHeight : 0;
  const [tabValue, setTabValue] = useState<string>("graph");
  const flueTypeOptions = useFlueTypeOptions();
  const [defaultFilterValues, setDefaultFilterValues] =
    useState<AbnormalBurnsFilterFormValues>(props.defaultFilterValues);
  useEffect(() => {
    setDefaultFilterValues((prevState) =>
      merge(prevState, { selfSimilarity: { min: 0, max: 100 } })
    );
  }, [props.defaultFilterValues]);

  useEffect(() => {
    setFilterValues(props.filterValues);
  }, [props.filterValues]);

  const handleSubmit = (values: AbnormalBurnsFilterFormValues) => {
    setFilterValues(values);
  };
  const handleViewBurnRequest = (rows: Array<BurnNonSensitiveDto>) => {
    setViewedBurns(rows);
  };

  return (
    <Stack
      direction={"column"}
      useFlexGap
      gap={2}
      sx={{ flexGrow: 1, minWidth: 0, minHeight: 0, pt: 1, pl: 1, pr: 1 }}
    >
      {viewedFlue == null && <Typography>No flue selected</Typography>}
      {viewedFlue != null && (
        <FlueMetricsLoader
          flueId={viewedFlue.id}
          type={[
            FlueMetricsTimeUnitEnum.Ever,
            FlueMetricsTimeUnitEnum.SinceSweep,
            FlueMetricsTimeUnitEnum.Year,
            FlueMetricsTimeUnitEnum.Month,
          ]}
        >
          {(result) => {
            return (
              <>
                <Stack direction={"row"} useFlexGap gap={1} flexWrap={"wrap"}>
                  <Property
                    label="Røykløp"
                    value={new StreetAddress(
                      viewedFlue.flue.streetAddress
                    ).toString()}
                  />
                  <Property
                    label="Sot-indeks"
                    value={notUndef(
                      viewedFlue?.flueMetricValuesSinceSweep.sootIndex,
                      (value) => MathUtils.round(value)
                    )}
                    sx={{ width: "100px" }}
                  />
                  <Property
                    label="Fyringer"
                    value={viewedFlue.burnCount}
                    sx={{ width: "100px" }}
                  />
                  <Property
                    label="Avvik"
                    value={viewedFlue.flueTypeDeviationAggregate.count}
                    sx={{ width: "100px" }}
                  />
                  <Property
                    label="Avvik i %"
                    value={MathUtils.round(
                      viewedFlue.flueTypeDeviationAggregate.percentage
                    )}
                    unit={"%"}
                    sx={{ width: "100px" }}
                  />
                </Stack>
                <Formik
                  initialValues={filterValues}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {(formik) => {
                    const handleResetFilter = () => {
                      formik.resetForm({ values: props.filterValues });
                      //setFilterValues();
                    };

                    const burnsFilterActions: Array<Action> = [
                      selectableAction({
                        name: t("Unfinished"),
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "unfinished",
                            action.selected
                              ? undefined
                              : props.defaultFilterValues.unfinished
                          ),
                        selected: formik.values.unfinished !== undefined,
                      }),
                      selectableAction({
                        name: "Min. fyringsvarighet",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "minDurationInHours",
                            action.selected
                              ? undefined
                              : defaultFilterValues.minDurationInHours
                          ),
                        selected:
                          formik.values.minDurationInHours !== undefined,
                      }),
                      selectableAction({
                        name: "Maks. fyringstemperatur",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "maxTemperature",
                            action.selected
                              ? undefined
                              : defaultFilterValues.maxTemperature
                          ),
                        selected: formik.values.maxTemperature !== undefined,
                      }),
                      selectableAction({
                        name: "Selvsimilaritet",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "selfSimilarity",
                            action.selected
                              ? undefined
                              : defaultFilterValues.selfSimilarity
                          ),
                        selected: formik.values.selfSimilarity !== undefined,
                      }),
                      selectableAction({
                        name: "Min. maks. delta temp.",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "minMaxDeltaTemperature",
                            action.selected
                              ? undefined
                              : defaultFilterValues.minMaxDeltaTemperature
                          ),
                        selected:
                          formik.values.minMaxDeltaTemperature !== undefined,
                      }),
                      selectableAction({
                        name: "Maks. maks. delta temp.",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "maxMaxDeltaTemperature",
                            action.selected
                              ? undefined
                              : defaultFilterValues.maxMaxDeltaTemperature
                          ),
                        selected:
                          formik.values.maxMaxDeltaTemperature !== undefined,
                      }),
                      selectableAction({
                        name: "Avvikende anslått røykløpstype",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "requireDeviantPresumedFlueType",
                            action.selected
                              ? undefined
                              : defaultFilterValues.requireDeviantPresumedFlueType
                          ),
                        selected:
                          formik.values.requireDeviantPresumedFlueType !==
                          undefined,
                      }),
                      selectableAction({
                        name: "Treffsikkerhet for anslått røykløpstype",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "presumedFlueTypeConfidence",
                            action.selected
                              ? undefined
                              : defaultFilterValues.presumedFlueTypeConfidence
                          ),
                        selected:
                          formik.values.presumedFlueTypeConfidence !==
                          undefined,
                      }),
                      selectableAction({
                        name: "Anslått røykløpstype",
                        onExecute: (action) =>
                          formik.setFieldValue(
                            "presumedFlueTypes",
                            action.selected
                              ? undefined
                              : defaultFilterValues.presumedFlueTypes
                          ),
                        selected: formik.values.presumedFlueTypes !== undefined,
                      }),
                    ];

                    return (
                      <>
                        <FilterGroup label={"Fyrings-filter"}>
                          <AddFilter filterActions={burnsFilterActions} />
                          {formik.values.unfinished !== undefined && (
                            <FormikProperty
                              formik={formik}
                              name={"unfinished"}
                              label={t("Unfinished")}
                              type={"boolean"}
                              mode={"edit"}
                              size={componentSize}
                              removable
                            />
                          )}
                          {formik.values.minDurationInHours !== undefined && (
                            <FormikProperty
                              formik={formik}
                              name={"minDurationInHours"}
                              label={"Minimum fyringsvarighet"}
                              removable
                              editComponent={"Slider"}
                              editComponentProps={{
                                Slider: {
                                  min: 0,
                                  max: 168,
                                  step: 0.5,
                                },
                              }}
                              sx={{ width: "250px" }}
                              unit={"t"}
                              mode={"edit"}
                              size={componentSize}
                            />
                          )}
                          {formik.values.selfSimilarity !== undefined && (
                            <Property
                              name={"selfSimilarity"}
                              label={"Self Similarity"}
                              value={formik.values.selfSimilarity}
                              removable
                              editComponent={"Slider"}
                              editComponentProps={{
                                Slider: {
                                  min: 0,
                                  max: 100,
                                },
                              }}
                              sx={{ width: "250px" }}
                              unit={"%"}
                              mode={"edit"}
                              onFormikFieldValueChange={formik.setFieldValue}
                              size={componentSize}
                            />
                          )}
                          {formik.values.maxTemperature !== undefined && (
                            <Property
                              name={"maxTemperature"}
                              label={"Maks. temp."}
                              value={formik.values.maxTemperature}
                              removable
                              editComponent={"Slider"}
                              editComponentProps={{
                                Slider: {
                                  min: 0,
                                  max: 600,
                                },
                              }}
                              sx={{ width: "250px" }}
                              unit={"°C"}
                              mode={"edit"}
                              onFormikFieldValueChange={formik.setFieldValue}
                              size={componentSize}
                            />
                          )}
                          {formik.values.minMaxDeltaTemperature !==
                            undefined && (
                            <Property
                              name={"minMaxDeltaTemperature"}
                              label={"Min. maks. delta temp."}
                              value={formik.values.minMaxDeltaTemperature}
                              removable
                              editComponent={"Slider"}
                              editComponentProps={{
                                Slider: {
                                  min: 0,
                                  max: 1000,
                                },
                              }}
                              sx={{ width: "250px" }}
                              mode={"edit"}
                              onFormikFieldValueChange={formik.setFieldValue}
                              size={componentSize}
                            />
                          )}
                          {formik.values.maxMaxDeltaTemperature !==
                            undefined && (
                            <Property
                              name={"maxMaxDeltaTemperature"}
                              label={"Maks. maks. delta temp."}
                              value={formik.values.maxMaxDeltaTemperature}
                              removable
                              editComponent={"Slider"}
                              editComponentProps={{
                                Slider: {
                                  min: 0,
                                  max: 1000,
                                },
                              }}
                              sx={{ width: "250px" }}
                              mode={"edit"}
                              onFormikFieldValueChange={formik.setFieldValue}
                              size={componentSize}
                            />
                          )}
                          {formik.values.requireDeviantPresumedFlueType !==
                            undefined && (
                            <Property
                              name={"requireDeviantPresumedFlueType"}
                              label={"Avvikende anslått røykløpstype"}
                              value={
                                formik.values.requireDeviantPresumedFlueType
                              }
                              removable
                              mode={"edit"}
                              onFormikFieldValueChange={formik.setFieldValue}
                            />
                          )}
                          {formik.values.presumedFlueTypes !== undefined && (
                            <Property
                              name={"presumedFlueTypes"}
                              label={"Anslått røykløpstype"}
                              value={formik.values.presumedFlueTypes}
                              valueOptions={{
                                options: flueTypeOptions,
                                multiple: true,
                                restrictToOptions: false,
                              }}
                              removable
                              mode={"edit"}
                              onFormikFieldValueChange={formik.setFieldValue}
                            />
                          )}
                          {formik.values.presumedFlueTypeConfidence !==
                            undefined && (
                            <Property
                              name={"presumedFlueTypeConfidence"}
                              label={"Treffsikkerhet for anslag"}
                              info={"Treffsikkerhet for anslått røykløpstype"}
                              value={formik.values.presumedFlueTypeConfidence}
                              removable
                              sx={{ width: "250px" }}
                              unit={"%"}
                              mode={"edit"}
                              onFormikFieldValueChange={formik.setFieldValue}
                            />
                          )}
                        </FilterGroup>
                        <Toolbar
                          variant={dense ? "dense" : "regular"}
                          disableGutters
                          sx={{ gap: 1 }}
                        >
                          <Button
                            variant={"outlined"}
                            color={"secondary"}
                            size={componentSize}
                            onClick={() => formik.submitForm()}
                          >
                            Last på nytt
                          </Button>
                          <Button
                            variant={"outlined"}
                            color={"secondary"}
                            size={componentSize}
                            onClick={handleResetFilter}
                          >
                            Resett filter
                          </Button>
                        </Toolbar>
                      </>
                    );
                  }}
                </Formik>
                <Stack
                  direction={"column"}
                  useFlexGap
                  gap={1}
                  sx={{ flexGrow: 1, minWidth: 0, minHeight: 0 }}
                  ref={ref}
                >
                  <Box sx={{ height: burnsTableHeight, display: "flex" }}>
                    <AbnormalBurnsTable
                      forceRefresh={0}
                      rows={rows}
                      onRowsChange={handleRowsChange}
                      query={query}
                      onViewRequest={handleViewBurnRequest}
                    />
                  </Box>
                  <BurnTemperatureSeriesLoader burnIds={selectedBurnIds}>
                    {({ temperatureSeries, isTemperatureSeriesLoading }) => {
                      return (
                        <TabsAndPanels
                          sx={{
                            height: burnsGraphHeight,
                          }}
                          value={tabValue}
                          onChange={setTabValue}
                          tabs={[
                            <Tab key={"graph"} label="Graf" value={"graph"} />,
                            <Tab
                              key={"details"}
                              label="Detaljer"
                              value={"details"}
                            />,
                          ]}
                          tabPanels={[
                            <TabPanel
                              tabValue={"graph"}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <BurnNonSensitiveChart2
                                flueId={viewedFlue?.flue.id}
                                burnTemperatureSeries={temperatureSeries}
                                sinceSweepMetrics={result.SinceSweep.data}
                                loading={isTemperatureSeriesLoading}
                                sx={{ flexGrow: 1, minHeight: 0 }}
                              />
                            </TabPanel>,
                            <TabPanel
                              tabValue={"details"}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {temperatureSeries?.map((it) => {
                                return (
                                  <PropertiesCard>
                                    <Property
                                      label={"SumOfDerivatives"}
                                      value={it.calculateSumOfDerivatives(
                                        derivativeOptions
                                      )}
                                    />
                                  </PropertiesCard>
                                );
                              })}
                            </TabPanel>,
                          ]}
                        />
                      );
                    }}
                  </BurnTemperatureSeriesLoader>
                </Stack>
              </>
            );
          }}
        </FlueMetricsLoader>
      )}
    </Stack>
  );
};
