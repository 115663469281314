import { useContext } from "react";
import { User } from "./User";
import { UserContext } from "./UserContext";

export const useUser = <USER extends User>(): USER => {
  const user = useContext(UserContext);

  if (user == null) {
    throw new Error("useUser must be used within an UserContextProvider");
  }
  return user as USER;
};

export const useUserOrNull = <USER extends User>(): USER | null => {
  const user = useContext(UserContext);

  if (user == null) {
    return null;
  }
  return user as USER;
};
