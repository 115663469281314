import {FontType, TextUtil} from "./TextUtil";
import {useMemo, useState} from "react";

export const useCalculateMaxWidth = (texts: Array<string>, element?: HTMLElement | null): number => {
    
    
    const computed = window.getComputedStyle(element != null ? element : document.body);
    const font: FontType = {};
    if (computed.fontFamily.length > 0) {
        font.fontFamily = computed.fontFamily;
    }
    if (computed.fontSize.length > 0) {
        font.fontSize = computed.fontSize;
    }
    if (computed.fontWeight.length > 0) {
        font.fontWeight = computed.fontWeight;
    }
    if (computed.fontKerning.length > 0) {
        font.fontKerning = computed.fontKerning;
    }
    if (computed.fontStretch.length > 0) {
        font.fontStretch = computed.fontStretch;
    }
    if (computed.letterSpacing.length > 0) {
        font.letterSpacing = computed.letterSpacing;
    }

    const computedWidth =  useMemo(() => TextUtil.calculateMaxSize(texts, font).width,
        [font.fontFamily, font.fontSize, font.fontWeight, font.letterSpacing, font.fontKerning, font.fontStretch, ...texts]);
    
    const [width, setWidth] = useState<number>(computedWidth);
    if(computedWidth != width) {
        setWidth(computedWidth);
    }
    return width;
}