import React, { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";
import { SxProps } from "@mui/system";
import { merge } from "lodash";
import { Gallery, Item } from "react-photoswipe-gallery";

export interface ImageGalleryProps {
  images: Array<string>;
  sx?: SxProps;
}
export const ImageGallery: FC<ImageGalleryProps> = (props) => {
  const { images } = props;
  const { width, height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
  });
  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      width: width != null ? width : undefined,
      height: height != null ? height : undefined,
    });
  }, [props.sx, height, width]);

  return (
    <Box ref={ref} sx={sx} className={"ImageGallery"}>
      {width !== undefined && height !== undefined && (
        <Gallery>
          {images.map((image) => {
            return (
              <Item<HTMLImageElement> key={image} original={image} cropped>
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    src={`${image}`}
                    alt={image}
                    loading="lazy"
                    style={{
                      maxWidth: width != null ? width : undefined,
                      maxHeight: height != null ? height : undefined,
                    }}
                  />
                )}
              </Item>
            );
          })}
        </Gallery>
      )}
    </Box>
  );
};
