import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface FlueMetricValuesTableRowHeaderProps {
  compact?: boolean;
}

export const FlueMetricValuesTableRowHeader: FC<
  FlueMetricValuesTableRowHeaderProps
> = (props) => {
  const compact = props.compact ?? false;
  const { t } = useTranslation("firefly-shared-ts-domain");
  return (
    <TableRow>
      <TableCell>{t("Period")}</TableCell>
      <TableCell align={"right"}>{t("Burns")}</TableCell>
      <TableCell align={"right"}>
        {compact ? t("Burn Duration:breakable") : t("Burn Duration")}
      </TableCell>
      <TableCell align={"right"}>{t("Soot Index")}</TableCell>
      <TableCell align={"right"}>{t("Max Temp.")}</TableCell>
      <TableCell align={"right"}>
        {compact ? t("Temperature Alarms:breakable") : t("Temperature Alarms")}
      </TableCell>
    </TableRow>
  );
};
