import { FC } from "react";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { ChartsAxisContentProps } from "@mui/x-charts/ChartsTooltip/ChartsAxisTooltipContent";
import { With } from "@airmont/shared/ts/ui/react";
import { LineSeriesType } from "@mui/x-charts";
import { ChartsTooltipMark } from "shared-ts-mui";
import { DateTime } from "luxon";

export const ChartsTooltipAxisContent: FC<ChartsAxisContentProps> = (props) => {
  const theme = useTheme();
  const axisValue = props.axisValue;
  return (
    <Stack
      direction={"column"}
      sx={{
        ml: 2,
        mr: 2,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        borderRadius: 1,
      }}
    >
      {axisValue != null && (
        <Typography
          color={"text.secondary"}
          sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}
        >
          {axisValue instanceof Date
            ? DateTime.fromJSDate(axisValue).toLocaleString(
                DateTime.DATETIME_SHORT
              )
            : axisValue}
        </Typography>
      )}
      <Divider />
      <Stack p={1} direction={"column"} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
        <With>
          {() => {
            const dataIndex = props.dataIndex;
            if (dataIndex == null) {
              return undefined;
            }
            const series = props.series as Array<LineSeriesType>;
            return series
              .filter((it) => it.data?.[dataIndex] != null)
              .map((itSeries, ix) => {
                const value = itSeries.data?.[dataIndex];

                return (
                  <Stack
                    key={ix}
                    direction={"row"}
                    useFlexGap
                    gap={1}
                    sx={{ alignItems: "center" }}
                  >
                    <ChartsTooltipMark color={itSeries.color} />
                    <Typography color={"text.secondary"}>
                      {itSeries.label !== undefined &&
                      typeof itSeries.label === "string"
                        ? itSeries.label
                        : itSeries.label?.("tooltip")}
                    </Typography>
                    <Typography sx={{ marginLeft: "auto", pl: 1 }}>
                      {value != null
                        ? itSeries.valueFormatter != null
                          ? itSeries.valueFormatter?.(value, {
                              dataIndex: dataIndex,
                            })
                          : value
                        : ""}
                    </Typography>
                  </Stack>
                );
              });
          }}
        </With>
      </Stack>
    </Stack>
  );
};
