import React, { FC, useMemo } from "react";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { UnauthenticatedRootPage } from "./UnauthenticatedRootPage";
import { ErrorPage } from "../../shared/error/router/ErrorPage";
import { UnauthenticatedPage } from "@airmont/shared/ts/ui/app-boot";
import { useOidcPaths } from "@airmont/firefly/shared/ts/authentication-oidc";
import { appName } from "../../appInfo";
import { useAuthenticationRoutes } from "../authorization/useAuthenticationRoutes";
import { AirmontLogoImg } from "shared-ts-ui-airmont";

export const AppUnauthenticated: FC = (props) => {
  const paths = useOidcPaths();

  console.log(
    "******************************************************************************"
  );
  console.log(
    " AppUnauthenticated **********************************************************"
  );
  console.log(
    "******************************************************************************"
  );

  console.log("Unauth location: ", window.location.href);

  const returnUrl = window.location.href;
  const authenticationRoute: RouteObject = useAuthenticationRoutes(
    returnUrl,
    false
  );
  const rootRoute: RouteObject = useMemo(() => {
    return {
      path: "/",
      element: <UnauthenticatedRootPage />,
      errorElement: <ErrorPage />,
      loader: (args) => {
        console.log("Unauthenticated root page: ", args);
        return null;
      },
      children: [
        {
          index: true,
          loader: ({ params }) => {
            console.log("Unauthenticated index route: " + params["*"]);
            return null;
          },
          element: (
            <UnauthenticatedPage
              appName={appName}
              appIcon={<AirmontLogoImg />}
              loginPath={paths.Login}
            />
          ),
        },
        authenticationRoute,
        {
          path: "*",
          loader: ({ params }) => {
            console.log("Unauthenticated splat route: " + params["*"]);
            return null;
          },
          element: (
            <UnauthenticatedPage
              appName={appName}
              appIcon={<AirmontLogoImg />}
              loginPath={paths.Login}
            />
          ),
        },
      ],
    };
  }, [authenticationRoute, paths.Login]);
  const routes: Array<RouteObject> = useMemo(() => [rootRoute], [rootRoute]);

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};
