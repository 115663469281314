import {
  DurationPoint,
  DurationPointDto,
  isDurationPoint,
  isDurationPointDto,
} from "./DurationPoint";
import { Duration } from "luxon";

/**
 * A DurationPoint where the value is of type double.
 */
export type DurationDoubleDto = DurationPointDto<number>;
export type DurationDoubleNullableDto = DurationPointDto<number | null>;

export function isDurationDoubleDto(obj: unknown): obj is DurationDoubleDto {
  return isDurationPointDto(obj) && typeof obj.value === "number";
}

export interface DurationDouble extends DurationPoint<number> {
  toDto(): DurationDoubleDto;
}

export function isDurationDouble(obj: unknown): obj is DurationDouble {
  return isDurationPoint(obj) && typeof obj.value === "number";
}

export interface DurationDoubleNullable extends DurationPoint<number | null> {
  toDto(): DurationDoubleNullableDto;
}

export class DurationDoubleImpl implements DurationDouble {
  duration: Duration;
  value: number;

  constructor(args: { duration: Duration; value: number }) {
    this.duration = args.duration;
    this.value = args.value;
  }

  toDto(): DurationDoubleDto {
    return {
      duration: this.duration.toMillis(),
      value: this.value,
    };
  }
}

export class DurationDoubleNullableImpl implements DurationDoubleNullable {
  duration: Duration;
  value: number | null;

  constructor(args: { duration: Duration; value: number | null }) {
    this.duration = args.duration;
    this.value = args.value;
  }

  toDto(): DurationDoubleNullableDto {
    return {
      duration: this.duration.toMillis(),
      value: this.value,
    };
  }
}
