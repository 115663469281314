import { FC } from "react";
import { AuditInfoDto } from "./AuditInfoDto";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { Property } from "shared-ts-property";
import { useTranslation } from "react-i18next";

export interface AuditPaneProps {
  audit: AuditInfoDto;
}

export const AuditPane: FC<AuditPaneProps> = (props) => {
  const { t } = useTranslation("firefly-shared-ts-domain");
  return (
    <PropertiesCard>
      <Property
        mode={"read"}
        label={t("Created")}
        value={props.audit.created}
        type={"dateTime"}
      />
      <Property
        mode={"read"}
        label={t("Created by")}
        value={props.audit.createdBy}
        type={"string"}
      />
      <Property
        mode={"read"}
        label={t("Changed")}
        value={props.audit.modified}
        type={"dateTime"}
      />
      <Property
        mode={"read"}
        label={t("Changed by")}
        value={props.audit.modifiedBy}
        type={"string"}
      />
    </PropertiesCard>
  );
};
