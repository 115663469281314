import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import {
  _throw,
  IllegalStateError,
  notUndef,
  NullError,
} from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import {
  PostalCodeMetricsQuery,
  PostalCodeMetricsQueryDto,
} from "./PostalCodeMetricsQuery";
import { PostalCodeMetricsDto } from "./PostalCodeMetrics";

export class PostalCodeMetricsDao {
  private fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/postal_code_metrics/",
    })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  query(query: PostalCodeMetricsQuery): Promise<Array<PostalCodeMetricsDto>> {
    return this.fetchHelper
      .post("query", {
        type: query.type,
        time: notUndef(
          query.time,
          (it) => it.toUTC().toISO() ?? _throw(new IllegalStateError("time"))
        ),
      } as PostalCodeMetricsQueryDto)
      .fetchAsJson();
  }
}
