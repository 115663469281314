import { FC, ReactNode, useMemo } from "react";
import {
  BurnId,
  BurnNonSensitiveUi,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useBurnDao } from "./useBurnDao";
import { ColorSchemeGenerator } from "shared-ts-mui";

type ChildrenParams = {
  burns: Array<BurnNonSensitiveUi> | undefined;
  burnIds: Array<BurnId> | undefined;
  isBurnsLoading: boolean;
};

export interface BurnsLoaderProps {
  flueId: FlueId;
  fromDate: DateTime;
  toDate: DateTime;
  children: (props: ChildrenParams) => ReactNode;
}

export const BurnsLoader: FC<BurnsLoaderProps> = (props) => {
  const burnDao = useBurnDao();
  const { data, isLoading: isBurnsLoading } = useQuery({
    queryKey: ["BurnDao.getByFlue", props.flueId, props.fromDate, props.toDate],
    queryFn: () =>
      burnDao.getByFlue(props.flueId, props.fromDate, props.toDate),
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
  const burns = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }

    let count = data.length;
    const colors = ColorSchemeGenerator.getComplementaries(data?.length ?? 0);
    return data.map(
      (it, index) =>
        new BurnNonSensitiveUi(it, {
          orderOfOccurence: count--,
          color: colors[index],
        })
    );
  }, [data]);
  const burnIds = useMemo(() => burns?.map((it) => it.id), [burns]);

  const result: ChildrenParams = useMemo(() => {
    return { burns: burns, burnIds: burnIds, isBurnsLoading: isBurnsLoading };
  }, [burns, burnIds, isBurnsLoading]);

  return props.children(result);
};
