import { cloneElement, FC, isValidElement, ReactElement } from "react";
import {
  Box,
  CircularProgress,
  LinearProgress,
  SxProps,
  Typography,
} from "@mui/material";
import { useResizeDetector } from "react-resize-detector";
import { DurationSeriesMuiChartProps } from "./DurationSeriesMuiChart";
import {
  ChartsLocaleText,
  useMuiLocalization,
  useSxMerge,
} from "shared-ts-mui";

export interface DurationSeriesMuiChartContainerProps {
  children: ReactElement<DurationSeriesMuiChartProps> | false;
  loading?: boolean;
  localeText?: Partial<ChartsLocaleText>;
  sx?: SxProps;
}

export const DurationSeriesMuiChartContainer: FC<
  DurationSeriesMuiChartContainerProps
> = (props) => {
  const { ref, width, height } = useResizeDetector();
  const l = useMuiLocalization();

  const loading = props.loading;
  const hasData =
    isValidElement(props.children) &&
    props.children.props.durationSeries !== undefined &&
    props.children.props.durationSeries.length > 0;

  const sx = useSxMerge(props.sx, {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });
  return (
    <Box ref={ref} sx={sx}>
      {loading && hasData && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "2px",
          }}
        />
      )}
      {loading && !hasData && <CircularProgress />}
      {props.children !== false &&
        !loading &&
        hasData &&
        cloneElement(props.children, { width: width, height: height })}
      {!loading && !hasData && (
        <Typography>
          {props.localeText?.noData ??
            l.muiLocalization.components.MuiCharts.defaultProps.localeText
              .noData}
        </Typography>
      )}
    </Box>
  );
};
