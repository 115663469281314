import { environment } from "../../environments/environment";
import { bootDemo, demoUser } from "./demo/bootDemo";
import {
  OidcAuthenticationService,
  OidcUser,
} from "@airmont/firefly/shared/ts/authentication-oidc";
import { SystemDao } from "@airmont/firefly/chimney-insights/ts/domain";
import { ChimneyInsightsBootResult } from "./ChimneyInsightsBootResult";
import { UnauthenticatedError } from "@airmont/shared/ts/ui/app-boot";
import { User } from "shared-ts-utils-authentication";
import {
  BooleanSetting,
  StringSetting,
  UserSettings,
} from "@airmont/shared/ts/utils/user-settings";
import { appKey, appRelease } from "../../appInfo";
import { MunicipalityDao } from "@airmont/firefly/shared/ts/domain";
import { DisableWhatsNewUserSettingName } from "@airmont/firefly/chimney-insights/ts/pages";

const loadUser = async (
  authService: OidcAuthenticationService
): Promise<User> => {
  const oidcClientUser = await authService.getUser();
  if (oidcClientUser == null) {
    throw new UnauthenticatedError();
  }
  return new OidcUser(oidcClientUser);
};

export const boot = async (args: {
  onMessage: (message: string) => void;
  onError: (error: Error) => void;
}): Promise<ChimneyInsightsBootResult> => {
  const authService = OidcAuthenticationService.get();
  const useBackend = await resolveUseBackend();
  console.info(" useBackend " + useBackend);
  if (!useBackend) {
    args.onMessage("Genererer demo data.");
    await bootDemo();
  }

  args.onMessage("Laster brukerinfo");
  const user = useBackend ? await loadUser(authService) : demoUser;

  const userSettings = new UserSettings({
    user: user,
    app: appKey,
  });
  let language = await userSettings.read("language", StringSetting);
  if (language == null) {
    language = "nb-NO";
    await userSettings.write("language", language, StringSetting);
  } else {
    const localAppLanguage = localStorage.getItem(`${appKey}:language`);
    if (localAppLanguage !== language) {
      localStorage.setItem(`${appKey}::language`, language);
    }
  }

  const systemDao = new SystemDao({ accessToken: user.accessToken });
  args.onMessage("Laster kundemiljøer");
  const environments = await systemDao.getCustomerEnvironments();
  if (environments.length === 0) {
    throw new Error("No environments available");
  }
  let initialEnvironment = userSettings.read(
    "customerEnvironment",
    StringSetting
  );
  if (initialEnvironment == null) {
    initialEnvironment = environments[0].id;
  }

  const accessToInitialEnvironment = environments.some(
    (it) => it.id === initialEnvironment
  );

  if (!accessToInitialEnvironment) {
    initialEnvironment = environments[0].id;
  }

  const municipalityDao = new MunicipalityDao({
    accessToken: user.accessToken,
    customerEnvironment: initialEnvironment,
  });
  args.onMessage("Laster kommuner");
  const municipalities = await municipalityDao.getList();

  // Check if previous boot was with a previous release
  let previousRelease = await userSettings.read(
    "AppInfo.release",
    StringSetting
  );
  console.log("previousRelease:", previousRelease);
  if (previousRelease == null) {
    await userSettings.write<string>(
      "AppInfo.release",
      appRelease,
      StringSetting
    );
    previousRelease = await userSettings.read("AppInfo.release", StringSetting);
  }
  console.log("appRelease:", appRelease);
  // .. if so ensure Whats New page is displayed
  if (appRelease !== previousRelease) {
    await userSettings.write<boolean>(
      DisableWhatsNewUserSettingName,
      false,
      BooleanSetting
    );
  }
  await userSettings.write<string>(
    "AppInfo.release",
    appRelease,
    StringSetting
  );

  return {
    customerEnvironments: environments,
    municipalities: municipalities,
    user: user,
  };
};

const resolveUseBackend = async (): Promise<boolean> => {
  if (environment.production) {
    return Promise.resolve(true);
  }
  try {
    await new SystemDao().ping();
    return Promise.resolve(true);
  } catch (e) {
    return Promise.resolve(false);
  }
};
