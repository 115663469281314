import { createContext, Dispatch, SetStateAction } from "react";
import { SettingsStorage } from "./storage/SettingsStorage";
import { NonAsyncSettingsStorage } from "./storage/NonAsyncSettingsStorage";

export type SettingsContextDictionary = Record<string, string | null>;

export type UserSettingsContextType = {
  settings: SettingsContextDictionary;
  setSettings: Dispatch<SetStateAction<SettingsContextDictionary>>;
  storage: SettingsStorage;
  localStorage: NonAsyncSettingsStorage;
};
export const UserSettingsContext = createContext<UserSettingsContextType>(
  [] as unknown as UserSettingsContextType
);
