import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { AccessToken } from "shared-ts-utils-authentication";
import { ChimneySensorDto, ChimneySensorSerialNr } from "./ChimneySensor";
import { ChimneySensorUpdateDto } from "./ChimneySensorUpdate";
import { SensorSerialNumber } from "./SensorSerialNumber";

export class ChimneySensorDao {
  private readonly accessToken: AccessToken;
  private readonly customerEnvironment: string;
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: AccessToken;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.accessToken = args.accessToken;
    this.customerEnvironment = args.customerEnvironment;
    this.fetchHelper = new FetchHelper({
      basePath: "/api/chimney-sensor/",
    })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  async getById(
    id: ChimneySensorSerialNr
  ): Promise<ChimneySensorDto | undefined> {
    return this.fetchHelper.get(`get-by-id/${id}`).fetchAsJson();
  }

  async update(update: ChimneySensorUpdateDto): Promise<ChimneySensorDto> {
    return this.fetchHelper.post("update", update).fetchAsJson();
  }

  async delete(id: SensorSerialNumber): Promise<void> {
    return this.fetchHelper.delete(`delete/${id}`).fetch();
  }
}
