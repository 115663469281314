import React, { FC } from "react";
import { Box, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { DateTime, Duration } from "luxon";
import { useSxMerge } from "shared-ts-mui";
import { useTimeslots } from "./day/useTimeslots";
import { useCalendarSettings } from "./CalendarSettingsProvider";

export interface CalendarTimeslotLabelsProps {
  timeslotDuration: Duration;
  sx?: SxProps;
}
export const CalendarTimeslotLabels: FC<CalendarTimeslotLabelsProps> = (
  props
) => {
  const { timeslotDuration } = props;
  const theme = useTheme();
  const calendarSettings = useCalendarSettings();

  const timeslots = useTimeslots(timeslotDuration);

  const sx = useSxMerge(props.sx, {
    ">.TimeslotLabel": {
      minHeight: calendarSettings.timeslotHeight + "px",
      position: "relative",
      ".label": {
        position: "absolute",
        left: 0,
        right: 0,
        top: "-11px",
        userSelect: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    ">.TimeslotLabel:not(:last-child)": {
      borderBottom: "1px solid " + theme.palette.divider,
    },
  });

  return (
    <Stack className={"CalendarTimeslotLabels"} direction={"column"} sx={sx}>
      <Box
        key={-1}
        sx={{
          minHeight: calendarSettings.timeslotsTopGutterHeight + "px",
          borderBottom: "1px solid " + theme.palette.divider,
        }}
      >
        <Typography variant={"caption"}> </Typography>
      </Box>
      {timeslots.map((dateTime) => {
        return (
          <Box key={dateTime.toMillis()} className={"TimeslotLabel"}>
            <Typography
              className={"label"}
              data-hour={dateTime.hour}
              variant={"caption"}
            >
              {dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
