import {FC, ReactNode} from "react";
import {CardActionArea} from "@mui/material";

export interface CardActionAreaOrNotProps {
    cardActions: boolean;
    children: ReactNode;

    onClick: () => void;
}

export const CardActionAreaOrNot: FC<CardActionAreaOrNotProps> = (props) => {

    const handleClick = () => {
        props.onClick();
    }

    if (props.cardActions) {
        return <CardActionArea onClick={handleClick}>{props.children}</CardActionArea>
    } else {
      // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{props.children}</>
    }
}
