import { RouteObject } from "react-router-dom";
import React, { useMemo } from "react";
import { LoginPage } from "./LoginPage";
import { LoginAction } from "./LoginAction";
import { Logout } from "./Logout";
import { OidcLogoutActions } from "@airmont/firefly/shared/ts/authentication-oidc";

export const useAuthenticationRoutes = (
  returnUrl: string,
  isAuthenticated: boolean
): RouteObject => {
  return useMemo(() => {
    const children: Array<RouteObject> = [];

    if (!isAuthenticated) {
      children.push(
        {
          path: "login",
          element: (
            <LoginPage action={LoginAction.Login} returnUrl={returnUrl} />
          ),
          loader: (args) => {
            console.log("LoginPage with action Login: ", args);
            return null;
          },
        },
        {
          path: "login-failed",
          element: (
            <LoginPage action={LoginAction.LoginFailed} returnUrl={returnUrl} />
          ),
          loader: (args) => {
            console.log("LoginPage with action LoginFailed: ", args);
            return null;
          },
        },
        {
          path: "login-callback",
          element: <LoginPage action={LoginAction.LoginCallback} />,
          loader: (args) => {
            console.log("LoginPage with action LoginCallback: ", args);
            return null;
          },
        },

        {
          path: "login-profile",
          element: <LoginPage action={LoginAction.Profile} />,
          loader: (args) => {
            console.log("LoginPage with action Profile: ", args);
            return null;
          },
        }
      );
    } else {
      children.push(
        {
          path: "logout",
          element: <Logout action={OidcLogoutActions.Logout} />,
          loader: (args) => {
            console.log("Logout with action Logout: ", args);
            return null;
          },
        },
        {
          path: "logout-callback",
          element: <Logout action={OidcLogoutActions.LogoutCallback} />,
          loader: (args) => {
            console.log("Logout with action LogoutCallback: ", args);
            return null;
          },
        },
        {
          path: "logged-out",
          element: <Logout action={OidcLogoutActions.LoggedOut} />,
          loader: (args) => {
            console.log("Logout with action LoggedOut: ", args);
            return null;
          },
        }
      );
    }

    return {
      path: "authentication",
      children: children,
    };
  }, [isAuthenticated, returnUrl]);
};
