import { AppKey } from "shared-ts-app-info";
import { resolveKey } from "./resolveKey";

export interface SettingKeyDto {
  name: string;
  app: AppKey;
  user: string | null | undefined;
}

export class SettingKey {
  private dto: SettingKeyDto;
  public asString: string;
  constructor(dto: SettingKeyDto) {
    this.dto = dto;
    this.asString = resolveKey(dto.name, dto.app, dto.user);
  }

  get app(): string {
    return this.dto.app;
  }

  get user(): string | null | undefined {
    return this.dto.user;
  }

  get name(): string {
    return this.dto.name;
  }

  toString(): string {
    return this.asString;
  }

  static from(
    name: string,
    app: AppKey,
    user: string | null | undefined
  ): SettingKey {
    return new SettingKey({ name: name, app: app, user: user });
  }
}
