import { BurnDto, createBurnDto } from "@airmont/firefly/shared/ts/domain";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";

export const getBurnMocks = (args: {
  flues: Array<FlueStatusDto>;
}): Array<BurnDto> => {
  return args.flues
    .map((flue) => {
      return ArrayUtils.createAndFill(3, () => {
        return createBurnDto({ flueId: flue.id });
      });
    })
    .flat(1);
};
