import { useMemo } from "react";
import { LatLngBounds } from "./LatLngBounds";
import { Feature, Point } from "geojson";

export const useLatLngBounds = (
  features: Array<Feature<Point>> | Feature<Point>
): google.maps.LatLngBounds | undefined => {
  return useMemo(() => {
    return Array.isArray(features)
      ? features.length === 0
        ? undefined
        : LatLngBounds.fromFeatures(features)
      : LatLngBounds.fromFeatures([features]);
  }, [features]);
};
