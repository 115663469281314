import { Duration } from "luxon";

/**
 * A value at a duration.
 * The Duration is in milliseconds.
 */
export interface DurationPointDto<TValue> {
  duration: number;
  value: TValue;
}

export function isDurationPointDto(
  obj: unknown
): obj is DurationPointDto<unknown> {
  return (
    obj != null &&
    typeof obj === "object" &&
    "duration" in obj &&
    typeof obj.duration === "number" &&
    "value" in obj
  );
}

export interface DurationPoint<TValue> {
  duration: Duration;
  value: TValue;
}

export function isDurationPoint(obj: unknown): obj is DurationPoint<unknown> {
  return (
    obj != null &&
    typeof obj === "object" &&
    "duration" in obj &&
    Duration.isDuration(obj.duration) &&
    "value" in obj
  );
}
