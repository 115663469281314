import { BurnDto } from "./Burn";
import { BurnTemperatureSeriesDto } from "./BurnTemperatureSeries";
import { TimeNumberDto } from "@airmont/firefly/shared/ts/timeseries";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { DateTime } from "luxon";

const temperatures = [
  [
    23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 24, 25, 26, 30, 35, 40, 44, 49, 55,
    59, 63, 71, 82, 90, 98, 107, 117, 126, 137, 146, 156, 164, 172, 182, 205,
    214, 223, 231, 239, 246, 250, 255, 260, 264, 268, 272, 275, 278, 281, 282,
    285, 286, 288, 290, 291, 292, 292, 293, 293, 293, 293, 293, 293, 293, 293,
    293, 293, 293, 291, 290, 289, 288, 287, 287, 285, 284, 283, 281, 279, 277,
    275, 273, 272, 269, 266, 264, 262, 260, 258, 256, 254, 253, 251, 249, 247,
    246, 244, 242, 240, 239, 237, 235, 233, 232, 229, 227, 226, 224, 222, 220,
    218, 216, 215, 213, 211, 209, 207, 205, 203, 201, 199, 197, 195, 194, 192,
    190, 188, 186, 185, 183, 182, 180, 178, 177, 175, 173, 172, 170, 168, 167,
    166, 164, 162, 161, 159, 158, 157, 155, 154, 152, 151, 150, 148, 147, 145,
    144, 143, 142, 140, 139, 138, 137, 135, 134, 133, 131, 130, 129, 127, 126,
    125, 124, 123, 122, 120, 119, 118, 117, 116, 115, 114, 113, 111, 111, 109,
    108, 107, 106, 105, 104, 103, 102, 101, 100, 99, 99, 98, 96, 96, 95, 94, 93,
    92, 92, 91, 90, 89, 88, 87, 86, 85, 85, 84, 83, 83, 82, 81, 80, 80, 79, 78,
    77, 77, 76, 75, 75, 74, 74, 73, 72, 72, 71, 71, 70, 69, 69, 68, 68, 67, 66,
    66, 65, 65, 64, 64, 63, 63, 62, 62, 61, 61, 60, 60, 59, 59, 59, 58, 58, 57,
    57, 57, 56, 56, 55, 54, 54, 54, 53, 53, 53, 52, 52, 51, 51, 50, 50, 50, 49,
    49, 49, 48, 48, 48, 47, 47, 47, 46, 46, 46, 45, 45, 45, 44, 44, 44, 44, 43,
    44, 43, 42, 42, 42, 41, 41, 41, 41, 40, 40, 40, 39, 39, 39, 39, 39, 38, 38,
    38, 38, 37, 37, 37, 37, 37, 36, 36, 36, 36, 35, 35, 35, 35, 35, 35, 34, 35,
    34, 34, 34, 34, 34, 33, 33, 33, 33, 33, 33, 32, 32, 32, 32, 32, 32, 32, 31,
    31, 31, 31, 31, 31, 31, 30,
  ],
  [
    14, 13, 14, 14, 14, 13, 14, 14, 14, 14, 14, 16, 16, 17, 18, 18, 19, 21, 24,
    26, 26, 26, 29, 33, 39, 45, 51, 58, 64, 69, 74, 79, 84, 91, 97, 106, 117,
    127, 138, 149, 159, 170, 181, 191, 199, 207, 215, 221, 228, 234, 240, 246,
    251, 255, 259, 263, 266, 269, 273, 275, 277, 279, 280, 280, 281, 284, 285,
    286, 286, 286, 287, 287, 287, 286, 286, 285, 284, 283, 283, 281, 280, 279,
    278, 276, 275, 274, 273, 272, 270, 269, 267, 266, 265, 263, 262, 261, 260,
    258, 257, 256, 255, 254, 252, 251, 249, 247, 245, 243, 238, 235, 232, 230,
    228, 230, 232, 233, 234, 235, 238, 240, 241, 243, 245, 246, 247, 249, 250,
    251, 252, 253, 254, 255, 257, 257, 257, 258, 259, 259, 259, 260, 260, 260,
    260, 260, 260, 260, 259, 258, 259, 259, 258, 258, 258, 258, 257, 257, 256,
    255, 255, 254, 253, 252, 252, 251, 250, 249, 249, 248, 246, 245, 243, 242,
    240, 239, 237, 236, 234, 233, 231, 230, 228, 227, 225, 224, 222, 221, 219,
    218, 216, 215, 213, 211, 210, 208, 207, 205, 203, 202, 200, 199, 197, 196,
    194, 192, 190, 189, 187, 186, 184, 182, 180, 179, 177, 176, 174, 173, 171,
    169, 168, 167, 165, 163, 162, 160, 159, 157, 155, 154, 152, 151, 149, 148,
    147, 145, 144, 143, 141, 140, 138, 137, 135, 134, 133, 132, 130, 129, 127,
    126, 125, 124, 123, 121, 120, 119, 118, 116, 116, 114, 113, 112, 111, 110,
    109, 108, 107, 106, 105, 104, 103, 101, 101, 99, 99, 98, 96, 96, 95, 94, 93,
    92, 91, 90, 90, 89, 88, 87, 86, 85, 85, 84, 83, 82, 82, 81, 80, 79, 79, 78,
    77, 77, 76, 75, 74, 74, 73, 73, 72, 71, 71, 70, 69, 69, 68, 68, 67, 67, 66,
    65, 65, 65, 65, 63, 63, 62, 62, 62, 61, 60, 60, 60, 59, 59, 58, 58, 57, 57,
    56, 56, 56, 56, 55, 55, 54, 54, 54, 53, 53, 52, 52, 52, 51, 51, 51, 50, 50,
    50, 50, 50, 49, 48, 48, 48, 48, 47, 47, 47, 47, 47, 47, 46, 46, 46, 45, 45,
    45, 45, 45, 44, 44, 44, 44, 44, 44, 43, 43, 43, 43, 43, 43, 42, 42,
  ],
  [
    24, 24, 24, 23, 15, 5, 3, 2, 2, 2, 8, 13, 16, 18, 19, 20, 21, 21, 22, 24,
    25, 27, 29, 31, 32, 34, 35, 36, 36, 36, 36, 37, 37, 37, 38, 38, 39, 41, 42,
    44, 45, 46, 47, 47, 47, 47, 48, 48, 48, 48, 47, 47, 47, 47, 47, 47, 46, 46,
    45, 45, 45, 45, 45, 44, 43, 44, 45, 46, 47, 48, 50, 51, 51, 51, 51, 53, 56,
    62, 72, 79, 87, 97, 107, 116, 127, 137, 145, 152, 157, 162, 170, 176, 182,
    188, 193, 197, 202, 206, 212, 215, 220, 222, 226, 229, 231, 235, 240, 241,
    243, 246, 250, 252, 253, 255, 258, 260, 261, 262, 264, 266, 267, 268, 269,
    270, 272, 273, 273, 275, 275, 276, 276, 278, 278, 278, 278, 278, 279, 279,
    278, 278, 278, 277, 276, 276, 276, 275, 274, 274, 273, 273, 272, 271, 270,
    269, 268, 267, 267, 266, 265, 263, 261, 261, 258, 257, 255, 254, 252, 251,
    249, 248, 247, 245, 243, 242, 240, 239, 237, 235, 234, 233, 231, 230, 228,
    227, 225, 224, 222, 221, 220, 218, 217, 216, 214, 213, 212, 210, 209, 208,
    207, 205, 204, 203, 202, 201, 199, 198, 198, 196, 195, 194, 193, 192, 191,
    189, 188, 188, 186, 185, 184, 183, 181, 180, 179, 178, 177, 176, 175, 173,
    172, 171, 170, 169, 168, 167, 165, 165, 164, 162, 162, 161, 159, 158, 157,
    156, 155, 154, 153, 152, 150, 149, 148, 147, 146, 145, 144, 143, 142, 141,
    140, 139, 138, 137, 137, 135, 134, 133, 132, 131, 130, 128, 127, 126, 125,
    124, 123, 122, 121, 121, 120, 119, 118, 117, 116, 115, 114, 113, 113, 111,
    111, 110, 109, 108, 107, 107, 106, 105, 105, 104, 103, 102, 101, 101, 100,
    99, 98, 98, 97, 96, 96, 95, 94, 94, 93, 93, 92, 91, 91, 90, 90, 89, 89, 88,
    87, 87, 86, 86, 85, 84, 84, 83, 83, 82, 82, 81, 81, 80, 79, 79, 79, 78, 78,
    77, 76, 75, 75, 75, 74, 74, 73, 73, 72, 72, 72, 71, 70, 70, 69, 70, 69, 68,
    68, 68, 67, 66, 66, 66, 65, 65, 64, 64, 63, 63, 62, 62, 62, 61, 61, 60, 60,
    60, 59, 59, 59, 58, 57, 57, 57, 56, 56, 56, 55, 55, 55, 54, 54, 54, 53, 53,
    52, 52, 52, 51, 50, 50, 50, 50, 50, 49, 48, 48, 48, 47, 47, 47, 46, 46, 46,
    45, 45, 45, 44, 44, 44, 44, 43, 43, 43, 42, 42, 42, 42, 41, 41, 41, 41, 40,
    40, 40, 39, 39, 39, 39, 39, 39, 38, 38, 38, 38, 37, 37, 37, 36, 36, 36, 36,
    36, 36, 35, 35, 35, 35, 35, 35, 35, 34, 34, 34, 33, 33, 33, 33, 33, 33, 33,
    32, 32, 32, 32, 32, 32, 32,
  ],
];

let tempSeriesIndex = 0;
export const createBurnTemperatureSeries = (args: {
  burn: BurnDto;
}): BurnTemperatureSeriesDto => {
  const startTime = DateTime.fromISO(args.burn.startTime);
  //const endTime = notUndef(args.burn.endTime, (it) => DateTime.fromISO(it));
  /*const interval = notUndef(endTime, (it) =>
    Interval.fromDateTimes(startTime, it)
  );*/
  //const duration = interval?.toDuration("minute");
  /*const totalNumberOfMinutes =
    notUndef(duration, (it) => it.get("minutes")) ?? 60;*/

  const dataPoints: Array<TimeNumberDto> = temperatures[tempSeriesIndex++].map(
    (temperature, index) => {
      const time = index > 0 ? startTime.plus({ minutes: index }) : startTime;

      return {
        time: time.toISO() ?? _throw(new IllegalStateError("time")),
        value: temperature,
      };
    }
  );

  if (tempSeriesIndex === temperatures.length) {
    tempSeriesIndex = 0;
  }
  return {
    burnId: args.burn.id,
    dataPoints: dataPoints,
  };
};
