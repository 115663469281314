import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { CalendarWeekDays } from "./week/CalendarWeekDays";
import { DateTime } from "luxon";
import { useCalendarSettings } from "./CalendarSettingsProvider";

export interface CalendarHeaderProps {
  variant: "events" | "timeslots";
  dateTimeNow: DateTime<true>;
  selectedDate: DateTime<true>;
  dates: Array<DateTime<true>>;
}
export const CalendarHeader: FC<CalendarHeaderProps> = (props) => {
  const { variant, dateTimeNow, selectedDate, dates } = props;
  const settings = useCalendarSettings();
  const theme = useTheme();
  return (
    <Box
      className={"CalendarHeader"}
      sx={{
        minHeight: "30px",
        borderBottom: "1px solid " + theme.palette.divider,
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns:
          variant === "timeslots"
            ? "60px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)"
            : "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
        placeItems: "center",
        justifyItems: "stretch",
        alignItems: "stretch",
        "> .WeekHeader": {
          borderRight: "1px solid " + theme.palette.divider,
        },
        "> .DayHeader": {
          lineHeight: "30px",
        },
        "> .DayHeader:not(:last-child)": {
          borderRight: "1px solid " + theme.palette.divider,
        },
      }}
    >
      {variant === "timeslots" && (
        <Typography
          className={"WeekHeader"}
          align={"center"}
          variant={"caption"}
          sx={{ lineHeight: settings.headerHeight + "px" }}
        >
          {"W "}
          {selectedDate.weekNumber}
        </Typography>
      )}
      <CalendarWeekDays
        dates={variant === "timeslots" ? dates : undefined}
        dateTimeNow={dateTimeNow}
        dayHeaderProps={{
          sx: {
            flex: 1,
            minWidth: 0,
          },
        }}
      />
    </Box>
  );
};
