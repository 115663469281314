import React, { FC } from "react";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import {
  ChildPropertyProps,
  PropertiesCard,
} from "@airmont/shared/ts/ui/properties-card";
import { ChimneySensorPlacementStringEnum } from "@airmont/firefly/shared/ts/domain";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ChimneySensorFormValues } from "./ChimneySensorFormValues";
import { SxProps } from "@mui/system";
import { Duration } from "luxon";
import { FormikProperty, Property, PropertyMode } from "shared-ts-property";

export interface ChimneySensorFormPropertiesProps {
  mode: PropertyMode;
  sensor: ChimneySensorStatusDto;
  childPropertyProps?: ChildPropertyProps;
  sx?: SxProps;
}

export const ChimneySensorFormProperties: FC<
  ChimneySensorFormPropertiesProps
> = (props) => {
  const { t } = useTranslation("firefly-shared-ts-domain");
  const { sensor } = props;
  const formik = useFormikContext<ChimneySensorFormValues>();

  return (
    <PropertiesCard childPropertyProps={props.childPropertyProps} sx={props.sx}>
      <Property
        name={"id"}
        label={t("Serial Number")}
        type={"string"}
        value={sensor.id}
      />
      <Property name={"model"} label={t("Model")} value={sensor.model} />
      <Property
        name={"id"}
        label={t("Registered")}
        type={"dateTime"}
        value={sensor.audit.created}
      />
      <Property
        name={"id"}
        label={t("Registered by")}
        type={"string"}
        value={sensor.audit.createdBy}
      />
      <Property
        name={"uptime"}
        label={t("Uptime")}
        type={"duration"}
        value={Duration.fromDurationLike({
          seconds: sensor.uptime,
        }).shiftToAll()}
        durationFormatterOptions={{
          timeDirection: "none",
          roundValues: true,
          maxNumberOfParts: 2,
        }}
      />
      <Property
        name={"temperature"}
        label={t("Sensor Temperature")}
        type={"number"}
        value={sensor.board.temperature}
        unit={"°C"}
      />
      <FormikProperty
        autoFocus
        formik={formik}
        name={"placement"}
        label={t("Placement")}
        type={"string"}
        value={formik.values.placement}
        valueOptions={{
          multiple: false,
          restrictToOptions: true,
          options: [
            {
              id: ChimneySensorPlacementStringEnum.Roof,
              label: t(
                `ChimneySensorPlacement.${ChimneySensorPlacementStringEnum.Roof}`
              ),
            },
            {
              id: ChimneySensorPlacementStringEnum.Attic,
              label: t(
                `ChimneySensorPlacement.${ChimneySensorPlacementStringEnum.Attic}`
              ),
            },
          ],
        }}
        mode={props.mode}
      />
      <FormikProperty
        formik={formik}
        name={"comment"}
        label={t("Comment")}
        type={"string"}
        multiline
        mode={props.mode}
      />
      <FormikProperty
        formik={formik}
        name={"tags"}
        label={t("Tags")}
        valueOptions={{
          options: [],
          multiple: true,
          restrictToOptions: false,
        }}
        mode={props.mode}
      />
    </PropertiesCard>
  );
};
