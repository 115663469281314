import React, { useState } from "react";
import Dropzone, { DropzoneInputProps } from "react-dropzone";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import { useApi } from "@airmont/firefly/shared/ts/api";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

enum UploadStatus {
  None,
  Success,
  Failure,
}

export const StyledImageUploader = styled(Box)<{ theme: Theme }>`
  display: flex;
  flex-direction: column;

  > form {
    flex: 1;

    display: flex;

    .dropzone {
      cursor: pointer;
      flex: 1;
      border: 2px dashed ${(props) => props.theme.palette.divider};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  > .acceptedFiles {
    flex: 1;
  }
`;

interface ImageUploaderProps {
  refValue: string;
  maxHeight?: string;
  onUpload: () => void;
}

export const ImageUploader = (props: ImageUploaderProps) => {
  const { t } = useTranslation("shared-ts-image-upload");
  const imageService = useApi({
    controllerName: "images",
    environmentAware: true,
  });
  const [acceptedFiles, setAcceptedFiles] = useState<Array<File>>([]);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.None);
  const [errorMessage, setErrorMessage] = useState("");

  const exposeInputProps = (getInputProps: () => DropzoneInputProps) => {
    const result = getInputProps();
    return result;
  };

  const handleDroppedFiles = (acceptedFiles: Array<File>) => {
    setAcceptedFiles((prevState) => {
      //const newState = [...prevState];
      //newState.push(...acceptedFiles);
      return acceptedFiles;
    });
  };

  const handleUploadFiles = () => {
    const data = new FormData();
    data.append("RefValue", props.refValue);
    acceptedFiles.map((file) => data.append("ImageFiles", file));
    imageService
      .post(data, "upload", null)
      .then((result) => {
        if (result) {
          setUploadStatus(UploadStatus.Success);
          setAcceptedFiles([]);
          if (props.onUpload) {
            props.onUpload();
          }
        } else {
          setUploadStatus(UploadStatus.Failure);
        }
      })
      .catch((response) => {
        let msg = "Noe gikk galt!";

        if (response.status === 400)
          msg =
            "Ugyldig data ble sent til sever. Vennligst forsøk med en annen fil.";
        if (response.status === 415)
          msg = "Ugyldig fil type. Vennligst forsøk med en annen type fil.";
        if (response.status === 500)
          msg =
            "Noe gikk galt på server siden. Vennligst forsøk ved en annen anledning.";

        setUploadStatus(UploadStatus.Failure);
        setErrorMessage(msg);
      });
  };

  return (
    <StyledImageUploader sx={{ maxHeight: props.maxHeight }}>
      {acceptedFiles.length === 0 && (
        <form action="/images/upload" method="post" encType="multipart/form">
          <Dropzone onDrop={handleDroppedFiles}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...exposeInputProps(getInputProps)} />
                <Typography>
                  <AddPhotoAlternateRoundedIcon fontSize={"large"} />
                </Typography>
                <Typography>
                  {t("Drag and drop files here, or click to select")}
                </Typography>
              </div>
            )}
          </Dropzone>
        </form>
      )}
      {acceptedFiles.length > 0 && (
        <Box className={"acceptedFiles"}>
          <List>
            {acceptedFiles.map((file: File, index: number) => (
              <ListItem key={index}>
                <ListItemText>{file.name}</ListItemText>
              </ListItem>
            ))}
          </List>
          <Toolbar>
            {acceptedFiles.length > 0 && (
              <Button onClick={handleUploadFiles}>
                {t("Upload Pictures")}
              </Button>
            )}
          </Toolbar>
        </Box>
      )}
      {uploadStatus === UploadStatus.Success && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <i className="fas fa-check-circle"></i>
          <span className="alert-message">{t("Successful Upload!")}</span>
        </div>
      )}
      {uploadStatus === UploadStatus.Failure && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <i className="fas fa-times-circle"></i>
          <span className="alert-message">{errorMessage}</span>
        </div>
      )}
    </StyledImageUploader>
  );
};
