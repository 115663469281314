import { cloneElement, ReactElement } from "react";
import { ChipToggleButtonProps } from "./ChipToggleButton";
import { Stack } from "@mui/material";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export interface ChipToggleButtonGroupProps<
  TValue extends string | number | Array<string | number>
> {
  value: TValue;
  exclusive?: boolean;
  noneZero?: boolean;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  borderRadiusVariant?: "rounded" | "chip";
  children: Array<ReactElement<ChipToggleButtonProps>>;
  onChange: (newValue: TValue) => void;
}

export const ChipToggleButtonGroup = <
  TValue extends string | number | Array<string | number>
>(
  props: ChipToggleButtonGroupProps<TValue>
) => {
  const direction = props.direction ?? "row";
  const exclusive = props.exclusive ?? false;
  const noneZero = props.noneZero ?? false;
  if (exclusive && Array.isArray(props.value)) {
    throw new IllegalArgumentError(
      "value cannot be an array when exclusive is true"
    );
  }

  const handleChildClick = (child: ChipToggleButtonProps) => {
    if (Array.isArray(props.value)) {
      if (props.value.includes(child.value)) {
        props.onChange(
          props.value.filter((it) => it !== child.value) as TValue
        );
      } else {
        props.onChange([...props.value, child.value] as TValue);
      }
    } else {
      props.onChange(child.value as TValue);
    }
  };

  const oneSelected =
    props.children.filter((child) => {
      return Array.isArray(props.value)
        ? props.value.includes(child.props.value)
        : props.value === child.props.value;
    }).length === 1;

  return (
    <Stack direction={direction} useFlexGap gap={1} sx={{ pl: "1px" }}>
      {props.children.map((child) => {
        const selected = Array.isArray(props.value)
          ? props.value.includes(child.props.value)
          : props.value === child.props.value;
        const handleClick = () => {
          handleChildClick(child.props);
        };
        const selectable = !(noneZero && oneSelected && selected);
        return cloneElement(child, {
          key: child.props.value,
          selected: selected,
          borderRadiusVariant: props.borderRadiusVariant,
          onClick: selectable ? handleClick : undefined,
          sx: !selectable ? { cursor: "not-allowed" } : undefined,
        });
      })}
    </Stack>
  );
};
