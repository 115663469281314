import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { VisitSettings } from "./VisitSettings";
import { green, purple, yellow } from "@mui/material/colors";

const VisitSettingsContext = createContext({} as VisitSettings);

export interface VisitSettingsProviderProps {
  children: ReactNode;
}

export const VisitSettingsProvider: FC<VisitSettingsProviderProps> = (
  props
) => {
  const settings: VisitSettings = useMemo(() => {
    return {
      participantById: {
        "1": { id: "1", name: "Feie Feiesen", color: green[500] },
        "2": { id: "2", name: "Slokke Brannsen", color: yellow[500] },
        "3": { id: "2", name: "Sorte Skorsteinsen", color: purple[500] },
      },
    };
  }, []);

  return (
    <VisitSettingsContext.Provider value={settings}>
      {props.children}
    </VisitSettingsContext.Provider>
  );
};

export const useVisitSettings = (): VisitSettings => {
  const context = useContext(VisitSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useVisitSettings must be used within a VisitSettingsProvider"
    );
  }
  return context;
};
