import { FC } from "react";
import { Divider } from "@mui/material";
import { DateTime } from "luxon";
import { useCalendarSettings } from "../CalendarSettingsProvider";

export interface TimeslotLineProps {
  dateTime: DateTime<true>;
  color: string;
  width?: number;
}
export const TimeslotLine: FC<TimeslotLineProps> = (props) => {
  const { dateTime, color, width } = props;
  const calendarSettings = useCalendarSettings();
  let pixelsFromTop = calendarSettings.timeslotsTopGutterHeight;
  // Add hours
  const hoursInMillis = dateTime.hour * 1000 * 60 * 60;
  const timeslotDurationInMillis = calendarSettings.timeslotDuration.toMillis();
  const slotCount = hoursInMillis / timeslotDurationInMillis;
  pixelsFromTop += slotCount * calendarSettings.timeslotHeight;
  // Add minutes
  const minutesInMillis = dateTime.minute * 1000 * 60;
  pixelsFromTop +=
    (minutesInMillis / timeslotDurationInMillis) *
    calendarSettings.timeslotHeight;

  return (
    <Divider
      sx={{
        borderColor: color,
        position: "absolute",
        top: pixelsFromTop + "px",
        height: "2px",
        width: width + "px",
      }}
    />
  );
};
