import { FC } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { FetchError } from "@airmont/shared/ts/utils/fetch";

export interface NoAccessToApplicationProps {
  error: FetchError;
}

export const NoAccessToApplication: FC<NoAccessToApplicationProps> = (
  props
) => {
  return (
    <Alert severity={"error"}>
      <AlertTitle>Du har ikke tilgang til applikasjonen</AlertTitle>
      Sjekk med din administrator om du skulle hatt tilgang
    </Alert>
  );
};
