import { RequestHandler, rest } from "msw";
import { ContactDto } from "./Contact";

export class ContactController {
  private readonly contacts: ContactDto[];
  private readonly listHandler: RequestHandler;
  private readonly updateHandler: RequestHandler;
  private readonly deleteHandler: RequestHandler;
  public readonly handlers: RequestHandler[];
  private readonly onDeleted: (id: string) => void;

  constructor(
    mocks: { contacts: ContactDto[] },
    events: { onDeleted: (id: string) => void }
  ) {
    this.contacts = mocks.contacts;

    this.listHandler = rest.get("/api/Contact/list", async (req, res, ctx) => {
      return res(ctx.json(this.contacts));
    });

    this.updateHandler = rest.put("/api/Contact/:id", async (req, res, ctx) => {
      const { id } = req.params;
      const contact = (await req.json()) as ContactDto;
      const existingIndex = this.contacts.findIndex((it) => it.id === id);
      if (existingIndex == null) {
        throw new Error("Unknown contact" + id);
      }
      mocks.contacts[existingIndex] = contact;

      return res(ctx.json(contact));
    });

    this.deleteHandler = rest.delete(
      "/api/Contact/:id",
      async (req, res, ctx) => {
        const { id } = req.params;
        if (Array.isArray(id)) {
          throw new Error(
            "Id as Array is not supported: " + JSON.stringify(id)
          );
        } else {
          const idAsString = id as string;
          const existingIndex = this.contacts.findIndex(
            (it) => it.id === idAsString
          );
          if (existingIndex == null) {
            throw new Error("Unknown contact" + idAsString);
          }
          this.contacts.slice(existingIndex, 1);
          this.onDeleted(idAsString);
          return res(ctx.status(204));
        }
      }
    );

    this.handlers = [this.listHandler, this.updateHandler, this.deleteHandler];

    this.onDeleted = events.onDeleted;
  }
}
