import React, { FC, useState } from "react";
import { FlueStatusPane } from "./FlueStatusPane";
import { DialogContent, Divider, Portal, Stack, useTheme } from "@mui/material";
import {
  FlueStatusDto,
  FlueUpdateDto,
  useFlueDao,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { Button, useSxMerge } from "shared-ts-mui";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { SizeClass, useWindowSize } from "@airmont/shared/ts/ui/responsive";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Formik } from "formik";
import { resolveFlueAndSensorFormValuesFromDevice } from "./resolveFlueAndSensorFormValuesFromDevice";
import { FlueFormValues } from "./FlueFormValues";
import {
  AuditPaneWithPopper,
  FlueBreadcrumbs,
  FlueTypeStringsEnum,
} from "@airmont/firefly/shared/ts/domain";
import { FormikErrors, FormikHelpers } from "formik/dist/types";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { useTranslation } from "react-i18next";
import { useUser } from "shared-ts-utils-authentication";
import { merge } from "lodash";
import { DeleteButton, DisplayMode } from "shared-ts-app-dialog";
import { SxProps } from "@mui/system";

export interface FlueDialogContentProps {
  flueStatus: FlueStatusDto;
  displayFlueBreadCrumbs?: boolean;
  layout: SizeClass;
  mode: DisplayMode;
  actionsContainer: Element;
  onDisplayModeChange: (mode: DisplayMode) => void;
  onUpdated: (updated: FlueStatusDto) => void;
  onDeleted: (deleted: FlueStatusDto) => void;
  sx?: SxProps;
}

export const FlueDialogContent: FC<FlueDialogContentProps> = (props) => {
  const { flueStatus, actionsContainer, mode } = props;
  const { t: tFireflyChimneyInsightsPages } = useTranslation(
    "firefly-chimney-insights-ts-pages"
  );
  const theme = useTheme();
  const { windowWidth } = useWindowSize();
  const user = useUser();
  const userCanEdit = user.hasClaim("devices.create");
  const userCanDelete = user.hasRole("PowerUser") || user.hasRole("Admin");
  const [askDeleteConfirmation, setAskDeleteConfirmation] = useState(false);

  const flueDao = useFlueDao();

  const formInitialValues = resolveFlueAndSensorFormValuesFromDevice(
    props.flueStatus
  );

  const formValidate = (values: FlueFormValues) => {
    const errors: FormikErrors<FlueFormValues> = {};
    if (values.flueType === FlueTypeStringsEnum.Elemental) {
      errors.flueType = "Ugyldig røykløpstype";
    }
    return errors;
  };

  const handleFormSubmit = async (
    values: FlueFormValues,
    formikHelpers: FormikHelpers<FlueFormValues>
  ) => {
    const tags = values.tags?.reduce(
      (a, b) => a + (a.length > 0 ? " " : "") + b,
      ""
    );
    const update: FlueUpdateDto = {
      id: flueStatus.id,
    };

    if (formInitialValues.comment !== values.comment) {
      update.comment = { value: values.comment };
    }

    if (formInitialValues.tags !== values.tags) {
      update.tags = { value: tags };
    }

    if (formInitialValues.lat !== values.lat) {
      update.lat = { value: values.lat };
    }
    if (formInitialValues.lng !== values.lng) {
      update.lng = { value: values.lng };
    }
    if (formInitialValues.municipality !== values.municipality) {
      update.municipality = { value: values.municipality };
    }
    if (formInitialValues.municipalityName !== values.municipalityName) {
      update.municipalityName = { value: values.municipalityName };
    }
    if (formInitialValues.postalCode !== values.postalCode) {
      update.postalCode = { value: values.postalCode };
    }
    if (formInitialValues.postalPlace !== values.postalPlace) {
      update.postalPlace = { value: values.postalPlace };
    }
    if (formInitialValues.street !== values.street) {
      update.street = { value: values.street };
    }
    if (formInitialValues.houseNumber !== values.houseNumber) {
      update.houseNumber = { value: values.houseNumber ?? null };
    }
    if (formInitialValues.houseLetter !== values.houseLetter) {
      update.houseLetter = { value: values.houseLetter ?? null };
    }
    if (formInitialValues.houseSection !== values.houseSection) {
      update.houseSection = { value: values.houseSection ?? null };
    }
    if (formInitialValues.cadastralUnit !== values.cadastralUnit) {
      update.cadastralUnit = { value: values.cadastralUnit ?? null };
    }
    if (formInitialValues.propertyUnit !== values.propertyUnit) {
      update.propertyUnit = { value: values.propertyUnit ?? null };
    }
    if (formInitialValues.leaseNumber !== values.leaseNumber) {
      update.leaseNumber = { value: values.leaseNumber ?? null };
    }

    if (formInitialValues.chimneyHat !== values.chimneyHat) {
      update.chimneyHat = { value: values.chimneyHat ?? null };
    }

    if (formInitialValues.flueType !== values.flueType) {
      update.flueType = { value: values.flueType ?? null };
    }
    if (formInitialValues.flueName !== values.flueName) {
      update.flueName = { value: values.flueName ?? null };
    }
    if (formInitialValues.flueHeight !== values.flueHeight) {
      update.flueHeight = { value: values.flueHeight ?? null };
    }

    if (formInitialValues.fireplaceCatalytic !== values.fireplaceCatalytic) {
      update.fireplaceCatalytic = { value: values.fireplaceCatalytic };
    }

    if (formInitialValues.sensorPlacement !== values.sensorPlacement) {
      update.sensorPlacement = { value: values.sensorPlacement };
    }

    const updatedFlue = await flueDao.update(update);
    const updatedFlueStatus: FlueStatusDto = merge({}, flueStatus, updatedFlue);
    props.onUpdated(updatedFlueStatus);
    props.onDisplayModeChange("view");
  };

  const handleDeleteConfirmed = async () => {
    await flueDao.delete(flueStatus.id);
    props.onDeleted(props.flueStatus);
  };

  const sx = useSxMerge(props.sx, {
    backgroundColor: theme.palette.background.default,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: "column",
  });

  return (
    <DialogContent dividers={true} sx={sx}>
      <Formik
        initialValues={formInitialValues}
        validate={formValidate}
        enableReinitialize
        onSubmit={handleFormSubmit}
      >
        {({ dirty, submitForm, resetForm, isSubmitting }) => {
          const handleSaveClick = () => {
            submitForm();
          };

          const handleEditCancelClick = () => {
            resetForm();
            props.onDisplayModeChange("view");
          };

          return (
            <Stack>
              <Portal container={actionsContainer}>
                {userCanDelete && mode !== "edit" && (
                  <DeleteButton
                    askConfirmation={askDeleteConfirmation}
                    onAskingConfirmation={setAskDeleteConfirmation}
                    onDeleteConfirmed={handleDeleteConfirmed}
                    layout={windowWidth}
                  />
                )}
                {mode === "view" && !askDeleteConfirmation && (
                  <Button
                    startIcon={<EditRoundedIcon />}
                    variant={"text"}
                    title={tFireflyChimneyInsightsPages("Edit")}
                    disabled={askDeleteConfirmation}
                    sx={{
                      minWidth: "90px",
                    }}
                    onClick={() => props.onDisplayModeChange("edit")}
                  >
                    {windowWidth !== SizeClass.Compact &&
                      tFireflyChimneyInsightsPages("Edit")}
                  </Button>
                )}
                {mode === "edit" && userCanEdit && !askDeleteConfirmation && (
                  <Button
                    startIcon={<CancelRoundedIcon />}
                    variant={"text"}
                    title={tFireflyChimneyInsightsPages("Cancel")}
                    disabled={isSubmitting}
                    sx={{
                      minWidth: "90px",
                    }}
                    onClick={handleEditCancelClick}
                  >
                    {windowWidth !== SizeClass.Compact &&
                      tFireflyChimneyInsightsPages("Cancel")}
                  </Button>
                )}
                {mode === "edit" && !askDeleteConfirmation && (
                  <Button
                    startIcon={<SaveRoundedIcon />}
                    variant={"outlined"}
                    disabled={!dirty || isSubmitting}
                    title={tFireflyChimneyInsightsPages("Save")}
                    sx={{
                      minWidth: "90px",
                    }}
                    onClick={handleSaveClick}
                  >
                    {windowWidth !== SizeClass.Compact &&
                      tFireflyChimneyInsightsPages("Save")}
                  </Button>
                )}
                <AuditPaneWithPopper
                  audit={flueStatus.audit}
                  disablePortal={true}
                  anchorButtonSx={{ marginLeft: "auto" }}
                />
              </Portal>
              {props.displayFlueBreadCrumbs && (
                <>
                  <FlueBreadcrumbs
                    municipality={flueStatus.municipality}
                    postalAddress={flueStatus.postalAddress}
                    streetAddress={flueStatus.streetAddress}
                    flue={flueStatus.flue}
                    layout={SizeClass.ExtraLarge}
                    hideMunicipality
                    sx={{ ml: 1, mt: 1, mr: 1 }}
                  />
                  <Divider sx={{ mt: 1 }} />
                </>
              )}
              <FlueStatusPane
                flueStatus={flueStatus}
                mode={props.mode}
                showNavEdit={false}
                showReturn={false}
                showTitle={false}
                sx={{ flexGrow: 1, minHeight: 0 }}
              />
            </Stack>
          );
        }}
      </Formik>
    </DialogContent>
  );
};
