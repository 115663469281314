import React, { FC, ReactNode } from "react";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { ValueOptions } from "./ValueOptions";
import { isValueOption, ValueOption } from "./ValueOption";
import { PropertyValueType } from "./PropertyValueType";
import { _throw, IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { useMergeSx } from "./useMergeSx";
import { useOptionLabelResolver } from "./OptionLabelResolver";
import { PropertyEditBaseProps } from "./PropertyEditBaseProps";
import { RemoveButton } from "./RemoveButton";
import { usePropertyEditBase } from "./usePropertyEditBase";
import { PropertyValue } from "./PropertyValue";
import { AutocompleteValue } from "@mui/material/useAutocomplete/useAutocomplete";

export interface PropertyEditAutocompleteMultipleProps
  extends PropertyEditBaseProps {
  valueOptions: ValueOptions;
  unit?: string;
  type?: PropertyValueType;
  autoFocus?: boolean;
  helperText?: ReactNode;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const PropertyEditAutocompleteMultiple: FC<
  PropertyEditAutocompleteMultipleProps
> = (props) => {
  const { removable, handleRemove } = usePropertyEditBase(props);
  const value = useValue(props);

  const noValue = value == null || (Array.isArray(value) && value.length === 0);
  const sx = useMergeSx(
    {
      width: props.fullWidth ? "100%" : "25ch",
    },
    props.sx,
    [props.sx, props.fullWidth]
  );

  const freeSolo = !props.valueOptions.restrictToOptions;

  if (!freeSolo) {
    const optionForValue = props.valueOptions.options.find((it) => {
      if (isValueOption(it)) {
        return it.id === props.value;
      } else {
        return it === props.value;
      }
    });

    if (optionForValue == null) {
      throw new Error(
        `Given value [${
          props.value
        }] does not exists in given options: ${JSON.stringify(
          props.valueOptions.options
        )}`
      );
    }
  }

  const optionLabelResolver = useOptionLabelResolver(props.valueOptions);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      options={props.valueOptions.options}
      multiple={props.valueOptions.multiple}
      filterSelectedOptions={props.valueOptions.filterSelectedOptions}
      getOptionLabel={optionLabelResolver.getOptionLabel}
      size={props.size}
      value={props.value as unknown as Array<string>}
      fullWidth={props.fullWidth}
      sx={sx}
      onChange={(event, value) => {
        const value_ = value as Array<string | ValueOption>;
        if (value_.length > 0) {
          const valuesAsStrings: Array<string | number> = value_.map(
            (option) => {
              if (isValueOption(option)) {
                return option.id;
              } else {
                return option;
              }
            }
          );

          props.onChange(
            valuesAsStrings,
            props.name ?? _throw(new Error("name cannot be null"))
          );
        } else {
          props.onChange(
            [],
            props.name ?? _throw(new Error("name cannot be null"))
          );
        }
      }}
      onBlur={props.onBlur}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label}
            helperText={props.helperText}
            error={props.error}
            autoFocus={props.autoFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment:
                params.InputProps.endAdornment ||
                props.unit ||
                props.info ||
                removable ? (
                  <InputAdornment position="end">
                    {props.unit ? props.unit : undefined}
                    {props.info && (
                      <Tooltip title={props.info}>
                        <IconButton size={"small"}>
                          <HelpOutlineRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {params.InputProps.endAdornment}
                    {removable && noValue && (
                      <RemoveButton onRemove={handleRemove} />
                    )}
                  </InputAdornment>
                ) : undefined,
            }}
            inputRef={props.inputRef}
          />
        );
      }}
    />
  );
};

const useValue = <Value, Multiple, DisableClearable, FreeSolo>(props: {
  name: string;
  value: PropertyValue;
  type?: PropertyValueType;
  valueOptions: ValueOptions;
}):
  | AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>
  | undefined => {
  const { value, type, valueOptions, name } = props;

  if (value == null) {
    return undefined;
  }

  if (valueOptions.multiple && !Array.isArray(value)) {
    throw new IllegalArgumentError(
      `Value of Property (${name}) must be an array`
    );
  }

  return value as AutocompleteValue<
    Value,
    Multiple,
    DisableClearable,
    FreeSolo
  >;
};
