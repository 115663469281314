import React, { FC, ReactNode } from "react";
import { TimeframeObject } from "./TimeframeObject";

import { TimeframeUnit } from "./TimeframeUnit";

export interface TimeframeObjectContextType {
  timeframe: TimeframeObject;
  timeframeUnit: TimeframeUnit;
}

const TimeframeObjectContext = React.createContext(
  {} as TimeframeObjectContextType
);

export interface TimeframeProviderProps {
  timeframe: TimeframeObject;
  timeframeUnit: TimeframeUnit;
  children?: ReactNode;
}

export const TimeframeObjectProvider: FC<TimeframeProviderProps> = (props) => {
  return (
    <TimeframeObjectContext.Provider
      value={{
        timeframe: props.timeframe,
        timeframeUnit: props.timeframeUnit,
      }}
    >
      {props.children}
    </TimeframeObjectContext.Provider>
  );
};

export const useTimeframeUnit = (): TimeframeUnit => {
  const context = React.useContext(TimeframeObjectContext);
  if (context === undefined) {
    throw new Error(
      "useTimeframeObject must be used within a TimeframeObjectProvider"
    );
  }

  return context.timeframeUnit;
};

export const useTimeframeObject = (): TimeframeObject => {
  const context = React.useContext(TimeframeObjectContext);
  if (context === undefined) {
    throw new Error(
      "useTimeframeObject must be used within a TimeframeObjectProvider"
    );
  }

  return context.timeframe;
};
