import React, { ReactNode, useEffect, useState } from "react";
import { BootResult } from "./BootResult";
import { BootFunction } from "./BootFunction";
import { UnauthenticatedError } from "./UnauthenticatedError";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface AppBootProps {
  children?: (bootResult: BootResult) => ReactNode;
  boot: BootFunction;
  bootPage: (args: {
    messages: Array<string>;
    error?: Error;
  }) => React.ReactNode;
  app: (bootResult: BootResult) => ReactNode;
  appWhenUnauthenticated?: () => ReactNode;
}

export const AppBoot = (props: AppBootProps) => {
  const [booting, setBooting] = useState<boolean>(true);
  const [bootMessages, setBootMessages] = useState<Array<string>>([]);
  const [bootResult, setBootResult] = useState<BootResult | null>(null);
  const [bootError, setBootError] = useState<Error | undefined>(undefined);
  const [unauthenticated, setUnauthenticated] = useState<boolean>(false);

  const handleBootMessage = (message: string) => {
    setBootMessages((prevState) => [...prevState, message]);
  };

  const handleBootError = (error: Error) => {
    setBootError(error);
  };

  useEffect(() => {
    const runBoot = async () => {
      try {
        setBooting(true);
        const bootResult = await props.boot({
          onMessage: handleBootMessage,
          onError: handleBootError,
        });
        setBootResult(bootResult);
        setBooting(false);
      } catch (e) {
        if (e instanceof UnauthenticatedError) {
          setUnauthenticated(true);
          setBooting(false);
        } else {
          console.error("Error during booting: ", e);
          setBootError(e as Error);
        }
      }
    };
    runBoot();

    return () => {
      setBooting(false);
      setBootError(undefined);
      setBootMessages([]);
    };
  }, []);

  if (!booting && !unauthenticated && bootResult == null) {
    throw new IllegalStateError("Missing boot result");
  }

  return (
    <>
      {booting && props.bootPage({ messages: bootMessages, error: bootError })}
      {!booting &&
        !unauthenticated &&
        bootResult != null &&
        props.app?.(bootResult)}
      {!booting && unauthenticated && props.appWhenUnauthenticated?.()}
    </>
  );
};
