import { FC, ReactNode } from "react";
import { Box, Toolbar, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledSettingsPane = styled(Box)``;

export interface SettingsPaneProps {
  name: string;
  children: ReactNode;
}

export const SettingsPane: FC<SettingsPaneProps> = (props) => {
  return (
    <StyledSettingsPane
      className={"SettingsPane"}
      sx={{
        width: "100%",
        maxWidth: "900px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar disableGutters>
        <Typography sx={{ fontWeight: "bold" }}>{props.name}</Typography>
      </Toolbar>
      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflowY: "auto",
          }}
        >
          {props.children}
        </Box>
      </Box>
    </StyledSettingsPane>
  );
};
