import React, { useMemo } from "react";
import { LicenseInfo } from "@mui/x-license";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { ErrorPage } from "../shared/error/router/ErrorPage";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { SettingsPage } from "@airmont/firefly/chimney-insights/ts/settings";
import { ChimneyInsightsBootResult } from "./boot/ChimneyInsightsBootResult";
import { OidcClaims } from "@airmont/firefly/chimney-insights/ts/authentication";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";
import {
  AbnormalBurnsPage,
  AboutPage,
  FlueStatusPage,
  NeedForSweepPage,
  OverviewPage,
  RootPage,
  SensorStatusPage,
  SweepPlanningPage,
  useAbnormalBurnsPageEnabled,
  UserPage,
  useSweepPlanningPageEnabled,
} from "@airmont/firefly/chimney-insights/ts/pages";
import * as Sentry from "@sentry/react";
import { useAuthenticationRoutes } from "./authorization/useAuthenticationRoutes";
import {
  GenerateErrorPage,
  useGenerateErrorPageEnabled,
} from "shared-ts-sentry";

LicenseInfo.setLicenseKey(
  "4f4590c6c6385ce447d7e8e8607a92dbTz0xMDE4MTEsRT0xNzYzMjExNDAxMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
);

interface AppProps {
  bootResult: ChimneyInsightsBootResult;
}

export const App = (props: AppProps) => {
  console.log(
    "******************************************************************************"
  );
  console.log(
    "App **************************************************************************"
  );
  console.log("App bootResult: ", props.bootResult);

  const canReadDevices = props.bootResult.user.hasClaim(OidcClaims.ReadDevices);
  const abnormalBurnsEnabled = useAbnormalBurnsPageEnabled();
  const sweepPlanningPageEnabled = useSweepPlanningPageEnabled();
  const generateErrorPageEnabled = useGenerateErrorPageEnabled();

  const returnUrl = window.location.href;
  const authenticationRoute: RouteObject = useAuthenticationRoutes(
    returnUrl,
    true
  );

  const rootRoute: RouteObject = useMemo(() => {
    const defaultSubChildren: Array<RouteObject> = [];

    defaultSubChildren.push({
      index: true,
      element: <OverviewPage />,
    });

    defaultSubChildren.push(authenticationRoute);

    const _rooRoute: RouteObject = {
      path: "/",
      element: (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <RootPage />
        </QueryParamProvider>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          children: defaultSubChildren,
        },
      ],
    };

    _rooRoute.children?.push({
      path: "about",
      element: <AboutPage />,
    });

    if (generateErrorPageEnabled) {
      _rooRoute.children?.push({
        path: "generate-error",
        element: <GenerateErrorPage />,
      });
    }

    if (canReadDevices) {
      _rooRoute.children?.push({
        path: "chimney-status",
        element: <FlueStatusPage />,
        children: [
          {
            path: "view/:idParam",
            element: <FlueStatusPage />,
          },
        ],
      });
      _rooRoute.children?.push({
        path: "need-for-sweep",
        element: <NeedForSweepPage />,
      });
      if (sweepPlanningPageEnabled) {
        _rooRoute.children?.push({
          path: "sweep-planning",
          element: <SweepPlanningPage />,
        });
      }
      if (abnormalBurnsEnabled) {
        _rooRoute.children?.push({
          path: "abnormal-burns",
          element: <AbnormalBurnsPage />,
        });
      }
      _rooRoute.children?.push({
        path: "sensor-status",
        element: <SensorStatusPage />,
        children: [
          {
            path: "view/:idParam",
            element: <SensorStatusPage />,
          },
        ],
      });
    }

    _rooRoute.children?.push({
      path: "user",
      element: <UserPage />,
    });

    _rooRoute.children?.push({
      path: "organization",
      element: <SettingsPage />,
    });

    return _rooRoute;
  }, [
    abnormalBurnsEnabled,
    authenticationRoute,
    canReadDevices,
    generateErrorPageEnabled,
    sweepPlanningPageEnabled,
  ]);

  const routes: Array<RouteObject> = useMemo(() => [rootRoute], [rootRoute]);
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(routes);

  return <RouterProvider router={router} />;
};
