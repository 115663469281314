import React, { FC, ReactNode } from "react";
import { MapLoadingProgress } from "./MapLoadingProgress";
import { useApiIsLoaded } from "@vis.gl/react-google-maps";

export interface WaitForGoogleApisLoaded {
  loadingComponent?: ReactNode;
  children: ReactNode;
}

export const WaitForGoogleApisLoaded: FC<WaitForGoogleApisLoaded> = (props) => {
  const googleApiIsLoaded = useApiIsLoaded();
  if (googleApiIsLoaded) {
    return props.children;
  } else {
    return props.loadingComponent ? (
      props.loadingComponent
    ) : (
      <MapLoadingProgress />
    );
  }
};
