import { WeatherValues } from "./WeatherValues";
import { FC } from "react";
import { Stack, SxProps, Tooltip, Typography } from "@mui/material";
import AirRoundedIcon from "@mui/icons-material/AirRounded";
import CompressRoundedIcon from "@mui/icons-material/CompressRounded";
import ThermostatRoundedIcon from "@mui/icons-material/ThermostatRounded";
import { WindDirectionArrowIcon } from "./WindDirectionArrowIcon";
import { resolveWindDirectionFromDegrees } from "./WindDirection";
import { MathUtils, notUndef } from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";
import { InfoPill } from "@airmont/shared/ts/ui/info-pill";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { Button_color } from "shared-ts-mui";

export interface WeatherInfoProps {
  weather?: WeatherValues;
  color?: Button_color;
  variant?: "normal" | "outlined" | "filled";
  layout: SizeClass;
  sx?: SxProps;
}
export const WeatherInfo: FC<WeatherInfoProps> = (props) => {
  const { weather } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");
  const windDirection = notUndef(weather, (weather) =>
    notUndef(weather.windDirectionDeg, (windDirectionDeg) =>
      resolveWindDirectionFromDegrees(windDirectionDeg)
    )
  );

  return (
    <InfoPill
      label={t("Weather")}
      color={props.color}
      variant={props.variant}
      layout={props.layout}
      info={
        <Stack
          direction={"row"}
          useFlexGap
          gap={1}
          sx={{ alignItems: "center", flexWrap: "nowrap" }}
        >
          <Tooltip title={t("Temperature")}>
            <ThermostatRoundedIcon
              fontSize={"small"}
              sx={{ color: "text.secondary" }}
            />
          </Tooltip>
          <Typography noWrap>{`${
            notUndef(weather?.outsideTempC, (it) => MathUtils.round(it)) ?? ""
          } °C`}</Typography>
          <Tooltip title={t("Air Pressure")}>
            <CompressRoundedIcon
              fontSize={"small"}
              sx={{ color: "text.secondary" }}
            />
          </Tooltip>
          <Typography noWrap>{`${
            weather?.atmosphericPressureHpa ?? ""
          } hPa`}</Typography>
          {windDirection != null && (
            <>
              <Tooltip
                title={`${t("The wind comes from")} ${t(
                  `WindDirection.${windDirection}`
                )}`}
              >
                <AirRoundedIcon
                  fontSize={"small"}
                  sx={{ color: "text.secondary" }}
                />
              </Tooltip>
              <WindDirectionArrowIcon
                value={windDirection}
                fontSize={"small"}
              />
              <Typography noWrap title={t(`WindDirection.${windDirection}`)}>
                {t(`WindDirection.short.${windDirection}`)}
              </Typography>
            </>
          )}
          <Typography noWrap>{`${
            notUndef(weather?.windSpeedMs, (it) => MathUtils.round(it)) ?? ""
          } m/s`}</Typography>
        </Stack>
      }
    />
  );
};
