import { Property, PropertyProps } from "shared-ts-property";
import { Trans, useTranslation } from "react-i18next";
import { FC } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { useAppInfo } from "shared-ts-app-info";

export interface ConsentToCollectUsageDataPropertyProps
  extends Omit<
    PropertyProps<boolean>,
    "name" | "value" | "onChange" | "label" | "type"
  > {
  name: string;
  value: boolean | null | undefined;
}

export const ConsentToCollectUsageDataProperty: FC<
  ConsentToCollectUsageDataPropertyProps
> = (props) => {
  const { t } = useTranslation("shared-ts-posthog");
  const appInfo = useAppInfo();
  return (
    <Property
      {...props}
      label={t("I consent to collection of usage data")}
      type={"boolean"}
      info={
        <Stack useFlexGap gap={2}>
          <Typography>
            <Trans
              i18nKey={"airmont uses PostHog to capture..."}
              ns={"shared-ts-posthog"}
              values={{ ApplicationName: appInfo.name }}
              components={{
                Link: (
                  <Link href={"https://www.posthog.com"} target={"_blank"} />
                ),
              }}
            />
          </Typography>
          <Typography>{t("We understand no consent ...")}</Typography>
        </Stack>
      }
      mode={props.mode ?? "edit"}
    />
  );
};
