import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import { SensorStatusTable } from "./SensorStatusTable";
import { StringParam, useQueryParam } from "use-query-params";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import SensorStatusMap from "./map/SensorStatusMap";
import { SearchInput } from "@airmont/firefly/shared/ts/ui";
import {
  ChimneySensorStatusDto,
  ChimneySensorStatusQueryDto,
  useMunicipalityContext,
  useSensorQueryClient,
  useSensorStatusQuery,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { FitContainerPane, PanelLayout } from "@airmont/shared/ts/ui/layout";
import {
  BatteryLevelEnum,
  ChimneySensorCableStatusEnum,
  ChimneySensorCableStatusEnumStrings,
  ChimneySensorSerialNr,
  ChimneySensorStatusEnum,
  ChimneySensorStatusStrings,
} from "@airmont/firefly/shared/ts/domain";
import { ChimneySensorStatusFilterControl } from "./ChimneySensorStatusFilterControl";
import { BatteryLevelFilterControl } from "./BatteryLevelFilterControl";
import { ChimneySensorDialog } from "../chimneySensor/ChimneySensorDialog";
import { useNotify } from "@airmont/shared/ts/ui/notification";
import { ChimneySensorCableStatusFilterControl } from "./ChimneySensorCableStatusFilterControl";
import { useQueryRows } from "shared/ts/ui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import {
  GoogleMapApiProvider,
  WaitForGoogleApisLoaded,
} from "shared-ts-ui-react-google-maps";

export const SensorStatusPage = () => {
  const theme = useTheme();
  const componentSize = useComponentSizeFromDense();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const navigate = useNavigate();
  const notify = useNotify();
  const { search: searchParams } = useLocation();
  const { idParam } = useParams();
  const { municipality } = useMunicipalityContext();
  const [zipCode, setZipCodeParam] = useQueryParam("zipCode", StringParam);
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [status, setStatus] = useQueryParam("status", StringParam);
  const [cableStatus, setCableStatus] = useQueryParam(
    "cableStatus",
    StringParam
  );
  const [batteryLevel, setBatteryLevel] = useQueryParam(
    "batteryLevel",
    StringParam
  );

  const [searchSubmit, setSearchSubmit] = useState(0);
  const [displaySensor, setDisplaySensor] = useState<
    ChimneySensorStatusDto | undefined
  >(undefined);

  const [settingsAccordion, setSettingsAccordion] = useState<string | false>(
    "filter"
  );

  const queryInput = useSensorStatusQuery({
    municipality: municipality,
    zipCode: zipCode,
    search: search,
    status:
      status != null
        ? ChimneySensorStatusEnum[status as ChimneySensorStatusStrings]
        : undefined,
    cableStatus:
      cableStatus != null
        ? ChimneySensorCableStatusEnum[
            cableStatus as ChimneySensorCableStatusEnumStrings
          ]
        : undefined,
    batteryLevel:
      batteryLevel != null
        ? BatteryLevelEnum[batteryLevel as keyof typeof BatteryLevelEnum]
        : undefined,
  });
  const {
    rows,
    selectedRowId,
    replaceRow,
    deleteRow,
    setSelectedRowId,
    query,
    handleRowsChange,
  } = useQueryRows<
    ChimneySensorSerialNr,
    ChimneySensorStatusDto,
    ChimneySensorStatusQueryDto
  >(queryInput);
  const indexOfDisplayed = useMemo(() => {
    return rows.findIndex((it) => it.id === idParam);
  }, [idParam, rows]);
  const sensorQueryClient = useSensorQueryClient();

  useEffect(() => {
    const doEffect = async () => {
      if (idParam === undefined) {
        setDisplaySensor(undefined);
      } else {
        const sensorToDisplay = rows.find((it) => it.id === idParam);
        if (sensorToDisplay !== undefined) {
          setDisplaySensor(sensorToDisplay);
        } else {
          try {
            const sensorToDisplay = await sensorQueryClient.getById(idParam);
            setDisplaySensor(sensorToDisplay);
          } catch (e) {
            notify.warning({ error: e });
          }
        }
      }
    };
    doEffect();
  }, [idParam, rows, sensorQueryClient, notify]);

  const handleGoPrevious = () => {
    if (indexOfDisplayed > 0) {
      const previousIndexToDisplay = indexOfDisplayed - 1;
      const previousToDisplay = rows[previousIndexToDisplay];
      navigate(
        `view/${previousToDisplay.id}?${new URLSearchParams(
          searchParams
        ).toString()}`
      );
    }
  };

  const handleGoNext = () => {
    if (indexOfDisplayed < rows.length - 1) {
      const nextIndexToDisplay = indexOfDisplayed + 1;
      const nextToDisplay = rows[nextIndexToDisplay];
      navigate(
        `view/${nextToDisplay.id}?${new URLSearchParams(
          searchParams
        ).toString()}`
      );
    }
  };

  const handleSearchSubmit = () => {
    setSearchSubmit((prev) => prev + 1);
  };

  const handleZipCodeKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.target instanceof HTMLInputElement) {
      setZipCodeParam(
        event.target.value.isNotEmpty() ? event.target.value : null
      );
      handleSearchSubmit();
    }
  };

  const handleSearchChange = (value: string) => {
    setSearch(value.isNotBlank() ? value : null);
    handleSearchSubmit();
  };

  const handleFilterStatusChange = (value: ChimneySensorStatusEnum | null) => {
    setStatus(value);
  };

  const handleFilterCableStatusChange = (
    value: ChimneySensorCableStatusEnum | null
  ) => {
    setCableStatus(value);
  };

  const handleFilterBatteryLevelChange = (value: BatteryLevelEnum | null) => {
    setBatteryLevel(value);
  };

  const handleSettingsAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSettingsAccordion(isExpanded ? panel : false);
    };

  const handleSelectedRow = (rowId: ChimneySensorSerialNr | undefined) => {
    setSelectedRowId(rowId);
  };

  const handleViewRequested = (sensor: ChimneySensorStatusDto) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.set("mainTab", "sensor");
    navigate(`view/${sensor.id}?${urlSearchParams.toString()}`);
  };

  const handleEditRequested = (sensor: ChimneySensorStatusDto) => {
    navigate(`edit/${sensor.id}${searchParams}`);
  };

  const handleDisplaySensorDialogClose = () => {
    navigate(`.${searchParams}`);
  };

  const handleSensorUpdated = (update: ChimneySensorStatusDto) => {
    replaceRow(update);
  };

  const handleSensorDeleted = (deleted: ChimneySensorStatusDto) => {
    deleteRow(deleted);
    navigate(`.${searchParams}`);
  };

  return (
    <>
      <AppPage
        name={"SensorStatusPage"}
        hideName
        mainPaneProps={{
          paperElevation: 0,
          disableGutters: true,
        }}
        mainPane={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minHeight: 0,
              minWidth: 0,
              gap: theme.spacing(1),
            }}
          >
            <PanelLayout
              resizable={true}
              autoSaveId={"firefly:SensorStatusPage"}
              panelProps={[
                {
                  title: "Kart",
                  resizableOptions: {
                    defaultSize: 40,
                  },
                  style: {
                    display: "flex",
                  },
                },
                {
                  title: "Tabell",
                  resizableOptions: {
                    defaultSize: 60,
                  },
                  style: {
                    display: "flex",
                  },
                },
              ]}
              panels={[
                <Paper
                  elevation={3}
                  sx={{
                    flex: 1,
                    minHeight: 0,
                    minWidth: 0,
                    position: "relative",
                  }}
                >
                  <FitContainerPane>
                    {({ width, height }) => (
                      <GoogleMapApiProvider>
                        <WaitForGoogleApisLoaded>
                          <SensorStatusMap
                            pageUrl={"/sensor-status"}
                            municipality={municipality}
                            data={rows}
                            selected={selectedRowId}
                            width={width}
                            height={height}
                          />
                        </WaitForGoogleApisLoaded>
                      </GoogleMapApiProvider>
                    )}
                  </FitContainerPane>
                </Paper>,
                <Paper
                  elevation={3}
                  sx={{
                    flex: 1,
                    minHeight: 0,
                    minWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    padding: 1,
                  }}
                >
                  <SearchInput
                    fullWidth={true}
                    inputPlaceholder={t(
                      "Search by name, street, ID, or hashtag"
                    )}
                    defaultValue={search}
                    onValueChange={handleSearchChange}
                  />
                  {rows != null && (
                    <Box
                      sx={{
                        flexGrow: 1,
                        minHeight: 0,
                        minWidth: 0,
                      }}
                    >
                      <SensorStatusTable
                        query={query}
                        forceRefresh={searchSubmit}
                        rows={rows}
                        onRowsChange={handleRowsChange}
                        onSelectedRow={handleSelectedRow}
                        onViewRequested={handleViewRequested}
                        onEditRequested={handleEditRequested}
                      />
                    </Box>
                  )}
                </Paper>,
              ]}
            />
          </Box>
        }
        disableResize={false}
        supportingPaneProps={{
          label: tFireflyDomain("Settings"),
          resizePanelProps: {
            minWidth: 240,
            minHeight: 240,
            horizontal: {
              style: {
                minWidth: 0,
                maxWidth: 350,
              },
            },
            vertical: {
              style: {
                minHeight: 0,
                maxHeight: 350,
              },
            },
          },
        }}
        supportingPane={
          <Box
            sx={{
              flex: 1,
              padding: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Accordion
              variant={"elevation"}
              elevation={4}
              expanded={settingsAccordion === "filter"}
              onChange={handleSettingsAccordionChange("filter")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {tFireflyDomain("Filter")}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 2,
                }}
              >
                <ChimneySensorStatusFilterControl
                  selected={
                    ChimneySensorStatusEnum[
                      status as keyof typeof ChimneySensorStatusEnum
                    ]
                  }
                  onChange={handleFilterStatusChange}
                  size={componentSize}
                />

                <ChimneySensorCableStatusFilterControl
                  selected={
                    ChimneySensorCableStatusEnum[
                      cableStatus as keyof typeof ChimneySensorCableStatusEnum
                    ]
                  }
                  onChange={handleFilterCableStatusChange}
                  size={componentSize}
                />
                <BatteryLevelFilterControl
                  selected={
                    BatteryLevelEnum[
                      batteryLevel as keyof typeof BatteryLevelEnum
                    ]
                  }
                  onChange={handleFilterBatteryLevelChange}
                  size={componentSize}
                />
                <TextField
                  label={tFireflyDomain("Postal Code.short")}
                  helperText={tFireflyDomain("Filter on Postal Code")}
                  defaultValue={zipCode}
                  onKeyUp={handleZipCodeKeyUp}
                  size={componentSize}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        }
      />
      <ChimneySensorDialog
        sensor={displaySensor}
        open={displaySensor != null}
        onGoPrevious={indexOfDisplayed > 0 ? handleGoPrevious : undefined}
        onGoNext={indexOfDisplayed < rows.length - 1 ? handleGoNext : undefined}
        onClose={handleDisplaySensorDialogClose}
        onUpdated={handleSensorUpdated}
        onDeleted={handleSensorDeleted}
      />
    </>
  );
};
