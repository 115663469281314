import { ChimneySensorStatusQueryDto } from "./ChimneySensorStatusQuery";
import { ChimneySensorStatusDao } from "./ChimneySensorStatusDao";
import { ChimneySensorSerialNr } from "@airmont/firefly/shared/ts/domain";
import { ChimneySensorStatusDto } from "./ChimneySensorStatus";
import { PageDto, QueryClient, QueryResult } from "shared/ts/utils/query";
import { QueryClient as TanStackQueryClient } from "@tanstack/react-query";
export class SensorQueryClient implements QueryClient<ChimneySensorStatusDto> {
  private queryClient: TanStackQueryClient;
  private sensorDao: ChimneySensorStatusDao;

  constructor(args: {
    queryClient: TanStackQueryClient;
    sensorDao: ChimneySensorStatusDao;
  }) {
    this.queryClient = args.queryClient;
    this.sensorDao = args.sensorDao;
  }

  getById(id: ChimneySensorSerialNr): Promise<ChimneySensorStatusDto> {
    return this.queryClient.fetchQuery({
      queryKey: ["SensorDao.getById", id],
      queryFn: () => this.sensorDao.getById(id),
    });
  }

  query = (
    query: ChimneySensorStatusQueryDto,
    page: PageDto
  ): Promise<QueryResult<ChimneySensorStatusDto>> => {
    return this.queryClient.fetchQuery({
      queryKey: ["SensorDao.query", query, page],
      queryFn: () =>
        this.sensorDao.query({
          ...query,
          page: page,
        }),
    });
  };
}
