import React, { FC } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  useTheme,
} from "@mui/material";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import { RouteItem } from "./RouteItem";
import { isSelectableItem } from "./SelectableItem";
import { useClassName } from "./useResolveClassName";
import { ItemVariant } from "./ItemVariant";
import { useSxMerge } from "shared-ts-mui";
import { useResolveItemColors } from "./useResolveItemColors";

export interface RouteMenuItemProps {
  item: RouteItem;
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  hideSelection?: boolean;
  hideLabel?: boolean;
  onSelected?: (item: RouteItem) => void;
  sx?: SxProps;
}
export const RouteMenuItem: FC<RouteMenuItemProps> = (props) => {
  const { item, onSelected } = props;
  const hideLabel = props.hideLabel ?? false;
  const selected = isSelectableItem(item) ? item.selected : undefined;

  const classNameRoot = useClassName(props, ["Item", "RouteMenuItem"]);

  const {
    onColor,
    onContainerColorWhenSelected,
    containerColorWhenSelected,
    containerColorOnHover,
    containerColorWhenSelectedOnHover,
  } = useResolveItemColors();

  const handleClick = () => {
    onSelected?.(item);
  };

  const sx = useSxMerge(props.sx, {
    whiteSpace: "wrap",
    ">.MuiListItemText-root": {
      ">.MuiTypography-root": {
        fontSize: "inherit",
        textAlign: "inherit",
      },
    },
    "&.layout-medium": {
      fontSize: "0.75rem",
      "&.direction-column": {
        ".MuiListItemIcon-root": {
          height: "32px",
          width: "56px",
        },
      },
    },
    "&.layout-compact": {
      fontSize: "0.75rem",
      "&.direction-column": {
        ".MuiListItemIcon-root": {
          height: "32px",
          width: "56px",
        },
      },
    },
    "&.direction-row": {
      textAlign: "left",
      "&.selected": {
        color: onContainerColorWhenSelected,
        backgroundColor: containerColorWhenSelected,
      },
      "&:hover": {
        backgroundColor: selected
          ? containerColorWhenSelectedOnHover
          : containerColorOnHover,
      },
    },
    "&.direction-column": {
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      gap: "4px",
      ".MuiListItemIcon-root": {
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "15px",
      },
      "&.selected": {
        backgroundColor: "unset",
        ".MuiListItemIcon-root": {
          backgroundColor: containerColorWhenSelected,
          color: onContainerColorWhenSelected,
        },
      },
      "&:hover": {
        backgroundColor: "unset",
        ".MuiListItemIcon-root": {
          backgroundColor: selected
            ? containerColorWhenSelectedOnHover
            : containerColorOnHover,
        },
      },
    },
  });

  return (
    <MenuItem onClick={handleClick} className={classNameRoot} sx={sx}>
      {item.icon != null && <ListItemIcon>{item.icon}</ListItemIcon>}
      {!hideLabel && <ListItemText primary={item.name} />}
    </MenuItem>
  );
};
