import { useMemo } from "react";
import { BurnId } from "../../Burn";
import { BurnTemperatureSeries } from "../../BurnTemperatureSeries";
import { ApexOptions } from "apexcharts";
import { ArrayElement } from "@airmont/shared/ts/types";
import { BurnSensitiveUi } from "../../BurnSensitiveUi";

type ApexSeriesData = ArrayElement<ApexAxisChartSeries>["data"];

export const useApexSeries = (
  burnTemperatureSeries: Array<BurnTemperatureSeries>,
  burnById: Record<BurnId, BurnSensitiveUi>
): ApexOptions["series"] => {
  return useMemo(() => {
    return burnTemperatureSeries.map((it) => {
      const burn = burnById[it.burnId];
      const data: ApexSeriesData = it.dataPoints.map((dp) => {
        return {
          x: dp.time,
          y: dp.value,
        };
      });

      return {
        type: "line",
        name: `Burn#${burn.orderOfOccurence}`,
        color: burn.color,
        data: data,
      };
    });
  }, [burnTemperatureSeries, burnById]);
};
