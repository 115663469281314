import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { AccessToken } from "shared-ts-utils-authentication";
import { ContactDto, ContactId } from "./Contact";
import { ContactCreateDto } from "./ContactCreate";

export class ContactDao {
  private readonly accessToken: string;
  private readonly customerEnvironment: string;
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.accessToken = args.accessToken;
    this.customerEnvironment = args.customerEnvironment;
    this.fetchHelper = new FetchHelper({ basePath: "/api/contact/" })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  getList(): Promise<Array<ContactDto>> {
    return this.fetchHelper.get("list").fetchAsJson();
  }

  queryKey(): {
    customerEnvironment: CustomerEnvironmentName;
    accessToken: AccessToken;
  } {
    return {
      customerEnvironment: this.customerEnvironment,
      accessToken: this.accessToken,
    };
  }

  create(contact: ContactCreateDto): Promise<ContactDto> {
    return this.fetchHelper.post("", contact).fetchAsJson();
  }
  update(contact: ContactDto): Promise<ContactDto> {
    return this.fetchHelper.put("", contact).fetchAsJson();
  }

  deleteById(id: ContactId): Promise<void> {
    return this.fetchHelper
      .delete(id)
      .fetchAsText() as unknown as Promise<void>;
  }
}
