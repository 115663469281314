import { Pin } from "@vis.gl/react-google-maps";
import React, { ReactElement, useMemo } from "react";
import { FeatureMarkerPinRenderProps } from "shared-ts-ui-react-google-maps";
import { FlueStatusGeoFeature } from "@airmont/firefly/chimney-insights/ts/domain";
import { DateTime, Interval } from "luxon";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { darken, useTheme } from "@mui/material";
import { notUndef } from "@airmont/shared/ts/utils/core";

export const FlueStatusPin = (
  props: FeatureMarkerPinRenderProps<FlueStatusGeoFeature>
): ReactElement => {
  const { feature } = props;
  const theme = useTheme();
  const now = useDateTimeNow();

  const color = useMemo(() => {
    const earliestSweepDate = feature.properties.flues
      .map((it) =>
        notUndef(
          it.flue.estimatedSweepDate,
          (it) => DateTime.fromISO(it) as DateTime<true>
        )
      )
      .reduce((a, curr) => {
        if (a === undefined) {
          return curr;
        }
        if (curr === undefined) {
          return a;
        }
        return curr < a ? curr : a;
      }, undefined);

    if (earliestSweepDate == null) {
      return theme.palette.success;
    }

    const interval = Interval.fromDateTimes(now, earliestSweepDate);
    const duration = interval.toDuration();
    const durationInMonths = duration.shiftTo("months").get("month");
    if (durationInMonths <= 12) {
      return theme.palette.error;
    }
    if (durationInMonths > 12 && durationInMonths <= 24) {
      return theme.palette.warning;
    }
    return theme.palette.success;
  }, [
    feature.properties.flues,
    now,
    theme.palette.error,
    theme.palette.success,
    theme.palette.warning,
  ]);

  return (
    <Pin
      background={color.main}
      glyphColor={darken(
        color.main,
        feature.properties.flues.length > 1 ? 0.7 : 0.3
      )}
      borderColor={"#000"}
      glyph={
        feature.properties.flues.length > 1
          ? feature.properties.flues.length.toString()
          : undefined
      }
    />
  );
};
