import { RequestHandler } from "msw";
import {
  BurnController,
  BurnDto,
  ChannelController,
  ChannelDto,
  ChimneySensorController,
  ChimneySensorDto,
  ChimneySensorSettingsController,
  ChimneySensorSettingsHistoryDto,
  ContactController,
  ContactDto,
  MunicipalityController,
  MunicipalityDto,
  SweepingController,
  SweepingDto,
} from "@airmont/firefly/shared/ts/domain";
import {
  FlueStatusDto,
  SystemController,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { MetricsController } from "@airmont/firefly/shared/ts/metric";

export class MockControllers {
  private readonly contacts: ContactDto[];
  private readonly channels: ChannelDto[];
  private readonly municipalities: MunicipalityDto[];
  //private readonly flues: Array<FlueStatusDto>;
  private readonly burns: BurnDto[];
  private readonly chimneySensors: ChimneySensorDto[];
  private readonly chimneySensorsSettings: ChimneySensorSettingsHistoryDto[];
  private readonly sweepings: SweepingDto[];

  private readonly systemController: SystemController;
  private readonly contactController: ContactController;
  private readonly channelController: ChannelController;
  private readonly municipalityController: MunicipalityController;
  private readonly burnController: BurnController;
  //private readonly sensorController: ChimneySensorController;
  private readonly metricsController: MetricsController;
  private readonly chimneySensorController: ChimneySensorController;
  private readonly chimneySensorSettingsController: ChimneySensorSettingsController;
  private readonly sweepingController: SweepingController;
  //private readonly timeSeriesController: TimeSeriesController;

  constructor(mocks: {
    contacts: ContactDto[];
    channels: ChannelDto[];
    municipalities: MunicipalityDto[];
    flues: FlueStatusDto[];
    burns: BurnDto[];
    chimneySensors: ChimneySensorDto[];
    chimneySensorsSettings: ChimneySensorSettingsHistoryDto[];
    sweepings: SweepingDto[];
  }) {
    this.contacts = mocks.contacts;
    this.channels = mocks.channels;
    this.municipalities = mocks.municipalities;
    //this.flues = mocks.flues;
    this.burns = mocks.burns;
    this.chimneySensors = mocks.chimneySensors;
    this.chimneySensorsSettings = mocks.chimneySensorsSettings;
    this.sweepings = mocks.sweepings;

    this.systemController = new SystemController({
      buildVersion: "local-dev",
      environments: [
        {
          name: "local-dev",
          friendlyName: "Local Dev",
        },
      ],
    });
    this.municipalityController = new MunicipalityController({
      municipalities: this.municipalities,
    });
    const handleContactDeleted = (id: string) => {
      this.channelController.cascadeContactDeleted(id);
    };
    this.contactController = new ContactController(
      { contacts: this.contacts },
      { onDeleted: handleContactDeleted }
    );
    this.channelController = new ChannelController({
      channels: this.channels,
      contacts: this.contacts,
    });
    this.burnController = new BurnController({
      burns: this.burns,
    });
    /*this.sensorController = new ChimneySensorController({
      chimneySensors: this.flues,
    });*/
    this.chimneySensorController = new ChimneySensorController({
      chimneySensors: this.chimneySensors,
    });
    this.chimneySensorSettingsController = new ChimneySensorSettingsController({
      chimneySensorsSettings: this.chimneySensorsSettings,
    });
    this.sweepingController = new SweepingController({
      sweepings: this.sweepings,
    });
    this.metricsController = new MetricsController();
    /*this.timeSeriesController = new TimeSeriesController({
      timeSeriesGenerators: [
        new ChimneyFireTimeSeriesGenerator({
          temperatureThreshold: 450,
        }),
      ],
    });*/
  }

  getHandlers(): RequestHandler[] {
    return [
      ...this.systemController.handlers,
      ...this.municipalityController.handlers,
      ...this.contactController.handlers,
      ...this.channelController.handlers,
      ...this.burnController.handlers,
      //...this.sensorController.handlers,
      ...this.chimneySensorController.handlers,
      ...this.chimneySensorSettingsController.handlers,
      ...this.sweepingController.handlers,
      ...this.metricsController.handlers,
      //...this.timeSeriesController.handlers,
    ];
  }
}
