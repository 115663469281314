import { useMemo } from "react";
import { useAccessToken } from "shared-ts-utils-authentication";
import { useCustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { FlueStatusDao } from "./FlueStatusDao";

export const useFlueStatusDao = (): FlueStatusDao => {
  const accessToken = useAccessToken();
  const customerEnvironmentName = useCustomerEnvironmentName();
  return useMemo(
    () =>
      new FlueStatusDao({
        accessToken: accessToken,
        customerEnvironment:
          customerEnvironmentName ??
          _throw(new NullError("customerEnvironmentName")),
      }),
    [accessToken, customerEnvironmentName]
  );
};
