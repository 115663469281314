import { FC, useCallback } from "react";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { DisableWhatsNewUserSettingName } from "./DisableWhatsNewUserSettingName";
import { WhatsNewPage } from "./WhatsNewPage";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { useLanguage } from "shared-ts-language";

export class WhatsNewProviderProps {
  children: React.ReactNode;
}

export const WhatsNewProvider: FC<WhatsNewProviderProps> = (props) => {
  const appInfo = useAppInfo();
  const { language, setLanguage } = useLanguage();
  const [disableShowWhatsNew, setDisableShowWhatsNew] =
    useUserSettingWithDefault(
      DisableWhatsNewUserSettingName,
      BooleanSetting,
      false
    );

  const handleWhatsNewPageClose = useCallback(() => {
    setDisableShowWhatsNew(true);
  }, []);

  if (disableShowWhatsNew) {
    return props.children;
  }

  return (
    <NavigationLessAppPage
      appName={appInfo.name}
      appIcon={appInfo.icon?.large}
      language={language}
      onLanguageChange={setLanguage}
    >
      <WhatsNewPage onClose={handleWhatsNewPageClose} />
    </NavigationLessAppPage>
  );
};
