import { FlueMetrics } from "../metrics/FlueMetrics";
import { ArrayUtils, MathUtils } from "@airmont/shared/ts/utils/core";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";

export const useResolveBurnHourCountSeries = (
  year: number,
  monthNow: number | null,
  metricsByMonth: Record<string, FlueMetrics>
): Array<number | null> => {
  return ArrayUtils.createAndFill(12, (index) => {
    const month = index + 1;
    if (monthNow != null && month > monthNow) {
      return null;
    }
    const flueMetrics = metricsByMonth[`${year}-${month}`];
    if (flueMetrics == null) {
      return null;
    }
    return MathUtils.round(flueMetrics.metrics.burnHourCount);
  });
};
