/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { SizeClassComparer } from "./SizeClassComparer";
import { useMemo } from "react";
import { useElementSizeClass } from "./useElementSizeClass";
import { OnRefChangeType } from "react-resize-detector/build/types/types";

export const useElementWidthComparer = (): {
  ref: OnRefChangeType;
  elementWidthComparer: SizeClassComparer;
} => {
  const { ref, width } = useElementSizeClass();
  return {
    ref: ref,
    elementWidthComparer: useMemo(() => new SizeClassComparer(width), [width]),
  };
};
