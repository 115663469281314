import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { FlueMetricsDto } from "../FlueMetrics";
import { FlueMetricsQueryDto } from "./FlueMetricsQuery";

export class FlueMetricsController {
  public readonly handlers: RequestHandler[];

  private readonly flueMetrics: Array<FlueMetricsDto>;

  constructor(args: { flueMetrics: Array<FlueMetricsDto> }) {
    this.flueMetrics = args.flueMetrics;
    this.handlers = [rest.post("/api/flue-metrics/query", this.query)];
  }

  query = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<FlueMetricsDto>>> => {
    const { flueId, timeUnit, time }: FlueMetricsQueryDto = await req.json();
    let result = this.flueMetrics.filter((it) => it.flueId === flueId);
    if (timeUnit != null) {
      result = result.filter((it) => it.timeUnit === timeUnit);
    }
    if (time != null) {
      result = result.filter((it) => it.time === time);
    }
    return res(ctx.json(result));
  };
}
