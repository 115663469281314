import { FC, ReactNode } from "react";
import { PaperElevationContext } from "./PaperElevationContext";

export interface PaperElevationProps {
  elevation: number;
  children: ReactNode;
}

export const PaperElevation: FC<PaperElevationProps> = (props) => {

  return (
    <PaperElevationContext.Provider value={props.elevation}>
      {props.children}
    </PaperElevationContext.Provider>
  );
};
