import React, { FC } from "react";
import { Roulette } from "./Roulette";
import { ValueCard } from "@airmont/firefly/shared/ts/ui";
import { MathUtils, notUndef } from "@airmont/shared/ts/utils/core";
import { resolveCardLayoutTypeFromSizeClass } from "./OverviewPage";
import {
  TimeframeProps,
  TimeframeUtils,
} from "@airmont/shared/ts/ui/timeframe";
import { ResolutionUtils } from "@airmont/firefly/shared/ts/timeseries";
import {
  EnvironmentMetricValues,
  MunicipalityId,
  MunicipalityMetricValues,
} from "@airmont/firefly/shared/ts/domain";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { PeriodBarChart } from "./PeriodBarChart";

export interface MainPaneProps {
  timeframeProps: TimeframeProps;
  autoRoll: boolean;
  autoRollDelay: number;
  timeSeriesOwner: CustomerEnvironmentName | MunicipalityId;
  loadingMetrics: boolean;
  metricValues: EnvironmentMetricValues | MunicipalityMetricValues | undefined;
  sx?: SxProps;
}

export const MainPane: FC<MainPaneProps> = (props) => {
  const {
    timeframeProps,
    autoRoll,
    autoRollDelay,
    loadingMetrics,
    metricValues,
    timeSeriesOwner,
  } = props;
  const { t: t_firefly_shared_domain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const timeframeResolution = TimeframeUtils.unitToResolution(
    timeframeProps.timeframeUnit
  );
  const expandedTimeframeResolution =
    ResolutionUtils.expandResolution(timeframeResolution);

  return (
    <Roulette
      autoRollDelay={autoRollDelay}
      autoRoll={autoRoll}
      sx={props.sx}
      autoSaveId={"Overview"}
      entries={[
        {
          header: (index, selected, layout, handleClick) => (
            <ValueCard
              key={index}
              elevation={3}
              label={t_firefly_shared_domain("Burns")}
              tooltip={"Antall fyringer i perioden"}
              value={metricValues?.burnCount}
              selected={selected}
              onClick={handleClick}
              layout={resolveCardLayoutTypeFromSizeClass(layout)}
              loading={loadingMetrics}
              sx={{ borderRadius: "10px" }}
            />
          ),
          content: (layout) => (
            <PeriodBarChart
              displayName={t_firefly_shared_domain("Burns")}
              noDataText={t_firefly_shared_domain("No burns during the period")}
              timeSeriesName="burn_count"
              unit={""}
              owner={timeSeriesOwner}
              from={timeframeProps.timeframe.start}
              to={timeframeProps.timeframe.end}
              resolution={expandedTimeframeResolution}
              yAxisLabel={t_firefly_shared_domain("Burns")}
              yAxisMinimumMax={100}
              layout={layout}
            />
          ),
        },
        {
          header: (index, selected, layout, handleClick) => (
            <ValueCard
              key={index}
              elevation={3}
              label={t_firefly_shared_domain("Burn Hours")}
              tooltip={"Antall timer fyrt i perioden"}
              value={notUndef(metricValues?.burnHourCount, (it) =>
                MathUtils.round(it)
              )}
              selected={selected}
              onClick={handleClick}
              layout={resolveCardLayoutTypeFromSizeClass(layout)}
              loading={loadingMetrics}
              sx={{ borderRadius: "10px" }}
            />
          ),
          content: (layout) => (
            <PeriodBarChart
              displayName={t_firefly_shared_domain("Burn Hours")}
              noDataText={t_firefly_shared_domain("No burns during the period")}
              timeSeriesName="burn_hour_count"
              yAxisLabel={t_firefly_shared_domain("Burn Hours")}
              unit={""}
              owner={timeSeriesOwner}
              from={timeframeProps.timeframe.start}
              to={timeframeProps.timeframe.end}
              resolution={expandedTimeframeResolution}
              yAxisMinimumMax={500}
              layout={layout}
            />
          ),
        },
        {
          header: (index, selected, layout, handleClick) => (
            <ValueCard
              key={index}
              elevation={3}
              label={t_firefly_shared_domain("Temperature Alarms")}
              tooltip={"Antall temperaturalarmer i perioden"}
              value={metricValues?.chimneyFireCount}
              selected={selected}
              onClick={handleClick}
              layout={resolveCardLayoutTypeFromSizeClass(layout)}
              loading={loadingMetrics}
              sx={{ borderRadius: "10px" }}
            />
          ),
          content: (layout) => (
            <PeriodBarChart
              displayName={t_firefly_shared_domain("Temperature Alarms")}
              noDataText={"Ingen temperaturalarmer i perioden"}
              timeSeriesName="chimney_fire_count"
              unit={""}
              owner={timeSeriesOwner}
              from={timeframeProps.timeframe.start}
              to={timeframeProps.timeframe.end}
              resolution={expandedTimeframeResolution}
              yAxisLabel={t_firefly_shared_domain("Temperature Alarms")}
              yAxisMinimumMax={10}
              layout={layout}
            />
          ),
        },
      ]}
    />
  );
};
