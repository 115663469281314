import { BarChart } from "@mui/x-charts";
import { SweepCountByPeriod } from "./SweepCountByPeriod";
import { FC, useMemo } from "react";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import { SxProps } from "@mui/system";
import { Info } from "luxon";
import {
  AxisConfig,
  ChartsXAxisProps,
  ScaleName,
} from "@mui/x-charts/models/axis";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "@tanstack/react-query";
import { SummarizingChartsAxisContent } from "shared-ts-mui";

type AxisData = {
  dataIndex: number;
  axisValue?: number | Date | string;
  seriesValues: Record<string, number | null | undefined>;
};

export interface SweepCountByMonthBarChartProps {
  upcomingSweepCounts: UseQueryResult<Array<SweepCountByPeriod>>;
  repeatingSweepCounts: UseQueryResult<Array<SweepCountByPeriod>>;
  colors: {
    overdue: string;
    repeating: string;
    upcoming: string;
  };
  onMonthClick?: (month: number) => void;
  sx?: SxProps;
}

export const SweepCountByMonthBarChart: FC<SweepCountByMonthBarChartProps> = (
  props
) => {
  const { repeatingSweepCounts } = props;
  const { t } = useTranslation("firefly-chimney-insights-ts-domain");
  const loading =
    props.upcomingSweepCounts.isFetching ||
    props.upcomingSweepCounts.isLoading ||
    repeatingSweepCounts.isFetching ||
    repeatingSweepCounts.isLoading;
  const now = useDateTimeNow();

  const sweepCounts = useMemo(() => {
    if (props.upcomingSweepCounts.data === undefined) {
      return undefined;
    }
    if (props.upcomingSweepCounts.data.length === 0) {
      return [];
    }
    return props.upcomingSweepCounts.data.filter((it) => it.time >= now);
  }, [props.upcomingSweepCounts.data, now]);

  const overdueSweepCounts = useMemo(() => {
    if (props.upcomingSweepCounts.data === undefined) {
      return undefined;
    }
    if (props.upcomingSweepCounts.data.length === 0) {
      return [];
    }
    return props.upcomingSweepCounts.data.filter((it) => it.time < now);
  }, [props.upcomingSweepCounts.data, now]);

  const xAxisBandData = useMemo(() => {
    return Info.months().map((it) => it.capitalizeFirstLetter());
  }, []);

  const series: Array<MakeOptional<BarSeriesType, "type">> = useMemo(() => {
    let upcomingNeedForSweepSeries: Array<number | null> = [];
    if (sweepCounts != null && sweepCounts.length > 0) {
      if (sweepCounts[0].time.year === now.year) {
        upcomingNeedForSweepSeries = ArrayUtils.createAndFill(
          now.month,
          () => null
        );
      }

      sweepCounts?.forEach((it) => {
        upcomingNeedForSweepSeries.push(it.count);
      });
    } else {
      upcomingNeedForSweepSeries = ArrayUtils.createAndFill(12, () => null);
    }

    const overdueSeries: Array<number | null> = [];
    if (overdueSweepCounts != null && overdueSweepCounts?.length > 0) {
      overdueSweepCounts?.forEach((it) => {
        overdueSeries.push(it.count);
      });
    }

    let repeatingNeedForSweepSeries: Array<number | null> = [];
    if (repeatingSweepCounts.data != null) {
      repeatingSweepCounts.data.forEach((it) => {
        repeatingNeedForSweepSeries.push(it.count);
      });
    } else {
      repeatingNeedForSweepSeries = ArrayUtils.createAndFill(12, () => null);
    }
    const barSeriesTypes: Array<BarSeriesType> = [
      {
        id: "upcoming",
        data: upcomingNeedForSweepSeries,
        stack: "total",
        color: props.colors.upcoming,
        label: (location) =>
          location === "legend" ? t("Upcoming Sweeps") : t("Upcoming Sweeps"),
      } as BarSeriesType,
      {
        id: "repeating",
        data: repeatingNeedForSweepSeries,
        stack: "total",
        color: props.colors.repeating,
        label: (location) =>
          location === "legend" ? t("Repeating Sweeps") : t("Repeating Sweeps"),
      } as BarSeriesType,
    ];
    if (overdueSeries.length > 0) {
      barSeriesTypes.push({
        id: "overdue",
        data: overdueSeries,
        stack: "total",
        color: props.colors.overdue,
        label: (location) =>
          location === "legend" ? t("Overdue Sweeps") : t("Overdue Sweeps"),
      } as BarSeriesType);
    }
    return barSeriesTypes;
  }, [
    now.month,
    now.year,
    overdueSweepCounts,
    props.colors.overdue,
    props.colors.repeating,
    props.colors.upcoming,
    repeatingSweepCounts.data,
    sweepCounts,
    t,
  ]);

  const handleAxisClick = (event: MouseEvent, axisData: AxisData | null) => {
    if (axisData != null && axisData?.dataIndex != null) {
      props.onMonthClick?.(axisData.dataIndex + 1);
    }
  };

  const xAxis: MakeOptional<
    AxisConfig<ScaleName, any, ChartsXAxisProps>,
    "id"
  >[] = useMemo(
    () => [
      {
        label: t("Month"),
        scaleType: "band",
        data: xAxisBandData,
      },
    ],
    [xAxisBandData, t]
  );

  return (
    <BarChart
      loading={loading}
      slotProps={{ loadingOverlay: { message: t("Loading data ...") } }}
      borderRadius={6}
      barLabel="value"
      xAxis={xAxis}
      yAxis={[
        {
          label: t("Flues"),
        },
      ]}
      series={series}
      onAxisClick={handleAxisClick}
      slots={{
        axisContent: (props) => (
          <SummarizingChartsAxisContent
            {...props}
            hideSeriesWithZeroValue
            noDataMessage={t("No need for sweep")}
          />
        ),
      }}
      sx={props.sx}
    />
  );
};
