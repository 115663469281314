import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { FlueStatusQueryClient } from "./FlueStatusQueryClient";
import { useFlueStatusDao } from "./useFlueStatusDao";

export const useFlueStatusQueryClient = (): FlueStatusQueryClient => {
  const queryClient = useQueryClient();
  const dao = useFlueStatusDao();
  return useMemo(
    () =>
      new FlueStatusQueryClient({
        queryClient: queryClient,
        dao: dao,
      }),
    [queryClient, dao]
  );
};
