export class OidcClaims {

    // permissions realted to devices
    public static CreateDevices = "devices.create";
    public static ReadDevices = "devices.read";

    // permissions related to data
    public static CreateData = "data.create";
    public static ReadData = "data.read";

    public static ViewClassified = "view.classified";
    public static ViewNormal = "view.normal";
    //TODO: permissions related to views
}
