import {
  BurnQualityCountDataPoint,
  burnQualityCountDataPointWithNoAssessedIgnitions,
} from "./BurnQualityCountDataPoint";
import { BurnQualityCountAggregateByTime } from "./BurnQualityCountAggregateByTime";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { merge, padStart } from "lodash";
import { DateTimeUnit, Interval } from "luxon";
import { TimeWheel } from "@airmont/shared/ts/utils/luxon";

export interface BalanceGoodInPercentOptions {
  thresholdFactor: number;
}

export class BurnQualityCountDataPoints extends Array<BurnQualityCountDataPoint> {
  public static readonly DefaultBalanceGoodInPercentOptions: BalanceGoodInPercentOptions =
    {
      thresholdFactor: 0.9,
    };
  private readonly options: BalanceGoodInPercentOptions;

  constructor(options?: BalanceGoodInPercentOptions) {
    super();
    this.options =
      merge(
        {},
        BurnQualityCountDataPoints.DefaultBalanceGoodInPercentOptions,
        options
      ) ?? BurnQualityCountDataPoints.DefaultBalanceGoodInPercentOptions;
  }

  static empty(): BurnQualityCountDataPoints {
    return new BurnQualityCountDataPoints();
  }

  static from(
    aggregates: Array<BurnQualityCountAggregateByTime>
  ): BurnQualityCountDataPoints {
    return BurnQualityCountDataPoints.fromAggregates(aggregates);
  }

  static fromAggregates(
    aggregates: Array<BurnQualityCountAggregateByTime>,
    options?: BalanceGoodInPercentOptions
  ): BurnQualityCountDataPoints {
    if (aggregates === undefined) {
      return new BurnQualityCountDataPoints();
    }
    const dataPoints = new BurnQualityCountDataPoints(options);
    aggregates.forEach((aggregate) => {
      const goodIgnitions =
        aggregate.qualityCount.good + aggregate.qualityCount.excellent;
      const assessedIgnitions = goodIgnitions + aggregate.qualityCount.bad;
      const goodInPercent =
        assessedIgnitions === 0
          ? null
          : MathUtils.round((goodIgnitions / assessedIgnitions) * 100);

      dataPoints.push({
        time: aggregate.time,
        excellent: aggregate.qualityCount.excellent,
        good: aggregate.qualityCount.good,
        bad: aggregate.qualityCount.bad,
        unknown: aggregate.qualityCount.unknown,
        avgAssessedIgnitions: -1,
        assessedIgnitions: assessedIgnitions,
        goodIgnitions: goodIgnitions,
        goodInPercentRaw: goodInPercent,
        goodInPercent: goodInPercent,
      });
    });
    return dataPoints.balanceGoodInPercent();
  }

  sumAssessedIgnitions(endIndex?: number): number {
    return this.reduce((a, b, index) => {
      if (endIndex !== undefined && index > endIndex) {
        return a;
      }
      return a + b.assessedIgnitions;
    }, 0);
  }

  sumGoodIgnitions(endIndex?: number): number {
    return this.reduce((a, b, index) => {
      if (endIndex !== undefined && index > endIndex) {
        return a;
      }
      return a + b.goodIgnitions;
    }, 0);
  }

  avgAssessedIgnitions(endIndex?: number): number {
    return (
      this.sumAssessedIgnitions(endIndex) /
      (endIndex !== undefined ? endIndex + 1 : this.length)
    );
  }

  avgGoodIgnitionsInPercent(): number {
    const sumGoodIgnitions = this.sumGoodIgnitions();
    const sumAssessedIgnitions = this.sumAssessedIgnitions();
    return (sumGoodIgnitions / sumAssessedIgnitions) * 100;
  }

  balanceGoodInPercent(): BurnQualityCountDataPoints {
    const newDataPoints = new BurnQualityCountDataPoints(this.options);
    for (let index = 0; index < this.length; index++) {
      const curr = this[index];
      const avgAssessedIgnitions = this.avgAssessedIgnitions(index - 1);
      const assessedIgnitionsThreshold =
        avgAssessedIgnitions * this.options.thresholdFactor;
      const significantFewerAssessedIgnitions =
        curr.assessedIgnitions <= assessedIgnitionsThreshold;

      if (!significantFewerAssessedIgnitions) {
        newDataPoints.push({
          ...curr,
          avgAssessedIgnitions: avgAssessedIgnitions,
        });
      } else {
        const previous =
          index === 0
            ? undefined
            : newDataPoints.findFirstFrom(
                (nearest) => nearest.goodInPercent != null,
                index - 1
              );

        if (curr.assessedIgnitions === 0) {
          newDataPoints.push({
            ...curr,
            avgAssessedIgnitions: avgAssessedIgnitions,
          });
        } else if (previous === undefined) {
          newDataPoints.push({
            ...curr,
            avgAssessedIgnitions: avgAssessedIgnitions,
          });
        } else {
          const factor = curr.assessedIgnitions / avgAssessedIgnitions;

          let goodInPercent: number | null =
            (curr.goodIgnitions / curr.assessedIgnitions) * 100;

          if (previous.goodInPercent == null) {
            goodInPercent = null;
          } else if (goodInPercent === 50) {
            goodInPercent = previous.goodInPercent;
          } else if (goodInPercent > 50) {
            const addition = factor === 1 ? 0 : goodInPercent * factor;
            goodInPercent = MathUtils.round(previous.goodInPercent + addition);
            goodInPercent = Math.min(curr.goodInPercentRaw ?? 0, goodInPercent);

            if (goodInPercent > 100) {
              goodInPercent = 100;
            }
          } else {
            const badInPercent = (curr.bad / curr.assessedIgnitions) * 100;

            const reduction = factor === 1 ? 0 : badInPercent * factor;
            goodInPercent = MathUtils.round(previous.goodInPercent - reduction);
            goodInPercent = Math.max(curr.goodInPercentRaw ?? 0, goodInPercent);
            if (goodInPercent < 0) {
              goodInPercent = 0;
            }
          }

          newDataPoints.push({
            ...curr,
            avgAssessedIgnitions: avgAssessedIgnitions,
            goodInPercent: goodInPercent,
          });
        }
      }
    }

    return newDataPoints;
  }

  filter(
    predicate: (
      value: BurnQualityCountDataPoint,
      index: number,
      array: BurnQualityCountDataPoint[]
    ) => boolean,
    thisArg?: BurnQualityCountDataPoints
  ): BurnQualityCountDataPoints {
    const filteredItems = super.filter(predicate, thisArg);
    const newDataPoints = new BurnQualityCountDataPoints();
    newDataPoints.push(...filteredItems);
    return newDataPoints;
  }

  between(
    interval: Interval<true>,
    frequency: DateTimeUnit
  ): BurnQualityCountDataPoints {
    const newDataPoints = BurnQualityCountDataPoints.empty();
    new TimeWheel({ start: interval.start, timeUnit: frequency }).runUntilTime(
      interval.end,
      (dateTime) => {
        const dateTimeAsUtc = dateTime.toUTC();
        const existing = this.find((it) => it.time.equals(dateTimeAsUtc));
        if (existing === undefined) {
          newDataPoints.push(
            burnQualityCountDataPointWithNoAssessedIgnitions(dateTimeAsUtc)
          );
        } else {
          newDataPoints.push(existing);
        }
      }
    );

    return newDataPoints;
  }

  findFirstFrom(
    predicate: (item: BurnQualityCountDataPoint) => boolean,
    startIndex: number = this.length - 1
  ): BurnQualityCountDataPoint | undefined {
    for (let i = startIndex; i >= 0; i--) {
      const it = this[i];
      if (predicate(it)) {
        return it;
      }
    }
    return undefined;
  }

  toString(): string {
    let str =
      "|  i | time    | avg. #ignitions | #ignitions | #good | good raw % | good bal % |\n";
    str += "---------------------------------\n";
    for (let i = 0; i < this.length; i++) {
      const item = this[i];
      str += `| ${padStart(i.toString(), 2)} | ${padStart(
        item.time.year + ":" + padStart(item.time.month.toString(), 2, "0"),
        4
      )} | ${padStart(
        Math.round(item.avgAssessedIgnitions).toString(),
        15
      )} | ${padStart(
        Math.round(item.assessedIgnitions).toString(),
        10
      )} | ${padStart(
        Math.round(item.goodIgnitions).toString(),
        5
      )} | ${padStart(item.goodInPercentRaw?.toString(), 9)}% | ${padStart(
        item.goodInPercent?.toString(),
        9
      )}% |\n`;
    }
    return str;
  }
}
