export class MathUtils {
  static round(value: number, precision?: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  static max(
    numbers: Array<number | null | undefined>,
    initialValue = 0
  ): number {
    let max = initialValue;
    numbers.forEach((it) => {
      if (it != null && it > max) {
        max = it;
      }
    });
    return max;
  }
}
