import { Item, isItem } from "./Item";
import { ReactNode } from "react";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface LinkItem extends Item {
  type: "LinkItem";
  name: string;
  nameBreakable?: string;
  icon?: ReactNode;
  href: string;
  target?: string;
}

export function isLinkItem(obj: object): obj is LinkItem {
  const check = isItem(obj) && "type" in obj && obj.type === "LinkItem";
  if (check) {
    if (!("href" in obj && typeof obj.href === "string")) {
      throw new IllegalStateError("A LinkItem requires href property");
    }
    if (!("name" in obj && typeof obj.name === "string")) {
      throw new IllegalStateError("A LinkItem requires name property");
    }
  }
  return check;
}
