import { FC, useMemo } from "react";
import {
  FlueNameResolver,
  FlueNeedsSweepDto,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/system";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { DateTime } from "luxon";
import { DataGridFooterWithToolbar, useMuiLocalization } from "shared-ts-mui";
import RepeatRoundedIcon from "@mui/icons-material/RepeatRounded";
import { UseQueryResult } from "@tanstack/react-query";

const noRows: Array<FlueNeedsSweepDto> = [];

export interface FluesNeedForSweepTableProps {
  rows: UseQueryResult<Array<FlueNeedsSweepDto>>;
  sx?: SxProps;
}

export const FluesNeedForSweepTable: FC<FluesNeedForSweepTableProps> = (
  props
) => {
  const { muiLocalization } = useMuiLocalization();
  const columns = useTableColumns();
  const rows = props.rows.data ?? noRows;
  const loading = props.rows.isLoading || props.rows.isFetching;
  return (
    <DataGridPremium
      localeText={
        muiLocalization.components.MuiDataGrid.defaultProps.localeText
      }
      loading={loading}
      sx={props.sx}
      disableAggregation
      disableRowGrouping
      initialState={{
        pinnedColumns: {
          left: ["flue"],
        },
      }}
      columns={columns}
      rows={rows}
      slotProps={{
        footer: {
          rowCount: rows.length,
          totalRowCount: rows.length,
          loading: loading,
        },
      }}
      slots={{
        footer: DataGridFooterWithToolbar,
      }}
    />
  );
};

const useTableColumns = (): Array<GridColDef<FlueNeedsSweepDto>> => {
  const { t: tDomain } = useTranslation("firefly-shared-ts-domain");
  const { t: tCHDomain } = useTranslation("firefly-chimney-insights-ts-domain");
  return useMemo(() => {
    const columns: Array<GridColDef<FlueNeedsSweepDto>> = [
      {
        field: "flue",
        headerName: tDomain("Flue"),
        pinnable: false,
        valueGetter: (value, row) => {
          return new FlueNameResolver(row.flue).toString();
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "flue.type",
        headerName: tDomain("Flue Type"),
        pinnable: false,
        valueGetter: (value, row) => {
          return tDomain(`FlueType.${row.flue.flue.type}`);
        },
        type: "string",
      },
      {
        field: "time",
        headerName: tDomain("Need for Sweep"),
        pinnable: false,
        type: "string",
        width: 150,
        valueGetter: (value, row, column, apiRef) => {
          const dateTime =
            DateTime.fromISO(row.time) ?? _throw(new IllegalStateError("time"));
          return dateTime.year < 10000
            ? dateTime.toFormat("LLLL yyyy").capitalizeFirstLetter()
            : null;
        },
      },
      {
        field: "repeating",
        headerName: tCHDomain("Repeating"),
        pinnable: false,
        width: 120,
        align: "center",
        renderCell: (params) => {
          return params.value === true ? (
            <RepeatRoundedIcon fontSize={"small"} />
          ) : (
            ""
          );
        },
      },
    ];
    return columns;
  }, [tDomain, tCHDomain]);
};
