import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";
import {
  _throw,
  IllegalArgumentError,
  notUndef,
} from "@airmont/shared/ts/utils/core";

export interface AuditInfoDto {
  created: DateTimeISO;
  createdBy: string;
  modified?: DateTimeISO;
  modifiedBy?: string;
}

export interface AuditInfo {
  created: DateTime<true>;
  createdBy: string;
  modified?: DateTime<true>;
  modifiedBy?: string;
}

export class AuditInfoImpl implements AuditInfo {
  readonly created: DateTime<true>;
  readonly createdBy: string;
  readonly modified: DateTime<true> | undefined;
  readonly modifiedBy: string | undefined;

  constructor(args: AuditInfoDto) {
    this.created =
      (DateTime.fromISO(args.created) as DateTime<true>) ??
      _throw(new IllegalArgumentError("Invalid created: " + args.created));
    this.createdBy = args.createdBy;
    this.modified = notUndef(
      args.modified,
      (modified) =>
        (DateTime.fromISO(modified) as DateTime<true>) ??
        _throw(new IllegalArgumentError("Invalid modified: " + modified))
    );
    this.modifiedBy = args.modifiedBy;
  }
}
