import { useContext } from "react";
import { EnvironmentContext, IEnvironmentContext } from "./EnvironmentContext";

export const useCustomerEnvironmentContext = (): IEnvironmentContext => {
  const context = useContext(EnvironmentContext);
  if (context == null) {
    throw new Error(
      "useCustomerEnvironmentContext must be used within a EnvironmentProvider"
    );
  }
  return context;
};
