import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { SweepingDto } from "./SweepingDto";
import { v4 as uuidv4 } from "uuid";
import { SweepingCreateDto } from "./SweepingCreateDto";
import { SweepingUpdateDto } from "./SweepingUpdateDto";

export class SweepingController {
  private readonly sweepings: Array<SweepingDto>;
  public readonly handlers: RequestHandler[];

  constructor(mocks: { sweepings: Array<SweepingDto> }) {
    this.sweepings = mocks.sweepings;

    this.handlers = [
      rest.get("api/sweeping/get-by-flue/:id", this.getByListFlueId),
      rest.post("api/sweeping/create/", this.create),
      rest.post("api/sweeping/update/", this.update),
    ];
  }

  private getByListFlueId = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<SweepingDto>>> => {
    const { id } = req.params;
    const result = this.sweepings.filter((it) => it.flueId === id);
    return res(ctx.json(result));
  };

  private create = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<SweepingDto>> => {
    const create: SweepingCreateDto = await req.json();
    const newEntry = { ...create, id: uuidv4() } as SweepingDto;
    this.sweepings.push(newEntry);
    return res(ctx.json(newEntry));
  };

  private update = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<SweepingDto>> => {
    const update: SweepingUpdateDto = await req.json();
    const existingEntryIndex = this.sweepings.findIndex(
      (it) => it.id === update.id
    );
    if (existingEntryIndex === -1) {
      return res(ctx.status(404));
    }
    const existingEntry = this.sweepings[existingEntryIndex];
    const updatedEntry: SweepingDto = { ...existingEntry, ...update };
    this.sweepings[existingEntryIndex] = updatedEntry;
    return res(ctx.json(updatedEntry));
  };
}
