import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";
import { XAxisDefaultConfig } from "shared-ts-mui";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime, DateTimeUnit, Duration, Interval } from "luxon";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { TimeWheel } from "@airmont/shared/ts/utils/luxon";

export const resolveTimeAxisData = (
  timeSeriesArray: Array<TimeSeries<number | null>>,
  axisConfig: XAxisDefaultConfig | undefined
): Array<Date> => {
  const timeById: Record<DateTimeISO, Date> = {};
  const axisData: Array<Date> = [];
  let interval: Interval | null = null;
  let firstTime: DateTime | null = null;
  timeSeriesArray.forEach((ts, tsIndex) => {
    ts.points.forEach((point, pointIndex) => {
      const dateTimeAsIso = point.time.toISO();
      if (timeById[dateTimeAsIso] == null) {
        timeById[dateTimeAsIso] = point.time.toJSDate();
      }
      if (firstTime == null) {
        firstTime = point.time;
      }
      if (pointIndex > 0) {
        const prevPoint = ts.points[pointIndex - 1].time;
        const newInterval = Interval.fromDateTimes(prevPoint, point.time);
        if (interval != null) {
          if (interval.length() !== newInterval.length()) {
            throw new IllegalStateError(
              `TimeSeries[${tsIndex}] has unequal interval between points: ${interval.length()} <> ${newInterval.length()}`
            );
          }
          interval = newInterval;
        }
      }
    });
  });

  if (
    axisConfig != null &&
    axisConfig.max != null &&
    timeSeriesArray[0].firstPoint?.time != null
  ) {
    const max =
      typeof axisConfig.max === "number"
        ? (DateTime.fromMillis(axisConfig.max) as DateTime<true>)
        : (DateTime.fromJSDate(axisConfig.max) as DateTime<true>);
    const resolutionAsDurationIso = timeSeriesArray[0].info
      .resolution as DateTimeUnit;
    new TimeWheel({
      start: timeSeriesArray[0].firstPoint?.time,
      duration: Duration.fromISO(resolutionAsDurationIso),
    }).runWhile(
      (dateTime) => dateTime <= max,
      (dateTime) => {
        const dateTimeAsIso = dateTime.toISO();
        if (timeById[dateTimeAsIso] == null) {
          timeById[dateTimeAsIso] = dateTime.toJSDate();
        }
      }
    );
  }

  Object.values(timeById).forEach((value) => {
    axisData.push(value);
  });

  return axisData;
};
