import { BurnId } from "./Burn";
import {
  DurationDoubleNullable,
  DurationDoubleNullableDto,
  DurationDoubleNullableImpl,
} from "@airmont/firefly/shared/ts/timeseries";
import { DateTime, Duration } from "luxon";
import { BurnTemperatureSeriesDto } from "./BurnTemperatureSeries";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { sum } from "lodash";

export interface BurnTemperatureSeriesNonSensitiveDto {
  burnId: BurnId;
  dataPoints: Array<DurationDoubleNullableDto>;
}

export interface DerivativeOptions {
  includeNegatives: boolean;
  absoluteNegatives: boolean;
}
export interface BurnTemperatureSeriesNonSensitive {
  burnId: BurnId;
  dataPoints: Array<DurationDoubleNullable>;
  maxY: number | undefined;
  minY: number | undefined;
  comparePointValues: (a: number, b: number) => -1 | 0 | 1;
  duration: Duration;

  toDto(): BurnTemperatureSeriesNonSensitiveDto;

  calculateDerivatives(options: DerivativeOptions): Array<number>;
  calculateSumOfDerivatives(options: DerivativeOptions): number;
}

export class BurnTemperatureSeriesNonSensitiveImpl
  implements BurnTemperatureSeriesNonSensitive
{
  readonly burnId: BurnId;
  readonly dataPoints: Array<DurationDoubleNullable>;

  get duration(): Duration {
    if (this.dataPoints.length === 0) {
      return Duration.fromMillis(0);
    }
    return this.dataPoints[this.dataPoints.length - 1].duration;
  }

  constructor(input: {
    burn: BurnId;
    dataPoints: Array<DurationDoubleNullable>;
  }) {
    this.burnId = input.burn;
    this.dataPoints = input.dataPoints;
  }

  comparePointValues(a: number, b: number): -1 | 0 | 1 {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  get maxY(): number | undefined {
    if (this.dataPoints.length === 0) {
      return undefined;
    }
    const initialValue = this.dataPoints[0]?.value ?? 0;
    return this.dataPoints.reduce((a, b) => {
      const comparison = this.comparePointValues(a, b.value ?? a);
      return comparison === -1 ? b.value ?? 0 : comparison === 1 ? a : a;
    }, initialValue);
  }

  get minY(): number | undefined {
    if (this.dataPoints.length === 0) {
      return undefined;
    }
    const initialValue = this.dataPoints[0].value ?? 0;
    return this.dataPoints.reduce((a, b) => {
      const aValue = a ?? 0;
      const bValue = b.value ?? a;
      const comparison = this.comparePointValues(aValue, bValue);
      return comparison === 1 ? b.value ?? 0 : comparison === -1 ? a : a;
    }, initialValue);
  }

  calculateSumOfDerivatives(options: DerivativeOptions): number {
    const absNegatives = true;
    let sum = 0;
    this.dataPoints.forEach((dataPoint, index) => {
      if (index > 0) {
        const prevDataPoint = this.dataPoints[index - 1];
        if (
          dataPoint.value != null &&
          prevDataPoint != null &&
          prevDataPoint.value != null
        ) {
          const deltaY = dataPoint.value - prevDataPoint.value;
          const deltaX = (dataPoint.duration.toMillis() /1000/60) - (prevDataPoint.duration.toMillis()/1000/60);
          const derivative = deltaY / deltaX;
          if(derivative < 0 && options.includeNegatives) {
            sum += derivative * (options.absoluteNegatives ? Math.abs(derivative) : derivative);
          }
          else {
            sum += derivative;
          }

        }
      }
    });
    return sum;
  }

  calculateDerivatives(options: DerivativeOptions): Array<number> {
    const derivatives: Array<number> = [];
    this.dataPoints.forEach((dataPoint, index) => {
      if (index > 0) {
        const prevDataPoint = this.dataPoints[index - 1];
        if (
          dataPoint.value != null &&
          prevDataPoint != null &&
          prevDataPoint.value != null
        ) {
          const deltaY = dataPoint.value - prevDataPoint.value;
          const deltaX = (dataPoint.duration.toMillis() /1000/60) - (prevDataPoint.duration.toMillis()/1000/60);
          const derivative = deltaY / deltaX;
          if(derivative < 0 && options.includeNegatives) {
            derivatives.push(derivative * (options.absoluteNegatives ? Math.abs(derivative) : derivative));
          }
          derivatives.push(derivative);
        }
      }
    });
    return derivatives;
  }

  toDto(): BurnTemperatureSeriesNonSensitiveDto {
    return {
      burnId: this.burnId,
      dataPoints: this.dataPoints.map((it) => it.toDto()),
    };
  }

  static fromBurnTemperatureSeriesNonSensitiveDto(
    dto: BurnTemperatureSeriesNonSensitiveDto,
    options?: { ensureLength?: number }
  ): BurnTemperatureSeriesNonSensitiveImpl {
    let dataPoints = dto.dataPoints.map((it) => {
      return new DurationDoubleNullableImpl({
        duration: Duration.fromMillis(it.duration),
        value: it.value,
      });
    });
    const ensureLength = options?.ensureLength ?? 0;
    if (dataPoints.length < ensureLength) {
      const lastDuration =
        dataPoints.length > 0
          ? dataPoints[dataPoints.length - 1].duration
          : Duration.fromMillis(0);
      dataPoints = dataPoints.concat(
        ArrayUtils.createAndFill(ensureLength - dataPoints.length, (index) => {
          return new DurationDoubleNullableImpl({
            duration: lastDuration.plus({ minutes: index + 1 }),
            value: null,
          });
        })
      );
    }

    return new BurnTemperatureSeriesNonSensitiveImpl({
      burn: dto.burnId,
      dataPoints: dataPoints,
    });
  }

  static fromBurnTemperatureSeriesDto(
    dto: BurnTemperatureSeriesDto
  ): BurnTemperatureSeriesNonSensitiveImpl {
    const firstStateTime = DateTime.fromISO(dto.dataPoints[0].time);

    return new BurnTemperatureSeriesNonSensitiveImpl({
      burn: dto.burnId,
      dataPoints: dto.dataPoints.map((it) => {
        const endTime = DateTime.fromISO(it.time);
        return new DurationDoubleNullableImpl({
          duration: Duration.fromMillis(
            endTime.toMillis() - firstStateTime.toMillis()
          ),
          value: it.value,
        });
      }),
    });
  }

  static getLongestDuration(
    array: Array<BurnTemperatureSeriesNonSensitive>
  ): Duration {
    return array.reduce((a, b) => {
      const d = b.duration.rescale();
      return d > a ? b.duration : a;
    }, Duration.fromMillis(0));
  }

  static getMinY(
    timeSeries: Array<BurnTemperatureSeriesNonSensitive>,
    minimumMin: number
  ): number {
    return timeSeries.reduce((a, b) => {
      const bMinY = b.minY ?? minimumMin;
      if (bMinY < a) {
        return bMinY;
      } else {
        return a;
      }
    }, minimumMin);
  }

  static getMaxY(
    timeSeries: Array<BurnTemperatureSeriesNonSensitive>,
    minimumMax: number
  ): number {
    return timeSeries.reduce((a, b) => {
      const bMaxY = b.maxY ?? minimumMax;
      if (bMaxY > a) {
        return bMaxY;
      } else {
        return a;
      }
    }, minimumMax);
  }
}
