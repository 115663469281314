import { FC, ReactNode, useMemo } from "react";
import {
  alpha,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { SxProps } from "@mui/system";
import LocalFireDepartmentRoundedIcon from "@mui/icons-material/LocalFireDepartmentRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { BurnAssessment } from "./assessment/BurnAssessment";
import { BurnNonSensitiveUi } from "./BurnNonSensitiveUi";
import { resolveBurnAssessmentIcon } from "./assessment/resolveBurnAssessmentIcon";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { isDense, SizeClass } from "@airmont/shared/ts/ui/responsive";
import { useFireflySharedTsDomainTranslation } from "../i18n";
import { BurnAssessmentColor } from "./assessment/BurnAssessmentColor";

export interface BurnListItemProps {
  burn: BurnNonSensitiveUi;
  hideDuration?: boolean;
  sx?: SxProps;
  selected?: boolean;
  hovered?: boolean;
  layout?: SizeClass;
  onHover?: (hovered: boolean) => void;
  onSelected?: (selected: boolean) => void;
  direction?: "row" | "column";
}

export const BurnListItem: FC<BurnListItemProps> = (props) => {
  const { t } = useFireflySharedTsDomainTranslation();
  const layout = props.layout ?? SizeClass.Compact;
  const dense = isDense(layout);
  const hideDuration = props.hideDuration ?? layout === SizeClass.Compact;
  const hideExtraInfoIcon = layout === SizeClass.Compact;
  const duration = props.burn.duration;

  const extraInfoIcon: ReactNode = useMemo(() => {
    if (props.burn.metrics.chimneyFire) {
      return (
        <Tooltip title={t("Temperature Alarm")} arrow placement={"top-start"}>
          <LocalFireDepartmentRoundedIcon sx={{ color: "text.secondary" }} />
        </Tooltip>
      );
    } else if (props.burn.analytics.startQuality !== BurnAssessment.Unknown) {
      const BurnQualityIcon = resolveBurnAssessmentIcon(
        props.burn.analytics.startQuality
      );
      const burnAssessment = t(
        `BurnAssessment.${props.burn.analytics.startQuality}`
      );
      const color = alpha(
        BurnAssessmentColor[props.burn.analytics.startQuality],
        0.7
      );
      return (
        <Tooltip
          title={`${t("Assessment of burn start")}: ${burnAssessment}`}
          arrow
          placement={"top-start"}
        >
          <BurnQualityIcon sx={{ color: color }} />
        </Tooltip>
      );
    } else {
      return null;
    }
  }, [props.burn.metrics.chimneyFire, props.burn.analytics.startQuality, t]);

  const handleClick = () => {
    props.onSelected?.(!(props.selected ?? false));
  };

  const handleMouseEnter = () => {
    props.onHover?.(true);
  };

  const handleMouseLeave = () => {
    props.onHover?.(false);
  };

  return (
    <ListItemButton
      dense={dense}
      key={props.burn.id}
      selected={props.selected}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        borderRadius: "18px",
        margin: "3px",
        pl: "10px",
        pr: "10px",
        minWidth: props.direction === "row" ? 80 : 0,
      }}
    >
      <Stack
        direction={"row"}
        useFlexGap={true}
        gap={1}
        sx={{ flex: 1, alignItems: "center" }}
      >
        <ListItemIcon
          sx={{
            color: props.burn.color,
            alignItems: "center",
            minWidth: "unset",
            marginRight: 1,
          }}
        >
          <CircleRoundedIcon />
        </ListItemIcon>
        <ListItemText sx={{ minWidth: "30px" }}>
          #{props.burn.orderOfOccurence}
        </ListItemText>
        {!hideDuration && (
          <Box title={t("The burns duration")}>
            <DurationDisplay
              duration={duration}
              maxUnits={1}
              layout={SizeClass.Compact}
              sx={{ minWidth: "30px", textAlign: "right" }}
            />
          </Box>
        )}
        {!hideExtraInfoIcon && (
          <Box
            sx={{
              ml: 2,
              minWidth: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {extraInfoIcon}
          </Box>
        )}
      </Stack>
    </ListItemButton>
  );
};
