import { FC, ReactNode } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import styled from "styled-components";

export const StyledSettingsGroup = styled(Box)`
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  > :nth-child(2) {
    flex-grow: 1;
  }
`;

export interface SettingsInfoProps {
  children: ReactNode;
}

export const SettingsInfo: FC<SettingsInfoProps> = (props) => {
  const theme = useTheme();
  return (
    <Typography
      component={"p"}
      sx={{paddingBottom: theme.spacing(2)}}
    >
      {props.children}
    </Typography>
  );
};
