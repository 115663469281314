import { useTheme } from "@mui/material";
import { useMemo } from "react";

export const useResolveBorderColor = (hover?: boolean) => {
  const theme = useTheme();
  return useMemo(() => {
    return theme.palette.mode === "light"
      ? hover ? "rgba(0, 0, 0, 0.87)"  : "rgba(0, 0, 0, 0.23)"
      : hover ? "#ebebeb" : "rgba(255, 255, 255, 0.23)";
  }, [hover, theme.palette.mode]);
};
