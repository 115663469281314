import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";

export interface SweepCountByPeriodDto {
  readonly time: DateTimeISO;
  readonly count: number;
}

export class SweepCountByPeriod {
  readonly time: DateTime<true>;
  readonly count: number;

  public constructor(dto: SweepCountByPeriodDto) {
    this.time = DateTime.fromISO(dto.time).toLocal() as DateTime<true>;
    this.count = dto.count;
  }
}
