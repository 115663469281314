import { Breakpoint } from "@mui/material";

export enum SizeClass {
  Compact = "Compact",
  Medium = "Medium",
  Expanded = "Expanded",
  ExtraLarge = "ExtraLarge",
}

export const resolveWindowSizeFromBreakpoint = (
  width: Breakpoint
): SizeClass => {
  if (width === "xs") {
    return SizeClass.Compact;
  } else if (width === "sm") {
    return SizeClass.Medium;
  } else if (width === "md" || width === "lg") {
    return SizeClass.Expanded;
  } else if (width === "xl") {
    return SizeClass.ExtraLarge;
  } else {
    throw new Error(
      "Unable to resolve WindowSize from Breakpoint: Unknown breakpoint: " +
        width
    );
  }
};

export const resolveSizeClassFromPixels = (value: number): SizeClass => {
  if (value < 600) {
    return SizeClass.Compact;
  } else if (value < 840) {
    return SizeClass.Medium;
  } else if (value < 1200) {
    return SizeClass.Expanded;
  } else {
    return SizeClass.ExtraLarge;
  }
};
