import React, { FC, useMemo } from "react";
import {
  DurationSeriesMuiChart,
  DurationSeriesMuiChartContainer,
} from "@airmont/firefly/shared/ts/durationseries-mui-chart";
import { ChartsReferenceLine } from "@mui/x-charts";
import { SxProps } from "@mui/system";
import { BurnTemperatureSeriesNonSensitive } from "../../BurnTemperatureSeriesNonSensitive";
import { FlueMetricsDto } from "../../../chimney/flue/metrics/FlueMetrics";
import { FlueMetricsTimeUnitEnum } from "../../../chimney/flue/metrics/FlueMetricsTimeUnit";
import { DurationSeriesImpl } from "@airmont/firefly/shared/ts/timeseries";
import { ChartsYReferenceLineProps } from "@mui/x-charts/ChartsReferenceLine/ChartsYReferenceLine";
import { XAxisDefaultConfig, YAxisDefaultConfig } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { FlueId } from "../../../chimney/flue/FlueId";

export interface BurnNonSensitiveChart2Props {
  flueId: FlueId;
  sinceSweepMetrics?: FlueMetricsDto;
  burnTemperatureSeries: Array<BurnTemperatureSeriesNonSensitive> | undefined;
  loading?: boolean;
  sx?: SxProps;
}

export const BurnNonSensitiveChart2: FC<BurnNonSensitiveChart2Props> = (
  props
) => {
  const { flueId, burnTemperatureSeries, sinceSweepMetrics, loading } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");
  const theme = useTheme();
  const durationSeries = useMemo(() => {
    return notUndef(burnTemperatureSeries, (burnTemperatureSeries) =>
      burnTemperatureSeries.map((burnTs, index) => {
        return new DurationSeriesImpl({
          info: {
            id: burnTs.burnId,
            name: burnTs.burnId,
            sid: flueId,
            unit: "°C",
            displayName: "Burn #" + (index + 1),
            resolution: "",
          },
          points: burnTs.dataPoints,
        });
      })
    );
  }, [burnTemperatureSeries, flueId]);

  const xAxisConfig: XAxisDefaultConfig = useMemo(() => {
    return {
      zoom: true,
    } as XAxisDefaultConfig;
  }, []);

  const yAxisConfig: Array<YAxisDefaultConfig> = useMemo(() => {
    return [
      {
        max:
          sinceSweepMetrics?.metrics.temperatureMax !== undefined
            ? sinceSweepMetrics?.metrics.temperatureMax
            : undefined,
        min: 0,
      } as YAxisDefaultConfig,
    ];
  }, [sinceSweepMetrics?.metrics.temperatureMax]);

  const chartYReferenceLineProps: Array<ChartsYReferenceLineProps> =
    useMemo(() => {
      return [
        sinceSweepMetrics?.metrics.temperatureMax !== undefined
          ? {
              label: `${t("Max Temp.")} (${t(
                `TimeUnit.${FlueMetricsTimeUnitEnum.SinceSweep}`
              )}`,
              y: sinceSweepMetrics?.metrics.temperatureMax,
              lineStyle: {
                stroke: theme.palette.warning.main,
                strokeDasharray: theme.spacing(1),
                strokeDashoffset: theme.spacing(1),
              },
              labelStyle: {
                fill: theme.palette.text.secondary,
                fontSize: "smaller",
              },
            }
          : undefined,
      ] as Array<ChartsYReferenceLineProps>;
    }, [sinceSweepMetrics?.metrics.temperatureMax, t, theme]);

  return (
    <DurationSeriesMuiChartContainer
      sx={props.sx}
      loading={loading}
      localeText={{
        noData: t("No Burns Selected"),
      }}
    >
      <DurationSeriesMuiChart
        yAxisConfig={yAxisConfig}
        xAxisConfig={xAxisConfig}
        durationSeries={durationSeries}
      >
        {chartYReferenceLineProps
          .filter((it) => it !== undefined)
          .map((it) => {
            return <ChartsReferenceLine key={it.label} {...it} />;
          })}
      </DurationSeriesMuiChart>
    </DurationSeriesMuiChartContainer>
  );
};
