import { useIsItemOverflowing } from "./useIsItemOverflowing";
import { Action } from "./Action";
import { LinkItem } from "./LinkItem";
import { RouteItem } from "./RouteItem";

export const useResolveItemName = (
  item: Action | LinkItem | RouteItem,
  element: HTMLElement | null | undefined
): string => {
  const isOverflowing = useIsItemOverflowing(element);
  return isOverflowing ? item.nameBreakable ?? item.name : item.name;
};
