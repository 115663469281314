import { FlueMetricValues } from "./FlueMetricValues";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { FlueMetricsTimeUnitEnum } from "./FlueMetricsTimeUnit";
import { FlueId } from "../FlueId";
import { DateTime } from "luxon";
import {
  _throw,
  IllegalStateError,
  notUndef,
} from "@airmont/shared/ts/utils/core";
import {
  FlueMetricNaturalKey,
  FlueMetricNaturalKeyImpl,
} from "./FlueMetricNaturalKey";

export interface FlueMetricsDto {
  flueId: FlueId;
  timeUnit: FlueMetricsTimeUnitEnum;
  time?: DateTimeISO;
  metrics: FlueMetricValues;
  modified: DateTimeISO;
}

export class FlueMetrics {
  readonly naturalKey: FlueMetricNaturalKey;
  readonly flueId: FlueId;
  readonly timeUnit: FlueMetricsTimeUnitEnum;
  readonly time?: DateTime;
  readonly metrics: FlueMetricValues;
  readonly modified: DateTime;

  constructor(args: FlueMetricsDto) {
    this.flueId = args.flueId;
    this.timeUnit = args.timeUnit;
    this.time = notUndef(args.time, (it) => DateTime.fromISO(it));
    this.metrics = args.metrics;
    this.modified = DateTime.fromISO(args.modified).toLocal();

    this.naturalKey = new FlueMetricNaturalKeyImpl(
      this.flueId,
      this.timeUnit,
      this.time
    );
  }

  static create(args: {
    flueId: FlueId;
    type: FlueMetricsTimeUnitEnum;
    time?: DateTimeISO;
    metrics?: FlueMetricValues;
  }): FlueMetrics {
    const now =
      DateTime.now().toISO() ??
      _throw(new IllegalStateError("Invalid DateTime"));
    return new FlueMetrics({
      flueId: args.flueId,
      timeUnit: args.type,
      time: args.time,
      modified: now,
      metrics: args.metrics ?? {
        burnCount: 0,
        burnHourCount: 0,
        chimneyFireCount: 0,
        sootIndex: 0,
        temperatureMax: 0,
      },
    });
  }
}

export class FlueMetricsFactory {
  static createEverDto(args: {
    flueId: FlueId;
    metrics: FlueMetricValues;
  }): FlueMetricsDto {
    return {
      flueId: args.flueId,
      timeUnit: FlueMetricsTimeUnitEnum.Ever,
      time:
        DateTime.local(2080, 1, 1).toISO() ?? _throw(new IllegalStateError()),
      modified: DateTime.now().toISO() ?? _throw(new IllegalStateError()),
      metrics: args.metrics,
    };
  }

  static createSinceSweepDto(args: {
    flueId: FlueId;
    metrics: FlueMetricValues;
  }): FlueMetricsDto {
    return {
      flueId: "f-1",
      timeUnit: FlueMetricsTimeUnitEnum.SinceSweep,
      time:
        DateTime.local(2023, 1, 1).toISO() ?? _throw(new IllegalStateError()),
      modified: DateTime.now().toISO() ?? _throw(new IllegalStateError()),
      metrics: args.metrics,
    };
  }
}
