import React, { FC, ReactNode, useEffect, useState } from "react";
import { LocaleContext } from "./LocaleContext";

export interface LocaleProviderProps {
  locale: string;
  children: ReactNode;
}

export const LocaleProvider: FC<LocaleProviderProps> = (props) => {
  const [locale, setLocale] = useState<string>(props.locale);
  useEffect(() => {
    setLocale(props.locale);
  }, [props.locale]);
  return (
    <LocaleContext.Provider value={locale}>
      {props.children}
    </LocaleContext.Provider>
  );
};
