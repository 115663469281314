import { useContext } from "react";
import {
  PaperElevationContext,
  PaperElevationContextType,
} from "./PaperElevationContext";

export const usePaperElevation = (): PaperElevationContextType => {
  const context = useContext(PaperElevationContext);

  if (context === undefined) {
    return 0;
  }
  return context;
};
