import { DateTime, DateTimeUnit } from "luxon";
import { DurationLike } from "luxon/src/duration";
import { _throw, IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export class TimeWheel {
  private readonly start: DateTime<true>;
  private readonly timeUnit: DurationLike;

  constructor(args: {
    start: DateTime<true>;
    timeUnit?: DateTimeUnit;
    duration?: DurationLike;
  }) {
    this.start = args.start;

    this.timeUnit =
      args.timeUnit != null
        ? { [args.timeUnit]: 1 }
        : args.duration ??
          _throw(
            new IllegalArgumentError(
              "Either timeUnit or duration must be specified"
            )
          );
  }

  run<T>(count: number, fn: (dateTime: DateTime<true>) => T): Array<T> {
    let currCount = 0;
    let currDateTime = this.start;
    const result: Array<T> = [];

    do {
      result.push(fn(currDateTime));
      currDateTime = currDateTime.plus(this.timeUnit);
      currCount++;
    } while (currCount <= count);

    return result;
  }

  runUntilTime<T>(
    untilTime: DateTime<true>,
    fn: (dateTime: DateTime<true>) => T
  ): Array<T> {
    let currDateTime = this.start;
    const result: Array<T> = [];

    do {
      result.push(fn(currDateTime));
      currDateTime = currDateTime.plus(this.timeUnit);
    } while (currDateTime < untilTime);

    return result;
  }

  runWhile<T>(
    predicate: (dateTime: DateTime<true>) => boolean,
    fn: (dateTime: DateTime<true>) => T
  ): Array<T> {
    let currDateTime = this.start;
    const result: Array<T> = [];

    do {
      result.push(fn(currDateTime));
      currDateTime = currDateTime.plus(this.timeUnit);
    } while (predicate(currDateTime));

    return result;
  }
}
