import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { Map, MapProps } from "@vis.gl/react-google-maps";
import { MapLoadingProgress } from "./MapLoadingProgress";

/**
 * Google Maps component does not support changing mapId.
 * This one does.
 */
export const MapWithDynamicMapId: FC<PropsWithChildren<MapProps>> = (props) => {
  const [state, setState] = useState<"init" | "mount" | "unmount">("init");
  const [previousMapId, setPreviousMapId] = useState<string | null | undefined>(
    props.mapId
  );

  useEffect(() => {
    if (state === "init" && previousMapId !== props.mapId) {
      setState("unmount");
    } else if (state === "unmount") {
      setState("mount");
      setPreviousMapId(props.mapId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapId, state]);

  return state === "init" || state === "mount" ? (
    <Map {...props} />
  ) : (
    <MapLoadingProgress />
  );
};
