import { ChannelDto, ContactDto } from "@airmont/firefly/shared/ts/domain";

export const getChannelMocks = (input: { contacts: Array<ContactDto>}) : Array<ChannelDto> => {


  return [
    {
      id: "123",
      name: "fire",
      type: "sms",
      contacts: input.contacts
    }
  ]
}
