import { FC, ReactNode } from "react";
import { User, UserContext } from "shared-ts-utils-authentication";

export interface OidcUserProviderProps {
  user: User;
  children: ReactNode;
}

export const OidcUserProvider: FC<OidcUserProviderProps> = (props) => {
  return (
    <UserContext.Provider value={props.user}>
      {props.children}
    </UserContext.Provider>
  );
};
