import { ResolutionType } from "@airmont/shared/ts/types";

export class ResolutionUtils {

    static expandResolution(resolution: ResolutionType) {
        if (resolution === ResolutionType.Year) {
            return ResolutionType.Month;
        } else if (resolution === ResolutionType.Month) {
            return ResolutionType.Day;
        } else if (resolution === ResolutionType.Week) {
            return ResolutionType.Day;
        } else if (resolution === ResolutionType.Day) {
            return ResolutionType.Hour;
        } else {
            throw new Error("Cannot expand resolution: " + resolution)
        }
    }
}
