import React, { FC, ReactNode, useState } from "react";
import { RenderEventParams } from "../RenderEventParams";
import { Event } from "@airmont/shared-ts-event";
import { Stack, useTheme } from "@mui/material";
import { DateTime, Duration, Interval } from "luxon";
import { useTimeslots } from "./useTimeslots";
import { useResizeDetector } from "react-resize-detector";
import { useCalendarSettings } from "../CalendarSettingsProvider";
import { TimeslotEvent } from "./TimeslotEvent";
import { TimeslotLine } from "./TimeslotLine";
import { Timeslot } from "./Timeslot";

export interface DayTimeslotsProps {
  date: DateTime<true>;
  dateTimeNow: DateTime<true>;
  timeslotDuration: Duration;
  isToday?: boolean;
  events: Array<Event>;
  renderEvent: (params: RenderEventParams) => ReactNode;
  onNewEvent?: (interval: Interval) => void;
}

export const DayTimeslots: FC<DayTimeslotsProps> = (props) => {
  const { date, dateTimeNow, timeslotDuration, isToday, events, renderEvent } =
    props;
  const isWeekend = date.isWeekend;
  const theme = useTheme();
  const calendarSettings = useCalendarSettings();
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    handleWidth: true,
  });
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [selection, setSelection] = useState<Array<DateTime<true>>>([]);
  const timeslots = useTimeslots(timeslotDuration, date);

  const handleNewEvent = () => {
    if (selection.length > 0) {
      const earliestDate = selection.reduce<DateTime<true> | null>((a, b) => {
        return a == null ? b : a < b ? a : b;
      }, null);
      const latestDate = selection.reduce<DateTime<true> | null>((a, b) => {
        return a == null ? b : a > b ? a : b;
      }, null);
      if (latestDate != null && earliestDate != null) {
        const interval = Interval.fromDateTimes(earliestDate, latestDate);
        props.onNewEvent?.(interval);
      }
    }
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setSelection([]);
  };

  return (
    <Stack
      ref={ref}
      className={"DayTimeslots"}
      direction={"column"}
      sx={{
        position: "relative",
        backgroundColor: isWeekend
          ? calendarSettings.weekendBackgroundColor
          : undefined,
        borderRadius: 0,
        flexGrow: 1,
        minHeight: 0,
        ".Timeslot": {
          borderBottom: "1px solid " + theme.palette.divider,
        },
      }}
      onMouseLeave={handleMouseLeave}
    >
      <Timeslot height={calendarSettings.timeslotsTopGutterHeight} key={-1} />
      {timeslots.map((timeslot, index) => {
        const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
          setMouseDown(true);
          setSelection([timeslot]);
        };

        const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
          if (mouseDown) {
            setSelection((prevState) => [...prevState, timeslot]);
          }
        };

        const handleMouseUp = (event: React.MouseEvent<HTMLElement>) => {
          setMouseDown(false);
          handleNewEvent();
          setSelection([]);
        };

        const isSelected = selection.some((it) => it.equals(timeslot));

        return (
          <Timeslot
            key={timeslot.toMillis()}
            timeslot={timeslot}
            height={calendarSettings.timeslotHeight}
            selected={isSelected}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseEnter={handleMouseEnter}
          />
        );
      })}
      {events.map((event) => {
        return (
          <TimeslotEvent
            key={event.id}
            event={event}
            width={width}
            renderEvent={renderEvent}
          />
        );
      })}
      {isToday && (
        <TimeslotLine
          dateTime={dateTimeNow}
          color={calendarSettings.nowColor}
          width={width}
        />
      )}
    </Stack>
  );
};
