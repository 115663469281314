import { MessageEntry } from "./MessageEntry";
import { NotifyWarningParams } from "./NotifyWarningParams";
import { DateTime } from "luxon";
import { NotifyErrorParams } from "./NotifyErrorParams";
import { NotifyInfoParams } from "./NotifyInfoParams";
import { NotifySuccessParams } from "./NotifySuccessParams";

export class MessageEntryFactory{

  static createError(params: NotifyErrorParams) :MessageEntry {

    let resolvedError: Error | undefined;
    let resolvedMessage = "";

    if (params.error != null) {
      resolvedError = params.error as Error;
      resolvedMessage = resolvedError.message;
    }

    if(params.message != null) {
      resolvedMessage = params.message;
    }

    return {
      time: DateTime.local(),
      read: false,
      notification: {
        error: params.error as Error,
        text: resolvedMessage,
        severity: "error"
      },
      options: params.options ?? {},
    }
  }

  static createWarning(params: NotifyWarningParams) :MessageEntry {

    let resolvedError: Error | undefined;
    let resolvedMessage = "";

    if (params.error != null) {
      resolvedError = params.error as Error;
      resolvedMessage = resolvedError.message;
    }
    if(params.message != null) {
      resolvedMessage = params.message;
    }

    return {
      time: DateTime.local(),
      read: false,
      notification: {
        text: resolvedMessage,
        severity: "warning"
      },
      options: params.options ?? {},
    }
  }

  static createInfo(params: NotifyInfoParams) :MessageEntry {

    let resolvedMessage = "";

    if(params.message != null) {
      resolvedMessage = params.message;
    }

    return {
      time: DateTime.local(),
      read: false,
      notification: {
        text: resolvedMessage,
        severity: "info"
      },
      options: params.options ?? {},
    }
  }

  static createSuccess(params: NotifySuccessParams) :MessageEntry {

    let resolvedMessage = "";

    if(params.message != null) {
      resolvedMessage = params.message;
    }

    return {
      time: DateTime.local(),
      read: false,
      notification: {
        text: resolvedMessage,
        severity: "success"
      },
      options: params.options ?? {},
    }
  }
}
