import React, { FC } from "react";
import { FormControl, FormHelperText, FormLabel, Tooltip } from "@mui/material";
import {
  ChimneySensorCableStatusEnum,
  ChimneySensorCableStatusSelect,
  useFireflySharedTsDomainTranslation,
} from "@airmont/firefly/shared/ts/domain";
import { FormControl_size } from "shared-ts-mui";

export interface ChimneySensorCableStatusFilterControlProps {
  selected: ChimneySensorCableStatusEnum | null | undefined;
  onChange: (value: ChimneySensorCableStatusEnum | null) => void;
  size?: FormControl_size;
}

export const ChimneySensorCableStatusFilterControl: FC<
  ChimneySensorCableStatusFilterControlProps
> = (props) => {
  const { t } = useFireflySharedTsDomainTranslation();
  return (
    <FormControl size={props.size}>
      <Tooltip title={"Filtrer på sensorkabel-status"}>
        <FormLabel>Kabelstatus</FormLabel>
      </Tooltip>
      <ChimneySensorCableStatusSelect
        noneOption
        displayEmpty
        selected={props.selected}
        onChange={props.onChange}
      />
      {props.selected == null && (
        <FormHelperText>Filtrer på sensorens kabelstatus</FormHelperText>
      )}
      {props.selected != null && (
        <FormHelperText>
          {t(`ChimneySensorCableStatus.description.${props.selected}`)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
