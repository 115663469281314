import React, { FC, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  BurnQualityBarChart,
  BurnQualityPieChart,
} from "@airmont/firefly/shared/ts/domain";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";
import { BurnQualityCountLoaderResult } from "@airmont/firefly/chimney-insights/ts/domain";
import { SxProps } from "@mui/system";
import { useSxMerge } from "shared-ts-mui";

export interface BurnQualityChartCardProps {
  result: BurnQualityCountLoaderResult;
  sx?: SxProps;
}

export const BurnQualityChartCard: FC<BurnQualityChartCardProps> = (props) => {
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const [burnQualityCountType, setBurnQualityCountType] =
    useState<string>("period");

  const showBurnQualityBarChart =
    localStorage.getItem("showBurnQualityBarChart") === "true";

  const result =
    burnQualityCountType === "period"
      ? props.result.period
      : props.result.total;

  const sx = useSxMerge(props.sx, {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  });

  return (
    <Card sx={sx}>
      <CardHeader
        title={tFireflySharedDomain("Ignition Quality")}
        titleTypographyProps={{
          sx: {
            "&&": {
              fontSize: "1rem",
            },
          },
        }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {result.isFetching && result.data != null && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}

        {result.data === undefined && result.isLoading && (
          <Box
            sx={{
              width: `${240}px`,
              height: `${200}px`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ lineHeight: 1 }} />
          </Box>
        )}
        {!showBurnQualityBarChart && (
          <BurnQualityPieChart
            data={result.data}
            isLoading={result.isLoading}
            width={240}
            height={160}
          />
        )}
        {showBurnQualityBarChart && (
          <BurnQualityBarChart
            data={result.data}
            isLoading={result.isLoading}
            width={240}
            height={160}
          />
        )}
        {result.data != null && (
          <Typography>
            {tFireflySharedDomain("Number of Burns")}
            {": "}
            {`${notUndef(result.data, (it) => it.total)}`}
          </Typography>
        )}
        <Box
          sx={{
            width: `100%`,
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            mt: 2,
          }}
        >
          <ToggleButtonGroup
            color="primary"
            size={"small"}
            value={burnQualityCountType}
            exclusive
            onChange={(event, value) => {
              if (value != null) {
                setBurnQualityCountType(value);
              }
            }}
          >
            <ToggleButton value="period">
              {tFireflySharedDomain("Period")}
            </ToggleButton>
            <ToggleButton value="total">
              {tFireflySharedDomain("Total")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </CardContent>
    </Card>
  );
};
