import { useMemo } from "react";
import { DateTime, Duration } from "luxon";

const defaultDate = DateTime.local();
export const useTimeslots = (
  timeslotDuration: Duration,
  date: DateTime = defaultDate
): Array<DateTime> => {
  return useMemo(() => {
    const midnight = date.startOf("day");
    const endOfDay = midnight.plus({ day: 1 });
    let currTime = midnight;
    const hours: Array<DateTime> = [];
    do {
      hours.push(currTime);
      currTime = currTime.plus(timeslotDuration);
    } while (currTime < endOfDay);
    return hours;
  }, [date, timeslotDuration]);
};
