import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import * as React from "react";
import { MouseEvent, SetStateAction } from "react";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import {
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRowPersistedState } from "./GridRowPersistedState";
import { Tooltip } from "@mui/material";
import { TFunction } from "i18next";

export const getGridRowActions = <
  R extends GridRowModel & GridRowPersistedState
>(input: {
  params: GridRowParams;
  disableEdit?: boolean;
  rowModesModel?: GridRowModesModel;
  setRowModesModel?: React.Dispatch<SetStateAction<GridRowModesModel>>;
  rows: Array<R>;
  setRows: React.Dispatch<SetStateAction<Array<R>>>;
  onDelete?: (id: GridRowId) => Promise<void>;
  onSave?: <T>(id: GridRowId) => Promise<T>;
  t: TFunction<"firefly-shared-ts-domain">;
}): React.ReactElement<GridActionsCellItemProps>[] => {
  const { params, rowModesModel, setRowModesModel, rows, setRows, t } = input;
  const isInEditMode =
    rowModesModel === undefined
      ? false
      : rowModesModel[params.id]?.mode === GridRowModes.Edit;
  const disableEdit = input.disableEdit ?? false;

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel?.({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id: GridRowId) => async () => {
    setRowModesModel?.({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleCancelClick = (id: GridRowId) => () => {
    const editedRow = rows.find((row) => row["id"] === id);
    if (editedRow != null && editedRow["isNew"]) {
      setRows(rows.filter((row) => row["id"] !== id));
      setRowModesModel?.((prevState) => {
        const newState = { ...prevState };
        delete newState[id];
        newState[id] = { mode: GridRowModes.View, ignoreModifications: true };
        return newState;
      });
    } else {
      setRowModesModel?.({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    }
  };

  const handleDeleteClick = (id: GridRowId) => async (event: MouseEvent) => {
    event.stopPropagation();
    setRows((prevState) => prevState.filter((row) => row["id"] !== id));
    await input.onDelete?.(id);
  };

  if (isInEditMode) {
    return [
      <Tooltip title={t("Save")}>
        <GridActionsCellItem
          icon={<SaveIcon />}
          label={t("Save")}
          title={t("Save")}
          onClick={handleSaveClick(params.id)}
        />
      </Tooltip>,
      <Tooltip title={t("Cancel")}>
        <GridActionsCellItem
          icon={<CancelIcon />}
          label={t("Cancel")}
          className="textPrimary"
          onClick={handleCancelClick(params.id)}
          color="inherit"
        />
      </Tooltip>,
    ];
  }

  const elementsInViewMode: JSX.Element[] = [];
  if (input.rowModesModel != null && !disableEdit) {
    elementsInViewMode.push(
      <Tooltip title={t("Change")}>
        <GridActionsCellItem
          icon={<EditIcon />}
          label={t("Change")}
          className="textPrimary"
          onClick={handleEditClick(params.id)}
          color="inherit"
        />
      </Tooltip>
    );
  }
  if (input.onDelete != null) {
    elementsInViewMode.push(
      <Tooltip title={t("Delete")}>
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("Delete")}
          onClick={handleDeleteClick(params.id)}
          color="inherit"
        />
      </Tooltip>
    );
  }

  return elementsInViewMode;
};
