import { BarChart } from "@mui/x-charts";
import { SweepCountByPeriod } from "./SweepCountByPeriod";
import { FC, useEffect, useMemo, useState } from "react";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import { SxProps } from "@mui/system";
import { DateTime } from "luxon";
import {
  AxisConfig,
  ChartsXAxisProps,
  ScaleName,
} from "@mui/x-charts/models/axis";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "@tanstack/react-query";
import { HighlightItemData } from "@mui/x-charts/context/HighlightedProvider/HighlightedContext";
import { SummarizingChartsAxisContent } from "shared-ts-mui";

const timeUnit = "year";
type AxisData = {
  dataIndex: number;
  axisValue?: number | Date | string;
  seriesValues: Record<string, number | null | undefined>;
};

export interface SweepCountByYearBarChartProps {
  overdue?: number;
  numberOfYears: number;
  colors: {
    overdue: string;
    repeating: string;
    upcoming: string;
  };
  upcomingSweepCounts: UseQueryResult<Array<SweepCountByPeriod>>;
  repeatingSweepCounts: UseQueryResult<Array<SweepCountByPeriod>>;
  selectedYear: number | null;
  onTimeClick?: (time: DateTime<true>) => void;
  sx?: SxProps;
}

export const SweepCountByYearBarChart: FC<SweepCountByYearBarChartProps> = (
  props
) => {
  const {
    upcomingSweepCounts,
    repeatingSweepCounts,
    numberOfYears,
    colors,
    overdue,
  } = props;
  const { t } = useTranslation("firefly-chimney-insights-ts-domain");
  const loading =
    upcomingSweepCounts.isFetching ||
    upcomingSweepCounts.isFetching ||
    repeatingSweepCounts.isFetching ||
    repeatingSweepCounts.isLoading;
  const xAxisBandData = useMemo(() => {
    const bandData: Array<string | number> = [];
    if (upcomingSweepCounts.data != null) {
      upcomingSweepCounts.data.forEach((it) =>
        bandData.push(it.time.get(timeUnit))
      );
    } else {
      ArrayUtils.createAndFill(numberOfYears, (it) => null);
    }
    return bandData;
  }, [upcomingSweepCounts, numberOfYears]);
  const [highlightedItem, setHighLightedItem] =
    useState<HighlightItemData | null>(null);

  const series: MakeOptional<BarSeriesType, "type">[] = useMemo(() => {
    const defaultSeries: Array<number | null> = [];
    if (upcomingSweepCounts.data != null) {
      upcomingSweepCounts.data.forEach((it) => {
        defaultSeries.push(it.count);
      });
    } else {
      ArrayUtils.createAndFill(numberOfYears, (it) => null);
    }

    const repeatingSeries: Array<number | null> = [];
    if (repeatingSweepCounts.data != null) {
      repeatingSweepCounts.data.forEach((it) => {
        repeatingSeries.push(it.count);
      });
    }

    const overdueSeries: Array<number | null> =
      overdue != null ? [overdue] : [];

    return [
      {
        id: "needForSweep",
        data: defaultSeries,
        stack: "total",
        color: colors.upcoming,
        label: (location) =>
          location === "legend" ? t("Upcoming Sweeps") : t("Upcoming Sweeps"),
        highlightScope: {
          highlight: "item",
          fade: "none",
        },
      } as BarSeriesType,
      {
        id: "repeating",
        data: repeatingSeries,
        stack: "total",
        color: colors.repeating,
        label: (location) =>
          location === "legend" ? t("Repeating Sweeps") : t("Repeating Sweeps"),
        highlightScope: {
          highlight: "item",
          fade: "none",
        },
      } as BarSeriesType,
      {
        id: "overdue",
        data: overdueSeries,
        stack: "total",
        color: colors.overdue,
        label: (location) =>
          location === "legend" ? t("Overdue Sweeps") : t("Overdue Sweeps"),
        highlightScope: {
          highlight: "item",
          fade: "none",
        },
      } as BarSeriesType,
    ];
  }, [
    upcomingSweepCounts.data,
    repeatingSweepCounts.data,
    overdue,
    colors.upcoming,
    colors.repeating,
    colors.overdue,
    numberOfYears,
    t,
  ]);

  useEffect(() => {
    const dataIndex = xAxisBandData.findIndex(
      (it) => it === props.selectedYear
    );
    if (dataIndex !== -1) {
      setHighLightedItem({ seriesId: "needForSweep", dataIndex: dataIndex });
    } else {
      setHighLightedItem(null);
    }
  }, [props.selectedYear, xAxisBandData]);

  const handleAxisClick = (event: MouseEvent, axisData: AxisData | null) => {
    if (
      axisData != null &&
      axisData?.axisValue &&
      typeof axisData?.axisValue === "number"
    ) {
      setHighLightedItem({
        seriesId: "needForSweep",
        dataIndex: axisData.dataIndex,
      });
      const year = axisData.axisValue;
      const item = upcomingSweepCounts.data?.find(
        (it) => it.time.get(timeUnit) === year
      );
      if (item != null) {
        props.onTimeClick?.(item.time);
      }
    }
  };

  const xAxis: Array<
    MakeOptional<AxisConfig<ScaleName, any, ChartsXAxisProps>, "id">
  > = useMemo(() => {
    return [
      {
        label: t("Year"),
        scaleType: "band",
        data: xAxisBandData,
        valueFormatter: yearFormatter,
      },
    ];
  }, [t, xAxisBandData]);

  return (
    <BarChart
      loading={loading}
      slotProps={{ loadingOverlay: { message: t("Loading data ...") } }}
      barLabel={"value"}
      borderRadius={6}
      xAxis={xAxis}
      yAxis={[
        {
          label: t("Flues"),
        },
      ]}
      series={series}
      highlightedItem={highlightedItem}
      onAxisClick={handleAxisClick}
      slots={{
        axisContent: (props) => (
          <SummarizingChartsAxisContent
            {...props}
            hideSeriesWithZeroValue
            noDataMessage={t("No need for sweep")}
          />
        ),
      }}
      sx={props.sx}
    />
  );
};

const yearFormatter = (year: number): string => {
  return year.toString();
};
