import { FC, ReactNode, useEffect, useState } from "react";
import { useOidcAuthenticationService } from "./useOidcAuthenticationService";

export interface AccessTokenProviderProps {
  children: (accessToken: string) => ReactNode;
}

export const OidcAccessTokenProvider: FC<AccessTokenProviderProps> = (
  props
) => {
  const authService = useOidcAuthenticationService();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    authService.getUser().then((user) => {
      setAccessToken(user?.access_token);
    });
  }, [authService]);

  return <>{props.children(accessToken ?? "")}</>;
};
