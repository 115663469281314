import React from "react";
import { alpha, Box, CircularProgress, Typography } from "@mui/material";
import { ResolutionType } from "@airmont/shared/ts/types";
import { DateTime, Duration } from "luxon";
import {
  NumberTimeSeriesImpl,
  ProcessingType,
  TimeSeries,
} from "@airmont/firefly/shared/ts/timeseries";
import {
  IllegalArgumentError,
  MathUtils,
  notNull,
} from "@airmont/shared/ts/utils/core";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import {
  TimeSeriesLoader,
  TimeSeriesQueryImpl,
} from "@airmont/firefly/shared/ts/timeseries-dao";
import {
  TimeAxisValueFormatterFactory,
  TimeSeriesMuiChart,
} from "@airmont/firefly/shared/ts/timeseries-mui-chart";
import { MuiChartContainer, XAxisDefaultConfig } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import { teal } from "@mui/material/colors";

const seriesColor = alpha(teal[500], 0.8);

export interface PeriodBarChart2Props {
  displayName?: string;
  timeSeriesName: string;
  from: DateTime;
  to: DateTime;
  resolution: ResolutionType;
  owner?: string;
  unit?: string;
  municipality?: string;
  valueMapper?: (value: number | null) => number | null;
  valueFormatter?: (value: number | null) => string;
  yAxisLabel: string;
  yAxisMinimumMax?: number;
  layout?: SizeClass;
  noDataText: string;
}

export const PeriodBarChart = (props: PeriodBarChart2Props) => {
  const { t } = useTranslation("firefly-shared-ts-domain");
  const xAxisEndTime = resolveXAxisEndTime(props.to, props.resolution);
  return (
    <Box
      sx={{
        flexGrow: 1,
        minWidth: 0,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <TimeSeriesLoader<number>
        query={
          new TimeSeriesQueryImpl({
            start: props.from,
            end: props.to,
            name: props.timeSeriesName,
            sid: props.owner,
            municipality: props.municipality,
            sourceResolution: resolutionToDuration(props.resolution),
            processing: ProcessingType.ReplaceMissingValuesWithZeros,
          })
        }
        timeSeriesFactory={(dto) =>
          NumberTimeSeriesImpl.fromNumberTimeSeriesDto({
            ...dto,
            info: {
              ...dto.info,
              unit: props.unit != null ? props.unit : dto.info.unit,
            },
          })
        }
      >
        {({ timeSeries, isLoading }) => {
          return (
            <>
              {isLoading && (
                <Box
                  sx={{
                    flexGrow: 1,
                    minWidth: 0,
                    minHeight: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {timeSeries !== undefined && timeSeries.length === 0 && (
                <Box
                  sx={{
                    flexGrow: 1,
                    minWidth: 0,
                    minHeight: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>{props.noDataText}</Typography>
                </Box>
              )}
              {timeSeries !== undefined && timeSeries.length > 0 && (
                <MuiChartContainer
                  sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
                >
                  <TimeSeriesMuiChart
                    timeSeries={timeSeries as Array<TimeSeries<number | null>>}
                    timeSeriesOptions={[
                      {
                        type: "bar",
                        label: props.displayName,
                        color: seriesColor,
                        valueFormatter: (value, context) =>
                          notNull(value, (it) =>
                            props.valueFormatter != null
                              ? props.valueFormatter(value)
                              : MathUtils.round(it).toString()
                          ) ?? "",
                      },
                    ]}
                    xAxisConfig={resolveXAxisConfig(
                      props.resolution,
                      xAxisEndTime,
                      t
                    )}
                    yAxisConfig={[
                      {
                        label: props.yAxisLabel,
                      },
                    ]}
                    yAxisLabelOffset={{ x: -20 }}
                    slotProps={{
                      BarPlotProps: {
                        borderRadius:
                          props.resolution === ResolutionType.Year ||
                          props.resolution === ResolutionType.Month
                            ? 6
                            : 4,
                      },
                    }}
                    margin={{
                      left: 80,
                    }}
                    sx={{
                      flexGrow: 1,
                      minWidth: 0,
                      minHeight: 0,
                    }}
                  />
                </MuiChartContainer>
              )}
            </>
          );
        }}
      </TimeSeriesLoader>
    </Box>
  );
};

const resolutionToDuration = (resolution: ResolutionType): Duration => {
  if (resolution === ResolutionType.Second) {
    return Duration.fromObject({ second: 1 });
  } else if (resolution === ResolutionType.Minute) {
    return Duration.fromObject({ minute: 1 });
  } else if (resolution === ResolutionType.Hour) {
    return Duration.fromObject({ hour: 1 });
  } else if (resolution === ResolutionType.Day) {
    return Duration.fromObject({ day: 1 });
  } else if (resolution === ResolutionType.Month) {
    return Duration.fromObject({ month: 1 });
  } else if (resolution === ResolutionType.Week) {
    return Duration.fromObject({ week: 1 });
  } else if (resolution === ResolutionType.Year) {
    return Duration.fromObject({ year: 1 });
  } else {
    throw new IllegalArgumentError("resolution not supported: " + resolution);
  }
};

const resolveXAxisEndTime = (
  to: DateTime,
  resolution: ResolutionType
): DateTime => {
  if (resolution === ResolutionType.Month) {
    return to
      .minus({ days: 1 })
      .endOf("year")
      .plus({ millisecond: 1 })
      .minus({ days: 1 });
  } else if (resolution === ResolutionType.Day) {
    return to
      .minus({ days: 1 })
      .endOf("month")
      .plus({ millisecond: 1 })
      .minus({ days: 1 });
  } else {
    throw new IllegalArgumentError("resolution not supported: " + resolution);
  }
};

const resolveXAxisConfig = (
  resolution: ResolutionType,
  to: DateTime,
  t: TFunction<"firefly-shared-ts-domain", unknown>
): XAxisDefaultConfig => {
  return {
    label:
      resolution === ResolutionType.Day ? t("Day of the month") : t("Month"),
    max: to.toJSDate(),
    valueFormatter: TimeAxisValueFormatterFactory.create({
      formatTokens: resolution === ResolutionType.Day ? "dd" : "MMM",
      formatOptionsByLocation: {
        tooltip:
          resolution === ResolutionType.Day ? DateTime.DATE_SHORT : undefined,
      },
    }),
  };
};
