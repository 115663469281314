import React, { FC, useMemo, useState } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Stack, useTheme } from "@mui/material";
import { ChimneyFireSettingsPane } from "./chimneyFireSettings/ChimneyFireSettingsPane";
import { Action } from "@airmont/shared/ts/ui/action";
import LocalFireDepartmentRoundedIcon from "@mui/icons-material/LocalFireDepartmentRounded";
import ContactsRoundedIcon from "@mui/icons-material/ContactsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { ContactsSettingsPane } from "./contactsSettings/ContactsSettingsPane";
import { SettingsPane } from "./SettingsPane";
import { SettingsConfig } from "./SettingsConfig";
import { NoSettingsChosenPane } from "./NoSettingsChosenPane";
import { SizeClass, useWindowSize } from "@airmont/shared/ts/ui/responsive";
import {
  NavigationDrawer,
  NavigationItemObject,
  NavigationRail,
} from "@airmont/shared/ts/ui/navigation";
import { GeneralSettingsPane } from "./generalSettings/GeneralSettingsPane";
import { useTranslation } from "react-i18next";

export const SettingsPage: FC = () => {
  const { t } = useTranslation("firefly-chimney-insights-ts-settings");
  const theme = useTheme();
  const { windowWidth } = useWindowSize();
  const [selected, _setSelected] = useState<string | null>(
    localStorage.getItem("chimney-insights:SettingsPage:selected")
  );

  const setSelected: React.Dispatch<string | null> = (value) => {
    _setSelected(value);
    if (value == null) {
      localStorage.removeItem("chimney-insights:SettingsPage:selected");
    } else {
      localStorage.setItem("chimney-insights:SettingsPage:selected", value);
    }
  };
  const settings: SettingsConfig[] = useMemo(() => {
    return [
      {
        key: "general",
        name: t("Generally"),
        icon: <SettingsRoundedIcon />,
        component: <GeneralSettingsPane />,
      },
      {
        key: "contacts",
        name: t("Contacts"),
        icon: <ContactsRoundedIcon />,
        component: <ContactsSettingsPane />,
      },
      {
        key: "chimneyFire",
        name: t("Temperature Alarms"),
        icon: <LocalFireDepartmentRoundedIcon />,
        component: <ChimneyFireSettingsPane />,
      },
    ];
  }, []);

  const menuItems: Array<NavigationItemObject> = useMemo(
    () =>
      settings.map((setting) => {
        return {
          type: "Action",
          name: setting.name,
          reference: setting.key,
          icon: setting.icon,
          onExecute: (action) => {
            setSelected(action.reference ?? null);
          },
          selectable: true,
          selected: selected === setting.key,
          preferences: {
            hideSelectionIcon: true,
          },
        } as Action;
      }),
    [settings, selected]
  );

  const selectedSettingsConfig = settings.find((it) => it.key === selected);

  const navigationType = resolveNavigationType(windowWidth);

  return (
    <AppPage
      name={"Settings"}
      hideName
      mainPane={
        <Stack direction={"row"} sx={{ flex: 1 }}>
          <>
            {navigationType === "rail" && (
              <NavigationRail
                items={menuItems}
                className={"navigation"}
                style={{ width: 80, margin: theme.spacing(1) }}
              />
            )}
            {navigationType === "drawer" && (
              <NavigationDrawer
                items={menuItems}
                className={"navigation"}
                style={{
                  minWidth: 300,
                  margin: theme.spacing(1),
                  padding: theme.spacing(1),
                }}
              />
            )}
          </>
          {selectedSettingsConfig != null ? (
            <SettingsPane name={selectedSettingsConfig.name}>
              {selectedSettingsConfig.component}
            </SettingsPane>
          ) : (
            <NoSettingsChosenPane />
          )}
        </Stack>
      }
    />
  );
};

type NavigationType = "drawer" | "rail";

const resolveNavigationType = (breakpoint: SizeClass): NavigationType => {
  if (breakpoint === SizeClass.Compact) {
    return "rail";
  } else if (breakpoint === SizeClass.Medium) {
    return "rail";
  } else if (breakpoint === SizeClass.Expanded) {
    return "rail";
  } else if (breakpoint === SizeClass.ExtraLarge) {
    return "drawer";
  }

  return "rail";
};
