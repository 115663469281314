import React, { cloneElement, FC, ReactElement } from "react";
import { Box, LinearProgress, SxProps } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";
import { useSxMerge } from "../sx/useSxMerge";

export interface MuiChartContainer {
  children: ReactElement<{ width: number; height: number }> | false;
  loading?: boolean;
  sx?: SxProps;
}

export const MuiChartContainer: FC<MuiChartContainer> = (props) => {
  const { ref, width, height } = useResizeDetector();
  const loading = props.loading ?? false;
  const sx = useSxMerge(props.sx, {
    position: "relative",
    "> *": {
      minHeight:
        props.sx != null && "minHeight" in props.sx
          ? props.sx.minHeight
          : undefined,
    } as SxProps,
  } as SxProps);
  return (
    <Box className={"MuiChartContainer"} ref={ref} sx={sx}>
      <>
        {loading && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
        {props.children !== false &&
          cloneElement(props.children, { width: width, height: height })}
      </>
    </Box>
  );
};
