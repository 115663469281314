import { Expr } from "../../Expr";

export interface IsAnyOfExpr {
  $type: "isAnyOf";
  instance: Expr;
  args: Array<Expr>;
}

export const isAnyOfExpr = (
  instance: Expr,
  args: Array<Expr>
): IsAnyOfExpr => ({
  $type: "isAnyOf",
  instance: instance,
  args: args,
});
