import React, { FC, ReactNode, useState } from "react";
import { Button } from "../button/Button";
import { ButtonProps } from "@mui/material/Button/Button";

export interface MenuStartButtonProps {
  label?: string;
  ButtonProps?: ButtonProps;
  menu: (
    open: boolean,
    anchorEl: HTMLElement | null,
    onClose: () => void,
    setDisabled: (disabled: boolean) => void
  ) => ReactNode;
}

export const MenuStartButton: FC<MenuStartButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [disabled, setDisabled] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetDisabled = (value: boolean) => {
    if (disabled && !value) {
      setDisabled(value);
    } else if (!disabled && value) {
      setDisabled(value);
    }
  };

  return (
    <>
      <Button {...props.ButtonProps} disabled={disabled} onClick={handleClick}>
        {props.label}
      </Button>
      {props.menu(open, anchorEl, handleClose, handleSetDisabled)}
    </>
  );
};
