import {
  DataGridPremium,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { LinearProgress } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import { useEffect, useState } from "react";
import { DataGridFooterWithToolbar, useMuiLocalization } from "shared-ts-mui";
import {
  FlueStatusDto,
  FlueStatusQueryDto,
  useFlueStatusQueryClient,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { useFlueStatusTableColumns } from "./useFlueStatusTableColumns";
import { useQueryAndDisplayRowsProvider } from "shared/ts/ui/x-data-grid";
import { FlueId } from "@airmont/firefly/shared/ts/domain";

export interface FlueStatusTableProps {
  query: FlueStatusQueryDto;
  forceRefresh: number;
  rows?: Array<FlueStatusDto>;
  onRowsChange: (rows: Array<FlueStatusDto>) => void;
  onSelectedRow: (rowId: FlueId | undefined) => void;
  onEditRequested: (sensor: FlueStatusDto) => void;
  onViewRequested: (sensor: FlueStatusDto) => void;
}

export const FlueStatusTable = (props: FlueStatusTableProps) => {
  const { muiLocalization } = useMuiLocalization();
  const [scrollEndThreshold] = useState<number>(80);
  const queryClient = useFlueStatusQueryClient();
  const {
    apiRef,
    rows,
    totalRows,
    loading,
    selectionModel: [selectionModel],
    sortModel: [sortModel],
    columnVisibilityModel,
    onRowSelectionModelChange,
    onRowsScrollEnd: handleRowsScrollEnd,
    onSortModelChange: handleSortModelChange,
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
  } = useQueryAndDisplayRowsProvider<FlueStatusDto>({
    tableId: "FlueStatusTable",
    query: props.query,
    queryClient: queryClient,
    initialPageSize: 1000,
    rows: props.rows,
    forceRefresh: props.forceRefresh,
    defaultColumnVisibilityModel: {
      "streetAddress.houseSection": false,
      "postalAddress.place": false,
      "chimney.hat": false,
      "flue.height": false,
      "flue.type": true,
      "fireplace.catalytic": true,
      "flue.lastSweep": false,
      "flue.lastChimneyFire": false,
      "flue.chimneyFireThreshold": false,
      "sensorFields.status": false,
      "sensorFields.cableStatus": false,
      comment: false,
      tags: false,
      "metricValuesSinceSweep.temperatureMax": false,
      "audit.created": false,
      "audit.createdBy": false,
      "audit.modified": false,
      "audit.modifiedBy": false,
    },
  });

  const columns = useFlueStatusTableColumns({
    onViewOnClick: (params) => handleViewOnClick(params),
    onEditOnClick: (params) => handleEditOnClick(params),
  });

  useEffect(() => {
    props.onRowsChange(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleViewOnClick = (params: GridRowParams<FlueStatusDto>) => {
    props.onViewRequested(params.row);
  };

  const handleEditOnClick = (params: GridRowParams<FlueStatusDto>) => {
    props.onEditRequested(params.row);
  };

  const handleRowSelectionModelChange = (
    selectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => {
    onRowSelectionModelChange(selectionModel);
    if (props.onSelectedRow) {
      if (selectionModel.length === 1) {
        const rowId = selectionModel[0];
        props.onSelectedRow(rowId as FlueId);
      } else {
        props.onSelectedRow(undefined);
      }
    }
  };

  return (
    <DataGridPremium
      apiRef={apiRef}
      localeText={
        muiLocalization.components.MuiDataGrid.defaultProps.localeText
      }
      initialState={{
        pinnedColumns: {
          left: ["streetAddress", "flue.name"],
          right: ["actions"],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      slots={{
        loadingOverlay: LinearProgress as never,
        footer: DataGridFooterWithToolbar,
      }}
      slotProps={{
        footer: {
          rowCount: rows.length,
          totalRowCount: totalRows,
          loading: loading,
        },
      }}
      hideFooterPagination
      disableColumnFilter
      disableRowGrouping
      disableAggregation
      columns={columns}
      loading={loading}
      rows={rows}
      rowCount={totalRows}
      rowSelectionModel={selectionModel}
      sortingMode="server"
      sortModel={sortModel}
      scrollEndThreshold={scrollEndThreshold}
      onRowsScrollEnd={handleRowsScrollEnd}
      onSortModelChange={handleSortModelChange}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
    />
  );
};
