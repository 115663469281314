import { useMemo } from "react";
import { BurnId } from "../../Burn";
import { BurnTemperatureSeriesNonSensitive } from "../../BurnTemperatureSeriesNonSensitive";
import { ApexOptions } from "apexcharts";
import { ArrayElement } from "@airmont/shared/ts/types";
import { BurnNonSensitiveUi } from "../../BurnNonSensitiveUi";
import { alpha } from "@mui/material";

type ApexSeriesData = ArrayElement<ApexAxisChartSeries>["data"];

export const useApexSeries = (args: {
  burnTemperatureSeries: Array<BurnTemperatureSeriesNonSensitive>;
  burnById: Record<BurnId, BurnNonSensitiveUi>;
  hoveredBurn?: BurnId;
}): { series: ApexOptions["series"]; maxY: number } => {
  const { series, maxY } = useMemo(() => {
    let maxY = 0;
    const array: Array<ApexSeriesData> = args.burnTemperatureSeries.map(
      (it, burnIndex) => {
        const data: ApexSeriesData = it.dataPoints.map((dp) => {
          if (dp.value != null && dp.value > maxY) {
            maxY = dp.value;
          }
          return {
            x: dp.duration.toMillis(),
            y: dp.value,
          };
        });

        return data;
      }
    );
    return { series: array, maxY: maxY };
  }, [args.burnTemperatureSeries]);

  return useMemo(() => {
    const array: ApexOptions["series"] = args.burnTemperatureSeries.map(
      (it, burnIndex) => {
        const burn = args.burnById[it.burnId];
        let color = burn.color;
        if (args.hoveredBurn != null && args.hoveredBurn !== it.burnId) {
          color = alpha(color, 0.3);
        }

        return {
          type: "line",
          name: `Burn #${burn.orderOfOccurence}`,
          color: color,
          data: series[burnIndex],
        };
      }
    );
    return { series: array, maxY: maxY };
  }, [
    args.burnTemperatureSeries,
    args.burnById,
    args.hoveredBurn,
    series,
    maxY,
  ]);
};
