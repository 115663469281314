import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";
import { XAxisDefaultConfig } from "shared-ts-mui";
import { TimeAxisScaleType } from "./TimeAxisScaleType";
import { resolveTimeAxisData } from "./resolveTimeAxisData";

const id = "time";

export const resolveXAxisConfig = (
  timeSeries: Array<TimeSeries<number | null>>,
  axisConfig: XAxisDefaultConfig | undefined,
  timeAxisScaleType: TimeAxisScaleType
): XAxisDefaultConfig[] => {
  if (timeSeries.length === 0) {
    return [
      {
        id: id,
        scaleType: timeAxisScaleType,
      } as XAxisDefaultConfig,
    ];
  }

  const axisData = resolveTimeAxisData(timeSeries, axisConfig);

  if (axisConfig == null) {
    return [
      {
        id: id,
        scaleType: timeAxisScaleType,
        data: axisData,
      } as XAxisDefaultConfig,
    ];
  }

  return [
    {
      ...axisConfig,
      id: id,
      scaleType: timeAxisScaleType,
      data: axisData,
    } as XAxisDefaultConfig,
  ];
};
