import i18next from "i18next";
import { Settings } from "luxon";
import { useLanguageUserSetting } from "./useLanguageUserSetting";

export interface UseLanguageInternalReturnType {
  language: string;
  setLanguage: (language: string) => void;
}

export const useLanguageInternal = (
  defaultLanguage?: string
): UseLanguageInternalReturnType => {
  const [language, _setLanguage] = useLanguageUserSetting(defaultLanguage);

  const setLanguage = (value: string) => {
    _setLanguage(value);
    i18next.changeLanguage(value);
    Settings.defaultLocale = value;
  };

  return {
    language: language,
    setLanguage: setLanguage,
  };
};
