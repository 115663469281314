import {
  DataGridPremium,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { FC, useMemo, useState } from "react";
import {
  pathValueGetter,
  useQueryAndDisplayRowsProvider,
} from "shared/ts/ui/x-data-grid";
import {
  FluesWithAbnormalBurnsQueryDto,
  FlueWithAbnormalBurnsDto,
  useFlueWithTypeDeviationQueryClient,
} from "@airmont/firefly/chimney-insights/ts/domain";
import {
  BurnId,
  FlueId,
  StreetAddress,
} from "@airmont/firefly/shared/ts/domain";
import { DataGridFooterWithToolbar, useMuiLocalization } from "shared-ts-mui";
import { LinearProgress } from "@mui/material";
import {
  _throw,
  IllegalStateError,
  MathUtils,
  notUndef,
} from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";

export interface FluesWithAbnormalBurnsTableProps {
  query: FluesWithAbnormalBurnsQueryDto;
  forceRefresh: number;
  rows?: Array<FlueWithAbnormalBurnsDto>;
  onRowsChange: (rows: Array<FlueWithAbnormalBurnsDto>) => void;
  onSelectedRow?: (rowId: FlueId | undefined) => void;
  onSelectedBurnRows?: (rowId: Array<BurnId>) => void;
  onViewRequested?: (row: FlueWithAbnormalBurnsDto) => void;
}

export const FluesWithAbnormalBurnsTable: FC<
  FluesWithAbnormalBurnsTableProps
> = (props) => {
  const { muiLocalization } = useMuiLocalization();
  const columns = useTableColumns();
  const queryClient = useFlueWithTypeDeviationQueryClient();
  const [scrollEndThreshold] = useState<number>(80);
  const {
    apiRef,
    rows,
    totalRows,
    loading,
    selectionModel: [selectionModel],
    sortModel: [sortModel],
    columnVisibilityModel,
    onRowSelectionModelChange,
    onRowsScrollEnd: handleRowsScrollEnd,
    onSortModelChange: handleSortModelChange,
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
  } = useQueryAndDisplayRowsProvider<FlueWithAbnormalBurnsDto>({
    tableId: "FluesWithAbnormalBurnsTable",
    query: props.query,
    queryClient: queryClient,
    rows: props.rows,
    forceRefresh: props.forceRefresh,
    defaultColumnVisibilityModel: {
      "flueMetricValuesSinceSweep.burnHourCount": false,
      "flueMetricValuesSinceSweep.chimneyFireCount": false,
      "flueTypeDeviationAggregate.overallAverageConfidence": false,
    },
  });

  const handleRowSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    onRowSelectionModelChange(selectionModel);
    if (props.onSelectedRow) {
      if (selectionModel.length === 1) {
        const rowId = selectionModel[0];
        props.onSelectedRow(rowId as BurnId);
      } else {
        props.onSelectedRow(undefined);
      }
    }

    if (props.onViewRequested) {
      if (selectionModel.length === 1) {
        const rowId = selectionModel[0];
        const row =
          rows.find((row) => row.id === rowId) ??
          _throw(new IllegalStateError("Unable to find row with id: " + rowId));
        props.onViewRequested(row);
      }
    }
  };

  return (
    <DataGridPremium
      localeText={
        muiLocalization.components.MuiDataGrid.defaultProps.localeText
      }
      initialState={{
        pinnedColumns: {
          left: ["flue"],
        },
      }}
      apiRef={apiRef}
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      columnGroupingModel={[
        {
          groupId: "Røykløp",
          children: [
            { field: "flue.flue.type" },
            { field: "burnCount" },
            { field: "burnAverageMaxTemp" },
            { field: "burnMaxTemp" },
          ],
        },
        {
          groupId: "Nøkkeltall siden siste feiing",
          children: [
            { field: "flueMetricValuesSinceSweep.burnCount" },
            { field: "flueMetricValuesSinceSweep.burnHourCount" },
            { field: "flueMetricValuesSinceSweep.sootIndex" },
            { field: "flueMetricValuesSinceSweep.chimneyFireCount" },
            { field: "flueMetricValuesSinceSweep.temperatureMax" },
          ],
        },
        {
          groupId: "Anslått røykløpstype",
          children: [
            { field: "flueTypeDeviationAggregate.count" },
            { field: "flueTypeDeviationAggregate.percentage" },
            { field: "flueTypeDeviationAggregate.averageConfidence" },
            { field: "flueTypeDeviationAggregate.overallAverageConfidence" },
          ],
        },
        {
          groupId: "Temperatursvingninger",
          children: [
            { field: "deltaTemperatureAggregate.max" },
            { field: "deltaTemperatureAggregate.minuteMax" },
          ],
        },
      ]}
      loading={loading}
      rowCount={totalRows}
      rows={rows}
      sortModel={sortModel}
      scrollEndThreshold={scrollEndThreshold}
      onRowsScrollEnd={handleRowsScrollEnd}
      onSortModelChange={handleSortModelChange}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      slots={{
        loadingOverlay: LinearProgress as never,
        footer: DataGridFooterWithToolbar,
      }}
      slotProps={{
        footer: {
          rowCount: rows.length,
          totalRowCount: totalRows,
          loading: loading,
        },
      }}
    />
  );
};

const useTableColumns = (): Array<GridColDef<FlueWithAbnormalBurnsDto>> => {
  const { t: tDomain } = useTranslation("firefly-shared-ts-domain");
  return useMemo(() => {
    const columns: Array<GridColDef<FlueWithAbnormalBurnsDto>> = [
      {
        field: "flue",
        headerName: tDomain("Flue"),
        pinnable: false,
        valueGetter: (value, row) => {
          return new StreetAddress(row.flue.streetAddress).toString();
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "flue.flue.type",
        headerName: tDomain("Flue Type"),
        pinnable: false,
        valueGetter: (value, row) => {
          return tDomain(`FlueType.${row.flue.flue.type}`);
        },
        type: "string",
      },
      {
        field: "burnCount",
        headerName: `Fyringer`,
        pinnable: false,
        type: "number",
      },
      {
        field: "burnAverageMaxTemp",
        headerName: tDomain("Avg. Max Temp."),
        pinnable: false,
        type: "number",
        valueGetter: (value, row) => MathUtils.round(row.burnAverageMaxTemp),
        valueFormatter: temperatureValueFormatter,
      },
      {
        field: "burnMaxTemp",
        headerName: tDomain("Max Temperature"),
        pinnable: false,
        type: "number",
        valueFormatter: temperatureValueFormatter,
      },
      {
        field: "flueMetricValuesSinceSweep.burnCount",
        headerName: "Fyringer",
        pinnable: false,
        type: "number",
        valueGetter: (value, row) => row.flueMetricValuesSinceSweep.burnCount,
      },
      {
        field: "flueMetricValuesSinceSweep.burnHourCount",
        headerName: "# fyringstimer",
        pinnable: false,
        type: "number",
        valueGetter: (value, row) =>
          MathUtils.round(row.flueMetricValuesSinceSweep.burnHourCount),
      },
      {
        field: "flueMetricValuesSinceSweep.sootIndex",
        headerName: "Sot-indeks",
        pinnable: false,
        type: "number",
        valueGetter: (value, row) =>
          MathUtils.round(row.flueMetricValuesSinceSweep.sootIndex),
      },
      {
        field: "flueMetricValuesSinceSweep.chimneyFireCount",
        headerName: "# temperaturalarmer",
        pinnable: false,
        type: "number",
        valueGetter: (value, row) =>
          row.flueMetricValuesSinceSweep.chimneyFireCount,
      },
      {
        field: "flueMetricValuesSinceSweep.temperatureMax",
        headerName: "Maks. temp.",
        pinnable: false,
        type: "number",
        valueGetter: (value, row) =>
          row.flueMetricValuesSinceSweep.temperatureMax,
        valueFormatter: temperatureValueFormatter,
      },
      {
        field: "flueTypeDeviationAggregate.count",
        headerName: `Avvik`,
        pinnable: false,
        valueGetter: pathValueGetter,
        type: "number",
      },
      {
        field: "flueTypeDeviationAggregate.percentage",
        headerName: `Avvik i %`,
        pinnable: false,
        type: "number",
        valueGetter: pathValueGetter,
        valueFormatter: (value) => `${value} %`,
      },
      {
        field: "flueTypeDeviationAggregate.averageConfidence",
        headerName: "Gj. treffsikkerhet",
        description: "Gjennomsnittlig treffsikkerhet blant avvik",
        width: 150,
        pinnable: false,
        type: "number",
        valueGetter: (value, row) =>
          notUndef(row.flueTypeDeviationAggregate.averageConfidence, (value) =>
            MathUtils.round(value * 100)
          ),
        valueFormatter: (value) => notUndef(value, (value) => `${value} %`),
      },
      {
        field: "flueTypeDeviationAggregate.overallAverageConfidence",
        headerName: "Gj. treffsikkerhet, totalt",
        description: "Gjennomsnittlig treffsikkerhet, totalt",
        width: 200,
        pinnable: false,
        type: "number",
        valueGetter: (value, row) =>
          notUndef(
            row.flueTypeDeviationAggregate.overallAverageConfidence,
            (value) => MathUtils.round(value * 100)
          ),
        valueFormatter: (value) => notUndef(value, (value) => `${value} %`),
      },
      {
        field: "deltaTemperatureAggregate.max",
        headerName: "Maks. delta temp.",
        width: 200,
        pinnable: false,
        type: "number",
        valueGetter: pathValueGetter,
      },
      {
        field: "deltaTemperatureAggregate.minuteMax",
        headerName: "Maks. delta temp. per min",
        width: 200,
        pinnable: false,
        type: "number",
        valueGetter: (value, row) =>
          notUndef(row.deltaTemperatureAggregate.minuteMax, (value) =>
            MathUtils.round(value, 1)
          ),
      },
    ];
    return columns;
  }, [tDomain]);
};

const temperatureValueFormatter = (
  value: number | undefined
): string | undefined => {
  return value != null ? value + " °C" : undefined;
};
