import { getChannelMocks } from "./channel.mocks";
import { getContactMocks } from "./contact.mocks";
import { setupWorker } from "msw";
import { getMunicipalityMocks } from "./municipality.mocks";
import { MockControllers } from "./MockControllers";
import { MockUser, User } from "shared-ts-utils-authentication";
import { OidcClaims } from "@airmont/firefly/chimney-insights/ts/authentication";
import { getFlueMocks } from "./flue.mocks";
import { getChimneySensorSettingsHistoryMocks } from "./chimneySensorSettingsHistory.mocks";
import { getChimneySensorMocks } from "./chimneySensor.mocks";
import { getSweepingMocks } from "./sweeping.mocks";
import { getBurnMocks } from "./burn.mocks";

export interface DemoBootResult {
  user: User;
}

export const demoUser = new MockUser({
  id: "123",
  email: "jvs@airmont.no",
  name: "Jørund",
  role: "Admin",
  claims: [
    OidcClaims.ReadData,
    OidcClaims.ReadDevices,
    OidcClaims.CreateData,
    OidcClaims.CreateDevices,
  ],
  accessToken: "123",
});

export const bootDemo = async (): Promise<DemoBootResult> => {
  console.info("Booting demo...");
  const contacts = getContactMocks();
  const channels = getChannelMocks({ contacts: contacts });
  const municipalities = getMunicipalityMocks();
  const flues = getFlueMocks();
  const burns = getBurnMocks({
    flues: flues,
  });
  const chimneySensors = getChimneySensorMocks();
  const chimneySensorsSettings = getChimneySensorSettingsHistoryMocks();
  const sweepings = getSweepingMocks({ flues: flues });

  const mockControllers = new MockControllers({
    contacts: contacts,
    channels: channels,
    municipalities: municipalities,
    flues: flues,
    burns: burns,
    chimneySensors: chimneySensors,
    chimneySensorsSettings: chimneySensorsSettings,
    sweepings: sweepings,
  });
  const worker = setupWorker(...mockControllers.getHandlers());
  await worker.start({
    onUnhandledRequest: "bypass",
  });

  return {
    user: demoUser,
  };
};
