import React, { FC } from "react";
import { FormControl, FormHelperText, FormLabel, Tooltip } from "@mui/material";
import {
  ChimneySensorStatusEnum,
  ChimneySensorStatusSelect,
  useFireflySharedTsDomainTranslation,
} from "@airmont/firefly/shared/ts/domain";
import { FormControl_size } from "shared-ts-mui";

export interface ChimneySensorStatusFilterControlProps {
  selected: ChimneySensorStatusEnum | null | undefined;
  onChange: (value: ChimneySensorStatusEnum | null) => void;
  size?: FormControl_size;
}

export const ChimneySensorStatusFilterControl: FC<
  ChimneySensorStatusFilterControlProps
> = (props) => {
  const { t } = useFireflySharedTsDomainTranslation();
  return (
    <FormControl size={props.size}>
      <Tooltip title={"Filtrer på sensor-status"}>
        <FormLabel>Status</FormLabel>
      </Tooltip>
      <ChimneySensorStatusSelect
        noneOption
        displayEmpty
        selected={props.selected}
        onChange={props.onChange}
      />
      {props.selected == null && (
        <FormHelperText>Filtrer på sensorens status</FormHelperText>
      )}
      {props.selected != null && (
        <FormHelperText>
          {t(`ChimneySensorStatus.description.${props.selected}`)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
