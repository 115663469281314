import { FC, ReactNode, useMemo } from "react";
import { SweepCountByPeriod } from "./SweepCountByPeriod";
import { useFlueDao } from "../useFlueDao";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useDateTimeNow, useTimeZoneId } from "@airmont/shared/ts/utils/luxon";
import { DateTime } from "luxon";
import { FlueNeedsSweepDto } from "./FlueNeedForSweep";
import { useCustomerEnvironment } from "@airmont/firefly/shared/ts/customer-environment";
import { RepeatingSweepCountQuery } from "./RepeatingSweepCountQuery";
import { UpcomingSweepCountQuery } from "./UpcomingSweepCountQuery";
import { FluesNeedsSweepQuery } from "./FluesNeedsSweepQuery";
import { useMunicipalityContext } from "../../../municipality/MunicipalityContextProvider";
import { MaxYearsPlanningAhead } from "./MaxYearsPlanningAhead";

export interface SweepCountLoaderResults {
  numberOfYears: number;
  overdueSweepCountResult: UseQueryResult<number>;
  sweepCountAfterPeriodResult: UseQueryResult<number>;
  upcomingSweepCountsByYearResult: UseQueryResult<Array<SweepCountByPeriod>>;
  repeatingSweepCountsByYearResult: UseQueryResult<Array<SweepCountByPeriod>>;
  upcomingSweepCountsByMonthResult?: UseQueryResult<Array<SweepCountByPeriod>>;
  repeatingSweepCountsByMonthResult?: UseQueryResult<Array<SweepCountByPeriod>>;
  fluesNeedsSweepResult?: UseQueryResult<Array<FlueNeedsSweepDto>>;
}

export interface SweepCountLoaderProps {
  selectedYear: number | null;
  selectedMonth: number | null;
  children: (results: SweepCountLoaderResults) => ReactNode;
}

export const SweepCountLoader: FC<SweepCountLoaderProps> = (props) => {
  const { selectedYear, selectedMonth } = props;
  const environment = useCustomerEnvironment();
  const flueDao = useFlueDao();
  const now = useDateTimeNow();
  const { municipality } = useMunicipalityContext();
  const timeZoneId = useTimeZoneId();
  const start = now;
  const end = useMemo(
    () =>
      start
        .startOf("year")
        .plus({ year: MaxYearsPlanningAhead }) as DateTime<true>,
    [start]
  );

  const overdueSweepCount = useQuery({
    queryKey: ["FlueDao.getOverdueSweepCount", environment, municipality, now],
    queryFn: () => flueDao.countOverdueSweeps(now, municipality),
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const sweepCountAfterPeriod = useQuery({
    queryKey: ["FlueDao.countUpcomingSweeps", environment, municipality, end],
    queryFn: () => flueDao.countUpcomingSweeps(end, municipality),
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const upcomingSweepCountsByYearQuery: UpcomingSweepCountQuery = {
    municipalityId: municipality,
    timeZoneId: timeZoneId,
    timeUnit: "year",
    start: start,
    end: end,
  };

  const upcomingSweepCountsByYear = useQuery({
    queryKey: [
      "FlueDao.queryUpcomingSweepCount",
      environment,
      upcomingSweepCountsByYearQuery,
    ],
    queryFn: () =>
      flueDao
        .queryUpcomingSweepCount(upcomingSweepCountsByYearQuery)
        .then((response) => response.map((it) => new SweepCountByPeriod(it))),
    placeholderData: (previousData) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const repeatingSweepCountForPeriodQuery: RepeatingSweepCountQuery = {
    municipalityId: municipality,
    timeZoneId: timeZoneId,
    timeUnit: "year",
    start: start,
    end: end,
  };

  const repeatingSweepCountsForPeriod = useQuery({
    queryKey: [
      "FlueDao.queryRepeatingSweepCount",
      environment,
      repeatingSweepCountForPeriodQuery,
    ],
    queryFn: () =>
      flueDao
        .queryRepeatingSweepCount(repeatingSweepCountForPeriodQuery)
        .then((response) => response.map((it) => new SweepCountByPeriod(it))),
    placeholderData: (previousData) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const monthQueryStart = useMemo(
    () =>
      selectedYear != null
        ? selectedYear === now.year
          ? (now.startOf("year") as DateTime<true>)
          : (DateTime.local(selectedYear) as DateTime<true>)
        : null,
    [now, selectedYear]
  );

  const monthQueryEnd = useMemo(
    () =>
      monthQueryStart != null
        ? monthQueryStart.startOf("year").plus({ year: 1 })
        : null,
    [monthQueryStart]
  );

  const upcomingSweepCountForMonthQuery: UpcomingSweepCountQuery | undefined =
    monthQueryStart != null && monthQueryEnd != null
      ? {
          municipalityId: municipality,
          timeZoneId: timeZoneId,
          timeUnit: "month",
          start: monthQueryStart,
          end: monthQueryEnd,
        }
      : undefined;

  const upcomingSweepCountsForMonth = useQuery({
    queryKey: [
      "flueDao.queryUpcomingSweepCount",
      environment,
      upcomingSweepCountForMonthQuery,
    ],
    queryFn: () => {
      return upcomingSweepCountForMonthQuery != null
        ? flueDao
            .queryUpcomingSweepCount(upcomingSweepCountForMonthQuery)
            .then((response) =>
              response.map((it) => new SweepCountByPeriod(it))
            )
        : [];
    },
    enabled: upcomingSweepCountForMonthQuery != null,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
    placeholderData: (previousData) => previousData,
  });

  const repeatingSweepCountForMonthQuery: RepeatingSweepCountQuery | undefined =
    monthQueryStart != null && monthQueryEnd != null
      ? {
          municipalityId: municipality,
          timeZoneId: timeZoneId,
          timeUnit: "month",
          start: monthQueryStart,
          end: monthQueryEnd,
        }
      : undefined;

  const repeatingSweepCountsForMonth = useQuery({
    queryKey: [
      "flueDao.queryRepeatingSweepCount",
      environment,
      repeatingSweepCountForMonthQuery,
    ],
    queryFn: () => {
      return repeatingSweepCountForMonthQuery != null
        ? flueDao
            .queryRepeatingSweepCount(repeatingSweepCountForMonthQuery)
            .then((response) =>
              response.map((it) => new SweepCountByPeriod(it))
            )
        : [];
    },
    enabled: repeatingSweepCountForMonthQuery != null,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
    placeholderData: (previousData) => previousData,
  });

  const fluesNeedForSweepQueryStart = useMemo(
    () =>
      selectedYear != null && selectedMonth != null
        ? (DateTime.local(
            selectedYear,
            selectedMonth
          ).toUTC() as DateTime<true>)
        : selectedYear != null && selectedMonth == null
        ? (DateTime.local(selectedYear).toUTC() as DateTime<true>)
        : null,
    [selectedMonth, selectedYear]
  );

  const fluesNeedForSweepQueryEnd = useMemo(
    () =>
      fluesNeedForSweepQueryStart != null
        ? selectedMonth != null
          ? fluesNeedForSweepQueryStart.plus({ month: 1 }).toUTC()
          : fluesNeedForSweepQueryStart.plus({ year: 1 }).toUTC()
        : null,
    [fluesNeedForSweepQueryStart, selectedMonth]
  );

  const fluesNeedsSweepQuery: FluesNeedsSweepQuery | undefined =
    fluesNeedForSweepQueryStart != null && fluesNeedForSweepQueryEnd != null
      ? {
          timeZoneId: timeZoneId,
          start: fluesNeedForSweepQueryStart,
          end: fluesNeedForSweepQueryEnd,
        }
      : undefined;
  const fluesNeedForSweepForMonth = useQuery({
    queryKey: [
      "flueDao.queryFluesNeedsSweep",
      environment,
      fluesNeedsSweepQuery,
    ],
    queryFn: () => {
      return fluesNeedsSweepQuery != null
        ? flueDao.queryFluesNeedsSweep(fluesNeedsSweepQuery)
        : [];
    },
    enabled: fluesNeedsSweepQuery != null,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
    placeholderData: (previousData) =>
      fluesNeedsSweepQuery != null ? previousData : [],
  });

  const anyLoading =
    overdueSweepCount.isFetching ||
    overdueSweepCount.isLoading ||
    sweepCountAfterPeriod.isFetching ||
    sweepCountAfterPeriod.isLoading ||
    upcomingSweepCountsByYear.isFetching ||
    upcomingSweepCountsByYear.isLoading ||
    repeatingSweepCountsForPeriod.isFetching ||
    repeatingSweepCountsForPeriod.isLoading ||
    upcomingSweepCountsForMonth.isFetching ||
    upcomingSweepCountsForMonth.isLoading ||
    repeatingSweepCountsForMonth.isFetching ||
    repeatingSweepCountsForMonth.isLoading ||
    fluesNeedForSweepForMonth.isLoading ||
    fluesNeedForSweepForMonth.isFetching;

  const results = useMemo(() => {
    return {
      loading: anyLoading,
      numberOfYears: MaxYearsPlanningAhead,
      overdueSweepCountResult: overdueSweepCount,
      sweepCountAfterPeriodResult: sweepCountAfterPeriod,
      upcomingSweepCountsByYearResult: upcomingSweepCountsByYear,
      repeatingSweepCountsByYearResult: repeatingSweepCountsForPeriod,
      upcomingSweepCountsByMonthResult: upcomingSweepCountsForMonth,
      repeatingSweepCountsByMonthResult: repeatingSweepCountsForMonth,
      fluesNeedsSweepResult: fluesNeedForSweepForMonth,
    } as SweepCountLoaderResults;
  }, [
    anyLoading,
    overdueSweepCount,
    sweepCountAfterPeriod,
    upcomingSweepCountsByYear,
    repeatingSweepCountsForPeriod,
    upcomingSweepCountsForMonth,
    repeatingSweepCountsForMonth,
    fluesNeedForSweepForMonth,
  ]);

  return <>{props.children(results)}</>;
};
