import { Property, PropertyProps } from "shared-ts-property";
import { useTranslation } from "react-i18next";
import { FC } from "react";

export interface AnonymizeIssueMonitoringDataPropertyProps
  extends Omit<
    PropertyProps<boolean>,
    "name" | "value" | "onChange" | "label" | "type"
  > {
  name: string;
  value: boolean | null | undefined;
}

export const AnonymizeIssueMonitoringDataProperty: FC<
  AnonymizeIssueMonitoringDataPropertyProps
> = (props) => {
  const { t } = useTranslation("shared-ts-sentry");
  return (
    <Property
      {...props}
      label={t("Anonymize my issue monitoring data")}
      type={"boolean"}
      mode={props.mode ?? "edit"}
    />
  );
};
