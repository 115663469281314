import React, { FC, useMemo } from "react";
import {
  FlueId,
  SootIndexByMonthView,
} from "@airmont/firefly/shared/ts/domain";
import { SootIndexByMonthLoader } from "./SootIndexByMonthLoader";
import { SxProps } from "@mui/system";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface SootIndexByMonthProps {
  flueId: FlueId;
  lastSweep: DateTimeISO;
  selectedYear: number;
  selectedMonth: number;
  sootIndexThreshold: number;
  hideBurnHours?: boolean;
  sx?: SxProps;
}

export const SootIndexByMonth: FC<SootIndexByMonthProps> = (props) => {
  const { flueId, selectedYear, selectedMonth } = props;
  const lastSweep: DateTime = useMemo(
    () =>
      DateTime.fromISO(props.lastSweep) ??
      _throw(new IllegalStateError("Invalid lastSweep: " + props.lastSweep)),
    [props.lastSweep]
  );

  return (
    <SootIndexByMonthLoader
      flueId={flueId}
      lastSweep={lastSweep}
      year={selectedYear}
    >
      {(result) => {
        return (
          <SootIndexByMonthView
            loading={result.loading}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            flueMetricsByMonth={result.flueMetricsByMonth}
            predictedFlueMetricsByMonth={result.predictedFlueMetricsByMonth}
            sweepings={result.sweepings}
            sootIndexThreshold={props.sootIndexThreshold}
            hideBurnHours={props.hideBurnHours}
            sx={props.sx}
          />
        );
      }}
    </SootIndexByMonthLoader>
  );
};
