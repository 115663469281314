import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime, Interval } from "luxon";
import {
  _throw,
  IllegalStateError,
  notUndef,
} from "@airmont/shared/ts/utils/core";
import { BurnNonSensitive, BurnNonSensitiveDto } from "./BurnNonSensitive";
import { BurnOptions } from "./BurnOptions";

export type BurnId = string;

export interface BurnDto extends BurnNonSensitiveDto {
  anonymized: boolean;
  startTime: DateTimeISO;
  endTime?: DateTimeISO;
}

export class Burn extends BurnNonSensitive {
  readonly anonymized: boolean;
  readonly startTime: DateTime;
  readonly endTime?: DateTime;

  constructor(dto: BurnDto, options?: BurnOptions) {
    super(dto, options);
    this.anonymized = dto.anonymized;
    this.startTime = DateTime.fromISO(dto.startTime);
    this.endTime = notUndef(dto.endTime, (it) => DateTime.fromISO(it));
  }

  private _interval?: Interval;

  get interval(): Interval {
    if (this._interval != null) {
      return this._interval;
    }
    if (this.endTime != null) {
      this._interval = Interval.fromDateTimes(this.startTime, this.endTime);
      return this._interval;
    } else {
      return Interval.fromDateTimes(this.startTime, DateTime.now());
    }
  }

  toDto(): BurnDto {
    return {
      id: this.id,
      flueId: this.flueId,
      temperatureSeriesId: this.temperatureSeriesId,
      anonymized: this.anonymized,
      year: this.startTime.year,
      month: this.startTime.month,
      startTime:
        this.startTime.toUTC().toISO() ??
        _throw(new IllegalStateError("startTime")),
      endTime: notUndef(
        this.endTime,
        (it) => it.toUTC().toISO() ?? _throw(new IllegalStateError("endTime"))
      ),
      completed: this.completed,
      durationInMillis: this.duration.toMillis(),
      metrics: this.metrics,
      weather: this.weather,
      analytics: this.analytics,
    };
  }
}
