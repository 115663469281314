import { useEffect, useState } from "react";

export const useIsItemOverflowing = (
  element: HTMLElement | null | undefined
): boolean => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  useEffect(() => {
    if (element != null) {
      const overFlowInPixels = element.scrollWidth - element.clientWidth;
      setIsOverflowing(overFlowInPixels > 0);
    }
  }, [element]);

  return isOverflowing;
};
