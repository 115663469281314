import { Expr } from "./Expr";

export const binaryExpr = (
  $type: "==" | "&&" | "||",
  left: Expr,
  right: Expr
): Expr => {
  return {
    $type,
    left,
    right,
  };
};
