import { EventImpl, EventDto, EventId } from "@airmont/shared-ts-event";
import { Task, TaskDto } from "./Task";
import { DateTime } from "luxon";
import { DateTimeISO } from "@airmont/shared/ts/types";

export interface VisitDto extends EventDto {
  type: "visit";
  location: string;
  tasks: Array<TaskDto>;
}

export class Visit extends EventImpl {
  tasks: Array<Task>;
  location: string;

  constructor(args: {
    id: EventId;
    type: string;
    parentId?: EventId;
    start: DateTimeISO | DateTime;
    end: DateTimeISO | DateTime;
    location: string;
    participants: Array<string>;
    tasks: Array<TaskDto> | Array<Task>;
  }) {
    super(args);
    this.location = args.location;
    this.tasks = args.tasks.map((task) => {
      if (task instanceof Task) {
        return task;
      } else {
        return new Task(task);
      }
    });
  }

  getChildEvents(): Array<EventImpl> {
    return this.tasks;
  }
}
