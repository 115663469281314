import React, { useMemo } from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Map } from "shared-ts-ui-react-google-maps";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { AdvancedMarker, MapMouseEvent } from "@vis.gl/react-google-maps";

export interface EditLocationProps {
  lat: number;
  lng: number;
  onChange: (value: { lat: number | null; lng: number | null }) => void;
}

export const EditLocation = (props: EditLocationProps) => {
  const theme = useTheme();
  const mapId =
    theme.palette.mode === "light" ? "b9eccba04faf5ab6" : "aa56e046793435c6";
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const latLngBounds = useMemo(() => {
    return new google.maps.LatLngBounds().extend({
      lat: props.lat,
      lng: props.lng,
    });
  }, [props.lat, props.lng]);
  const defaultCenter = useMemo(
    () => notUndef(latLngBounds, (it) => it.getCenter().toJSON()),
    [latLngBounds]
  );

  const handleClick = (event: MapMouseEvent) => {
    if (event.detail.latLng != null) {
      const lat = event.detail.latLng.lat;
      const lng = event.detail.latLng.lng;

      if (window.confirm(tFireflyDomain("Change to new location?"))) {
        props.onChange({ lat: lat, lng: lng });
      }
    }
  };

  return (
    <Map
      palette={theme.palette.mode}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
      defaultCenter={defaultCenter}
      defaultZoom={18}
      backgroundColor={theme.palette.background.default}
      onClick={handleClick}
    >
      <AdvancedMarker position={defaultCenter} />
    </Map>
  );
};
