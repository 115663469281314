import { useMemo } from "react";
import { SystemDao } from "./SystemDao";
import { useAccessToken } from "shared-ts-utils-authentication";

export const useSystemDao = (): SystemDao => {
  const accessToken = useAccessToken();
  return useMemo(
    () => new SystemDao({ accessToken: accessToken }),
    [accessToken]
  );
};
