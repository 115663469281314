import { AirmontLogoImg } from "shared-ts-ui-airmont";
import React from "react";

export const appKey = "ff-ci";
export const appName = "Chimney Insights";
export const appRelease = "2024.2.0";

export const appInfo = {
  key: appKey,
  name: appName,
  icon: {
    large: <AirmontLogoImg />,
  },
  release: appRelease,
};
