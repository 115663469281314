import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";
import { EventDto, EventImpl, EventId } from "@airmont/shared-ts-event";
import { TaskType } from "./TaskType";

export interface TaskDto extends EventDto {
  type: TaskType;
  performers: Array<string>;
}

export class Task extends EventImpl {
  type: TaskType;

  constructor(args: {
    id: EventId;
    parentId?: EventId;
    type: TaskType;
    start: DateTimeISO | DateTime;
    end: DateTimeISO | DateTime;
    participants: Array<string> | null;
  }) {
    super(args);
    this.type = args.type;
  }
}
