import { MunicipalityDto } from "./Municipality";
import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { AccessToken } from "shared-ts-utils-authentication";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";

export class MunicipalityDao {
  private readonly fetchHelper: FetchHelper;
  constructor(args: {
    accessToken: AccessToken;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/municipality/",
    })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  getList(): Promise<Array<MunicipalityDto>> {
    return this.fetchHelper.get("list").fetchAsJson();
  }
}
