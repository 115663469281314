import { cloneElement, FC, ReactElement } from "react";
import { TabPanelProps } from "./TabPanel";
import { Box } from "@mui/material";

export interface TabPanelsProps {
  value: string;
  tabPanels: Array<ReactElement<TabPanelProps> | undefined>;
  renderHidden?: boolean;
}

export const TabPanels: FC<TabPanelsProps> = (props) => {
  const renderHidden = props.renderHidden ?? true;
  return (
    <Box
      className={"TabPanels"}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        ".TabPanel": {
          flexGrow: 1,
          minHeight: 0,
          minWidth: 0,
        },
      }}
    >
      {props.tabPanels
        .filter((tabPanel) => {
          if (tabPanel === undefined) {
            return false;
          }
          if (renderHidden) {
            return true;
          }
          return tabPanel.props.tabValue === props.value;
        })
        .map((tabPanel) => {
          if (tabPanel == null) {
            return null;
          }
          return cloneElement(tabPanel, {
            key: tabPanel.props.tabValue,
          });
        })}
    </Box>
  );
};
