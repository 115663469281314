import { ThemeOptions } from "@mui/material/styles";

const airmontTorqiseDark = "#669999";
const airmontTorqiseLight = "#9ACCCC";

declare module "@mui/material/styles" {
  interface PaletteColor {
    container?: string;
    onContainer?: string;
  }

  interface SimplePaletteColorOptions {
    container?: string;
    onContainer?: string;
  }

  interface Palette {
    tertiary?: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
  }
}

const light_primary_main = "#669999";
const light_primary_container = "#D1E0E0";
const light_secondary_main = "#3D5252";
const light_secondary_container = "#D1E0E0";
const orange = "#FA854F";
const orangeContainer = "#FEDACA";
const turquoise = "#669999";
const turquoiseContainer = "#D1E0E0";

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: light_primary_main,
      container: light_primary_container,
    },
    secondary: {
      main: light_secondary_main,
      container: light_secondary_container,
    },
    tertiary: {
      main: orange,
      container: orangeContainer,
    },
  },
  shape: {
    borderRadius: 3,
  },
};

const dark_primary_main = "#9ACCCC";
const dark_primary_container = "#3D5252";

const dark_secondary_main = "#9ACCCC";
const dark_secondary_container = "#3D5252";

const dark_text_primary = "#ebebeb";
const dark_background_default = "#202020";
const dark_background_paper = "#181818";

export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: dark_primary_main,
      container: dark_primary_container,
    },
    secondary: {
      main: dark_secondary_main,
      container: dark_secondary_container,
    },
    text: {
      primary: dark_text_primary,
    },
    background: {
      default: dark_background_default,
      paper: dark_background_paper,
    },
  },
  shape: {
    borderRadius: 3,
  },
};
