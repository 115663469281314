import { SettingConfig } from "./SettingConfig";

export const StringSetting: SettingConfig<string> = {
  type: "string",
  serialize: (value: string): string => {
    return value;
  },

  deserialize: (value: string): string => {
    return value;
  },
};
