import {
  DataGridPremium,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { LinearProgress } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import { useEffect, useState } from "react";
import { DataGridFooterWithToolbar, useMuiLocalization } from "shared-ts-mui";
import {
  ChimneySensorStatusDto,
  ChimneySensorStatusQueryDto,
  useSensorQueryClient,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { useSensorStatusTableColumns } from "./useSensorStatusTableColumns";
import {
  ChimneySensorSerialNr,
  SignalStrengthLevel,
} from "@airmont/firefly/shared/ts/domain";
import { useQueryAndDisplayRowsProvider } from "shared/ts/ui/x-data-grid";

export interface SensorStatusTableProps {
  query: ChimneySensorStatusQueryDto;
  forceRefresh: number;
  rows?: Array<ChimneySensorStatusDto>;
  onRowsChange: (rows: Array<ChimneySensorStatusDto>) => void;
  onSelectedRow: (row: ChimneySensorSerialNr | undefined) => void;
  onEditRequested: (sensor: ChimneySensorStatusDto) => void;
  onViewRequested: (sensor: ChimneySensorStatusDto) => void;
}

export const SensorStatusTable = (props: SensorStatusTableProps) => {
  const { muiLocalization } = useMuiLocalization();
  const [scrollEndThreshold] = useState<number>(80);
  const queryClient = useSensorQueryClient();
  const {
    apiRef,
    rows,
    totalRows,
    loading,
    selectionModel: [selectionModel, setSelectionModel],
    sortModel: [sortModel],
    columnVisibilityModel,
    onRowsScrollEnd: handleRowsScrollEnd,
    onSortModelChange: handleSortModelChange,
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
  } = useQueryAndDisplayRowsProvider<ChimneySensorStatusDto>({
    tableId: "SensorStatusTable",
    query: props.query,
    queryClient: queryClient,
    rows: props.rows,
    initialPageSize: 1000,
    forceRefresh: props.forceRefresh,
    defaultColumnVisibilityModel: {
      "streetAddress.houseSection": false,
      "postalAddress.place": false,
      comment: false,
      tags: false,
      model: false,
      retired: false,
      lastHeard: false,
      "board.voltage": false,
      uptime: false,
      "signal.interferenceNoiseRatio": false,
      "signal.refReceivedPower": false,
      "signal.refReceivedQuality": false,
      "signal.strength": false,
      "cadastre.cadastralUnit": false,
      "cadastre.propertyUnit": false,
      "cadastre.leaseNumber": false,
      "audit.created": false,
      "audit.createdBy": false,
      "audit.modified": false,
      "audit.modifiedBy": false,
      id: false,
    },
  });

  const columns = useSensorStatusTableColumns({
    onViewOnClick: (params) => handleViewClick(params),
    onEditOnClick: (params) => handleEditClick(params),
  });

  useEffect(() => {
    props.onRowsChange(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleViewClick = (params: GridRowParams<ChimneySensorStatusDto>) => {
    props.onViewRequested(params.row);
  };

  const handleEditClick = (params: GridRowParams<ChimneySensorStatusDto>) => {
    props.onEditRequested(params.row);
  };

  const handleRowSelectionModelChange = (
    selectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => {
    setSelectionModel(selectionModel);
    if (props.onSelectedRow) {
      if (selectionModel.length === 1) {
        const rowId = selectionModel[0];
        props.onSelectedRow(rowId as ChimneySensorSerialNr);
      } else {
        props.onSelectedRow(undefined);
      }
    }
  };

  return (
    <DataGridPremium
      sx={{
        "& .radio-signal.excellent": {
          backgroundColor: SignalStrengthLevel.Excellent,
        },
        "& .radio-signal.good": {
          backgroundColor: SignalStrengthLevel.Good,
        },
        "& .radio-signal.medium": {
          backgroundColor: SignalStrengthLevel.Medium,
        },
        "& .radio-signal.weak": {
          backgroundColor: SignalStrengthLevel.Weak,
        },
        "& .radio-signal.veryLow": {
          backgroundColor: SignalStrengthLevel.VeryLow,
        },
        "& .radio-signal.noSignal": {
          backgroundColor: SignalStrengthLevel.NoSignal,
        },
      }}
      apiRef={apiRef}
      localeText={
        muiLocalization.components.MuiDataGrid.defaultProps.localeText
      }
      initialState={{
        pinnedColumns: {
          left: ["streetAddress", "flueName"],
          right: ["actions"],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      slots={{
        loadingOverlay: LinearProgress as never,
        footer: DataGridFooterWithToolbar,
      }}
      slotProps={{
        footer: {
          rowCount: rows.length,
          totalRowCount: totalRows,
          loading: loading,
        },
      }}
      hideFooterPagination
      disableColumnFilter
      disableRowGrouping
      disableAggregation
      columns={columns}
      loading={loading}
      rows={rows}
      rowCount={totalRows}
      rowSelectionModel={selectionModel}
      sortingMode="server"
      sortModel={sortModel}
      scrollEndThreshold={scrollEndThreshold}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      onRowsScrollEnd={handleRowsScrollEnd}
      onSortModelChange={handleSortModelChange}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
    />
  );
};
