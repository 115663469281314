import { ReactNode, useCallback } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  useAdvancedMarkerRef,
  useMap,
} from "@vis.gl/react-google-maps";
import { Feature, Point } from "geojson";
import { ClusterPinRenderProps } from "./ClusterPinRenderProps";
import { LatLngBounds } from "../LatLngBounds";

export interface FeaturesClusterMarkerProps<TFeature extends Feature<Point>> {
  clusterId: number;
  features: Array<TFeature>;
  onMarkerClick?: (
    marker: google.maps.marker.AdvancedMarkerElement,
    clusterId: number
  ) => void;
  position: google.maps.LatLngLiteral;
  size: number;
  sizeAsText: string;
  renderClusterPin: (props: ClusterPinRenderProps<TFeature>) => ReactNode;
}

export const FeaturesClusterMarker = <TFeature extends Feature<Point>>(
  props: FeaturesClusterMarkerProps<TFeature>
) => {
  const { clusterId, features, onMarkerClick, position, size } = props;
  const map = useMap();
  const [markerRef, marker] = useAdvancedMarkerRef();
  const handleClick = useCallback(() => {
    if (onMarkerClick != null) {
      onMarkerClick(marker!, clusterId);
    }
    if (map != null) {
      const bounds = LatLngBounds.fromFeatures(features);
      map.fitBounds(bounds);
    }
  }, [onMarkerClick, map, marker, clusterId, features]);
  const markerSize = Math.floor(48 + Math.sqrt(size) * 2);
  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      zIndex={size}
      onClick={handleClick}
      className={"marker cluster"}
      style={{ width: markerSize, height: markerSize }}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
    >
      {props.renderClusterPin({ size: size, features: props.features })}
    </AdvancedMarker>
  );
};
