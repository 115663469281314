import { DateTime } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import {
  DateObjectUnits,
  DateTimeJSOptions,
  DateTimeOptions,
} from "luxon/src/datetime";

export class DateTimeUtils {
  static fromISO(text: string, opts?: DateTimeOptions): DateTime<true> {
    return (DateTime.fromISO(text, opts) ??
      _throw(
        new IllegalStateError(`Invalid DateTime: ${text}`)
      )) as DateTime<true>;
  }
  static fromObject(
    obj: DateObjectUnits,
    opts?: DateTimeJSOptions
  ): DateTime<true> {
    return (DateTime.fromObject(obj, opts) ??
      _throw(
        new IllegalStateError(`Invalid DateTime: ${obj}`)
      )) as DateTime<true>;
  }
}
