import React, { Dispatch, FocusEvent, ReactNode, SetStateAction } from "react";
import { PropertyEdit } from "./PropertyEdit";
import { PropertyValue } from "./PropertyValue";
import { PropertyRead } from "./PropertyRead";
import { PropertyValueType } from "./PropertyValueType";
import { TextField_size } from "shared-ts-mui";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { SxProps } from "@mui/system";
import { ValueOptions } from "./ValueOptions";
import { InputBaseProps } from "@mui/material/InputBase";
import { DurationFormatterOptions } from "@airmont/shared/ts/ui/time";
import { EditComponentTypeName } from "./EditComponentTypeName";
import { SliderComponentProps } from "./PropertyEditSlider";

export type PropertyMode = "edit" | "read";

export interface PropertyProps<Value extends PropertyValue = PropertyValue> {
  label: string;
  name?: string;
  variant?: "standard" | "outlined" | "filled";
  value: PropertyValue;
  removable?: boolean;
  editComponent?: EditComponentTypeName;
  editComponentProps?: {
    Slider: SliderComponentProps | undefined;
  };
  type?: PropertyValueType;
  multiline?: boolean;
  unit?: string;
  valueOptions?: ValueOptions;
  disableFuture?: boolean;
  durationFormatterOptions?: DurationFormatterOptions;
  onChange?: (value: Value, name: string) => void;
  onValueChange?: (value: Value) => void;
  onReactSetState?: Dispatch<SetStateAction<Value>>;
  onFormikFieldValueChange?: (name: string, value: PropertyValue) => void;
  onBlur?: (event: FocusEvent) => void;
  size?: TextField_size;
  fullWidth?: boolean;
  readOnly?: boolean;
  mode?: PropertyMode;
  info?: ReactNode;
  autoFocus?: boolean;
  helperText?: ReactNode;
  error?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  inputRef?: React.Ref<HTMLInputElement>;
  sx?: SxProps;
}

export const Property = <Value extends PropertyValue = PropertyValue>(
  props: PropertyProps<Value>
) => {
  const componentSize = useComponentSizeFromDense();
  const mode = props.mode ?? "read";
  const read = mode === "read" || (props.readOnly ?? false);

  const handleChange = (value: PropertyValue, name: string) => {
    props.onChange?.(value as Value, name);
    props.onValueChange?.(value as Value);
    props.onReactSetState?.(value as Value);
    props.onFormikFieldValueChange?.(name, value);
  };

  if (read) {
    return (
      <PropertyRead
        label={props.label}
        name={props.name}
        value={props.value}
        multiline={props.multiline}
        valueOptions={props.valueOptions}
        unit={props.unit}
        durationFormatterOptions={props.durationFormatterOptions}
        size={props.size ?? componentSize}
        fullWidth={props.fullWidth}
        info={props.info}
        type={props.type}
        sx={props.sx}
      />
    );
  } else {
    return (
      <PropertyEdit
        label={props.label}
        name={props.name}
        type={props.type}
        editComponent={props.editComponent}
        editComponentProps={props.editComponentProps}
        value={props.value}
        removable={props.removable}
        disableFuture={props.disableFuture}
        multiline={props.multiline}
        valueOptions={props.valueOptions}
        unit={props.unit}
        size={props.size ?? componentSize}
        fullWidth={props.fullWidth}
        info={props.info}
        autoFocus={props.autoFocus}
        helperText={props.helperText}
        error={props.error}
        inputProps={props.inputProps}
        inputRef={props.inputRef}
        sx={props.sx}
        onChange={handleChange}
        onBlur={(event) => props.onBlur?.(event)}
      />
    );
  }
};
