import { DateTime, Duration } from "luxon";
import { DateTimeISO, PeriodISO } from "@airmont/shared/ts/types";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import {
  AggregateType,
  ProcessingType,
} from "@airmont/firefly/shared/ts/timeseries";

export interface QueryDto {
  id?: string;
  start: DateTimeISO;
  end: DateTimeISO;
  aggregate?: AggregateType;
  resolution?: PeriodISO;
  processing?: ProcessingType;
  timeZone?: string;
  clientTimeZone?: string;
}

export interface Query {
  id?: string;
  start: DateTime;
  end: DateTime;
  aggregate?: AggregateType;
  resolution?: Duration;
  processing?: ProcessingType;
  timeZone?: string;
  clientTimeZone?: string;

  toQueryDto: () => QueryDto;
}

export class QueryImpl implements Query {
  readonly id?: string;
  readonly start: DateTime;
  readonly end: DateTime;
  readonly aggregate?: AggregateType;
  readonly resolution?: Duration;
  readonly processing?: ProcessingType;
  readonly timeZone?: string;
  readonly clientTimeZone?: string;

  constructor(args: Omit<Query, "toQueryDto">) {
    this.id = args.id;
    this.start = args.start;
    this.end = args.end;
    this.aggregate = args.aggregate;
    this.resolution = args.resolution;
    this.processing = args.processing;
    this.timeZone = args.timeZone;
    this.clientTimeZone = args.clientTimeZone;
  }

  toQueryDto(): QueryDto {
    return {
      id: this.id,
      start:
        this.start.toUTC().toISO() ?? _throw(new IllegalStateError("start")),
      end: this.end.toUTC().toISO() ?? _throw(new IllegalStateError("end")),
      aggregate: this.aggregate,
      resolution:
        this.resolution !== undefined
          ? this.resolution?.rescale()?.toISO() ??
            _throw(new IllegalStateError("resolution"))
          : undefined,
      processing: this.processing,
      timeZone: this.timeZone,
      clientTimeZone: this.clientTimeZone,
    };
  }
}
