import { createContext, FC, ReactNode } from "react";
import { OidcAuthenticationService } from "./OidcAuthenticationService";

export const OidcContext = createContext({} as OidcAuthenticationService);

export interface OidcContextProviderProps {
  instance: OidcAuthenticationService;
  children?: ReactNode;
}

export const OidcProvider: FC<OidcContextProviderProps> = (props) => {
  return (
    <OidcContext.Provider value={props.instance}>
      {props.children}
    </OidcContext.Provider>
  );
};
