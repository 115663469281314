import {PeriodType, ResolutionType} from "@airmont/shared/ts/types";


export class DateUtil {

    static now() {
        return new Date();
    }

    static today() {
        const now = this.now();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }


    static offsetDays(date: Date, days: number) {
      let newDate = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
      const dstOrig = date.getTimezoneOffset();
      const dstNew = newDate.getTimezoneOffset();
      const diff = dstNew - dstOrig;

      if(diff !== 0) {
            newDate = new Date(newDate.getTime() + diff * 60 * 1000);
        }

        return newDate;
    }

    static dateFormatOptions: Intl.DateTimeFormatOptions = {
        // timeZone:"Africa/Accra",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };

    public static parse(value: string | undefined): Date | undefined {
        if (value) return new Date(value);

        return undefined;
    }

    public static formatDate(
        value: Date | string | undefined,
        options?: Intl.DateTimeFormatOptions
    ): string {
        if (value)
            return new Date(value).toLocaleDateString(
                "nb-NO",
                options ?? this.dateFormatOptions
            );
        return "";
    }



    /**
     * Adds an interval of a given type to the date.
     * Supported types are:
     * year, quarter, month,
     * week, day, hour,
     * minute, second, millisecond
     */
    public static add(
        date: Date,
        interval: PeriodType,
        units: number
    ): Date {
        const ret = new Date(date); //don't change original date
      const checkRollover = function() {
        if (ret.getDate() !== date.getDate()) ret.setDate(0);
      };
      switch (interval) {
            case PeriodType.Year:
                ret.setFullYear(ret.getFullYear() + units);
                checkRollover();
                break;
            case PeriodType.Quarter:
                ret.setMonth(ret.getMonth() + 3 * units);
                checkRollover();
                break;
            case PeriodType.Month:
                ret.setMonth(ret.getMonth() + units);
                checkRollover();
                break;
            case PeriodType.Week:
                ret.setDate(ret.getDate() + 7 * units);
                break;
            case PeriodType.Day:
                ret.setDate(ret.getDate() + units);
                break;
            case PeriodType.Hour:
                ret.setTime(ret.getTime() + units * 3600000);
                break;
            case PeriodType.Minute:
                ret.setTime(ret.getTime() + units * 60000);
                break;
            case PeriodType.Second:
                ret.setTime(ret.getTime() + units * 1000);
                break;
            default:
                ret.setTime(ret.getTime() + units);
                break;
        }
        return ret;
    }

    public static getWeekNumber(date: Date) {
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      return Math.ceil(
            ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
        );
    }

    public static getPeriodString(start: Date | undefined, end: Date | undefined) {
        if(start === undefined || end === undefined) return "";

      const seconds = Math.abs((end.getTime() - start.getTime()) / 1000);

      if (seconds < 60)
            return seconds.toFixed(0) + " sek";

      const minutes = seconds / 60;

      if (minutes < 60)
            return minutes.toFixed(0) + "min";

      const hours = minutes / 60;

      if (hours <= 24)
            return hours.toFixed(0) + " t";

      const days = hours / 24;

      if (days < 365)
            return days.toFixed(0) + " dg";

      const years = days / 365;
      return years.toFixed(0) + " år";
    }

    public static getPeriodForResolution(date: Date, resolution: ResolutionType) {
        const hourFormatter = new Intl.DateTimeFormat("default", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });

        const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
      const month = date.getMonth() + 1;
        switch (resolution) {
            case ResolutionType.Minute:
            case ResolutionType.Hour:
                return hourFormatter.format(date);
            case ResolutionType.Day:
                return date.toLocaleDateString();
            case ResolutionType.Week:
                return (
                    "Uke " +
                    DateUtil.getWeekNumber(date) +
                    " " +
                    date.getFullYear()
                );
            case ResolutionType.Month:
                return (
                    monthNamesShort[date.getMonth()] + " " + date.getFullYear()
                );
            case ResolutionType.Quarter:

              return (
                    (month < 4
                        ? "Q1"
                        : month < 7
                            ? "Q2"
                            : month < 10
                                ? "Q3"
                                : "Q4") +
                    "-" +
                    date.getFullYear()
                );
            case ResolutionType.Year:
                return date.getFullYear();

            default:
                throw new Error("Unsupported resolution: " + resolution);
        }
    }




}
