import { FC, ReactNode, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { Properties } from "posthog-js";
import { useAnonymizeUsageData } from "./useAnonymizeUsageData";
import { usePostHogEnabled } from "./PosthogEnabled";

export interface PostHogIdentifierProps {
  user?: {
    id: string;
    name?: string;
    email?: string;
  };
  children: ReactNode;
}

export const PostHogIdentifier: FC<PostHogIdentifierProps> = (props) => {
  const { user } = props;
  const postHog = usePostHog();
  const postHogEnabled = usePostHogEnabled();
  const [anonymizeUsageData] = useAnonymizeUsageData();

  useEffect(() => {
    if (postHogEnabled && !anonymizeUsageData) {
      if (user?.id != null) {
        // Identify sends an event, so you want may want to limit how often you call it
        const userProps: Properties = {};
        if (user.email != null) {
          userProps["email"] = user.email;
        }
        if (user.name != null) {
          userProps["name"] = user.name;
        }
        postHog.identify(user.id, userProps);
        postHog.set_config({
          ip: !anonymizeUsageData,
        });
      }
    }
  }, [
    postHogEnabled,
    anonymizeUsageData,
    user?.id,
    user?.email,
    user?.name,
    postHog,
  ]);

  return props.children;
};
