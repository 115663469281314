import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { EnvironmentMetricsDto } from "./EnvironmentMetrics";
import { EnvironmentMetricsQuery } from "./EnvironmentMetricsQuery";

export class EnvironmentMetricsDao {
  private fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/environment_metrics/",
    })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  query(query: EnvironmentMetricsQuery): Promise<Array<EnvironmentMetricsDto>> {
    return this.fetchHelper.post("query", query.toDto()).fetchAsJson();
  }
}
