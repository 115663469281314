import React, { FC, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import {
  BatteryLevelUtils,
  FlueBreadcrumbs,
  useFireflySharedTsDomainTranslation,
} from "@airmont/firefly/shared/ts/domain";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { DateTime, Interval } from "luxon";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";

export interface SensorStatusInfoWindowCardProps {
  sensor: ChimneySensorStatusDto;
  onView?: (sensor: ChimneySensorStatusDto) => void;
  onClose: () => void;
}

export const SensorStatusInfoWindowCard: FC<SensorStatusInfoWindowCardProps> = (
  props
) => {
  const theme = useTheme();
  const { t } = useFireflySharedTsDomainTranslation();
  const sensor = props.sensor;
  const now = useDateTimeNow();
  const lastHeardDuration = notUndef(props.sensor.lastHeard, (it) => {
    const start = DateTime.fromISO(it);
    const end = now;
    if (start > end) {
      // Remedy against some having lastHeard set to a time in the future (ie. 2080-01-06T00:00:30+00:00)
      return undefined;
    }
    return Interval.fromDateTimes(start, end).toDuration();
  });
  const flueNameObject = useMemo(
    () => ({ name: sensor.flueName }),
    [sensor.flueName]
  );

  return (
    <Card elevation={0} sx={{ backgroundColor: "transparent" }}>
      <CardHeader
        title={
          <FlueBreadcrumbs
            municipality={sensor.municipality}
            postalAddress={sensor.postalAddress}
            streetAddress={sensor.streetAddress}
            flue={flueNameObject}
            hideMunicipality
            hidePostalAddress
          />
        }
        subheader={
          <Box sx={{ whiteSpace: "nowrap" }}>
            {`${sensor.postalAddress?.code ?? ""} ${
              sensor.postalAddress?.place ?? ""
            }`}
          </Box>
        }
        sx={{ padding: theme.spacing(0.5) }}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ padding: "4px" }}>
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Tooltip title={t("Serial Number")}>
              <Typography component={"span"} sx={{ fontSize: "12px" }}>
                SN: {sensor.id}
              </Typography>
            </Tooltip>
          </Box>
          <Typography sx={{ fontSize: "12px" }}>
            {t("Status")}:{" "}
            {notUndef(sensor.status, (it) => t(`ChimneySensorStatus.${it}`))}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {t("Battery Level")}:{" "}
            {notUndef(sensor.board.voltage, (it) =>
              t(`BatteryLevel.${BatteryLevelUtils.getStatus(it)}`)
            )}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {t("Last Heard")}:{" "}
            {lastHeardDuration != null && (
              <DurationDisplay
                duration={lastHeardDuration}
                maxUnits={2}
                layout={SizeClass.Compact}
                component={"span"}
                sx={{ fontSize: "inherit" }}
              />
            )}
            {lastHeardDuration == null && "?"}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => props.onView?.(sensor)} size={"small"}>
            {t("Show")}
          </Button>
          <Button onClick={props.onClose} size={"small"}>
            {t("Close")}
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
