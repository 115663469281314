import { MuiTimeSeriesOptions } from "./TimeSeriesMuiChart";
import { TimeAxisScaleType } from "./TimeAxisScaleType";

const defaultScaleType = "utc";
export const resolveTimeAxisScaleType = (
  timeSeriesOptions: Array<MuiTimeSeriesOptions> | undefined
): TimeAxisScaleType => {
  if (timeSeriesOptions === undefined) {
    return defaultScaleType;
  }
  const someIsBar = timeSeriesOptions.some((it) => it.type === "bar");
  return someIsBar ? "band" : defaultScaleType;
};
