import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
  ArraySetting,
  DictionarySetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { QueryClient, QueryDto, QueryItem } from "shared/ts/utils/query";
import { useQueryProvider } from "./useQueryProvider";
import { GridRowScrollEndParams } from "@mui/x-data-grid-pro/models/gridRowScrollEndParams";
import { GridInputRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel";

const defaultSortModel: GridSortModel = [];
export const useQueryAndDisplayRowsProvider = <ROW extends QueryItem>(props: {
  tableId: string;
  query: QueryDto;
  queryClient: QueryClient;
  rows?: Array<ROW>;
  initialPageSize?: number;
  forceRefresh: number;
  defaultColumnVisibilityModel?: GridColumnVisibilityModel;
}): {
  apiRef: MutableRefObject<GridApiPremium>;
  loading: boolean;
  rows: Array<ROW>;
  totalRows: number;
  columnVisibilityModel: GridColumnVisibilityModel;
  selectionModel: [
    GridInputRowSelectionModel,
    Dispatch<SetStateAction<GridInputRowSelectionModel>>
  ];
  sortModel: [GridSortModel, Dispatch<GridSortModel>];
  filterModel: [GridFilterModel, Dispatch<SetStateAction<GridFilterModel>>];
  fetchAndDisplayRows: (args: {
    query: QueryDto;
    firstRow: number;
    lastRow: number;
  }) => Promise<void>;
  onRowsScrollEnd: (params: GridRowScrollEndParams) => void;
  onRowSelectionModelChange: (model: GridRowSelectionModel) => void;
  onFilterModelChange: (sortModel: GridFilterModel) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
  onColumnVisibilityModelChange: (sortModel: GridColumnVisibilityModel) => void;
} => {
  const apiRef = useGridApiRef();
  const initialPageSize = props.initialPageSize ?? 100;
  const [loading, setLoading] = useState<boolean>(false);
  const noRows = useMemo(() => [] as Array<ROW>, []);
  const [rows, setRows] = useState<Array<ROW>>(props.rows ?? noRows);
  const [totalRows, setTotalRows] = useState<number>(-1);
  const [selectionModel, setSelectionModel] =
    useState<GridInputRowSelectionModel>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useUserSettingWithDefault<GridColumnVisibilityModel>(
      `${props.tableId}.columnVisibilityModel`,
      DictionarySetting,
      props.defaultColumnVisibilityModel ?? {}
    );
  const [sortModel, setSortModel] = useUserSettingWithDefault<GridSortModel>(
    `${props.tableId}.sortModel`,
    ArraySetting,
    defaultSortModel
  );

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const query = useQueryProvider(props.queryClient);

  const fetchAndDisplayRows = useCallback(
    async (args: { query: QueryDto; firstRow: number; lastRow: number }) => {
      const { firstRow, lastRow } = args;

      setLoading(true);

      const { slice, total } = await query<ROW>({
        query: args.query,
        firstRowToRender: firstRow,
        lastRowToRender: lastRow,
        sortModel: sortModel,
        filterModel: filterModel,
      });

      if (firstRow === 0) {
        apiRef.current?.scrollToIndexes({ rowIndex: 0 });
        setTotalRows(total);
        setRows(slice);
      } else {
        setRows((prevRows) => prevRows.concat(slice));
      }

      setLoading(false);
    },
    [apiRef, sortModel, query, filterModel]
  );

  useEffect(() => {
    (async () => {
      fetchAndDisplayRows({
        query: props.query,
        firstRow: 0,
        lastRow: initialPageSize,
      });
    })();
  }, [fetchAndDisplayRows, props.query, props.forceRefresh, initialPageSize]);

  useEffect(() => {
    setRows(props.rows ?? []);
  }, [props.rows]);

  const handleOnRowsScrollEnd = useCallback(
    async (params: GridRowScrollEndParams) => {
      if (params.visibleRowsCount >= totalRows && totalRows !== -1) {
        return;
      }
      fetchAndDisplayRows({
        query: props.query,
        firstRow: rows.length,
        lastRow: rows.length + initialPageSize,
      });
    },
    [fetchAndDisplayRows, initialPageSize, props.query, rows.length, totalRows]
  );
  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
    },
    [setFilterModel]
  );
  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
    },
    [setSortModel]
  );

  const handleColumnVisibilityChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(model);
    },
    [setColumnVisibilityModel]
  );

  const handleRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
      /*if (props.onSelectedRow) {
      if (selectionModel.length === 1) {
        const rowId = selectionModel[0];
        const row = rows.find((it) => it.id === rowId);
        if (row != null) {
          props.onSelectedRow(row);
        }
      } else {
        props.onSelectedRow(undefined);
      }
    }
    if (props.onSelectedRowId) {
      if (selectionModel.length === 1) {
        const rowId = selectionModel[0];
        if (rowId != null) {
          props.onSelectedRowId(rowId as string);
        }
      } else {
        props.onSelectedRowId(undefined);
      }
    }*/
    },
    [setSelectionModel]
  );

  return useMemo(() => {
    return {
      apiRef: apiRef,
      loading: loading,
      rows: rows,
      totalRows: totalRows,
      selectionModel: [selectionModel, setSelectionModel],
      sortModel: [sortModel, setSortModel],
      columnVisibilityModel: columnVisibilityModel,
      filterModel: [filterModel, setFilterModel],
      fetchAndDisplayRows: fetchAndDisplayRows,
      onRowSelectionModelChange: handleRowSelectionModelChange,
      onRowsScrollEnd: handleOnRowsScrollEnd,
      onFilterModelChange: handleFilterModelChange,
      onSortModelChange: handleSortModelChange,
      onColumnVisibilityModelChange: handleColumnVisibilityChange,
      onSelectionModelChange: handleRowSelectionModelChange,
    };
  }, [
    rows,
    totalRows,
    selectionModel,
    filterModel,
    columnVisibilityModel,
    setSelectionModel,
    setSortModel,
    setFilterModel,
    sortModel,
    loading,
    fetchAndDisplayRows,
    handleRowSelectionModelChange,
    handleFilterModelChange,
    handleColumnVisibilityChange,
    handleOnRowsScrollEnd,
    handleSortModelChange,
    apiRef,
  ]);
};
