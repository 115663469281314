import { Property, PropertyProps } from "shared-ts-property";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export type FlueHeightPropertyProps = Omit<
  PropertyProps,
  "label" | "name" | "type" | "info"
>;

export const FlueHeightProperty: FC<FlueHeightPropertyProps> = (props) => {
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  return (
    <Property
      {...props}
      name="flueHeight"
      label={tFireflySharedDomain("Flue Height")}
      type={"number"}
      info={tFireflySharedDomain(
        "Height from the fireplace to the top of the chimney"
      )}
    />
  );
};
