import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export enum ChimneyHatEnum {
  No = -1,
  Unknown = 0,
  Yes = 1,
  YesWithFan = 2,
}

export enum ChimneyHatStringsEnum {
  No = "No",
  Unknown = "Unknown",
  Yes = "Yes",
  YesWithFan = "YesWithFan",
}

export const chimneyHatEnumToChimneyHatStringsEnum = (
  value: ChimneyHatEnum
): ChimneyHatStringsEnum => {
  switch (value) {
    case ChimneyHatEnum.No:
      return ChimneyHatStringsEnum.No;
    case ChimneyHatEnum.Unknown:
      return ChimneyHatStringsEnum.Unknown;
    case ChimneyHatEnum.Yes:
      return ChimneyHatStringsEnum.Yes;
    case ChimneyHatEnum.YesWithFan:
      return ChimneyHatStringsEnum.YesWithFan;
    default:
      throw new IllegalArgumentError(value);
  }
};
