import { NotifyActions } from "./NotifyActions";
import { useContext } from "react";
import { NotificationContext } from "./NotificationContextProvider";

export const useNotify = (): NotifyActions => {

  const context = useContext(NotificationContext);

  if (context == null) {
    throw new Error("useNotification must be used within a NotificationContextProvider");
  }

  return context.notify;
};
