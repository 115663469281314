import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  alpha,
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { Calendar, CalendarView } from "@airmont/shared-ts-calendar";
import { DateTime, Info } from "luxon";
import {
  useComponentSizeFromDense,
  useDense,
} from "@airmont/shared/ts/ui/responsive";
import React, { useMemo, useState } from "react";
import {
  renderEvent,
  Task,
  Visit,
  VisitSettingsProvider,
} from "@airmont/firefly-shared-ts-visit";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { Panel, PanelGroup } from "react-resizable-panels";
import { ResizeHandle } from "@airmont/shared/ts/ui/react-resizeable-panels";

export const SweepPlanningPage = () => {
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const dateNow = useDateTimeNow();
  const [selectedDate, setSelectedDate] = useState<DateTime<true>>(dateNow);
  const [calendarView, setCalendarView] = useState<CalendarView>("month");
  const monthName = Info.months()[dateNow.month - 1];
  const now = useDateTimeNow();
  const events = useMemo(() => {
    const baseTime = now.startOf("day").plus({ hours: 8 });
    return [
      new Visit({
        id: "1",
        type: "visit",
        start: baseTime,
        end: baseTime.plus({ hours: 2 }),
        location: "Klokkergata 6",
        participants: ["1"],
        tasks: [
          new Task({
            id: "1.1",
            parentId: "1",
            type: "sweep",
            start: baseTime,
            end: baseTime.plus({ hours: 1 }),
            participants: ["1"],
          }),
          new Task({
            id: "1.2",
            parentId: "1",
            type: "sensorInst",
            start: baseTime.plus({ hours: 1 }),
            end: baseTime.plus({ hours: 2 }),
            participants: ["1"],
          }),
        ],
      }),
      new Visit({
        id: "2",
        type: "visit",
        start: baseTime.plus({ days: 1, hours: 1 }),
        end: baseTime.plus({ days: 1, hours: 2 }),
        location: "Sponvikveien 30",
        participants: ["2"],
        tasks: [
          new Task({
            id: "2.1",
            parentId: "2",
            type: "supervision",
            start: baseTime.plus({ days: 1, hours: 1 }),
            end: baseTime.plus({ days: 1, hours: 2 }),
            participants: ["2"],
          }),
        ],
      }),
      new Visit({
        id: "3",
        type: "visit",
        start: baseTime.plus({ days: 2, hours: 0 }),
        end: baseTime.plus({ days: 2, hours: 2 }),
        location: "Løvveien 5",
        participants: ["3"],
        tasks: [
          new Task({
            id: "3.1",
            parentId: "3",
            type: "supervision",
            start: baseTime.plus({ days: 1, hours: 0 }),
            end: baseTime.plus({ days: 2, hours: 1 }),
            participants: ["3"],
          }),
        ],
      }),
      new Visit({
        id: "4",
        type: "visit",
        start: baseTime.plus({ days: 3, hours: 4 }),
        end: baseTime.plus({ days: 3, hours: 6 }),
        location: "Opalveien 15",
        participants: ["1"],
        tasks: [
          new Task({
            id: "4.1",
            parentId: "4",
            type: "sweep",
            start: baseTime.plus({ days: 3, hours: 4 }),
            end: baseTime.plus({ days: 3, hours: 5 }),
            participants: ["1"],
          }),
          new Task({
            id: "4.2",
            parentId: "4",
            type: "sensorInst",
            start: baseTime.plus({ days: 3, hours: 5 }),
            end: baseTime.plus({ days: 3, hours: 6 }),
            participants: ["1"],
          }),
        ],
      }),
      new Visit({
        id: "5",
        type: "visit",
        start: baseTime.plus({ days: 4, hours: 4 }),
        end: baseTime.plus({ days: 3, hours: 5 }),
        location: "Kongefaret 4",
        participants: ["3"],
        tasks: [
          new Task({
            id: "5.1",
            parentId: "5",
            type: "supervision",
            start: baseTime.plus({ days: 4, hours: 4 }),
            end: baseTime.plus({ days: 4, hours: 5 }),
            participants: ["3"],
          }),
        ],
      }),
      new Visit({
        id: "6",
        type: "visit",
        start: baseTime.plus({ days: 5, hours: 4 }),
        end: baseTime.plus({ days: 5, hours: 4 }),
        location: "Blåbærsvingen 3",
        participants: ["2"],
        tasks: [
          new Task({
            id: "6.1",
            parentId: "6",
            type: "supervision",
            start: baseTime.plus({ days: 5, hours: 4 }),
            end: baseTime.plus({ days: 5, hours: 5 }),
            participants: ["2"],
          }),
        ],
      }),
      new Visit({
        id: "7",
        type: "visit",
        start: baseTime.plus({ days: 6, hours: 4 }),
        end: baseTime.plus({ days: 6, hours: 6 }),
        location: "Lyngen 44",
        participants: ["1"],
        tasks: [
          new Task({
            id: "7.1",
            parentId: "7",
            type: "supervision",
            start: baseTime.plus({ days: 6, hours: 4 }),
            end: baseTime.plus({ days: 6, hours: 6 }),
            participants: ["1"],
          }),
        ],
      }),
    ];
  }, [now]);

  const handleCalendarViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newCalendarView: CalendarView | null
  ) => {
    setCalendarView(newCalendarView ?? "month");
  };

  return (
    <AppPage
      $key={"SweepPlanning"}
      name={"Sweep Planning"}
      mainPane={
        <Stack direction={"column"} sx={{ flex: 1, minHeight: 0, minWidth: 0 }}>
          <PanelGroup
            direction={"horizontal"}
            style={{ flex: 1, minHeight: 0 }}
          >
            <Panel
              id={"SweepPlanningPage.Calendar"}
              defaultSize={50}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Toolbar variant={dense ? "dense" : "regular"}>
                <Typography align={"center"} sx={{ flex: 1 }}>
                  {monthName} {dateNow.year}
                </Typography>
                <ToggleButtonGroup
                  size={componentSize}
                  value={calendarView}
                  exclusive
                  onChange={handleCalendarViewChange}
                  sx={{ marginLeft: "auto" }}
                >
                  <ToggleButton value={"month"}>Month</ToggleButton>
                  <ToggleButton value={"week"}>Week</ToggleButton>
                  <ToggleButton value={"day"}>Day</ToggleButton>
                  <ToggleButton value={"agenda"}>Agenda</ToggleButton>
                </ToggleButtonGroup>
              </Toolbar>
              <VisitSettingsProvider>
                <Calendar
                  view={calendarView}
                  dateTimeNow={dateNow}
                  selectedDate={selectedDate}
                  events={events}
                  sx={{ overflow: "auto", flex: 1, minWidth: 0 }}
                  onNewEvent={(value) => <Box>New Event: {value?.toISO()}</Box>}
                  renderEvent={renderEvent}
                />
              </VisitSettingsProvider>
            </Panel>
            <ResizeHandle direction={"horizontal"} />
            <Panel
              id={"SweepPlanningPage.Table"}
              defaultSize={50}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  flex: 1,
                  minWidth: 0,
                  minHeight: 0,
                  backgroundColor: alpha("#909090", 0.5),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Table with Flues with Need for Sweep
              </Box>
            </Panel>
          </PanelGroup>
        </Stack>
      }
    />
  );
};

export const useSweepPlanningPageEnabled = (): boolean => {
  return localStorage.getItem("SweepPlanningPage") === "true";
};
