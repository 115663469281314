import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export enum ChimneySensorPlacementEnum {
  Roof,
  Attic,
}

export enum ChimneySensorPlacementStringEnum {
  Roof = "Roof",
  Attic = "Attic",
}

export const chimneySensorPlacementEnumToChimneySensorPlacementStringEnum = (
  value: ChimneySensorPlacementEnum
): ChimneySensorPlacementStringEnum => {
  if (value === ChimneySensorPlacementEnum.Attic) {
    return ChimneySensorPlacementStringEnum.Attic;
  } else if (value === ChimneySensorPlacementEnum.Roof) {
    return ChimneySensorPlacementStringEnum.Roof;
  } else {
    throw new IllegalArgumentError(value);
  }
};
