import { FC, ReactNode } from "react";
import { Variant } from "./Variant";
import { AppPageVariantContext } from "./AppPageVariantContext";

export const AppPageVariantProvider: FC<{
  value: Variant;
  children: ReactNode;
}> = (props) => {
  return (
    <AppPageVariantContext.Provider value={props.value}>
      {props.children}
    </AppPageVariantContext.Provider>
  );
};
