import {FC} from "react";
import {ErrorResponse} from "./ErrorResponse";
import {Alert, AlertTitle} from "@mui/material";
import {useLocation, Location} from "react-router-dom";
import {AlertColor} from "@mui/material/Alert/Alert";

const resolveMessageAndSeverity = (error: ErrorResponse, location: Location): { message: string | null, title?: string, severity?: AlertColor } => {
    if (error.status === 404) {
        return {
            severity: "warning",
            title: "Siden eksisterer ikke",
            message: location.pathname
        };
    }
    return {
        severity: "error",
        title: error.statusText,
        message: error.error?.message ?? null
    };
}

export const ErrorResponseAlert: FC<{ errorResponse: ErrorResponse }> = (props) => {
    const location = useLocation();
    const {title, message, severity} = resolveMessageAndSeverity(props.errorResponse, location);
    return (
        <Alert severity={severity} variant={"outlined"}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {message}
        </Alert>
    );
} 