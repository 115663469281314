import { isItem, Item } from "./Item";
import { ReactElement, useMemo } from "react";

export interface SelectableItem extends Item {
  selectable: true;
  selected: boolean;
}

export function isSelectableItem(obj: object): obj is SelectableItem {
  return isItem(obj) && "selectable" in obj && obj.selectable === true;
}

export const findSelectedItem = (
  items: Array<Item | ReactElement | object | undefined>
): SelectableItem | undefined => {
  return items.find((it) => {
    return it !== undefined && isSelectableItem(it) && it.selected;
  }) as SelectableItem | undefined;
};

export const useFindSelectedItem = (
  items: Array<Item | ReactElement | object | undefined>
): SelectableItem | undefined => {
  return useMemo(() => findSelectedItem(items), [items]);
};
