import { BurnNonSensitive, BurnNonSensitiveDto } from "./BurnNonSensitive";

export class BurnNonSensitiveUi extends BurnNonSensitive {
  readonly orderOfOccurence: number;
  readonly color: string;

  constructor(
    dto: BurnNonSensitiveDto,
    ui: { orderOfOccurence: number; color: string }
  ) {
    super(dto);
    this.orderOfOccurence = ui.orderOfOccurence;
    this.color = ui.color;
  }
}
