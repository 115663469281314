import React, { FC } from "react";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { Button } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ThumbDownAltRoundedIcon from "@mui/icons-material/ThumbDownAltRounded";
import { Stack, Typography } from "@mui/material";

export interface DeleteButtonProps {
  layout: SizeClass;
  askConfirmation: boolean;
  disabled?: boolean;
  onAskingConfirmation: (value: boolean) => void;
  onDeleteConfirmed: () => void;
}

export const DeleteButton: FC<DeleteButtonProps> = (props) => {
  const { askConfirmation } = props;
  const { t } = useTranslation("shared-ts-app-dialog");

  const handleDeleteInitiated = () => {
    props.onAskingConfirmation(true);
  };

  const handleDeleteConfirmed = () => {
    props.onDeleteConfirmed();
  };

  const handleDeleteCancel = () => {
    props.onAskingConfirmation(false);
  };

  return (
    <>
      {!askConfirmation && (
        <Button
          startIcon={<DeleteRoundedIcon />}
          variant={"text"}
          title={t("Delete")}
          disabled={props.disabled}
          onClick={handleDeleteInitiated}
        >
          {props.layout !== SizeClass.Compact && t("Delete")}
        </Button>
      )}
      {askConfirmation && (
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          <Typography variant={"button"}>{`${t(
            "Confirm Delete"
          )}:`}</Typography>
          <Button
            startIcon={<ThumbUpAltRoundedIcon />}
            variant={"outlined"}
            title={t("Yes")}
            onClick={handleDeleteConfirmed}
            sx={{ mr: 1 }}
          >
            {props.layout !== SizeClass.Compact && t("Yes")}
          </Button>
          <Button
            startIcon={<ThumbDownAltRoundedIcon />}
            variant={"text"}
            title={t("No")}
            onClick={handleDeleteCancel}
          >
            {props.layout !== SizeClass.Compact && t("No")}
          </Button>
        </Stack>
      )}
    </>
  );
};
