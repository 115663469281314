import { Release } from "shared-ts-release";

export const release_2024_2_0: Release = {
  version: "2024.2.0",
  date: "2024-11-01",
  entries: [
    {
      type: "Feature",
      heading: "Feiebehov",
      description:
        "Feiebehov viser antall røykløp med feiebehov pr. år og pr. måned frem i tid.",
    },
    {
      type: "Feature",
      heading: "Se et røykløps endring av opptenningskvalitet over tid",
      description:
        "Du kan nå se røykløps endring av opptenningskvalitet over tid.",
    },
    {
      type: "Usability",
      heading: "Ny navigasjon",
      description:
        "For å kunne bedre støtte flere meny-valg så har vi laget en ny navigasjons-meny.",
    },
    {
      type: "Usability",
      heading: "Valg av språk",
      description:
        "Brukerne av Chimney Insights kan nå velge hvilket språk man vil ha presentert applikasjonen i. " +
        " I første omgang støttes amerikansk engelsk og nynorsk utover bokmål.",
    },
    {
      type: "Usability",
      heading: "Støtte for lyst fargetema",
      description:
        "Du kan nå skifte mellom lyst og mørkt fargetema i hoved-menyen",
    },
    {
      type: "Usability",
      heading: "Brukerinnstillinger blir nå lagret sentralt",
      description:
        "Inntil denne versjonen har brukerinnstillinger bare blitt lagret lokalt i nettleseren. " +
        "Nå lagres disse i databasen, slik at innstillingene blir med deg, uansett hvilken enhet du logger inn på.",
    },
    {
      type: "Usability",
      heading: "Ny dokumentasjon",
      description:
        "Vi har laget en ny og mer utfyllende dokumentasjon av Chimney Insights.",
    },
  ],
};
