import {FC} from "react";
import {Alert, AlertTitle} from "@mui/material";
import {ApiError, HttpStatusText} from "@airmont/firefly/shared/ts/api";

export const ErrorAlert: FC<{ error: Error }> = (props) => {

    let title : string | undefined = props.error.name;
    let message: string | undefined = props.error.message;

    if(props.error instanceof ApiError ) {
      title = HttpStatusText[props.error.status];

      if(!message) {
        message = props.error.message ?? props.error.url;
      }
    }

    return (
        <Alert severity={"error"} variant={"outlined"}>
            {props.error instanceof ApiError &&
                <>
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </>
            }
            {!(props.error instanceof ApiError) && message}
        </Alert>
    );
}
