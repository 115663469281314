import { i18n } from "i18next";
import { ResourceBundle } from "./ResourceBundle";
import { standardLanguageFallbacks } from "./standardLanguageFallbacks";

export class I18NextResourceLoader {
  private i18nInstance: i18n;
  private languages: Array<string>;

  constructor(i18nInstance: i18n, languages: Array<string>) {
    this.i18nInstance = i18nInstance;
    this.languages = languages;
  }

  async load(resources: Array<ResourceBundle>): Promise<void> {
    await Promise.all(
      resources.map(async (resourceBundle) => {
        console.info(
          `I18NextResourceLoader: loading translations for namespace[${resourceBundle.namespace}]`
        );

        const bundle = await this.loadResourceBundle(resourceBundle);

        Object.entries(bundle).map(async ([language, translations]) => {
          this.i18nInstance.addResourceBundle(
            language,
            resourceBundle.namespace,
            translations,
            true,
            true
          );
        });
      })
    );
  }

  private loadResourceBundle = async (
    resourceBundle: ResourceBundle
  ): Promise<Record<string, any>> => {
    const record: Record<string, any> = {};
    const module: Record<string, unknown> = await resourceBundle.loader;
    this.languages.forEach((language) => {
      const translations = this.resolveTranslationsFromModule(language, module);
      if (translations != null) {
        record[language] = translations;
      }

      const fallBacks = standardLanguageFallbacks[language];
      if (fallBacks != null) {
        fallBacks.forEach((fallBack) => {
          if (record[fallBack] === undefined) {
            const fallbackTranslations = this.resolveTranslationsFromModule(
              fallBack,
              module
            );
            if (fallbackTranslations) {
              record[fallBack] = fallbackTranslations;
            }
          }
        });
      }
    });
    return Promise.resolve(record);
  };

  private resolveTranslationsFromModule(
    language: string,
    module: Record<string, unknown>
  ): Record<string, string> | undefined {
    let translations: Record<string, string> | undefined = undefined;

    translations = module[language.replace("-", "")] as unknown as Record<
      string,
      string
    >;

    if (translations === undefined) {
      translations = module[language.replace("-", "_")] as unknown as Record<
        string,
        string
      >;
    }
    return translations;
  }
}
