export class ApiError extends Error {

  readonly status: number;
  readonly url: string;

  constructor(status: number, url: string, message: string | undefined = undefined) {
    super(message);
    this.status = status;
    this.url = url;
  }
}
