import { Pin } from "@vis.gl/react-google-maps";
import React, { ReactElement, useMemo } from "react";
import { FeatureMarkerPinRenderProps } from "shared-ts-ui-react-google-maps";
import { darken } from "@mui/material";
import { SensorStatusGeoFeature } from "./SensorStatusGeoFeature";
import {
  ChimneySensorStatusEnum,
  ChimneySensorStatusEnumBySeverity,
  resolveChimneySensorStatusColorOrThrow,
} from "@airmont/firefly/shared/ts/domain";

export const SensorStatusPin = (
  props: FeatureMarkerPinRenderProps<SensorStatusGeoFeature>
): ReactElement => {
  const { feature } = props;
  const worstStatus = useMemo(() => {
    return feature.properties.sensors
      .map((it) => it.status)
      .reduce((a, c) => {
        const aSeverity = ChimneySensorStatusEnumBySeverity[a];
        const severity = ChimneySensorStatusEnumBySeverity[c];
        return severity > aSeverity ? c : a;
      }, ChimneySensorStatusEnum.Unknown);
  }, [feature.properties.sensors]);
  const color = useMemo(() => {
    return resolveChimneySensorStatusColorOrThrow(worstStatus);
  }, [worstStatus]);

  return (
    <Pin
      background={color}
      glyphColor={darken(
        color,
        feature.properties.sensors.length > 1 ? 0.7 : 0.3
      )}
      borderColor={"#000"}
      glyph={
        feature.properties.sensors.length > 1
          ? feature.properties.sensors.length.toString()
          : undefined
      }
    />
  );
};
