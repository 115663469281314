import { ObjectPath } from "@airmont/shared/ts/utils/core";
import { GridColDef } from "@mui/x-data-grid-premium";
import type { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import * as React from "react";
import { GridApiCommunity } from "@mui/x-data-grid/models/api/gridApiCommunity";

const objectPathByField: Record<string, ObjectPath> = {};

export const pathValueGetter = <
  R extends GridValidRowModel = GridValidRowModel,
  V = never,
  F = V,
  TValue = never,
  TV = V
>(
  value: TValue,
  row: R,
  column: GridColDef<R, V, F>,
  apiRef: React.MutableRefObject<GridApiCommunity>,
  valueGetter?: (value: V) => TV
): typeof valueGetter extends undefined ? V : TV => {
  let objectPath = objectPathByField[column.field];
  if (objectPath == null) {
    objectPath = ObjectPath.of(column.field);
    objectPathByField[column.field] = objectPath;
  }
  const valueFromPath = objectPath.getValue(row);
  if (valueGetter !== undefined) {
    return valueGetter(valueFromPath);
  }
  return valueFromPath;
};
