import { ArrayUtils } from "./ArrayUtils";

declare global {
  interface Array<T> {
    copyAndRemoveByPredicate(
      predicate: (item: T, index: number) => boolean
    ): Array<T>;
  }
}

if (!Array.prototype.copyAndRemoveByPredicate) {
  Array.prototype.copyAndRemoveByPredicate = function <T>(
    predicate: (it: T, index: number) => boolean
  ) {
    return ArrayUtils.copyAndRemoveByPredicate(this, predicate);
  };
}
