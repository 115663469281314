import { Duration } from "luxon";
import * as React from "react";
import { FC, useMemo } from "react";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { SxProps, Typography } from "@mui/material";
import { DurationFormatter } from "./DurationFormatter";
import { useLocale } from "shared-ts-locale";

type LocalesType =
  | Intl.UnicodeBCP47LocaleIdentifier
  | Array<Intl.UnicodeBCP47LocaleIdentifier>;

export interface DurationDisplayProps {
  locales?: LocalesType;
  duration: Duration;
  timeDirection?: "past" | "future" | "none";
  suffix?: string;
  maxUnits: number;
  shiftTo?: Intl.RelativeTimeFormatUnit[];
  layout?: SizeClass;
  component?: "p" | "span";
  sx?: SxProps;
}

export const DurationDisplay: FC<DurationDisplayProps> = (props) => {
  const layout = props.layout ?? SizeClass.Medium;
  const { luxonLocale } = useLocale();

  const locales = useMemo(() => {
    if (props.locales != null) return props.locales;
    return luxonLocale;
  }, [luxonLocale, props.locales]);
  const parts = useMemo(() => {
    const duration =
      props.shiftTo !== undefined
        ? props.duration.shiftTo(...props.shiftTo)
        : props.duration.shiftToAll();

    const lastUnit =
      props.shiftTo !== undefined
        ? props.shiftTo[props.shiftTo.length - 1]
        : undefined;

    return new DurationFormatter({
      locales: locales,
      options: {
        maxNumberOfParts: props.maxUnits,
        roundValues: true,
        timeDirection: props.timeDirection,
        lastUnit: lastUnit,
        style: layout === SizeClass.Compact ? "short" : "long",
      },
    }).formatToParts(duration);
  }, [
    props.duration,
    layout,
    props.timeDirection,
    props.maxUnits,
    props.shiftTo,
    locales,
  ]);

  return (
    <Typography
      sx={props.sx}
      component={props.component === "span" ? "span" : "p"}
    >
      {parts.map((part, index) => {
        const isFirst = index === 0;
        const isLast = index === parts.length - 1;

        return (
          <span style={{ whiteSpace: "nowrap" }} key={index}>
            {`${part.value}${
              part.type === "literal" && !(isLast || isFirst) ? ", " : ""
            }`}
          </span>
        );
      })}
      {props.suffix != null && ` ${props.suffix}`}
    </Typography>
  );
};
