import {FC, ReactNode} from "react";
import {useResizeDetector} from "react-resize-detector";

export interface FitContainerProps {
    children: (size: { width: number, height: number }) => ReactNode;
}

export const FitContainerPane: FC<FitContainerProps> = (props) => {
    const {width, height, ref} = useResizeDetector();

    return (
        <div style={{position: "absolute", top: 0, bottom:0, left:0, right: 0}} ref={ref} className={"FitContainerPane"}>
            {width != null && height != null && (
                props.children({width, height})
            )}
        </div>
    );
}
