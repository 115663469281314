import { ValueOptions } from "./ValueOptions";
import { isValueOption } from "./ValueOption";
import { PropertyValue } from "./PropertyValue";
import { isLink } from "./Link";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export class ValueOptionsUtils {
  static resolveDisplayValue = (
    value: PropertyValue,
    valueOptions: ValueOptions
  ): string => {
    const valueOption = valueOptions.options.find((it) => {
      if (typeof it === "string") {
        return it === value;
      } else if (isValueOption(it)) {
        return it.id === value;
      } else {
        throw new Error("Unsupported type of value: " + JSON.stringify(it));
      }
    });

    if (value === undefined || value === null || value === "") {
      return "";
    } else if (isValueOption(valueOption)) {
      return valueOption.label;
    } else if (typeof value === "string") {
      return value;
    } else if (typeof value === "number") {
      return value.toString();
    } else if (typeof value === "boolean") {
      return value.toString();
    } else if (value instanceof File) {
      return value.name;
    } else if (isLink(value)) {
      return value.label ?? "";
    } else {
      throw new IllegalArgumentError(
        "value not supported: " + JSON.stringify(value)
      );
    }
  };
}
