import { FlueTypeStringsEnum } from "@airmont/firefly/shared/ts/domain";
import { RangeDto } from "@airmont/shared/ts/utils/core";
import { AbnormalBurnsFilterFormValues } from "./AbnormalBurnsFilterFormValues";

export interface FluesWithAbnormalBurnsFilterFormValues {
  street: string | undefined;
  flueTypes: Array<FlueTypeStringsEnum> | undefined;
  unfinished: boolean | undefined;
  minDurationInHours: number | undefined;
  maxTemperature: RangeDto | undefined;
  minMaxDeltaTemperature: number | undefined;
  maxMaxDeltaTemperature: number | undefined;
  requireDeviantPresumedFlueType: boolean | undefined;
  presumedFlueTypeConfidence: RangeDto | undefined;
  presumedFlueTypes: Array<FlueTypeStringsEnum> | undefined;
}

export const transform_FluesWithAbnormalBurnsFilterFormValues_to_AbnormalBurnsFilterFormValues =
  (
    values: FluesWithAbnormalBurnsFilterFormValues
  ): AbnormalBurnsFilterFormValues => {
    return {
      unfinished: values.unfinished,
      minDurationInHours: values.minDurationInHours,
      maxTemperature: values.maxTemperature,
      selfSimilarity: undefined,
      minMaxDeltaTemperature: values.minMaxDeltaTemperature,
      maxMaxDeltaTemperature: values.maxMaxDeltaTemperature,
      requireDeviantPresumedFlueType: values.requireDeviantPresumedFlueType,
      presumedFlueTypes: values.presumedFlueTypes,
      presumedFlueTypeConfidence: values.presumedFlueTypeConfidence,
    };
  };
