import React, { FC, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Toolbar } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useMuiLocalization } from "shared-ts-mui";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { useQueryClient } from "@tanstack/react-query";
import { GridInputRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel";
import { SettingsInfo } from "../shared/SettingsInfo";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import { SettingsGroup } from "../shared/SettingsGroup";
import { GridRowPersistedState } from "../shared/GridRowPersistedState";
import { getGridRowActions } from "../shared/GridRowActions";
import {
  ContactDto,
  ContactId,
  useContactDao,
} from "@airmont/firefly/shared/ts/domain";
import { useNotify } from "@airmont/shared/ts/ui/notification";
import { countryCallingCodeValueOptions } from "./countryCallingCodeValueOptions";
import { useUser } from "shared-ts-utils-authentication";
import { useTranslation } from "react-i18next";
import { ObjectUtils } from "@airmont/shared/ts/utils/core";

type ContactRow = ContactDto & GridRowPersistedState;
export const ContactsSettingsPane: FC = () => {
  const { muiLocalization } = useMuiLocalization();
  const { t } = useTranslation("firefly-chimney-insights-ts-settings");
  const { t: tSharedDomain } = useTranslation("firefly-shared-ts-domain");
  const notify = useNotify();
  const contactDao = useContactDao();
  const queryClient = useQueryClient();
  const apiRef = useGridApiRef();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const user = useUser();
  const hasAdminRole = user.hasRole("Admin");

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<Array<ContactRow>>([]);

  const handleDeleteClick = async (id: GridRowId): Promise<void> => {
    const rowToDelete = apiRef.current.getRow(id);
    if (rowToDelete != null) {
      await contactDao.deleteById(id as ContactId);
      notify.success({
        message: `${t("Deleted contact")}: ${rowToDelete.name}`,
        options: {
          autoHideDuration: 5000,
        },
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "email",
      headerName: t("Email"),
      editable: true,
      width: 140,
    },
    {
      field: "phone",
      headerName: t("Telephone"),
      editable: true,
    },
    {
      field: "mobile",
      headerName: t("Mobile"),
      editable: true,
    },
    {
      field: "countryCode",
      headerName: t("Country"),
      editable: true,
      width: 150,
      type: "singleSelect",
      valueOptions: countryCallingCodeValueOptions,
    },
  ];
  if (hasAdminRole) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      getActions: (params) =>
        getGridRowActions<ContactRow>({
          params: params,
          rows: rows,
          setRows: setRows,
          rowModesModel: rowModesModel,
          setRowModesModel: setRowModesModel,
          onDelete: hasAdminRole ? handleDeleteClick : undefined,
          disableEdit: !hasAdminRole,
          t: tSharedDomain,
        }),
    });
  }
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [selectionModel, setSelectionModel] =
    useState<GridInputRowSelectionModel>([]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const contacts = await queryClient.fetchQuery({
          queryKey: ["contact/list", contactDao.queryKey()],
          queryFn: () => contactDao.getList(),
        });
        setLoading(false);
        setRows(contacts);
      } catch (e) {
        setLoading(false);
        notify.error({ error: e });
      }
    };
    fetch();
  }, [notify, queryClient, contactDao]);

  const handleColumnVisibilityChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
  };

  /*const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    setSelectionModel(selectionModel);
  };*/
  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleNewClick = () => {
    const id = uuidv4();

    setRows((oldRows) => [
      ...oldRows,
      {
        isNew: true,
        id: id,
        name: "",
        email: "",
        phone: "",
        mobile: "",
        countryCode: "0047",
      } as ContactDto & GridRowPersistedState,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  const processRowUpdate = async (
    changedRow: ContactRow,
    original: ContactRow | null,
    params: { rowId: GridRowId }
  ) => {
    const updatedRow = { ...changedRow, isNew: false };

    if (changedRow.isNew || original == null) {
      await contactDao.create(changedRow);
      notify.success({
        message: `${t("Created new contact")} : ${changedRow.name}`,
        options: {
          autoHideDuration: 5000,
        },
      });
    } else if (!ObjectUtils.equals(changedRow, original)) {
      await contactDao.update(changedRow);
      notify.success({
        message: `${t("Updated the contact")} : ${changedRow.name}`,
        options: {
          autoHideDuration: 5000,
        },
      });
    }

    return updatedRow;
  };

  const handleProcessRowUpdateError = (error: Error) => {
    console.error(error);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SettingsInfo>
        {t("Contacts are persons who can receive notifications")}
      </SettingsInfo>
      <SettingsGroup name={t("Contacts")}>
        <Toolbar disableGutters>
          {hasAdminRole && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleNewClick}
            >
              {t("Add")}
            </Button>
          )}
        </Toolbar>
        <Box
          sx={{
            height: "500px",
            width: "100%",
          }}
        >
          <DataGridPremium
            apiRef={apiRef}
            initialState={{
              pinnedColumns: {
                left: ["name"],
                right: ["actions"],
              },
            }}
            localeText={
              muiLocalization.components.MuiDataGrid.defaultProps.localeText
            }
            columnVisibilityModel={columnVisibilityModel}
            slots={{
              loadingOverlay: LinearProgress as never,
            }}
            hideFooterPagination
            disableColumnFilter
            disableRowGrouping
            disableAggregation
            loading={loading}
            columns={columns}
            rows={rows}
            rowCount={rows?.length ?? 0}
            editMode={"row"}
            rowModesModel={rowModesModel}
            sortingMode="client"
            sortModel={sortModel}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={setSelectionModel}
            onSortModelChange={handleSortModelChange}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </SettingsGroup>
    </Box>
  );
};
