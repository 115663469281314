export class ObjectUtils {
  static copy<T>(obj: T): T {
    return Object.assign({}, obj);
  }

  static update<T>(obj: T, partialObj: T | Partial<T>): T {
    return Object.assign({}, obj, partialObj);
  }

  static delete<T extends Record<string, unknown>>(
    obj: T,
    property: string
  ): T {
    if (obj[property] === undefined) {
      return obj;
    }

    const copy: T = Object.assign({}, obj) as T;
    delete copy[property];
    return copy;
  }

  /*static removeUndefined<T extends { [s: string]: unknown; }>(obj: T, recursive = false): T {
    const newObj = {} as T;
    Object.entries(obj).forEach((entry) => {
      if (entry[1] !== undefined) {
        const key = entry[0];
        const value = entry[1];
        newObj[key] =
          typeof value === "object" && recursive
            ? ObjectUtils.removeUndefined(value, recursive)
            : value;
      }
    });

    return newObj as T;
  }*/

  static map<K extends string, V, R>(
    obj: Record<K, V>,
    fn: (arg0: V, arg1: K, arg2: number) => R
  ): Record<string, R> {
    const entries: [string, R][] = Object.entries(obj).map(([k, v], index) => [
      k as any,
      fn(v as any, k as any, index),
    ]);
    const record: Record<string, R> = {};
    for (const [key, value] of entries) {
      record[key] = value;
    }
    return record;
  }

  static filterByKeys<T extends Record<string, any>>(
    keys: Array<string>,
    object: Record<string, any>,
    ifNull: (key: string) => any = () => null
  ): T {
    const newObject: Record<string, any> = {};
    keys.forEach((key: string) => {
      const existing = object[key];

      if (existing != null) {
        newObject[key] = existing;
      } else {
        if (ifNull != null) {
          const ifNullResult = ifNull(key);

          if (ifNullResult != null) {
            newObject[key] = ifNullResult;
          }
        }
      }
    });
    return newObject as any;
  }

  static toMap<V>(obj: Record<string, V>): Map<string, V> {
    const map = new Map();
    Object.entries(obj).forEach(([key, value]) => {
      map.set(key, value as unknown);
    });
    return map;
  }

  static toMapAndTransformValue(
    obj: Record<string, unknown>,
    valueTransformer: (arg0: unknown) => unknown
  ): Map<string, unknown> {
    const map = new Map<string, unknown>();
    Object.entries(obj).forEach(([key, value]) => {
      const transformedValue = valueTransformer(value);
      map.set(key, transformedValue);
    });
    return map;
  }

  static equals(a: unknown, b: unknown): boolean {
    if (a === b) {
      return true;
    }

    if (a == null || b == null) {
      return false;
    }

    if (Array.isArray(a) && Array.isArray(b)) {
      if (a === b) {
        return true;
      }

      if (a == null || b == null) {
        return false;
      }

      if (a.length !== b.length) {
        return false;
      }

      for (let i = 0; i < a.length; ++i) {
        if (!ObjectUtils.equals(a[i], b[i])) {
          return false;
        }
      }

      return true;
    }

    if (Object.is(a, b)) {
      return true;
    }

    if (typeof a === "string" || typeof a === "number") {
      return a === b;
    }

    if (
      (a as { constructor: unknown }).constructor !==
      (b as { constructor: unknown }).constructor
    ) {
      return false;
    }

    const equalsFnOfA: (other: unknown) => boolean = (
      a as { equals: (other: unknown) => boolean }
    ).equals;

    if (equalsFnOfA && typeof equalsFnOfA === "function") {
      return equalsFnOfA.bind(a)(b);
    }

    const aAsObject = a as { [prop: string]: unknown };
    const bAsObject = b as { [prop: string]: unknown };

    const keysOfA = Object.keys(aAsObject);
    const keysOfB = Object.keys(bAsObject);

    if (!ObjectUtils.equals(keysOfA, keysOfB)) {
      return false;
    }

    for (const keyOfA of keysOfA) {
      const valueOfA = aAsObject[keyOfA];
      const valueOfB = bAsObject[keyOfA];
      const valuesEqual = ObjectUtils.equals(valueOfA, valueOfB);

      if (!valuesEqual) {
        return false;
      }
    }

    return true;
  }
}
