import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  SettingsContextDictionary,
  UserSettingsContext,
} from "./UserSettingsContext";
import { useUserOrNull } from "shared-ts-utils-authentication";
import { useAppInfo } from "shared-ts-app-info";
import { SettingsStorage } from "./storage/SettingsStorage";
import { LocalSettingsStorage } from "./storage/LocalSettingsStorage";
import { NonAsyncSettingsStorage } from "./storage/NonAsyncSettingsStorage";
import { ReadOnlyUserSettings } from "./ReadOnlyUserSettings";

export interface UserSettingsProviderProps {
  storage?: SettingsStorage;
  children: ReactNode | ((settings: ReadOnlyUserSettings) => ReactNode);
}

export const UserSettingsProviderNonAsync: FC<UserSettingsProviderProps> = (
  props
) => {
  const user = useUserOrNull();
  const userId = user?.id;
  const app = useAppInfo();
  const appKey = app.key;

  const [entries, setEntries] = useState<SettingsContextDictionary | undefined>(
    {}
  );
  const storage = props.storage ?? LocalSettingsStorage;
  useEffect(() => {
    const doEffect = async () => {
      const nonAsyncStorage = storage as NonAsyncSettingsStorage;
      const loadedEntries: SettingsContextDictionary =
        nonAsyncStorage.loadSettings(appKey, userId);

      console.debug(
        `UserSettingsProvider loaded the following settings user: ${userId}`
      );
      Object.entries(loadedEntries).forEach(([key, value]) => {
        const debugKey = key.substring(key.lastIndexOf(":") + 1);
        console.debug(`${debugKey}: ${value}`);
      });

      setEntries(loadedEntries);
    };
    doEffect();
  }, []);

  const readOnlySettings = useMemo(() => {
    if (entries === undefined) {
      return undefined;
    }
    return new ReadOnlyUserSettings({
      app: app.key,
      user: user,
      settings: entries,
    });
  }, [app.key, user, entries]);

  if (entries === undefined || readOnlySettings === undefined) {
    return null;
  }

  return (
    <UserSettingsContext.Provider
      value={{
        settings: entries,
        setSettings: setEntries as Dispatch<
          SetStateAction<SettingsContextDictionary>
        >,
        storage: storage,
        localStorage: LocalSettingsStorage,
      }}
    >
      {typeof props.children === "function"
        ? props.children(readOnlySettings)
        : props.children}
    </UserSettingsContext.Provider>
  );
};
