import { DialogTitle, Stack, Typography, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { FlueBreadcrumbs } from "@airmont/firefly/shared/ts/domain";
import { FlueDialogContent } from "./FlueDialogContent";
import { notNull, notUndef } from "@airmont/shared/ts/utils/core";
import { Button } from "shared-ts-mui";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { useTranslation } from "react-i18next";
import { AppDialog, DisplayMode } from "shared-ts-app-dialog";

export interface FlueDialogProps {
  flueStatus?: FlueStatusDto;
  open: boolean;
  onGoNext?: () => void;
  onGoPrevious?: () => void;
  onUpdated: (updated: FlueStatusDto) => void;
  onDeleted: (deleted: FlueStatusDto) => void;
  onClose: () => void;
}

export const FlueDialog = (props: FlueDialogProps) => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: t_firefly_shared_domain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const windowWidthComparer = useWindowWidthComparer();
  const [actionsContainer, setActionsContainer] = useState<Element | null>(
    null
  );
  const [displayMode, setDisplayMode] = useState<DisplayMode>("view");
  const fullScreen = windowWidthComparer.lte(SizeClass.Compact);

  const handleNavigatePrevious = () => {
    props.onGoPrevious?.();
  };

  const handleNavigateNext = () => {
    props.onGoNext?.();
  };
  const handleModeChange = (mode: DisplayMode) => {
    setDisplayMode(mode);
  };

  const handleClose = () => {
    setDisplayMode("view");
    props.onClose();
  };

  const actionsContainerRef = useCallback((node: Element | null) => {
    if (node != null) {
      setActionsContainer(node);
    }
  }, []);

  return (
    <AppDialog
      open={props.open}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={"xl"}
      onDisplayModeChange={handleModeChange}
      onClose={handleClose}
      slots={{
        DialogTitle: (
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              pt: 1,
              pb: 1,
              pl: 1,
              pr: 1,
            }}
          >
            <Stack direction={"row"} sx={{ justifySelf: "left" }}>
              <Button
                title={t("Close")}
                startIcon={<CloseRoundedIcon />}
                color={"inherit"}
                onClick={handleClose}
              />
              <Stack direction={"row"}>
                <Button
                  title={t("Previous")}
                  startIcon={<NavigateBeforeRoundedIcon />}
                  color={"inherit"}
                  onClick={handleNavigatePrevious}
                  disabled={props.onGoPrevious === undefined}
                />
                <Button
                  title={t("Next")}
                  startIcon={<NavigateNextRoundedIcon />}
                  color={"inherit"}
                  onClick={handleNavigateNext}
                  disabled={props.onGoNext === undefined}
                />
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              useFlexGap
              gap={1}
              sx={{ alignItems: "center" }}
            >
              {displayMode === "edit" &&
                windowWidthComparer.gte(SizeClass.Expanded) && (
                  <EditRoundedIcon />
                )}
              {displayMode === "view" &&
                windowWidthComparer.gte(SizeClass.Expanded) && (
                  <VisibilityRoundedIcon />
                )}
              {windowWidthComparer.gte(SizeClass.Expanded) && (
                <Typography>
                  {t_firefly_shared_domain("Flue")}:&nbsp;
                </Typography>
              )}
              {props.flueStatus &&
                windowWidthComparer.gte(SizeClass.Expanded) && (
                  <FlueBreadcrumbs
                    municipality={props.flueStatus.municipality}
                    postalAddress={props.flueStatus.postalAddress}
                    streetAddress={props.flueStatus.streetAddress}
                    flue={props.flueStatus.flue}
                    hideMunicipality
                    layout={windowWidthComparer.sizeClass}
                    sx={{ marginLeft: theme.spacing(1) }}
                  />
                )}
            </Stack>
            <Stack
              sx={{ marginLeft: "auto" }}
              ref={actionsContainerRef}
              direction={"row"}
              useFlexGap
              gap={1}
            />
          </DialogTitle>
        ),
        DialogContent: notUndef(props.flueStatus, (flueStatus) =>
          notNull(actionsContainer, (actionsContainer) => (
            <FlueDialogContent
              flueStatus={flueStatus}
              layout={windowWidthComparer.sizeClass}
              displayFlueBreadCrumbs={windowWidthComparer.lte(SizeClass.Medium)}
              mode={displayMode}
              actionsContainer={actionsContainer}
              onDisplayModeChange={handleModeChange}
              onUpdated={props.onUpdated}
              onDeleted={props.onDeleted}
              sx={{ pt: 0 }}
            />
          ))
        ),
      }}
    ></AppDialog>
  );
};
