export class AddressUtils {
    static resolveStreetAddress(address: { street?: string, houseNumber?: number, houseLetter?: string }): string {
        let streetAddress = address.street ?? "";
        if (address.houseNumber != null) {
            streetAddress += " " + address.houseNumber;
            if (address.houseLetter != null) {
                streetAddress += " " + address.houseLetter;
            }
        }
        return streetAddress
    }
}