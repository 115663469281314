import React, {FC, KeyboardEvent, useRef, useState} from "react";
import {Box, IconButton, InputBase, useTheme} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export interface SearchInputProps {
    fullWidth?: boolean;
    inputPlaceholder?: string;
    defaultValue: string | null | undefined;

    onValueChange: (value: string) => void;
}

export const SearchInput: FC<SearchInputProps> = (props) => {

    const theme = useTheme();
    const [focus, setFocus] = useState(false);
    const searchTextRef = useRef<HTMLInputElement | null>(null);

    const handleSearchTextKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Enter") {

            if (searchTextRef.current != null) {
                const searchText = searchTextRef.current.value;
                props.onValueChange(searchText);
            }
        }
    }

    const handleSearchSubmit = () => {
        if (searchTextRef.current != null) {
            const searchText = searchTextRef.current?.value;
            if (searchText != null) {
                props.onValueChange(searchText);
            }
        }
    }

    const handleFocus = () => {
        setFocus(true);
    }

    const handleBlur = () => {
        setFocus(false);
    }

    return (
        <Box
            sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                border: focus ? "1px solid " + theme.palette.primary.main : "1px solid " + theme.palette.divider,
                borderRadius: "3px"
            }}
        >
            <InputBase
                autoFocus
                inputRef={searchTextRef}
                fullWidth={props.fullWidth}
                defaultValue={props.defaultValue}
                sx={{flex: 1}}
                placeholder={props.inputPlaceholder}
                inputProps={{
                    "aria-label": props.inputPlaceholder,
                    padding: "13px"
                }}
                onKeyUp={handleSearchTextKeyUp}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <IconButton
                type="button"
                sx={{p: '10px'}}
                aria-label="search"

                onClick={handleSearchSubmit}
            >
                <SearchIcon/>
            </IconButton>
        </Box>
    );
}