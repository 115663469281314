import React, { FC, useMemo } from "react";
import { TabPanel, TabPanelProps } from "@airmont/shared/ts/ui/tabs";
import { SxProps } from "@mui/system";
import { merge } from "lodash";
import { DevicePicturesForm } from "./DevicePicturesForm";
import { FlueId } from "@airmont/firefly/shared/ts/domain";

export interface PicturesTabPanelProps extends TabPanelProps {
  flueId: FlueId;
}

export const PicturesTabPanel: FC<PicturesTabPanelProps> = (props) => {
  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      display: "flex",
      flexDirection: "column",
      pt: 1,
      pb: 1,
    });
  }, [props.sx]);

  return (
    <TabPanel tabValue={props.tabValue} sx={sx}>
      <DevicePicturesForm id={props.flueId} sx={{ flex: 1 }} />
    </TabPanel>
  );
};
