import React, { useMemo } from "react";
import { Tooltip, Typography, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { NotificationContextProvider } from "@airmont/shared/ts/ui/notification";
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
import { PaperElevation } from "@airmont/shared/ts/ui/elevation";
import { useWindowWidthComparer } from "@airmont/shared/ts/ui/responsive";
import { AppLayout, Variant } from "@airmont/shared/ts/ui/app-layout";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import RoofingRoundedIcon from "@mui/icons-material/RoofingRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import {
  Action,
  ActionGroup,
  LinkItem,
  RouteItem,
  SelectableAction,
} from "@airmont/shared/ts/ui/action";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import FireExtinguisherRoundedIcon from "@mui/icons-material/FireExtinguisherRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import {
  StringSetting,
  useUserSetting,
} from "@airmont/shared/ts/utils/user-settings";
import { useTranslation } from "react-i18next";
import { useUser } from "shared-ts-utils-authentication";
import { useNavigation } from "@airmont/shared/ts/ui/navigation";
import { useOidcPaths } from "@airmont/firefly/shared/ts/authentication-oidc";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { useCustomerEnvironmentContext } from "@airmont/firefly/shared/ts/customer-environment";
import { useMunicipalityContext } from "@airmont/firefly/chimney-insights/ts/domain";
import { useAppInfo } from "shared-ts-app-info";
import { useAbnormalBurnsPageEnabled } from "./supervision/abnormalBurns/AbnormalBurnsPage";
import { useSweepPlanningPageEnabled } from "./sweepPlanning/SweepPlanningPage";
import { PostHogTrackReactRouterPageView } from "shared-ts-posthog";
import { useGenerateErrorPageEnabled } from "shared-ts-sentry";

export const RootPage = () => {
  const theme = useTheme();
  const appInfo = useAppInfo();
  const [appTheme, setAppTheme] = useUserSetting("theme", StringSetting);
  const user = useUser();
  const windowWidthComparer = useWindowWidthComparer();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: t_shared_ts_release } = useTranslation("shared-ts-release");
  const hasAdminRole = user.hasRole("Admin");
  const oidcPaths = useOidcPaths();
  const manageUsersHref = oidcPaths?.IdentityManageUsersPath;
  const abnormalBurnsPageEnabled = useAbnormalBurnsPageEnabled();
  const sweepPlanningPageEnabled = useSweepPlanningPageEnabled();
  const generateErrorPageEnabled = useGenerateErrorPageEnabled();
  const { environmentId, environment, environmentList, setEnvironment } =
    useCustomerEnvironmentContext();
  const appLayoutVariant: Variant =
    (localStorage.getItem("AppLayout.variant") as Variant) ?? "flat";
  const {
    municipalities,
    municipality: municipalityId,
    setMunicipality,
  } = useMunicipalityContext();

  const municipality = useMemo(
    () => municipalities.find((it) => it.id === municipalityId),
    [municipalityId, municipalities]
  );

  const { navigationItems, handleSelected } = useNavigation(
    [
      {
        type: "Component",
        render: (navigationType) => (
          <AirmontLogoImg
            size={navigationType === "drawer" ? "standard" : "small"}
            sx={{ m: 1 }}
          />
        ),
      },
      {
        type: "Component",
        render: (navigationType) => (
          <Typography
            variant={"button"}
            color={"secondary"}
            align={"center"}
            sx={{
              paddingTop: theme.spacing(1),
              textTransform: "unset",
              fontSize: navigationType === "drawer" ? "2em" : undefined,
            }}
          >
            {t("Chimney Insights")}
          </Typography>
        ),
      },
      {
        type: "Component",
        render: (navigationType) => (
          <Tooltip
            title={t_shared_ts_release("Release")}
            arrow
            placement={"right"}
          >
            <Typography
              variant={"button"}
              align={"center"}
              color={"text.secondary"}
              sx={{
                textTransform: "unset",
                fontSize: navigationType === "rail" ? "10px" : undefined,
                fontWeight: navigationType === "rail" ? 500 : undefined,
              }}
            >
              {appInfo.release}
            </Typography>
          </Tooltip>
        ),
      },
      {
        type: "RouteItem",
        selectable: true,
        name: t("Overview"),
        route: "/",
        icon: <AnalyticsRoundedIcon />,
        selected: false,
      } as RouteItem,
      {
        type: "ActionGroup",
        name: t("Fire Prevention"),
        nameBreakable: t("Fire Prevention:breakable"),
        reference: "fire-prevention",
        icon: <FireExtinguisherRoundedIcon />,
        selected: false,
        preferences: {
          displayInline: true,
          displayGroupNode: true,
          hideGroupNodeSelected: true,
          medium: {
            displayInline: false,
            displayGroupNode: true,
            hideGroupNodeSelected: true,
          },
        },
        items: [
          {
            type: "RouteItem",
            selectable: true,
            name: t("Chimney Status"),
            route: "/chimney-status",
            icon: <RoofingRoundedIcon />,
            selected: false,
          } as RouteItem,
          {
            type: "RouteItem",
            selectable: true,
            name: t("Need for Sweep"),
            nameBreakable: t("Need for Sweep:breakable"),
            route: "/need-for-sweep",
            icon: <PendingActionsRoundedIcon />,
            selected: false,
          } as RouteItem,
          sweepPlanningPageEnabled
            ? ({
                type: "RouteItem",
                selectable: true,
                name: t("Sweep Planning"),
                nameBreakable: t("Sweep Planning:breakable"),
                route: "/sweep-planning",
                icon: <CalendarMonthRoundedIcon />,
                selected: false,
              } as RouteItem)
            : undefined,
          abnormalBurnsPageEnabled
            ? ({
                type: "RouteItem",
                selectable: true,
                name: t("Abnormal Burns"),
                route: "/abnormal-burns",
                icon: <FireExtinguisherRoundedIcon />,
                selected: false,
              } as RouteItem)
            : undefined,
        ],
      } as ActionGroup,
      {
        type: "RouteItem",
        selectable: true,
        route: "/sensor-status",
        name: t("Sensor Status"),
        icon: <SensorsRoundedIcon />,
        selected: false,
      } as RouteItem,
      { type: "Spacer" },
      environmentList.length > 1
        ? ({
            type: "ActionGroup",
            icon: <StorageRoundedIcon />,
            name: t("Environment"),
            preferences: {
              displayInline: false,
              hideGroupNodeSelected: true,
            },
            disabled: environmentList.length === 1,
            items: environmentList.map((env) => {
              return {
                type: "Action",
                selectable: true,
                name: env.name,
                reference: env.id,
                onExecute: () => {
                  setEnvironment(env.id);
                },
                selected: env.id === environmentId,
              } as SelectableAction;
            }),
          } as ActionGroup)
        : undefined,
      {
        type: "ActionGroup",
        icon: <LocationCityRoundedIcon />,
        name: t("Municipality"),
        hide: municipalities.length === 0,
        preferences: {
          displayInline: false,
          hideGroupNodeSelected: true,
        },
        items: [
          {
            type: "Action",
            selectable: true,
            name: t("All"),
            onExecute: () => {
              setMunicipality(undefined);
            },
            selected: municipalityId == null,
          } as SelectableAction,
          ...municipalities.map((currMunicipality) => {
            return {
              type: "Action",
              selectable: true,
              name: `${currMunicipality.name} (${currMunicipality.id})`,
              reference: currMunicipality.id,
              onExecute: () => {
                setMunicipality(currMunicipality.id);
              },
              selected: currMunicipality.id === municipalityId,
            } as SelectableAction;
          }),
        ],
      } as ActionGroup,
      {
        type: "ActionGroup",
        icon: <MenuRoundedIcon />,
        preferences: {
          displayInline: true,
        },
        items: [
          {
            type: "ActionGroup",
            name: t("Settings"),
            icon: <SettingsRoundedIcon />,
            preferences: {
              displayInline: false,
              hideSelected: true,
            },
            items: [
              {
                type: "RouteItem",
                selectable: true,
                route: "user",
                name: user.name,
                icon: <PersonRoundedIcon />,
                selected: false,
              } as RouteItem,
              hasAdminRole
                ? ({
                    type: "LinkItem",
                    name: "Brukere",
                    icon: <PeopleRoundedIcon />,
                    href: manageUsersHref,
                  } as LinkItem)
                : undefined,
              {
                type: "RouteItem",
                selectable: true,
                route: "organization",
                name: environment?.name,
                icon: <ApartmentRoundedIcon />,
                selected: false,
              } as RouteItem,
            ],
          } as ActionGroup,
          {
            type: "Action",
            name: appTheme === "dark" ? t("Light") : t("Dark"),
            icon:
              appTheme === "dark" ? (
                <LightModeRoundedIcon />
              ) : (
                <DarkModeRoundedIcon />
              ),
            onExecute: (item) => {
              setAppTheme(appTheme === "dark" ? "light" : "dark");
            },
          } as Action,
          generateErrorPageEnabled
            ? ({
                type: "RouteItem",
                selectable: true,
                route: "/generate-error",
                name: "Generate Error",
                icon: <ErrorOutlineRoundedIcon />,
                selected: false,
              } as RouteItem)
            : undefined,
          {
            type: "ActionGroup",
            name: t("Help"),
            icon: <HelpRoundedIcon />,
            preferences: {
              displayInline: false,
              hideSelected: true,
              layoutDirection: "row",
            },
            items: [
              {
                type: "LinkItem",
                name: `${appInfo.name} - ${t("user guide")}`,
                href: "/docs/index.html",
              } as LinkItem,
              {
                type: "LinkItem",
                name: `${t("Registration of Chimney Sensor")} - ${t(
                  "user guide"
                )}`,
                href: "https://airmontstorage.blob.core.windows.net/public-firefly/userguide-register-chimney-sensor_no.pdf",
              } as LinkItem,
              {
                type: "LinkItem",
                name: `${t("Registration of Chimney Sensor")} - ${t("video")}`,
                href: "https://airmontstorage.blob.core.windows.net/public-firefly/register-chimney-sensor_no.m4v",
              } as LinkItem,
            ],
          } as ActionGroup,
          {
            type: "RouteItem",
            selectable: true,
            route: "/about",
            name: `${t("About")} ${t("Chimney Insights")}`,
            icon: <InfoRoundedIcon />,
            selected: false,
          } as RouteItem,
        ],
      } as ActionGroup,
    ],
    [
      windowWidthComparer,
      appTheme,
      environmentId,
      environmentList,
      municipality,
      municipalities,
      t,
    ]
  );

  return (
    <NotificationContextProvider>
      <AppLayout
        variant={appLayoutVariant}
        navigationItems={navigationItems}
        main={
          <PaperElevation elevation={1}>
            <Outlet />
          </PaperElevation>
        }
        onNavigationItemSelected={handleSelected}
      />
      <PostHogTrackReactRouterPageView />
    </NotificationContextProvider>
  );
};
