import { useEffect, useState } from "react";
import { DateTime, Duration } from "luxon";

const defaultDuration = Duration.fromDurationLike({ hour: 1 });
export const useDateTimeNow = (
  changeDuration: Duration = defaultDuration
): DateTime<true> => {
  const [now, setNow] = useState<DateTime<true>>(DateTime.now());
  const changeDurationAsMillis = changeDuration.toMillis();

  useEffect(() => {
    setInterval(() => {
      setNow(DateTime.now());
    }, changeDurationAsMillis);
  }, [changeDurationAsMillis]);

  return now;
};
