import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { usePostHogEnabled } from "./PosthogEnabled";

export const usePostHogTrackReactRouter = () => {
  const postHogEnabled = usePostHogEnabled();
  const postHog = usePostHog();
  const { pathname, search } = useLocation();
  useEffect(() => {
    if (postHogEnabled) {
      postHog.capture("$pageview", {
        $current_url: pathname + search,
      });
    }
  }, [pathname, search, postHogEnabled, postHog]);
};
