import { FlueMetricsTimeUnitEnum } from "../FlueMetricsTimeUnit";
import { DateTime } from "luxon";
import { FlueId } from "../../FlueId";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface FlueMetricsQuery {
  flueId: FlueId;
  timeUnit?: FlueMetricsTimeUnitEnum;
  time?: DateTime;
  toDto: () => FlueMetricsQueryDto;
}

export interface FlueMetricsQueryDto {
  flueId: FlueId;
  timeUnit?: FlueMetricsTimeUnitEnum;
  time?: DateTimeISO;
}

export class FlueMetricsQueryImpl implements FlueMetricsQuery {
  readonly flueId: FlueId;
  readonly timeUnit?: FlueMetricsTimeUnitEnum;
  readonly time?: DateTime;
  constructor(args: {
    flueId: FlueId;
    timeUnit?: FlueMetricsTimeUnitEnum;
    time?: DateTimeISO | DateTime;
  }) {
    this.flueId = args.flueId;
    this.timeUnit = args.timeUnit;
    this.time =
      args.time !== undefined
        ? typeof args.time === "string"
          ? DateTime.fromISO(args.time)
          : args.time
        : undefined;
  }

  toDto(): FlueMetricsQueryDto {
    return {
      flueId: this.flueId,
      timeUnit: this.timeUnit,
      time:
        this.time !== undefined
          ? this.time.toUTC().toISO() ?? _throw(new IllegalStateError("time"))
          : undefined,
    };
  }
}
