import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { TimeframeObject } from "./TimeframeObject";
import { TimeframeProps } from "./Timeframe";
import { TimeframeUtils } from "./TimeframeUtils";
import { TimeframeOptions } from "./TimeframeOptions";
import { TimeframeUnit } from "./TimeframeUnit";
import {
  IntervalSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { DateTime, Interval } from "luxon";
import {
  _throw,
  IllegalStateError,
  UnsupportedError,
} from "@airmont/shared/ts/utils/core";

const useTimeframeState = (
  input: UseTimeframeInput
): [TimeframeObject, Dispatch<SetStateAction<TimeframeObject>>] => {
  const { start, end, ...partialOptions } = input;

  const defaultInterval = resolveInitialInterval(
    resolveDefaultTimeframeOptions(partialOptions),
    start != null && end != null
      ? ((Interval.fromDateTimes(start, end) ??
          _throw(new IllegalStateError("Invalid Interval"))) as Interval<true>)
      : undefined
  );
  if (partialOptions.persistUsingUserSettings) {
    const userSettingName =
      partialOptions.storageId != null
        ? typeof partialOptions.storageId === "string"
          ? partialOptions.storageId + "timeframe"
          : partialOptions.storageId()
        : "timeframe";
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useUserSettingWithDefault(
      userSettingName,
      IntervalSetting,
      defaultInterval
    ) as [TimeframeObject, Dispatch<SetStateAction<TimeframeObject>>];
  } else if (partialOptions.persistUsingQueryParams) {
    throw new UnsupportedError("persistUsingQueryParams is not supported yet");
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useState<TimeframeObject>(
      resolveInitialTimeframe(
        resolveDefaultTimeframeOptions(partialOptions),
        start != null && end != null
          ? ((Interval.fromDateTimes(start, end) ??
              _throw(
                new IllegalStateError("Invalid Interval")
              )) as Interval<true>)
          : undefined
      )
    );
  }
};

export type UseTimeframeInput = Partial<TimeframeObject> &
  Partial<TimeframeOptions>;

export const useTimeframe = (input: UseTimeframeInput): TimeframeProps => {
  const { start, end, ...partialOptions } = input;
  const [options, setOptions] = useState<TimeframeOptions>(
    resolveDefaultTimeframeOptions(partialOptions)
  );
  const [timeframe, setTimeframe] = useTimeframeState(options);

  const handleTimeframeChange = useCallback(
    (newTimeframe: TimeframeObject) => {
      setTimeframe(newTimeframe);
    },
    [setTimeframe]
  );

  const handleUnitChange = useCallback(
    (newUnit: TimeframeUnit) => {
      const newTimeframe = TimeframeUtils.transformTimeframeToUnit({
        timeframe: timeframe,
        options: options,
        newUnit: newUnit,
        oldUnit: options.unit,
      });

      setOptions((prevState) => {
        return { ...prevState, unit: newUnit };
      });

      setTimeframe(newTimeframe);
    },
    [timeframe, options, setTimeframe]
  );

  const handleYearChange = useCallback(
    (newYear: number) => {
      setTimeframe(
        TimeframeUtils.expandWithinUnit(
          TimeframeUtils.changeYear(timeframe, newYear, options),
          options
        )
      );
    },
    [timeframe, options, setTimeframe]
  );

  const handleMonthChange = useCallback(
    (newMonth: number) => {
      setTimeframe(
        TimeframeUtils.expandWithinUnit(
          TimeframeUtils.changeMonth(timeframe, newMonth, options),
          options
        )
      );
    },
    [timeframe, options, setTimeframe]
  );

  const handlePrevious = useCallback(() => {
    setTimeframe(TimeframeUtils.resolvePreviousTimeframe(timeframe, options));
  }, [timeframe, options, setTimeframe]);

  const handleNext = useCallback(() => {
    setTimeframe(TimeframeUtils.resolveNextTimeframe(timeframe, options));
  }, [timeframe, options, setTimeframe]);

  const handleNow = useCallback(() => {
    setTimeframe(TimeframeUtils.resolveNowTimeframe(options));
  }, [options, setTimeframe]);

  return {
    timeframe: timeframe,
    timeframeUnit: options.unit,
    onNextTimeframe: handleNext,
    onNowTimeframe: handleNow,
    onPreviousTimeframe: handlePrevious,
    onTimeframeChange: handleTimeframeChange,
    onUnitChange: handleUnitChange,
    onYearChange: handleYearChange,
    onMonthChange: handleMonthChange,
    options: options,
  };
};

const resolveDefaultTimeframeOptions = (
  options?: Partial<TimeframeOptions>
): TimeframeOptions => {
  const allowedUnits = options?.allowedUnits ?? ["year", "month"];
  const unit = options?.unit ?? allowedUnits[allowedUnits.length - 1];
  const defaultOptions: TimeframeOptions = {
    disallowFuture: false,
    unit: unit,
    allowedUnits: allowedUnits,
  };
  return Object.assign(defaultOptions, options);
};

const resolveInitialTimeframe = (
  options: {
    unit: TimeframeUnit;
    maxEnd?: DateTime<true>;
    disallowFuture: boolean;
  },
  defaultTimeframe?: TimeframeObject
): TimeframeObject => {
  if (defaultTimeframe != null) {
    return defaultTimeframe;
  }
  return TimeframeUtils.resolveNowTimeframe(options);
};

const resolveInitialInterval = (
  options: {
    unit: TimeframeUnit;
    maxEnd?: DateTime;
    disallowFuture: boolean;
  },
  defaultTimeframe?: TimeframeObject
): Interval<true> => {
  if (defaultTimeframe != null) {
    return defaultTimeframe;
  }
  return TimeframeUtils.resolveNowTimeframe(options);
};
