import { PostHog } from "posthog-js";

export class PostHogUtils {
  private readonly postHog: PostHog;

  constructor(postHog: PostHog) {
    this.postHog = postHog;
  }

  handleConsentChange(
    consentToHandleUsageData: boolean,
    anonymizeData: boolean
  ) {
    if (consentToHandleUsageData) {
      this.postHog.has_opted_in_capturing();
      this.postHog.opt_in_capturing();
      if (anonymizeData) {
        this.postHog.reset();
      }
    } else {
      this.postHog.opt_out_capturing();
    }
  }
}
