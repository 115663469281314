import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, Stack, Tooltip, useTheme } from "@mui/material";
import { Button } from "shared-ts-mui";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import { SxProps } from "@mui/system";
import {
  SizeClass,
  useElementWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export interface RouletteProps {
  autoRoll: boolean;
  autoRollDelay: number;
  entries: Array<{
    header: (
      index: number,
      selected: boolean,
      layout: SizeClass,
      onClick: () => void
    ) => ReactElement;
    content: (layout: SizeClass) => ReactNode;
  }>;
  autoSaveId: string;
  sx?: SxProps;
  onChangePage?: (page: number) => void;
}

export const Roulette: FC<RouletteProps> = (props) => {
  const theme = useTheme();
  const { ref, elementWidthComparer } = useElementWidthComparer();

  const layout = elementWidthComparer.sizeClass;

  const [page, setPage] = useState(0);
  const [play, setPlay] = useUserSettingWithDefault(
    "Roulette." + props.autoSaveId,
    BooleanSetting,
    false
  );
  const intervalIdRef = useRef<number | null>(null);

  const setNextPage = () =>
    setPage((prev) => (prev + 1 === props.entries.length ? 0 : prev + 1));

  useEffect(() => {
    if (play && intervalIdRef.current == null) {
      intervalIdRef.current = setInterval(
        setNextPage,
        props.autoRollDelay * 1000
      ) as unknown as number;
    } else if (!play && intervalIdRef.current != null) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    } else if (
      play &&
      intervalIdRef.current != null &&
      props.autoRollDelay !== props.autoRollDelay
    ) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = setInterval(
        setNextPage,
        props.autoRollDelay * 1000
      ) as unknown as number;
    }
  }, [setNextPage, play, props.autoRollDelay]);

  useEffect(() => {
    if (!props.autoRoll) {
      setPlay(false);
    }
  }, [props.autoRoll]);

  const handleTogglePlay = () => {
    setPlay(!play);
  };

  const handleHeaderClick = (i: number): void => {
    setPlay(false);
    setPage(i);
  };

  const headers = props.entries.map((entry) => entry.header);

  return (
    <Stack
      ref={ref}
      className={"Roulette"}
      direction={
        layout === SizeClass.Compact || layout === SizeClass.Medium
          ? "column"
          : "row"
      }
      sx={props.sx}
    >
      <Stack
        direction={
          layout === SizeClass.Compact || layout === SizeClass.Medium
            ? "row"
            : "column"
        }
        sx={{
          flexWrap: "nowrap",
          pr:
            layout === SizeClass.Medium || layout === SizeClass.Compact
              ? 0
              : theme.spacing(1),
          pb:
            layout === SizeClass.Medium || layout === SizeClass.Compact
              ? theme.spacing(1)
              : 0,
        }}
      >
        <Stack
          direction={
            layout === SizeClass.Compact || layout === SizeClass.Medium
              ? "row"
              : "column"
          }
          spacing={1}
          useFlexGap
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            flexWrap: "wrap",
            "> *":
              layout === SizeClass.Compact || layout === SizeClass.Medium
                ? {
                    flexGrow: 1,
                    minWidth: 0,
                    minHeight: 0,
                  }
                : undefined,
          }}
        >
          {headers.map((header, index) =>
            header(index, page === index, layout, () =>
              handleHeaderClick(index)
            )
          )}
          {props.autoRoll && (
            <Box
              sx={{
                paddingTop: theme.spacing(1),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tooltip title={play ? "Pause rullering" : "Start rullering"}>
                <Button
                  color={"secondary"}
                  onClick={handleTogglePlay}
                  startIcon={
                    play ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />
                  }
                />
              </Tooltip>
            </Box>
          )}
        </Stack>
      </Stack>
      {props.entries[page].content(layout)}
    </Stack>
  );
};
