import { Release } from "shared-ts-release";

export const release_2023_2_0: Release = {
  version: "2023.2.0",
  date: "2024-01-15",
  entries: [
    {
      type: "Feature",
      heading: "Registering av feiing",
      description: "Det er nå mulig å registering feiing av et røykløp",
    },
    {
      type: "Feature",
      heading: "Vurdering av fyringsstart",
      description:
        "Det gis nå en vurdering på fyringsstarten av en fyring. En god fyringsstart kjennetegnes med at temperaturen stiger raskt opp til forventet temperatur for røykløpet.",
    },
    {
      type: "Feature",
      heading:
        "Diagrammet for visning av temperturkurver viser nå høyeste målte temperatur",
    },
    {
      type: "Feature",
      heading:
        "Diagrammet for visning av temperaturalarmer viser nå terskelverdien sensoren har satt for å varsle unormale temperaturer",
    },
    {
      type: "Feature",
      heading:
        "Sensor-status-side: Viser nå nøkkeltall for totalt antall sensorer",
    },
    {
      type: "Bugfix",
      heading:
        "Oversikt-side: Skifte av period-lengde til ÅR fungerte ikke korrekt",
      description: "Start-tid ble ikke satt til årets begynnelse",
    },
    {
      type: "Bugfix",
      heading: "Oversikt-side: Rullering av grafer startet ikke som forventet",
      description: "Start-tid ble ikke satt til årets begynnelse",
    },
    {
      type: "Usability",
      heading: "Forbedret støtte for mobil",
      description:
        "Vi har gjort grep for at applikasjonen skal være mer brukbar på mobil.",
    },
    {
      type: "Usability",
      heading: "Valgt sortering i Pipe-status-tabellen huskes",
    },
    {
      type: "Usability",
      heading: "Valgt sortering i Sensor-status-tabellen huskes",
    },
    {
      type: "Usability",
      heading:
        "Designet om siden Innstillinger for å gi en bedre brukeropplevelse",
    },
    {
      type: "Usability",
      heading: "Oversikt-side: Forbedret responstid på lasting av data",
      description:
        "Vi kalkulerer og lagrer statistikk fortløpende når vi mottar temperatur-målinger fra sensor. Dette gjør at vi kan hente ut statistikk betraktelig raskere enn tidligere.",
    },
    {
      type: "Usability",
      heading:
        "Oversikt-side: Grafenes y-akse har nå en mer stabil maksimums-verdi",
      description:
        "Y-aksen hadde tidligere en maks-verdi som var den samme som den største verdien i grafen. Dette kunne gi inntrykk av at små verdier var store. Nå får y-aksene en minimums max-verdi som sikrer at dette ikke skjer.",
    },
  ],
};
