import { ReactElement, ReactNode, useMemo } from "react";
import {
  ChartsAxisHighlight,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  LineSeriesType,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { DurationSeries } from "@airmont/firefly/shared/ts/timeseries";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { SxProps } from "@mui/material";
import { resolveXAxisConfig } from "./resolveXAxisConfig";
import { resolveYAxisConfig } from "./resolveYAxisConfig";
import { merge } from "lodash";
import { AllSeriesType } from "@mui/x-charts/models/seriesType";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { ChartsTooltipAxisContent } from "./ChartsTooltipAxisContent";
import { XAxisDefaultConfig, YAxisDefaultConfig } from "shared-ts-mui";

export type MuiDurationSeriesOptions = Omit<
  MakeOptional<LineSeriesType, "type">,
  "data"
>;

export interface DurationSeriesMuiChartProps {
  durationSeries: Array<DurationSeries<number | null>> | undefined;
  durationSeriesOptions?: Array<MuiDurationSeriesOptions>;
  yAxisConfig?: Array<YAxisDefaultConfig>;
  xAxisConfig?: XAxisDefaultConfig;
  width?: number;
  height?: number;
  sx?: SxProps;
  children?: ReactNode;
}

export function DurationSeriesMuiChart(
  props: DurationSeriesMuiChartProps
): ReactElement {
  const {
    durationSeries,
    durationSeriesOptions,
    xAxisConfig,
    yAxisConfig,
    width,
    height,
    sx,
  } = props;
  const xAxis: Array<XAxisDefaultConfig> = useMemo(() => {
    return resolveXAxisConfig(durationSeries, xAxisConfig);
  }, [durationSeries, xAxisConfig]);

  const yAxis: Array<YAxisDefaultConfig> = useMemo(() => {
    return resolveYAxisConfig(durationSeries, yAxisConfig);
  }, [durationSeries, yAxisConfig]);

  const series: Array<AllSeriesType> = useMemo(() => {
    if (durationSeries === undefined) {
      return [];
    }
    return durationSeries.map((series, seriesIndex) => {
      const data = series.points.map((point) => point.value);
      const defaultOptions: LineSeriesType = {
        type: "line",
        showMark: false,
        data: data,
        valueFormatter: (value) => {
          return value == null
            ? ""
            : `${MathUtils.round(value)} ${series.info.unit}`;
        },
      };
      const options: MakeOptional<LineSeriesType, "type"> | undefined =
        durationSeriesOptions?.[seriesIndex];

      return merge(defaultOptions, options);
    });
  }, [durationSeries, durationSeriesOptions]);

  return (
    <ResponsiveChartContainer
      xAxis={xAxis}
      yAxis={yAxis}
      series={series}
      width={width}
      height={height}
      sx={sx}
    >
      <LinePlot />
      <ChartsYAxis />
      <ChartsXAxis />
      <ChartsTooltip
        slots={{
          axisContent: ChartsTooltipAxisContent,
        }}
      />
      <ChartsAxisHighlight x={"line"} />
      {props.children}
    </ResponsiveChartContainer>
  );
}
