import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import React, { FC } from "react";
import { useMunicipalityContext } from "./MunicipalityContextProvider";
import { NestedMenuItem } from "shared-ts-mui";
import { MunicipalityDto } from "@airmont/firefly/shared/ts/domain";

export interface MunicipalityNestedMenuItemProps {
  parentMenuOpen: boolean;
}
export const MunicipalityNestedMenuItem: FC<MunicipalityNestedMenuItemProps> = (
  props
) => {
  const { municipalities, municipality, setMunicipality } =
    useMunicipalityContext();
  const handleMunicipalityClick = (value: MunicipalityDto | null) => {
    setMunicipality(value === null ? undefined : value.id);
  };

  return (
    <NestedMenuItem
      label={"Kommune"}
      parentMenuOpen={props.parentMenuOpen}
      leftIcon={<LocationCityRoundedIcon fontSize="small" />}
      sx={{ padding: "6px 16px" }}
    >
      <MenuItem
        key={"-1"}
        value={"-1"}
        selected={municipality == null}
        onClick={() => handleMunicipalityClick(null)}
      >
        <ListItemIcon>
          {municipality == null && <RadioButtonCheckedRoundedIcon />}
          {municipality != null && <RadioButtonUncheckedRoundedIcon />}
        </ListItemIcon>
        <ListItemText>ALLE</ListItemText>
      </MenuItem>
      {municipalities.map((it, i) => {
        const selectedMunicipality = it.id === municipality;
        return (
          <MenuItem
            key={it.id}
            value={it.id}
            selected={selectedMunicipality}
            onClick={() => handleMunicipalityClick(it)}
          >
            <ListItemIcon>
              {selectedMunicipality && <RadioButtonCheckedRoundedIcon />}
              {!selectedMunicipality && <RadioButtonUncheckedRoundedIcon />}
            </ListItemIcon>
            <ListItemText>{it.name}</ListItemText>
          </MenuItem>
        );
      })}
    </NestedMenuItem>
  );
};
