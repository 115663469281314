import { LayoutDirection } from "./LayoutDirection";
import { Layout } from "./Layout";
import { useMemo } from "react";
import { useResolveLayoutDirection } from "./useResolveLayoutDirection";
import { Action } from "./Action";
import { RouteItem } from "./RouteItem";
import { SelectableAction } from "./SelectableAction";
import { ItemVariant } from "./ItemVariant";
import { useResolveVariant } from "./useResolveVariant";
import { LinkItem } from "./LinkItem";

export const useClassName = (
  props: {
    item: Action | SelectableAction | RouteItem | LinkItem;
    variant?: ItemVariant;
    layout?: Layout;
    layoutDirection?: LayoutDirection;
  },
  names: Array<string>
): string => {
  const { layout, item } = props;
  const layoutDirection = useResolveLayoutDirection(props);
  const variant = useResolveVariant(props.variant);
  return useMemo(() => {
    let value = "";
    names.forEach((name, index) => {
      if (index > 0) {
        value += " ";
      }
      value += name;
    });
    value += " variant-" + variant;
    value += " layout-" + layout;
    value += " direction-" + layoutDirection;
    if (item.selectable) {
      value += " selectable";
    }
    if (item.selected) {
      value += " selected";
    }

    return value;
  }, [item.selected, variant, layout, layoutDirection, item.selectable, names]);
};
