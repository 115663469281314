import { useMemo } from "react";
import { useAccessToken } from "shared-ts-utils-authentication";
import { useCustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { ChimneySensorDao } from "./ChimneySensorDao";

export const useChimneySensorDao = (): ChimneySensorDao => {
  const accessToken = useAccessToken();
  const customerEnvironment =
    useCustomerEnvironmentName() ??
    _throw(new NullError("customerEnvironment"));
  return useMemo(
    () =>
      new ChimneySensorDao({
        accessToken: accessToken,
        customerEnvironment: customerEnvironment,
      }),
    [accessToken, customerEnvironment]
  );
};
