import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { CalendarSettings } from "./CalendarSettings";
import { Duration } from "luxon";
import { blueGrey, grey, red } from "@mui/material/colors";
import { alpha, useTheme } from "@mui/material";

const defaultHeaderHeight = 30;
const defaultTimeslotDuration = Duration.fromDurationLike({ minute: 30 });
const defaultTimeslotsTopGutterHeight = 10;
const defaultTimeslotHeight = 25;
const defaultWeekendBackgroundColorLight = alpha(grey[100], 0.2);
const defaultWeekendBackgroundColorDark = alpha(grey[800], 0.2);
const defaultEventBackgroundColorDark = alpha(blueGrey[800], 0.5);
const defaultEventBackgroundColorLight = alpha(blueGrey[800], 0.5);
const CalendarSettingsContext = createContext({} as CalendarSettings);

export interface CalendarSettingsProviderProps {
  settings?: Partial<CalendarSettings>;
  children: ReactNode | ((settings: CalendarSettings) => ReactNode);
}

export const CalendarSettingsProvider: FC<CalendarSettingsProviderProps> = (
  props
) => {
  const theme = useTheme();
  const settings: CalendarSettings = useMemo(() => {
    return {
      headerHeight: defaultHeaderHeight,
      nowColor: red[500],
      timeslotsTopGutterHeight: defaultTimeslotsTopGutterHeight,
      timeslotHeight: defaultTimeslotHeight,
      timeslotDuration: defaultTimeslotDuration,
      weekendBackgroundColor:
        theme.palette.mode === "light"
          ? defaultWeekendBackgroundColorLight
          : defaultWeekendBackgroundColorDark,
      eventBackgroundColor:
        theme.palette.mode === "light"
          ? defaultEventBackgroundColorLight
          : defaultEventBackgroundColorDark,
      ...props.settings,
    };
  }, [props.settings, theme.palette.mode]);

  return (
    <CalendarSettingsContext.Provider value={settings}>
      {typeof props.children === "function"
        ? props.children(settings)
        : props.children}
    </CalendarSettingsContext.Provider>
  );
};

export const useCalendarSettings = (): CalendarSettings => {
  const context = useContext(CalendarSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useCalendarSettings must be used within a CalendarSettingsProvider"
    );
  }
  return context;
};
