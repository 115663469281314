import { Action } from "./Action";
import { RouteItem } from "./RouteItem";
import { LinkItem } from "./LinkItem";
import { ActionGroup } from "./ActionGroup";
import { Divider } from "./Divider";
import { Spacer } from "./Spacer";

export type ItemTypeName =
  | "Action"
  | "RouteItem"
  | "LinkItem"
  | "ActionGroup"
  | "Divider"
  | "Spacer";

export type ItemType =
  | Action
  | RouteItem
  | LinkItem
  | ActionGroup
  | Divider
  | Spacer;

export interface Item {
  type: ItemTypeName;
  reference?: string;
  hide?: boolean;
  [others: string]: unknown;
}

export function isItem(obj: object): obj is Item {
  return (
    typeof obj === "object" &&
    "type" in obj &&
    typeof obj.type === "string" &&
    (obj.type === "Action" ||
      obj.type === "ActionGroup" ||
      obj.type === "RouteItem" ||
      obj.type === "LinkItem" ||
      obj.type === "Divider" ||
      obj.type === "Spacer")
  );
}
