import {
  ActionMenuItem,
  isAction,
  isActionGroup,
  isLinkItem,
  isRouteItem,
  isSelectableAction,
  Item,
  ItemVariant,
  LinkListItem,
  RouteMenuItem,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import React, { ReactNode } from "react";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";
import { GroupMenu } from "./GroupMenu";
import { PopoverOrigin } from "@mui/material/Popover/Popover";

export interface RenderItemParams {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: Item;
  index?: number;
  onSelected?: (item: SelectableItem) => void;
  hideSelection?: boolean;
  childComponentPreferences?: {
    groupMenu?: {
      anchorOrigin?: PopoverOrigin;
      transformOrigin?: PopoverOrigin;
    };
  };
}

export const renderMenuItem = (params: RenderItemParams): ReactNode => {
  const { variant, layout, layoutDirection, item, onSelected } = params;
  const index = params.index ?? 0;
  const handleItemSelected = (item: SelectableItem) => {
    onSelected?.(item);
  };

  if (isSelectableAction(item)) {
    return (
      <ActionMenuItem
        key={index}
        item={item}
        variant={variant}
        layout={layout}
        layoutDirection={layoutDirection}
        hideSelection={params.hideSelection}
      />
    );
  } else if (isRouteItem(item)) {
    return (
      <RouteMenuItem
        key={index}
        item={item}
        variant={variant}
        layout={layout}
        layoutDirection={layoutDirection}
        onSelected={handleItemSelected}
      />
    );
  } else if (isLinkItem(item)) {
    return (
      <LinkListItem
        key={index}
        item={item}
        variant={variant}
        layout={layout}
        layoutDirection={layoutDirection}
      />
    );
  } else if (isAction(item)) {
    return (
      <ActionMenuItem
        key={index}
        item={item}
        variant={variant}
        layout={layout}
        layoutDirection={layoutDirection}
      />
    );
  } else if (isActionGroup(item)) {
    return (
      <GroupMenu
        variant={variant}
        layout={layout}
        key={index}
        item={item}
        onSelected={onSelected}
        hideSelected={true}
        anchorOrigin={params.childComponentPreferences?.groupMenu?.anchorOrigin}
        transformOrigin={
          params.childComponentPreferences?.groupMenu?.transformOrigin
        }
      />
    );
  } else {
    throw new Error("Unsupported type: \n" + item.type);
  }
};
