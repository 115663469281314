export enum WindDirection {
  North = "North",
  NorthEast = "NorthEast",
  East = "East",
  SouthEast = "SouthEast",
  South = "South",
  SouthWest = "SouthWest",
  West = "West",
  NorthWest = "NorthWest",
}

const north = 0;
const northEast = 45;
const east = 90;
const southEast = 135;
const south = 180;
const southWest = 225;
const west = 270;
const northWest = 315;

export const resolveWindDirectionFromDegrees = (
  degrees: number
): WindDirection => {
  if (degrees >= northWest + 22.5 && degrees < northEast - 22.5) {
    return WindDirection.North;
  } else if (degrees >= north + 22.5 && degrees < east - 22.5) {
    return WindDirection.NorthEast;
  } else if (degrees >= northEast + 22.5 && degrees < southEast - 22.5) {
    return WindDirection.East;
  } else if (degrees >= east + 22.5 && degrees < south - 22.5) {
    return WindDirection.SouthEast;
  } else if (degrees >= southEast + 22.5 && degrees < southWest - 22.5) {
    return WindDirection.South;
  } else if (degrees >= south + 22.5 && degrees < west - 22.5) {
    return WindDirection.SouthWest;
  } else if (degrees >= southWest + 22.5 && degrees < northWest - 22.5) {
    return WindDirection.West;
  } else {
    return WindDirection.NorthWest;
  }
};
