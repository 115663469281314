import {FC, useEffect, useState} from "react";


const resolvePreviousState = (dots: Array<boolean>, i: number): boolean => {
    if (i === 0) {
        return dots[dots.length - 1];
    } else {
        return dots[i - 1];
    }
}

export const DotsProgress: FC<{ length?: number }> = (props) => {

    const length = props.length ?? 3;
    const [dots, setDots] = useState<Array<boolean>>(new Array(length).fill(false));

    useEffect(() => {
        setInterval(() => {
            setDots((prevDots) => {
                // [true, false, false]
                // [true, true, false] 
                // [true, true, true] 
                // [false, true, true] 
                // [false, false, true] 
                // [false, false, false] 

                const newDots = [...prevDots];
                for (let i = 0; i < newDots.length; i++) {
                    const previous = resolvePreviousState(prevDots, i);
                    const current = prevDots[i];
                    const isFirst = i === 0;
                    const isLast = i === newDots.length - 1;


                    if (current === true && previous === false && isFirst) {
                        newDots[i] = true;
                    } else if (current === true && previous === false && isLast) {
                        newDots[i] = false;
                    } else if (current === true && previous === false) {
                        newDots[i] = false;
                    } else if (current === true && previous === true && isFirst) {
                        newDots[i] = false;
                    } else if (current === true && previous === true && isLast) {
                        newDots[i] = true;
                    } else if (current === true && previous === true) {
                        newDots[i] = true;
                    } else if (current === false && previous === false && isFirst) {
                        newDots[i] = true;
                    } else if (current === false && previous === false && isLast) {
                        newDots[i] = false;
                    } else if (current === false && previous === false) {
                        newDots[i] = false;
                    } else if (current === false && previous === true && isFirst) {
                        newDots[i] = false;
                    } else if (current === false && previous === true && isLast) {
                        newDots[i] = true;
                    } else if (current === false && previous === true) {
                        newDots[i] = true;
                    }

                }
                return newDots;
            })
        }, 250);
    }, [])

    return (
        <span>
            {dots.map((dot, index) => {
                return <span
                    key={index}
                    style={{
                        color: !dot ? "rgba(255,255,255,0.5)" : "inherit"
                    }}
                >.</span>
            })}
        </span>
    );
}