import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

export enum ChimneySensorCableStatusEnum {
  Ok = "Ok",
  Broken = "Broken",
  Shorted = "Shorted",
}

export type ChimneySensorCableStatusEnumStrings =
  keyof typeof ChimneySensorCableStatusEnum;

export const resolveChimneySensorCableStatusIcon = (
  status: ChimneySensorCableStatusEnum
) => {
  if (status === ChimneySensorCableStatusEnum.Shorted) {
    return  <PriorityHighRoundedIcon color="error"/>;
  } else if (status === ChimneySensorCableStatusEnum.Broken) {
    return <PriorityHighRoundedIcon color="error"/>;
  } else {
    return <CheckRoundedIcon/>;
  }
};
