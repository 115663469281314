import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { Expr } from "./Expr";

export const runExpr = (e: Expr, i: any): any => {
  if (e.$type === "field") {
    return i[e.name];
  } else if (e.$type === "val") {
    return e.value;
  } else if (e.$type === "==") {
    const leftVal = runExpr(e.left, i);
    const rightVal = runExpr(e.right, i);
    return leftVal == rightVal;
  } else if (e.$type === "contains") {
    const leftVal = runExpr(e.instance, i);
    const rightVal = runExpr(e.arg, i);
    return leftVal.includes(rightVal);
  } else if (e.$type === "&&") {
    const leftVal = runExpr(e.left, i);
    const rightVal = runExpr(e.right, i);
    return leftVal && rightVal;
  } else if (e.$type === "||") {
    const leftVal = runExpr(e.left, i);
    const rightVal = runExpr(e.right, i);
    return leftVal || rightVal;
  } else {
    throw new IllegalArgumentError("Unsupported expr: " + JSON.stringify(e));
  }
};
