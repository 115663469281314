import React, { FC } from "react";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { SxProps } from "@mui/system";
import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { useFormikContext } from "formik";
import { FlueFormValues } from "../FlueFormValues";
import { FormikProperty, PropertyMode } from "shared-ts-property";
import { useTranslation } from "react-i18next";

export interface CadastralNumbersPropertiesPaneProps {
  flueStatus: FlueStatusDto;
  mode: PropertyMode;
  fullWidth?: boolean;
  sx?: SxProps;
}

export const CadastralNumbersPropertiesPane: FC<
  CadastralNumbersPropertiesPaneProps
> = (props) => {
  const { mode } = props;
  const formik = useFormikContext<FlueFormValues>();
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  return (
    <PropertiesCard
      header={{ title: tFireflyDomain("Cadastre") }}
      childPropertyProps={{
        fullWidth: props.fullWidth,
      }}
      elevation={0}
      sx={props.sx}
    >
      <FormikProperty
        formik={formik}
        name="municipality"
        label={tFireflyDomain("Municipality Code")}
        mode={mode}
      />
      <FormikProperty
        formik={formik}
        name="municipalityName"
        label={tFireflyDomain("Municipality Name")}
        mode={mode}
      />
      <FormikProperty
        formik={formik}
        name="cadastralUnit"
        label={tFireflyDomain("Cadastre.Cadastral Unit")}
        mode={"read"}
      />
      <FormikProperty
        formik={formik}
        name="propertyUnit"
        label={tFireflyDomain("Cadastre.Property Unit")}
        mode={mode}
      />
      <FormikProperty
        formik={formik}
        name="leaseNumber"
        label={tFireflyDomain("Cadastre.Lease Number")}
        mode={mode}
      />
    </PropertiesCard>
  );
};
