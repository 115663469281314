import { FC } from "react";
import { alpha, Chip, darken, lighten, SxProps, useTheme } from "@mui/material";
import { ChipProps } from "@mui/material/Chip/Chip";
import { IllegalArgumentError, notUndef } from "@airmont/shared/ts/utils/core";
import { useSxMerge } from "../sx/useSxMerge";

export type ChipToggleButtonProps = ChipProps & {
  value: string | number;
  selected?: boolean;
  borderRadiusVariant?: "rounded" | "chip";
};

export const ChipToggleButton: FC<ChipToggleButtonProps> = (props) => {
  const theme = useTheme();
  const color = props.color ?? "primary";
  const selected = props.selected ?? false;
  const borderRadiusVariant = props.borderRadiusVariant ?? "rounded";
  if (color === "default") {
    throw new IllegalArgumentError("color 'default' is not supported");
  }
  const selectedColorAsHex = theme.palette[color].main;
  const backgroundColorAsHex = theme.palette["grey"]["500"];
  const shadowColor = notUndef(backgroundColorAsHex, (hex) =>
    alpha(hex, theme.palette.mode === "dark" ? 0.5 : 0.9)
  );

  const backgroundColor = alpha(
    backgroundColorAsHex,
    theme.palette.mode === "dark" ? 0.8 : 0.7
  );
  const backgroundColorHover = notUndef(backgroundColorAsHex, (hex) =>
    alpha(hex, theme.palette.mode === "dark" ? 1 : 0.6)
  );
  const shadowColorSelected = notUndef(selectedColorAsHex, (hex) =>
    alpha(hex, theme.palette.mode === "dark" ? 0.6 : 0.9)
  );
  const backgroundColorSelected = alpha(
    selectedColorAsHex,
    theme.palette.mode === "dark" ? 1 : 0.8
  );
  const backgroundColorHoverSelected = notUndef(selectedColorAsHex, (hex) =>
    alpha(hex, theme.palette.mode === "dark" ? 0.8 : 0.7)
  );
  const outlineColor = darken(backgroundColor, 0.2);
  const outlineColorSelected = darken(backgroundColorSelected, 0.2);
  const outlineColorHoverSelected = lighten(outlineColorSelected, 0.2);

  const sx = useSxMerge(props.sx as SxProps, {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.grey["800"]
        : theme.palette.grey["100"],
    textShadow: `${theme.palette.mode === "dark" ? "#999" : "#999"} 1px 1px 0`,
    borderRadius: borderRadiusVariant === "rounded" ? "6px" : undefined,
    outline: `1px solid ${outlineColor}`,
    boxShadow: `1px 5px ${shadowColor}`,
    backgroundColor: backgroundColor,
    "&.selected": {
      color:
        theme.palette.mode === "dark" ? theme.palette.grey["800"] : undefined,
      outline: "1px solid " + outlineColorSelected,
      textShadow: `1px 1px 0 ${
        theme.palette.mode === "dark" ? "#e0e0e0" : "#7A7A7A"
      }`,
      boxShadow: `1px 5px ${shadowColorSelected}`,
      backgroundColor: backgroundColorSelected,
      "&:hover": {
        backgroundColor: backgroundColorHoverSelected,
        outlineColor: outlineColorHoverSelected,
      },
    },
    "&:hover": {
      backgroundColor: backgroundColorHover,
    },
    ".MuiSvgIcon-root": {
      filter: `drop-shadow(${
        theme.palette.mode === "dark"
          ? selected
            ? "#e0e0e0"
            : "#999"
          : selected
          ? "#7A7A7A"
          : "#999"
      } 1px 1px 0)`,
    },
    ".MuiChip-label": {
      fontSize: "larger",
      fontWeight: 500,
      letterSpacing: "0.4px",
    },
  });

  let className = "ChipToggleButton";
  if (selected) {
    className += " selected";
  }
  return <Chip className={className} {...props} sx={sx} />;
};
