import { useMemo } from "react";
import { summarizeFlueMetricValues } from "./summarizeFlueMetricValues";
import { DateTime } from "luxon";
import { FlueMetrics } from "../metrics/FlueMetrics";
import { FlueMetricValues } from "../metrics/FlueMetricValues";

export const useSummarizeFlueMetricValues = (
  untilTime: DateTime<true>,
  flueMetricsByMonth: Record<string, FlueMetrics>
): FlueMetricValues => {
  return useMemo(() => {
    return summarizeFlueMetricValues(untilTime, flueMetricsByMonth);
  }, [flueMetricsByMonth, untilTime]);
};
