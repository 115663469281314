import { DateTime } from "luxon";

export class CalendarUtils {
  static generateMonth(date: DateTime<true>): Array<DateTime<true>> {
    const firstDayOfMonth = date.startOf("month");
    const firstDayOfWeek = firstDayOfMonth.startOf("week");
    const lastDayOfMonth = date.endOf("month");
    const lastDayOfWeek = lastDayOfMonth.endOf("week");
    const result: Array<DateTime<true>> = [];

    let curr = firstDayOfWeek;
    do {
      result.push(curr);
      curr = curr.plus({ day: 1 });
    } while (curr <= lastDayOfWeek);

    return result;
  }

  static generateWeek(date: DateTime<true>): Array<DateTime<true>> {
    const firstDayOfWeek = date.startOf("week");
    const lastDayOfWeek = firstDayOfWeek.endOf("week");
    const result: Array<DateTime<true>> = [];

    let curr = firstDayOfWeek;
    do {
      result.push(curr);
      curr = curr.plus({ day: 1 });
    } while (curr <= lastDayOfWeek);

    return result;
  }
}
