/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { SettingConfig } from "./SettingConfig";

export type ArrayType = any;

export const ArraySetting: SettingConfig<ArrayType> = {
  type: "array",
  serialize: (value: ArrayType): string => {
    return JSON.stringify(value);
  },

  deserialize: (value: string): ArrayType => {
    return JSON.parse(value);
  },
};
