import { FC, ReactNode, useEffect } from "react";
import { useAppTheme } from "./AppThemeProvider";
import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export const AppThemeInitializer: FC<{ children: ReactNode }> = (props) => {
  const { themeName, setTheme } = useAppTheme();
  const [theme] = useUserSettingWithDefault("theme", StringSetting, themeName);
  useEffect(() => {
    if (themeName !== theme) {
      setTheme(theme);
    }
  }, [theme, themeName, setTheme]);

  return props.children;
};
