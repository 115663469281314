import React, { FC } from "react";
import { PropertyWrapper } from "./PropertyWrapper";
import { _throw } from "@airmont/shared/ts/utils/core";
import { PropertyEditBaseProps } from "./PropertyEditBaseProps";
import { usePropertyEditBase } from "./usePropertyEditBase";

export interface PropertyEditFileProps extends PropertyEditBaseProps {
  value: File | null | undefined;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const PropertyEditFile: FC<PropertyEditFileProps> = (props) => {
  const { removable, handleRemove } = usePropertyEditBase(props);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0] != null
    ) {
      props.onChange(
        event.target.files[0],
        props.name ?? _throw(new Error("name cannot be null"))
      );
    }
  };

  return (
    <PropertyWrapper
      label={props.label}
      size={props.size}
      fullWidth={props.fullWidth}
      sx={props.sx}
    >
      <input
        ref={props.inputRef}
        type={"file"}
        onChange={handleChange}
        onBlur={props.onBlur}
        name={props.name}
        style={{ marginLeft: "14px" }}
      />
    </PropertyWrapper>
  );
};
