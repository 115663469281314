import { useMemo } from "react";
import { Api } from "./Api";
import { useCustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { useAccessToken } from "shared-ts-utils-authentication";

export const useApi = (input: {
  controllerName: string;
  environmentAware?: boolean;
}) => {
  const environmentAware = input.environmentAware ?? false;
  const accessToken = useAccessToken();
  const environment = useCustomerEnvironmentName();

  return useMemo(
    () =>
      new Api(
        input.controllerName,
        accessToken,
        environmentAware ? environment : undefined
      ),
    [input.controllerName, accessToken, environment]
  );
};
