import {
  Container,
  DialogContent,
  Link,
  Paper,
  Portal,
  Stack,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React, { useState } from "react";
import {
  SizeClass,
  useElementWidthComparer,
  useWindowSize,
} from "@airmont/shared/ts/ui/responsive";
import {
  ChimneySensorBatteryVoltageChart,
  ChimneySensorRadioSignaChart,
  ChimneySensorStatusDto,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { useTranslation } from "react-i18next";
import { ChimneySensorFormProperties } from "./ChimneySensorFormProperties";
import { Formik } from "formik";
import { ChimneySensorFormValues } from "./ChimneySensorFormValues";
import { FormikHelpers } from "formik/dist/types";
import { useNotify } from "@airmont/shared/ts/ui/notification";
import { Button } from "shared-ts-mui";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  AuditPaneWithPopper,
  BatteryLevelUtils,
  FlueBreadcrumbs,
  useChimneySensorDao,
} from "@airmont/firefly/shared/ts/domain";
import { merge } from "lodash";
import { ValueCard, ValueCardsContainer } from "@airmont/firefly/shared/ts/ui";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";
import { DurationUtils } from "@airmont/shared/ts/utils/luxon";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { TabPanel, TabsAndPanels } from "@airmont/shared/ts/ui/tabs";
import { useUser } from "shared-ts-utils-authentication";
import { DeleteButton, DisplayMode } from "shared-ts-app-dialog";
import { notNullOrUndefined } from "@airmont/shared/ts/utils/core";

type TabValue = "battery" | "radio-signal";

export interface ChimneySensorDialogContentProps {
  sensor: ChimneySensorStatusDto;
  mode: DisplayMode;
  actionsContainer: Element;
  onDisplayModeChange: (mode: DisplayMode) => void;
  onUpdated: (updated: ChimneySensorStatusDto) => void;
  onDeleted: (deleted: ChimneySensorStatusDto) => void;
}

export const ChimneySensorDialogContent = (
  props: ChimneySensorDialogContentProps
) => {
  const { sensor, actionsContainer, mode } = props;
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const { t: tFireflyChimneyInsightsPages } = useTranslation(
    "firefly-chimney-insights-ts-pages"
  );
  const theme = useTheme();
  const { windowWidth } = useWindowSize();
  const { ref, elementWidthComparer } = useElementWidthComparer();
  const user = useUser();
  const userCanEdit = user.hasClaim("devices.create");
  const userCanDelete = user.hasRole("PowerUser") || user.hasRole("Admin");
  const notify = useNotify();
  const chimneySensorDao = useChimneySensorDao();
  const [tabValue, setTabValue] = useState<TabValue>("battery");
  const [askDeleteConfirmation, setAskDeleteConfirmation] = useState(false);

  const formInitialValues: ChimneySensorFormValues = {
    comment: sensor.comment ?? "",
    tags:
      notNullOrUndefined(sensor.tags, (tags) =>
        tags
          .trim()
          .split(" ")
          .filter((it) => it.trim().length > 0)
      ) ?? [],
    placement: sensor.placement,
  };

  const formValidate = (values: ChimneySensorFormValues) => {
    //
  };

  const handleFormSubmit = async (
    values: ChimneySensorFormValues,
    formikHelpers: FormikHelpers<ChimneySensorFormValues>
  ) => {
    try {
      //setLoading(true);
      const tags = values.tags.join(" ").trim().emptyToNull();
      const comment = values.comment.emptyToNull();
      const updatedChimneySensor = await chimneySensorDao.update({
        id: sensor.id,
        comment: comment,
        tags: tags,
        placement: values.placement,
      });
      const updatedChimneySensorStatus: ChimneySensorStatusDto = merge(
        {},
        sensor,
        updatedChimneySensor
      );
      props.onUpdated(updatedChimneySensorStatus);
      props.onDisplayModeChange("view");
      //setLoading(false);
    } catch (e) {
      //setLoading(false);
      notify.error({ error: e, message: "Failed to save changes" });
    }
  };

  const handleTabChange = (newValue: TabValue) => {
    setTabValue(newValue);
  };

  const handleDeleteConfirmed = async () => {
    await chimneySensorDao.delete(props.sensor.id);
    props.onDeleted(props.sensor);
  };

  return (
    <DialogContent
      ref={ref}
      dividers={true}
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
      }}
    >
      <Container
        maxWidth={"lg"}
        disableGutters={true}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Formik
          initialValues={formInitialValues}
          validate={formValidate}
          enableReinitialize
          onSubmit={handleFormSubmit}
        >
          {({ dirty, submitForm, isSubmitting, resetForm }) => {
            const handleSaveClick = () => {
              submitForm();
            };

            const handleEditCancelClick = () => {
              resetForm();
              props.onDisplayModeChange("view");
            };

            return (
              <>
                <Portal container={actionsContainer}>
                  {userCanDelete && mode !== "edit" && (
                    <DeleteButton
                      askConfirmation={askDeleteConfirmation}
                      onAskingConfirmation={setAskDeleteConfirmation}
                      onDeleteConfirmed={handleDeleteConfirmed}
                      layout={windowWidth}
                    />
                  )}
                  {mode === "view" && !askDeleteConfirmation && (
                    <Button
                      startIcon={<EditRoundedIcon />}
                      variant={"text"}
                      title={tFireflyChimneyInsightsPages("Edit")}
                      disabled={askDeleteConfirmation}
                      sx={{
                        minWidth: "90px",
                      }}
                      onClick={() => props.onDisplayModeChange("edit")}
                    >
                      {windowWidth !== SizeClass.Compact &&
                        tFireflyChimneyInsightsPages("Edit")}
                    </Button>
                  )}
                  {mode === "edit" && userCanEdit && !askDeleteConfirmation && (
                    <Button
                      startIcon={<VisibilityRoundedIcon />}
                      variant={"text"}
                      title={tFireflyChimneyInsightsPages("Cancel")}
                      disabled={isSubmitting}
                      sx={{
                        minWidth: "90px",
                      }}
                      onClick={handleEditCancelClick}
                    >
                      {windowWidth !== SizeClass.Compact &&
                        tFireflyChimneyInsightsPages("Cancel")}
                    </Button>
                  )}
                  {mode === "edit" && !askDeleteConfirmation && (
                    <Button
                      startIcon={<SaveRoundedIcon />}
                      variant={"outlined"}
                      disabled={!dirty || isSubmitting}
                      title={tFireflyChimneyInsightsPages("Save")}
                      sx={{
                        minWidth: "90px",
                      }}
                      onClick={handleSaveClick}
                    >
                      {windowWidth !== SizeClass.Compact &&
                        tFireflyChimneyInsightsPages("Save")}
                    </Button>
                  )}
                  <AuditPaneWithPopper
                    audit={props.sensor.audit}
                    disablePortal={true}
                    anchorButtonSx={{ marginLeft: "auto" }}
                  />
                </Portal>
                <Stack direction={"column"} sx={{ flex: 1 }} useFlexGap gap={1}>
                  <ValueCardsContainer
                    elevation={1}
                    direction={
                      elementWidthComparer.lte(SizeClass.Compact)
                        ? "column"
                        : "row"
                    }
                    valueCards={[
                      <ValueCard
                        label={"Status"}
                        value={tFireflySharedDomain(
                          `ChimneySensorStatus.${sensor.status}`
                        )}
                      />,
                      <ValueCard
                        label={tFireflySharedDomain("Last Heard")}
                        value={sensor.lastHeard}
                        render={(dateTime: DateTimeISO) => {
                          const end = DateTime.now();
                          const start = DateTime.fromISO(dateTime);
                          if (start > end) {
                            // Remedy against some having lastHeard set to a time in the future (ie. 2080-01-06T00:00:30+00:00)
                            return undefined;
                          }
                          const duration = DurationUtils.fromDates(start, end);
                          return (
                            <DurationDisplay
                              duration={duration}
                              maxUnits={2}
                              timeDirection={"past"}
                            />
                          );
                        }}
                      />,
                      <ValueCard
                        label={tFireflySharedDomain("Battery Level")}
                        value={tFireflySharedDomain(
                          `BatteryLevel.${BatteryLevelUtils.getStatus(
                            sensor.board.voltage
                          )}`
                        )}
                      />,
                      <ValueCard
                        label={tFireflySharedDomain("Cable Status")}
                        value={tFireflySharedDomain(
                          `ChimneySensorCableStatus.${sensor.cableStatus}`
                        )}
                      />,
                    ]}
                  />
                  <Paper
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pl: 2,
                      pr: 2,
                      pt: 1,
                      pb: 1,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {tFireflySharedDomain("Placed in Flue")}:&nbsp;{" "}
                      </Typography>
                      <FlueBreadcrumbs
                        municipality={sensor.municipality}
                        postalAddress={sensor.postalAddress}
                        streetAddress={sensor.streetAddress}
                        flue={{
                          name: sensor.flueName,
                        }}
                      />
                      <Link
                        sx={{ alignSelf: "end" }}
                        component={RouterLink}
                        to={`/chimney-status/view/${sensor.flueId}`}
                      >
                        {tFireflyChimneyInsightsPages("View Flue")}
                      </Link>
                    </Stack>
                  </Paper>
                  <Stack
                    direction={
                      elementWidthComparer.lte(SizeClass.Compact)
                        ? "column"
                        : "row"
                    }
                    sx={{ flex: 1 }}
                    useFlexGap
                    gap={1}
                  >
                    <ChimneySensorFormProperties
                      sensor={sensor}
                      mode={props.mode === "view" ? "read" : "edit"}
                      childPropertyProps={
                        elementWidthComparer.lte(SizeClass.Compact)
                          ? { fullWidth: true }
                          : { fullWidth: true }
                      }
                      sx={{ minWidth: "210px" }}
                    />
                    <TabsAndPanels
                      sx={{ flex: 1 }}
                      value={tabValue}
                      onChange={(value) => handleTabChange(value as TabValue)}
                      tabs={[
                        <Tab
                          label={tFireflySharedDomain("Battery")}
                          value={"battery"}
                        />,
                        <Tab
                          label={tFireflySharedDomain("Radio Signal")}
                          value={"radio-signal"}
                        />,
                      ]}
                      tabPanels={[
                        <TabPanel
                          tabValue={"battery"}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <ChimneySensorBatteryVoltageChart
                            sensor={sensor}
                            sx={{ flex: 1, minHeight: "400px" }}
                          />
                        </TabPanel>,
                        <TabPanel
                          tabValue={"radio-signal"}
                          sx={{
                            flexGrow: 1,
                            minHeight: 0,
                            minWidth: 0,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ChimneySensorRadioSignaChart
                            sensor={sensor}
                            sx={{ flex: 1, minHeight: 0, minWidth: 0 }}
                          />
                        </TabPanel>,
                      ]}
                    />
                  </Stack>
                </Stack>
              </>
            );
          }}
        </Formik>
      </Container>
    </DialogContent>
  );
};
