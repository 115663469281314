import { alpha } from "@mui/material";

export const SignalStrengthLevel = {
  Excellent: alpha("rgb(0,255,0)", 0.5),
  Good: alpha("rgb(144, 238, 144)", 0.5),
  Medium: alpha("rgb(255, 255, 0)", 0.5),
  Weak: alpha("rgb(255, 165, 0)", 0.5),
  VeryLow: alpha("rgb(255, 0, 0)", 0.5),
  NoSignal: alpha("rgb(255, 0, 0)", 0.5),
};
