import { createContext, FC, ReactNode, useMemo } from "react";
import { AccessToken } from "./AccessToken";

interface AccessTokenContextType {
  token: AccessToken;
}

export const AccessTokenContext = createContext(undefined as unknown as AccessTokenContextType);

export interface AccessTokenContextProviderProps {
  token: AccessToken;
  children?: ReactNode;
}

export const AccessTokenContextProvider: FC<AccessTokenContextProviderProps> = (
  props
) => {
  const contextValue = useMemo(
    () => ({ token: props.token }),
    [props.token]
  );

  if (contextValue.token == null) {
    return null;
  }

  return (
    <AccessTokenContext.Provider value={contextValue as AccessTokenContextType}>
      {props.children}
    </AccessTokenContext.Provider>
  );
};
