import {
  Box,
  CircularProgress,
  css,
  Menu,
  MenuItem,
  Stack,
  useTheme,
} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { FC, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { MenuStartButton } from "../menu/MenuStartButton";
import { useTranslation } from "react-i18next";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    rowCount: number;
    totalRowCount?: number;
    loading?: boolean;
  }
}

const StyledDataGridFooterWithToolbar = styled(Box)<{
  $animateTotalVisibleRows: boolean;
}>`
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  align-items: center;

  > .rightSide {
    padding-left: 1em;
    margin-left: auto;

    > .totalVisibleRows {
      ${({ $animateTotalVisibleRows }) =>
        $animateTotalVisibleRows &&
        css`
          animation: ${valueChangeAnimation} 1s normal;
        `}
    }
  }
`;

export interface DataGridFooterWithToolbarProps {
  totalRowCount?: number;
  rowCount: number;
  loading?: boolean;
}

export const DataGridFooterWithToolbar: FC<DataGridFooterWithToolbarProps> = (
  props
) => {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const { t } = useTranslation("shared-ts-mui");
  const [animateTotalVisibleRows, setAnimateTotalVisibleRows] = useState(false);

  useEffect(() => {
    setAnimateTotalVisibleRows(true);
    setTimeout(() => {
      setAnimateTotalVisibleRows(false);
    }, 1000);
  }, [props.rowCount, props.totalRowCount]);
  const handleExportToCSV = () => {
    apiRef.current.exportDataAsCsv();
  };

  const handleExportToExcel = () => {
    apiRef.current.exportDataAsExcel();
  };

  return (
    <StyledDataGridFooterWithToolbar
      $animateTotalVisibleRows={animateTotalVisibleRows}
      sx={{ minHeight: 52, borderTop: "1px solid " + theme.palette.divider }}
    >
      <Box className={"actions"}>
        <MenuStartButton
          label={t("Export")}
          ButtonProps={{
            color: "secondary",
          }}
          menu={(open, anchorEl, onClose) => (
            <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
              <MenuItem onClick={handleExportToCSV}>{t("CSV")}</MenuItem>
              <MenuItem onClick={handleExportToExcel}>{t("Excel")}</MenuItem>
            </Menu>
          )}
        />
      </Box>
      <Stack direction={"row"} className={"rightSide"}>
        {props.loading === true && (
          <CircularProgress size={"1em"} sx={{ mr: 1 }} />
        )}
        <Box component={"span"} sx={{ pr: 2 }}>
          {apiRef.current.getLocaleText("footerTotalRows")}
        </Box>
        <Box component={"span"} className={"totalVisibleRows"}>
          {apiRef.current.getLocaleText("footerTotalVisibleRows")(
            props.rowCount,
            props.totalRowCount ?? -1
          )}
        </Box>
      </Stack>
    </StyledDataGridFooterWithToolbar>
  );
};

const valueChangeAnimation = keyframes`
  0% {
    color: rgb(255, 255, 255, 0.2);
  }
  30% {
    color: rgb(255, 255, 255, 0.4);
  }
  70% {
    color: rgb(255, 255, 255, 0.8);
  }
  100% {
    color: rgb(255, 255, 255, 1);
  }
`;
