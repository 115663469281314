import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Button, Container } from "@mui/material";
import React from "react";
import { SizeClass, useWindowWidth } from "@airmont/shared/ts/ui/responsive";

export const GenerateErrorPage = () => {
  const width = useWindowWidth();

  const handleThrowErrorClick = () => {
    throw new Error("Generated error");
  };
  return (
    <AppPage
      $key={"GenerateErrorPage"}
      name={"Generate Error"}
      mainPane={
        <Container
          maxWidth={
            width === SizeClass.Compact
              ? "xs"
              : width === SizeClass.Medium
              ? "sm"
              : "md"
          }
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Button onClick={handleThrowErrorClick}>Throw Error</Button>
        </Container>
      }
    />
  );
};

export const useGenerateErrorPageEnabled = (): boolean => {
  return localStorage.getItem("GenerateErrorPage") === "true";
};
