import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MunicipalityDto,
  MunicipalityId,
  useMunicipalityDao,
} from "@airmont/firefly/shared/ts/domain";

const resolveInitialMunicipality = (
  initialMunicipalities: Array<MunicipalityDto>
): string | undefined => {
  const storedMunicipality = localStorage.getItem("municipality");
  if (storedMunicipality == null || storedMunicipality === "undefined") {
    return undefined;
  }

  if (!initialMunicipalities.some((it) => it.id === storedMunicipality)) {
    return undefined;
  }
  return storedMunicipality;
};

const storeMunicipality = (value: string | undefined) => {
  localStorage.setItem("municipality", value ?? "undefined");
};

export type MunicipalityContextType = {
  municipalities: Array<MunicipalityDto>;
  municipality: MunicipalityId | undefined;
  setMunicipality: (municipality: MunicipalityId | undefined) => void;
};

const MunicipalityContext = React.createContext({} as MunicipalityContextType);

export interface RegionContextProviderProps {
  initialMunicipalities: Array<MunicipalityDto>;
  children?: ReactNode;
}

export const MunicipalityContextProvider = (
  props: RegionContextProviderProps
) => {
  const [municipalities, setMunicipalities] = useState<Array<MunicipalityDto>>(
    props.initialMunicipalities
  );
  const [municipality, setMunicipality] = useState<string | undefined>(
    resolveInitialMunicipality(props.initialMunicipalities)
  );
  const municipalityDao = useMunicipalityDao();

  useEffect(() => {
    const load = async () => {
      const newMunicipalities = await municipalityDao.getList();
      setMunicipalities(newMunicipalities);
      if (
        municipality != null &&
        !newMunicipalities.some((it) => it.id === municipality)
      ) {
        setMunicipality(undefined);
      }
    };
    load();
  }, [municipalityDao, municipality]);

  const setMunicipality_ = (value: string | undefined) => {
    if (value != null && !municipalities.some((it) => it.id === value)) {
      throw new Error("Municipality does not exist: " + value);
    }
    setMunicipality(value);
    storeMunicipality(value);
  };
  const value: MunicipalityContextType = useMemo(() => {
    return {
      municipalities: municipalities,
      municipality: municipality,
      setMunicipality: setMunicipality_,
    };
  }, [municipalities, municipality, setMunicipality_]);

  return (
    <MunicipalityContext.Provider value={value}>
      {props.children}
    </MunicipalityContext.Provider>
  );
};

export const useMunicipalityContext = (): MunicipalityContextType => {
  const context = useContext(MunicipalityContext);
  if (context == null) {
    throw new Error(
      "useMunicipalityContext must be used within a MunicipalityProvider"
    );
  }
  return context;
};
