import {
  ChimneySensorStatusQuery,
  ChimneySensorStatusQueryDto,
} from "./ChimneySensorStatusQuery";
import { ChimneySensorStatusQueryInput } from "./ChimneySensorStatusQueryInput";
import { useMemo } from "react";

export const useSensorStatusQuery = (
  input: ChimneySensorStatusQueryInput
): ChimneySensorStatusQueryDto => {
  return useMemo(
    () => new ChimneySensorStatusQuery(input).toDto(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(input)
  );
};
