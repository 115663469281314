import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { FC } from "react";
import { SxProps } from "@mui/system";
import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { useFormikContext } from "formik";
import { FlueFormValues } from "../FlueFormValues";
import { FormikProperty, PropertyMode } from "shared-ts-property";
import { useTranslation } from "react-i18next";

export interface AddressPropertiesPanelProps {
  flueStatus: FlueStatusDto;
  mode: PropertyMode;
  fullWidth?: boolean;
  sx?: SxProps;
}

export const AddressPropertiesPanel: FC<AddressPropertiesPanelProps> = (
  props
) => {
  const { mode } = props;
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const formik = useFormikContext<FlueFormValues>();

  return (
    <PropertiesCard
      elevation={0}
      header={{ title: tFireflyDomain("Address") }}
      childPropertyProps={{
        fullWidth: props.fullWidth,
      }}
      sx={props.sx}
    >
      <FormikProperty
        formik={formik}
        name="street"
        label={tFireflyDomain("Street")}
        mode={mode}
        autoFocus
      />
      <FormikProperty
        formik={formik}
        name="houseNumber"
        label={tFireflyDomain("House Number")}
        mode={mode}
      />
      <FormikProperty
        formik={formik}
        name="houseLetter"
        label={tFireflyDomain("House Letter")}
        mode={mode}
      />
      <FormikProperty
        formik={formik}
        name="houseSection"
        label={tFireflyDomain("House Section")}
        mode={mode}
      />
      <FormikProperty
        formik={formik}
        name="postalCode"
        label={tFireflyDomain("Postal Code")}
        mode={mode}
      />
      <FormikProperty
        formik={formik}
        name="postalPlace"
        label={tFireflyDomain("Postal Place")}
        mode={mode}
      />
    </PropertiesCard>
  );
};
