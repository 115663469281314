import { useMemo } from "react";
import { FlueMetricsDao } from "./FlueMetricsDao";
import { useAccessToken } from "shared-ts-utils-authentication";
import { useCustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";

export const useFlueMetricsDao = (): FlueMetricsDao => {
  const accessToken = useAccessToken();
  const customerEnvironment =
    useCustomerEnvironmentName() ??
    _throw(new NullError("customerEnvironment"));

  return useMemo(
    () =>
      new FlueMetricsDao({
        accessToken: accessToken,
        customerEnvironment: customerEnvironment,
      }),
    [accessToken, customerEnvironment]
  );
};
