import React from "react";
import { Stack, Tab, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import LocalFireDepartmentRoundedIcon from "@mui/icons-material/LocalFireDepartmentRounded";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";
import { notNullOrUndefined } from "@airmont/shared/ts/utils/core";
import { TabsAndPanels } from "@airmont/shared/ts/ui/tabs";
import {
  ChimneyFireUtils,
  FlueStatusDto,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { AddressTabPanel } from "./address/AddressTabPanel";
import { OverviewTabPanel } from "./overview/OverviewTabPanel";
import { ChimneyFireTabPanel } from "./chimneyFire/ChimneyFireTabPanel";
import { SweepingTabPanel } from "./sweeping/SweepingTabPanel";
import { BurnsTabPanel } from "./burns/BurnsTabPanel";
import { PicturesTabPanel } from "./pictures/PicturesTabPanel";
import { useTranslation } from "react-i18next";
import { CombustionPlantTabPanel } from "./combustionPlant/CombustionPlantTabPanel";
import { SupervisionTabPanel } from "./supervision/SupervisionTabPanel";
import { DisplayMode } from "shared-ts-app-dialog";
import { SxProps } from "@mui/system";

const MainTabEnumParam = withDefault(
  createEnumParam([
    "overview",
    "combustionPlant",
    "burns",
    "sweepings",
    "temperature-alarm",
    "supervision",
    "pictures",
    "address",
    "sensor",
  ]),
  "overview"
);

export interface FlueStatusPaneProps {
  flueStatus: FlueStatusDto;
  mode: DisplayMode;
  showNavView?: boolean;
  showNavEdit?: boolean;
  showReturn?: boolean;
  showTitle?: boolean;
  sx?: SxProps;
}

export const FlueStatusPane = (props: FlueStatusPaneProps) => {
  const { flueStatus, mode } = props;
  const theme = useTheme();
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const [mainTab, setMainTab] = useQueryParam("mainTab", MainTabEnumParam);

  const activeFire =
    notNullOrUndefined(props.flueStatus.flue.lastChimneyFire, (it) =>
      ChimneyFireUtils.isActiveChimneyFire(DateTime.fromISO(it))
    ) ?? false;

  const handleMainTabChange = (value: string) => {
    setMainTab(value);
  };

  return (
    <Stack direction={"column"} sx={props.sx}>
      <TabsAndPanels
        value={mainTab}
        onChange={handleMainTabChange}
        variant={"scrollable"}
        renderHiddenTabPanels={false}
        sx={{ flex: 1, minHeight: 0, minWidth: 0 }}
        tabs={[
          <Tab
            key={1}
            label={tFireflySharedDomain("Overview")}
            value={"overview"}
          />,
          <Tab
            key={2}
            label={tFireflySharedDomain("Combustion Plant")}
            value={"combustionPlant"}
          />,
          <Tab key={3} label={tFireflySharedDomain("Burns")} value={"burns"} />,
          <Tab
            key={4}
            label={tFireflySharedDomain("Sweeps")}
            value={"sweepings"}
          />,
          <Tab
            key={5}
            label={tFireflySharedDomain("Supervision")}
            value={"supervision"}
          />,
          <Tab
            key={6}
            label={tFireflySharedDomain("Temperature Alarm")}
            value={"temperature-alarm"}
            title={
              activeFire
                ? "Unormalt høye temperaturer i røykløp pågår nå"
                : undefined
            }
            icon={
              activeFire ? (
                <LocalFireDepartmentRoundedIcon
                  sx={{ color: theme.palette.error.main }}
                />
              ) : undefined
            }
            iconPosition={"end"}
          />,
          <Tab
            key={7}
            label={tFireflySharedDomain("Pictures")}
            value={"pictures"}
          />,
          <Tab
            key={8}
            label={tFireflySharedDomain("Address")}
            value={"address"}
          />,
        ]}
        tabPanels={[
          <OverviewTabPanel
            key={1}
            tabValue="overview"
            flueStatus={props.flueStatus}
            mode={props.mode}
          />,
          <CombustionPlantTabPanel
            key={2}
            tabValue="combustionPlant"
            flueStatus={props.flueStatus}
            mode={props.mode}
          />,
          <BurnsTabPanel
            key={3}
            tabValue={"burns"}
            flueId={flueStatus.id}
            chimneyFireThreshold={flueStatus.flue.chimneyFireThreshold}
            sx={{
              pt: 1,
              pl: 0,
              pr: 0,
              pb: 0,
            }}
          />,
          <SweepingTabPanel
            key={4}
            tabValue={"sweepings"}
            flueId={flueStatus.id}
            sx={{
              padding: 0,
            }}
          />,
          <SupervisionTabPanel
            key={5}
            tabValue={"supervision"}
            flueStatus={flueStatus}
          />,
          <PicturesTabPanel
            key={6}
            tabValue={"pictures"}
            flueId={flueStatus.id}
            sx={{
              padding: 0,
            }}
          />,
          <ChimneyFireTabPanel
            key={7}
            id={flueStatus.id}
            tabValue={"temperature-alarm"}
            flueStatus={props.flueStatus}
            sx={{
              pt: 1,
              pl: 0,
              pr: 0,
              pb: 0,
              display: "flex",
            }}
          />,
          <AddressTabPanel
            key={8}
            tabValue="address"
            flueStatus={props.flueStatus}
            displayMode={mode}
          />,
        ]}
      />
    </Stack>
  );
};
