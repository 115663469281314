import { SxProps } from "@mui/system";
import { DependencyList, useMemo } from "react";
import { merge } from "lodash";

export const useMergeSx = (
  obj1: SxProps | undefined,
  obj2: SxProps | undefined,
  deps: DependencyList
): SxProps => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => merge({}, obj1, obj2), deps);
};
