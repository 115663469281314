export interface FontType {
    fontFamily?: string | undefined;
    fontSize?: string | undefined;
    fontWeight?: string | undefined;
    letterSpacing?: string | undefined;
    fontKerning?: string | undefined;
    fontStretch?: string | undefined;
}

export class TextUtil {

    static calculateMaxSize(texts: Array<string>, font: FontType): { width: number, height: number } {

        const el = document.createElement("div");

        el.style.position = "absolute";
        el.style.visibility = "hidden";
        el.style.whiteSpace = "nowrap";
        el.style.left = "-9999px";

        if (font.fontFamily) {
            el.style.fontFamily = font.fontFamily;
        }
        if (font.fontSize) {
            el.style.fontSize = font.fontSize;
        }
        if (font.fontWeight) {
            el.style.fontWeight = font.fontWeight;
        }
        if (font.fontStretch) {
            el.style.fontStretch = font.fontStretch;
        }
        if (font.fontKerning) {
            el.style.fontStretch = font.fontKerning;
        }
        if (font.letterSpacing) {
            el.style.letterSpacing = font.letterSpacing;
        }

        document.body.appendChild(el);

        let maxHeight = 0;
        let maxWidth = 0;
        texts.forEach(it => {
            const textNode = new Text(it);
            el.appendChild(textNode);
            maxWidth = Math.max(TextUtil.resolveWidth(window.getComputedStyle(el)), maxWidth);
            maxHeight = Math.max(TextUtil.resolveWidth(window.getComputedStyle(el)), maxHeight);
            el.removeChild(textNode);
        });

        document.body.removeChild(el);

        return {width: maxWidth, height: maxHeight};
    }

    private static resolveWidth(css: CSSStyleDeclaration): number {
        try {
            return parseFloat(css.width);
        } catch (e) {
            return 0;
        }
    }
}