import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { Event } from "@airmont/shared-ts-event";
import { RenderEventParams } from "../RenderEventParams";
import { useCalendarSettings } from "../CalendarSettingsProvider";

export interface TimeslotEventProps {
  event: Event;
  width?: number;
  renderEvent: (params: RenderEventParams) => ReactNode;
}
export const TimeslotEvent: FC<TimeslotEventProps> = (props) => {
  const { event, width, renderEvent } = props;
  const calendarSettings = useCalendarSettings();
  const pixelsFromTop =
    calendarSettings.timeslotsTopGutterHeight +
    event.start.hour * calendarSettings.timeslotHeight * 2;
  const eventDuration = event.toInterval.toDuration();
  const eventDurationInMillis = eventDuration.toMillis();
  const timeslotDurationInMillis = calendarSettings.timeslotDuration.toMillis();
  const numberOfTimeslots = eventDurationInMillis / timeslotDurationInMillis;
  const height = numberOfTimeslots * calendarSettings.timeslotHeight;

  return (
    <Box
      className={"event"}
      sx={{
        position: "absolute",
        left: 0,
        top: pixelsFromTop + "px",
        height: height + "px",
        width: width,
        backgroundColor: calendarSettings.eventBackgroundColor,
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        "> *": {
          flex: 1,
          minHeight: 0,
        },
        ".MuiListItem-root": {
          padding: 0,
          alignItems: "stretch",
          ".MuiListItemButton-root": {
            padding: 0,
            alignItems: "flex-start",
            ".MuiListItemIcon-root": {
              mt: 1,
              ml: 0.5,
              svg: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }}
    >
      {renderEvent({
        event,
        type: "ListItem",
        clickAction: "view:Card",
      })}
    </Box>
  );
};
