import React, { FC } from "react";
import {
  ActionGroup,
  ActionListItem,
  hasItemsSelected,
  ItemVariant,
  resolveActionGroupPreferences,
  SelectableAction,
  useResolveItemColors,
} from "@airmont/shared/ts/ui/action";
import { Layout } from "../../../Layout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { LayoutDirection } from "../../../LayoutDirection";

export interface GroupNodeProps {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: ActionGroup;
  open: boolean;
  hideLabel?: boolean;
  hasSiblings: boolean;
  onClick: () => void;
}

export const GroupNode: FC<GroupNodeProps> = (props) => {
  const { variant, layout, layoutDirection, item, hideLabel, onClick } = props;
  const preferences = resolveActionGroupPreferences(item.preferences, layout);
  const hideGroupNodeSelected = preferences?.hideGroupNodeSelected ?? false;
  const hasChildSelected = hasItemsSelected(item);
  const actionItem = {
    type: "Action",
    selectable: true,
    selected: true,
    name: item.name ?? "",
    nameBreakable: item.nameBreakable,
    icon: item.icon,
    onExecute: onClick,
  } as SelectableAction;

  const { onContainerColorWhenSelected, containerColorWhenSelected } =
    useResolveItemColors();

  return (
    <ActionListItem
      variant={variant}
      item={actionItem}
      layout={layout}
      layoutDirection={layoutDirection}
      hideSelectionIcon={true}
      hideLabel={hideLabel}
      endAdornment={<ExpandMoreIcon />}
      sx={{
        "&.direction-column": {
          "&.layout-medium": {
            borderRadius: 3,
            borderBottomLeftRadius: props.hasSiblings ? 0 : undefined,
            borderBottomRightRadius: props.hasSiblings ? 0 : undefined,
          },
        },
        "&&.selected": {
          ".MuiListItemButton-root": {
            color: hasChildSelected ? onContainerColorWhenSelected : undefined,
            backgroundColor:
              hasChildSelected && !hideGroupNodeSelected
                ? containerColorWhenSelected
                : "unset",
            ".MuiListItemIcon-root": {
              color: hasChildSelected
                ? onContainerColorWhenSelected
                : undefined,
              backgroundColor:
                hasChildSelected && !hideGroupNodeSelected
                  ? containerColorWhenSelected
                  : "unset",
            },
          },
        },
      }}
    />
  );
};
