import { useCustomerEnvironmentContext } from "./useCustomerEnvironmentContext";
import { CustomerEnvironmentName } from "./CustomerEnvironmentDeprecated";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export const useCustomerEnvironmentName = ():
  | CustomerEnvironmentName
  | undefined => {
  const context = useCustomerEnvironmentContext();
  if (context == null) {
    throw new Error(
      "useCustomerEnvironmentName must be used within a EnvironmentProvider"
    );
  }
  return context.environmentId;
};

export const useCustomerEnvironmentNameOrThrow =
  (): CustomerEnvironmentName => {
    const context = useCustomerEnvironmentContext();
    if (context == null) {
      throw new Error(
        "useCustomerEnvironmentName must be used within a EnvironmentProvider"
      );
    }
    return (
      context.environmentId ??
      _throw(new IllegalStateError("environmentName not set"))
    );
  };
