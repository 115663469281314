import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useUserOrNull } from "shared-ts-utils-authentication";
import { useAppInfo } from "shared-ts-app-info";
import { SettingConfig } from "./SettingConfig";
import { UserSettingsContext } from "./UserSettingsContext";
import { SettingKey } from "./SettingKeyDto";
import { NonAsyncSettingsStorage } from "./storage/NonAsyncSettingsStorage";
import { AsyncSettingsStorage } from "./storage/AsyncSettingsStorage";
import { ObjectUtils, UnsupportedError } from "@airmont/shared/ts/utils/core";
import { SettingOverrides } from "./SettingOverrides";

const resolveNewValue = <TValue>(
  prevValue: TValue,
  newValueOrFn: SetStateAction<TValue>
): TValue => {
  let newValue: TValue;
  if (typeof newValueOrFn === "function") {
    const fn: (prevState: TValue) => TValue = newValueOrFn as any;
    newValue = fn(prevValue);
  } else {
    newValue = newValueOrFn;
  }
  return newValue;
};

export type UseUserSettingReturnType<TValue> = [
  TValue,
  Dispatch<SetStateAction<TValue>>
];
export const useUserSetting = <TValue>(
  name: string,
  settingConfig: SettingConfig<NonNullable<TValue>>,
  overrides?: SettingOverrides<TValue>
): UseUserSettingReturnType<TValue> => {
  const appInfo = useAppInfo();
  const user = useUserOrNull();
  const { settings, setSettings, storage } = useContext(UserSettingsContext);

  const settingKey = SettingKey.from(name, appInfo.key, user?.id ?? null);
  const settingAsString = settings[settingKey.asString];
  const value = useMemo(() => {
    return (
      settingAsString != null
        ? overrides?.deserialize != null
          ? overrides.deserialize(settingAsString)
          : settingConfig.deserialize(settingAsString)
        : null
    ) as TValue;
  }, [settingAsString, settingConfig, overrides]);

  const setValue_: Dispatch<SetStateAction<TValue>> = useCallback(
    (newValueOrFn: SetStateAction<TValue>) => {
      const prevValueSerialized = settings[settingKey.asString];
      const newValue = resolveNewValue<TValue>(value, newValueOrFn);
      const newValueSerialized =
        newValue != null
          ? overrides?.serialize != null
            ? overrides.serialize(newValue)
            : settingConfig.serialize(newValue)
          : null;

      const isChange = !ObjectUtils.equals(
        prevValueSerialized,
        newValueSerialized
      );

      if (isChange) {
        setSettings((prev) => {
          const newValueSerialized =
            newValue == null
              ? null
              : overrides?.serialize != null
              ? overrides.serialize(newValue)
              : settingConfig.serialize(newValue);

          return {
            ...prev,
            [settingKey.asString]: newValueSerialized,
          };
        });

        if (overrides?.storeLocally === true) {
          localStorage.writeValue(
            settingKey,
            newValueOrFn,
            settingConfig,
            overrides
          );
        } else if (storage.type === "NonAsync") {
          const nonAsyncStorage = storage as NonAsyncSettingsStorage;
          nonAsyncStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else if (storage.type === "Async") {
          const asyncStorage = storage as AsyncSettingsStorage;
          asyncStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else {
          throw new UnsupportedError(
            "Unsupported SettingsStorage.TValue: " + storage.type
          );
        }
      }
    },
    [
      settingKey,
      value,
      settings,
      setSettings,
      settingConfig,
      overrides,
      storage,
    ]
  );

  return [value, setValue_];
};

export type UseUserSettingWithDefaultReturnType<TValue> = [
  TValue,
  Dispatch<SetStateAction<TValue>>
];

export const useUserSettingWithDefault = <TValue>(
  name: string,
  settingConfig: SettingConfig<NonNullable<TValue>>,
  defaultValue: TValue,
  overrides?: SettingOverrides<TValue>
): UseUserSettingWithDefaultReturnType<TValue> => {
  const appInfo = useAppInfo();
  const user = useUserOrNull();
  const { settings, setSettings, storage, localStorage } =
    useContext(UserSettingsContext);

  const settingKey = useMemo(
    () => SettingKey.from(name, appInfo.key, user?.id ?? null),
    [name, appInfo.key, user?.id]
  );
  const settingAsString =
    overrides?.storeLocally === true
      ? localStorage.readRawValue(settingKey, settingConfig, overrides)
      : settings[settingKey.asString];

  const value = useMemo(() => {
    return (
      settingAsString != null
        ? overrides?.deserialize != null
          ? overrides.deserialize(settingAsString)
          : settingConfig.deserialize(settingAsString)
        : null
    ) as TValue;
  }, [settingAsString, settingConfig, overrides]);

  const setValue_: Dispatch<SetStateAction<TValue>> = useCallback(
    (newValueOrFn: SetStateAction<TValue>) => {
      const prevValueSerialized = settings[settingKey.asString];
      const newValue = resolveNewValue(value, newValueOrFn);
      const newValueSerialized =
        newValue != null
          ? overrides?.serialize != null
            ? overrides.serialize(newValue)
            : settingConfig.serialize(newValue)
          : null;

      const isChange = !ObjectUtils.equals(
        prevValueSerialized,
        newValueSerialized
      );
      if (isChange) {
        setSettings((prev) => {
          return {
            ...prev,
            [settingKey.asString]: newValueSerialized,
          };
        });

        if (overrides?.storeLocally === true) {
          localStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else if (storage.type === "NonAsync") {
          const nonAsyncStorage = storage as NonAsyncSettingsStorage;
          nonAsyncStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else if (storage.type === "Async") {
          const asyncStorage = storage as AsyncSettingsStorage;
          asyncStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else {
          throw new UnsupportedError(
            "Unsupported SettingsStorage.TValue: " + storage.type
          );
        }
      }
    },
    [
      settingKey,
      value,
      settings,
      setSettings,
      overrides,
      settingConfig,
      storage,
      localStorage,
    ]
  );

  if (value != null) {
    return [value, setValue_];
  }

  return [defaultValue, setValue_];
};
