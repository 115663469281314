import { FC, ReactNode } from "react";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useLanguage } from "shared-ts-language";
import { Trans, useTranslation } from "react-i18next";
import { useConsentToCollectUsageDataWithNullAsDefault } from "./useConsentToCollectUsageDataWithNullAsDefault";
import { Formik } from "formik";
import { ConsentToCollectUsageDataCheckbox } from "./ConsentToCollectUsageDataCheckbox";
import { AnonymizeUsageDataCheckbox } from "./AnonymizeUsageDataCheckbox";
import { useAnonymizeUsageDataWithNullAsDefault } from "./useAnonymizeUsageDataWithNullAsDefault";
import { PostHogUtils } from "./PostHogUtils";
import { usePostHog } from "posthog-js/react";

export interface PostHogConsentProviderProps {
  children: ReactNode;
}

export const PostHogConsentProvider: FC<PostHogConsentProviderProps> = (
  props
) => {
  const { t } = useTranslation("shared-ts-posthog");
  const postHog = usePostHog();
  const appInfo = useAppInfo();
  const { language, setLanguage } = useLanguage();
  const [consentToCollectUsageData, setConsentToCollectUsageData] =
    useConsentToCollectUsageDataWithNullAsDefault();
  const [, setAnonymizeUsageData] = useAnonymizeUsageDataWithNullAsDefault();

  const handleConsentFormSubmit = (values: PostHogConsentForm) => {
    if (values.consentToCollectUsageData) {
      setAnonymizeUsageData(values.anonymizeUsageData);
    } else {
      setAnonymizeUsageData(null);
    }
    setConsentToCollectUsageData(values.consentToCollectUsageData);

    new PostHogUtils(postHog).handleConsentChange(
      values.consentToCollectUsageData,
      values.anonymizeUsageData
    );
  };

  if (consentToCollectUsageData == null) {
    return (
      <NavigationLessAppPage
        appName={appInfo.name}
        appIcon={appInfo.icon?.large}
        language={language}
        onLanguageChange={setLanguage}
      >
        <Formik<PostHogConsentForm>
          initialValues={{
            consentToCollectUsageData: true,
            anonymizeUsageData: false,
          }}
          onSubmit={handleConsentFormSubmit}
        >
          {({ values, setFieldValue, submitForm }) => {
            return (
              <Stack useFlexGap gap={2}>
                <Typography>
                  <Trans
                    i18nKey={"airmont uses PostHog to capture..."}
                    ns={"shared-ts-posthog"}
                    values={{ ApplicationName: appInfo.name }}
                    components={{
                      Link: (
                        <Link
                          href={"https://www.posthog.com"}
                          target={"_blank"}
                        />
                      ),
                    }}
                  />
                </Typography>
                <Typography>{t("PostHog stores usage data ...")}</Typography>
                <Typography>{t("We understand no consent ...")}</Typography>
                <ConsentToCollectUsageDataCheckbox
                  value={values.consentToCollectUsageData}
                  onChange={(value) =>
                    setFieldValue("consentToCollectUsageData", value)
                  }
                />
                {values.consentToCollectUsageData && (
                  <AnonymizeUsageDataCheckbox
                    value={values.anonymizeUsageData}
                    onChange={(value) =>
                      setFieldValue("anonymizeUsageData", value)
                    }
                  />
                )}
                <Button variant={"contained"} onClick={() => submitForm()}>
                  {t("Submit")}
                </Button>
              </Stack>
            );
          }}
        </Formik>
      </NavigationLessAppPage>
    );
  }
  return props.children;
};

type PostHogConsentForm = {
  consentToCollectUsageData: boolean;
  anonymizeUsageData: boolean;
};
