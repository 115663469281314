import React, { FC } from "react";
import { Stack } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { NotificationContextProvider } from "@airmont/shared/ts/ui/notification";

export const UnauthenticatedRootPage: FC = () => {
  const location = useLocation();

  console.log(
    "------------------------------------------------------------------------------"
  );
  console.log(
    " UnauthenticatedRootPage -----------------------------------------------------"
  );
  console.log(
    "------------------------------------------------------------------------------"
  );
  console.log("location ", location);

  return (
    <Stack
      className={"UnauthenticatedRootPage"}
      sx={{
        height: "100%",
        p: 2,
        "> *": {
          flexGrow: 1,
          minHeight: 0,
          minWidth: 0,
        },
      }}
    >
      <NotificationContextProvider>
        <Outlet />
      </NotificationContextProvider>
    </Stack>
  );
};
