import { Property, PropertyProps } from "shared-ts-property";
import React, { FC } from "react";
import { ChimneyHatStringsEnum } from "@airmont/firefly/shared/ts/domain";
import { useTranslation } from "react-i18next";

export type ChimneyHatPropertyProps = Omit<
  PropertyProps,
  "name" | "label" | "type" | "valueOptions"
>;

export const ChimneyHatProperty: FC<ChimneyHatPropertyProps> = (props) => {
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  return (
    <Property
      {...props}
      name="chimneyHat"
      label={tFireflySharedDomain("Chimney Hat")}
      type={"string"}
      valueOptions={{
        multiple: false,
        restrictToOptions: true,
        options: [
          {
            id: ChimneyHatStringsEnum.Yes,
            label: tFireflySharedDomain(
              `ChimneyHat.${ChimneyHatStringsEnum.Yes}`
            ),
          },
          {
            id: ChimneyHatStringsEnum.YesWithFan,
            label: tFireflySharedDomain(
              `ChimneyHat.${ChimneyHatStringsEnum.YesWithFan}`
            ),
          },
          {
            id: ChimneyHatStringsEnum.No,
            label: tFireflySharedDomain(
              `ChimneyHat.${ChimneyHatStringsEnum.No}`
            ),
          },
          {
            id: ChimneyHatStringsEnum.Unknown,
            label: tFireflySharedDomain(
              `ChimneyHat.${ChimneyHatStringsEnum.Unknown}`
            ),
          },
        ],
      }}
    />
  );
};
