export enum FlueTypeEnum {
  Unknown,
  Brick,
  Element,
  Steel,
  SteelOutside,
  SteelFlexi,
  Ceramic,
  PumiceStone,
}
export enum FlueTypeStringsEnum {
  Unknown = "Unknown",
  Brick = "Brick",
  Elemental = "Elemental",
  Steel = "Steel",
  SteelExternal = "SteelExternal",
  SteelFlexi = "SteelFlexi",
  ElementalCeramic = "ElementalCeramic",
  ElementalPimpstone = "ElementalPimpstone",
}
