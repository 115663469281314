import { ChartsAxisContentProps, LineSeriesType } from "@mui/x-charts";
import { FC } from "react";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Duration } from "luxon";
import { With } from "@airmont/shared/ts/ui/react";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import {IllegalArgumentError, notNull} from "@airmont/shared/ts/utils/core";

export const ChartsTooltipAxisContent: FC<ChartsAxisContentProps> = (props) => {
  const theme = useTheme();
  if (!(typeof props.axisValue === "number" || props.axisValue === null)) {
    throw new IllegalArgumentError(
      "axisValue is expected to be number containing duration in milliseconds: " +
        props.axisValue
    );
  }
  const duration = notNull(props.axisValue, value => Duration.fromMillis(value));

  return (
    <Stack
      direction={"column"}
      sx={{
        ml: 2,
        mr: 2,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        borderRadius: 1,
      }}
    >
      <Typography p={1}>
        {duration != null && <DurationDisplay duration={duration} maxUnits={2} />}
      </Typography>
      <Divider />
      <Stack p={1} direction={"row"} sx={{ alignItems: "center" }}>
        <With>
          {() => {
            const dataIndex = props.dataIndex;
            if (dataIndex == null) {
              return undefined;
            }
            const series = props.series as Array<LineSeriesType>;
            return series.map((itSeries) => {
              const value = itSeries.data?.[dataIndex];
              return (
                <>
                  <Box
                    sx={{
                      width: theme.spacing(1),
                      height: theme.spacing(1),
                      borderRadius: "50%",
                      backgroundColor: itSeries.color,
                      borderColor: theme.palette.background.paper,
                      border: `solid ${
                        theme.palette.background.paper
                      } ${theme.spacing(0.25)}`,
                      boxSizing: "content-box",
                    }}
                  />
                  <Typography sx={{ marginLeft: "auto" }}>
                    {value != null
                      ? itSeries.valueFormatter?.(value, {
                          dataIndex: dataIndex,
                        })
                      : "?"}
                  </Typography>
                </>
              );
            });
          }}
        </With>
      </Stack>
    </Stack>
  );
};
