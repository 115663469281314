import { FC, ReactNode, useMemo } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { BurnQualityCount, FlueId } from "@airmont/firefly/shared/ts/domain";
import { useBurnDao } from "../useBurnDao";
import { BurnQualityCountQuery } from "./BurnQualityCountQuery";

export interface BurnQualityCountLoaderResult {
  period: UseQueryResult<BurnQualityCount>;
  total: UseQueryResult<BurnQualityCount>;
}

export interface BurnQualityForPeriodLoaderProps {
  flueId: FlueId;
  start: DateTime;
  end: DateTime;
  children: (result: BurnQualityCountLoaderResult) => ReactNode;
}

export const BurnQualityCountByPeriodLoader: FC<
  BurnQualityForPeriodLoaderProps
> = (props) => {
  const { flueId, start, end } = props;
  const burnDao = useBurnDao();

  const query: BurnQualityCountQuery = useMemo(() => {
    const queryStartTime = start;
    const queryEndTime = start.endOf("month");
    return {
      flueId: flueId,
      start: queryStartTime,
      end: queryEndTime,
    } as BurnQualityCountQuery;
  }, [flueId, start]);

  const periodQueryResult = useQuery({
    queryKey: ["BurnDao.getBurnQualityCountByPeriod", query],
    queryFn: () => burnDao.querySingleBurnQualityCount(query),
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const totalQueryResult = useQuery({
    queryKey: ["BurnDao.getBurnQualitySinceSweep", flueId],
    queryFn: () => burnDao.getBurnQualityCountSinceSweep(flueId),
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const result = useMemo(() => {
    return { period: periodQueryResult, total: totalQueryResult };
  }, [periodQueryResult, totalQueryResult]);

  return props.children(result);
};
