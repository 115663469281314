import React, { FC, ReactElement, ReactNode, useMemo } from "react";
import { Box, SvgIconProps, useTheme } from "@mui/material";
import styled from "styled-components";
import { SizeClass, useWindowSize } from "@airmont/shared/ts/ui/responsive";
import { PanelGroupStorage } from "react-resizable-panels";
import { AppPagePanelGroupH } from "./horizontal/AppPagePanelGroupH";
import { AppPagePanelGroupV } from "./vertical/AppPagePanelGroupV";
import { AppPageSwipeableBottomPane } from "./mobile/AppPageSwipeableBottomPane";
import { useResizeDetector } from "react-resize-detector";
import { NullError } from "@airmont/shared/ts/utils/core";
import { IncreasePaperElevation } from "@airmont/shared/ts/ui/elevation";
import {
  BooleanSetting,
  StringSetting,
  UserSettings,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { Variant } from "./Variant";
import { SupportingPaneProps } from "./shared/SupportingPaneProps";
import { SupportingPaneProvider } from "./shared/SupportingPaneProvider";
import { AppPageLayout } from "./shared/AppPageLayout";
import { MainPaneProps } from "./shared/MainPaneProps";
import { useAppInfo } from "shared-ts-app-info";
import { useUserOrNull } from "shared-ts-utils-authentication";
import { useAppPageVariant } from "./useAppPageVariant";

export const StyledAppPage = styled(Box)`
  position: relative;
  display: flex;

  &.compact {
    flex-direction: column;
  }

  div[data-panel-group] {
    flex: 1;
  }

  > :first-child {
    flex: 1;
  }

  > aside {
    display: flex;
  }
`;

export interface AppPageProps {
  $key?: string;
  name: string;
  variant?: Variant;
  hideName?: boolean;
  icon?: React.ComponentType<SvgIconProps>;
  mainPaneProps?: MainPaneProps;
  mainPaneActions?: ReactNode;
  mainPane: ReactElement;
  supportingPane?: ReactElement | SupportingPaneProvider;
  supportingPaneProps?: SupportingPaneProps;
  disableResize?: boolean;
  layout?: AppPageLayout;
}

export const AppPage: FC<AppPageProps> = (props) => {
  if (props.supportingPane != null && props.supportingPaneProps == null) {
    throw new NullError("supportingPane requires supportingPaneProps");
  }
  const contextVariant = useAppPageVariant();
  const variant = props.variant ?? contextVariant;
  const theme = useTheme();
  const app = useAppInfo();
  const user = useUserOrNull();
  const { windowWidth } = useWindowSize();
  const { width, height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
  });
  const [supportingPaneOpen, setSupportingPaneOpen] = useUserSettingWithDefault(
    `${props.$key ?? props.name}.supportingPaneOpen`,
    BooleanSetting,
    false
  );
  const userSettings = useMemo(
    () => new UserSettings({ app: app.key, user: user }),
    [app.key, user]
  );

  const handleSupportingPanelOpen = (open: boolean) => {
    setSupportingPaneOpen(open);
  };

  const handlePanelGroupStorage: PanelGroupStorage = {
    getItem: (name: string): string | null => {
      return userSettings.read(name, StringSetting);
    },
    setItem: (name: string, value: string): void => {
      userSettings.write(name, value, StringSetting);
    },
  };

  const mainPane = props.mainPane;

  const layout =
    props.layout != null ? props.layout : resolveLayout(windowWidth);

  if (layout === "SwipeableBottomPane") {
    return (
      <StyledAppPage className={"AppPage compact"} ref={ref}>
        <IncreasePaperElevation>
          <AppPageSwipeableBottomPane
            name={props.name}
            width={width}
            height={height}
            variant={variant}
            hideName={props.hideName}
            icon={props.icon}
            mainPaneProps={props.mainPaneProps}
            mainPaneActions={props.mainPaneActions}
            supportingPaneProps={props.supportingPaneProps}
            supportingPane={props.supportingPane}
            supportingPaneOpen={supportingPaneOpen}
            disableResize={props.disableResize}
            onSupportingPaneOpen={handleSupportingPanelOpen}
            onPanelGroupStorage={handlePanelGroupStorage}
          >
            {mainPane}
          </AppPageSwipeableBottomPane>
        </IncreasePaperElevation>
      </StyledAppPage>
    );
  } else if (layout === "PanelGroupVertical") {
    return (
      <StyledAppPage
        className={"AppPage medium"}
        ref={ref}
        sx={{
          flexDirection: "row",
          rowGap: theme.spacing(1),
        }}
      >
        <IncreasePaperElevation>
          <AppPagePanelGroupV
            name={props.name}
            width={width}
            height={height}
            variant={variant}
            hideName={props.hideName}
            icon={props.icon}
            mainPaneProps={props.mainPaneProps}
            mainPaneActions={props.mainPaneActions}
            supportingPaneProps={props.supportingPaneProps}
            supportingPane={props.supportingPane}
            supportingPaneOpen={supportingPaneOpen}
            disableResize={props.disableResize}
            onSupportingPaneOpen={handleSupportingPanelOpen}
            onPanelGroupStorage={handlePanelGroupStorage}
          >
            {mainPane}
          </AppPagePanelGroupV>
        </IncreasePaperElevation>
      </StyledAppPage>
    );
  }
  return (
    <StyledAppPage
      className={"AppPage"}
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <IncreasePaperElevation>
        <AppPagePanelGroupH
          name={props.name}
          width={width}
          height={height}
          variant={variant}
          hideName={props.hideName}
          icon={props.icon}
          maxWidth={width}
          mainPaneProps={props.mainPaneProps}
          mainPaneActions={props.mainPaneActions}
          supportingPaneProps={props.supportingPaneProps}
          supportingPane={props.supportingPane}
          supportingPaneOpen={supportingPaneOpen}
          disableResize={props.disableResize}
          onSupportingPaneOpen={handleSupportingPanelOpen}
          onPanelGroupStorage={handlePanelGroupStorage}
        >
          {mainPane}
        </AppPagePanelGroupH>
      </IncreasePaperElevation>
    </StyledAppPage>
  );
};

const resolveLayout = (width: SizeClass): AppPageLayout => {
  if (width === SizeClass.Compact) {
    return "SwipeableBottomPane";
  } else if (width === SizeClass.Medium) {
    return "PanelGroupVertical";
  } else {
    return "PanelGroupHorizontal";
  }
};
