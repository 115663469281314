import { DateTime } from "luxon";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { MunicipalityMetricsTimeUnitEnum } from "./MunicipalityMetricsTimeUnit";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { MunicipalityId } from "./MunicipalityId";

export interface MunicipalityMetricsQuery {
  municipality: MunicipalityId;
  type?: MunicipalityMetricsTimeUnitEnum;
  time?: DateTime;
  toDto: () => MunicipalityMetricsQueryDto;
}

export interface MunicipalityMetricsQueryDto {
  municipality: MunicipalityId;
  type?: MunicipalityMetricsTimeUnitEnum;
  time?: DateTimeISO;
}

export class MunicipalityMetricsQueryImpl implements MunicipalityMetricsQuery {
  readonly municipality: MunicipalityId;
  readonly type: MunicipalityMetricsTimeUnitEnum;
  readonly time?: DateTime;
  constructor(args: {
    municipality: MunicipalityId;
    type: MunicipalityMetricsTimeUnitEnum;
    time?: DateTimeISO | DateTime;
  }) {
    this.municipality = args.municipality;
    this.type = args.type;
    this.time =
      args.time !== undefined
        ? typeof args.time === "string"
          ? DateTime.fromISO(args.time)
          : args.time
        : undefined;
  }

  toDto(): MunicipalityMetricsQueryDto {
    return {
      municipality: this.municipality,
      type: this.type,
      time:
        this.time !== undefined
          ? this.time.toUTC().toISO() ?? _throw(new IllegalStateError("time"))
          : undefined,
    };
  }
}
