import { FC, ReactNode, useMemo } from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import { SxProps } from "@mui/system";
import { merge } from "lodash";

export const StyledSupportingPane = styled(Box)``;

export interface SupportingPaneProps {
  open: boolean;
  sx?: SxProps;
  children: ReactNode;
  onToggleOpen?: (value: boolean) => void;
}

export const SupportingPane: FC<SupportingPaneProps> = (props) => {
  const sx: SxProps = useMemo(() => {
    return merge(
      {
        flex: 1,
        minHeight: 0,
        minWidth: 0,
        display: props.open ? "flex" : undefined,
      },
      props.sx
    );
  }, [props.open, props.sx]);

  return (
    <StyledSupportingPane
      className={"SupportingPane"}
      component={"aside"}
      sx={sx}
    >
      {props.open && props.children}
    </StyledSupportingPane>
  );
};
