import { StreetAddress, StreetAddressDto } from "./StreetAddress";
import { CadastralAddressDto } from "./CadastralAddress";
import { PostalPlace } from "../postalCode/PostalPlace";
import { MunicipalityId } from "../municipality/MunicipalityId";
import { PostalCodeId } from "../postalCode/PostalCode";
import { MunicipalityDto } from "../municipality/Municipality";

export type AddressId = string;

export class AddressIdResolver {
  static resolveAddressId(args: {
    municipalityId: MunicipalityId;
    postalCode: PostalCodeId;
    streetAddress?: StreetAddressDto;
    cadastralAddress?: CadastralAddressDto;
  }): AddressId {
    let id = args.municipalityId + ":" + args.postalCode;
    if (args.streetAddress != null) {
      id += ":" + args.streetAddress.street;
      if (args.streetAddress.houseNumber != null) {
        id += ":" + args.streetAddress.houseNumber;
      }
      if (args.streetAddress.houseLetter != null) {
        id += ":" + args.streetAddress.houseLetter;
      }
      if (args.streetAddress.houseSection != null) {
        id += ":" + args.streetAddress.houseSection;
      }
    } else if (args.cadastralAddress != null) {
      id += ":" + args.cadastralAddress.cadastralUnit;
      if (args.cadastralAddress.propertyUnit != null) {
        id += ":" + args.cadastralAddress.propertyUnit;
      }
      if (args.cadastralAddress.leaseNumber != null) {
        id += ":" + args.cadastralAddress.leaseNumber;
      }
      if (args.cadastralAddress.usageName != null) {
        id += ":" + args.cadastralAddress.usageName;
      }
    }
    return id;
  }
}

export interface AddressDto {
  id: AddressId;
  municipality: MunicipalityDto;
  postalAddress: PostalPlace;
  streetAddress: StreetAddressDto;
  cadastralAddress?: CadastralAddressDto;
}

export class Address {
  readonly id: AddressId;
  readonly municipalityId: MunicipalityDto;
  readonly postalCodeAndName: PostalPlace;
  readonly streetAddress: StreetAddress;
  readonly cadastralAddress?: CadastralAddressDto;

  constructor(args: AddressDto) {
    this.municipalityId = args.municipality;
    this.postalCodeAndName = args.postalAddress;
    this.streetAddress = new StreetAddress(args.streetAddress);
    this.cadastralAddress = args.cadastralAddress;
    this.id = args.id;
  }
}
