import { FlueMetrics } from "../metrics/FlueMetrics";
import { ArrayUtils, MathUtils } from "@airmont/shared/ts/utils/core";

export const useResolveSootIndexPredictedSeries = (
  thisYear: number,
  thisMonth: number | null,
  predictedFlueMetricsByMonth: Record<string, FlueMetrics>
): Array<number | null> => {
  return ArrayUtils.createAndFill(12, (index) => {
    const month = index + 1;
    if (thisMonth == null || month <= thisMonth) {
      return null;
    }
    const flueMetrics = predictedFlueMetricsByMonth[`${thisYear}-${month}`];
    if (flueMetrics == null) {
      return null;
    }
    return MathUtils.round(flueMetrics.metrics.sootIndex);
  });
};
