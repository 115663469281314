import * as React from "react";
import { FC, useEffect, useMemo, useState } from "react";
import {
  BurnId,
  BurnList,
  BurnNonSensitiveChart,
  BurnNonSensitiveUi,
  BurnTemperatureSeriesNonSensitive,
} from "@airmont/firefly/shared/ts/domain";
import {
  Box,
  CircularProgress,
  debounce,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "shared-ts-mui";
import { SxProps } from "@mui/system";
import { ArrayUtils, notUndef } from "@airmont/shared/ts/utils/core";
import {
  SizeClass,
  useWindowWidth,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";
import { useTranslation } from "react-i18next";
import { BurnInfoMultiple } from "./BurnInfoMultiple";
import { BurnInfoSingle } from "./BurnInfoSingle";

export interface BurnsPaneProps {
  loading?: boolean;
  burns?: Array<BurnNonSensitiveUi>;
  noDataText?: string;
  burnTemperatureSeries?: Array<BurnTemperatureSeriesNonSensitive>;
  maxTemperatures?: {
    ever?: number;
    sinceSweep?: number;
  };
  chimneyFireThreshold?: number;
  layout: SizeClass;
  sx?: SxProps;
}

export const BurnsPanel: FC<BurnsPaneProps> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-chimney-insights-ts-domain");
  const windowWidthComparer = useWindowWidthComparer();
  const width = useWindowWidth();
  const [burns, setBurns] = useState<Array<BurnNonSensitiveUi> | undefined>(
    undefined
  );
  const [hoveredBurn, setHoveredBurn] = useState<BurnId | undefined>();
  const [multiSelect, setMultiSelect] = useState(false);
  const [selectedBurnIndexes, setSelectedBurnIndexes] = useState<
    Array<boolean> | undefined
  >(props.burns?.map((it) => false) ?? undefined);
  const lastSelectedBurn: BurnNonSensitiveUi | undefined = useMemo(() => {
    if (
      multiSelect ||
      props.burns === undefined ||
      selectedBurnIndexes === undefined ||
      selectedBurnIndexes.length === 0
    ) {
      return undefined;
    }
    const selectedBurnIndex = selectedBurnIndexes.findIndex((it) => it);
    return props.burns[selectedBurnIndex];
  }, [props.burns, selectedBurnIndexes, multiSelect]);
  const selectedBurns = useMemo(() => {
    return burns?.filter((it, index) => {
      return selectedBurnIndexes?.[index] ?? false;
    });
  }, [burns, selectedBurnIndexes]);
  const everySelected = useMemo(
    () =>
      (selectedBurnIndexes?.length ?? 0) > 0 &&
      selectedBurnIndexes?.every((it) => it),
    [selectedBurnIndexes]
  );
  const someSelected = useMemo(
    () => !everySelected && selectedBurnIndexes?.some((it) => it),
    [everySelected, selectedBurnIndexes]
  );

  const noneSelected = !(someSelected || everySelected);

  const [selectedBurnTemperatureSeries, setSelectedBurnTemperatureSeries] =
    useState<Array<BurnTemperatureSeriesNonSensitive> | undefined>(undefined);
  const burnById = useMemo(
    () =>
      notUndef(burns, (burns) =>
        ArrayUtils.toObjectDictionary(burns, (it) => it.id)
      ),
    [burns]
  );

  useEffect(() => {
    if (props.burns === undefined) {
      setBurns(undefined);
    } else {
      setBurns(props.burns);
      setHoveredBurn(undefined);
      const selectedBurnIndexes =
        props.burns?.map((it, index) => index === 0) ?? undefined;
      setSelectedBurnIndexes(selectedBurnIndexes);
    }
  }, [props.burns, props.burnTemperatureSeries]);

  useEffect(() => {
    const selectedBurnTemperatureSeries: Array<BurnTemperatureSeriesNonSensitive> =
      [];
    props.burnTemperatureSeries?.forEach((it, index) => {
      if (
        (selectedBurnIndexes?.[index] ?? false) ||
        it.burnId === hoveredBurn
      ) {
        selectedBurnTemperatureSeries.push(it);
      }
    });
    setSelectedBurnTemperatureSeries(selectedBurnTemperatureSeries);
  }, [props.burnTemperatureSeries, selectedBurnIndexes, hoveredBurn]);

  const handleBurnHovered = (value: BurnId | undefined) => {
    setHoveredBurn(value);
  };

  const debouncedHandleBurnHovered = debounce((value: BurnId | undefined) => {
    handleBurnHovered(value);
  }, 200);
  const handleBurnsSelected = (value: Array<boolean>) => {
    setSelectedBurnIndexes(value);
  };

  const handleSelectionToggle = () => {
    if (everySelected) {
      setSelectedBurnIndexes((prevState) => prevState?.map((it) => false));
    } else if (someSelected || noneSelected) {
      setSelectedBurnIndexes((prevState) => prevState?.map((it) => true));
    }
  };

  const handleMultiSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedBurnIndexes((prevState) => {
        let oneSelected = false;
        return prevState?.map((it) => {
          if (it && !oneSelected) {
            oneSelected = true;
            return true;
          } else {
            return false;
          }
        });
      });
    } else {
      setSelectedBurnIndexes((prevState) => {
        let oneSelected = false;
        return prevState?.map((it) => {
          if (it && !oneSelected) {
            oneSelected = true;
            return true;
          } else {
            return false;
          }
        });
      });
    }
    setMultiSelect(checked);
  };

  const selectionIcon = everySelected ? (
    <CheckBoxRoundedIcon />
  ) : someSelected ? (
    <IndeterminateCheckBoxRoundedIcon />
  ) : (
    <CheckBoxOutlineBlankRoundedIcon />
  );

  return (
    <Stack sx={props.sx}>
      <Stack
        direction={width === SizeClass.Compact ? "column" : "row"}
        useFlexGap
        gap={1}
        sx={{ flexGrow: 1, minWidth: 0, minHeight: 0 }}
      >
        {burns != null && (
          <Stack direction={width === SizeClass.Compact ? "row" : "column"}>
            <Toolbar
              disableGutters
              variant={
                windowWidthComparer.gte(SizeClass.Expanded)
                  ? "dense"
                  : "regular"
              }
              sx={{
                pl: 1.4,
              }}
            >
              {burns.length > 0 && (
                <>
                  <Button
                    color={"inherit"}
                    onClick={handleSelectionToggle}
                    startIcon={selectionIcon}
                    disabled={!multiSelect}
                    sx={{ opacity: multiSelect ? 1 : 0 }}
                  />
                  <Tooltip title={t("Compare Burns")}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={multiSelect}
                          onChange={handleMultiSelectChange}
                          size="small"
                        />
                      }
                      label={t("Compare")}
                      labelPlacement={"start"}
                      sx={{ ml: "auto", mr: 0.5 }}
                    />
                  </Tooltip>
                </>
              )}
            </Toolbar>
            <BurnList
              burns={burns}
              multiSelect={multiSelect}
              selected={selectedBurnIndexes}
              hovered={hoveredBurn}
              layout={props.layout}
              onSelected={handleBurnsSelected}
              onHovered={debouncedHandleBurnHovered}
              sx={{ overflowY: "auto" }}
              direction={width === SizeClass.Compact ? "row" : "column"}
            />
          </Stack>
        )}
        <Stack direction={"column"} sx={{ flex: 1, minWidth: 0, minHeight: 0 }}>
          <Toolbar
            variant={
              windowWidthComparer.gte(SizeClass.Expanded) ? "dense" : "regular"
            }
            disableGutters
          >
            {(burns?.length ?? 0) > 0 && noneSelected && hoveredBurn && (
              <Typography color={"text.secondary"}>
                Klikk for å velge en fyring
              </Typography>
            )}
            {lastSelectedBurn != null && (
              <BurnInfoSingle
                burn={lastSelectedBurn}
                layout={windowWidthComparer.sizeClass}
              />
            )}
            {lastSelectedBurn == null &&
              selectedBurns != null &&
              selectedBurns.length > 0 && (
                <BurnInfoMultiple burns={selectedBurns} />
              )}
          </Toolbar>
          <Paper
            elevation={3}
            sx={{
              borderRadius: theme.shape.borderRadius,
              flex: 1,
              minWidth: 0,
              minHeight: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.burnTemperatureSeries === undefined && (
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  minHeight: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {props.burnTemperatureSeries?.length === 0 && (
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  minHeight: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{props.noDataText ?? t("No burns")}</Typography>
              </Box>
            )}
            {(props.burnTemperatureSeries?.length ?? 0) > 0 &&
              selectedBurnTemperatureSeries !== undefined &&
              selectedBurnTemperatureSeries.length === 0 && (
                <Box
                  sx={{
                    flexGrow: 1,
                    minWidth: 0,
                    minHeight: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>
                    {props.noDataText ?? t("No burns chosen")}
                  </Typography>
                </Box>
              )}
            {burnById !== undefined &&
              selectedBurnTemperatureSeries !== undefined &&
              selectedBurnTemperatureSeries.length > 0 && (
                <BurnNonSensitiveChart
                  burnTemperatureSeries={selectedBurnTemperatureSeries}
                  burnById={burnById}
                  hoveredBurn={hoveredBurn}
                  maxTemperatures={props.maxTemperatures}
                  chimneyFireThreshold={props.chimneyFireThreshold}
                />
              )}
          </Paper>
        </Stack>
      </Stack>
    </Stack>
  );
};
