import { FC } from "react";
import { Alert, Stack } from "@mui/material";
import { FetchError } from "@airmont/shared/ts/utils/fetch";
import { FetchErrorAlert } from "@airmont/shared/ts/utils/fetch";
import { NoAccessToApplication } from "./NoAccessToApplication";

export interface BootErrorProps {
  error: Error;
}

export const BootError: FC<BootErrorProps> = (props) => {
  let alertError = (
    <Alert severity={"error"}>
      {props.error.name}: {props.error.message}
    </Alert>
  );

  if (
    props.error instanceof FetchError &&
    props.error.response?.status === 403
  ) {
    alertError = <NoAccessToApplication error={props.error} />;
  } else if (props.error instanceof FetchError) {
    alertError = <FetchErrorAlert error={props.error} />;
  }

  return (
    <Stack direction={"row"} gap={1}>
      {alertError}
    </Stack>
  );
};
