import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { useDrawingArea, useYScale } from "@mui/x-charts";
import { ScaleLinear } from "d3-scale";

const StyledPath = styled("path")(({ theme }) => ({
  fill: "none",
  stroke: theme.palette.text.primary,
  shapeRendering: "crispEdges",
  strokeWidth: 1,
  pointerEvents: "none",
  strokeDasharray: "5 2",
}));

const StyledText = styled("text")(({ theme }) => ({
  stroke: "none",
  fill: theme.palette.text.primary,
  shapeRendering: "crispEdges",
}));

export interface LeftRightSideValueHighlightProps {
  svgRef: React.RefObject<SVGSVGElement>;
  leftAxisScaleId: string;
  rightAxisScaleId: string;
}
export const LeftRightSideValueHighlight: FC<
  LeftRightSideValueHighlightProps
> = (props) => {
  const { svgRef } = props;

  // Get the drawing area bounding box
  const { left, top, width, height } = useDrawingArea();

  // Get the two scale
  const leftAxisScale = useYScale(props.leftAxisScaleId) as ScaleLinear<
    any,
    any
  >;
  const rightAxisScale = useYScale(props.rightAxisScaleId) as ScaleLinear<
    any,
    any
  >;

  const [mouseY, setMouseY] = React.useState<null | number>(null);

  React.useEffect(() => {
    const element = svgRef.current;
    if (element === null) {
      return () => {
        // nothing
      };
    }

    const handleMouseOut = () => {
      setMouseY(null);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const x = event.offsetX;
      const y = event.offsetY;
      if (x < left || x > left + width) {
        setMouseY(null);
        return;
      }
      if (y < top - 10 || y > top + height + 10) {
        // Allows some margin if slightly on top/bottom of the drawing area
        setMouseY(null);
        return;
      }
      setMouseY(Math.max(Math.min(top + height, y), top)); // clamp to the drawing area
    };

    element.addEventListener("mouseout", handleMouseOut);
    element.addEventListener("mousemove", handleMouseMove);
    return () => {
      element.removeEventListener("mouseout", handleMouseOut);
      element.removeEventListener("mousemove", handleMouseMove);
    };
  }, [height, left, top, width, svgRef]);

  if (mouseY === null) {
    return null;
  }
  return (
    <React.Fragment>
      <StyledPath d={`M ${left} ${mouseY} l ${width} 0`} />
      <StyledText
        x={left + 5}
        y={mouseY}
        textAnchor="start"
        dominantBaseline="text-after-edge"
      >
        {leftAxisScale.invert(mouseY).toFixed(0)}
      </StyledText>
      <StyledText
        x={left + width - 5}
        y={mouseY}
        textAnchor="end"
        dominantBaseline="text-after-edge"
      >
        {rightAxisScale.invert(mouseY).toFixed(0)}
      </StyledText>
    </React.Fragment>
  );
};
