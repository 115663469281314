import * as React from "react";
import { FC } from "react";
import { MenuItem, Select } from "@mui/material";
import { useFireflySharedTsDomainTranslation } from "../i18n";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { BatteryLevelEnum } from "./BatteryLevelEnum";

export interface BatteryLevelSelectProps {
  noneOption?: boolean;
  label?: React.ReactNode;
  displayEmpty?: boolean;
  selected: BatteryLevelEnum | null | undefined;
  onChange: (value: BatteryLevelEnum | null) => void;
}
export const BatteryLevelSelect: FC<BatteryLevelSelectProps> = (props) => {
  const { t } = useFireflySharedTsDomainTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    return props.onChange(
      BatteryLevelEnum[event.target.value as keyof typeof BatteryLevelEnum]
    );
  };

  return (
    <Select
      label={props.label}
      value={props.selected ?? ""}
      displayEmpty={props.displayEmpty}
      onChange={handleChange}
    >
      {props.noneOption && (
        <MenuItem value="">
          <em>{t("Not set")}</em>
        </MenuItem>
      )}
      <MenuItem value={BatteryLevelEnum.Excellent}>
        {t(`BatteryLevel.${BatteryLevelEnum.Excellent}`)}
      </MenuItem>
      <MenuItem value={BatteryLevelEnum.Good}>
        {t(`BatteryLevel.${BatteryLevelEnum.Good}`)}
      </MenuItem>
      <MenuItem value={BatteryLevelEnum.Poor}>
        {t(`BatteryLevel.${BatteryLevelEnum.Poor}`)}
      </MenuItem>
      <MenuItem value={BatteryLevelEnum.Critical}>
        {t(`BatteryLevel.${BatteryLevelEnum.Critical}`)}
      </MenuItem>
      <MenuItem value={BatteryLevelEnum.Unknown}>
        {t(`BatteryLevel.${BatteryLevelEnum.Unknown}`)}
      </MenuItem>
    </Select>
  );
};
