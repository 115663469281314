import { SettingConfig } from "./SettingConfig";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { DateTime } from "luxon";

export const DateTimeSetting: SettingConfig<DateTime> = {
  type: "dateTime",
  serialize: (value: DateTime): string => {
    return (
      value.toISO() ??
      _throw(new IllegalStateError("Invalid DateTime: " + value))
    );
  },

  deserialize: (value: string): DateTime => {
    return DateTime.fromISO(value);
  },
};
