import { APIProvider } from "@vis.gl/react-google-maps";
import React, { FC } from "react";
import { useLanguage } from "shared-ts-language";

const googleMapsLibraries: Array<string> = ["visualization"];

const languageMap: Record<string, string> = {
  "nb-NO": "no",
  "nn-NO": "no",
  "en-US": "en",
};

export interface GoogleMapApiProviderProps {
  children: React.ReactNode;
}

export const GoogleMapApiProvider: FC<GoogleMapApiProviderProps> = (props) => {
  const { language } = useLanguage();
  const mappedLanguage = languageMap[language];
  return (
    <APIProvider
      apiKey={"AIzaSyAxUk29muWcEh-gcoHnT2LKWdLEi2m6hZ8"}
      libraries={googleMapsLibraries}
      language={mappedLanguage}
    >
      {props.children}
    </APIProvider>
  );
};
