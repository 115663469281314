import { Property, PropertyProps } from "shared-ts-property";
import { useTranslation } from "react-i18next";
import { FC } from "react";

export interface AnonymizeUsageDataPropertyProps
  extends Omit<
    PropertyProps<boolean>,
    "name" | "value" | "onChange" | "label" | "type"
  > {
  name: string;
  value: boolean | null | undefined;
}

export const AnonymizeUsageDataProperty: FC<AnonymizeUsageDataPropertyProps> = (
  props
) => {
  const { t } = useTranslation("shared-ts-posthog");
  return (
    <Property
      {...props}
      label={t("Anonymize my usage data")}
      type={"boolean"}
      mode={props.mode ?? "edit"}
    />
  );
};
