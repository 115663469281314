import { BurnId } from "../../Burn";
import { BurnTemperatureSeries } from "../../BurnTemperatureSeries";
import { FC, useMemo } from "react";
import { ApexChart } from "@airmont/firefly/shared/ts/charts";
import { ApexOptions } from "apexcharts";
import { useXAxisLabelsFormatter } from "./useXAxisLabelsFormatter";
import { useXAxisTooltipFormatter } from "./useXAxisTooltipFormatter";
import { useApexSeries } from "./useApexSeries";
import { useYAxisLabelsFormatter } from "./useYAxisLabelsFormatter";
import { SxProps } from "@mui/system";
import { BurnSensitiveUi } from "../../BurnSensitiveUi";

export interface BurnSensitiveChartProps {
  burnTemperatureSeries: Array<BurnTemperatureSeries>;
  burnById: Record<BurnId, BurnSensitiveUi>;
  forceSameStartTime?: boolean;
  sx?: SxProps;
}
export const BurnSensitiveChart: FC<BurnSensitiveChartProps> = (props) => {
  const series = useApexSeries(props.burnTemperatureSeries, props.burnById);
  const max = undefined; //60 * 60 * maxHours * 1000

  const xAxisType = "numeric";
  const xAxisTooltipFormatter = useXAxisTooltipFormatter(xAxisType);
  const xAxisLabelsFormatter = useXAxisLabelsFormatter(xAxisType);
  const yAxisLabelsFormatter = useYAxisLabelsFormatter();

  const options: ApexOptions = useMemo(() => {
    return {
      chart: {
        type: "line",
      },
      noData: {
        text: "No burns",
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: xAxisType,
        min: 0,
        max: max,
        labels: {
          formatter: xAxisLabelsFormatter,
        },
        tooltip: {
          formatter: xAxisTooltipFormatter,
        },
      },
      yaxis: {
        labels: {
          formatter: yAxisLabelsFormatter,
        },
      },
    };
  }, [xAxisType, xAxisLabelsFormatter, xAxisTooltipFormatter]);

  return <ApexChart series={series} options={options} sx={props.sx} />;
};
