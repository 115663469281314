import { Visit } from "./Visit";
import { FC } from "react";
import {
  PropertiesCard,
} from "@airmont/shared/ts/ui/properties-card";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { Typography } from "@mui/material";
import { useVisitSettings } from "./VisitSettingsProvider";
import {Property} from "shared-ts-property";

export interface VisitCardProps {
  event: Visit;
}
export const VisitCard: FC<VisitCardProps> = (props) => {
  const { event } = props;
  const componentSize = useComponentSizeFromDense();
  const visitSettings = useVisitSettings();

  return (
    <PropertiesCard
      header={{ title: "Visit" }}
      childPropertyProps={{ size: componentSize }}
    >
      <Property name={"start"} label={"Start"} value={event.start} />
      <Property name={"end"} label={"End"} value={event.end} />
      <Property name={"location"} label={"Location"} value={event.location} />
      {event.participants?.map((participantId, index) => {
        const participant = visitSettings.participantById[participantId];
        return (
          <Property
            name={`participant-${participant.id}`}
            label={`Participant #${index + 1}`}
            value={participant.name}
          />
        );
      })}
      <Typography>Tasks:</Typography>
      {event.tasks.map((task) => {
        return (
          <PropertiesCard>
            <Property name={"type"} label={"Type"} value={task.type} />
            <Property name={"start"} label={"Start"} value={task.start} />
            <Property name={"end"} label={"End"} value={task.end} />
            {task.participants?.map((participantId, index) => {
              const participant = visitSettings.participantById[participantId];
              return (
                <Property
                  name={`participant-${participant.id}`}
                  label={`Participant#${index + 1}`}
                  value={participant.name}
                />
              );
            })}
          </PropertiesCard>
        );
      })}
    </PropertiesCard>
  );
};
