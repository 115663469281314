import { FC, ReactNode } from "react";
import { usePaperElevation } from "./usePaperElevation";
import { PaperElevationContext } from "./PaperElevationContext";

export interface IncreasePaperElevationProps {
  count?: number;
  children: ReactNode;
}

export const IncreasePaperElevation: FC<IncreasePaperElevationProps> = (
  props
) => {
  const elevation = usePaperElevation();
  const newElevation = elevation + (props.count ? props.count : 1);

  return (
    <PaperElevationContext.Provider value={newElevation}>
      {props.children}
    </PaperElevationContext.Provider>
  );
};
