import React, { FC } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { ErrorResponseAlert } from "./ErrorResponseAlert";
import { ErrorAlert } from "../ErrorAlert";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { AirmontLogoImg } from "shared-ts-ui-airmont";

export const ErrorPage: FC = () => {
  const error = useRouteError();
  const appInfo = useAppInfo();

  return (
    <NavigationLessAppPage appName={appInfo.name} appIcon={<AirmontLogoImg />}>
      <>
        {isRouteErrorResponse(error) && (
          <ErrorResponseAlert errorResponse={error} />
        )}
        {error instanceof Error && <ErrorAlert error={error} />}
      </>
    </NavigationLessAppPage>
  );
};
