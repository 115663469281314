import { FC, ReactNode } from "react";
import { FlueId } from "../flue/FlueId";
import { useSweepingDao } from "./useSweepingDao";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { Sweeping, SweepingImpl } from "./SweepingDto";

export interface SweepingLoaderProps {
  flueId: FlueId;
  children: (result: UseQueryResult<Sweeping[], Error>) => ReactNode;
}

export const SweepingLoader: FC<SweepingLoaderProps> = (props) => {
  const sweepingDao = useSweepingDao();

  const result = useQuery({
    queryKey: ["SweepingDao.getListByFlue", props.flueId],
    queryFn: async () => {
      const dtos = await sweepingDao.getListByFlue(props.flueId);
      return dtos.map((dto) => new SweepingImpl(dto));
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  return props.children(result);
};
