import { ContactDto } from "@airmont/firefly/shared/ts/domain";

export const getContactMocks = () : Array<ContactDto> => {

  return [
    {
      id: "101",
      name: "Jørund Vier Skriubakken",
      email: "jvs@airmont.no",
      countryCode: "0047",
      mobile: "11111111",
      phone: "11111111"
    },
    {
      id: "102",
      name: "Espen Sture Andersen",
      email: "esa@airmont.no",
      countryCode: "0047",
      mobile: "00000000",
      phone: "00000000"
    }
  ]
}
