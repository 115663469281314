import { DateTime } from "luxon";

export interface BurnQualityCountDataPoint {
  time: DateTime<true>;
  excellent: number;
  good: number;
  bad: number;
  unknown: number;
  avgAssessedIgnitions: number;
  assessedIgnitions: number;
  goodIgnitions: number;
  goodInPercentRaw: number | null;
  goodInPercent: number | null;
}

export const burnQualityCountDataPointWithNoAssessedIgnitions = (
  time: DateTime<true>
): BurnQualityCountDataPoint => {
  return {
    time: time,
    excellent: 0,
    good: 0,
    bad: 0,
    unknown: 0,
    avgAssessedIgnitions: 0,
    assessedIgnitions: 0,
    goodIgnitions: 0,
    goodInPercentRaw: null,
    goodInPercent: null,
  } as BurnQualityCountDataPoint;
};
