import { SensorId } from "@airmont/firefly/shared/ts/domain";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { ChimneySensorStatusDto } from "./ChimneySensorStatus";
import { useSensorDao } from "./useSensorDao";

export const useGetChimneySensorStatusById = (
  id: SensorId | undefined,
  queryOptions?: UseQueryOptions<unknown, unknown, ChimneySensorStatusDto>
): UseQueryResult<ChimneySensorStatusDto, unknown> => {
  const sensorDao = useSensorDao();
  return useQuery({
    queryKey: ["sensorDao.getSensorById/", id] as QueryKey,
    queryFn: () => (id != null ? sensorDao.getById(id) : undefined),
    ...queryOptions,
  });
};
