import { Feature, Point } from "geojson";
import { FlueStatusDto } from "./FlueStatus";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export interface FlueStatusGeoFeature
  extends Feature<Point, { flues: Array<FlueStatusDto> }> {
  id: string;
}

export const flueStatusToGeoFeature = (
  flue: FlueStatusDto
): FlueStatusGeoFeature => {
  if (flue.geoLocation == null) {
    throw new IllegalArgumentError(
      "geoLocation cannot be null for flue: " + flue.id
    );
  }

  return {
    type: "Feature",
    id: flue.id,
    geometry: {
      type: "Point",
      coordinates: [flue.geoLocation?.lng, flue.geoLocation?.lat],
    },
    properties: {
      flues: [flue],
    },
  };
};

export const groupFeaturesOnSameLocation = (
  features: Array<FlueStatusGeoFeature>
): Array<FlueStatusGeoFeature> => {
  const featuresByLocation: Record<string, FlueStatusGeoFeature> = {};
  const featuresGroupedOnLocation: Array<FlueStatusGeoFeature> = [];
  features.forEach((feature) => {
    const existing =
      featuresByLocation[JSON.stringify(feature.geometry.coordinates)];
    if (existing != null) {
      existing.properties.flues.push(...feature.properties.flues);
      existing.id = existing.id + "|" + feature.id;
    } else {
      featuresByLocation[JSON.stringify(feature.geometry.coordinates)] =
        feature;
      featuresGroupedOnLocation.push(feature);
    }
  });

  return featuresGroupedOnLocation;
};
