import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { ChimneySensorSettingsHistoryDto } from "./ChimneySensorSettingsHistory";

export class ChimneySensorSettingsController {
  private readonly chimneySensorsSettings: Array<ChimneySensorSettingsHistoryDto>;

  private readonly getLatestBySensorId: RequestHandler;

  public readonly handlers: RequestHandler[];

  constructor(args: {
    chimneySensorsSettings: Array<ChimneySensorSettingsHistoryDto>;
  }) {
    this.chimneySensorsSettings = args.chimneySensorsSettings;
    this.getLatestBySensorId = this.provideGetLatestBySensorId();
    this.handlers = [this.getLatestBySensorId];
  }

  private provideGetLatestBySensorId = () => {
    return rest.get(
      "api/chimney-sensor/settings/get-latest-by-sensor-id/:sensorId",
      async (
        req: RestRequest,
        res: ResponseComposition,
        ctx: RestContext
      ): Promise<MockedResponse> => {
        const { sensorId } = req.params;
        const result = this.chimneySensorsSettings
          .filter((it) => it.chimneySensorId === sensorId)
          .sort((a, b) => a.timestamp.localeCompare(b.timestamp));

        if (result.length === 0) {
          return res(ctx.status(404));
        }
        return res(ctx.json(result[0]));
      }
    );
  };
}
