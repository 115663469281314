import type { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridColDef } from "@mui/x-data-grid-premium";
import * as React from "react";
import { GridApiCommunity } from "@mui/x-data-grid/models/api/gridApiCommunity";

export const streetAddressGridValueGetter = <
  R extends GridValidRowModel = GridValidRowModel,
  V = never,
  F = V,
  TValue = never
>(
  value: TValue,
  row: {
    streetAddress: {
      street: string;
      houseNumber?: number;
      houseLetter?: string;
      houseSection?: string;
    };
  },
  column: GridColDef<R, V, F>,
  apiRef: React.MutableRefObject<GridApiCommunity>
) => {
  let streetAddress = row.streetAddress.street;
  if (row.streetAddress.houseNumber != null) {
    streetAddress += ` ${row.streetAddress.houseNumber}`;
  }
  if (row.streetAddress.houseLetter != null) {
    streetAddress += row.streetAddress.houseLetter.toLowerCase();
  }
  if (
    row.streetAddress.houseSection != null &&
    row.streetAddress.houseSection !== "H0101"
  ) {
    streetAddress += " " + row.streetAddress.houseSection;
  }
  return streetAddress;
};
