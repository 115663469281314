import React, { cloneElement, FC, ReactElement, ReactNode } from "react";
import { Box, Container, Stack, SxProps, Typography } from "@mui/material";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { LanguageIconButtonMenu } from "shared-ts-language";
import { useWindowSize } from "usehooks-ts";

export interface GenericBootPageProps {
  appIcon?: ReactNode;
  appName: ReactNode;
  children: ReactNode;
  language?: string;
  onLanguageChange?: (lang: string) => void;
}

export const NavigationLessAppPage: FC<GenericBootPageProps> = (props) => {
  const { height } = useWindowSize();
  const widthComparer = useWindowWidthComparer();
  const childrenIsValidReactElement = React.isValidElement(props.children);
  return (
    <Container
      className={"NavigationLessAppPage"}
      maxWidth={
        widthComparer.gte(SizeClass.Expanded)
          ? "md"
          : widthComparer.gte(SizeClass.Medium)
          ? "md"
          : "xs"
      }
      component={"main"}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        className={"NavigationLessAppPage-height"}
        direction={"column"}
        useFlexGap
        gap={3}
        sx={{
          flexGrow: 1,
          minHeight: 0,
          mt: `${height * 0.1}px`,
          mb: `${height * 0.05}px`,
        }}
      >
        <Stack
          className={"NavigationLessAppPage-header"}
          direction={"column"}
          useFlexGap
          gap={1}
        >
          {props.appIcon != null && props.appIcon}
          {React.isValidElement(props.appName) && props.appName}
          {typeof props.appName === "string" && (
            <Typography
              align={"center"}
              variant={
                widthComparer.gte(SizeClass.Expanded)
                  ? "h1"
                  : widthComparer.gte(SizeClass.Medium)
                  ? "h2"
                  : "h3"
              }
            >
              {props.appName}
            </Typography>
          )}
        </Stack>
        <Stack
          className={"NavigationLessAppPage-content"}
          sx={{
            flexGrow: 1,
            minHeight: 0,
            pt: 1,
            overflowY: "auto",
          }}
        >
          {childrenIsValidReactElement &&
            cloneElement<{ sx?: SxProps }>(props.children as ReactElement, {
              sx: {
                flexGrow: 1,
                minHeight: 0,
              },
            })}
          {!childrenIsValidReactElement && props.children}
        </Stack>
      </Stack>
      {props.language != null && props.onLanguageChange != null && (
        <Box sx={{ position: "fixed", right: "10px", bottom: "10px" }}>
          <LanguageIconButtonMenu
            label={`Språk / Language`}
            value={props.language}
            onChange={props.onLanguageChange}
          />
        </Box>
      )}
    </Container>
  );
};
