import { FlueMetrics } from "../metrics/FlueMetrics";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { MonthWheel } from "@airmont/shared/ts/utils/luxon";
import { Sweeping } from "../../sweeping/SweepingDto";

export const useResolveSootIndexCumulativePredictedSeries = (args: {
  selectedYear: number;
  selectedMonth: number;
  nowYear: number;
  nowMonth: number;
  flueMetricsByMonth: Record<string, FlueMetrics>;
  predictedFlueMetricsByMonth: Record<string, FlueMetrics>;
  sootIndexCumulativeSeries: Array<number | null>;
  sweepings: Array<Sweeping> | undefined;
}): Array<number | null> => {
  const {
    selectedYear,
    nowYear,
    nowMonth,
    flueMetricsByMonth,
    predictedFlueMetricsByMonth,
  } = args;
  // TODO: remove flueMetricsByMonth
  const lastSootIndexIndex =
    args.sootIndexCumulativeSeries.findIndex((it) => it === null) - 1 ?? -1;

  const startSootIndex =
    lastSootIndexIndex > -1
      ? (args.sootIndexCumulativeSeries[lastSootIndexIndex] as number)
      : 0;

  return useMemo(() => {
    const untilTime = DateTime.local(nowYear).plus({
      year: 1,
    }) as DateTime<true>;
    let currSootIndex = startSootIndex;

    return new MonthWheel({
      startYear: selectedYear,
      startMonth: 1,
    }).runUntilTime(untilTime, (month, year) => {
      if (year !== nowYear || month <= nowMonth) {
        return null;
      }
      const flueMetrics = predictedFlueMetricsByMonth[`${year}-${month}`];
      currSootIndex += flueMetrics?.metrics.sootIndex ?? 0;

      return MathUtils.round(currSootIndex);
    });
  }, [
    startSootIndex,
    selectedYear,
    nowYear,
    nowMonth,
    predictedFlueMetricsByMonth,
  ]);
};
