import {ChimneyWasteTypeStringsEnum, SweepingDto} from "@airmont/firefly/shared/ts/domain";
import {FlueStatusDto} from "@airmont/firefly/chimney-insights/ts/domain";
import {DuenessStringsEnum} from "@airmont/shared/ts/types";

export const getSweepingMocks = (input: {
  flues: Array<FlueStatusDto>;
}): Array<SweepingDto> => {
  return [
    {
      id: "abc-123",
      flueId: input.flues[0].id,
      time: "2023-07-12T12:00:00+00:00",
      wasteAmount: {
        type: ChimneyWasteTypeStringsEnum.Soot,
        value: 1,
        unit: "Kg",
        flammablePct: 10,
        coveragePct: 90,
      },

      dueness: DuenessStringsEnum.Due,
      audit: {
        created: "2023-07-13T12:00:00+00:00",
        createdBy: "jvs",
      },
    },
  ];
};
