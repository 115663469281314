import { Feature, Point } from "geojson";

export class LatLng {
  static fromFeature(feature: Feature<Point>): google.maps.LatLng {
    return new google.maps.LatLng({
      lng: feature.geometry.coordinates[0],
      lat: feature.geometry.coordinates[1],
    });
  }
}
