import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { MunicipalityMetricsQuery } from "./MunicipalityMetricsQuery";
import { MunicipalityMetricsDto } from "./MunicipalityMetrics";

export class MunicipalityMetricsDao {
  private fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/municipality_metrics/",
    })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  query(
    query: MunicipalityMetricsQuery
  ): Promise<Array<MunicipalityMetricsDto>> {
    return this.fetchHelper.post("query", query.toDto()).fetchAsJson();
  }
}
