import { TimeSeriesOption } from "./TimeSeriesOption";
import { TimeSeriesChartType } from "./TimeSeriesChartType";
import { ApexOptions } from "apexcharts";
import { ArrayElement } from "@airmont/shared/ts/types";
import { useMemo } from "react";
import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";

export const resolveApexSeries = <PV,>(
  timeSeriesArray: Array<TimeSeries<PV>>,
  timeSeriesOptionsArray: Array<TimeSeriesOption>,
  defaultChartType: TimeSeriesChartType | undefined
): ApexOptions["series"] => {
  return timeSeriesArray.map((ts, index) => {
    const timeSeriesOptionIndex = Math.min(
      index,
      timeSeriesOptionsArray.length - 1
    );
    const timeSeriesOption = timeSeriesOptionsArray[timeSeriesOptionIndex];
    if (timeSeriesOption == null) {
      throw new Error(`No options given for timeSeries at index: ${index}`);
    }

    const series: ArrayElement<ApexAxisChartSeries> = {
      type: timeSeriesOption.type ?? defaultChartType,
      name: timeSeriesOption.displayName ?? ts.info.name,
      color: timeSeriesOption.color,
      data: ts.points.map((point) => {
        const x =
          timeSeriesOption.transformTime != null
            ? timeSeriesOption.transformTime(point.time)
            : point.time.toMillis();
        const y =
          timeSeriesOption.transformValue != null
            ? timeSeriesOption.transformValue(point.value)
            : point.value;

        return {
          x: x,
          y: y,
        };
      }),
    };
    return series;
  });
};

export const useResolveApexSeries = <PV,>(
  timeSeriesArray: Array<TimeSeries<PV>>,
  timeSeriesOptionsArray: Array<TimeSeriesOption>,
  defaultChartType: TimeSeriesChartType | undefined
): ApexOptions["series"] => {
  return useMemo(
    () =>
      resolveApexSeries(
        timeSeriesArray,
        timeSeriesOptionsArray,
        defaultChartType
      ),
    [timeSeriesArray, timeSeriesOptionsArray, defaultChartType]
  );
};
