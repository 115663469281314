import React, { FC, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexChartContainer } from "./ApexChartContainer";
import { ApexOptions } from "apexcharts";
import { useTheme } from "@mui/material";
import { merge } from "lodash";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { useDefaultApexOptions } from "./useDefaultApexOptions";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export type LayoutType = "Standard" | "Compact";

function resolveYaxis(
  layout: SizeClass,
  theme: Theme,
  yaxis: ApexYAxis | ApexYAxis[] | undefined
): ApexYAxis | ApexYAxis[] {
  if (Array.isArray(yaxis)) {
    return yaxis.map((it) => resolveYaxis(layout, theme, it) as ApexYAxis);
  } else {
    const sources: Array<ApexYAxis> = [
      {
        show: layout !== SizeClass.Compact,
        title: {
          style: {
            color: theme.palette.text.secondary,
          },
        },
        labels: {
          show: layout !== SizeClass.Compact,
          style: {
            colors: theme.palette.text.secondary,
          },
        },
        axisTicks: {
          show: false,
          color: theme.palette.text.secondary,
        },
        axisBorder: {
          show: false,
          color: theme.palette.text.secondary,
        },
      },
    ];
    if (yaxis) {
      sources.push(yaxis);
    }
    if (layout === "Compact") {
      sources.push({
        title: {
          text: undefined,
        },
      });
    }

    const result = merge({}, ...sources);
    return result;
  }
}

export interface ApexChartProps {
  series: ApexOptions["series"];
  options: Partial<ApexOptions>;
  layout?: SizeClass;
  sx?: SxProps;
}

export const ApexChart: FC<ApexChartProps> = (props) => {
  const theme = useTheme();
  const layout = props.layout ?? SizeClass.Expanded;
  const seriesCount = props.series?.length ?? 0;

  const defaultOptions = useDefaultApexOptions({
    theme: theme,
    seriesCount: seriesCount,
    apexOptions: props.options,
  });

  const yAxis: ApexYAxis | ApexYAxis[] = useMemo(() => {
    return resolveYaxis(layout, theme, props.options.yaxis);
  }, [layout, theme, props.options.yaxis]);

  const options: ApexOptions = useMemo(() => {
    return merge({}, defaultOptions, {
      yaxis: yAxis,
    });
  }, [defaultOptions, yAxis]);

  const sx = useMemo(() => {
    return merge(props.sx, { flexGrow: 1, minWidth: 0, minHeight: 0 });
  }, [props.sx]);

  return (
    <ApexChartContainer
      sx={sx}
      chart={(width, height) => (
        <ReactApexChart
          options={options}
          series={props.series}
          width={width}
          height={height}
        />
      )}
    />
  );
};
