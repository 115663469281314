import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import {
  DurationSeries,
  DurationSeriesImpl,
} from "@airmont/firefly/shared/ts/timeseries";
import { YAxisDefaultConfig } from "shared-ts-mui";

type ReturnType = Array<YAxisDefaultConfig>;

export const resolveYAxisConfig = (
  series: Array<DurationSeries<number | null>> | undefined,
  axisConfigs?: Array<YAxisDefaultConfig>
): ReturnType => {
  if (series === undefined || series.length === 0) {
    return [
      {
        max: 100,
        data: [],
      },
    ] as ReturnType;
  }

  const firstSeries = series.length > 0 ? series[0] : undefined;

  if (axisConfigs == null) {
    return [
      {
        max: (DurationSeriesImpl.getMaxY(series, 0) ?? 0) + 20,
      },
    ] as ReturnType;
  }

  return axisConfigs.map((it) => {
    const max =
      it.max != null && typeof it.max === "number"
        ? it.max
        : (DurationSeriesImpl.getMaxY<number | null>(series, 0) ?? 0) + 20;

    const valueFormatter:
      | ((value: number, context: AxisValueFormatterContext) => string)
      | undefined =
      it.valueFormatter != null
        ? it.valueFormatter
        : firstSeries !== undefined
        ? (value: number) => `${value} ${firstSeries.info.unit}`
        : undefined;

    return {
      ...it,
      max: max,
      valueFormatter: valueFormatter,
    } as YAxisDefaultConfig;
  });
};
