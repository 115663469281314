import { FC, ReactNode } from "react";
import { FlueId } from "../../FlueId";
import { FlueMetricsDto } from "../FlueMetrics";
import { useFlueMetricsDao } from "../dao/useFlueMetricsDao";
import { FlueMetricsTimeUnitEnum } from "../FlueMetricsTimeUnit";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { IllegalStateError, notUndef } from "@airmont/shared/ts/utils/core";
import { FlueMetricsQueryImpl } from "../dao/FlueMetricsQuery";

export interface SingleFlueMetricsLoaderProps {
  flueId: FlueId;
  type: FlueMetricsTimeUnitEnum;
  time?: DateTime | DateTimeISO;
  children: (flueMetrics: FlueMetricsDto | null, loading: boolean) => ReactNode;
}

export const SingleFlueMetricsLoader: FC<SingleFlueMetricsLoaderProps> = (
  props
) => {
  const flueMetricsDao = useFlueMetricsDao();
  const time = notUndef(props.time, (it) => {
    if (typeof it === "string") {
      return DateTime.fromISO(it);
    } else {
      return it;
    }
  });
  const query = new FlueMetricsQueryImpl({
    flueId: props.flueId,
    timeUnit: props.type,
    time: time,
  });
  const { data: flueMetrics, isLoading } = useQuery({
    queryKey: ["FlueMetricsDao.query", query],
    queryFn: () => flueMetricsDao.query(query),
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  if (flueMetrics == null) {
    return null;
  }
  if (flueMetrics.length > 1) {
    throw new IllegalStateError(
      `Unexpected number of flue metrics for query: \n${JSON.stringify(
        query
      )}\n\nExpected only one, but got: ${flueMetrics.length}`
    );
  }

  return props.children(flueMetrics[0] ?? null, isLoading);
};
