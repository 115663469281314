import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Trans, useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Container,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { humanReadableBuildVersion, useAppInfo } from "shared-ts-app-info";
import React from "react";
import { WhatsNewPane } from "./whatsNew/WhatsNewPane";
import { SizeClass, useWindowWidth } from "@airmont/shared/ts/ui/responsive";

export const AboutPage = () => {
  const width = useWindowWidth();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: t_shared_ts_release } = useTranslation("shared-ts-release");
  const { t: t_shared_ts_app_info } = useTranslation("shared-ts-app-info");
  const appInfo = useAppInfo();
  return (
    <AppPage
      $key={"AboutPage"}
      name={`${t("About")} ${t("Chimney Insights")}`}
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      mainPane={
        <Container
          maxWidth={
            width === SizeClass.Compact
              ? "xs"
              : width === SizeClass.Medium
              ? "sm"
              : "md"
          }
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Card sx={{ minHeight: "fit-content" }}>
            <CardContent>
              <Stack direction={"column"} gap={1} useFlexGap>
                <Typography>
                  {t_shared_ts_release("Release")}: {appInfo.release}
                </Typography>
                <Typography component={"p"} gutterBottom>
                  {t_shared_ts_app_info("Build Version")}
                  {": "}
                  {notUndef(appInfo.buildVersion, (it) =>
                    humanReadableBuildVersion(it)
                  ) ?? "?"}
                </Typography>
                <Typography component={"p"} gutterBottom>
                  {t("Made in Halden with love")}
                </Typography>
                <Typography component={"p"} gutterBottom>
                  Copyright &#169; 2019-2024 airMont AS️
                </Typography>
                <Typography component={"p"} gutterBottom>
                  <Trans
                    i18nKey={"Contact support ..."}
                    ns={"firefly-chimney-insights-ts-pages"}
                    components={{
                      Link: (
                        <Link
                          href={"mailto:support@airmont.no"}
                          target={"_blank"}
                        />
                      ),
                    }}
                  />
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ flexGrow: 1, minHeight: "fit-content" }}>
            <CardContent>
              <Typography variant={"h6"}>
                {t_shared_ts_release("Releases")}
              </Typography>
              <WhatsNewPane sx={{ mt: 2 }} />
            </CardContent>
          </Card>
        </Container>
      }
    />
  );
};
