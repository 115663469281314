import { RequestHandler, rest } from "msw";
import { CustomerEnvironmentDeprecated } from "@airmont/firefly/shared/ts/customer-environment";

export class SystemController {
  private readonly getVersion: RequestHandler;
  private readonly getEnvs: RequestHandler;
  public readonly handlers: RequestHandler[];

  constructor(mocks: {
    buildVersion: string;
    environments: Array<CustomerEnvironmentDeprecated>;
  }) {
    this.getVersion = rest.get("/api/system/version", async (req, res, ctx) => {
      return res(
        ctx.json({
          version: mocks.buildVersion,
        })
      );
    });
    this.getEnvs = rest.get("/api/system/envs", async (req, res, ctx) => {
      return res(ctx.json(mocks.environments));
      //return res(ctx.status(403));
    });
    this.handlers = [this.getVersion, this.getEnvs];
  }
}
