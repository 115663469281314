/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { ArrayUtils } from "./ArrayUtils";
import { IllegalArgumentError } from "./errors";
import { ObjectUtils } from "./ObjectUtils";

export class ObjectPath {
  readonly elements: Array<string>;
  readonly asString: string;

  constructor(elements: Array<string>) {
    this.elements = elements;
    this.asString = elements.join(".");
  }

  removeFirstElement(): ObjectPath {
    return new ObjectPath(this.elements.slice(1));
  }

  updateObject(object: Record<string, any>, value: any): Record<string, any> {
    if (this.elements.length === 0) {
      return object;
    }

    if (this.elements.length === 1) {
      const element = this.elements[0];
      if (element.endsWith("]")) {
        const arrayBracketStart = element.indexOf("[");
        const arrayInstruction = element.substring(
          arrayBracketStart + 1,
          element.length - 1
        );
        const propertyName = element.substring(0, arrayBracketStart);
        const array = object[propertyName] as Array<unknown>;
        if (arrayInstruction.startsWith("+")) {
          // array[++]
          if (arrayInstruction.charAt(1) === "+") {
            const updatedArray = ArrayUtils.copyAndAppend(array, value);
            return ObjectUtils.update(object, {
              [propertyName]: updatedArray,
            });
          }
          // array[+$expression]
          else if (arrayInstruction.charAt(1) === "$") {
            const expression = arrayInstruction.substring(2);
            const leftAndRight = expression.split(" == ");
            const itemValuePath = ObjectPath.of(leftAndRight[0]);
            const expressionValue = leftAndRight[1].replace(/['"]+/g, "");
            const arrayIndex = array.findIndex((item) => {
              const itemValue = itemValuePath.getValue(
                item as Record<string, unknown>
              );
              return itemValue === expressionValue;
            });
            const updatedArray = ArrayUtils.copyAndAppendAtIndex(
              array,
              arrayIndex,
              value
            );
            return ObjectUtils.update(object, {
              [propertyName]: updatedArray,
            });
          }
          // array[+1]
          else {
            const arrayIndex = Number(arrayInstruction.substring(1));
            if (isNaN(arrayIndex)) {
              throw new IllegalArgumentError(
                "Expected number at array instruction after '[+':" + element
              );
            }

            const updatedArray = ArrayUtils.copyAndAppendAtIndex(
              array,
              arrayIndex,
              value
            );
            return ObjectUtils.update(object, {
              [propertyName]: updatedArray,
            });
          }
        }
        // array[1]
        else if (typeof Number(arrayInstruction) === "number") {
          const arrayIndex = Number(arrayInstruction);
          const updatedArray = ArrayUtils.updateElement(
            array,
            (item, index) => index === arrayIndex,
            value
          );
          return ObjectUtils.update(object, {
            [propertyName]: updatedArray,
          });
        } else {
          throw new IllegalArgumentError(
            "Unsupported array instruction: " + element
          );
        }
      }

      return ObjectUtils.update(object, {
        [this.elements[0]]: value,
      });
    }

    return ObjectUtils.update(object, {
      [this.elements[0]]: this.removeFirstElement().updateObject(
        object[this.elements[0]],
        value
      ),
    });
  }

  getValue(object: Record<string, any>): any {
    if (object === undefined) {
      return undefined;
    }
    if (object === null) {
      return null;
    }
    if (this.elements.length === 0) {
      return object;
    }

    if (this.elements.length === 1) {
      return object[this.elements[0]];
    }

    const valueOfFirstElement = object[this.elements[0]];
    return this.removeFirstElement().getValue(valueOfFirstElement as any);
  }

  getValueInArray(array: Array<Record<string, any>>): Array<any> {
    if (this.elements.length === 0) {
      return array;
    }

    if (this.elements.length === 1) {
      return array
        .map((object) => object[this.elements[0]])
        .filter((value) => value !== undefined);
    }

    const valueOfFirstElement = array
      .map((object) => object[this.elements[0]])
      .filter((value) => value !== undefined);
    return this.removeFirstElement().getValueInArray(valueOfFirstElement);
  }

  static of(s: string): ObjectPath {
    const elements: Array<string> = s.split(".");
    const cleanedElements = elements.filter((el) => el.length > 0);
    return new ObjectPath(cleanedElements);
  }

  static isObjectPath(s: string): boolean {
    return s.indexOf(".") > 0;
  }
}
