import { FC, ReactNode, useMemo } from "react";
import { DateTime, Interval } from "luxon";
import { Stack, SxProps } from "@mui/material";
import { useSxMerge } from "shared-ts-mui";
import { EventImpl } from "@airmont/shared-ts-event";
import { RenderEventParams } from "../RenderEventParams";
import { CalendarHeader } from "../CalendarHeader";
import { CalendarWeekBody } from "./CalendarWeekBody";

export interface CalendarWeekProps {
  variant: "events" | "timeslots";
  dateTimeNow: DateTime<true>;
  selectedDate: DateTime<true>;
  dates: Array<DateTime<true>>;
  events: Array<EventImpl>;
  hideDayHeader?: boolean;
  renderEvent: (params: RenderEventParams) => ReactNode;
  onNewEvent?: (interval: Interval<true> | DateTime<true> | null) => void;
  sx?: SxProps;
}

export const CalendarWeek: FC<CalendarWeekProps> = (props) => {
  const { variant, dateTimeNow, selectedDate, dates, renderEvent, onNewEvent } =
    props;
  const displayDayHeader = !props.hideDayHeader ?? true;
  const firstDateTime = dates[0];
  const lastDateTime = dates[dates.length - 1];
  const events = useMemo(() => {
    return props.events.filter(
      (event) => event.start >= firstDateTime && event.start < lastDateTime
    );
  }, [firstDateTime, lastDateTime, props.events]);

  const sx = useSxMerge(props.sx, {
    minHeight: 0,
    alignItems: "stretch",
  });

  return (
    <Stack className={"CalendarWeek"} direction={"column"} sx={sx}>
      {displayDayHeader && (
        <CalendarHeader
          variant={variant}
          dateTimeNow={dateTimeNow}
          selectedDate={selectedDate}
          dates={dates}
        />
      )}
      <CalendarWeekBody
        variant={variant}
        dateTimeNow={dateTimeNow}
        selectedDate={selectedDate}
        dates={dates}
        events={events}
        renderEvent={renderEvent}
        onNewEvent={onNewEvent}
      />
    </Stack>
  );
};
