import { DateTime, Interval } from "luxon";

export class ChimneyFireUtils {
  static isActiveChimneyFire(lastChimneyFireDateTime: DateTime): boolean {
    const now = DateTime.now();
    const duration = Interval.fromDateTimes(
      lastChimneyFireDateTime,
      now
    ).toDuration("days");
    return duration.days < 1;
  }
}
