export interface Link {
  href: string | null;
  label: string | null;
}

export function isLink(obj: object): obj is Link {
  return (
    typeof obj === "object" &&
    "href" in obj &&
    (typeof obj.href === "string" || obj.href === null) &&
    "label" in obj &&
    (typeof obj.label === "string" || obj.label === null)
  );
}
