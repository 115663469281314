export const RssiThresholds = {
  max: 0,
  excellent: {
    min: -65,
  },
  good: {
    min: -75,
  },
  medium: {
    min: -85,
  },
  weak: {
    min: -95,
  },
  noSignal: {
    min: -105,
  },
  min: -105,
};
export const RssiLevelDictionary = (value: number) => ({
  excellent: value >= -65,
  good: value >= -75 && value < -65,
  medium: value >= -85 && value < -75,
  weak: value >= -95 && value < -85,
  noSignal: value < -95,
});
