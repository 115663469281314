import { Task } from "./Task";
import { EventId } from "@airmont/shared-ts-event";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";

export class SweepTask extends Task {
  constructor(args: {
    id: EventId;
    parentId?: EventId;
    start: DateTimeISO | DateTime;
    end: DateTimeISO | DateTime;
    participants: Array<string>;
  }) {
    super({ ...args, type: "sweep" });
  }
}
