import { PeriodISO } from "@airmont/shared/ts/types";
import { QueryDto } from "./Query";
import { Query, QueryImpl } from "./Query";
import { Duration } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface TimeSeriesQueryDto extends QueryDto {
  sid?: string;
  name?: string;
  unit?: string;
  city?: string;
  decimalPlaces?: number;
  municipality?: string;
  sourceResolution?: PeriodISO;
  includeQuality?: boolean;
}

export interface TimeSeriesQuery extends Query {
  sid?: string;
  name?: string;
  unit?: string;
  city?: string;
  decimalPlaces?: number;
  municipality?: string;
  sourceResolution?: Duration;
  includeQuality?: boolean;

  toDto: () => TimeSeriesQueryDto;
}

export class TimeSeriesQueryImpl extends QueryImpl implements TimeSeriesQuery {
  readonly sid?: string;
  readonly name?: string;
  readonly unit?: string;
  readonly city?: string;
  readonly decimalPlaces?: number;
  readonly municipality?: string;
  readonly sourceResolution?: Duration;
  readonly includeQuality?: boolean;

  constructor(args: Omit<TimeSeriesQuery, "toQueryDto" | "toDto">) {
    super(args);
    this.sid = args.sid;
    this.name = args.name;
    this.unit = args.unit;
    this.city = args.city;
    this.decimalPlaces = args.decimalPlaces;
    this.municipality = args.municipality;
    this.sourceResolution = args.sourceResolution;
    this.includeQuality = args.includeQuality;
  }

  toDto(): TimeSeriesQueryDto {
    return {
      ...super.toQueryDto(),
      sid: this.sid,
      name: this.name,
      unit: this.unit,
      city: this.city,
      decimalPlaces: this.decimalPlaces,
      municipality: this.municipality,
      sourceResolution:
        this.sourceResolution !== undefined
          ? this.sourceResolution?.toISO() ??
            _throw(new IllegalStateError("resolution"))
          : undefined,
      includeQuality: this.includeQuality,
    };
  }
}
