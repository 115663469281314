import { useState } from "react";
import { Marker } from "@googlemaps/markerclusterer";
import { Feature, Point } from "geojson";

export interface FeatureMarkersProps<TFeature extends Feature<Point>> {
  features: Array<TFeature>;
  setMarkerRef: (marker: Marker | null, key: string) => void;
  render: (args: {
    feature: TFeature;
    openMarkerInfoWindow: boolean;
    onMarkerInfoWindowOpen: (open: boolean, feature: TFeature) => void;
    setMarkerRef: (marker: Marker | null, key: string) => void;
  }) => JSX.Element;
}

export const FeatureMarkers = <TFeature extends Feature<Point>>(
  props: FeatureMarkersProps<TFeature>
) => {
  const [openMarkerInfoWindow, setOpenMarkerInfoWidnow] =
    useState<TFeature | null>(null);
  const handleMarkerInfoWindowOpen = (open: boolean, feature: TFeature) => {
    setOpenMarkerInfoWidnow(open ? feature : null);
  };

  return (
    <>
      {props.features.map((flue) =>
        props.render({
          feature: flue,
          openMarkerInfoWindow: openMarkerInfoWindow === flue,
          onMarkerInfoWindowOpen: handleMarkerInfoWindowOpen,
          setMarkerRef: props.setMarkerRef,
        })
      )}
    </>
  );
};
