import {
  binaryExpr,
  containsExpr,
  endsWithExpr,
  Expr,
  fieldExpr,
  isAnyOfExpr,
  isEmptyExpr,
  isNotEmptyExpr,
  noopXpr,
  startsWithExpr,
  valExpr,
} from "shared/ts/utils/query";
import { GridFilterModel } from "@mui/x-data-grid/models";
import {
  GridFilterItem,
  GridLogicOperator,
} from "@mui/x-data-grid/models/gridFilterItem";
import { IllegalArgumentError, notUndef } from "@airmont/shared/ts/utils/core";

export class ExprHelper {
  static filterModelToExpressions(filter: GridFilterModel): Expr | undefined {
    if (filter.items.length === 0 || filter.logicOperator === undefined) {
      return undefined;
    }

    if (filter.items.length === 1) {
      const item = filter.items[0];
      return ExprHelper.noopToUndefined(
        ExprHelper.filterItemToExpressions(item)
      );
    }
    let expression: Expr | undefined = undefined;
    filter.items.forEach((item, index) => {
      if (filter.logicOperator != null) {
        if (expression === undefined) {
          expression = ExprHelper.filterItemToExpressions(item);
        } else {
          const right = ExprHelper.filterItemToExpressions(item);
          if (right.$type === "noop") {
            // keep current expression as is
          } else {
            expression = binaryExpr(
              ExprHelper.logicOperatorToExprKind(filter.logicOperator),
              expression,
              right
            );
          }
        }
      }
    });
    return ExprHelper.noopToUndefined(expression);
  }

  static filterItemToExpressions(
    filterItem: GridFilterItem | string | number | boolean
  ): Expr {
    const isValue =
      typeof filterItem === "string" ||
      typeof filterItem === "number" ||
      typeof filterItem === "boolean";
    if (isValue) {
      return valExpr(filterItem);
    }
    const $type = ExprHelper.operatorToExprKind(filterItem.operator);
    if ($type === "contains") {
      const arg = notUndef(filterItem.value, (it) =>
        this.filterItemToExpressions(it)
      );
      return arg !== undefined
        ? containsExpr(fieldExpr(filterItem.field), arg)
        : noopXpr();
    } else if ($type === "isAnyOf") {
      const values =
        filterItem.value != null && Array.isArray(filterItem.value)
          ? filterItem.value.map((it) => valExpr(it))
          : [];

      return values.length > 0
        ? isAnyOfExpr(fieldExpr(filterItem.field), values)
        : noopXpr();
    } else if ($type === "startsWith") {
      const arg = notUndef(filterItem.value, (it) =>
        this.filterItemToExpressions(it)
      );
      return arg !== undefined
        ? startsWithExpr(fieldExpr(filterItem.field), arg)
        : noopXpr();
    } else if ($type === "endsWith") {
      const arg = notUndef(filterItem.value, (it) =>
        this.filterItemToExpressions(it)
      );
      return arg !== undefined
        ? endsWithExpr(fieldExpr(filterItem.field), arg)
        : noopXpr();
    } else if ($type === "isEmpty") {
      return isEmptyExpr(fieldExpr(filterItem.field));
    } else if ($type === "isNotEmpty") {
      return isNotEmptyExpr(fieldExpr(filterItem.field));
    } else {
      const left = fieldExpr(filterItem.field);
      if (filterItem.value === undefined) {
        return noopXpr();
      }
      const rightExpr = this.filterItemToExpressions(filterItem.value);
      return binaryExpr($type, left, rightExpr);
    }
  }

  static logicOperatorToExprKind(operator: GridLogicOperator): "&&" | "||" {
    if (operator === GridLogicOperator.And) {
      return "&&";
    } else {
      return "||";
    }
  }

  static operatorToExprKind(
    operator: string
  ):
    | "=="
    | "contains"
    | "isAnyOf"
    | "startsWith"
    | "endsWith"
    | "isEmpty"
    | "isNotEmpty" {
    if (operator === "equals") {
      return "==";
    } else if (operator === "contains") {
      return "contains";
    } else if (operator === "startsWith") {
      return "startsWith";
    } else if (operator === "endsWith") {
      return "endsWith";
    } else if (operator === "isEmpty") {
      return "isEmpty";
    } else if (operator === "isNotEmpty") {
      return "isNotEmpty";
    } else if (operator === "isAnyOf") {
      return "isAnyOf";
    } else {
      throw new IllegalArgumentError("Unsupported operator: " + operator);
    }
  }

  private static noopToUndefined(expr: Expr | undefined): Expr | undefined {
    return expr !== undefined
      ? expr.$type === "noop"
        ? undefined
        : expr
      : undefined;
  }
}
