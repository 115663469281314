import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { MunicipalityDto } from "./Municipality";

export class MunicipalityController {
  private municipalities: Array<MunicipalityDto>;
  public readonly handlers: RequestHandler[];

  constructor(args: { municipalities: Array<MunicipalityDto> }) {
    this.municipalities = args.municipalities;
    this.handlers = [
      rest.get("/api/municipality/list", this.getMunicipalities),
    ];
  }

  getMunicipalities = async (
    request: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse> => {
    return res(ctx.json(this.municipalities));
  };
}
