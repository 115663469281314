import { ApexChartsFormatterOptions } from "@airmont/firefly/shared/ts/charts";
import { TimeSeriesChartOptions } from "./TimeSeriesChartOptions";
import { DateTime } from "luxon";

export const resolveXAxisTooltipFormatter = (
  value: number,
  opts: ApexChartsFormatterOptions,
  options: TimeSeriesChartOptions | undefined
): string => {
  if (options?.tooltip?.x?.formatter != null) {
    if ((opts as unknown) === value) {
      return options.tooltip?.x.formatter(value);
    }
    const x =
      opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x;
    return options.tooltip?.x.formatter(x);
  } else {
    const dateTime = DateTime.fromMillis(value);
    return dateTime.toLocaleString(DateTime.DATE_MED);
  }
};
