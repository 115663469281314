import { useContext } from "react";
import { LocaleContext } from "./LocaleContext";

export interface UseLocaleReturnType {
  locale: string;
  luxonLocale: string;
}

export const useLocale = (): UseLocaleReturnType => {
  const locale = useContext(LocaleContext);
  let luxonLocale = locale;
  if (luxonLocale === "nn-NO") {
    // nn-NO is not supported by Intl, fallback to
    luxonLocale = "nb-NO";
  }
  return { locale, luxonLocale };
};
