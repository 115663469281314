import { IMetaInfo } from "../IMetaInfo";
import { DurationDoubleImpl, isDurationDoubleDto } from "./DurationDouble";
import { DurationPoint, DurationPointDto } from "./DurationPoint";
import { Duration } from "luxon";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export interface DurationSeriesDto<T = unknown> {
  length: number;
  info: IMetaInfo;
  points: DurationPointDto<T>[];
}

export interface DurationSeries<PV = unknown> {
  info: IMetaInfo;
  points: Array<DurationPoint<PV>>;
  firstPoint: DurationPoint<PV> | undefined;
  lastPoint: DurationPoint<PV> | undefined;
  maxY: PV | undefined;
  minY: PV | undefined;
  comparePointValues: (a: PV, b: PV) => -1 | 0 | 1;
}

export class DurationSeriesImpl<PV> implements DurationSeries<PV> {
  readonly info: IMetaInfo;
  readonly points: Array<DurationPoint<PV>>;

  get firstPoint(): DurationPoint<PV> | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points[0];
  }
  get lastPoint(): DurationPoint<PV> | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points[this.points.length - 1];
  }

  get maxY(): PV | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points.reduce((a, b) => {
      const comparison = this.comparePointValues(a, b.value);
      return comparison === -1 ? b.value : comparison === 1 ? a : a;
    }, this.points[0].value);
  }

  get minY(): PV | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points.reduce((a, b) => {
      const comparison = this.comparePointValues(a, b.value);
      return comparison === 1 ? b.value : comparison === -1 ? a : a;
    }, this.points[0].value);
  }
  constructor(args: {
    info: IMetaInfo;
    readonly points: Array<DurationPoint<PV>>;
  }) {
    this.info = args.info;
    this.points = args.points;
  }

  comparePointValues(a: PV, b: PV): -1 | 0 | 1 {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
  static fromDto<PV>(dto: DurationSeriesDto<PV>): DurationSeries<PV> {
    return new DurationSeriesImpl<PV>({
      info: dto.info,
      points: dto.points.map((durationPointDto) => {
        if (isDurationDoubleDto(durationPointDto)) {
          return new DurationDoubleImpl({
            duration: Duration.fromMillis(durationPointDto.duration),
            value: durationPointDto.value,
          }) as unknown as DurationPoint<PV>;
        } else {
          throw new IllegalArgumentError(
            "durationPointDto not supported: " +
              JSON.stringify(durationPointDto)
          );
        }
      }),
    });
  }

  static getMaxY<PV>(series: Array<DurationSeries<PV>>, minimumMax: PV): PV {
    return series.reduce((a, b) => {
      const bMaxY = b.maxY ?? minimumMax;
      if (bMaxY > a) {
        return bMaxY;
      } else {
        return a;
      }
    }, minimumMax);
  }

  static getMinY<PV>(series: Array<DurationSeries<PV>>, minimumMin: PV): PV {
    return series.reduce((a, b) => {
      const bMinY = b.minY ?? minimumMin;
      if (bMinY < a) {
        return bMinY;
      } else {
        return a;
      }
    }, minimumMin);
  }
}
