import { FC, ReactNode } from "react";
import { SettingsStorage } from "./storage/SettingsStorage";
import { LocalSettingsStorage } from "./storage/LocalSettingsStorage";
import { UnsupportedError } from "@airmont/shared/ts/utils/core";
import { UserSettingsProviderAsync } from "./UserSettingsProviderAsync";
import { UserSettingsProviderNonAsync } from "./UserSettingsProviderNonAsync";
import { ReadOnlyUserSettings } from "./ReadOnlyUserSettings";

export interface UserSettingsProviderProps {
  storage?: SettingsStorage;
  children: ReactNode | ((settings: ReadOnlyUserSettings) => ReactNode);
}

export const UserSettingsProvider: FC<UserSettingsProviderProps> = (props) => {
  const storage = props.storage ?? LocalSettingsStorage;

  if (storage.type === "NonAsync") {
    return (
      <UserSettingsProviderNonAsync
        storage={props.storage}
        children={props.children}
      />
    );
  } else if (storage.type === "Async") {
    return (
      <UserSettingsProviderAsync
        storage={props.storage}
        children={props.children}
      />
    );
  } else {
    throw new UnsupportedError(
      "Unsupported SettingsStorage.type: " + storage.type
    );
  }
};
