import { QueryClient as TanStackQueryClient } from "@tanstack/react-query";
import { PageDto, QueryClient, QueryResult } from "shared/ts/utils/query";
import { FlueDao } from "./FlueDao";
import { FlueWithAbnormalBurnsDto } from "./FlueWithAbnormalBurns";
import { FluesWithAbnormalBurnsQueryDto } from "./FluesWithAbnormalBurnsQuery";

export class FluesWithAbnormalBurnsQueryClient
  implements QueryClient<FlueWithAbnormalBurnsDto>
{
  private queryClient: TanStackQueryClient;
  private dao: FlueDao;

  constructor(args: { queryClient: TanStackQueryClient; dao: FlueDao }) {
    this.queryClient = args.queryClient;
    this.dao = args.dao;
  }

  /*getById = (id: FlueId): Promise<BurnNonSensitiveDto> => {
    return this.queryClient.fetchQuery({
      queryKey: ["BurnDao.getById", id],
      queryFn: () => this.dao.getById(id),
    });
  };*/

  query = (
    query: FluesWithAbnormalBurnsQueryDto,
    page: PageDto
  ): Promise<QueryResult<FlueWithAbnormalBurnsDto>> => {
    const combinedQuery: FluesWithAbnormalBurnsQueryDto = {
      ...query,
      page: page,
    };
    return this.queryClient.fetchQuery({
      queryKey: [
        "FlueDao.queryFluesWithAbnormalBurns",
        this.dao.customerEnvironment,
        combinedQuery,
      ],
      queryFn: () => this.dao.queryFluesWithAbnormalBurns(combinedQuery),
      staleTime: 10 * 60 * 1000,
      gcTime: 15 * 60 * 1000,
    });
  };
}
