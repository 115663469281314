import { Action, ActionPreferences, isAction } from "./Action";
import { ReactElement } from "react";

export interface SelectableAction extends Action {
  selectable: true;
  selected: boolean;
}

export function isSelectableAction(obj: object): obj is SelectableAction {
  return isAction(obj) && "selectable" in obj && obj.selectable === true;
}

export const selectableAction = (args: {
  name: string;
  nameBreakable?: string;
  onExecute: (item: Action) => void;
  reference?: string;
  icon?: ReactElement;
  shortcut?: string;
  disabled?: boolean;
  preferences?: ActionPreferences;
  selected?: boolean;
}): Action => {
  return {
    type: "Action",
    selectable: true,
    selected: args.selected,
    name: args.name,
    nameBreakable: args.nameBreakable,
    onExecute: args.onExecute,
    reference: args.reference,
    icon: args.icon,
    shortcut: args.shortcut,
    disabled: args.disabled,
    preferences: args.preferences,
  } as SelectableAction;
};
