import React, { FC } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { Deck } from "@airmont/shared/ts/ui/deck";
import { useTranslation } from "react-i18next";
import { InfoWindow } from "@vis.gl/react-google-maps";
import { FlueStatusInfoWindowCard } from "./FlueStatusInfoWindowCard";
import {
  FlueStatusDto,
  FlueStatusGeoFeature,
} from "@airmont/firefly/chimney-insights/ts/domain";

export interface FlueInfoWindowProps {
  feature: FlueStatusGeoFeature;
  marker: google.maps.marker.AdvancedMarkerElement | null;
  onClose: () => void;
  onView?: (flue: FlueStatusDto) => void;
}

export const FlueInfoWindow: FC<FlueInfoWindowProps> = (props) => {
  const { feature, marker, onClose } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");

  return (
    <InfoWindow anchor={marker} onCloseClick={onClose} headerDisabled={true}>
      <Paper elevation={12}>
        <Deck
          layout={"compact"}
          previousButtonProps={{
            tooltip: t("Previous Flue"),
          }}
          nextButtonProps={{
            tooltip: t("Next Flue"),
          }}
        >
          {feature.properties.flues.map((flue, index) => {
            return (
              <Stack>
                {feature.properties.flues.length > 1 && (
                  <Typography
                    color={"text.secondary"}
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {t("{{number}} of {{total}} flues", {
                      number: index + 1,
                      total: feature.properties.flues.length,
                    })}
                  </Typography>
                )}
                <FlueStatusInfoWindowCard
                  key={index}
                  flueStatus={flue}
                  onView={props.onView}
                  onClose={onClose}
                />
              </Stack>
            );
          })}
        </Deck>
      </Paper>
    </InfoWindow>
  );
};
