import { SettingConfig } from "./SettingConfig";

export type Dictionary = any;

export const DictionarySetting: SettingConfig<Dictionary> = {
  type: "dictionary",
  serialize: (value: Dictionary): string => {
    return JSON.stringify(value);
  },

  deserialize: (value: string): Dictionary => {
    return JSON.parse(value);
  },
};
