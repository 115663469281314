import {
  BurnId,
  BurnTemperatureSeriesNonSensitive,
  BurnTemperatureSeriesNonSensitiveImpl,
} from "@airmont/firefly/shared/ts/domain";
import { FC, ReactNode, useMemo } from "react";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { useQuery } from "@tanstack/react-query";
import { useBurnDao } from "./useBurnDao";

export interface BurnTemperatureSeriesLoaderProps {
  burnIds: Array<BurnId> | undefined;
  children: (props: {
    temperatureSeries: Array<BurnTemperatureSeriesNonSensitive> | undefined;
    isTemperatureSeriesLoading: boolean;
  }) => ReactNode;
}
export const BurnTemperatureSeriesLoader: FC<
  BurnTemperatureSeriesLoaderProps
> = (props) => {
  const { burnIds } = props;
  const burnDao = useBurnDao();

  const { data, isLoading } = useQuery({
    queryKey: ["BurnDao.getTemperatureSeriesByBurn", burnIds],

    queryFn: () =>
      notUndef(burnIds, (burnIds) =>
        burnDao.getTemperatureSeriesByBurn(burnIds)
      ),

    enabled: burnIds !== undefined,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const burnTemperatureSeriesNonSensitive = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    const maxDataPointsLength = data.reduce(
      (a, b) => Math.max(a, b.dataPoints.length),
      0
    );
    return data.map((dto) =>
      BurnTemperatureSeriesNonSensitiveImpl.fromBurnTemperatureSeriesNonSensitiveDto(
        dto,
        { ensureLength: maxDataPointsLength }
      )
    );
  }, [data]);

  const result = useMemo(() => {
    return {
      temperatureSeries: burnTemperatureSeriesNonSensitive,
      isTemperatureSeriesLoading: isLoading,
    };
  }, [burnTemperatureSeriesNonSensitive, isLoading]);

  return props.children(result);
};
