import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { ChimneySensorDto } from "./ChimneySensor";

export class ChimneySensorController {
  private readonly chimneySensors: Array<ChimneySensorDto>;

  private readonly getById: RequestHandler;

  public readonly handlers: RequestHandler[];

  constructor(args: { chimneySensors: Array<ChimneySensorDto> }) {
    this.chimneySensors = args.chimneySensors;
    this.getById = this.provideGetById();
    this.handlers = [this.getById];
  }

  private provideGetById = () => {
    return rest.get(
      "api/chimney-sensor/get-by-id/:id",
      async (
        req: RestRequest,
        res: ResponseComposition,
        ctx: RestContext
      ): Promise<MockedResponse> => {
        const { id } = req.params;
        const result = this.chimneySensors.find((it) => it.id === id);
        return res(ctx.json(result));
      }
    );
  };
}
