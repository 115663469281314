/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { useCallback } from "react";
import { DateTime, Duration } from "luxon";

export const useXAxisLabelsFormatter = (
  xAxisType: "datetime" | "category" | "numeric"
): ((value: string, timestamp?: number, opts?: any) => string | string[]) => {
  return useCallback(
    (value: string, timestamp?: number, opts?: any): string => {
      if (xAxisType === "numeric") {
        const valueAsInt = parseInt(value);
        const duration = Duration.fromMillis(valueAsInt);
        return duration.rescale().toHuman({
          unitDisplay: "narrow",
          listStyle: "narrow",
        });
      } else {
        const valueAsInt = parseInt(value);
        const duration = DateTime.fromMillis(valueAsInt);

        return duration.toLocaleString(DateTime.DATE_SHORT);
      }
    },
    [xAxisType]
  );
};
