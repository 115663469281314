import React, { FC } from "react";
import { FormControl, FormHelperText, FormLabel, Tooltip } from "@mui/material";
import {
  BatteryLevelEnum,
  BatteryLevelSelect,
  useFireflySharedTsDomainTranslation,
} from "@airmont/firefly/shared/ts/domain";
import { FormControl_size } from "shared-ts-mui";

export interface BatteryLevelFilterControlProps {
  selected: BatteryLevelEnum | null | undefined;
  onChange: (value: BatteryLevelEnum | null) => void;
  size?: FormControl_size;
}

export const BatteryLevelFilterControl: FC<BatteryLevelFilterControlProps> = (
  props
) => {
  const { t } = useFireflySharedTsDomainTranslation();
  return (
    <FormControl size={props.size}>
      <Tooltip title={"Filtrer på batteri-nivå"}>
        <FormLabel>{t("Battery Level")}</FormLabel>
      </Tooltip>
      <BatteryLevelSelect
        noneOption
        displayEmpty
        selected={props.selected}
        onChange={props.onChange}
      />
      {props.selected == null && (
        <FormHelperText>Filtrer på sensorens batterinivå</FormHelperText>
      )}
      {props.selected != null && (
        <FormHelperText>
          {t(`BatteryLevel.description.${props.selected}`)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
