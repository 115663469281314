import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";

export interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const handleConfirm = () => {
    props.onConfirm();
  };
  const handleCancelClick = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      {props.title != null && <DialogTitle>{props.title}</DialogTitle>}
      {props.message != null && (
        <DialogContent dividers>{props.message}</DialogContent>
      )}
      <DialogActions>
        <Button variant={"outlined"} onClick={handleConfirm}>
          OK
        </Button>
        <Button onClick={handleCancelClick}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
