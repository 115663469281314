import { ValueOptions } from "@mui/x-data-grid/models/colDef/gridColDef";

export const countryCallingCodeValueOptions: Array<ValueOptions> = [
  {
    value: "0047",
    label: "Norge (0047)",
  },
  {
    value: "0046",
    label: "Sverige (0046)",
  },
  {
    value: "0045",
    label: "Danmark (0045)",
  },
  {
    value: "0044",
    label: "United Kingdom (0044)",
  },
  {
    value: "0354",
    label: "Finland (0354)",
  },
  {
    value: "0048",
    label: "Polen (0048)",
  },
  {
    value: "0049",
    label: "Tyskland (0049)",
  },
];
