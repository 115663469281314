import { FlueId } from "../chimney/flue/FlueId";
import { BurnMetricValuesDto } from "./BurnMetricValues";
import { WeatherValues } from "../weather/WeatherValues";
import { BurnAnalytics } from "./BurnAnalytics";
import { BurnId } from "./Burn";
import { Duration } from "luxon";
import { BurnOptions } from "./BurnOptions";

export interface BurnNonSensitiveDto {
  id: BurnId;
  flueId: FlueId;
  temperatureSeriesId: string;
  completed: boolean;
  year: number;
  month: number;
  durationInMillis: number;
  metrics: BurnMetricValuesDto;
  weather?: WeatherValues;
  analytics: BurnAnalytics;
}

export class BurnNonSensitive {
  readonly id: BurnId;
  readonly flueId: FlueId;
  readonly temperatureSeriesId: string;
  readonly completed: boolean;
  readonly duration: Duration;
  readonly metrics: BurnMetricValuesDto;
  readonly weather?: WeatherValues;
  readonly analytics: BurnAnalytics;
  readonly count?: number;
  readonly countOfDay?: number;

  constructor(dto: BurnNonSensitiveDto, options?: BurnOptions) {
    this.id = dto.id;
    this.flueId = dto.flueId;
    this.temperatureSeriesId = dto.temperatureSeriesId;
    this.completed = dto.completed;
    this.duration = Duration.fromMillis(dto.durationInMillis).shiftToAll();
    this.metrics = dto.metrics;
    this.weather = dto.weather;
    this.analytics = dto.analytics;
    this.count = options?.count;
    this.countOfDay = options?.countOfDay;
  }
}
