import { Expr } from "../../Expr";

export interface StartsWithXpr {
  $type: "startsWith";
  instance: Expr;
  arg: Expr;
}

export const startsWithExpr = (instance: Expr, arg: Expr): StartsWithXpr => ({
  $type: "startsWith",
  instance: instance,
  arg: arg,
});
