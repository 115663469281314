import { DateTime, Info } from "luxon";
import { FC } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { _throw, IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { useSxMerge } from "shared-ts-mui";
import { useCalendarSettings } from "../CalendarSettingsProvider";

export interface DayHeaderProps {
  weekday?: number;
  date?: DateTime<true>;
  isToday?: boolean;
  sx?: SxProps;
}
export const DayHeader: FC<DayHeaderProps> = (props) => {
  const settings = useCalendarSettings();
  const weekday =
    props.weekday ??
    props.date?.weekday ??
    _throw(
      new IllegalArgumentError("Either weekday or date must be specified")
    );
  const text =
    props.date != null
      ? props.date.toLocaleString({
          weekday: "long",
          day: "numeric",
        })
      : Info.weekdays()[weekday - 1];

  const sx = useSxMerge(props.sx, {
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    lineHeight: settings.headerHeight + "px",
  });
  return (
    <Box className={"DayHeader"} sx={sx}>
      <Typography
        variant={"caption"}
        align={"center"}
        sx={{
          flexGrow: 1,
          minWidth: 0,
          textTransform: "capitalize",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
