import { FC } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { FetchError } from "./FetchError";

export interface FetchErrorAlertProps {
  error: FetchError;
}

export const FetchErrorAlert: FC<FetchErrorAlertProps> = (props) => {
  let url = undefined;
  let method: "getting" | "posting" | "putting" | "deleting" | "requesting" =
    "requesting";
  if (typeof props.error.request === "string") {
    url = typeof props.error.request;
  } else {
    url = props.error.request?.url;
    if (props.error.request?.method === "GET") {
      method = "requesting";
    } else if (props.error.request?.method === "POST") {
      method = "requesting";
    } else if (props.error.request?.method === "PUT") {
      method = "requesting";
    } else if (props.error.request?.method === "DELETE") {
      method = "requesting";
    }
  }
  let explanation: string | undefined;

  if (props.error.response != null) {
    explanation = props.error.response.statusText;
  }

  return (
    <Alert severity={"error"}>
      <AlertTitle>
        Error {method}: {url}
      </AlertTitle>
      {explanation}
    </Alert>
  );
};
