import { FC, ReactNode, useMemo } from "react";
import { FlueMetrics } from "../FlueMetrics";
import { useFlueMetricsDao } from "../dao/useFlueMetricsDao";
import { useQuery } from "@tanstack/react-query";
import { FlueId } from "../../FlueId";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { DateTime } from "luxon";
import { MonthWheel, useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { FlueMetricsTimeUnitEnum } from "../FlueMetricsTimeUnit";

export interface FlueMonthMetricsByYearLoaderProps {
  flueId: FlueId;
  year?: Array<number>;
  createMissing?: {
    enabled: boolean;
    since: DateTime<true>;
  };
  children: (
    result: Record<string, FlueMetrics>,
    loading: boolean
  ) => ReactNode;
}

export const FlueMonthMetricsByYearLoader: FC<
  FlueMonthMetricsByYearLoaderProps
> = (props) => {
  const flueMetricsDao = useFlueMetricsDao();
  const now = useDateTimeNow();
  const { data, isLoading } = useQuery({
    queryKey: [
      "FlueMetricsDao.queryMonthMetricsByYear",
      props.flueId,
      props.year,
    ],
    queryFn: () =>
      flueMetricsDao.queryMonthMetricsByYear(props.flueId, props.year),
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const result = useMemo(() => {
    const metrics = data?.map((dto) => new FlueMetrics(dto));
    const result: Record<string, FlueMetrics> =
      metrics != null
        ? ArrayUtils.toObjectDictionary(
            metrics,
            (flueMetric) => `${flueMetric.time?.year}-${flueMetric.time?.month}`
          )
        : {};
    if (
      props.createMissing?.enabled === true &&
      props.createMissing.since != null
    ) {
      const untilTime = DateTime.local(
        now.year,
        now.month + 1
      ) as DateTime<true>;
      new MonthWheel({
        startYear: props.createMissing.since.year,
        startMonth: props.createMissing.since.month,
      }).runUntilTime(untilTime, (month, year) => {
        const key = `${year}-${month}`;
        const metric = result[key];
        if (metric == null) {
          const time = DateTime.local(year, month);
          result[key] = FlueMetrics.create({
            flueId: props.flueId,
            type: FlueMetricsTimeUnitEnum.Month,
            time: time.toISO() ?? undefined,
          });
        }
      });
    }

    return result;
  }, [
    data,
    props.createMissing?.enabled,
    props.createMissing?.since,
    props.flueId,
    now.year,
    now.month,
  ]);

  return props.children(result, isLoading);
};
