import { LayoutDirection } from "./LayoutDirection";
import { Layout } from "./Layout";

export const useResolveLayoutDirection = (props: {
  layout?: Layout;
  layoutDirection?: LayoutDirection;
}): LayoutDirection => {
  if (props.layoutDirection != null) {
    return props.layoutDirection;
  } else {
    return props.layout === "compact" || props.layout === "medium"
      ? "column"
      : "row";
  }
};
