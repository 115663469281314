import { MunicipalityDto } from "@airmont/firefly/shared/ts/domain";

export const getMunicipalityMocks = (): Array<MunicipalityDto> => {
  return [
    {
      id: "3001",
      name: "HALDEN",
    },
  ];
};
