import { BottomNavigationAction } from "@mui/material";
import React, { ReactNode } from "react";
import { ActionGroup, SelectableItem } from "@airmont/shared/ts/ui/action";
import { PopoverOrigin } from "@mui/material/Popover/Popover";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Menu } from "../shared/group/menu/Menu";

export interface NavigationBarMenuProps {
  item: ActionGroup;
  children?: ReactNode;
  onSelected?: (item: SelectableItem) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const NavigationBarMenu = (props: NavigationBarMenuProps) => {
  const { item } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BottomNavigationAction
        label={item.name}
        showLabel={true}
        value={item.reference}
        icon={item?.icon ?? <MenuRoundedIcon />}
        onClick={handleItemClick}
        sx={{ flexGrow: 0 }}
      />
      <Menu
        open={open}
        variant={"square"}
        layout={"compact"}
        layoutDirection={"column"}
        item={item}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        anchor={anchorEl}
        onClose={handleClose}
        onItemSelected={props.onSelected}
      />
    </>
  );
};
