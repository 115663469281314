import { ReactNode, useState } from "react";
import { Feature, GeoJsonProperties, Point } from "geojson";
import Supercluster, { ClusterProperties } from "supercluster";
import { useSupercluster } from "./useSupercluster";
import { FeaturesClusterMarker } from "./FeaturesClusterMarker";
import { ClusterPinRenderProps } from "./ClusterPinRenderProps";

const superclusterOptions: Supercluster.Options<
  GeoJsonProperties,
  ClusterProperties
> = {
  extent: 256,
  radius: 80,
  maxZoom: 16,
};

export interface FeatureMarkersProps<TFeature extends Feature<Point>> {
  features: Array<TFeature>;
  render: (args: {
    feature: TFeature;
    openMarkerInfoWindow: boolean;
    onMarkerInfoWindowOpen: (open: boolean, feature: TFeature) => void;
  }) => JSX.Element;
  renderClusterPin: (props: ClusterPinRenderProps<TFeature>) => ReactNode;
}

export const FeatureMarkers = <TFeature extends Feature<Point>>(
  props: FeatureMarkersProps<TFeature>
) => {
  const { clusters, getLeaves } = useSupercluster(
    props.features,
    superclusterOptions
  );
  const [openMarkerInfoWindow, setOpenMarkerInfoWidnow] =
    useState<TFeature | null>(null);
  const handleMarkerInfoWindowOpen = (open: boolean, feature: TFeature) => {
    setOpenMarkerInfoWidnow(open ? feature : null);
  };

  return (
    <>
      {clusters.map((feature) => {
        const [lng, lat] = feature.geometry.coordinates;

        const clusterProperties = feature.properties as ClusterProperties;
        const isCluster: boolean = clusterProperties.cluster;

        if (isCluster) {
          const leaves = getLeaves(
            clusterProperties.cluster_id
          ) as Array<TFeature>;

          return (
            <FeaturesClusterMarker
              clusterId={clusterProperties.cluster_id}
              features={leaves}
              position={{ lat, lng }}
              size={clusterProperties.point_count}
              sizeAsText={String(clusterProperties.point_count_abbreviated)}
              renderClusterPin={props.renderClusterPin}
            />
          );
        } else {
          return props.render({
            feature: feature as TFeature,
            openMarkerInfoWindow: openMarkerInfoWindow === feature,
            onMarkerInfoWindowOpen: handleMarkerInfoWindowOpen,
          });
        }
      })}
    </>
  );
};
