import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { LatLng } from "../LatLng";
import { Feature, Point } from "geojson";
import { FeatureMarkerPinRenderProps } from "./FeatureMarkerPinRenderProps";

export interface FeatureMarkerProps<TFeature extends Feature<Point>> {
  feature: TFeature;
  initialInfoWindowOpen?: boolean;
  onInfoWindowOpen?: (open: boolean, feature: TFeature) => void;
  renderPin?: (props: FeatureMarkerPinRenderProps<TFeature>) => ReactNode;
  renderInfoWindow?: (props: {
    feature: TFeature;
    marker: google.maps.marker.AdvancedMarkerElement | null;
    onClose: () => void;
  }) => ReactNode;
}

export const FeatureMarker = <TFeature extends Feature<Point>>(
  props: FeatureMarkerProps<TFeature>
) => {
  const { feature, initialInfoWindowOpen, onInfoWindowOpen } = props;

  const [infoWindowOpen, setInfoWindowOpen] = useState(initialInfoWindowOpen);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const position = LatLng.fromFeature(feature);

  useEffect(() => {
    setInfoWindowOpen(initialInfoWindowOpen);
  }, [initialInfoWindowOpen]);

  const handleMarkerClick = useCallback(() => {
    if (props.renderInfoWindow != null) {
      if (onInfoWindowOpen != null) {
        onInfoWindowOpen(!infoWindowOpen, feature);
      } else {
        setInfoWindowOpen((prevState) => !prevState);
      }
    }
  }, [feature, infoWindowOpen, onInfoWindowOpen, props.renderInfoWindow]);

  const handleInfoWindowClose = useCallback(() => {
    if (onInfoWindowOpen != null) {
      onInfoWindowOpen(false, feature);
    } else {
      setInfoWindowOpen(false);
    }
  }, [feature, onInfoWindowOpen]);

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={handleMarkerClick}
        position={position}
      >
        {props.renderPin?.({ feature: feature })}
      </AdvancedMarker>
      {infoWindowOpen &&
        props.renderInfoWindow != null &&
        props.renderInfoWindow({
          feature: feature,
          marker: marker,
          onClose: handleInfoWindowClose,
        })}
    </>
  );
};
