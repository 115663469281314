import { Visit } from "./Visit";
import { FC } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { useVisitSettings } from "./VisitSettingsProvider";

export interface VisitListItemProps {
  event: Visit;
  onClick?: (event: Visit) => void;
}
export const VisitListItem: FC<VisitListItemProps> = (props) => {
  const { event } = props;
  const visitSettings = useVisitSettings();
  const handleClick = () => {
    props.onClick?.(props.event);
  };

  return (
    <ListItem disableGutters>
      <ListItemButton
        onClick={handleClick}
        sx={{ gap: 0.5, position: "relative" }}
        disableGutters
      >
        <ListItemIcon sx={{ justifyContent: "center" }}>
          <HomeRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: "caption",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          primary={event.location}
          secondary={"1794"}
          secondaryTypographyProps={{
            variant: "caption",
            fontSize: "0.5rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        />
        <Stack
          className={"participants"}
          sx={{ position: "absolute", bottom: 0, right: 0, pr: 1, pb: 1 }}
        >
          {event.participants?.map((participantId) => {
            const participant = visitSettings.participantById[participantId];
            return (
              <CircleRoundedIcon
                key={participant.id}
                sx={{ color: participant.color, fontSize: "0.5rem" }}
              />
            );
          })}
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};
