import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import { SensorStatusGeoFeature } from "./SensorStatusGeoFeature";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { SensorFeatureUtils } from "./SensorFeatureUtils";
import {
  Map,
  MapController,
  MapLoadingProgress,
  useLatLngBounds,
} from "shared-ts-ui-react-google-maps";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import { SensorStatusClusterPin } from "./SensorStatusClusterPin";
import { SensorStatusPin } from "./SensorStatusPin";
import { SensorInfoWindow } from "./SensorInfoWindow";
import { SensorMapStatusLegend } from "./SensorMapStatusLegend";

export interface SensorStatusMapProps {
  pageUrl: string;
  municipality?: string;
  selected?: string;
  data: Array<ChimneySensorStatusDto>;
  width?: number;
  height?: number;
}

export const SensorStatusMap: FC<SensorStatusMapProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [features, setFeatures] = useState<Array<SensorStatusGeoFeature>>([]);
  useEffect(() => {
    const features = SensorFeatureUtils.groupSensorsOnSameLocation(
      SensorFeatureUtils.sensorsToFeatures(props.data)
    );
    setFeatures(features);
  }, [props.data]);

  const latLngBounds = useLatLngBounds(features);
  const defaultBounds = useMemo(
    () => notUndef(latLngBounds, (it) => it.toJSON()),
    [latLngBounds]
  );
  const defaultCenter = useMemo(
    () => notUndef(latLngBounds, (it) => it.getCenter().toJSON()),
    [latLngBounds]
  );

  const handleView = (sensor: ChimneySensorStatusDto) => {
    navigate(`${props.pageUrl}/view/${sensor.id}`);
  };

  const numberOfSensorsInMap = useMemo(
    () => features.reduce((a, b) => a + b.properties.sensors.length, 0),
    [features]
  );

  return defaultBounds !== undefined ? (
    <Map
      palette={theme.palette.mode}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
      defaultBounds={defaultBounds}
      backgroundColor={theme.palette.background.default}
      defaultCenter={defaultCenter}
    >
      <MapController
        features={features}
        renderFeaturePin={(props) => <SensorStatusPin {...props} />}
        renderInfoWindow={(props) => (
          <SensorInfoWindow {...props} onView={handleView} />
        )}
        renderClusterPin={(props) => (
          <SensorStatusClusterPin
            {...props}
            numberOfSensors={numberOfSensorsInMap}
          />
        )}
      ></MapController>
      <MapControl position={ControlPosition.TOP_LEFT}>
        <SensorMapStatusLegend />
      </MapControl>
    </Map>
  ) : (
    <MapLoadingProgress />
  );
};

export default React.memo(SensorStatusMap);
