import { FlueMetricsDto } from "../FlueMetrics";
import { FlueId } from "../../FlueId";
import { FlueMetricsTimeUnitEnum } from "../FlueMetricsTimeUnit";
import { DateTime } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { FlueMetricValues } from "../FlueMetricValues";

export interface FlueMetricSpecification {
  type: FlueMetricsTimeUnitEnum;
  time?: DateTimeISO;
  values: FlueMetricValues;
}
export interface FlueMetricsSpecification {
  metrics: Array<FlueMetricSpecification>;
}

export const createFlueMetricMocks = (args: {
  flues: Array<FlueId>;
  specifications: Array<FlueMetricsSpecification>;
}): Array<FlueMetricsDto> => {
  const metrics: Array<FlueMetricsDto> = [];
  args.flues.forEach((flueId, flueIndex) => {
    const createFlueMetricsDto = (
      specification: FlueMetricSpecification
    ): FlueMetricsDto => {
      return {
        flueId: flueId,
        timeUnit: specification.type,
        time:
          specification.time ??
          DateTime.local(2080, 1, 1).toISO() ??
          _throw(new IllegalStateError("time")),
        modified:
          DateTime.now().toISO() ?? _throw(new IllegalStateError("modified")),
        metrics: {
          burnCount: specification.values.burnCount ?? 0,
          burnHourCount: specification.values.burnHourCount ?? 0,
          chimneyFireCount: specification.values.chimneyFireCount ?? 0,
          temperatureMax: specification.values.chimneyFireCount ?? 0,
          sootIndex: specification.values.sootIndex ?? 0,
        },
      };
    };

    const specification = args.specifications[flueIndex];
    if (specification != null) {
      specification.metrics.forEach((metricSpecification) => {
        metrics.push(createFlueMetricsDto(metricSpecification));
      });
    } else {
      metrics.push({
        flueId: flueId,
        timeUnit: FlueMetricsTimeUnitEnum.Ever,
        time:
          DateTime.local(2080, 1, 1).toISO() ??
          _throw(new IllegalStateError("time")),
        modified:
          DateTime.now().toISO() ?? _throw(new IllegalStateError("modified")),
        metrics: {
          burnCount: 0,
          burnHourCount: 0,
          chimneyFireCount: 0,
          temperatureMax: 0,
          sootIndex: 0,
        },
      });
      metrics.push({
        flueId: flueId,
        timeUnit: FlueMetricsTimeUnitEnum.SinceSweep,
        time:
          DateTime.local(2080, 1, 1).toISO() ??
          _throw(new IllegalStateError("time")),
        modified:
          DateTime.now().toISO() ?? _throw(new IllegalStateError("modified")),
        metrics: {
          burnCount: 0,
          burnHourCount: 0,
          chimneyFireCount: 0,
          temperatureMax: 0,
          sootIndex: 0,
        },
      });
    }
  });
  return metrics;
};
