import React, { FC } from "react";
import { FluesWithAbnormalBurnsFilterFormValues } from "./FluesWithAbnormalBurnsFilterFormValues";
import { Formik } from "formik";
import { Button, Stack, Toolbar } from "@mui/material";
import { SxProps } from "@mui/system";
import { FilterGroup } from "./FilterGroup";
import { useFlueTypeOptions } from "./useFlueTypeOptions";
import {
  useComponentSizeFromDense,
  useDense,
} from "@airmont/shared/ts/ui/responsive";
import { FormikProperty, Property } from "shared-ts-property";
import { AddFilter } from "./AddFilter";
import { Action, selectableAction } from "@airmont/shared/ts/ui/action";
import { useTranslation } from "react-i18next";

export interface FluesWithAbnormalBurnsFilterPanelProps {
  filterValues: FluesWithAbnormalBurnsFilterFormValues;
  defaultFilterValues: FluesWithAbnormalBurnsFilterFormValues;
  onChange: (values: FluesWithAbnormalBurnsFilterFormValues) => void;
  sx?: SxProps;
}

export const FluesWithAbnormalBurnsFilterPanel: FC<
  FluesWithAbnormalBurnsFilterPanelProps
> = (props) => {
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const { t } = useTranslation("firefly-shared-ts-domain");
  const flueTypeOptions = useFlueTypeOptions();
  const handleSubmit = (values: FluesWithAbnormalBurnsFilterFormValues) => {
    props.onChange(values);
  };

  return (
    <Formik
      initialValues={props.filterValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => {
        const fluesFilterActions: Array<Action> = [
          selectableAction({
            name: "Vegadresse",
            onExecute: (action) =>
              formik.setFieldValue(
                "street",
                action.selected ? undefined : props.defaultFilterValues.street
              ),
            selected: formik.values.street !== undefined,
          }),
          selectableAction({
            name: "Røykløpstype",
            onExecute: (action) =>
              formik.setFieldValue(
                "flueTypes",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.flueTypes
              ),
            selected: formik.values.flueTypes !== undefined,
          }),
        ];

        const burnsFilterActions: Array<Action> = [
          selectableAction({
            name: t("Unfinished"),
            onExecute: (action) =>
              formik.setFieldValue(
                "unfinished",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.unfinished
              ),
            selected: formik.values.unfinished !== undefined,
          }),
          selectableAction({
            name: "Min. fyringsvarighet",
            onExecute: (action) =>
              formik.setFieldValue(
                "minDurationInHours",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.minDurationInHours
              ),
            selected: formik.values.minDurationInHours !== undefined,
          }),
          selectableAction({
            name: "Maks. fyringstemperatur",
            onExecute: (action) =>
              formik.setFieldValue(
                "maxTemperature",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.maxTemperature
              ),
            selected: formik.values.maxTemperature !== undefined,
          }),
          selectableAction({
            name: "Min. maks. delta temp.",
            onExecute: (action) =>
              formik.setFieldValue(
                "minMaxDeltaTemperature",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.minMaxDeltaTemperature
              ),
            selected: formik.values.minMaxDeltaTemperature !== undefined,
          }),
          selectableAction({
            name: "Maks. maks. delta temp.",
            onExecute: (action) =>
              formik.setFieldValue(
                "maxMaxDeltaTemperature",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.maxMaxDeltaTemperature
              ),
            selected: formik.values.maxMaxDeltaTemperature !== undefined,
          }),
          selectableAction({
            name: "Avvikende anslått røykløpstype",
            onExecute: (action) =>
              formik.setFieldValue(
                "requireDeviantPresumedFlueType",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.requireDeviantPresumedFlueType
              ),
            selected:
              formik.values.requireDeviantPresumedFlueType !== undefined,
          }),
          selectableAction({
            name: "Treffsikkerhet for anslått røykløpstype",
            onExecute: (action) =>
              formik.setFieldValue(
                "presumedFlueTypeConfidence",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.presumedFlueTypeConfidence
              ),
            selected: formik.values.presumedFlueTypeConfidence !== undefined,
          }),
          selectableAction({
            name: "Anslått røykløpstype",
            onExecute: (action) =>
              formik.setFieldValue(
                "presumedFlueTypes",
                action.selected
                  ? undefined
                  : props.defaultFilterValues.presumedFlueTypes
              ),
            selected: formik.values.presumedFlueTypes !== undefined,
          }),
        ];

        return (
          <Stack direction={"column"} useFlexGap gap={1} sx={{ padding: 0 }}>
            <FilterGroup label={"Røykløps-filter"}>
              <AddFilter filterActions={fluesFilterActions} />
              {formik.values.street !== undefined && (
                <Property
                  name={"street"}
                  label={"Vegadresse"}
                  value={formik.values.street}
                  removable
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
              {formik.values.flueTypes !== undefined && (
                <Property
                  name={"flueTypes"}
                  label={"Røykløpstype"}
                  value={formik.values.flueTypes}
                  valueOptions={{
                    options: flueTypeOptions,
                    multiple: true,
                    restrictToOptions: false,
                  }}
                  removable
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
            </FilterGroup>
            <FilterGroup label={"Fyrings-filter"}>
              <AddFilter filterActions={burnsFilterActions} />
              {formik.values.unfinished !== undefined && (
                <FormikProperty
                  formik={formik}
                  name={"unfinished"}
                  label={t("Unfinished")}
                  type={"boolean"}
                  mode={"edit"}
                  size={componentSize}
                  removable
                />
              )}
              {formik.values.minDurationInHours !== undefined && (
                <FormikProperty
                  formik={formik}
                  name={"minDurationInHours"}
                  label={"Minimum fyringsvarighet"}
                  editComponent={"Slider"}
                  editComponentProps={{
                    Slider: {
                      min: 0,
                      max: 168,
                      step: 0.5,
                    },
                  }}
                  sx={{ width: "250px" }}
                  unit={"t"}
                  mode={"edit"}
                  size={componentSize}
                  removable
                />
              )}
              {formik.values.maxTemperature !== undefined && (
                <Property
                  name={"maxTemperature"}
                  label={"Maks. temp."}
                  value={formik.values.maxTemperature}
                  removable
                  editComponent={"Slider"}
                  editComponentProps={{
                    Slider: {
                      min: 0,
                      max: 600,
                    },
                  }}
                  sx={{ width: "250px" }}
                  unit={"°C"}
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
              {formik.values.minMaxDeltaTemperature !== undefined && (
                <Property
                  name={"minMaxDeltaTemperature"}
                  label={"Min. maks. delta temp."}
                  value={formik.values.minMaxDeltaTemperature}
                  removable
                  editComponent={"Slider"}
                  editComponentProps={{
                    Slider: {
                      min: 0,
                      max: 1000,
                    },
                  }}
                  sx={{ width: "250px" }}
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
              {formik.values.maxMaxDeltaTemperature !== undefined && (
                <Property
                  name={"maxMaxDeltaTemperature"}
                  label={"Maks. maks. delta temp."}
                  value={formik.values.maxMaxDeltaTemperature}
                  removable
                  editComponent={"Slider"}
                  editComponentProps={{
                    Slider: {
                      min: 0,
                      max: 1000,
                    },
                  }}
                  sx={{ width: "250px" }}
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
              {formik.values.requireDeviantPresumedFlueType !== undefined && (
                <Property
                  name={"requireDeviantPresumedFlueType"}
                  label={"Avvikende anslått røykløpstype"}
                  value={formik.values.requireDeviantPresumedFlueType}
                  removable
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
              {formik.values.presumedFlueTypes !== undefined && (
                <Property
                  name={"presumedFlueTypes"}
                  label={"Anslått røykløpstype"}
                  value={formik.values.presumedFlueTypes}
                  valueOptions={{
                    options: flueTypeOptions,
                    multiple: true,
                    restrictToOptions: false,
                  }}
                  removable
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
              {formik.values.presumedFlueTypeConfidence !== undefined && (
                <Property
                  name={"presumedFlueTypeConfidence"}
                  label={"Treffsikkerhet for anslag"}
                  info={"Treffsikkerhet for anslått røykløpstype"}
                  value={formik.values.presumedFlueTypeConfidence}
                  sx={{ width: "250px" }}
                  removable
                  unit={"%"}
                  mode={"edit"}
                  onFormikFieldValueChange={formik.setFieldValue}
                  size={componentSize}
                />
              )}
            </FilterGroup>
            <Toolbar variant={dense ? "dense" : "regular"} disableGutters>
              <Button
                size={componentSize}
                color={"secondary"}
                variant={"outlined"}
                onClick={() => formik.submitForm()}
              >
                Last på nytt
              </Button>
            </Toolbar>
          </Stack>
        );
      }}
    </Formik>
  );
};
