import { Property, PropertyProps } from "shared-ts-property";
import React, { FC } from "react";
import { FlueTypeStringsEnum } from "@airmont/firefly/shared/ts/domain";
import { useTranslation } from "react-i18next";

export type FlueTypePropertyProps = Omit<
  PropertyProps,
  "name" | "label" | "type" | "valueOptions"
>;
export const FlueTypeProperty: FC<FlueTypePropertyProps> = (props) => {
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  return (
    <Property
      {...props}
      name="flueType"
      label={tFireflySharedDomain("Flue Type")}
      type={"string"}
      valueOptions={{
        multiple: false,
        restrictToOptions: true,
        options: [
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.Steel}`
            ),
            id: FlueTypeStringsEnum.Steel,
          },
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.SteelExternal}`
            ),
            id: FlueTypeStringsEnum.SteelExternal,
          },
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.ElementalCeramic}`
            ),
            id: FlueTypeStringsEnum.ElementalCeramic,
          },
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.SteelFlexi}`
            ),
            id: FlueTypeStringsEnum.SteelFlexi,
          },
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.ElementalPimpstone}`
            ),
            id: FlueTypeStringsEnum.ElementalPimpstone,
          },
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.Brick}`
            ),
            id: FlueTypeStringsEnum.Brick,
          },
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.Elemental}`
            ),
            id: FlueTypeStringsEnum.Elemental,
          },
          {
            label: tFireflySharedDomain(
              `FlueType.${FlueTypeStringsEnum.Unknown}`
            ),
            id: FlueTypeStringsEnum.Unknown,
          },
        ],
      }}
    />
  );
};
