import { FC, useMemo, useState } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Stack } from "@mui/material";
import { useQueryRows } from "shared/ts/ui/x-data-grid";
import { FlueId } from "@airmont/firefly/shared/ts/domain";
import { FluesWithAbnormalBurnsTable } from "./FluesWithAbnormalBurnsTable";
import {
  FluesWithAbnormalBurnsQueryDto,
  FlueWithAbnormalBurnsDto,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { FluesWithAbnormalBurnsFilterPanel } from "./FluesWithAbnormalBurnsFilterPanel";
import {
  FluesWithAbnormalBurnsFilterFormValues,
  transform_FluesWithAbnormalBurnsFilterFormValues_to_AbnormalBurnsFilterFormValues,
} from "./FluesWithAbnormalBurnsFilterFormValues";
import { AbnormalBurnsSupportingPane } from "./AbnormalBurnsSupportingPane";
import { notUndef } from "@airmont/shared/ts/utils/core";

const initialFilterValues: FluesWithAbnormalBurnsFilterFormValues = {
  street: undefined,
  flueTypes: undefined,
  unfinished: undefined,
  maxTemperature: undefined,
  minMaxDeltaTemperature: undefined,
  maxMaxDeltaTemperature: undefined,
  minDurationInHours: undefined,
  requireDeviantPresumedFlueType: undefined,
  presumedFlueTypeConfidence: undefined,
  presumedFlueTypes: undefined,
};

const defaultFilterValues: FluesWithAbnormalBurnsFilterFormValues = {
  street: "",
  flueTypes: [],
  unfinished: true,
  minDurationInHours: 1,
  maxTemperature: { min: 0, max: 500 },
  minMaxDeltaTemperature: 500,
  maxMaxDeltaTemperature: 1000,
  requireDeviantPresumedFlueType: true,
  presumedFlueTypeConfidence: { min: 90, max: 100 },
  presumedFlueTypes: [],
};
export const AbnormalBurnsPage: FC = () => {
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [viewedFlue, setViewedFlue] = useState<
    FlueWithAbnormalBurnsDto | undefined
  >(undefined);

  const [filterValues, setFilterValues] =
    useState<FluesWithAbnormalBurnsFilterFormValues>(initialFilterValues);

  const queryInput = useMemo(
    () => {
      return {
        street: filterValues.street?.notEmptyOrUndef(),
        minDurationInMinutes: notUndef(
          filterValues.minDurationInHours,
          (value) => value * 60
        ),
        requireDeviantPresumedFlueType:
          filterValues.requireDeviantPresumedFlueType,
        maxTemperature: filterValues.maxTemperature,
        minMaxDeltaTemperature: filterValues.minMaxDeltaTemperature,
        maxMaxDeltaTemperature: filterValues.maxMaxDeltaTemperature,
        presumedFlueTypeConfidence: notUndef(
          filterValues.presumedFlueTypeConfidence,
          (confidence) => ({
            min: confidence.min * 0.01,
            max: confidence.max * 0.01,
          })
        ),
        flueTypes:
          filterValues.flueTypes?.length === 0
            ? undefined
            : filterValues.flueTypes,
        presumedFlueTypes:
          filterValues.presumedFlueTypes === undefined ||
          filterValues.presumedFlueTypes.length === 0
            ? undefined
            : filterValues.presumedFlueTypes,
      } as FluesWithAbnormalBurnsQueryDto;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValues]
  );
  const { rows, selectedRowId, setSelectedRowId, query, handleRowsChange } =
    useQueryRows<
      FlueId,
      FlueWithAbnormalBurnsDto,
      FluesWithAbnormalBurnsQueryDto
    >(queryInput);

  const handleSelectedFlueRow = (rowId: FlueId | undefined) => {
    setSelectedRowId(rowId);
  };

  const handleViewRequested = (flue: FlueWithAbnormalBurnsDto) => {
    setViewedFlue(flue);
  };

  const handleFilerValuesChange = (
    values: FluesWithAbnormalBurnsFilterFormValues
  ) => {
    setFilterValues(values);
    setForceRefresh((prevState) => prevState + 1);
  };

  return (
    <AppPage
      $key={"FluesWithTypeDeviationPage"}
      name={"Røykløp med unormale fyringer"}
      mainPane={
        <Stack
          direction={"column"}
          useFlexGap
          gap={1}
          sx={{
            flexGrow: 1,
            minHeight: 0,
            "&&&": {
              pl: 2,
              pr: 2,
            },
          }}
        >
          <FluesWithAbnormalBurnsFilterPanel
            filterValues={filterValues}
            defaultFilterValues={defaultFilterValues}
            onChange={handleFilerValuesChange}
          />
          <FluesWithAbnormalBurnsTable
            forceRefresh={forceRefresh}
            query={query}
            rows={rows}
            onRowsChange={handleRowsChange}
            onSelectedRow={handleSelectedFlueRow}
            onViewRequested={handleViewRequested}
          />
        </Stack>
      }
      supportingPaneProps={{
        label: "Røykløp",
      }}
      supportingPane={
        <AbnormalBurnsSupportingPane
          viewedFlue={viewedFlue}
          filterValues={transform_FluesWithAbnormalBurnsFilterFormValues_to_AbnormalBurnsFilterFormValues(
            filterValues
          )}
          defaultFilterValues={transform_FluesWithAbnormalBurnsFilterFormValues_to_AbnormalBurnsFilterFormValues(
            defaultFilterValues
          )}
        />
      }
    />
  );
};

export const useAbnormalBurnsPageEnabled = (): boolean => {
  return localStorage.getItem("AbnormalBurnsPage") === "true";
};
