import { FC } from "react";
import { useCustomerEnvironmentContext } from "@airmont/firefly/shared/ts/customer-environment";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export const GeneralSettingsPane: FC = () => {
  const { environmentId, environment } = useCustomerEnvironmentContext();

  return (
    <Box>
      <Typography>{environment?.name}</Typography>
    </Box>
  );
};
