import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {FlueTypeStringsEnum} from "@airmont/firefly/shared/ts/domain";
import {ValueOption} from "shared-ts-property";

export const useFlueTypeOptions = (): Array<ValueOption> => {
  const {t: tDomain} = useTranslation("firefly-shared-ts-domain");
  return useMemo(() => {
    return [
      {
        id: FlueTypeStringsEnum.Steel,
        label: tDomain(`FlueType.${FlueTypeStringsEnum.Steel}`),
      },
      {
        id: FlueTypeStringsEnum.SteelExternal,
        label: tDomain(`FlueType.${FlueTypeStringsEnum.SteelExternal}`),
      },
      {
        id: FlueTypeStringsEnum.SteelFlexi,
        label: tDomain(`FlueType.${FlueTypeStringsEnum.SteelFlexi}`),
      },
      {
        id: FlueTypeStringsEnum.ElementalCeramic,
        label: tDomain(`FlueType.${FlueTypeStringsEnum.ElementalCeramic}`),
      },
      {
        id: FlueTypeStringsEnum.ElementalPimpstone,
        label: tDomain(`FlueType.${FlueTypeStringsEnum.ElementalPimpstone}`),
      },
      {
        id: FlueTypeStringsEnum.Brick,
        label: tDomain(`FlueType.${FlueTypeStringsEnum.Brick}`),
      },
      {
        id: FlueTypeStringsEnum.Unknown,
        label: tDomain(`FlueType.${FlueTypeStringsEnum.Unknown}`),
      },
    ];
  }, [tDomain]);
};
