import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { FlueUpdateDto } from "./FlueUpdate";
import { AccessToken } from "shared-ts-utils-authentication";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { FlueDto } from "./Flue";
import { FlueId, MunicipalityId } from "@airmont/firefly/shared/ts/domain";
import { DateTime } from "luxon";
import { SweepCountByPeriodDto } from "./needForSweep/SweepCountByPeriod";
import { FluesWithAbnormalBurnsQueryDto } from "./FluesWithAbnormalBurnsQuery";
import { QueryResult } from "shared/ts/utils/query";
import { FlueWithAbnormalBurnsDto } from "./FlueWithAbnormalBurns";
import { FlueNeedsSweepDto } from "./needForSweep/FlueNeedForSweep";
import {
  RepeatingSweepCountQuery,
  RepeatingSweepCountQueryDto,
} from "./needForSweep/RepeatingSweepCountQuery";
import {
  UpcomingSweepCountQuery,
  UpcomingSweepCountQueryDto,
} from "./needForSweep/UpcomingSweepCountQuery";
import {
  FluesNeedsSweepQuery,
  FluesNeedsSweepQueryDto,
} from "./needForSweep/FluesNeedsSweepQuery";

export class FlueDao {
  public readonly customerEnvironment: CustomerEnvironmentName;
  private fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: AccessToken;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.customerEnvironment = args.customerEnvironment;
    this.fetchHelper = new FetchHelper({ basePath: "/api/flue/" })
      .authorizationBearer(args.accessToken)
      .addHeader("Firefly-Environment", args.customerEnvironment);
  }

  update(update: FlueUpdateDto): Promise<FlueDto> {
    return this.fetchHelper.post("update", update).fetchAsJson();
  }

  delete(id: FlueId): Promise<void> {
    return this.fetchHelper.delete(`delete/${id}`).fetch();
  }

  countOverdueSweeps(
    before: DateTime<true>,
    municipalityId: MunicipalityId | undefined
  ): Promise<number> {
    return this.fetchHelper
      .post("count-overdue-sweeps", {
        before: before.toUTC().toISO(),
        municipalityId: municipalityId,
      })
      .fetchAsJson();
  }

  countUpcomingSweeps(
    time: DateTime<true>,
    municipalityId: MunicipalityId | undefined
  ): Promise<number> {
    const timeAsIso = time.toUTC().toISO();
    return this.fetchHelper
      .post("count-upcoming-sweeps", {
        after: timeAsIso,
        municipalityId: municipalityId,
      })
      .fetchAsJson();
  }

  queryUpcomingSweepCount(
    query: UpcomingSweepCountQuery
  ): Promise<Array<SweepCountByPeriodDto>> {
    const json: UpcomingSweepCountQueryDto = {
      municipalityId: query.municipalityId,
      timeZoneId: query.timeZoneId,
      timeUnit: query.timeUnit,
      start: query.start.toUTC().toISO(),
      end: query.end.toUTC().toISO(),
    };
    return this.fetchHelper
      .post("query-upcoming-sweep-count", json)
      .fetchAsJson();
  }

  queryRepeatingSweepCount(
    query: RepeatingSweepCountQuery
  ): Promise<Array<SweepCountByPeriodDto>> {
    const json: RepeatingSweepCountQueryDto = {
      municipalityId: query.municipalityId,
      timeZoneId: query.timeZoneId,
      timeUnit: query.timeUnit,
      start: query.start.toUTC().toISO(),
      end: query.end.toUTC().toISO(),
    };
    return this.fetchHelper
      .post("query-repeating-sweep-count", json)
      .fetchAsJson();
  }

  queryFluesNeedsSweep(
    query: FluesNeedsSweepQuery
  ): Promise<Array<FlueNeedsSweepDto>> {
    const json: FluesNeedsSweepQueryDto = {
      municipalityId: query.municipalityId,
      timeZoneId: query.timeZoneId,
      start: query.start.toUTC().toISO(),
      end: query.end.toUTC().toISO(),
    };
    return this.fetchHelper.post("query-flues-needs-sweep", json).fetchAsJson();
  }

  queryFluesWithAbnormalBurns(
    query: FluesWithAbnormalBurnsQueryDto
  ): Promise<QueryResult<FlueWithAbnormalBurnsDto>> {
    return this.fetchHelper
      .post("query-flues-with-abnormal-burns", query)
      .fetchAsJson<QueryResult<FlueWithAbnormalBurnsDto>>();
  }
}
