

export const notNullOrUndefined = <T, R>(value : T | null | undefined, fn : (value: T) => R | null): R | null | undefined => {
    if(value === undefined) {
        return undefined;
    }
    if(value == null) {
        return null;
    }
    
    return fn(value);
}

export const notNull = <T, R>(value : T | null, fn : (value: T) => R | null): R | null => {
   
    if(value == null) {
        return null;
    }

    return fn(value);
}

export const notUndef = <T, R>(value : T | undefined, fn : (value: T) => R | undefined): R | undefined => {

    if(value == undefined) {
        return undefined;
    }

    return fn(value);
} 