import { User, UserManager, UserManagerSettings } from "oidc-client";
import {
  OidcLoginActions,
  OidcLogoutActions,
} from "./OidcAuthenticationConstants";
import { IOidcApplicationPaths } from "./OidcApplicationPaths";

export class OidcAuthenticationService {
  private static instance: OidcAuthenticationService;
  userManager: UserManager;
  authority: string | undefined;
  _user: User | null = null;

  // By default pop ups are disabled because they don't work properly on Edge.
  _isAuthenticated = false;
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  constructor(settings: UserManagerSettings) {
    this.authority = settings.authority;
    this.userManager = new UserManager(settings);
    this.userManager.events.addUserSignedOut(() => {
      this.userManager.removeUser().then(() => {
        this.updateState(null);
      });
    });
    OidcAuthenticationService.instance = this;
  }

  static get(): OidcAuthenticationService {
    if (OidcAuthenticationService.instance == null) {
      throw new Error(
        "OidcAuthenticationService.get cannot be used before instantiated"
      );
    }

    return OidcAuthenticationService.instance;
  }

  applicationPaths(): IOidcApplicationPaths {
    const prefix = "/authentication";
    return {
      DefaultLoginRedirectPath: "/",
      //ApiAuthorizationClientConfigurationUrl: `${authServer}/_configuration/${ApplicationName}`,
      ApiAuthorizationPrefix: prefix,
      Login: `${prefix}/${OidcLoginActions.Login}`,
      LoginFailed: `${prefix}/${OidcLoginActions.LoginFailed}`,
      LoginCallback: `${prefix}/${OidcLoginActions.LoginCallback}`,
      Register: `${prefix}/${OidcLoginActions.Register}`,
      Profile: `${prefix}/${OidcLoginActions.Profile}`,
      LogOut: `${prefix}/${OidcLogoutActions.Logout}`,
      LoggedOut: `${prefix}/${OidcLogoutActions.LoggedOut}`,
      LogOutCallback: `${prefix}/${OidcLogoutActions.LogoutCallback}`,
      IdentityRegisterPath: `${this.authority}/Identity/Account/Register`,
      IdentityManagePath: `${this.authority}/Identity/Account/Manage`,
      IdentityManageUsersPath: `${this.authority}/IdentityManager/Home/Users`,
    };
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user;
    }
    return await this.userManager.getUser();
  }

  //    redirect flow.
  async signIn(state: unknown) {
    try {
      const silentUser = await this.userManager.signinSilent(
        this.createArguments(state)
      );
      this.updateState(silentUser);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.error("Silent authentication error: ", silentError);

      try {
        if (this._popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
          );
        }

        const popUpUser = await this.userManager.signinPopup(
          this.createArguments()
        );
        this.updateState(popUpUser);
        return this.success(state);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (popUpError: any) {
        if (popUpError.message === "Popup window closed") {
          // The user explicitly cancelled the login action by closing an opened popup.
          return this.error("The user closed the window.");
        } else if (!this._popUpDisabled) {
          console.error("Popup authentication error: ", popUpError);
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.userManager.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          console.error("Redirect authentication error: ", redirectError);
          return this.error(redirectError);
        }
      }
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional

  async completeSignIn(url: string) {
    try {
      const user = await this.userManager.signinCallback(url);
      this.updateState(user);
      return this.success(user && user.state);
    } catch (error) {
      console.error("There was an error signing in: ", error);
      return this.error("There was an error signing in.");
    }
  }

  //    post logout redirect flow.
  async signOut(state: unknown) {
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
        );
      }

      await this.userManager.signoutPopup(this.createArguments());
      this.updateState(null);
      return this.success(state);
    } catch (popupSignOutError) {
      console.error("Popup signout error: ", popupSignOutError);
      try {
        await this.userManager.signoutRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectSignOutError) {
        console.error("Redirect signout error: ", redirectSignOutError);
        return this.error(redirectSignOutError);
      }
    }
  }

  async completeSignOut(url: string) {
    try {
      const response = await this.userManager.signoutCallback(url);
      this.updateState(null);
      return this.success(response && response.state);
    } catch (error) {
      console.error(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  updateState(user: User | null) {
    this._user = user;
    this._isAuthenticated = !!this._user;
  }

  createArguments(state: unknown | null = null) {
    return { useReplaceToNavigate: true, data: state };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(error: any) {
    const message = error["message"] != null ? error["message"] : error;

    return { status: AuthenticationResultStatus.Fail, message: message };
  }

  success(state: unknown) {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }
}

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
