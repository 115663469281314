import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { FluesWithAbnormalBurnsQueryClient } from "./FluesWithAbnormalBurnsQueryClient";
import { useFlueDao } from "./useFlueDao";

export const useFlueWithTypeDeviationQueryClient =
  (): FluesWithAbnormalBurnsQueryClient => {
    const queryClient = useQueryClient();
    const dao = useFlueDao();
    return useMemo(
      () =>
        new FluesWithAbnormalBurnsQueryClient({
          queryClient: queryClient,
          dao: dao,
        }),
      [queryClient, dao]
    );
  };
