import { Expr } from "../../Expr";

export interface IsEmptyXpr {
  $type: "isEmpty";
  instance: Expr;
}

export const isEmptyExpr = (instance: Expr): IsEmptyXpr => ({
  $type: "isEmpty",
  instance: instance,
});
