import { ServerErrorFactory } from "./ServerErrorFactory";

export async function notOKToError(
  request: RequestInfo,
  response: Response,
  errorFactory: ServerErrorFactory
): Promise<Response> {
  if (!response.ok) {
    throw await errorFactory(response, request);
  }

  return Promise.resolve(response);
}
