import { TimeSeriesChartOptions } from "./TimeSeriesChartOptions";
import { Theme } from "@mui/material/styles";
import { useMemo } from "react";

const resolveYAxis = (
  options: TimeSeriesChartOptions | undefined,
  theme: Theme
): ApexYAxis => {
  const yAxis: ApexYAxis = {
    title: {
      text: options?.yAxis?.title?.text,
      style: {
        color: theme.palette.text.secondary,
      },
    },
    labels: {
      style: {
        colors: theme.palette.text.secondary,
      },
      formatter: (value: number, opts?: unknown): string => {
        const valueRounded = Math.round(value);
        if (options?.yAxis?.unit != null) {
          return `${valueRounded} ${options.yAxis?.unit}`;
        } else {
          return `${valueRounded}`;
        }
      },
    },
    max: options?.yAxis?.max,
    min: options?.yAxis?.min,
  };
  return yAxis;
};

export const useResolveYAxis = (
  options: TimeSeriesChartOptions | undefined,
  theme: Theme
): ApexYAxis => {
  return useMemo(() => resolveYAxis(options, theme), [options, theme]);
};
