import React, { FC } from "react";
import { TabPanel, TabPanelProps } from "@airmont/shared/ts/ui/tabs";
import { Box, Stack } from "@mui/material";
import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { CssGridTemplate } from "@airmont/shared/ts/ui/css-grid-template";
import { ViewLocation } from "./ViewLocation";
import { AddressPropertiesPanel } from "./AddressPropertiesPanel";
import { CadastralNumbersPropertiesPane } from "./CadastralNumbersPropertiesPane";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { EditLocation } from "./EditLocation";
import { useFormikContext } from "formik";
import { FlueFormValues } from "../FlueFormValues";
import { Property, PropertyMode } from "shared-ts-property";
import { useTranslation } from "react-i18next";
import { DisplayMode } from "shared-ts-app-dialog";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { useSxMerge } from "shared-ts-mui";
import { ConditionalWrap } from "@airmont/shared/ts/ui/react";
import {
  GoogleMapApiProvider,
  WaitForGoogleApisLoaded,
} from "shared-ts-ui-react-google-maps";

export interface AddressTabPanelProps extends TabPanelProps {
  flueStatus: FlueStatusDto;
  displayMode: DisplayMode;
}

export const AddressTabPanel: FC<AddressTabPanelProps> = (props) => {
  const widthComparer = useWindowWidthComparer();
  const propertiesMode: PropertyMode =
    props.displayMode === "view" ? "read" : "edit";
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const { values: formValues, setFieldValue: setFormValue } =
    useFormikContext<FlueFormValues>();

  const sx = useSxMerge(props.sx, {
    display: "flex",
    flexDirection: "column",
    p: 1,
  });

  return (
    <TabPanel tabValue={props.tabValue} sx={sx} scrollableY>
      <CssGridTemplate
        areas={{
          compact: "'m' 'g' 'a' 'c'",
          medium: "'m m m' 'g a c'",
          expanded: "'m gac' 'm gac' 'm gac'",
        }}
        columns={{
          compact: "auto",
          medium: "auto auto",
          expanded: "1fr auto",
        }}
        rows={{
          compact: "auto",
          medium: "1fr auto",
          expanded: "auto auto",
        }}
        sx={{
          flexGrow: 1,
          minHeight: 0,
          gap: 1,
        }}
      >
        <GoogleMapApiProvider>
          <WaitForGoogleApisLoaded
            loadingComponent={<Box>Loading Google Maps api</Box>}
          >
            <Box sx={{ gridArea: "m" }}>
              {propertiesMode === "read" && (
                <ViewLocation
                  flueStatus={props.flueStatus}
                  minWidth={"100px"}
                  minHeight={"250px"}
                />
              )}
              {propertiesMode === "edit" && (
                <EditLocation
                  lat={formValues.lat}
                  lng={formValues.lng}
                  onChange={(value) => {
                    setFormValue("lat", value.lat);
                    setFormValue("lng", value.lng);
                  }}
                />
              )}
            </Box>
          </WaitForGoogleApisLoaded>
        </GoogleMapApiProvider>
        <ConditionalWrap
          condition={widthComparer.gte(SizeClass.Expanded)}
          wrap={(children) => (
            <Stack sx={{ gridArea: "gac", overflowY: "auto" }}>
              {children}
            </Stack>
          )}
        >
          <PropertiesCard
            sx={{
              gridArea: widthComparer.lt(SizeClass.Expanded) ? "g" : undefined,
            }}
            header={{
              title: tFireflyDomain("Geo-location"),
            }}
            childPropertyProps={{
              fullWidth: widthComparer.sizeClass === SizeClass.Compact,
            }}
            elevation={0}
          >
            <Property
              name="lat"
              label={tFireflyDomain("Latitude")}
              type={"number"}
              value={formValues.lat}
              mode={propertiesMode}
              onFormikFieldValueChange={setFormValue}
            />
            <Property
              name="lng"
              label={tFireflyDomain("Longitude")}
              type={"number"}
              value={formValues.lng}
              mode={propertiesMode}
              onFormikFieldValueChange={setFormValue}
            />
          </PropertiesCard>
          <AddressPropertiesPanel
            flueStatus={props.flueStatus}
            mode={propertiesMode}
            fullWidth={widthComparer.eq(SizeClass.Compact)}
            sx={{
              gridArea: widthComparer.lt(SizeClass.Expanded) ? "a" : undefined,
            }}
          />
          <CadastralNumbersPropertiesPane
            flueStatus={props.flueStatus}
            mode={propertiesMode}
            fullWidth={widthComparer.eq(SizeClass.Compact)}
            sx={{
              gridArea: widthComparer.lt(SizeClass.Expanded) ? "c" : undefined,
            }}
          />
        </ConditionalWrap>
      </CssGridTemplate>
    </TabPanel>
  );
};
