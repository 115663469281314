import { styled } from "@mui/material";
import React, { ReactNode } from "react";
import { useDrawingArea } from "@mui/x-charts";

const StyledText = styled("text")(({ theme, fontSize }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: fontSize ?? 20,
}));

export interface PieCenterLabelProps {
  children: ReactNode;
  fontSize?: number | string;
}

export const PieCenterLabel = (props: PieCenterLabelProps) => {
  const { children, fontSize } = props;
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2} fontSize={fontSize}>
      {children}
    </StyledText>
  );
};
