import { FC, ReactNode, useMemo } from "react";
import { DateTime, Interval } from "luxon";
import { Box, SxProps, useTheme } from "@mui/material";
import { EventImpl } from "@airmont/shared-ts-event";
import { RenderEventParams } from "../RenderEventParams";
import { Day } from "../day/Day";
import { CalendarTimeslotLabels } from "../CalendarTimeslotLabels";
import { useCalendarSettings } from "../CalendarSettingsProvider";

export interface CalendarWeekBodyProps {
  variant: "events" | "timeslots";
  dateTimeNow: DateTime<true>;
  selectedDate: DateTime<true>;
  dates: Array<DateTime<true>>;
  events: Array<EventImpl>;
  hideDayHeader?: boolean;
  renderEvent: (params: RenderEventParams) => ReactNode;
  onNewEvent?: (interval: Interval<true> | DateTime<true> | null) => void;
  sx?: SxProps;
}

export const CalendarWeekBody: FC<CalendarWeekBodyProps> = (props) => {
  const { variant, dateTimeNow, selectedDate, dates } = props;
  const firstDateTime = dates[0];
  const lastDateTime = dates[dates.length - 1];
  const theme = useTheme();
  const calendarSettings = useCalendarSettings();
  const events = useMemo(() => {
    return props.events.filter(
      (event) => event.start >= firstDateTime && event.start < lastDateTime
    );
  }, [firstDateTime, lastDateTime, props.events]);

  return (
    <Box
      className={"CalendarWeekBody"}
      sx={{
        flexGrow: 1,
        minHeight: 0,
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns:
          variant === "timeslots"
            ? "60px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)"
            : "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
        gridAutoRows: "1fr",
        overflowY: variant === "timeslots" ? "auto" : undefined,
        ".CalendarTimeslotLabels": {
          borderRight: "1px solid " + theme.palette.divider,
        },
        ".Day:not(:last-child)": {
          borderRight: "1px solid " + theme.palette.divider,
        },
        ".MuiListItemIcon-root": {
          minWidth: "unset",
        },
      }}
    >
      {variant === "timeslots" && (
        <CalendarTimeslotLabels
          timeslotDuration={calendarSettings.timeslotDuration}
        />
      )}
      {dates.map((currDate) => {
        const isToday =
          currDate.year === dateTimeNow.year &&
          currDate.month === dateTimeNow.month &&
          currDate.day === dateTimeNow.day;
        return (
          <Day
            key={currDate.toMillis()}
            date={currDate}
            dateTimeNow={dateTimeNow}
            timeslotDuration={calendarSettings.timeslotDuration}
            isToday={isToday}
            variant={variant}
            events={events}
            renderEvent={props.renderEvent}
            onNewEvent={props.onNewEvent}
          />
        );
      })}
    </Box>
  );
};
