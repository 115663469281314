import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import { DateTime } from "luxon";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { AxisValueFormatterLocation } from "./AxisValueFormatterLocation";

export class TimeAxisValueFormatterFactory {
  static create(args: {
    formatOptions?: Intl.DateTimeFormatOptions;
    formatOptionsByLocation?: Partial<
      Record<AxisValueFormatterLocation, Intl.DateTimeFormatOptions | undefined>
    >;
    formatTokens?: string;
    formatTokensByLocation?: Partial<
      Record<AxisValueFormatterLocation, string | undefined>
    >;
  }): (value: Date, context: AxisValueFormatterContext) => string {
    return (value, context: AxisValueFormatterContext) => {
      const dateTime = DateTime.fromJSDate(value);

      const formatOptions = this.resolveFormatOptions(
        args.formatOptions,
        args.formatOptionsByLocation,
        context
      );
      const formatTokens = this.resolveFormatTokens(
        args.formatTokens,
        args.formatTokensByLocation,
        context
      );

      if (formatOptions !== undefined) {
        return dateTime.toLocaleString(formatOptions);
      } else if (formatTokens !== undefined) {
        return dateTime.toFormat(formatTokens);
      } else {
        throw new IllegalArgumentError(
          "Either formatOptions or formatTokens must be given"
        );
      }
    };
  }

  private static resolveFormatTokens(
    formatTokens: string | undefined,
    formatTokensByLocation: Record<string, string | undefined> | undefined,
    context: AxisValueFormatterContext
  ): string | undefined {
    let result: string | undefined;
    if (formatTokensByLocation !== undefined) {
      result = formatTokensByLocation[context.location];
    }

    if (result === undefined) {
      result = formatTokens;
    }

    return result;
  }

  private static resolveFormatOptions(
    formatOptions: Intl.DateTimeFormatOptions | undefined,
    formatOptionsByLocation:
      | Record<string, Intl.DateTimeFormatOptions | undefined>
      | undefined,
    context: AxisValueFormatterContext
  ): Intl.DateTimeFormatOptions | undefined {
    let result: Intl.DateTimeFormatOptions | undefined;
    if (formatOptionsByLocation !== undefined) {
      result = formatOptionsByLocation[context.location];
    }
    if (result === undefined) {
      result = formatOptions;
    }

    return result;
  }
}
