import { Box, useTheme } from "@mui/material";
import React, { FC } from "react";
import { DateTime } from "luxon";

export interface TimeslotProps {
  timeslot?: DateTime<true>;
  height: number;
  selected?: boolean;
  onMouseDown?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const Timeslot: FC<TimeslotProps> = (props) => {
  const { timeslot, height } = props;
  const selected = props.selected ?? false;
  const theme = useTheme();

  return (
    <Box
      className={"Timeslot"}
      data-hour={timeslot?.hour}
      sx={{
        minHeight: height + "px",
        userSelect: "none",
        backgroundColor: selected ? theme.palette.action.selected : undefined,
      }}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      onMouseEnter={props.onMouseEnter}
    />
  );
};
