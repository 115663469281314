import { ChimneySensorSettingsHistoryDto } from "@airmont/firefly/shared/ts/domain";

export const getChimneySensorSettingsHistoryMocks =
  (): Array<ChimneySensorSettingsHistoryDto> => {
    return [
      {
        id: "078d6e0a-5993-400b-81fc-1cd4e5d63cd7",
        chimneySensorId: "123123",
        timestamp: "2023-01-01T12:00:00",
        modifiedBy: "jvs",
        settings: {
          thumbprint: 2956957995,
          samplingInterval: 60,
          tempSamplingDelay: 250,
          fireSamplingInterval: 10,
          statusReportInterval: 86400,
          healthReportInterval: 86400,
          measurementReportInterval: 86400,
          fireReportInterval: 60,
          fireReportStopInterval: 900,
          checkConfigInterval: 86400,
          chimneyFireThreshold: 450,
          fireDeltaTemp: 2,
          fireDeltaTempStop: 50,
          sendRetries: 3,
          modemMaxOnTime: 120,
          brokerUrl: "iot-no-qa-01.azure-devices.net",
          configUrl: "config.airmontcloud.net",
          firmwareVer: "1.15.4",
          firmwareChk: 4228669158,
        },
      },
    ];
  };
