import {useWindowSize} from "./useWindowSize";
import {useMemo} from "react";
import {SizeClassComparer} from "./SizeClassComparer";
import {SizeClass} from "./SizeClass";

export const useDense = () : boolean => {
  const {windowWidth} = useWindowSize();
  const comparer = useMemo(() => new SizeClassComparer(windowWidth), [windowWidth]);
  return useMemo(() => comparer.gte(SizeClass.Expanded), [comparer]);
}
