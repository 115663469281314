import { Property, PropertyMode } from "shared-ts-property";
import React, { FC } from "react";
import {ChimneyWasteTypeStringsEnum } from "@airmont/firefly/shared/ts/domain";
import { useTranslation } from "react-i18next";

export interface ChimneyWasteTypePropertyProps {
  propertyMode: PropertyMode;
  value: ChimneyWasteTypeStringsEnum | null | undefined;
  onChange: (
    name: string,
    value: ChimneyWasteTypeStringsEnum | null | undefined
  ) => void;
}
export const ChimneyWasteTypeProperty: FC<ChimneyWasteTypePropertyProps> = (props) => {
  const { propertyMode, value } = props;
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  return (
    <Property
      name="chimneyWasteType"
      label={tFireflySharedDomain("Waste Type")}
      mode={propertyMode}
      type={"string"}
      value={value}
      onChange={(value,name) =>
        props.onChange(name, value as typeof props.value)
      }
      valueOptions={{
        multiple: false,
        restrictToOptions: true,
        options: [
          {
            label: tFireflySharedDomain(
              `ChimneyWasteType.${ChimneyWasteTypeStringsEnum.Ash}`
            ),
            id: ChimneyWasteTypeStringsEnum.Ash,
          },
          {
            label: tFireflySharedDomain(
              `ChimneyWasteType.${ChimneyWasteTypeStringsEnum.Soot}`
            ),
            id: ChimneyWasteTypeStringsEnum.Soot ,
          },
          {
            label: tFireflySharedDomain(
              `ChimneyWasteType.${ChimneyWasteTypeStringsEnum.Creosote}`
            ),
            id: ChimneyWasteTypeStringsEnum.Creosote,
          }],
      }}
    />
  );
};
