import { GridFetchRowsParams } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import {
  QueryClient,
  QueryDto,
  QueryItem,
  QueryProviderFn,
  QueryWithoutPageDto,
} from "shared/ts/utils/query";
import { ExprHelper } from "./ExprHelper";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export const useQueryProvider = <T extends QueryItem>(
  queryClient: QueryClient<T>
): QueryProviderFn => {
  return useCallback(
    async (
      params: GridFetchRowsParams & { query: QueryDto }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<{ slice: any[]; total: number }> => {
      const where = ExprHelper.filterModelToExpressions(params.filterModel);
      const sort = params.sortModel.map((it) => {
        return {
          field: it.field,
          direction: it.sort,
        };
      });

      const query: QueryWithoutPageDto = {
        ...params.query,
        where: where,
        sort: sort,
      };
      const loadedRows = await queryClient.query(query, {
        fromIndex: params.firstRowToRender,
        size: params.lastRowToRender - params.firstRowToRender,
      });

      if (loadedRows.result == null) {
        throw new IllegalStateError("query.result is null or undefined");
      }

      return {
        slice: loadedRows.result,
        total: loadedRows.totalCount,
      };
    },
    [queryClient]
  );
};
