import { User as OidcClientUser } from "oidc-client";
import { NullError } from "@airmont/shared/ts/utils/core";
import { User } from "shared-ts-utils-authentication";

export class OidcUser implements User {
  private readonly oidcUser: OidcClientUser;

  constructor(oidcUser: OidcClientUser) {
    if (oidcUser == null) {
      throw new NullError("oidcUser");
    }
    this.oidcUser = oidcUser;
  }

  get id(): string {
    return this.oidcUser.profile.sub ?? "?";
  }
  get name(): string {
    return this.oidcUser.profile.name ?? "?";
  }
  get email(): string {
    return this.oidcUser.profile.email ?? "?";
  }

  get accessToken(): string {
    return this.oidcUser.access_token;
  }

  hasClaim(claim: string): boolean {
    const permissions = this.oidcUser.profile[
      "http://airmont.no/claims/permissions"
    ] as Array<string>;
    return permissions.includes(claim);
  }

  hasRole(role: string): boolean {
    const actualRole = this.oidcUser.profile["http://airmont.no/claims/role"];
    return actualRole === role;
  }

  getRole(): string {
    return this.oidcUser.profile["http://airmont.no/claims/role"];
  }

  isSessionActive(): boolean {
    return !this.oidcUser.expired;
  }
}
