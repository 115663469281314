import React, { ReactNode } from "react";
import { ApexOptions } from "apexcharts";
import { useTheme } from "@mui/material";
import { TimeSeriesOption } from "./TimeSeriesOption";
import { TimeSeriesChartOptions } from "./TimeSeriesChartOptions";
import { ApexChart } from "@airmont/firefly/shared/ts/charts";
import { SxProps } from "@mui/system";
import { useResolveApexSeries } from "./resolveApexSeries";
import { useResolveApexOptions } from "./useResolveApexOptions";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";

export interface TimeSeriesChartProps<PV> {
  timeSeries: Array<TimeSeries<PV>>;
  timeSeriesOptions: Array<TimeSeriesOption>;
  options?: TimeSeriesChartOptions;
  layout?: SizeClass;
  sx?: SxProps;
}

export const TimeSeriesChart = <PV,>(
  props: TimeSeriesChartProps<PV>
): ReactNode => {
  const theme = useTheme();

  const series = useResolveApexSeries(
    props.timeSeries,
    props.timeSeriesOptions,
    props.options?.chart?.type
  );

  const options: ApexOptions = useResolveApexOptions({
    timeSeries: props.timeSeries,
    options: props.options,
    theme,
  });

  return (
    <ApexChart
      options={options}
      series={series}
      layout={props.layout}
      sx={props.sx}
    />
  );
};
