import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { AddressDto } from "../address/Address";

export class AddressDao {
  private fetchHelper: FetchHelper;

  constructor() {
    this.fetchHelper = new FetchHelper({ basePath: "/api/address/" });
  }
  query(): Promise<Array<AddressDto>> {
    return this.fetchHelper.post("query").fetchAsJson();
  }

  /*getById(id: AddressId): Promise<AddressDto> {
    return this.fetchHelper.get(`get-by-id/${id}`).fetchAsJson();
  }*/
}
