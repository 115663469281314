import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { QueryDto, QueryItem } from "shared/ts/utils/query";
import { GridRowId } from "@mui/x-data-grid-premium";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";

export type GridRow = QueryItem & {
  id: GridRowId;
};

export interface UseQueryRowsReturnValue<
  ROW_ID extends GridRowId,
  ROW extends GridRow,
  QUERY extends QueryDto
> {
  rows: Array<ROW>;
  selectedRowId: ROW_ID | undefined;
  setRows: Dispatch<SetStateAction<Array<ROW>>>;
  prependRow: (row: ROW) => void;
  replaceRow: (row: ROW) => void;
  deleteRow: (row: ROW_ID | ROW) => void;
  query: QUERY;
  handleRowsChange: (rows: Array<ROW>) => void;
  setSelectedRowId: Dispatch<SetStateAction<ROW_ID | undefined>>;
}

export const useQueryRows = <
  ROW_ID extends GridRowId,
  ROW extends GridRow,
  QUERY extends QueryDto
>(
  query: QUERY
): UseQueryRowsReturnValue<ROW_ID, ROW, QUERY> => {
  const [rows, setRows] = useState<Array<ROW>>([]);
  const [selectedRowId, setSelectedRowId] = useState<ROW_ID | undefined>(
    undefined
  );

  const prependRow = useCallback(
    (row: ROW) => {
      setRows((prevState) => ArrayUtils.copyAndPrepend(prevState, row));
    },
    [setRows]
  );

  const replaceRow = useCallback(
    (row: ROW) => {
      setRows((prevState) => {
        return ArrayUtils.copyAndUpdateElement(
          prevState,
          (it) => it.id === row.id,
          row
        );
      });
    },
    [setRows]
  );

  const deleteRow = useCallback(
    (row: ROW_ID | ROW) => {
      setRows((prevState) => {
        return ArrayUtils.copyAndRemoveByPredicate(prevState, (it) =>
          typeof row === "number"
            ? it.id === row
            : typeof row === "string"
            ? it.id === row
            : it.id === row.id
        );
      });
    },
    [setRows]
  );

  const handleRowsChange = useCallback(
    (rows: Array<ROW>) => {
      setRows(rows);
    },
    [setRows]
  );

  return useMemo(() => {
    return {
      rows,
      selectedRowId,
      setRows,
      prependRow,
      replaceRow,
      deleteRow,
      setSelectedRowId,
      query,
      handleRowsChange,
    };
  }, [
    rows,
    selectedRowId,
    query,
    setRows,
    prependRow,
    replaceRow,
    deleteRow,
    setSelectedRowId,
    handleRowsChange,
  ]);
};
