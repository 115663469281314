import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { CustomerEnvironment } from "@airmont/firefly/shared/ts/customer-environment";

export class SystemDao {
  fetchHelper: FetchHelper;

  constructor(args?: { accessToken?: string }) {
    this.fetchHelper = new FetchHelper({ basePath: "/api/system/" });
    if (args?.accessToken != null) {
      this.fetchHelper.authorizationBearer(args.accessToken);
    }
  }

  async ping(): Promise<string> {
    return await this.fetchHelper.get("ping").fetchAsText();
  }

  getBuildNumber = async (): Promise<string> => {
    return await this.fetchHelper.get("get-build-number").fetchAsText();
  };

  getCustomerEnvironments(): Promise<Array<CustomerEnvironment>> {
    return this.fetchHelper.get("customer-environments").fetchAsJson();
  }
}
