import { FC } from "react";
import { Stack, SxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSxMerge } from "shared-ts-mui";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";

export interface MapLoadingProgressProps {
  sx?: SxProps;
}

export const MapLoadingProgress: FC<MapLoadingProgressProps> = (props) => {
  const { t } = useTranslation("shared-ts-ui-react-google-maps");
  const sx = useSxMerge(props.sx, {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  });
  return (
    <Stack sx={sx} direction={"row"}>
      <Typography>{t("Loading Map")}</Typography>
      <DotsProgress />
    </Stack>
  );
};
