export const RsrpThresholds = {
  max: 0,
  excellent: {
    min: -80,
  },
  good: {
    min: -90,
  },
  weak: {
    min: -100,
  },
  veryLow: {
    min: -110,
  },
  min: -110,
};
export const RsrpLevelDictionary = (value: number) => ({
  excellent: value >= -80,
  good: value >= -90 && value < -80,
  weak: value >= -100 && value < -90,
  veryLow: value < -100,
});
