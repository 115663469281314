import React, { CSSProperties, FC, ReactNode, useState } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import { Box, styled, Tab, useTheme } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import {
  SizeClass,
  SizeClassComparer,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { TabPanel } from "@airmont/shared/ts/ui/tabs";
import { ResizeHandle } from "@airmont/shared/ts/ui/react-resizeable-panels";
import { NullError } from "@airmont/shared/ts/utils/core";

type LayoutType = "resizable" | "tabs" | "standard";

const TabsContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .TabPanel-scrollableY {
    flex-grow: 1;
  }
`;

export interface PanelLayoutProps {
  layout?: LayoutType;
  resizable?: boolean;
  panels: Array<ReactNode>;
  autoSaveId: string;
  panelProps: Array<{
    title: string;
    style?: CSSProperties;
    tabPanelOptions?: {
      scrollableY?: boolean;
    };
    resizableOptions?: {
      defaultSize?: number | null;
      maxSize?: number;
      minSize?: number;
    };
  }>;
}

export const PanelLayout: FC<PanelLayoutProps> = (props) => {
  if (props.panels == null) {
    throw new NullError("panels");
  }
  if (props.panelProps == null) {
    throw new NullError("panelProps");
  }
  const windowWidthComparer = useWindowWidthComparer();
  const theme = useTheme();
  const [tab, setTab] = useState<number>(0);

  const selectedTab = `${tab}`;

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value);
  };
  const resizable = props.resizable ?? false;
  const layout: LayoutType = resolveLayoutType(
    windowWidthComparer,
    resizable,
    props.layout
  );

  if (layout === "tabs") {
    return (
      <TabsContainer className={"TabsContainer"}>
        <TabContext value={selectedTab}>
          <Box
            className={"TabLisWrapper"}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList onChange={handleTabChange}>
              {props.panels.map((panel, index) => {
                const panelProps = props.panelProps[index];
                return (
                  <Tab
                    key={panelProps.title}
                    label={panelProps.title}
                    value={`${index}`}
                  />
                );
              })}
            </TabList>
          </Box>
          {props.panels.map((panel, index) => {
            const panelProps = props.panelProps[index];
            const scrollableY =
              panelProps.tabPanelOptions?.scrollableY ?? false;
            return (
              <TabPanel
                key={panelProps.title}
                scrollableY={scrollableY}
                tabValue={`${index}`}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  minHeight: 0,
                  minWidth: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  paddingBottom: theme.spacing(1),
                }}
                style={panelProps.style}
              >
                {panel}
              </TabPanel>
            );
          })}
        </TabContext>
      </TabsContainer>
    );
  } else if (layout === "resizable") {
    return (
      <PanelGroup
        direction={"horizontal"}
        autoSaveId={props.autoSaveId}
        style={{ color: theme.palette.text.secondary }}
      >
        {props.panels.map((panel, index) => {
          const panelProps = props.panelProps[index];
          return (
            <React.Fragment key={panelProps.title}>
              <Panel
                style={panelProps.style}
                defaultSize={
                  panelProps.resizableOptions?.defaultSize ?? undefined
                }
                maxSize={panelProps.resizableOptions?.maxSize}
                minSize={panelProps.resizableOptions?.minSize}
              >
                {panel}
              </Panel>
              {index < props.panels.length - 1 && (
                <ResizeHandle key={"_resizeHandle"} direction={"horizontal"} />
              )}
            </React.Fragment>
          );
        })}
      </PanelGroup>
    );
  } else {
    throw new Error("Layout not supported: " + layout);
  }
};

const resolveLayoutType = (
  windowWidthComparer: SizeClassComparer,
  resizable: boolean,
  layout: LayoutType | undefined
): LayoutType => {
  if (layout != null) {
    return layout;
  }

  const mediumOrLess = windowWidthComparer.lte(SizeClass.Medium);
  return mediumOrLess ? "tabs" : "resizable";
};
