import { ServerEnvironment } from "./ServerEnvironment";

export class ServerEnvironmentResolver {
  static resolveFromWindowLocation(): ServerEnvironment {
    const hostnameSplitted = window.location.hostname.split(".");
    const environment =
      hostnameSplitted.length > 0
        ? hostnameSplitted[0]
        : window.location.hostname;

    if (environment === "localhost") {
      return "local";
    }
    if (environment.endsWith("-dev")) {
      return "dev";
    }
    if (environment.endsWith("-qa")) {
      return "qa";
    }
    return "prod";
  }
}
