import React, { FC } from "react";
import { Box, Button, Stack, Toolbar } from "@mui/material";
import { WhatsNewPane } from "./WhatsNewPane";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";

export interface WhatsNewPageProps {
  sx?: SxProps;
  onClose: () => void;
}

export const WhatsNewPage: FC<WhatsNewPageProps> = (props) => {
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const handleCloseClick = () => {
    props.onClose();
  };

  return (
    <Stack className={"WhatsNewPage"} direction={"column"} sx={props.sx}>
      <Box
        sx={{
          flex: 1,
          position: "relative",
        }}
      >
        <WhatsNewPane
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: "auto",
          }}
        />
      </Box>
      <Toolbar
        sx={{
          justifyContent: "center",
        }}
      >
        <Button onClick={handleCloseClick}>{t("Close")}</Button>
      </Toolbar>
    </Stack>
  );
};
