import { FC, useContext, useEffect, useState } from "react";
import { LoginAction } from "../authorization/LoginAction";
import { Alert, Box, Typography } from "@mui/material";
import { SignInResult } from "../authorization/SignInResult";
import { SignInOptions } from "../authorization/SignInOptions";
import {
  AuthenticationResultStatus,
  OidcContext,
  OidcLoginActions,
  OidcQueryParameterNames,
  useOidcPaths,
} from "@airmont/firefly/shared/ts/authentication-oidc";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { useTranslation } from "react-i18next";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";

export interface LoginPageProps {
  action: LoginAction;
  returnUrl?: string | undefined;
}

export const LoginPage: FC<LoginPageProps> = (props) => {
  const appInfo = useAppInfo();
  const { t } = useTranslation("app");
  const authService = useContext(OidcContext);
  const paths = useOidcPaths();
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const getReturnUrl = (state?: { returnUrl: string }) => {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(OidcQueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error(
          "Invalid return url. The return url needs to have the same origin as the current page."
        );
      }
      return (
        (state && state.returnUrl) ||
        fromQuery ||
        props.returnUrl ||
        `${window.location.origin}/`
      );
    };

    const login = async (returnUrl: string) => {
      const state: SignInOptions = {
        returnUrl: returnUrl,
      };
      console.log(
        "LoginPage: calling authService.signIn with returnUrl: " + returnUrl
      );
      const result = (await authService.signIn(state)) as SignInResult;

      console.log("LoginPage: got response from authService.SignIn: ", result);

      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message ?? _throw(new NullError("result.message")));
          break;
        default:
          throw new Error(`Invalid status result ${result.status}.`);
      }
    };

    const processLoginCallback = async () => {
      console.log("LoginPage: processLoginCallback...");
      const url = window.location.href;
      console.log(
        "LoginPage: processLoginCallback calling authService.completeSignIn with url: " +
          url
      );
      const result = (await authService.completeSignIn(url)) as SignInResult;

      console.log(
        "LoginPage: processLoginCallback got response from authService.completeSignIn with url: " +
          url,
        result
      );
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error("Should not redirect.");
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(getReturnUrl(result.state));
          // TODO : try usinsg react router navigate instead here
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message!);
          break;
        default:
          throw new Error(
            `Invalid authentication result status '${result.status}'.`
          );
      }
    };
    const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
      const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
      // It's important that we do a replace here so that when the user hits the back arrow on the
      // browser he gets sent back to where it was on the app instead of to an endpoint on this
      // component.
      console.log("LoginPage redirectToApiAuthorizationPath: " + redirectUrl);
      window.location.replace(redirectUrl);
    };
    const redirectToProfile = () => {
      redirectToApiAuthorizationPath(paths?.IdentityManagePath);
    };
    const redirectToRegister = () => {
      console.log("LoginPage redirectToRegister: ");
      redirectToApiAuthorizationPath(
        `${paths?.IdentityRegisterPath}?${
          OidcQueryParameterNames.ReturnUrl
        }=${encodeURI(paths.Login)}`
      );
    };

    if (props.action === LoginAction.Login) {
      console.log("LoginPage handling action Login..");
      login(getReturnUrl());
    } else if (props.action === LoginAction.LoginCallback) {
      console.log("LoginPage handling action LoginCallback..");
      processLoginCallback();
    } else if (props.action === LoginAction.LoginFailed) {
      console.log("LoginPage handling action LoginFailed..");
      const params = new URLSearchParams(window.location.search);
      const error = params.get(OidcQueryParameterNames.Message);
      setMessage(error);
    } else if (props.action === LoginAction.Profile) {
      console.log("LoginPage handling action Profile..");
      redirectToProfile();
    } else if (props.action === LoginAction.Register) {
      console.log("LoginPage handling action Register..");
      redirectToRegister();
    } else {
      throw new Error(`Invalid action '${props.action}'`);
    }
  }, [
    props.action,
    paths?.IdentityManagePath,
    paths?.IdentityRegisterPath,
    props.returnUrl,
    paths.Login,
    authService,
  ]);

  return (
    <NavigationLessAppPage appName={appInfo.name} appIcon={<AirmontLogoImg />}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {message != null && <Alert severity={"error"}>{message}</Alert>}
        {message == null && props.action === OidcLoginActions.Login && (
          <>
            <Typography>{t("Taking you to the login page")} </Typography>
            <DotsProgress />
          </>
        )}
        {message == null && props.action === OidcLoginActions.LoginCallback && (
          <>
            <Typography>{t("Logging in")} </Typography>
            <DotsProgress />
          </>
        )}
        {message == null && props.action === OidcLoginActions.LoginFailed && (
          <Alert severity={"error"}>{t("Log in failed")}</Alert>
        )}
      </Box>
    </NavigationLessAppPage>
  );
};

const navigateToReturnUrl = (returnUrl: string) => {
  // It's important that we do a replace here so that we remove the callback uri with the
  // fragment containing the tokens from the browser history.
  console.log("LoginPage changing location to : " + returnUrl);
  window.location.replace(returnUrl);
};
