import { ClusterPinRenderProps } from "shared-ts-ui-react-google-maps";
import React, { ReactNode, useMemo } from "react";
import { PieChart } from "@mui/x-charts";
import { useTheme } from "@mui/material";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { PieCenterLabel } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { SensorStatusGeoFeature } from "./SensorStatusGeoFeature";
import { ChimneySensorStatusEnum } from "@airmont/firefly/shared/ts/domain";
import { MathUtils } from "@airmont/shared/ts/utils/core";

export interface SensorStatusClusterPinProps
  extends ClusterPinRenderProps<SensorStatusGeoFeature> {
  numberOfSensors: number;
}

export const SensorStatusClusterPin = (
  props: SensorStatusClusterPinProps
): ReactNode => {
  const { features, numberOfSensors } = props;
  const theme = useTheme();
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const sensors = useMemo<ChimneySensorStatusDto[]>(() => {
    const array: Array<ChimneySensorStatusDto> = [];
    features.forEach((feature: SensorStatusGeoFeature) => {
      array.push(...feature.properties.sensors);
    });
    return array;
  }, [features]);

  const totalAffected = sensors.length;

  const countStatusActive = sensors.filter(
    (it) => it.status === ChimneySensorStatusEnum.Active
  ).length;
  const countStatusStarting = sensors.filter(
    (it) => it.status === ChimneySensorStatusEnum.Starting
  ).length;
  const countStatusFailedToStart = sensors.filter(
    (it) => it.status === ChimneySensorStatusEnum.FailedToStart
  ).length;
  const countStatusNotCommunicating = sensors.filter(
    (it) => it.status === ChimneySensorStatusEnum.NotCommunicating
  ).length;
  const countStatusRetired = sensors.filter(
    (it) => it.status === ChimneySensorStatusEnum.Retired
  ).length;
  const countStatusDead = sensors.filter(
    (it) => it.status === ChimneySensorStatusEnum.Dead
  ).length;

  const size = Math.max(
    MathUtils.round(
      (totalAffected / numberOfSensors) * (maxSize - minSize) + minSize
    ),
    minSize
  );
  const innerRadius = size * 0.3;
  const fontSize = Math.max(
    16,
    Math.round((totalAffected / numberOfSensors) * 30)
  );
  return (
    <PieChart
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      skipAnimation
      slotProps={{ legend: { hidden: true } }}
      series={[
        {
          innerRadius: innerRadius,
          data: [
            {
              id: 0,
              value: countStatusActive + countStatusStarting,
              label: tFireflySharedDomain(
                `ChimneySensorStatus.${ChimneySensorStatusEnum.Active}`
              ),
              color: theme.palette.success.main,
            },
            {
              id: 1,
              value: countStatusNotCommunicating,
              label: tFireflySharedDomain(
                `ChimneySensorStatus.${ChimneySensorStatusEnum.NotCommunicating}`
              ),
              color: theme.palette.warning.main,
            },
            {
              id: 2,
              value: countStatusDead,
              label: tFireflySharedDomain(
                `ChimneySensorStatus.${ChimneySensorStatusEnum.Dead}`
              ),
              color: theme.palette.error.main,
            },
            {
              id: 3,
              value: countStatusRetired,
              label: tFireflySharedDomain(
                `ChimneySensorStatus.${ChimneySensorStatusEnum.Retired}`
              ),
              color: theme.palette.error.main,
            },
            {
              id: 4,
              value: countStatusFailedToStart,
              label: tFireflySharedDomain(
                `ChimneySensorStatus.${ChimneySensorStatusEnum.FailedToStart}`
              ),
              color: theme.palette.error.main,
            },
          ],
        },
      ]}
      width={size}
      height={size}
    >
      <PieCenterLabel fontSize={fontSize}>{sensors.length}</PieCenterLabel>
    </PieChart>
  );
};

const minSize = 35;
const maxSize = 100;
