import React from "react";
import {
  CustomerEnvironment,
  CustomerEnvironmentId,
} from "./CustomerEnvironment";

export interface IEnvironmentContext {
  environmentId?: CustomerEnvironmentId;
  environment?: CustomerEnvironment;
  environmentList: Array<CustomerEnvironment>;
  setEnvironment: (env: CustomerEnvironmentId) => void;
}
export const EnvironmentContext = React.createContext(
  {} as IEnvironmentContext
);
