import { isValueOption } from "./ValueOption";
import { ValueOption } from "./ValueOption";
import { ValueOptions } from "./ValueOptions";
import { useMemo } from "react";

export class OptionLabelResolver {
  private valueOptions: ValueOptions;
  constructor(valueOptions: ValueOptions) {
    this.valueOptions = valueOptions;
  }
  getOptionLabel = (option: string | ValueOption): string => {
    if (
      typeof option === "string" &&
      this.valueOptions.options.length > 0 &&
      isValueOption(this.valueOptions.options[0])
    ) {
      const valueOption = this.valueOptions.options.find((it) =>
        isValueOption(it) ? it.id === option : it === option
      );
      if (valueOption != null && isValueOption(valueOption)) {
        return valueOption.label;
      } else if (valueOption != null) {
        return valueOption;
      }
    }

    return typeof option === "string" ? option : option.label;
  };
}

export const useOptionLabelResolver = (
  valueOptions: ValueOptions
): OptionLabelResolver => {
  return useMemo(() => new OptionLabelResolver(valueOptions), [valueOptions]);
};
