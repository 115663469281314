import React, { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";
import { BootError } from "./BootError";
import { NavigationLessAppPage } from "./NavigationLessAppPage";
import { useTranslation } from "react-i18next";

export interface BootPageProps {
  appIcon?: ReactNode;
  booting?: string;
  appName: ReactNode;
  messages: Array<string>;
  error?: Error;
}

export const BootPage: FC<BootPageProps> = (props) => {
  const { t } = useTranslation("shared-ts-ui-app-boot");

  return (
    <NavigationLessAppPage appName={props.appName} appIcon={props.appIcon}>
      <Typography>
        {props.booting != null ? props.booting : t("Booting")}
        {props.error && ` ${t("failed")}`}:
      </Typography>
      <Box sx={{ pl: 1 }}>
        {props.messages.length > 0 && (
          <Typography>
            {props.messages[props.messages.length - 1]}
            {!props.error && <DotsProgress length={5} />}
          </Typography>
        )}
        {props.error && <BootError error={props.error} />}
      </Box>
    </NavigationLessAppPage>
  );
};
