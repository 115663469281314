import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { SweepingDto, SweepingId } from "./SweepingDto";
import { SweepingCreateDto } from "./SweepingCreateDto";
import { SweepingUpdateDto } from "./SweepingUpdateDto";

export class SweepingDao {
  private readonly accessToken: string;
  private readonly customerEnvironment: string;
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.accessToken = args.accessToken;
    this.customerEnvironment = args.customerEnvironment;
    this.fetchHelper = new FetchHelper({ basePath: "/api/sweeping/" })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  create(create: SweepingCreateDto): Promise<SweepingDto> {
    return this.fetchHelper.post("create", create).fetchAsJson<SweepingDto>();
  }

  update(update: SweepingUpdateDto): Promise<SweepingDto> {
    return this.fetchHelper.post("update", update).fetchAsJson<SweepingDto>();
  }

  delete(id: SweepingId): Promise<void> {
    return this.fetchHelper.delete(`delete/${id}`).fetch();
  }

  getById(id: SweepingId): Promise<SweepingDto> {
    return this.fetchHelper.get(`get-by-id/${id}`).fetchAsJson<SweepingDto>();
  }

  getListByFlue(flueId: string): Promise<Array<SweepingDto>> {
    return this.fetchHelper
      .get(`get-by-flue/${flueId}`)
      .fetchAsJson<Array<SweepingDto>>();
  }
}
