import {
  FlueId,
  MunicipalityId,
  PostalCodeId,
} from "@airmont/firefly/shared/ts/domain";
import { StringUtils } from "@airmont/shared/ts/utils/core";
import { QueryDto, SortItemDto } from "shared/ts/utils/query";

export interface FlueStatusQueryDto extends QueryDto {
  municipality?: MunicipalityId;
  postalCode?: PostalCodeId;
  search?: string;
  id?: string;
  sort?: Array<SortItemDto>;
}

export type FlueStatusQueryInput = {
  municipality?: MunicipalityId | null;
  postalCode?: PostalCodeId | null;
  id?: FlueId | null;
  search?: string | null;
  sort?: Array<SortItemDto> | null;
};

export class FlueStatusQuery {
  readonly municipality?: MunicipalityId;
  readonly postalCode?: PostalCodeId;
  readonly id?: FlueId;
  readonly search?: string;
  readonly sort?: Array<SortItemDto>;

  constructor(args: FlueStatusQueryInput) {
    this.municipality = StringUtils.notEmptyOrUndef(args.municipality);
    this.postalCode = StringUtils.notEmptyOrUndef(args.postalCode);
    this.id = StringUtils.notEmptyOrUndef(args.id);
    this.search = StringUtils.notEmptyOrUndef(args.search);
    this.sort = args.sort ?? undefined;
  }

  toDto(): FlueStatusQueryDto {
    return {
      municipality: this.municipality,
      postalCode: this.postalCode,
      id: this.id,
      search: this.search,
      sort: this.sort,
    };
  }
}
