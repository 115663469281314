import {
  ChimneySensorCableStatusEnum,
  ChimneySensorDto,
  ChimneySensorPlacementStringEnum,
  ChimneySensorStatusEnum,
} from "@airmont/firefly/shared/ts/domain";

export const getChimneySensorMocks = (): Array<ChimneySensorDto> => {
  return [
    {
      id: "123123",
      flueId: "078d6e0a-5993-400b-81fc-0cd4e5d63cd7",
      model: "CH01.6.11B",
      comment: "Her bor Jørund",
      retired: false,
      tags: "",
      placement: ChimneySensorPlacementStringEnum.Roof,
      uptime: 17376769,
      lastHeard: "2023-06-27T15:10:29.000000Z",
      status: ChimneySensorStatusEnum.Active,
      cableStatus: ChimneySensorCableStatusEnum.Ok,
      board: {
        temperature: 24,
        voltage: 3093,
      },
      signal: {
        strength: -69,
        refReceivedPower: -96,
        interferenceNoiseRatio: 0,
        refReceivedQuality: -14,
      },
      audit: {
        createdBy: "jvs",
        created: "2022-09-27 12:54:21.287823 +00:00",
        modifiedBy: "jvs",
        modified: "2023-03-17 08:35:43.831828 +00:00",
      },
    },
  ];
};
