import { Property, PropertyProps } from "shared-ts-property";
import React, { FC } from "react";
import { YesNoUnknownStringEnum } from "../../YesNoUnknownEnum";
import { useTranslation } from "react-i18next";

export type FireplaceCatalyticPropertyProps = Omit<
  PropertyProps,
  "name" | "label" | "type" | "valueOptions"
>;
export const FireplaceCatalyticProperty: FC<FireplaceCatalyticPropertyProps> = (
  props
) => {
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  return (
    <Property
      {...props}
      name="fireplaceCatalytic"
      label={tFireflySharedDomain("Clean-burning Fireplace")}
      type={"string"}
      valueOptions={{
        multiple: false,
        restrictToOptions: true,
        options: [
          {
            id: YesNoUnknownStringEnum.Yes,
            label: tFireflySharedDomain(
              `YesNoUnknown.${YesNoUnknownStringEnum.Yes}`
            ),
          },
          {
            id: YesNoUnknownStringEnum.No,
            label: tFireflySharedDomain(
              `YesNoUnknown.${YesNoUnknownStringEnum.No}`
            ),
          },
          {
            id: YesNoUnknownStringEnum.Unknown,
            label: tFireflySharedDomain(
              `YesNoUnknown.${YesNoUnknownStringEnum.Unknown}`
            ),
          },
        ],
      }}
    />
  );
};
