import React, { FC, useMemo } from "react";
import { Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { merge } from "lodash";
import { BurnNonSensitiveUi } from "@airmont/firefly/shared/ts/domain";
import { useTranslation } from "react-i18next";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { Duration } from "luxon";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";

export interface BurnInfoMultipleProps {
  burns: Array<BurnNonSensitiveUi>;
  sx?: SxProps;
}

export const BurnInfoMultiple: FC<BurnInfoMultipleProps> = (props) => {
  const { burns } = props;
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const totalDuration = useMemo(() => {
    return Duration.fromMillis(
      burns.reduce((a, b) => {
        return a + b.duration.toMillis();
      }, 0)
    ).shiftToAll();
  }, [burns]);
  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      position: "relative",
      overflowX: "auto",
      minHeight: "40px",
      alignItems: "center",
    } as SxProps);
  }, [props.sx]);

  return (
    <Stack direction={"row"} useFlexGap gap={1} sx={sx}>
      <Typography color={"text.secondary"}>
        {burns.length} {tFireflySharedDomain("Burns").toLowerCase()}:
      </Typography>
      <Tooltip title={tFireflySharedDomain("Burn Duration")}>
        <TimelapseRoundedIcon />
      </Tooltip>
      <Typography>≈</Typography>
      <DurationDisplay
        duration={totalDuration}
        maxUnits={2}
        shiftTo={["hours", "minutes"]}
        layout={SizeClass.Medium}
      />
    </Stack>
  );
};
