import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { useResolveXAxis } from "./useResolveXAxis";
import { useResolveYAxis } from "./useResolveYAxis";
import { resolveYAxisTooltipFormatter } from "./resolveYAxisTooltipFormatter";
import { resolveXAxisTooltipFormatter } from "./resolveXAxisTooltipFormatter";
import { TimeSeriesChartOptions } from "./TimeSeriesChartOptions";
import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";

export const useResolveApexOptions = <PV>(args: {
  timeSeries: Array<TimeSeries<PV>>;
  options?: TimeSeriesChartOptions;
  theme: Theme;
}): ApexOptions => {
  const xAxis = useResolveXAxis(args.options, args.theme);
  const yAxis = useResolveYAxis(args.options, args.theme);

  return useMemo(
    () => ({
      chart: {
        type: args.options?.chart?.type,
        animations: {
          enabled: true,
          easing: "linear",
          animateGradually: {
            enabled: true,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 500,
          },
        },
      },
      annotations: args.options?.annotations ?? {},
      markers: {
        /* TODO: Remove? */ size: [1],
        strokeWidth: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: xAxis,
      yaxis: yAxis,
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        x: {
          formatter: (value, apexOptions) =>
            resolveXAxisTooltipFormatter(value, apexOptions, args.options),
        },
        y: args.timeSeries.map((it) => {
          return {
            title: it.info.displayName,
            formatter: (value, opts) =>
              resolveYAxisTooltipFormatter(value, opts, args.options),
          } as ApexTooltipY;
        }),
      },
      legend: {
        show: args.options?.legend?.show,
        position: args.options?.legend?.position ?? "bottom",
      },
      noData: {
        text: args.options?.noData?.text,
      },
    }),
    [args.timeSeries, args.options, args.theme]
  );
};
