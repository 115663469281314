import React, { FC } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { FlueMetricValuesTableRowHeader } from "./FlueMetricValuesTableRowHeader";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { FlueMetricValuesTableRow } from "./FlueMetricValuesTableRow";
import { FlueMetricValues } from "@airmont/firefly/shared/ts/domain";
import { SxProps } from "@mui/system";

export interface FlueMetricsTableProps {
  layout: SizeClass;
  paper?: boolean;
  metrics: Array<{ label: string; values?: FlueMetricValues }>;
  sx?: SxProps;
}
export const FlueMetricsTable: FC<FlueMetricsTableProps> = (props) => {
  return (
    <TableContainer
      sx={props.sx}
      component={props.paper === true ? Paper : Box}
    >
      <Table size={"small"}>
        <TableHead>
          <FlueMetricValuesTableRowHeader
            compact={props.layout === SizeClass.Compact}
          />
        </TableHead>
        <TableBody>
          {props.metrics.map(({ label, values }) => {
            return (
              <FlueMetricValuesTableRow
                label={label}
                values={values ?? defaultFlueMetricValues}
                layout={props.layout}
                key={label}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const defaultFlueMetricValues: FlueMetricValues = {
  burnCount: 0,
  burnHourCount: 0,
  sootIndex: 0,
  temperatureMax: 0,
  chimneyFireCount: 0,
};
