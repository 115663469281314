import { DateTime, Duration, Interval, Settings } from "luxon";
import { MathUtils, UnsupportedError } from "@airmont/shared/ts/utils/core";

export class DurationUtils {
  static fromDates(start: DateTime, end: DateTime): Duration {
    return Interval.fromDateTimes(start, end).toDuration();
  }
  static asBiggestDominatingUnit(
    duration: Duration,
    roundingPrecision?: number
  ): number {
    const rescaled = duration.rescale();

    if (rescaled.years > 0) {
      return MathUtils.round(rescaled.as("years"), roundingPrecision ?? 0);
    } else if (rescaled.months > 0) {
      return MathUtils.round(rescaled.as("months"), roundingPrecision ?? 0);
    } else if (rescaled.days > 0) {
      return MathUtils.round(rescaled.as("days"), roundingPrecision ?? 0);
    } else if (rescaled.hours > 0) {
      return MathUtils.round(rescaled.as("hours"), roundingPrecision ?? 0);
    } else if (rescaled.minutes > 0) {
      return MathUtils.round(rescaled.as("minutes"), roundingPrecision ?? 0);
    } else if (rescaled.seconds > 0) {
      return MathUtils.round(rescaled.as("seconds"), roundingPrecision ?? 0);
    } else {
      return MathUtils.round(
        duration.as("milliseconds"),
        roundingPrecision ?? 0
      );
    }
  }

  static asBiggestDominatingUnitFormatted(
    duration: Duration,
    roundingPrecision?: number
  ): [value: string, unit: string] {
    const rescaled = duration.rescale();
    const rtf = new Intl.RelativeTimeFormat(Settings.defaultLocale ?? "en", {
      style: "short",
    });
    if (rescaled.years > 0) {
      const formatted = rtf.formatToParts(rescaled.years, "years");
      return [formatted[1].value, formatted[2].value];
    } else if (rescaled.months > 0) {
      const formatted = rtf.formatToParts(rescaled.months, "months");
      return [formatted[1].value, formatted[2].value];
    } else if (rescaled.days > 0) {
      const formatted = rtf.formatToParts(rescaled.days, "days");
      return [formatted[1].value, formatted[2].value];
    } else if (rescaled.hours > 0) {
      const formatted = rtf.formatToParts(rescaled.hours, "hours");
      return [formatted[1].value, formatted[2].value];
    } else if (rescaled.minutes > 0) {
      const formatted = rtf.formatToParts(rescaled.minutes, "minutes");
      return [formatted[1].value, formatted[2].value];
    } else if (rescaled.seconds > 0) {
      const formatted = rtf.formatToParts(rescaled.seconds, "seconds");
      return [formatted[1].value, formatted[2].value];
    } else {
      return ["0", "s"];
      //throw new UnsupportedError("duration < 1 sec not supported");
    }
  }
}
