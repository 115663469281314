import { SettingConfig } from "../SettingConfig";
import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { SettingKey } from "../SettingKeyDto";
import { AsyncSettingsStorage } from "./AsyncSettingsStorage";
import { AppKey } from "shared-ts-app-info";
import { UserSettingDto } from "./UserSetting";
import { SettingOverrides } from "../SettingOverrides";
import { AccessToken } from "shared-ts-utils-authentication";

export interface ServerStorageOptions {
  baseApiPath?: string;
  accessToken?: AccessToken;
}

export class ServerSettingsStorage implements AsyncSettingsStorage {
  public readonly type: "Async";
  private readonly fetchHelper;

  public constructor(options?: ServerStorageOptions) {
    this.fetchHelper = new FetchHelper({
      basePath: options?.baseApiPath ?? "/api/user-settings/",
    });
    this.fetchHelper.authorizationBearer(options?.accessToken);
    this.type = "Async";
  }

  loadSettings(
    appKey: AppKey,
    user: string | null | undefined
  ): Promise<Array<UserSettingDto>> {
    return this.fetchHelper
      .get(`load?app=${appKey}&user=${user}`)
      .fetchAsJson();
  }

  async readValue<Type>(
    key: SettingKey,
    config: SettingConfig<NonNullable<Type>>,
    options?: SettingOverrides<Type>
  ): Promise<Type> {
    const value = await this.fetchHelper
      .get(`get?app=${key.app}&user=${key.user}&name=${key.name}`)
      .fetchAsJson<string | null>();

    const valueDeserialized =
      value != null
        ? options?.deserialize != null
          ? options.deserialize(value)
          : config.deserialize(value)
        : null;

    return Promise.resolve(valueDeserialized as Type);
  }

  writeValue<Type>(
    key: SettingKey,
    newValue: Type,
    config: SettingConfig<NonNullable<Type>>,
    overrides?: SettingOverrides<Type>
  ): Promise<void> {
    const newValueSerialized =
      newValue != null
        ? overrides?.serialize != null
          ? overrides.serialize(newValue)
          : config.serialize(newValue)
        : null;

    return this.fetchHelper
      .post("set", {
        app: key.app,
        user: key.user,
        name: key.name,
        value: newValueSerialized,
        type: config.type,
      })
      .fetch();
  }
}
