import { FC, ReactNode } from "react";
import { useLanguageInternal } from "./useLanguageInternal";
import i18next from "i18next";
import { Settings } from "luxon";

export interface LanguageProviderProps {
  defaultLanguage: string;
  children: (language: string) => ReactNode;
}

export const LanguageUserSettingProvider: FC<LanguageProviderProps> = (
  props
) => {
  const { language } = useLanguageInternal(props.defaultLanguage);
  console.info("Setting i18next language to : " + language);
  i18next.changeLanguage(language);
  let locale = language;
  if (language === "nn-NO") {
    // nn-NO is not supported by Intl, fallback to
    locale = "nb-NO";
  }
  console.info("Setting Luxon defaultLocale to : " + locale);
  Settings.defaultLocale = locale;
  return props.children(language);
};
