import {DateTime, DateTimeUnit} from "luxon";

export class ChimneySensorUtil {
    static calculateTimeForLastCommunication = (unit?: DateTimeUnit): DateTime => {
        const now = DateTime.now();
        let lastReceived = now.minus({hours: 24});
        if (unit != null) {
            lastReceived = lastReceived.startOf(unit);
        }
        return lastReceived;
    }
}