import React, { useMemo } from "react";
import { Box, LinearProgress, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import {
  BatteryLevelColors,
  BatteryLevelThresholds,
  ChimneySensorDto,
} from "@airmont/firefly/shared/ts/domain";
import {
  TimeSeries,
  TimeSeriesImpl,
} from "@airmont/firefly/shared/ts/timeseries";
import { useQuery } from "@tanstack/react-query";
import { useDeviceHealthDao } from "./useDeviceHealthDao";
import { useTranslation } from "react-i18next";
import { merge } from "lodash";
import {
  TimeSeriesChart,
  TimeSeriesOption,
} from "@airmont/firefly/shared/ts/timeseries-apex-chart";

export interface ChimneySensorBatteryVoltageChartProps {
  sensor: ChimneySensorDto;
  sx?: SxProps<Theme>;
}

export const ChimneySensorBatteryVoltageChart = (
  props: ChimneySensorBatteryVoltageChartProps
) => {
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const theme = useTheme();
  const deviceHealthDao = useDeviceHealthDao();

  const { data: timeData, isLoading } = useQuery({
    queryKey: ["DeviceHealthDao.getList", props.sensor.flueId],
    queryFn: () => deviceHealthDao.getList(props.sensor.flueId),
  });

  const timeDataSeries: Array<TimeSeries<number>> | undefined = useMemo(() => {
    return timeData !== undefined
      ? [
          TimeSeriesImpl.fromDto({
            info: {
              id: props.sensor.id,
              sid: props.sensor.id,
              name: props.sensor.id,
              displayName: props.sensor.id,
              resolution: "",
              unit: "mV",
            },
            length: timeData.length,
            points: timeData.map((it) => {
              return {
                time: it.time,
                value: it.boardVoltage,
              };
            }),
          }),
        ]
      : undefined;
  }, [timeData, props.sensor]);

  const excellentVoltageAnnotation: YAxisAnnotations = createYAxisAnnotation({
    y: BatteryLevelThresholds.excellent,
    y2: 3250,
    color: BatteryLevelColors.Excellent,
    text: tFireflySharedDomain("BatteryLevel.Excellent"),
    getContrastText: theme.palette.getContrastText,
  });

  const goodVoltageAnnotation: YAxisAnnotations = createYAxisAnnotation({
    y: BatteryLevelThresholds.good,
    y2: BatteryLevelThresholds.excellent,
    color: BatteryLevelColors.Good,
    text: tFireflySharedDomain("BatteryLevel.Good"),
    getContrastText: theme.palette.getContrastText,
  });

  const poorVoltageAnnotation: YAxisAnnotations = createYAxisAnnotation({
    y: BatteryLevelThresholds.poor,
    y2: BatteryLevelThresholds.good,
    color: BatteryLevelColors.Poor,
    text: tFireflySharedDomain("BatteryLevel.Poor"),
    getContrastText: theme.palette.getContrastText,
  });

  const criticalVoltageAnnotation: YAxisAnnotations = createYAxisAnnotation({
    y: BatteryLevelThresholds.critical,
    y2: BatteryLevelThresholds.poor,
    color: BatteryLevelColors.Critical,
    text: tFireflySharedDomain("BatteryLevel.Critical"),
    getContrastText: theme.palette.getContrastText,
  });

  const yAxisAnnotations: Array<YAxisAnnotations> = [
    excellentVoltageAnnotation,
    goodVoltageAnnotation,
    poorVoltageAnnotation,
    criticalVoltageAnnotation,
  ];

  const sx = useMemo(
    () =>
      merge({}, props.sx, {
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }),
    [props.sx]
  );

  return (
    <Box sx={sx} className={"ChimneySensorBatteryVoltageChart"}>
      {isLoading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: "-3px",
            left: 0,
            right: 0,
            height: "2px",
          }}
        />
      )}
      {timeDataSeries !== undefined && (
        <TimeSeriesChart
          timeSeries={timeDataSeries}
          timeSeriesOptions={
            [
              {
                displayName: "Batterinivå",
              },
            ] as Array<TimeSeriesOption<number>>
          }
          options={{
            annotations: {
              yaxis: yAxisAnnotations,
            },
            yAxis: {
              max: 3200,
              min: 2400,
            },
          }}
        />
      )}
    </Box>
  );
};

const createYAxisAnnotation = (args: {
  y: number;
  y2: number;
  text: string;
  color: string;
  getContrastText: (background: string) => string;
}): YAxisAnnotations => {
  return {
    y: args.y,
    y2: args.y2,
    fillColor: args.color,
    label: {
      borderColor: args.color,
      offsetY: 25,
      style: {
        color: args.getContrastText(args.color),
        background: args.color,
      },
      text: args.text,
    },
  };
};
