import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export enum YesNoUnknownEnum {
  No = -1,
  Unknown = 0,
  Yes = 1,
}

export enum YesNoUnknownStringEnum {
  No = "No",
  Unknown = "Unknown",
  Yes = "Yes",
}

export const yesNoUnknownEnumToYesNoUnknownStringEnum = (
  value: YesNoUnknownEnum
): YesNoUnknownStringEnum => {
  if (value === YesNoUnknownEnum.No) {
    return YesNoUnknownStringEnum.No;
  } else if (value === YesNoUnknownEnum.Unknown) {
    return YesNoUnknownStringEnum.Unknown;
  } else if (value === YesNoUnknownEnum.Yes) {
    return YesNoUnknownStringEnum.Yes;
  } else {
    throw new IllegalArgumentError(value);
  }
};
