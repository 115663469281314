import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export const useAnonymizeIssueMonitoringDataWithNullAsDefault = () => {
  return useUserSettingWithDefault<boolean | null>(
    "anonymizeIssueMonitoringData",
    BooleanSetting,
    null
  );
};
