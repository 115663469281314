import { FC, ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { Day } from "./Day";
import { DateTime, Interval } from "luxon";
import { EventImpl } from "@airmont/shared-ts-event";
import { RenderEventParams } from "../RenderEventParams";
import { useCalendarSettings } from "../CalendarSettingsProvider";
import { CalendarTimeslotLabels } from "../CalendarTimeslotLabels";
import { CalendarHeader } from "../CalendarHeader";

export interface CalendarDayProps {
  date: DateTime<true>;
  dateTimeNow: DateTime<true>;
  isToday?: boolean;
  events: Array<EventImpl>;
  children?: ReactNode;
  renderEvent: (params: RenderEventParams) => ReactNode;
  onNewEvent?: (interval: Interval<true> | DateTime<true> | null) => void;
}
export const CalendarDay: FC<CalendarDayProps> = (props) => {
  const { date, dateTimeNow, events, renderEvent, onNewEvent } = props;
  const settings = useCalendarSettings();
  const theme = useTheme();
  const selectedDateIsToday =
    date.year === dateTimeNow.year &&
    date.month === dateTimeNow.month &&
    date.day === dateTimeNow.day;

  return (
    <>
      <CalendarHeader
        variant={"timeslots"}
        dateTimeNow={dateTimeNow}
        dates={[date]}
        selectedDate={date}
      />
      <Box
        className={"CalendarDayBody"}
        sx={{
          flexGrow: 1,
          minHeight: 0,
          display: "grid",
          gridAutoFlow: "column",
          gridAutoColumns: "60px minmax(0, 1fr)",
          overflowY: "auto",
          ".CalendarTimeslotLabels": {
            borderRight: "1px solid " + theme.palette.divider,
          },
        }}
      >
        <CalendarTimeslotLabels timeslotDuration={settings.timeslotDuration} />
        <Day
          variant={"timeslots"}
          date={date}
          dateTimeNow={dateTimeNow}
          isToday={selectedDateIsToday}
          timeslotDuration={settings.timeslotDuration}
          events={events}
          renderEvent={renderEvent}
          onNewEvent={onNewEvent}
        />
      </Box>
    </>
  );
};
