import React, { useMemo, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import {
  ChimneySensorDto,
  RsrpThresholds,
  RsrqThresholds,
  RssiThresholds,
  SignalStrengthLevel,
  SinrThresholds,
} from "@airmont/firefly/shared/ts/domain";
import {
  TimeSeries,
  TimeSeriesImpl,
} from "@airmont/firefly/shared/ts/timeseries";
import { useQuery } from "@tanstack/react-query";
import { useDeviceHealthDao } from "./useDeviceHealthDao";
import { useTranslation } from "react-i18next";
import {
  TimeSeriesChart,
  TimeSeriesOption,
} from "@airmont/firefly/shared/ts/timeseries-apex-chart";
import { IllegalStateError, notUndef } from "@airmont/shared/ts/utils/core";

type SignalMeasurementType = "rssi" | "rsrp" | "sinr" | "rsrq";
type Annotation = {
  displayName: string;
  max: number;
  min: number;
  color: string;
};
type Current = {
  timeSeries: Array<TimeSeries<number>> | undefined;
  displayName: string;
  shortName: string;
  unit?: string;
  max: number;
  min: number;
  annotations: Array<Annotation>;
};

export interface ChimneySensorRadioSignaChartProps {
  sensor: ChimneySensorDto;
  sx?: SxProps<Theme>;
}

export const ChimneySensorRadioSignaChart = (
  props: ChimneySensorRadioSignaChartProps
) => {
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const theme = useTheme();
  const deviceHealthDao = useDeviceHealthDao();

  const { data: timeData, isLoading } = useQuery({
    queryKey: ["DeviceHealthDao.getList", props.sensor.flueId],
    queryFn: () => deviceHealthDao.getList(props.sensor.flueId),
  });

  const timeDataSeriesRssi: TimeSeries<number> | undefined = useMemo(() => {
    return timeData !== undefined
      ? TimeSeriesImpl.fromDto({
          info: {
            id: props.sensor.id,
            sid: props.sensor.id,
            name: props.sensor.id,
            displayName: props.sensor.id,
            resolution: "",
            unit: "dBm",
          },
          length: timeData.length,
          points: timeData.map((it) => {
            return {
              time: it.time,
              value: it.signalStrength,
            };
          }),
        })
      : undefined;
  }, [timeData, props.sensor]);
  const timeDataSeriesRsrp: TimeSeries<number> | undefined = useMemo(() => {
    return timeData !== undefined
      ? TimeSeriesImpl.fromDto({
          info: {
            id: props.sensor.id,
            sid: props.sensor.id,
            name: props.sensor.id,
            displayName: props.sensor.id,
            resolution: "",
            unit: "dBm",
          },
          length: timeData.length,
          points: timeData.map((it) => {
            return {
              time: it.time,
              value: it.refSignalReceivedPower,
            };
          }),
        })
      : undefined;
  }, [timeData, props.sensor]);
  const timeDataSeriesSinr: TimeSeries<number> | undefined = useMemo(() => {
    return timeData !== undefined
      ? TimeSeriesImpl.fromDto({
          info: {
            id: props.sensor.id,
            sid: props.sensor.id,
            name: props.sensor.id,
            displayName: props.sensor.id,
            resolution: "",
            unit: "dB",
          },
          length: timeData.length,
          points: timeData.map((it) => {
            return {
              time: it.time,
              value: it.signalInterferenceNoiseRatio,
            };
          }),
        })
      : undefined;
  }, [timeData, props.sensor]);
  const timeDataSeriesRsrq: TimeSeries<number> | undefined = useMemo(() => {
    return timeData !== undefined
      ? TimeSeriesImpl.fromDto({
          info: {
            id: props.sensor.id,
            sid: props.sensor.id,
            name: props.sensor.id,
            displayName: props.sensor.id,
            resolution: "",
            unit: "dB",
          },
          length: timeData.length,
          points: timeData.map((it) => {
            return {
              time: it.time,
              value: it.refSignalReceivedQuality,
            };
          }),
        })
      : undefined;
  }, [timeData, props.sensor]);

  const [currentType, setCurrentType] = useState<SignalMeasurementType>("rsrp");
  const current: Current = useMemo(() => {
    if (currentType === "rsrp") {
      return {
        displayName: "Reference Signal Received Power",
        shortName: "RSRP",
        timeSeries: notUndef(timeDataSeriesRsrp, (it) => [it]),
        unit: timeDataSeriesRsrp?.info.unit,
        max: RsrpThresholds.max,
        min: RsrpThresholds.min,
        annotations: [
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Excellent"),
            max: RsrpThresholds.max,
            min: RsrpThresholds.excellent.min,
            color: SignalStrengthLevel.Excellent,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Good"),
            max: RsrpThresholds.excellent.min,
            min: RsrpThresholds.good.min,
            color: SignalStrengthLevel.Good,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Weak"),
            max: RsrpThresholds.good.min,
            min: RsrpThresholds.weak.min,
            color: SignalStrengthLevel.Weak,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.VeryLow"),
            max: RsrpThresholds.weak.min,
            min: RsrpThresholds.min,
            color: SignalStrengthLevel.VeryLow,
          },
        ],
      };
    } else if (currentType === "rssi") {
      return {
        displayName: "Received Signal Strength Indicator",
        shortName: "RSSI",
        timeSeries: notUndef(timeDataSeriesRssi, (it) => [it]),
        unit: timeDataSeriesRssi?.info.unit,
        max: RssiThresholds.max,
        min: RssiThresholds.min,
        annotations: [
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Excellent"),
            max: RssiThresholds.max,
            min: RssiThresholds.excellent.min,
            color: SignalStrengthLevel.Excellent,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Good"),
            max: RssiThresholds.excellent.min,
            min: RssiThresholds.good.min,
            color: SignalStrengthLevel.Good,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Medium"),
            max: RssiThresholds.good.min,
            min: RssiThresholds.medium.min,
            color: SignalStrengthLevel.Medium,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Weak"),
            max: RssiThresholds.medium.min,
            min: RssiThresholds.weak.min,
            color: SignalStrengthLevel.Weak,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Good"),
            max: RssiThresholds.weak.min,
            min: RssiThresholds.min,
            color: SignalStrengthLevel.NoSignal,
          },
        ],
      };
    } else if (currentType === "rsrq") {
      return {
        displayName: "Reference Signal Received Quality",
        shortName: "RSRQ",
        timeSeries: notUndef(timeDataSeriesRsrq, (it) => [it]),
        unit: timeDataSeriesRsrq?.info.unit,
        max: RsrqThresholds.max,
        min: RsrqThresholds.min,
        annotations: [
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Excellent"),
            max: RsrqThresholds.max,
            min: RsrqThresholds.excellent.min,
            color: SignalStrengthLevel.Excellent,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Good"),
            max: RsrqThresholds.excellent.min,
            min: RsrqThresholds.good.min,
            color: SignalStrengthLevel.Good,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Weak"),
            max: RsrqThresholds.good.min,
            min: RsrqThresholds.weak.min,
            color: SignalStrengthLevel.Weak,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.VeryLow"),
            max: RsrqThresholds.weak.min,
            min: RsrqThresholds.min,
            color: SignalStrengthLevel.VeryLow,
          },
        ],
      };
    } else if (currentType === "sinr") {
      return {
        displayName: "Signal Interference + Noise Ratio",
        shortName: "SINR",
        timeSeries: notUndef(timeDataSeriesSinr, (it) => [it]),
        unit: timeDataSeriesSinr?.info.unit,
        max: SinrThresholds.max,
        min: SinrThresholds.min,
        annotations: [
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Excellent"),
            max: SinrThresholds.max,
            min: SinrThresholds.excellent.min,
            color: SignalStrengthLevel.Excellent,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Good"),
            max: SinrThresholds.excellent.min,
            min: SinrThresholds.good.min,
            color: SignalStrengthLevel.Good,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.Weak"),
            max: SinrThresholds.good.min,
            min: SinrThresholds.weak.min,
            color: SignalStrengthLevel.Weak,
          },
          {
            displayName: tFireflySharedDomain("RadioSignalStrength.VeryLow"),
            max: SinrThresholds.weak.min,
            min: SinrThresholds.min,
            color: SignalStrengthLevel.VeryLow,
          },
        ],
      };
    } else {
      throw new IllegalStateError("currentType : " + currentType);
    }
  }, [
    currentType,
    timeDataSeriesRsrp,
    tFireflySharedDomain,
    timeDataSeriesRssi,
    timeDataSeriesRsrq,
    timeDataSeriesSinr,
  ]);

  const handleTypeChange = (event: SelectChangeEvent) => {
    setCurrentType(event.target.value as SignalMeasurementType);
  };

  return (
    <Stack direction={"column"} sx={props.sx}>
      <FormControl sx={{ pt: 2 }} size={"small"}>
        <InputLabel>{tFireflySharedDomain("Type")}</InputLabel>
        <Select
          variant={"outlined"}
          value={currentType}
          label={tFireflySharedDomain("Type")}
          onChange={handleTypeChange}
        >
          <MenuItem value={"rsrp"}>
            Reference Signal Received Power (RSRP)
          </MenuItem>
          <MenuItem value={"rssi"}>
            Received Signal Strength Indicator (RSSI)
          </MenuItem>
          <MenuItem value={"sinr"}>
            Signal Interference + Noise Ratio (SINR)
          </MenuItem>
          <MenuItem value={"rsrq"}>
            Reference Signal Received Quality (RSRQ)
          </MenuItem>
        </Select>
      </FormControl>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          minWidth: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: "-3px",
              left: 0,
              right: 0,
              height: "2px",
            }}
          />
        )}
        {current.timeSeries !== undefined && (
          <TimeSeriesChart
            timeSeries={current.timeSeries}
            timeSeriesOptions={
              [
                {
                  displayName: current.shortName,
                },
              ] as Array<TimeSeriesOption<number>>
            }
            options={{
              yAxis: {
                max: current.max,
                min: current.min,
                unit: current.unit,
              },
              annotations: {
                yaxis: current.annotations.map((annotation) => {
                  return createYAxisAnnotation({
                    text: annotation.displayName,
                    y2: annotation.max,
                    y: annotation.min,
                    color: annotation.color,
                    getContrastText: theme.palette.getContrastText,
                  });
                }),
              },
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

const createYAxisAnnotation = (args: {
  y: number;
  y2: number;
  text: string;
  color: string;
  getContrastText: (background: string) => string;
}): YAxisAnnotations => {
  return {
    y: args.y,
    y2: args.y2,
    fillColor: args.color,
    label: {
      borderColor: args.color,
      offsetY: 25,
      style: {
        color: args.getContrastText(args.color),
        background: args.color,
      },
      text: args.text,
    },
  };
};
