import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Alert, Box, Button, Snackbar, useTheme } from "@mui/material";
import { NotifyActions } from "./NotifyActions";
import { MessageEntry } from "./MessageEntry";
import { NotifyErrorParams } from "./NotifyErrorParams";
import { NotifyWarningParams } from "./NotifyWarningParams";
import { MessageEntryFactory } from "./MessageEntryFactory";
import { NotifyInfoParams } from "./NotifyInfoParams";
import { NotifySuccessParams } from "./NotifySuccessParams";
import { NotificationDisplay } from "./NotificationDisplay";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";

export type NotificationContextType = {
  notify: NotifyActions;
  messageEntries: Array<MessageEntry>;
  readEntry: (entry: MessageEntry) => void;
  unreadEntry: (entry: MessageEntry) => void;
};

export const NotificationContext = createContext(
  undefined as unknown as NotificationContextType
);

export interface NotificationContextProviderProps {
  children?: ReactNode;
}

export const NotificationContextProvider: FC<
  NotificationContextProviderProps
> = (props) => {
  const theme = useTheme();
  const [messages, setMessages] = useState<Array<MessageEntry>>([]);
  const unreadMessages = useMemo(
    () => messages.filter((it) => !it.read),
    [messages]
  );
  const notifyError = useCallback((params: NotifyErrorParams) => {
    console.error(params.error);
    setMessages((prevState) => {
      return ArrayUtils.copyAndPrepend(
        prevState,
        MessageEntryFactory.createError(params)
      );
    });
  }, []);

  const notifyWarning = useCallback((params: NotifyWarningParams) => {
    setMessages((prevState) => {
      return ArrayUtils.copyAndPrepend(
        prevState,
        MessageEntryFactory.createWarning(params)
      );
    });
  }, []);

  const notifyInfo = useCallback((params: NotifyInfoParams) => {
    setMessages((prevState) => {
      return ArrayUtils.copyAndPrepend(
        prevState,
        MessageEntryFactory.createInfo(params)
      );
    });
  }, []);

  const notifySuccess = useCallback((params: NotifySuccessParams) => {
    setMessages((prevState) => {
      return ArrayUtils.copyAndPrepend(
        prevState,
        MessageEntryFactory.createSuccess(params)
      );
    });
  }, []);

  const handleClose = useCallback((messageEntry: MessageEntry) => {
    setMessages((prevState) => {
      return prevState.map((it) => {
        if (it === messageEntry) {
          return { ...it, read: true };
        } else {
          return it;
        }
      });
    });
  }, []);

  const readEntry = useCallback((entry: MessageEntry) => {
    setMessages((prevState) => {
      return prevState.map((it) => {
        if (it === entry) {
          return { ...it, read: true };
        } else {
          return it;
        }
      });
    });
  }, []);

  const unreadEntry = useCallback((entry: MessageEntry) => {
    setMessages((prevState) => {
      return prevState.map((it) => {
        if (it === entry) {
          return { ...it, read: false };
        } else {
          return it;
        }
      });
    });
  }, []);

  const messageToShow =
    unreadMessages.length > 0 ? unreadMessages[0] : undefined;

  const notifyActions = useMemo(
    () => ({
      error: notifyError,
      warning: notifyWarning,
      info: notifyInfo,
      success: notifySuccess,
    }),
    [notifyError, notifyInfo, notifySuccess, notifyWarning]
  );

  const contextValue: NotificationContextType = useMemo(
    () => ({
      notify: notifyActions,
      messageEntries: messages,
      readEntry: readEntry,
      unreadEntry: unreadEntry,
    }),
    [messages, notifyActions, readEntry, unreadEntry]
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
      <Snackbar
        open={messageToShow?.read === false ?? false}
        onClose={() => {
          if (messageToShow != null) {
            handleClose(messageToShow);
          }
        }}
        autoHideDuration={messageToShow?.options.autoHideDuration}
      >
        <Alert
          severity={messageToShow?.notification.severity}
          variant={"filled"}
          sx={{
            ".MuiAlert-icon": {
              alignSelf: "center",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {messageToShow?.notification != null && (
              <NotificationDisplay notification={messageToShow.notification} />
            )}
            <Box sx={{ marginLeft: theme.spacing(3) }}>
              <Button
                color={"inherit"}
                onClick={() => {
                  if (messageToShow != null) {
                    handleClose(messageToShow);
                  }
                }}
              >
                Lukk
              </Button>
            </Box>
          </Box>
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
