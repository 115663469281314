import { FC, useMemo } from "react";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { ChartsAxisContentProps } from "@mui/x-charts/ChartsTooltip/ChartsAxisTooltipContent";
import { With } from "@airmont/shared/ts/ui/react";
import { LineSeriesType } from "@mui/x-charts";
import { ChartsTooltipMark } from "./ChartsTooltipMark";
import { DateTime } from "luxon";
import { notNullOrUndefined } from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";

export interface SummarizingChartsAxisContentProps
  extends ChartsAxisContentProps {
  hideSeriesWithZeroValue?: boolean;
  noDataMessage?: string;
}

export const SummarizingChartsAxisContent: FC<
  SummarizingChartsAxisContentProps
> = (props) => {
  const { t } = useTranslation("shared-ts-mui");
  const theme = useTheme();
  const noDataMessage = props.noDataMessage ?? t("No Data");
  const hideSeriesWithZeroValue = props.hideSeriesWithZeroValue ?? false;
  const axisValue = props.axisValue;
  const dataIndex = props.dataIndex;
  const series = (props.series as Array<LineSeriesType>).filter((it) => {
    if (dataIndex == null) {
      return false;
    }

    if (it.data?.[dataIndex] == null) {
      return false;
    }

    return !hideSeriesWithZeroValue || it.data[dataIndex] > 0;
  });
  const sum = useMemo(() => {
    return series.reduce((a, it) => {
      return (
        a +
        (notNullOrUndefined(
          props.dataIndex,
          (dataIndex) => it.data?.[dataIndex] ?? 0
        ) ?? 0)
      );
    }, 0);
  }, [props.dataIndex, series]);

  return (
    <Stack
      direction={"column"}
      sx={{
        ml: 2,
        mr: 2,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        borderRadius: 1,
      }}
    >
      {axisValue != null && (
        <Typography
          color={"text.secondary"}
          sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}
        >
          {axisValue instanceof Date
            ? DateTime.fromJSDate(axisValue).toLocaleString(
                DateTime.DATETIME_SHORT
              )
            : axisValue}
        </Typography>
      )}
      <Divider />
      <Stack p={1} direction={"column"} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
        {series.length === 0 && (
          <Typography color={"text.secondary"}>{noDataMessage}</Typography>
        )}
        {series.length > 0 && (
          <>
            <With>
              {() => {
                const dataIndex = props.dataIndex;
                if (dataIndex == null) {
                  return undefined;
                }

                return series.map((itSeries, ix) => {
                  const value = itSeries.data?.[dataIndex];

                  return (
                    <Stack
                      key={ix}
                      direction={"row"}
                      useFlexGap
                      gap={1}
                      sx={{ alignItems: "center" }}
                    >
                      <ChartsTooltipMark color={itSeries.color} />
                      <Typography color={"text.secondary"}>
                        {itSeries.label !== undefined &&
                        typeof itSeries.label === "string"
                          ? itSeries.label
                          : itSeries.label?.("tooltip")}
                      </Typography>
                      <Typography sx={{ marginLeft: "auto", pl: 1 }}>
                        {value != null
                          ? itSeries.valueFormatter != null
                            ? itSeries.valueFormatter?.(value, {
                                dataIndex: dataIndex,
                              })
                            : value
                          : ""}
                      </Typography>
                    </Stack>
                  );
                });
              }}
            </With>
            {series.length > 1 && (
              <>
                <Divider />
                <Stack
                  key={"sum"}
                  direction={"row"}
                  useFlexGap
                  gap={1}
                  sx={{ alignItems: "center" }}
                >
                  <Typography color={"text.secondary"} sx={{ pl: "2px" }}>
                    =
                  </Typography>
                  <Typography color={"text.secondary"} sx={{ pl: 0 }}>
                    {t("Sum")}
                  </Typography>
                  <Typography sx={{ marginLeft: "auto", pl: 1 }}>
                    {sum}
                  </Typography>
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
