import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { useBurnDao } from "./useBurnDao";
import { AbnormalBurnsQueryClient } from "./AbnormalBurnsQueryClient";

export const useAbnormalBurnsQueryClient =
  (): AbnormalBurnsQueryClient => {
    const queryClient = useQueryClient();
    const dao = useBurnDao();
    return useMemo(
      () =>
        new AbnormalBurnsQueryClient({
          queryClient: queryClient,
          dao: dao,
        }),
      [queryClient, dao]
    );
  };
