import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Box, Card, css, Tooltip, Typography, useTheme } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { PaperPropsVariantOverrides } from "@mui/material/Paper/Paper";
import styled, { keyframes } from "styled-components";
import { SxProps } from "@mui/system";
import { DateTime, Duration } from "luxon";
import { CardActionAreaOrNot } from "./CardActionAreaOrNot";

export type CardLayoutType = "standard" | "row" | "compact" | "tiny";
export type ValueType = string | number | boolean | DateTime | Duration;

const resolveValueToDisplay = <T extends ValueType, R extends ValueType>(
  nextValue: T | null | undefined,
  prevValue: T | null | undefined,
  transform?: (value: T) => R
): T | R | null | undefined => {
  let value = nextValue === undefined ? prevValue : nextValue;

  if (transform != null && value != null) {
    return transform(value);
  }

  if (typeof value === "string") {
    if (value.length === 0) {
      value = null;
    }
  }

  return value;
};

const valueChangeAnimation = keyframes`
  0% {
    color: rgb(255, 255, 255, 0.2);
  }
  30% {
    color: rgb(255, 255, 255, 0.4);
  }
  70% {
    color: rgb(255, 255, 255, 0.8);
  }
  100% {
    color: rgb(255, 255, 255, 1);
  }
`;

const skeletonAnimation = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #404040;
  }
  100% {
    background-color: transparent;
  }
`;

export const StyledValueCard = styled(Card)<{
  $layout: CardLayoutType;
  $loading: boolean;
  $selected: boolean;
  $primaryColor: string;
  $animateValue: boolean;
}>`
  overflow: visible;

  ${({ $selected, $primaryColor }) =>
    $selected &&
    css`
      border: 2px solid ${$primaryColor};
    `}
  ${({ $selected }) =>
    !$selected &&
    css`
      border: 2px solid transparent;
    `}
  ${({ $loading }) =>
    $loading &&
    css`
      background-color: #222;
      animation-name: ${skeletonAnimation};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
  .grid {
    display: grid;

    ${({ $layout }) =>
      $layout === "standard" &&
      css`
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
          "header"
          "main";
      `}
    ${({ $layout }) =>
      $layout === "compact" &&
      css`
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
          "header"
          "main";
      `}
    ${({ $layout }) =>
      $layout === "row" &&
      css`
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: "header main";
      `}
    ${({ $layout }) =>
      $layout === "tiny" &&
      css`
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: "header main";
      `}
    .labelContainer {
      grid-area: header;

      display: flex;
      align-items: center;
    }

    .valueContainer {
      grid-area: main;

      display: flex;
      align-items: center;
      justify-content: center;

      .value {
        ${({ $animateValue }) =>
          $animateValue &&
          css`
            animation: ${valueChangeAnimation} 1s normal;
          `}
      }
    }
  }

  .grid {
    &.standard-layout {
      .labelContainer {
        padding-top: 14px;
        padding-left: 16px;
        padding-right: 16px;
        justify-content: center;
      }

      .valueContainer {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 8px;
      }
    }
  }

  .grid {
    &.row-layout {
      .labelContainer {
        padding-left: 16px;
        padding-right: 16px;
        justify-content: flex-start;
      }

      .valueContainer {
        min-height: 48px;
        padding-right: 16px;
        justify-content: flex-end;
      }
    }
  }

  .grid {
    &.compact-layout {
      .labelContainer {
        padding-top: 8px;
        padding-left: 8px;
        padding-right: 8px;
        justify-content: center;
      }

      .valueContainer {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .grid {
    &.tiny-layout {
      .labelContainer {
        padding-left: 8px;
        padding-right: 8px;
        justify-content: flex-start;
      }

      .valueContainer {
        min-height: 38px;
        padding-right: 8px;
        justify-content: flex-end;
      }
    }
  }
`;

export interface ValueCardProps<V extends ValueType, R extends ValueType> {
  variant?: OverridableStringUnion<
    "elevation" | "outlined",
    PaperPropsVariantOverrides
  >;
  elevation?: number;
  label: string;
  hideLabel?: boolean;
  labelProps?: {
    align?: "inherit" | "left" | "center" | "right" | "justify";
    noWrap?: boolean;
  };
  tooltip?: string;
  loading?: boolean;
  value: V | null | undefined;
  transform?: (value: V) => R;
  render?: (value: V | R) => React.ReactNode;
  unit?: string;
  layout?: CardLayoutType;
  sx?: SxProps;
  selected?: boolean;
  onClick?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ValueCard = <V extends ValueType, R extends ValueType>(
  props: ValueCardProps<V, R>
): ReactElement<any, any> | null => {
  const theme = useTheme();
  const selected = props.selected ?? false;
  const loading = props.loading ?? false;
  const layout = props.layout ?? "standard";
  const [showValueChangeAnimation, setShowValueChangeAnimation] =
    useState(false);
  const labelFontSize =
    layout === "tiny"
      ? "0.875rem"
      : layout === "compact"
      ? "1.2rem"
      : "0.875rem";
  const valueFontSize =
    layout === "tiny" ? "1rem" : layout === "compact" ? "1.2rem" : "2rem";
  const unitFontSize =
    layout === "tiny" ? "1rem" : layout === "compact" ? "1.2rem" : "2rem";
  const valueChangeCount = useRef<number>(0);
  const [previousValue, setPreviousValue] = useState<V | null | undefined>(
    props.value
  );

  const valueToDisplay = resolveValueToDisplay(
    props.value,
    previousValue,
    props.transform
  );
  const skeleton = (loading ?? false) && valueToDisplay === undefined;

  useEffect(() => {
    if (!skeleton) {
      setShowValueChangeAnimation(true);
      setTimeout(() => {
        setShowValueChangeAnimation(false);
      }, 1000);
    }
  }, [skeleton, valueToDisplay]);

  useEffect(() => {
    if (
      valueChangeCount.current > 0 &&
      !(loading && props.value === undefined)
    ) {
      setPreviousValue(props.value);
    }

    valueChangeCount.current = valueChangeCount.current + 1;
  }, [props.value, loading]);

  const handleActionAreaClick = () => {
    props.onClick?.();
  };

  return (
    <StyledValueCard
      $layout={layout}
      $animateValue={showValueChangeAnimation}
      $loading={skeleton}
      $selected={selected}
      $primaryColor={theme.palette.primary.main}
      className={"ValueCard"}
      variant={props.variant}
      elevation={
        selected ? Math.min((props.elevation ?? 1) + 8, 24) : props.elevation
      }
      sx={props.sx}
    >
      <CardActionAreaOrNot
        cardActions={props.onClick != null}
        onClick={handleActionAreaClick}
      >
        <Box className={`grid ${layout}-layout`}>
          {(props.hideLabel ?? false) === false && (
            <Tooltip title={props.tooltip} placement={"top"}>
              <Box className={"labelContainer"}>
                <Typography
                  align={props.labelProps?.align ?? "center"}
                  fontSize={labelFontSize}
                  fontWeight={500}
                  color={"text.secondary"}
                  noWrap={props.labelProps?.noWrap ?? true}
                  component={"p"}
                  variant={"subtitle2"}
                >
                  {props.label}
                </Typography>
              </Box>
            </Tooltip>
          )}
          <Box className={"valueContainer"}>
            {props.render == null && (
              <Typography
                className={"value"}
                component={"p"}
                sx={{
                  color: theme.palette.text.primary,
                  align: "center",
                  fontWeight: 600,
                  fontSize: valueFontSize,
                  letterSpacing: "0.03em",
                  visibility: valueToDisplay != null ? "visible" : "hidden",
                }}
              >
                {valueToDisplay?.toString() ?? "?"}
              </Typography>
            )}
            {props.render == null && valueToDisplay != null && props.unit && (
              <Typography
                className={"unit"}
                component={"span"}
                sx={{
                  fontSize: unitFontSize,
                  lineHeight: "1",
                }}
              >
                &nbsp;{props.unit}
              </Typography>
            )}
            {props.render != null && valueToDisplay != null && (
              <Box
                className={"value"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {props.render(valueToDisplay)}
              </Box>
            )}
          </Box>
        </Box>
      </CardActionAreaOrNot>
    </StyledValueCard>
  );
};
