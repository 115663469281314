import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import {
  TimeSeries,
  TimeSeriesImpl,
} from "@airmont/firefly/shared/ts/timeseries";
import { YAxisDefaultConfig } from "shared-ts-mui";
import { merge } from "lodash";

export const resolveYAxisConfig = (
  timeSeries: Array<TimeSeries<number | null>>,
  axisConfigs?: Array<YAxisDefaultConfig>
): Array<YAxisDefaultConfig> => {
  if (timeSeries.length === 0) {
    return [
      {
        max: 100,
      } as YAxisDefaultConfig,
    ];
  }

  const firstTimeSeries = timeSeries.length > 0 ? timeSeries[0] : undefined;

  return (
    axisConfigs?.map((it) => {
      const max =
        it.max != null && typeof it.max === "number"
          ? it.max
          : (TimeSeriesImpl.getMaxY(timeSeries, 0) ?? 0) + 20;

      const valueFormatter:
        | ((value: number, context: AxisValueFormatterContext) => string)
        | undefined =
        it.valueFormatter != null
          ? it.valueFormatter
          : firstTimeSeries !== undefined
          ? (value: number) => `${value} ${firstTimeSeries.info.unit}`
          : undefined;

      return merge(
        {
          max: max,
          valueFormatter: valueFormatter,
        },
        it
      );
    }) ?? [
      {
        max: (TimeSeriesImpl.getMaxY(timeSeries, 0) ?? 0) + 20,
      },
    ]
  );
};
