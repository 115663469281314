import { PropertyValueType } from "./PropertyValueType";
import { ValueOptions } from "./ValueOptions";
import { EditComponentTypeName } from "./EditComponentTypeName";

export const resolveEditComponentTypeName = (
  valueType: PropertyValueType,
  valueOptions: ValueOptions | undefined,
  editComponent: EditComponentTypeName | undefined
): EditComponentTypeName => {
  if (editComponent !== undefined) {
    return editComponent;
  } else {
    if (valueOptions != null) {
      if (valueOptions.multiple === true) {
        return "AutocompleteMultiple";
      } else {
        if (valueOptions.restrictToOptions === true) {
          return "Select";
        }
        return "Autocomplete";
      }
    } else if (valueType === "number") {
      return "TextField";
    } else if (valueType === "boolean") {
      return "Switch";
    } else if (valueType === "range") {
      return "Slider";
    } else if (valueType === "date") {
      return "Date";
    } else if (valueType === "dateTime") {
      return "DateTime";
    } else if (valueType === "file") {
      return "File";
    } else {
      return "TextField";
    }
  }
};
