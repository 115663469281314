import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export const useAnonymizeUsageDataWithNullAsDefault = () => {
  return useUserSettingWithDefault<boolean | null>(
    "anonymizeUsageData",
    BooleanSetting,
    null
  );
};
