import React from "react";
import { NavigationType } from "../NavigationType";

export interface Component {
  type: "Component";
  render: (navigationType: NavigationType) => React.ReactElement;
}

export function isComponent(obj: object): obj is Component {
  return typeof obj === "object" && "type" in obj && obj.type === "Component";
}
