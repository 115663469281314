import { useTabContext } from "@mui/lab";
import { CSSProperties, FC, ReactNode, useMemo } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { merge } from "lodash";

export interface TabPanelProps {
  tabValue: string;
  scrollableY?: boolean;
  sx?: SxProps;
  style?: CSSProperties;
  children?: ReactNode;
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const tabContext = useTabContext();
  const selected = props.tabValue === tabContext?.value;
  const scrollableY = props.scrollableY ?? false;

  const sx: SxProps<Theme> = useMemo(() => {
    const sx: SxProps<Theme> = merge(
      { padding: "unset" },
      { ...props.sx },
      scrollableY
        ? {
            position: "absolute",
            overflowY: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }
        : undefined,
      !selected ? { display: "none" } : undefined
    );

    return sx;
  }, [props.sx, selected, scrollableY]);

  const style: CSSProperties | undefined = useMemo(() => {
    const style: CSSProperties | undefined =
      props.style != null ? { ...props.style } : undefined;
    if (style != null && style.display != null && !selected) {
      style.display = "none";
    }
    return style;
  }, [props.style, selected]);

  const className = useMemo(() => {
    let cs = "TabPanel";
    if (scrollableY) {
      cs += " TabPanel-scrollableY";
    }
    return cs;
  }, [scrollableY]);

  return (
    <Box sx={sx} style={style} className={className}>
      {props.children}
    </Box>
  );
};
