import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import { FlueMetricValues } from "@airmont/firefly/shared/ts/domain";
import { Duration } from "luxon";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface FlueMetricValuesTableRowProps {
  label: string;
  values: FlueMetricValues;
  loading?: boolean;
  layout?: SizeClass;
}

export const FlueMetricValuesTableRow: FC<FlueMetricValuesTableRowProps> = (
  props
) => {
  const { values } = props;
  const burnHourCountRounded = MathUtils.round(values.burnHourCount);
  const duration = Duration.fromDurationLike({
    hour: burnHourCountRounded,
  });
  return (
    <TableRow>
      <TableCell key={"label"}>{props.label}</TableCell>
      <TableCell key={"burnCount"} align={"right"}>
        {values.burnCount}
      </TableCell>
      <TableCell key={"burnHours"} align={"right"}>
        <DurationDisplay
          duration={duration}
          maxUnits={1}
          shiftTo={["hours"]}
          layout={props.layout}
          sx={{ fontSize: "inherit", lineHeight: "inherit" }}
        />
      </TableCell>
      <TableCell key={"sootIndex"} align={"right"}>
        {MathUtils.round(values.sootIndex)}
      </TableCell>
      <TableCell key={"temperatureMax"} align={"right"}>
        <span style={{ whiteSpace: "nowrap" }}>{values.temperatureMax} °C</span>
      </TableCell>
      <TableCell key={"chimneyFireCount"} align={"right"}>
        {values.chimneyFireCount}
      </TableCell>
    </TableRow>
  );
};
