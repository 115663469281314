export enum ResolutionType {
  Original,
  Second,
  Minute,
  Hour,
  Day,
  Week,
  Month,
  Quarter,
  Year
}
