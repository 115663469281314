export const SinrThresholds = {
  max: 50,
  excellent: {
    min: 20,
  },
  good: {
    min: 13,
  },
  weak: {
    min: 0,
  },
  veryLow: {
    min: -5,
  },
  min: -5,
};
export const SinrLevelDictionary = (value: number) => ({
  excellent: value >= 20,
  good: value >= 13 && value < 20,
  weak: value >= 0 && value < 13,
  veryLow: value < 0,
});
