import React, { FC } from "react";
import {
  ActionGroup,
  ActionListItem,
  ItemVariant,
  SelectableAction,
  useResolveItemColors,
} from "@airmont/shared/ts/ui/action";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";
import ExpandLessIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";

export interface GroupNodeProps {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: ActionGroup;
  open: boolean;
  hideLabels: boolean;
  labelOrientation: "horizontal" | "vertical";
  onOpenChange: (open: boolean) => void;
}

export const GroupNode: FC<GroupNodeProps> = (props) => {
  const { variant, layout, layoutDirection, item, open, onOpenChange } = props;
  const handleGroupActionExecute = () => {
    onOpenChange(!open);
  };

  const actionItem = {
    type: "Action",
    selectable: true,
    selected: open,
    name: item.name,
    nameBreakable: item.nameBreakable,
    icon: item.icon,
    onExecute: handleGroupActionExecute,
  } as SelectableAction;

  const { containerColorOnHover } = useResolveItemColors();

  return (
    <ActionListItem
      variant={variant}
      item={actionItem}
      layout={layout}
      layoutDirection={layoutDirection}
      hideSelectionIcon={true}
      endAdornment={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      sx={{
        "&&.selected": {
          ".MuiListItemButton-root": {
            backgroundColor: containerColorOnHover,
          },
        },
      }}
    />
  );
};
