import {
  alpha,
  Card,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useTranslation } from "react-i18next";
import {
  ChimneySensorStatusEnum,
  resolveChimneySensorStatusColorOrDefault,
  resolveChimneySensorStatusColorOrThrow,
} from "@airmont/firefly/shared/ts/domain";

export const SensorMapStatusLegend = () => {
  const theme = useTheme();
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const [display, setDisplay] = useUserSettingWithDefault(
    "SensorMapStatusLegend.display",
    BooleanSetting,
    true
  );
  const handleToggleDisplay = () => {
    setDisplay((prevDisplay) => !prevDisplay);
  };
  return (
    <Card
      sx={{
        m: 1,
        backgroundColor: alpha(
          theme.palette.background.paper,
          display ? 0.9 : 0.7
        ),
      }}
    >
      <Toolbar
        variant="dense"
        sx={{ "&&": { maxHeight: "40px", minHeight: "40px", pl: 1, pr: 1 } }}
      >
        <Typography sx={{ mr: 1 }}>{t("Legend")}</Typography>
        <IconButton
          onClick={handleToggleDisplay}
          sx={{
            ml: "auto",
            backgroundColor: "transparent",
            borderRadius: 0,
          }}
        >
          {display ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </IconButton>
      </Toolbar>
      <Collapse in={display} orientation="horizontal">
        <Collapse in={display} orientation="vertical">
          <List
            sx={{
              ".MuiListItemIcon-root": { minWidth: "unset", mr: 1 },
            }}
          >
            <ListSubheader sx={{ backgroundColor: "inherit" }}>
              {t("Sensor Status") + ":"}
            </ListSubheader>
            <ListItem>
              <ListItemIcon>
                <CircleIcon
                  sx={{
                    color: resolveChimneySensorStatusColorOrDefault(
                      ChimneySensorStatusEnum.Active,
                      "#66bb6a"
                    ),
                  }}
                  fontSize={"small"}
                />
              </ListItemIcon>
              <ListItemText>
                {tFireflySharedDomain(
                  `ChimneySensorStatus.${ChimneySensorStatusEnum.Active}`
                )}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon
                  sx={{
                    color: resolveChimneySensorStatusColorOrThrow(
                      ChimneySensorStatusEnum.NotCommunicating
                    ),
                  }}
                  fontSize={"small"}
                />
              </ListItemIcon>
              <ListItemText>
                {tFireflySharedDomain(
                  `ChimneySensorStatus.${ChimneySensorStatusEnum.NotCommunicating}`
                )}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon
                  sx={{
                    color: resolveChimneySensorStatusColorOrThrow(
                      ChimneySensorStatusEnum.FailedToStart
                    ),
                  }}
                  fontSize={"small"}
                />
              </ListItemIcon>
              <ListItemText>
                {tFireflySharedDomain(
                  `ChimneySensorStatus.${ChimneySensorStatusEnum.FailedToStart}`
                )}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon
                  sx={{
                    color: resolveChimneySensorStatusColorOrThrow(
                      ChimneySensorStatusEnum.Dead
                    ),
                  }}
                  fontSize={"small"}
                />
              </ListItemIcon>
              <ListItemText>
                {tFireflySharedDomain(
                  `ChimneySensorStatus.${ChimneySensorStatusEnum.Dead}`
                )}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </Collapse>
    </Card>
  );
};
