export const apexChartsLocales : Array<ApexLocale>  = [
    {
        name: "en",
        options: {
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            toolbar: {
                download: "Download",
                selection: "Selection",
                selectionZoom: "Selection Zoom",
                zoomIn: "Zoom In",
                zoomOut: "Zoom Out",
                pan: "Panning",
                reset: "Reset Zoom",
                exportToSVG: "Download SVG",
                exportToPNG: "Download PNG",
                exportToCSV: "Download CSV",
            }
        }
    },
    {
        name: "no",
        options: {
            months: ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"],
            shortMonths: ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"],
            days: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
            shortDays: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
            toolbar: {
                download: "Last ned",
                selection: "Utvalg",
                selectionZoom: "Velg forstørring",
                zoomIn: "Forstørr",
                zoomOut: "Forminsk",
                pan: "Flytt",
                reset: "Tilbakestill forstørring",
                exportToSVG: "Last ned SVG",
                exportToPNG: "Last ned PNG",
                exportToCSV: "Last ned CSV",
            }
        }
    }
]