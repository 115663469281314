import { SensorId } from "../sensor/SensorId";
import { DateTime } from "luxon";
import {
  _throw,
  IllegalStateError,
  RandomUtils,
} from "@airmont/shared/ts/utils/core";
import {
  IMetaInfo,
  TimeNumberDto,
  TimeSeriesDto,
  TimeSeriesGenerator,
  TimeSeriesGeneratorQueryOptions,
} from "@airmont/firefly/shared/ts/timeseries";
import { ChimneySensorDto } from "../sensor/ChimneySensor";

export class ChimneyFireTimeSeriesGenerator implements TimeSeriesGenerator {
  readonly sourceId: SensorId;
  readonly name: string;
  readonly metaInfo: IMetaInfo;
  private readonly sensor: ChimneySensorDto;
  private readonly temperatureThreshold: number;
  private readonly points: Array<TimeNumberDto>;

  constructor(args: {
    sensor: ChimneySensorDto;
    temperatureThreshold: number;
  }) {
    this.name = "fire";
    this.sensor = args.sensor;
    this.sourceId = args.sensor.id;
    this.temperatureThreshold = args.temperatureThreshold;
    this.metaInfo = {
      id: args.sensor.id,
      sid: args.sensor.id,
      name: "fire",
      displayName: "Temperature alarm",
      unit: "C",
      resolution: "",
    };
    this.points = [];
    this.init();
    setInterval(this.handleInterval.bind(this), 5000);
  }

  getTimeSeries(options?: TimeSeriesGeneratorQueryOptions): TimeSeriesDto {
    return {
      info: this.metaInfo,
      length: this.points.length,
      points: this.points.filter((point) => {
        if (options != null) {
          if (options.previousTime != null) {
            const time = DateTime.fromISO(point.time);
            return time > options.previousTime;
          }
        }

        return true;
      }),
    };
  }

  private init() {
    const now = DateTime.now();
    this.points.push({
      time: now.toISO() ?? _throw(new IllegalStateError("time is invalid")),
      value: this.temperatureThreshold,
    });

    while (this.points[0].value > 20) {
      this.points.unshift({
        time:
          DateTime.fromISO(this.points[0].time).minus({ seconds: 5 }).toISO() ??
          _throw(new IllegalStateError("time is invalid")),
        value: this.points[0].value - RandomUtils.getRandomInt(0, 5),
      });
    }
  }

  private handleInterval() {
    const lastPoint = this.points[this.points.length - 1];
    const nextValue =
      lastPoint.value < 600
        ? lastPoint.value + RandomUtils.getRandomInt(0, 5)
        : lastPoint.value;
    this.points.push({
      time:
        DateTime.fromISO(lastPoint.time).plus({ seconds: 5 }).toISO() ??
        _throw(new IllegalStateError("time is invalid")),
      value: nextValue,
    });
  }
}
