import React, { FC, useMemo } from "react";
import { SxProps } from "@mui/system";
import { TabPanel, TabPanelProps } from "@airmont/shared/ts/ui/tabs";
import { Container, Link, Typography } from "@mui/material";
import { CssGridTemplate } from "@airmont/shared/ts/ui/css-grid-template";
import {
  ChimneySensorPlacementStringEnum,
  FireplaceCatalyticProperty,
} from "@airmont/firefly/shared/ts/domain";
import { merge } from "lodash";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import {
  ChimneyHatProperty,
  FlueHeightProperty,
  FlueStatusDto,
  FlueTypeProperty,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useFormikContext } from "formik";
import { FlueFormValues } from "../FlueFormValues";
import { Property, PropertyMode } from "shared-ts-property";
import { DisplayMode } from "shared-ts-app-dialog";

export interface CombustionPlantTabPanelProps extends TabPanelProps {
  flueStatus: FlueStatusDto;
  mode: DisplayMode;
}

export const CombustionPlantTabPanel: FC<CombustionPlantTabPanelProps> = (
  props
) => {
  const { flueStatus } = props;
  const propertyMode: PropertyMode = props.mode === "view" ? "read" : "edit";
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const { t: tFireflyChimneyInsightsPages } = useTranslation(
    "firefly-chimney-insights-ts-pages"
  );
  const {
    values: formValues,
    setFieldValue: setFormValue,
    errors,
  } = useFormikContext<FlueFormValues>();

  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      display: "flex",
      flexDirection: "column",
      pt: 1,
      pb: 1,
    });
  }, [props.sx]);

  return (
    <TabPanel tabValue={props.tabValue} sx={sx} scrollableY>
      <Container maxWidth={"lg"} sx={{ display: "flex" }}>
        <CssGridTemplate
          className={"CombustionPlant"}
          areas={{
            compact: "'c' 'r' 's' 'f'",
            medium: "'c r' 's f'",
            expanded: "'c r s f'",
            extraLarge: "'c r s f'",
          }}
          columns={{
            compact: "auto",
            medium: "auto",
            expanded: "auto",
            extraLarge: "auto",
          }}
          rows={{
            compact: "auto",
            medium: "auto",
            expanded: "auto",
            extraLarge: "auto",
          }}
          sx={{
            flex: 1,
            width: "fit-content",
            ml: "auto",
            mr: "auto",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <PropertiesCard
            header={{ title: tFireflySharedDomain("Chimney") }}
            sx={{ gridArea: "c", width: "fit-content" }}
          >
            <ChimneyHatProperty
              mode={propertyMode}
              value={formValues.chimneyHat}
              onFormikFieldValueChange={setFormValue}
            />
          </PropertiesCard>
          <PropertiesCard
            header={{ title: tFireflySharedDomain("Flue") }}
            sx={{ gridArea: "r", width: "fit-content" }}
          >
            <Property
              name="flueName"
              label={tFireflySharedDomain("Flue Name")}
              autoFocus
              mode={propertyMode}
              type={"string"}
              value={formValues.flueName}
              onFormikFieldValueChange={setFormValue}
            />
            <FlueTypeProperty
              mode={propertyMode}
              value={formValues.flueType}
              error={errors.flueType != null}
              onFormikFieldValueChange={setFormValue}
            />
            <FlueHeightProperty
              mode={propertyMode}
              value={formValues.flueHeight}
              onFormikFieldValueChange={setFormValue}
            />
            <Property
              name="sensorPlacement"
              label={tFireflySharedDomain("Sensor Placement")}
              type={"string"}
              value={formValues.sensorPlacement}
              mode={propertyMode}
              valueOptions={{
                multiple: false,
                restrictToOptions: true,
                options: [
                  {
                    id: ChimneySensorPlacementStringEnum.Roof,
                    label: tFireflySharedDomain(
                      `ChimneySensorPlacement.${ChimneySensorPlacementStringEnum.Roof}`
                    ),
                  },
                  {
                    id: ChimneySensorPlacementStringEnum.Attic,
                    label: tFireflySharedDomain(
                      `ChimneySensorPlacement.${ChimneySensorPlacementStringEnum.Attic}`
                    ),
                  },
                ],
              }}
              onFormikFieldValueChange={setFormValue}
            />
            <Property
              name="tags"
              label={tFireflySharedDomain("Tags")}
              mode={propertyMode}
              value={formValues.tags}
              valueOptions={{
                options: [],
                multiple: true,
                restrictToOptions: false,
              }}
              onFormikFieldValueChange={setFormValue}
            />
            <Property
              name="comment"
              label={tFireflySharedDomain("Comment")}
              mode={propertyMode}
              type={"string"}
              multiline
              value={formValues.comment}
              onFormikFieldValueChange={setFormValue}
            />
          </PropertiesCard>
          <PropertiesCard
            header={{
              title: tFireflySharedDomain("Sensor"),
              action:
                flueStatus.sensorFields?.serialNumber != null ? (
                  <Link
                    sx={{ alignSelf: "end" }}
                    component={RouterLink}
                    to={`/sensor-status/view/${flueStatus.sensorFields?.serialNumber}`}
                    style={{ marginRight: "8px" }}
                  >
                    {tFireflyChimneyInsightsPages("View Sensor")}
                  </Link>
                ) : undefined,
            }}
            sx={{ gridArea: "s", width: "fit-content" }}
          >
            {props.flueStatus.sensorFields == null && (
              <Typography>{tFireflySharedDomain("No Sensor")}</Typography>
            )}
            {props.flueStatus.sensorFields != null && (
              <>
                <Property
                  name="sensorSerialNumber"
                  label={tFireflySharedDomain("Serial Number")}
                  type={"string"}
                  mode={"read"}
                  value={props.flueStatus.sensorFields?.serialNumber}
                />
                <Property
                  name="sensorStatus"
                  label={tFireflySharedDomain("Status")}
                  type={"string"}
                  mode={"read"}
                  value={tFireflySharedDomain(
                    `ChimneySensorStatus.${props.flueStatus.sensorFields?.status}`
                  )}
                />
                <Property
                  name="sensorCableStatus"
                  label={tFireflySharedDomain("Cable Status")}
                  type={"string"}
                  mode={"read"}
                  value={tFireflySharedDomain(
                    `ChimneySensorCableStatus.${props.flueStatus.sensorFields?.cableStatus}`
                  )}
                />
              </>
            )}
          </PropertiesCard>
          <PropertiesCard
            header={{ title: tFireflySharedDomain("Fireplace") }}
            sx={{ gridArea: "f", width: "fit-content" }}
          >
            <FireplaceCatalyticProperty
              mode={propertyMode}
              value={formValues.fireplaceCatalytic}
              onFormikFieldValueChange={setFormValue}
            />
          </PropertiesCard>
        </CssGridTemplate>
      </Container>
    </TabPanel>
  );
};
