import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime, Interval } from "luxon";

export type EventId = string;

export interface EventDto {
  id: EventId;
  type: string;
  parentId?: EventId;
  start: DateTimeISO;
  end: DateTimeISO;
  participants: Array<string> | null;
}

export interface Event {
  id: EventId;
  parentId?: EventId;
  type: string;
  start: DateTime;
  end: DateTime;
  participants: Array<string> | null;
  toInterval: Interval;

  getChildEvents(): Array<EventImpl>;
  countChildEvents(): number;
}

export class EventImpl implements Event {
  id: EventId;
  parentId?: EventId;
  type: string;
  start: DateTime;
  end: DateTime;
  participants: Array<string> | null;
  private _interval?: Interval;

  constructor(args: {
    id: EventId;
    parentId?: EventId;
    type: string;
    start: DateTimeISO | DateTime;
    end: DateTimeISO | DateTime;
    participants?: Array<string> | null;
  }) {
    this.id = args.id;
    this.parentId = args.parentId;
    this.type = args.type;
    this.start =
      typeof args.start === "string"
        ? DateTime.fromISO(args.start)
        : args.start;
    this.end =
      typeof args.end === "string" ? DateTime.fromISO(args.end) : args.end;
    this.participants = args.participants ?? null;
  }

  get toInterval(): Interval {
    if (this._interval == null) {
      this._interval = Interval.fromDateTimes(this.start, this.end);
    }
    return this._interval;
  }

  getChildEvents(): Array<EventImpl> {
    return [];
  }

  countChildEvents(): number {
    return this.getChildEvents().length;
  }
}
