import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { AccessToken } from "shared-ts-utils-authentication";
import { ChannelDto, ChannelId } from "./Channel";
import { ChannelContactDto } from "./ChannelContact";

export class ChannelDao {
  private readonly accessToken: string;
  private readonly customerEnvironment: string;
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.accessToken = args.accessToken;
    this.customerEnvironment = args.customerEnvironment;
    this.fetchHelper = new FetchHelper({ basePath: "/api/channel/" })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  async getList(): Promise<Array<ChannelDto>> {
    return this.fetchHelper.get("list").fetchAsJson<Array<ChannelDto>>();
  }

  async getById(id: ChannelId): Promise<ChannelDto | undefined> {
    const channels = await this.getList();
    return channels.find((it) => it.id === id);
  }

  async getByName(name: string): Promise<ChannelDto | undefined> {
    const channels = await this.getList();
    return channels.find((it) => it.name === name);
  }

  addContact(channelContact: ChannelContactDto): Promise<void> {
    return this.fetchHelper.post("add-contact", channelContact).fetch();
  }
  removeContact(channelContact: ChannelContactDto): Promise<void> {
    return this.fetchHelper.post("remove-contact", channelContact).fetch();
  }

  queryKey(): {
    customerEnvironment: CustomerEnvironmentName;
    accessToken: AccessToken;
  } {
    return {
      customerEnvironment: this.customerEnvironment,
      accessToken: this.accessToken,
    };
  }
}
