import { FC, PropsWithChildren } from "react";
import { MapProps as VisMapProps } from "@vis.gl/react-google-maps";
import { MapWithDynamicMapId } from "./MapWithDynamicMapId";

export interface MapProps extends PropsWithChildren<VisMapProps> {
  palette: "light" | "dark";
}

export const Map: FC<MapProps> = (props) => {
  const mapId =
    props.palette === "light" ? "b9eccba04faf5ab6" : "aa56e046793435c6";
  return <MapWithDynamicMapId {...props} mapId={mapId} />;
};
