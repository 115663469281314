import { ApexChartsFormatterOptions } from "@airmont/firefly/shared/ts/charts";
import { TimeSeriesChartOptions } from "./TimeSeriesChartOptions";

export const resolveYAxisTooltipFormatter = (
  value: number,
  opts: ApexChartsFormatterOptions,
  options: TimeSeriesChartOptions | undefined
): string => {
  const valueRounded = Math.round(value);
  if (options?.yAxis?.unit != null) {
    return `${valueRounded} ${options.yAxis?.unit}`;
  } else {
    return `${valueRounded}`;
  }
};
