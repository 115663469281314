import { QueryClient as TanStackQueryClient } from "@tanstack/react-query";
import { FlueStatusDao } from "./FlueStatusDao";
import { FlueStatusQueryDto } from "./FlueStatusQuery";
import { FlueStatusDto } from "./FlueStatus";
import { FlueId } from "@airmont/firefly/shared/ts/domain";
import { PageDto, QueryClient, QueryResult } from "shared/ts/utils/query";

export class FlueStatusQueryClient implements QueryClient<FlueStatusDto> {
  private queryClient: TanStackQueryClient;
  private dao: FlueStatusDao;

  constructor(args: { queryClient: TanStackQueryClient; dao: FlueStatusDao }) {
    this.queryClient = args.queryClient;
    this.dao = args.dao;
  }

  getById = (id: FlueId): Promise<FlueStatusDto> => {
    return this.queryClient.fetchQuery({
      queryKey: ["FlueStatusDao.getById", id],
      queryFn: () => this.dao.getById(id),
    });
  };

  query = (
    query: FlueStatusQueryDto,
    page: PageDto
  ): Promise<QueryResult<FlueStatusDto>> => {
    return this.queryClient.fetchQuery({
      queryKey: ["FlueStatusDao.query", query, page],
      queryFn: () =>
        this.dao.query({
          ...query,
          page: page,
        }),
    });
  };
}
