import { AccessToken } from "shared-ts-utils-authentication";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { FlueId } from "@airmont/firefly/shared/ts/domain";
import { TimeDeviceHealthDto } from "./TimeDeviceHealthPoint";

export class DeviceHealthDao {
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: AccessToken;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({ basePath: "/api/devicehealth/" })
      .authorizationBearer(args.accessToken)
      .addHeader("Firefly-Environment", args.customerEnvironment);
  }

  getList(flueId: FlueId): Promise<Array<TimeDeviceHealthDto>> {
    return this.fetchHelper.get(`list/${flueId}`).fetchAsJson();
  }
}
